import { useEffect, useState } from "react";
import { TableDetailsViewData } from "../../../../../../../../models";
import { TableDetailsView } from "../../../../../../../components/table-details-view/TableDetailsView";
import {
  useBusinessApplicationNameCodesData,
  useFuelPressureCodesData,
  useSupportedVehicleNameCodesData,
} from "../../../../../../../../hooks";
import { Input, InputChangeEvent } from "@progress/kendo-react-inputs";
import { Locale } from "../../../../../../../../utils/localization";
import { EditFieldTypeConst } from "../../../../../../../../constants";
import { Skeleton } from "@progress/kendo-react-indicators";
import {
  DropDownList,
  DropDownListChangeEvent,
} from "@progress/kendo-react-dropdowns";

interface IProps {
  isCreate: boolean;
  isEditing: boolean;

  evDetailsTabSelected: any;

  onPatchRequestValueSet: any;
  onSectionValueSet: any;

  isSectionValueChanged: any;
  onSectionValueChanged: any;
  sectionEditedData: any;

  fetchedHydrogenMobilityData: any;
  isHydrogenMobilityDataLoading: boolean;
}

export const HydrogenMobility = (props: IProps) => {
  const {
    isCreate,
    isEditing,

    evDetailsTabSelected,

    onPatchRequestValueSet,
    onSectionValueSet,

    isSectionValueChanged,
    onSectionValueChanged,
    sectionEditedData,

    fetchedHydrogenMobilityData,
    isHydrogenMobilityDataLoading,
  } = props;

  const [defaultData, setDefaultData] = useState<any>([]);

  const [newPumpID, setNewPumpID] = useState("");
  const [newDispenserID, setNewDispenserID] = useState("");
  const [
    newBusinessApplicationNameSelectedValue,
    setNewBusinessApplicationNameSelectedValue,
  ] = useState<{ id: string; value: string } | null>();
  const [newFuelPressureSelectedValue, setNewFuelPressureSelectedValue] =
    useState<{ id: string; value: string } | null>();
  const [
    newNewSupportedVehicleName1SelectedValue,
    setNewSupportedVehicleName1SelectedValue,
  ] = useState<{ id: string; value: string } | null>();
  const [
    newNewSupportedVehicleName2SelectedValue,
    setNewSupportedVehicleName2SelectedValue,
  ] = useState<{ id: string; value: string } | null>();
  const [
    newNewSupportedVehicleName3SelectedValue,
    setNewSupportedVehicleName3SelectedValue,
  ] = useState<{ id: string; value: string } | null>();

  const [isNewHydrogenMobilityRowValid, setIsNewHydrogenMobilityRowValid] =
    useState(0);
  const [isNewPumpIDValid, setIsNewPumpIDValid] = useState(0);
  const [isNewDispenserIDValid, setIsNewDispenserIDValid] = useState(0);
  const [
    isNewBusinessApplicationNameValid,
    setIsNewBusinessApplicationNameValid,
  ] = useState(0);
  const [isNewFuelPressureValid, setIsNewFuelPressureValid] = useState(0);
  const [isNewSupportedVehicleName1Valid, setIsNewSupportedVehicleName1Valid] =
    useState(0);

  const [
    newHydrogenMobilityRowInvalidText,
    setNewHydrogenMobilityRowInvalidText,
  ] = useState(Locale.common?.required_field);

  const [
    currentStateOfHydrogenMobilityData,
    setCurrentStateOfHydrogenMobilityData,
  ] = useState<TableDetailsViewData>({ tableHeaders: [] });
  const [
    savedStateOfHydrogenMobilityData,
    setSavedStateOfHydrogenMobilityData,
  ] = useState<any>();

  const {
    isLoading: isBusinessApplicationNameCodesLoading,
    data: businessApplicationNameCodes,
    refetch: refetchBusinessApplicationNameCodes,
  } = useBusinessApplicationNameCodesData();

  const {
    isLoading: isFuelPressureCodesLoading,
    data: fuelPressureCodes,
    refetch: refetchFuelPressureCodes,
  } = useFuelPressureCodesData();

  const {
    isLoading: isSupportedVehicleNameCodesLoading,
    data: supportedVehicleNameCodes,
    refetch: refetchSupportedVehicleNameCodes,
  } = useSupportedVehicleNameCodesData();

  useEffect(() => {
    if (evDetailsTabSelected === 3 && isEditing) {
      refetchBusinessApplicationNameCodes();
      refetchFuelPressureCodes();
      refetchSupportedVehicleNameCodes();
    }
  }, [evDetailsTabSelected, isEditing]);

  useEffect(() => {
    if (fetchedHydrogenMobilityData) {
      const defaultHydrogenMobility = fetchedHydrogenMobilityData?.map(
        (item: any, index: number) => {
          return {
            index: index,
            pumpID: item?.pumpID,
            dispenserID: item?.dispenserID,
            businessAppName: item?.businessAppName?.businessAppCd,
            businessAppCd: item?.businessAppName?.businessAppCd,
            fuelPressure: item?.fuelPressure?.fuelPressureCd,
            fuelPressureCd: item?.fuelPressure?.fuelPressureCd,

            SupportedVehicleName1:
              item?.SupportedVehicleName1?.vehicleSupportedCd,
            SupportedVehicleName1Cd:
              item?.SupportedVehicleName1?.vehicleSupportedCd,

            supportedVehicleName2:
              item?.supportedVehicleName2?.vehicleSupportedCd,
            supportedVehicleName2Cd:
              item?.supportedVehicleName2?.vehicleSupportedCd,

            supportedVehicleName3:
              item?.supportedVehicleName3?.vehicleSupportedCd,
            supportedVehicleName3Cd:
              item?.supportedVehicleName3?.vehicleSupportedCd,
          };
        }
      );

      setDefaultData(defaultHydrogenMobility);
    }
  }, [fetchedHydrogenMobilityData]);

  useEffect(() => {
    let list: string[] = [];
    const tbHeaders = [
      {
        label:
          Locale.site_details?.ev_details.hydrogen_section.hydrogen_mobility
            ?.fields?.pump_number,
        field: "pumpID",
        editType: EditFieldTypeConst.TEXT_FIELD,
        onChange: onEditRow,
        maxLength: 10,
        required: true,
      },
      {
        label:
          Locale.site_details?.ev_details.hydrogen_section.hydrogen_mobility
            ?.fields?.dispenser_id,
        field: "dispenserID",
        editType: EditFieldTypeConst.TEXT_FIELD,
        onChange: onEditRow,
        maxLength: 10,
        required: true,
      },
      {
        label:
          Locale.site_details?.ev_details.hydrogen_section.hydrogen_mobility
            ?.fields?.business_application_name,
        field: "businessAppName",
        editType: EditFieldTypeConst.DROPDOWN_FIELD,
        editData: businessApplicationNameCodes,
        onChange: onEditRow,
        required: false,
      },
      {
        label:
          Locale.site_details?.ev_details.hydrogen_section.hydrogen_mobility
            ?.fields?.fuel_pressure,
        field: "fuelPressure",
        editType: EditFieldTypeConst.DROPDOWN_FIELD,
        editData: fuelPressureCodes,
        onChange: onEditRow,
        required: false,
      },
      {
        label: Locale.formatString(
          Locale.site_details?.ev_details.hydrogen_section.hydrogen_mobility
            ?.fields?.supported_vehicle_name,
          1
        ),
        field: "SupportedVehicleName1",
        editType: EditFieldTypeConst.DROPDOWN_FIELD,
        editData: supportedVehicleNameCodes,
        required: false,
        onChange: onEditRow,
      },
      {
        label: Locale.formatString(
          Locale.site_details?.ev_details.hydrogen_section.hydrogen_mobility
            ?.fields?.supported_vehicle_name,
          2
        ),
        field: "vehicleSupportedName2",
        editType: EditFieldTypeConst.DROPDOWN_FIELD,
        editData: supportedVehicleNameCodes,
        required: false,
        onChange: onEditRow,
      },
      {
        label: Locale.formatString(
          Locale.site_details?.ev_details.hydrogen_section.hydrogen_mobility
            ?.fields?.supported_vehicle_name,
          3
        ),
        field: "supportedVehicleName3",
        editType: EditFieldTypeConst.DROPDOWN_FIELD,
        editData: supportedVehicleNameCodes,
        required: false,
        onChange: onEditRow,
      },
      {
        label: "",
        field: "editActionRow",
      },
    ];
    if (isSectionValueChanged && sectionEditedData?.HydrogenMobility) {
      list =
        sectionEditedData?.HydrogenMobility?.data?.map((item: any) => {
          return {
            id: item.id,
            pumpID: item?.pumpID,
            dispenserID: item?.dispenserID,
            businessAppName: item?.businessAppName,
            businessAppCd: item?.businessAppName,
            fuelPressure: item?.fuelPressure,
            fuelPressureCd: item?.fuelPressure,

            SupportedVehicleName1: item?.SupportedVehicleName1,
            SupportedVehicleName1Cd: item?.SupportedVehicleName1,

            vehicleSupportedName2: item?.vehicleSupportedName2,
            vehicleSupportedName2Cd: item?.vehicleSupportedName2,

            supportedVehicleName3: item?.supportedVehicleName3,
            supportedVehicleName3Cd: item?.supportedVehicleName3,

            editActionRow: "",
          };
        }) || [];
    } else {
      list =
        fetchedHydrogenMobilityData?.map((item: any, index: number) => {
          return {
            id: index,

            pumpID: item?.pumpID,
            dispenserID: item?.dispenserID,
            businessAppName: item?.businessAppName?.businessAppCd,
            businessAppCd: item?.businessAppName?.businessAppCd,
            fuelPressure: item?.fuelPressure?.fuelPressureCd,
            fuelPressureCd: item?.fuelPressure?.fuelPressureCd,

            SupportedVehicleName1:
              item?.SupportedVehicleName1?.vehicleSupportedCd,
            SupportedVehicleName1Cd:
              item?.SupportedVehicleName1?.vehicleSupportedCd,

            vehicleSupportedName2:
              item?.vehicleSupportedName2?.vehicleSupportedCd,
            vehicleSupportedName2Cd:
              item?.vehicleSupportedName2?.vehicleSupportedCd,

            supportedVehicleName3:
              item?.supportedVehicleName3?.vehicleSupportedCd,
            supportedVehicleName3Cd:
              item?.supportedVehicleName3?.vehicleSupportedCd,

            editActionRow: "",
          };
        }) || [];
    }
    const hydrogenMobilityData: TableDetailsViewData = {
      tableHeaders: tbHeaders,
      data: list,
    };
    setCurrentStateOfHydrogenMobilityData(hydrogenMobilityData);
    setSavedStateOfHydrogenMobilityData(hydrogenMobilityData);
  }, [
    fetchedHydrogenMobilityData,
    businessApplicationNameCodes,
    fuelPressureCodes,
    supportedVehicleNameCodes,
    isEditing,
    evDetailsTabSelected,
  ]);

  const onAddNewPumpDispenserClick = () => {
    let isValid = true;
    if (newPumpID) {
      setIsNewPumpIDValid(1);
    } else {
      setIsNewPumpIDValid(-1);
    }

    if (newDispenserID) {
      setIsNewDispenserIDValid(1);
    } else {
      setIsNewDispenserIDValid(-1);
    }

    if (newBusinessApplicationNameSelectedValue) {
      setIsNewBusinessApplicationNameValid(1);
    } else {
      setIsNewBusinessApplicationNameValid(-1);
    }

    if (newFuelPressureSelectedValue) {
      setIsNewFuelPressureValid(1);
    } else {
      setIsNewFuelPressureValid(-1);
    }

    if (newNewSupportedVehicleName1SelectedValue) {
      setIsNewSupportedVehicleName1Valid(1);
    } else {
      setIsNewSupportedVehicleName1Valid(-1);
    }

    if (
      newPumpID &&
      newDispenserID &&
      newBusinessApplicationNameSelectedValue &&
      newFuelPressureSelectedValue &&
      newNewSupportedVehicleName1SelectedValue
    ) {
      const findIndex = savedStateOfHydrogenMobilityData?.data?.find(
        (item: { dispenserID: string }) => item.dispenserID === newDispenserID
      );
      if (findIndex?.dispenserID) {
        isValid = false;
        setIsNewHydrogenMobilityRowValid(-1);
        setIsNewDispenserIDValid(-1);
        setNewHydrogenMobilityRowInvalidText(
          Locale.create_site?.error_messages.hydrogen_dispenser_id_unique
        );
      }
    } else {
      isValid = false;
      setIsNewHydrogenMobilityRowValid(-1);
      setNewHydrogenMobilityRowInvalidText(
        Locale.create_site?.error_messages.hydrogen_mobility_required_fields
      );
    }

    if (isValid) {
      setIsNewHydrogenMobilityRowValid(1);
      setIsNewPumpIDValid(1);
      setIsNewDispenserIDValid(1);
      setIsNewBusinessApplicationNameValid(1);
      setIsNewFuelPressureValid(1);
      setIsNewSupportedVehicleName1Valid(1);

      setNewPumpID("");
      setNewDispenserID("");
      setNewBusinessApplicationNameSelectedValue(null);
      setNewFuelPressureSelectedValue(null);
      setNewSupportedVehicleName1SelectedValue(null);
      setNewSupportedVehicleName2SelectedValue(null);
      setNewSupportedVehicleName3SelectedValue(null);

      onSectionValueChanged(true);

      const newData: any = {
        id: new Date().getMilliseconds() + 1000,

        pumpID: newPumpID,

        dispenserID: newDispenserID,

        businessAppName: newBusinessApplicationNameSelectedValue?.value,
        businessAppCd: newBusinessApplicationNameSelectedValue?.id,

        fuelPressure: newFuelPressureSelectedValue?.value,
        fuelPressureCd: newFuelPressureSelectedValue?.id,

        SupportedVehicleName1: newNewSupportedVehicleName1SelectedValue?.value,
        SupportedVehicleName1Cd: newNewSupportedVehicleName1SelectedValue?.id,

        vehicleSupportedName2: newNewSupportedVehicleName2SelectedValue?.value,
        vehicleSupportedName2Cd: newNewSupportedVehicleName2SelectedValue?.id,

        supportedVehicleName3: newNewSupportedVehicleName3SelectedValue?.value,
        supportedVehicleName3Cd: newNewSupportedVehicleName3SelectedValue?.id,

        editActionRow: "",
      };
      const currentData = [...(currentStateOfHydrogenMobilityData?.data || [])];
      currentData.unshift(newData);

      var currentState: any = {
        ...currentStateOfHydrogenMobilityData,
        data: [...currentData],
      };
      setCurrentStateOfHydrogenMobilityData(currentState);
      setSavedStateOfHydrogenMobilityData({
        ...savedStateOfHydrogenMobilityData,
        data: currentState.data,
      });
      onSectionValueSet({
        HydrogenMobility: {
          ...savedStateOfHydrogenMobilityData,
          data: currentState.data,
        },
      });
      const payload = currentState?.data?.map((item: any) => {
        return {
          pumpID: item?.pumpID,
          dispenserID: item?.dispenserID,
          businessAppName: {
            businessAppCd: item?.businessAppName,
          },
          fuelPressure: {
            fuelPressureCd: item?.fuelPressureCd,
          },
          SupportedVehicleName1: {
            vehicleSupportedCd: item?.SupportedVehicleName1Cd,
          },

          ...(item?.vehicleSupportedName2Cd && {
            vehicleSupportedName2: {
              vehicleSupportedCd: item?.vehicleSupportedName2Cd,
            },
          }),

          ...(item?.supportedVehicleName3Cd && {
            supportedVehicleName3: {
              vehicleSupportedCd: item?.supportedVehicleName3Cd,
            },
          }),
        };
      });
      onPatchRequestValueSet(
        {
          HydrogenMobility: {
            item: payload,
          },
        },
        true
      );
    }
    return isValid;
  };

  const onEditClicked = (row: any) => {
    let newTableHeaders = [...currentStateOfHydrogenMobilityData?.tableHeaders];
    const supportedVehicle1SelectedVehicle = row?.SupportedVehicleName1;
    const supportedVehicle2SelectedVehicle = row?.vehicleSupportedName2;
    const supportedVehicle3SelectedVehicle = row?.supportedVehicleName3;

    currentStateOfHydrogenMobilityData?.tableHeaders?.map((header: any) => {
      if (header?.field === "SupportedVehicleName1") {
        header.editData = supportedVehicleNameCodes?.filter(
          (code: { id: string }) =>
            code?.id !== supportedVehicle2SelectedVehicle &&
            code?.id !== supportedVehicle3SelectedVehicle
        );
      } else if (header?.field === "vehicleSupportedName2") {
        header.editData = supportedVehicleNameCodes?.filter(
          (code: { id: string }) =>
            code?.id !== supportedVehicle1SelectedVehicle &&
            code?.id !== supportedVehicle3SelectedVehicle
        );
      } else if (header?.field === "supportedVehicleName3") {
        header.editData = supportedVehicleNameCodes?.filter(
          (code: { id: string }) =>
            code?.id !== supportedVehicle1SelectedVehicle &&
            code?.id !== supportedVehicle2SelectedVehicle
        );
      }
      return header;
    });

    setCurrentStateOfHydrogenMobilityData({
      ...currentStateOfHydrogenMobilityData,
      tableHeaders: newTableHeaders,
    });
  };

  const onEditRow = (currentState: any, event: any, row: any, field: any) => {
    const newCurrentState = [...(currentState?.rows || [])].map((row: any) => {
      const { isValid, validationMessage, ...allData } = row;
      return allData;
    });
    const currentData = [...(newCurrentState || [])];

    const newData = currentData?.map((item: any) => {
      if (row.id === item.id) {
        if (field === "businessAppName") {
          return {
            ...item,
            [field]: event?.value,
            businessAppName: event?.id,
            businessAppCd: event?.id,
          };
        } else if (field === "fuelPressure") {
          return {
            ...item,
            [field]: event?.value,
            fuelPressure: event?.id,
            fuelPressureCd: event?.id,
          };
        } else if (field === "SupportedVehicleName1") {
          return {
            ...item,
            [field]: event?.value,
            SupportedVehicleName1: event?.id,
            SupportedVehicleName1Cd: event?.id,
          };
        } else if (field === "vehicleSupportedName2") {
          return {
            ...item,
            [field]: event?.value,
            vehicleSupportedName2: event?.id,
            vehicleSupportedName2Cd: event?.id,
          };
        } else if (field === "supportedVehicleName3") {
          return {
            ...item,
            [field]: event?.value,
            supportedVehicleName3: event?.id,
            supportedVehicleName3Cd: event?.id,
          };
        }

        return { ...item, [field]: event?.value };
      }
      return { ...item };
    });

    let newTableHeaders = [...currentState?.tableHeaders];

    if (
      field === "SupportedVehicleName1" ||
      field === "vehicleSupportedName2" ||
      field === "supportedVehicleName3"
    ) {
      const currentRow = newData?.find(
        (item: { id: string }) => item?.id === row?.id
      );

      const supportedVehicle1SelectedVehicle =
        currentRow?.SupportedVehicleName1;
      const supportedVehicle2SelectedVehicle =
        currentRow?.vehicleSupportedName2;
      const supportedVehicle3SelectedVehicle =
        currentRow?.supportedVehicleName3;

      if (field === "SupportedVehicleName1") {
        currentState?.tableHeaders?.map((header: any) => {
          if (header?.field === "vehicleSupportedName2") {
            header.editData = supportedVehicleNameCodes?.filter(
              (code: { id: string }) =>
                code?.id !== supportedVehicle1SelectedVehicle &&
                code?.id !== supportedVehicle3SelectedVehicle
            );
          } else if (header?.field === "supportedVehicleName3") {
            header.editData = supportedVehicleNameCodes?.filter(
              (code: { id: string }) =>
                code?.id !== supportedVehicle1SelectedVehicle &&
                code?.id !== supportedVehicle2SelectedVehicle
            );
          }
          return header;
        });
      } else if (field === "vehicleSupportedName2") {
        currentState?.tableHeaders?.map((header: any) => {
          if (header?.field === "SupportedVehicleName1") {
            header.editData = supportedVehicleNameCodes?.filter(
              (code: { id: string }) =>
                code?.id !== supportedVehicle2SelectedVehicle &&
                code?.id !== supportedVehicle3SelectedVehicle
            );
          } else if (header?.field === "supportedVehicleName3") {
            header.editData = supportedVehicleNameCodes?.filter(
              (code: { id: string }) =>
                code?.id !== supportedVehicle1SelectedVehicle &&
                code?.id !== supportedVehicle2SelectedVehicle
            );
          }
          return header;
        });
      } else if (field === "supportedVehicleName3") {
        currentState?.tableHeaders?.map((header: any) => {
          if (header?.field === "SupportedVehicleName1") {
            header.editData = supportedVehicleNameCodes?.filter(
              (code: { id: string }) =>
                code?.id !== supportedVehicle2SelectedVehicle &&
                code?.id !== supportedVehicle3SelectedVehicle
            );
          } else if (header?.field === "vehicleSupportedName2") {
            header.editData = supportedVehicleNameCodes?.filter(
              (code: { id: string }) =>
                code?.id !== supportedVehicle1SelectedVehicle &&
                code?.id !== supportedVehicle3SelectedVehicle
            );
          }
          return header;
        });
      }
    }
    setCurrentStateOfHydrogenMobilityData({
      ...currentState,
      tableHeaders: newTableHeaders,
      data: newData,
    });
  };

  const onDiscardRow = (rowId: string) => {
    const discardRow = [...savedStateOfHydrogenMobilityData?.data]?.filter(
      (row: any) => row.id === rowId
    )?.[0];

    const currentState = currentStateOfHydrogenMobilityData?.data?.map(
      (row: any) => {
        if (row.id === rowId) {
          return discardRow;
        }
        return { ...row };
      }
    );

    const filteredArray = currentState?.filter((row: any) => {
      return row;
    });

    setCurrentStateOfHydrogenMobilityData({
      ...currentStateOfHydrogenMobilityData,
      data: filteredArray,
    });
  };

  const onDeleteRow = (rowId: string) => {
    const currentState = currentStateOfHydrogenMobilityData?.data?.filter(
      (row: any) => {
        return row.id !== rowId;
      }
    );
    setCurrentStateOfHydrogenMobilityData({
      ...currentStateOfHydrogenMobilityData,
      data: currentState,
    });

    const savedState = savedStateOfHydrogenMobilityData?.data?.filter(
      (row: any) => {
        return row.id !== rowId;
      }
    );

    onSectionValueChanged(true);
    setSavedStateOfHydrogenMobilityData({
      ...savedStateOfHydrogenMobilityData,
      data: savedState,
    });
    onSectionValueSet({
      HydrogenMobility: {
        ...savedStateOfHydrogenMobilityData,
        data: savedState,
      },
    });
    const payload = savedState?.map((item: any) => {
      return {
        pumpID: item?.pumpID,
        dispenserID: item?.dispenserID,
        businessAppName: {
          businessAppCd: item?.businessAppName,
        },
        fuelPressure: {
          fuelPressureCd: item?.fuelPressureCd,
        },
        SupportedVehicleName1: {
          vehicleSupportedCd: item?.SupportedVehicleName1Cd,
        },

        ...(item?.vehicleSupportedName2Cd && {
          vehicleSupportedName2: {
            vehicleSupportedCd: item?.vehicleSupportedName2Cd,
          },
        }),

        ...(item?.supportedVehicleName3Cd && {
          supportedVehicleName3: {
            vehicleSupportedCd: item?.supportedVehicleName3Cd,
          },
        }),
      };
    });
    onPatchRequestValueSet(
      {
        HydrogenMobility: {
          item: payload,
        },
      },
      true
    );
  };

  const onSaveRow = (rowId: string) => {
    let isValid: boolean = true;

    const filteredData: any = currentStateOfHydrogenMobilityData?.data?.filter(
      (row: any) => {
        return row.id === rowId;
      }
    )?.[0];
    let newCurrentState: any = [
      ...(currentStateOfHydrogenMobilityData?.data || []),
    ];
    if (!filteredData?.dispenserID || !filteredData?.pumpID) {
      isValid = false;
      newCurrentState = currentStateOfHydrogenMobilityData?.data?.map(
        (row: any) => {
          if (row.id === rowId) {
            return {
              ...row,
              isValid: false,
              validationMessage:
                Locale.create_site?.error_messages
                  .hydrogen_pump_and_dispenser_id_required_field,
            };
          }
          return row;
        }
      );
    } else {
      const existedRecord: any = currentStateOfHydrogenMobilityData?.data?.find(
        (row: any) => {
          return (
            row.dispenserID === filteredData?.dispenserID &&
            row?.id !== filteredData?.id
          );
        }
      );
      if (existedRecord?.dispenserID) {
        isValid = false;
        newCurrentState = currentStateOfHydrogenMobilityData?.data?.map(
          (row: any) => {
            if (row.id === rowId) {
              return {
                ...row,
                isValid: false,
                validationMessage:
                  Locale.create_site?.error_messages
                    .hydrogen_dispenser_id_unique,
              };
            }
            return row;
          }
        );
      }
    }
    setCurrentStateOfHydrogenMobilityData({
      ...currentStateOfHydrogenMobilityData,
      data: newCurrentState,
    });

    if (isValid) {
      const savedState = savedStateOfHydrogenMobilityData?.data?.map(
        (row: any) => {
          if (row.id === rowId) {
            return filteredData;
          }
          return { ...row };
        }
      );

      onSectionValueChanged(true);
      onSectionValueSet({
        HydrogenMobility: {
          ...currentStateOfHydrogenMobilityData,
          data: savedState,
        },
      });
      setSavedStateOfHydrogenMobilityData({
        ...currentStateOfHydrogenMobilityData,
        data: savedState,
      });
      const payload = savedState?.map((item: any) => {
        return {
          pumpID: item?.pumpID,
          dispenserID: item?.dispenserID,
          businessAppName: {
            businessAppCd: item?.businessAppName,
          },
          fuelPressure: {
            fuelPressureCd: item?.fuelPressureCd,
          },
          SupportedVehicleName1: {
            vehicleSupportedCd: item?.SupportedVehicleName1Cd,
          },

          ...(item?.vehicleSupportedName2Cd && {
            vehicleSupportedName2: {
              vehicleSupportedCd: item?.vehicleSupportedName2Cd,
            },
          }),

          ...(item?.supportedVehicleName3Cd && {
            supportedVehicleName3: {
              vehicleSupportedCd: item?.supportedVehicleName3Cd,
            },
          }),
        };
      });
      onPatchRequestValueSet(
        {
          HydrogenMobility: {
            item: payload,
          },
        },
        true
      );
    }
    return isValid;
  };

  const onShowNewRowClick = () => {
    setNewPumpID("");
    setNewDispenserID("");
    setNewBusinessApplicationNameSelectedValue(null);
    setNewFuelPressureSelectedValue(null);
    setNewSupportedVehicleName1SelectedValue(null);
    setNewSupportedVehicleName2SelectedValue(null);
    setNewSupportedVehicleName3SelectedValue(null);
    setIsNewPumpIDValid(0);
    setIsNewDispenserIDValid(0);
    setIsNewBusinessApplicationNameValid(0);
    setIsNewFuelPressureValid(0);
    setIsNewSupportedVehicleName1Valid(0);
    setIsNewHydrogenMobilityRowValid(0);
    setCurrentStateOfHydrogenMobilityData({
      ...currentStateOfHydrogenMobilityData,
      data: savedStateOfHydrogenMobilityData.data,
    });
  };

  const onNewPumpIDChange = (event: InputChangeEvent) => {
    setNewPumpID(event.value);
    setIsNewPumpIDValid(1);
    setIsNewHydrogenMobilityRowValid(1);
  };

  const onNewDispenserIDChange = (event: InputChangeEvent) => {
    setNewDispenserID(event.value);
    setIsNewDispenserIDValid(1);
    setIsNewHydrogenMobilityRowValid(1);
  };

  const onNewBusinessApplicationNameChange = (
    event: DropDownListChangeEvent
  ) => {
    setNewBusinessApplicationNameSelectedValue(event.target.value);
    setIsNewBusinessApplicationNameValid(1);
    setIsNewHydrogenMobilityRowValid(1);
  };

  const onNewFuelPressureChange = (event: DropDownListChangeEvent) => {
    setNewFuelPressureSelectedValue(event.target.value);
    setIsNewFuelPressureValid(1);
    setIsNewHydrogenMobilityRowValid(1);
  };

  const onNewSupportedVehicleName1Change = (event: DropDownListChangeEvent) => {
    setNewSupportedVehicleName1SelectedValue(event.target.value);
    setIsNewSupportedVehicleName1Valid(1);
    setIsNewHydrogenMobilityRowValid(1);
  };

  const onNewSupportedVehicleName2Change = (event: DropDownListChangeEvent) => {
    setNewSupportedVehicleName2SelectedValue(event.target.value);
  };

  const onNewSupportedVehicleName3Change = (event: DropDownListChangeEvent) => {
    setNewSupportedVehicleName3SelectedValue(event.target.value);
  };

  const renderAddNewPumpUI = () => {
    return (
      <>
        <div className="mdm--table-td">
          <Input
            value={newPumpID}
            onChange={onNewPumpIDChange}
            valid={isNewPumpIDValid !== -1}
            maxLength={10}
          />
        </div>
        <div className="mdm--table-td">
          <Input
            value={newDispenserID}
            onChange={onNewDispenserIDChange}
            valid={isNewDispenserIDValid !== -1}
            maxLength={10}
          />
        </div>
        <div className="mdm--table-td">
          {isBusinessApplicationNameCodesLoading ? (
            <Skeleton shape={"text"} style={{ width: "100%" }} />
          ) : (
            <DropDownList
              data={businessApplicationNameCodes}
              value={newBusinessApplicationNameSelectedValue}
              onChange={onNewBusinessApplicationNameChange}
              textField="value"
              dataItemKey="id"
              valid={isNewBusinessApplicationNameValid !== -1 ? true : false}
            />
          )}
        </div>
        <div className="mdm--table-td">
          {isFuelPressureCodesLoading ? (
            <Skeleton shape={"text"} style={{ width: "100%" }} />
          ) : (
            <DropDownList
              data={fuelPressureCodes}
              value={newFuelPressureSelectedValue}
              onChange={onNewFuelPressureChange}
              textField="value"
              dataItemKey="id"
              valid={isNewFuelPressureValid !== -1 ? true : false}
            />
          )}
        </div>
        <div className="mdm--table-td">
          {isSupportedVehicleNameCodesLoading ? (
            <Skeleton shape={"text"} style={{ width: "100%" }} />
          ) : (
            <DropDownList
              data={supportedVehicleNameCodes?.filter(
                (vehicle: { id: string }) =>
                  vehicle.id !== newNewSupportedVehicleName2SelectedValue?.id &&
                  vehicle?.id !== newNewSupportedVehicleName3SelectedValue?.id
              )}
              value={newNewSupportedVehicleName1SelectedValue}
              onChange={onNewSupportedVehicleName1Change}
              textField="value"
              dataItemKey="id"
              valid={isNewSupportedVehicleName1Valid !== -1 ? true : false}
            />
          )}
        </div>
        <div className="mdm--table-td">
          {isSupportedVehicleNameCodesLoading ? (
            <Skeleton shape={"text"} style={{ width: "100%" }} />
          ) : (
            <DropDownList
              data={supportedVehicleNameCodes?.filter(
                (vehicle: { id: string }) =>
                  vehicle.id !== newNewSupportedVehicleName1SelectedValue?.id &&
                  vehicle?.id !== newNewSupportedVehicleName3SelectedValue?.id
              )}
              value={newNewSupportedVehicleName2SelectedValue}
              onChange={onNewSupportedVehicleName2Change}
              textField="value"
              dataItemKey="id"
            />
          )}
        </div>
        <div className="mdm--table-td">
          {isSupportedVehicleNameCodesLoading ? (
            <Skeleton shape={"text"} style={{ width: "100%" }} />
          ) : (
            <DropDownList
              data={supportedVehicleNameCodes?.filter(
                (vehicle: { id: string }) =>
                  vehicle.id !== newNewSupportedVehicleName2SelectedValue?.id &&
                  vehicle?.id !== newNewSupportedVehicleName1SelectedValue?.id
              )}
              value={newNewSupportedVehicleName3SelectedValue}
              onChange={onNewSupportedVehicleName3Change}
              textField="value"
              dataItemKey="id"
            />
          )}
        </div>
      </>
    );
  };

  return (
    <TableDetailsView
      className={"hydrogen-mobility"}
      data={{
        tableHeaders: currentStateOfHydrogenMobilityData.tableHeaders,
        rows: currentStateOfHydrogenMobilityData?.data,
        defaultData: defaultData,
      }}
      isLoading={isHydrogenMobilityDataLoading}
      renderAddNewRowUI={renderAddNewPumpUI}
      deleteRow={onDeleteRow}
      onAddRowClick={onAddNewPumpDispenserClick}
      onEditDiscard={onDiscardRow}
      onSaveRow={onSaveRow}
      onShowNewRowClick={onShowNewRowClick}
      isNewRowValid={isNewHydrogenMobilityRowValid}
      newRowInvalidText={newHydrogenMobilityRowInvalidText}
      onEditClicked={onEditClicked}
      isEditing={isEditing}
      isCreate={isCreate}
    />
  );
};
