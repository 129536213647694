export class SiteTypeConst {
  public static SHELL_IN_NETWORK_RETAIL_SITE = "2";
  public static SHELL_COMMERCIAL_SITE = "3";
  public static COMPETITOR_SITE = "4";
  public static SHELL_LICENSED_MARKET_SITE = "5";
  public static SHELL_CARD_PARTNER_SITE = "6";
}

export class SiteTypeNameConst {
  public static SHELL_IN_NETWORK_RETAIL_SITE = "Shell In Network Retail Site";
  public static SHELL_COMMERCIAL_SITE = "Shell Commercial Site";
  public static SHELL_LICENSED_SITE = "Shell Licensed Market Site";
  public static SHELL_COMPETITOR_SITE = "Competitor Site";
}
