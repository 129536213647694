import React from "react";
import {
  TabStrip,
  TabStripTab,
  TabStripSelectEventArguments,
} from "@progress/kendo-react-layout";
import { Configruration } from "./configration/Configration";
import PreApproval from "./preapproval/PreApproval";
import { ChangeRequestList } from "./change-request-list";

const ChangeRequest = () => {
  const [selected, setSelected] = React.useState<number>(0);

  const handleSelect = (e: TabStripSelectEventArguments) => {
    setSelected(e.selected);
  };

  return (
    <div>
      <TabStrip
        className="mdm--site-list-tabs-change-request"
        style={{ height: "120vh" }}
        selected={selected}
        onSelect={handleSelect}
      >
        <TabStripTab
          title={
            <span className="mdm-change-request-sites-list-tab-title">
              Change Request
            </span>
          }
        >
          <ChangeRequestList />
        </TabStripTab>
        <TabStripTab
          title={
            <span
              className="mdm--lp-sites-list-tab-title"
              data-testid="shell_sites_title"
            >
              Configuration
            </span>
          }
        >
          <Configruration />
        </TabStripTab>
        <TabStripTab
          title={
            <span className="mdm--lp-sites-list-tab-title">Preapproval</span>
          }
        >
          <PreApproval />
        </TabStripTab>
      </TabStrip>
    </div>
  );
};

export default ChangeRequest;
