import { useEffect, useRef, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useQuery } from "react-query";

import {
  Grid,
  GridColumn,
  GridPageChangeEvent,
  GridPagerSettings,
} from "@progress/kendo-react-grid";
import {
  CompositeFilterDescriptor,
  filterBy,
} from "@progress/kendo-data-query";

import _cloneDeep from "lodash/cloneDeep";
import { format } from "date-fns";
import _uniq from "lodash/uniq";
import _map from "lodash/map";

import { Locale } from "../../../utils/localization";
import { Loader } from "../../components";
import { ChangeHistoryDocumentData } from "../../../models";
import { AppConst, APP_ROUTES, WorkFlowActionsConst } from "../../../constants";
import { WorkflowActionsService } from "../../../services/workflow-actions.service";
import { adobeAnalyticsConst } from "../../../constants/adobe-analytics.constant";

import backArrow from "../../../assets/images/back-arrow-icon.svg";

import "./WorkflowActions.scss";

interface AppState {
  items: ChangeHistoryDocumentData[];
  total: number;
  skip: number;
  // pageSize: number;
  pageable: GridPagerSettings;
}
interface TableHeaderType {
  label: string;
  field: string;
  width?: string;
}

export const WorkflowActions = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const userDetails = useSelector((state: any) => state.auth.user);

  const [searchText, setSearchText] = useState("");

  const createState = (skip: number, take: number, data: any[]) => {
    let pagerSettings: GridPagerSettings = {
      buttonCount: 5,
      info: true,
      type: "numeric",
      // pageSizes: true,
      previousNext: true,
    };
    return {
      items: data.slice(skip, skip + take),
      total: data.length,
      skip: skip,
      pageSize: take,
      pageable: pagerSettings,
    };
  };
  const initialFilter: CompositeFilterDescriptor = {
    logic: "or",
    filters: [],
  };
  const [filter, setFilter] = useState(initialFilter);
  const [state, setState] = useState<AppState>(createState(0, 20, []));

  const pageChange = (event: GridPageChangeEvent) => {
    setState(
      createState(event.page.skip, event.page.take, workflowActionsData)
    );
  };

  const isMounted = useRef(false);

  const adminTableHeaders = [
    {
      label: Locale.landing_page?.action_items.table_headers.site_id_label,
      field:
        WorkFlowActionsConst.WORKFLOW_ACTIONS_TABLE_HEADER_KEY.GLOBAL_SITE_ID,
    },
    {
      label: Locale.landing_page?.action_items.table_headers.site_name_label,
      field: WorkFlowActionsConst.WORKFLOW_ACTIONS_TABLE_HEADER_KEY.SITE_NAME,
    },
    {
      label: Locale.landing_page?.action_items.table_headers.country_label,
      field: WorkFlowActionsConst.WORKFLOW_ACTIONS_TABLE_HEADER_KEY.COUNTRY,
    },
    {
      label: Locale.landing_page?.action_items.table_headers.created_by_label,
      field: WorkFlowActionsConst.WORKFLOW_ACTIONS_TABLE_HEADER_KEY.CREATED_BY,
    },
    {
      label:
        Locale.landing_page?.action_items.table_headers.creation_date_label,
      field:
        WorkFlowActionsConst.WORKFLOW_ACTIONS_TABLE_HEADER_KEY.CREATION_DATE,
    },
    {
      label:
        Locale.landing_page?.action_items.table_headers.last_updated_date_label,
      field:
        WorkFlowActionsConst.WORKFLOW_ACTIONS_TABLE_HEADER_KEY.UPDATED_DATE,
    },
    {
      label: Locale.landing_page?.action_items.table_headers.due_by_label,
      field: WorkFlowActionsConst.WORKFLOW_ACTIONS_TABLE_HEADER_KEY.DUE_BY,
    },

    {
      label: Locale.landing_page?.action_items.table_headers.status_label,
      field: WorkFlowActionsConst.WORKFLOW_ACTIONS_TABLE_HEADER_KEY.STATUS,
    },
    {
      label: Locale.landing_page?.action_items.table_headers.comments_label,
      field: WorkFlowActionsConst.WORKFLOW_ACTIONS_TABLE_HEADER_KEY.COMMENTS,
    },
  ];

  const [isRefreshClicked, setIsRefreshClicked] = useState(false);
  const [tableHeaders, setTableHeaders] = useState(adminTableHeaders);
  const [syncOnLastDateTime, setSyncOnLastDateTime] = useState("");

  const fetchWorkflowActionsData = async () => {
    const response = await WorkflowActionsService.getWorkflowList();
    if (response?.data?.workflowTask.length > 0) {
      // setChangeHistoryDataCount(response?.data?.length);
      setState(createState(0, 20, response?.data?.workflowTask));
    }
    // setIsDataLoading(false);
    setSyncOnLastDateTime(response?.data?.syncOn);
    setIsRefreshClicked(false);
    return response?.data?.workflowTask;
  };
  const { isLoading, data: workflowActionsData } = useQuery(
    "workflow-actions-details",
    fetchWorkflowActionsData,
    {
      staleTime: AppConst.VIEW_SITE_DETAILS_STALE_TIME,
      enabled: true,
      refetchOnMount: true,
      refetchOnWindowFocus: false,
    }
  );

  const selectedGlobalCountry = useSelector((state: any) => {
    return state?.selectedGlobalCountry.selectedGlobalCountry;
  });
  useEffect(() => {
    window.adobeDataLayer = window.adobeDataLayer || [];
    window.adobeDataLayer.push({
      event: "Page Loaded",
      metric: "attract",
      timestamp: new Date().toISOString(),
      page: {
        pageName: "Site Data Web | " + pathname.slice(1),
        language: adobeAnalyticsConst.language,
        platform: adobeAnalyticsConst.platform,
        countryCode: selectedGlobalCountry,
        cvp: "Site Data Web",
        siteSection: pathname.slice(1),
      },
      user: {
        userId: userDetails.userLoginId,
        loginStatus: "active",
        userType: userDetails.userType,
        customerRole: userDetails?.roleDetail?.userRoleName,
      },
    });
  }, []);
  useEffect(() => {
    if (workflowActionsData) {
      setState(createState(0, 20, workflowActionsData));
    } else {
      setState(createState(0, 20, []));
    }

    // setState(createState(0, 20, changeHistoryUserData));
  }, []);

  const onBackButtonClicked = () => {
    navigate(APP_ROUTES.DASHBOARD_PATH);
  };

  const sendSiteClickedEventAdobeAnalytics = (clickedGlobalSiteId: any) => {
    window.adobeDataLayer = window.adobeDataLayer || [];
    window.adobeDataLayer.push({
      event: "globalIDClicked",
      globalIDSelected: clickedGlobalSiteId, //'1250983'
      globalIDClickArea: pathname.slice(1), //name of area where tile is clicked for example Home Screen, Reports screen,
      metric: "engage",
      page: {
        pageName: "Site Data Web | " + pathname.slice(1),
        language: adobeAnalyticsConst.language,
        platform: adobeAnalyticsConst.platform,
        countryCode: selectedGlobalCountry,
        cvp: "Site Data Web",
        siteSection: pathname.slice(1),
      },
      user: {
        userId: userDetails.userLoginId,
        loginStatus: "active",
        userType: userDetails.userType,
        customerRole: userDetails?.roleDetail?.userRoleName,
      },
    });
  };
  const renderGridCell = (props: any) => {
    if (
      props.field ===
      WorkFlowActionsConst.WORKFLOW_ACTIONS_TABLE_HEADER_KEY.GLOBAL_SITE_ID
    ) {
      return (
        <td>
          <Link
            to={`${APP_ROUTES.SITES_PATH}/${props.dataItem[props.field]}`}
            onClick={() =>
              sendSiteClickedEventAdobeAnalytics(props.dataItem[props.field])
            }
          >
            {props.dataItem[props.field]}
          </Link>
        </td>
      );
    } else if (
      props.field ===
        WorkFlowActionsConst.WORKFLOW_ACTIONS_TABLE_HEADER_KEY.UPDATED_DATE ||
      props.field ===
        WorkFlowActionsConst.WORKFLOW_ACTIONS_TABLE_HEADER_KEY.CREATION_DATE
    ) {
      return (
        <td
          title={format(
            new Date(props.dataItem[props.field]),
            AppConst.VIEW_DETAIL_DATE_FORMAT
          )}
          className={
            format(
              new Date(props.dataItem[props.field]),
              AppConst.VIEW_DETAIL_DATE_FORMAT
            )
              ? ""
              : "mdm-grid-cell-empty"
          }
        >
          {format(
            new Date(props.dataItem[props.field]),
            AppConst.VIEW_DETAIL_DATE_FORMAT
          )}
        </td>
      );
    } else if (
      props.field ===
      WorkFlowActionsConst.WORKFLOW_ACTIONS_TABLE_HEADER_KEY.DUE_BY
    ) {
      return (
        <td
          title={format(new Date(props.dataItem[props.field]), "dd/MMM/yyyy")}
          className={
            format(new Date(props.dataItem[props.field]), "dd/MMM/yyyy")
              ? ""
              : "mdm-grid-cell-empty"
          }
        >
          {format(new Date(props.dataItem[props.field]), "dd/MMM/yyyy")}
        </td>
      );
    } else if (
      props.field ===
      WorkFlowActionsConst.WORKFLOW_ACTIONS_TABLE_HEADER_KEY.STATUS
    ) {
      return (
        <td className={`mdm--action-item-${props.dataItem[props.field]}`}>
          <span className="mdm-grid-td-text">
            {props.dataItem[props.field]}
          </span>
        </td>
      );
    }
    return (
      <td
        title={props.dataItem[props.field]}
        className={props.dataItem[props.field] ? "" : "mdm-grid-cell-empty"}
      >
        {props.dataItem[props.field] || "--"}
      </td>
    );
  };
  const onSearchTextChange = (event: any) => {
    // setState(createState(0, 20, workflowActionsData));
    // console.log(event.target.value);
    const searchText = event.target.value;
    setSearchText(searchText);
    if (searchText === "") {
      setState(createState(0, 20, workflowActionsData));
    } else {
      const filter: CompositeFilterDescriptor = {
        logic: "or",
        filters: [
          {
            operator: "contains",
            value: searchText,
            field:
              WorkFlowActionsConst.WORKFLOW_ACTIONS_TABLE_HEADER_KEY.SITE_NAME,
          },
          {
            operator: "contains",
            value: searchText,
            field:
              WorkFlowActionsConst.WORKFLOW_ACTIONS_TABLE_HEADER_KEY.COUNTRY,
          },
          {
            operator: "contains",
            value: searchText,
            field:
              WorkFlowActionsConst.WORKFLOW_ACTIONS_TABLE_HEADER_KEY.CREATED_BY,
          },
          {
            operator: "contains",
            value: searchText,
            field:
              WorkFlowActionsConst.WORKFLOW_ACTIONS_TABLE_HEADER_KEY
                .CREATION_DATE,
          },
          {
            operator: "contains",
            value: searchText,
            field:
              WorkFlowActionsConst.WORKFLOW_ACTIONS_TABLE_HEADER_KEY.DUE_BY,
          },
          {
            operator: "contains",
            value: searchText,
            field:
              WorkFlowActionsConst.WORKFLOW_ACTIONS_TABLE_HEADER_KEY.STATUS,
          },
          {
            operator: "contains",
            value: searchText,
            field:
              WorkFlowActionsConst.WORKFLOW_ACTIONS_TABLE_HEADER_KEY.COMMENTS,
          },
        ],
      };
      setFilter(filter);
      const dataFilter = filterBy(workflowActionsData, filter);
      setState(createState(0, 20, dataFilter));
    }
  };

  return (
    <div className="mdm--change-history-container">
      <div className="mdn-change-history-first-row">
        <div
          onClick={onBackButtonClicked}
          className="mdm-change-history-back-arrow"
        >
          {" "}
          <img className="mdm-back-arrow-icon" src={backArrow} />
        </div>
        <div className="mdm-change-history-back-arrow">
          <h5 className="mdm--change-history-container-title mdm--landing-page-item-title">
            {Locale.landing_page?.action_items.action_items_title}
          </h5>
        </div>
      </div>

      <div
        className="mdm--change-history-container-table-container"
        style={{
          height: "84.5vh",
        }}
      >
        <div className="mdm--change-history-container-table-container-first-row">
          <div className="mdm--change-his-search-and-radio">
            <div className="mdm--chg-his-container-colum">
              <input
                type="search"
                onChange={onSearchTextChange}
                placeholder="Search sites"
                className="mdm--ch-serch-bar"
              />
            </div>
            {/* <div className="mdm-row mdm--chg-his-container-colum fild-set-contanert">
              <RadioButton
                name="group1"
                value="Site"
                checked={selectedValue === "Site"}
                label="Site"
                onChange={handleChange}
                size={"large"}
              />
              &ensp; &ensp; &ensp;
              <RadioButton
                name="group1"
                value="User"
                checked={selectedValue === "User"}
                label="User (me)"
                onChange={handleChange}
                size={"large"}
              />
            </div> */}
          </div>
          <div className="mdm-main-container-refresh-button mdm-refresh-label">
            <div>
              <span className="mdm-refresh-label-2">
                {Locale.landing_page?.action_items.last_sync_on_title}:
                {syncOnLastDateTime &&
                  format(
                    new Date(syncOnLastDateTime),
                    AppConst.VIEW_DETAIL_DATE_FORMAT
                  )}
              </span>
            </div>
          </div>
        </div>
        {/* <div className="mdm--change-history-container-table-container-first-row">
        
          <div className="mdm-main-container-refresh-button mdm-refresh-label">
            <div style={{ cursor: "pointer" }} onClick={onRefreshClick}>
              <img
                className="mdm-change-history-refresh-image"
                style={{ color: "#003C88" }}
                src={refreshIcon}
                alt="image"
              />
              <span className="mdm-refresh-label-2">
                {" "}
                {
                  Locale.landing_page?.change_history
                    .change_history_refresh_button_label
                }{" "}
              </span>
            </div>
          </div>
        
        </div> */}

        <div className="mdm--data-grid mdm--landing-page-item-body mdm--sites-list-body">
          {(isLoading || isRefreshClicked) && <Loader />}
          <Grid
            style={{
              maxHeight: " 92%",
            }}
            // data={state.items}
            onPageChange={pageChange}
            total={state.total}
            skip={state.skip}
            pageable={state.pageable}
            // pageSize={state.pageSize}
            data={state.items}
          >
            {tableHeaders.map((item: TableHeaderType, index: number) => {
              return (
                <GridColumn
                  key={index}
                  // width={tableHeaders[index]?.width}
                  field={tableHeaders[index].field}
                  title={tableHeaders[index].label}
                  cell={(props: any) => renderGridCell(props)}
                  // columnMenu={ColumnMenuCheckboxFilter}
                />
              );
            })}
          </Grid>
        </div>
      </div>
    </div>
  );
};
