import { CategoryGraph } from "../../../../components/category";

interface IProps {
  data: { label: string; total: number }[];
  isLoading: boolean;
  totalSitesCount: number;
}

export const CategoryDonutChart = (props: IProps) => {
  const { isLoading, data, totalSitesCount } = props;

  return (
    <CategoryGraph
      data={data}
      isLoading={isLoading}
      totalSitesCount={totalSitesCount}
    />
  );
};
