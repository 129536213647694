import React from "react";
import ReactDOM from "react-dom";

import { Button } from "@progress/kendo-react-buttons";

interface Iprops {
  onClose: () => void;
  showSidePanel: boolean;
  children?: any;
  title: string;
}

export const SidePanel = (props: Iprops) => {
  const { title, onClose, showSidePanel } = props;

  const onChildClick = (e: any) => {
    e.stopPropagation();
  };

  const isDOMLoaded = document.getElementById("portal-root") as HTMLElement;

  return isDOMLoaded ? (
    ReactDOM.createPortal(
      <div
        className={`mdm--sidepanel ${showSidePanel ? "visible" : "hidden"}`}
        onClick={onClose}
      >
        <div
          onClick={onChildClick}
          className={`mdm-sidepanel-body ${
            showSidePanel ? "visible" : "hidden"
          }`}
        >
          <div className="mdm--sidepanel-title mdm--row">
            <h6>{title}</h6>
            <Button icon="close" size={"large"} onClick={onClose} />
          </div>
          {props.children}
        </div>
      </div>,
      document.getElementById("portal-root") as HTMLElement
    )
  ) : (
    <></>
  );
};
