import { useMutation } from "react-query";
import { SitesService } from "../services/sites.service";

const patchSiteDetails = (payload: any) =>
  SitesService.patchSiteDetailsAPI(payload);

const postSiteDetails = (payload: any) =>
  SitesService.postSiteDetailsAPI(payload);

export const usePatchSiteDetailsMutation = () => {
  return useMutation(patchSiteDetails);
};
export const usePostSiteDetailsMutation = () => {
  return useMutation(postSiteDetails);
};
