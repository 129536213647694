import { AppConst } from "../../constants";

const initialState = {
  globalCountry: [AppConst.ALL_SITES],
};

const globalCountryReducer = (
  state = initialState,
  action: { type: string; payload: [string] }
) => {
  switch (action.type) {
    case "SET_GLOBAL_COUNTRY":
      return { ...state, globalCountry: [AppConst.ALL_SITES, ...action.payload] };
    default:
      return state;
  }
};

export default globalCountryReducer;
