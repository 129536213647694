import React, { useEffect, useState } from "react";

import {
  DropDownList,
  DropDownListChangeEvent,
} from "@progress/kendo-react-dropdowns";
import { Skeleton } from "@progress/kendo-react-indicators";
import { Input, InputChangeEvent } from "@progress/kendo-react-inputs";

import {
  AppConst,
  EditFieldTypeConst,
} from "../../../../../../../../constants";
import { useExternalIdTypeCodesData } from "../../../../../../../../hooks";
import { TableDetailsViewData } from "../../../../../../../../models";
import { Locale } from "../../../../../../../../utils/localization";
import { TableDetailsView } from "../../../../../../../components/table-details-view/TableDetailsView";

export const ExternalIdsTab = (props: any) => {
  const {
    isEditing,
    isCreate,
    basicDetailsTabSelected,
    onPatchRequestValueSet,
    isSectionValueChanged,
    onSectionValueChanged,
    sectionEditedData,
    onSectionValueSet,
    classifiedSiteType,
    fetchedExternalIdData,
    isExternalIdDataLoading,
  } = props;

  const [currentStateOfExternalIdData, setCurrentStateOfExternalIdData] =
    useState<TableDetailsViewData>({ tableHeaders: [] });
  const [savedStateOfExternalIdData, setsavedStateOfExternalIdData] =
    useState<any>();

  const [
    newExternalSiteTypeSelectedValue,
    setNewExternalSiteTypeSelectedValue,
  ] = useState<any>();

  const [newExternalSiteId, setNewExternalSiteId] = useState<
    string | undefined
  >();

  const [isNewExternalSiteTypeValid, setIsNewExternalSiteTypeValid] =
    useState(0);
  const [isNewExternalSiteRowValid, setIsNewExternalSiteRowValid] = useState(0);
  const [isNewExternalSiteIdValid, setIsNewExternalSiteIdValid] = useState(0);

  const {
    isLoading: isExternalSiteTypeCodesLoading,
    data: externalSiteTypeCodes,
    refetch: refetchExternalSiteTypeCodes,
  } = useExternalIdTypeCodesData();

  useEffect(() => {
    if (isEditing && basicDetailsTabSelected === 5) {
      refetchExternalSiteTypeCodes();
    }
  }, [isEditing, basicDetailsTabSelected]);

  const [defaultData, setDefaultData] = useState<any>([]);
  useEffect(() => {
    if (fetchedExternalIdData) {
      const defaultFacility =
        fetchedExternalIdData?.data?.result?.SiteExternalId?.item?.map(
          (item: any, index: number) => {
            return {
              index: index,
              externalSiteType:
                item?.externalIdTypeCd?.externalIdTypeName || "-",
              externalIdTypeCd: item?.externalIdTypeCd?.externalIdTypeCd || "-",
              externalSiteId: item.externalSiteId,
            };
          }
        );

      setDefaultData(defaultFacility);
    }
  }, [fetchedExternalIdData]);

  const onNewExternalSiteTypeChange = (event: DropDownListChangeEvent) => {
    setNewExternalSiteTypeSelectedValue(event.target.value);
    setIsNewExternalSiteTypeValid(1);
    setIsNewExternalSiteRowValid(1);
  };

  const onNewExternalSiteIdChange = (event: InputChangeEvent) => {
    setNewExternalSiteId(event.value);
    setIsNewExternalSiteIdValid(1);
    setIsNewExternalSiteRowValid(1);
  };

  const [newExternalSiteRowInvalidText, setNewExternalSiteRowInvalidText] =
    useState("");

  const onAddNewExternalIdClick = () => {
    if (newExternalSiteTypeSelectedValue) {
      setIsNewExternalSiteTypeValid(1);
      setIsNewExternalSiteRowValid(1);
    } else {
      setIsNewExternalSiteTypeValid(-1);
      setIsNewExternalSiteRowValid(-1);
      setNewExternalSiteRowInvalidText(
        Locale.create_site?.error_messages.external_required_fields
      );
    }
    if (newExternalSiteId) {
      setIsNewExternalSiteIdValid(1);
      setIsNewExternalSiteRowValid(1);
    } else {
      setIsNewExternalSiteIdValid(-1);
      setIsNewExternalSiteRowValid(-1);
      setNewExternalSiteRowInvalidText(
        Locale.create_site?.error_messages.external_required_fields
      );
    }

    if (newExternalSiteTypeSelectedValue && newExternalSiteId) {
      const findIndex = savedStateOfExternalIdData?.data?.find((site: any) => {
        return (
          site.externalIdTypeCd === newExternalSiteTypeSelectedValue?.id &&
          site?.externalSiteId === newExternalSiteId
        );
      });

      if (findIndex) {
        setIsNewExternalSiteTypeValid(-1);
        setIsNewExternalSiteIdValid(-1);
        setIsNewExternalSiteRowValid(-1);
        setNewExternalSiteRowInvalidText(
          Locale.create_site?.error_messages.external_already_exists
        );
      } else {
        setIsNewExternalSiteRowValid(1);
        const newData: any = {
          id: new Date().getMilliseconds() + 1000,
          externalSiteType: newExternalSiteTypeSelectedValue?.value || "-",
          externalIdTypeCd: newExternalSiteTypeSelectedValue?.id || "-",
          externalSiteId: newExternalSiteId || "-",
          editActionRow: "",
        };
        const currentData = [...(currentStateOfExternalIdData?.data || [])];
        currentData.unshift(newData);

        var currentState: any = {
          ...currentStateOfExternalIdData,
          data: [...currentData],
        };
        setCurrentStateOfExternalIdData(currentState);
        setNewExternalSiteTypeSelectedValue(null);
        setNewExternalSiteId(undefined);

        onSectionValueChanged(true);
        setsavedStateOfExternalIdData({
          ...savedStateOfExternalIdData,
          data: currentState.data,
        });
        onSectionValueSet({
          SiteExternalId: {
            ...savedStateOfExternalIdData,
            data: currentState.data,
          },
        });

        const payload = currentState?.data?.map((eid: any) => {
          return {
            externalSiteId: eid.externalSiteId,
            externalIdTypeCd: {
              externalIdTypeCd: eid.externalIdTypeCd,
            },
          };
        });
        onPatchRequestValueSet(
          {
            SiteExternalId: {
              item: payload,
            },
          },
          true
        );
        return true;
      }
      return false;
    }
    return false;
  };

  const onEditRow = (currentState: any, event: any, row: any, field: any) => {
    const newCurrentState = [...(currentState?.rows || [])].map((row: any) => {
      const { isValid, validationMessage, ...allData } = row;
      return allData;
    });
    const currentData = [...(newCurrentState || [])];
    const filterData = currentData?.map((item: any) => {
      if (row.id === item.id) {
        if (field === "externalSiteType") {
          return {
            ...item,
            [field]: event?.value,
            externalIdTypeCd: event?.id,
          };
        }
        return { ...item, [field]: event?.value };
      }
      return { ...item };
    });
    setCurrentStateOfExternalIdData({ ...currentState, data: filterData });
  };

  const onDiscardRow = (rowId: string) => {
    const discardRow = [...savedStateOfExternalIdData?.data]?.filter(
      (row: any) => row.id === rowId
    )?.[0];

    const currentState = currentStateOfExternalIdData?.data?.map((row: any) => {
      if (row.id === rowId) {
        return discardRow;
      }
      return { ...row };
    });

    const filteredArray = currentState?.filter((row: any) => {
      return row;
    });
    setCurrentStateOfExternalIdData({
      ...currentStateOfExternalIdData,
      data: filteredArray,
    });
  };

  const onDeleteRow = (rowId: string) => {
    const currentState = currentStateOfExternalIdData?.data?.filter(
      (row: any) => {
        return row.id !== rowId;
      }
    );
    setCurrentStateOfExternalIdData({
      ...currentStateOfExternalIdData,
      data: currentState,
    });

    const savedState = savedStateOfExternalIdData?.data?.filter((row: any) => {
      return row.id !== rowId;
    });

    onSectionValueChanged(true);
    setsavedStateOfExternalIdData({
      ...savedStateOfExternalIdData,
      data: savedState,
    });
    onSectionValueSet({
      SiteExternalId: { ...savedStateOfExternalIdData, data: savedState },
    });
    const payload = savedState?.map((eid: any) => {
      return {
        externalSiteId: eid.externalSiteId,
        externalIdTypeCd: {
          externalIdTypeCd: eid.externalIdTypeCd,
        },
      };
    });
    onPatchRequestValueSet(
      {
        SiteExternalId: {
          item: payload,
        },
      },
      true
    );
  };

  const onSaveRow = (rowId: string) => {
    let isValid: boolean = true;

    const filteredData: any = currentStateOfExternalIdData?.data?.filter(
      (row: any) => {
        return row.id === rowId;
      }
    )?.[0];

    let newCurrentState: any = [...(currentStateOfExternalIdData?.data || [])];

    if (!filteredData?.externalSiteId) {
      isValid = false;
      newCurrentState = currentStateOfExternalIdData?.data?.map((row: any) => {
        if (row.id === rowId) {
          return {
            ...row,
            isValid: false,
            validationMessage:
              Locale.create_site?.error_messages.external_required_fields,
          };
        }
        return row;
      });
    } else {
      const existedRecord: any = currentStateOfExternalIdData?.data?.find(
        (row: any) => {
          return (
            row.externalSiteId === filteredData?.externalSiteId &&
            row.externalSiteTypeCd === filteredData?.externalSiteTypeCd &&
            row?.id !== filteredData?.id
          );
        }
      );

      if (existedRecord?.externalSiteId) {
        isValid = false;
        newCurrentState = currentStateOfExternalIdData?.data?.map(
          (row: any) => {
            if (row.id === rowId) {
              return {
                ...row,
                isValid: false,
                validationMessage:
                  Locale.create_site?.error_messages.external_already_exists,
              };
            }
            return row;
          }
        );
      }
    }

    setCurrentStateOfExternalIdData({
      ...currentStateOfExternalIdData,
      data: newCurrentState,
    });

    if (isValid) {
      const savedState = savedStateOfExternalIdData?.data?.map((row: any) => {
        if (row.id === rowId) {
          return filteredData;
        }
        return { ...row };
      });

      onSectionValueChanged(true);
      onSectionValueSet({
        SiteExternalId: {
          ...currentStateOfExternalIdData,
          data: savedState,
        },
      });
      setsavedStateOfExternalIdData({
        ...currentStateOfExternalIdData,
        data: savedState,
      });
      const payload = savedState?.map((eid: any) => {
        return {
          externalSiteId: eid.externalSiteId,
          externalIdTypeCd: {
            externalIdTypeCd: eid.externalIdTypeCd,
          },
        };
      });
      onPatchRequestValueSet(
        {
          SiteExternalId: {
            item: payload,
          },
        },
        true
      );
    }
    return isValid;
  };

  const onShowNewRowClick = () => {
    setIsNewExternalSiteIdValid(1);
    setIsNewExternalSiteRowValid(1);
    setIsNewExternalSiteTypeValid(1);
    setNewExternalSiteTypeSelectedValue(undefined);
    setNewExternalSiteId("");
    setCurrentStateOfExternalIdData({
      ...currentStateOfExternalIdData,
      data: savedStateOfExternalIdData.data,
    });
  };

  const renderAddNewExternalIdUI = () => {
    return (
      <>
        <div className="mdm--table-td">
          {isExternalSiteTypeCodesLoading ? (
            <Skeleton shape={"text"} style={{ width: "100%" }} />
          ) : (
            <DropDownList
              data={externalSiteTypeCodes}
              value={newExternalSiteTypeSelectedValue}
              onChange={onNewExternalSiteTypeChange}
              textField="value"
              dataItemKey="id"
              valid={isNewExternalSiteTypeValid !== -1 ? true : false}
            />
          )}
        </div>
        <div className="mdm--table-td">
          <Input
            value={newExternalSiteId}
            onChange={onNewExternalSiteIdChange}
            valid={isNewExternalSiteIdValid !== -1 ? true : false}
            maxLength={80}
          />
        </div>
      </>
    );
  };

  useEffect(() => {
    let list: string[] = [];
    const tbHeaders = [
      {
        label: Locale.site_details?.ev_details.External_ids.external_site_type,
        field: "externalSiteType",
        editType: EditFieldTypeConst.DROPDOWN_FIELD,
        editData: externalSiteTypeCodes,
        onChange: onEditRow,
      },
      {
        label: Locale.site_details?.ev_details.External_ids.external_site_id,
        field: "externalSiteId",
        editType: EditFieldTypeConst.TEXT_FIELD,
        maxLength: 80,
        onChange: onEditRow,
      },
      {
        label: "",
        field: "editActionRow",
      },
    ];
    if (isSectionValueChanged && sectionEditedData?.SiteExternalId) {
      list =
        sectionEditedData?.SiteExternalId?.data?.map((item: any) => {
          return {
            id: item.id,
            externalSiteType: item.externalSiteType || "-",
            externalIdTypeCd: item.externalIdTypeCd || "-",
            externalSiteId: item.externalSiteId,
            editActionRow: "",
          };
        }) || [];
    } else {
      list =
        fetchedExternalIdData?.data?.result?.SiteExternalId?.item?.map(
          (item: any, index: number) => {
            return {
              id: index,

              externalSiteType:
                item?.externalIdTypeCd?.externalIdTypeName || "-",
              externalIdTypeCd: item?.externalIdTypeCd?.externalIdTypeCd || "-",
              externalSiteId: item.externalSiteId,
              editActionRow: "",
            };
          }
        ) || [];
    }
    const externalIdData: TableDetailsViewData = {
      tableHeaders: tbHeaders,
      data: list,
    };
    setCurrentStateOfExternalIdData(externalIdData);
    setsavedStateOfExternalIdData(externalIdData);
  }, [
    fetchedExternalIdData,
    externalSiteTypeCodes,
    isEditing,
    basicDetailsTabSelected,
  ]);

  return (
    <TableDetailsView
      className={"external-ids"}
      data={{
        tableHeaders: currentStateOfExternalIdData.tableHeaders,
        rows: currentStateOfExternalIdData?.data,
        defaultData: defaultData,
      }}
      isLoading={isExternalIdDataLoading}
      isEditing={isEditing}
      // isEditing={
      //   classifiedSiteType === AppConst.GSAP_SITE_TYPE ? false : isEditing
      // }
      renderAddNewRowUI={renderAddNewExternalIdUI}
      deleteRow={onDeleteRow}
      onAddRowClick={onAddNewExternalIdClick}
      onEditDiscard={onDiscardRow}
      onSaveRow={onSaveRow}
      onShowNewRowClick={onShowNewRowClick}
      isNewRowValid={isNewExternalSiteRowValid}
      newRowInvalidText={newExternalSiteRowInvalidText}
      isCreate={isCreate}
    />
  );
};
