

import { useQuery } from "react-query";
import { AppConst } from "../constants";
import { ChangeHistoryService } from "../services/change-history.service";

export const useChangeHistoryData = (payload: any) => {
  return useQuery(
    [`change-history-data`],
    () => ChangeHistoryService.getChangeHistoryList(payload),
    {
      staleTime: AppConst.VIEW_SITE_DETAILS_STALE_TIME,
      enabled: true,
      refetchOnMount: true,
      refetchOnWindowFocus: false,
    }
  );
};

export const useChangeHistoryByUserData = (payload: any) => {
  return useQuery(
    [`change-history-by-user-data`],
    () => ChangeHistoryService.getChangeHistoryByUser(payload),
    {
      staleTime: AppConst.VIEW_SITE_DETAILS_STALE_TIME,
      enabled: true,
      refetchOnMount: true,
      refetchOnWindowFocus: false,
    }
  );
};
