export const APP_ROUTES = {
  ROOT_PATH: "/",
  LEADERSHIP_DASHBOARD_PATH: "/home",
  DASHBOARD_PATH: "/dashboard",
  LOGIN_PATH: "/login",
  SITES_PATH: "/sites",
  SITE_DETAILS: ":siteId",
  CHANGE_HISTORY_PATH: "/changehistory",
  CREATE_SITE: "/create-site",
  WORKFLOW_ACTIONS_PATH: "/workflow-actions",
  RSMA_CONFIGURATION_PATH: "/smsm-config",
};
