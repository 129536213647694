import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";

import {
  TabStrip,
  TabStripSelectEventArguments,
  TabStripTab,
} from "@progress/kendo-react-layout";
import { Tooltip } from "@progress/kendo-react-tooltip";

import {
  CurrenciesTab,
  CustomerMissionTab,
  EnvironmentalTab,
  EVChargePostsTab,
  EVOverviewTab,
  HydrogenMobility,
  HydrogenOverview,
  LocalCustomFieldsTab,
  SiteContactTab,
} from "./components";
import { AppConst, RoleConst } from "../../../../../../constants";
import { Locale } from "../../../../../../utils/localization";
import { SitesService } from "../../../../../../services/sites.service";

import "./EVSiteDetails.scss";
interface IProps {
  siteId: string;
  basicSiteDetails: any;
  isBasicSiteDetailsLoading: boolean;
  isEditing: boolean;
  onBasicDetailsPatchDataValueSet: any;
  onBasicDetailsEditedDataValueSet: any;
  sectionEditedData: any;
  ref?: any;
  isCreate: boolean;
  classifiedSiteType: string;
  refetchAllData?: boolean;
}

const fetchSiteEVChargePostData = ({ queryKey }: any) => {
  const siteId = queryKey[1];
  return SitesService.getSiteDetails({
    globalSiteId: siteId || "",
    viewName: "EvchargerResult",
  });
};

const fetchHydrogenOverviewData = ({ queryKey }: any) => {
  const siteId = queryKey[1];
  return SitesService.getSiteDetails({
    globalSiteId: siteId || "",
    viewName: "HydrogenOverviewResult",
  });
};

const fetchHydrogenMobilityData = ({ queryKey }: any) => {
  const siteId = queryKey[1];
  return SitesService.getSiteDetails({
    globalSiteId: siteId || "",
    viewName: "HydrogenMobilityResult",
  });
};

const fetchSiteMarketTypeData = ({ queryKey }: any) => {
  const siteId = queryKey[1];
  return SitesService.getSiteDetails({
    globalSiteId: siteId || "",
    viewName: "SiteMarketTypeResult",
  });
};

const fetchSiteContactData = ({ queryKey }: any) => {
  const siteId = queryKey[1];
  return SitesService.getSiteDetails({
    globalSiteId: siteId || "",
    viewName: "SiteEmployeeResult",
  });
};

const fetchCurrencyData = ({ queryKey }: any) => {
  const siteId = queryKey[1];
  return SitesService.getSiteDetails({
    globalSiteId: siteId || "",
    viewName: "SiteCurrencyResult",
  });
};

export const EVSiteDetails = React.memo(
  forwardRef((props: IProps, ref) => {
    const {
      siteId,
      basicSiteDetails,
      isBasicSiteDetailsLoading,
      isEditing,
      onBasicDetailsPatchDataValueSet,
      onBasicDetailsEditedDataValueSet,
      sectionEditedData,
      isCreate,
      classifiedSiteType,
      refetchAllData,
    } = props;

    const userDetails = useSelector((state: any) => state?.auth?.user);
    const roleName = userDetails?.roleDetail?.userRoleName;

    const [evDetailsTabSelected, setEVDetailsTabSelected] = useState<
      number | undefined
    >();

    const [isEvOverviewValueChanged, setIsEvOverviewValueChanged] =
      useState(false);

    const [isEVOverviewSectionValid, setIsEVOverviewSectionValid] = useState(0);

    const [isHydrogenOverviewSectionValid, setIsHydrogenOverviewSectionValid] =
      useState(0);

    const {
      isLoading: isEVChargePostDataLoading,
      isRefetching: isEVChargePostDatarefetching,
      data: fetchedEVChargePostData,
      refetch: refetchEVChargePostData,
    } = useQuery(
      [`site-ev-charge-post-data-${siteId}`, siteId],
      fetchSiteEVChargePostData,
      {
        staleTime: AppConst.VIEW_SITE_DETAILS_STALE_TIME,
        enabled: false,
        refetchOnMount: false,
        refetchOnWindowFocus: false,
        select: (data) => {
          const types = data?.data?.result?.EVCharger?.item?.map(
            (item: any) => {
              return {
                evseId: item.evseId,
                deleteInd: item.deleteInd,
                ocpiEvseId: item.ocpiEvseId,
                chargepostId: item.chargepostId,
                countryCd: item.countryCd.countryCd,
                countryName: item.countryCd.countryName,
                evChargerOperatorCd:
                  item.evChargerOperatorCd.evChargerOperatorCd,
                evChargerBrandCd: item.evChargerBrandCd.evChargerBrandCd,
                EVChargerConnector: item.EVChargerConnector.item,
                externalEvseId: item.externalEvseId,
                latitude :item.latitude,
                longitude : item.longitude
              };
            }
          );
          return types;
        },
      }
    );

    const {
      isLoading: isHydrogenOverviewDataLoading,
      data: fetchedHydrogenOverviewData,
      refetch: refetchHydrogenOverviewData,
    } = useQuery(
      [`hydrogen-overview-data-${siteId}`, siteId],
      fetchHydrogenOverviewData,
      {
        staleTime: AppConst.VIEW_SITE_DETAILS_STALE_TIME,
        enabled: false,
        refetchOnMount: false,
        refetchOnWindowFocus: false,
      }
    );

    const {
      isLoading: isHydrogenMobilityDataLoading,
      data: fetchedHydrogenMobilityData,
      refetch: refetchHydrogenMobilityData,
    } = useQuery(
      [`hydrogen-mobility-data-${siteId}`, siteId],
      fetchHydrogenMobilityData,
      {
        staleTime: AppConst.VIEW_SITE_DETAILS_STALE_TIME,
        enabled: false,
        refetchOnMount: false,
        refetchOnWindowFocus: false,
      }
    );

    const {
      isLoading: isLocalMarketTypeDataLoading,
      data: fetchedLocalMarketTypeData,
      refetch: refetchLocalMarketTypeData,
    } = useQuery(
      [`site-market-type-data-${siteId}`, siteId],
      fetchSiteMarketTypeData,
      {
        staleTime: AppConst.VIEW_SITE_DETAILS_STALE_TIME,
        enabled: false,
        refetchOnMount: false,
        refetchOnWindowFocus: false,
      }
    );

    const {
      isLoading: isSiteContactDataLoading,
      data: fetchedSiteContactData,
      refetch: refetchSiteContactData,
    } = useQuery(
      [`site-contact-data-${siteId}`, siteId],
      fetchSiteContactData,
      {
        staleTime: AppConst.VIEW_SITE_DETAILS_STALE_TIME,
        enabled: false,
        refetchOnMount: false,
        refetchOnWindowFocus: false,
      }
    );

    const {
      isLoading: isCurrencyDataLoading,
      data: fetchedCurrencyData,
      refetch: refetchCurrencyData,
    } = useQuery([`site-currency-data-${siteId}`, siteId], fetchCurrencyData, {
      staleTime: AppConst.VIEW_SITE_DETAILS_STALE_TIME,
      enabled: false,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    });

    useImperativeHandle(ref, () => ({
      triggerValidations(): boolean {
        let isValid = true;
        if (
          isEVOverviewSectionValid === -1 &&
          (classifiedSiteType === AppConst.GSAP_SITE_TYPE ||
            classifiedSiteType === AppConst.NON_GSAP_SITE_TYPE )
        ) {
          isValid = false;
        }
        if (
          isHydrogenOverviewSectionValid === -1 &&
          (classifiedSiteType === AppConst.GSAP_SITE_TYPE ||
            classifiedSiteType === AppConst.NON_GSAP_SITE_TYPE)
        ) {
          isValid = false;
        }
        return isValid;
      },
    }));

    useEffect(() => {
      if (refetchAllData && siteId) {
        if (evDetailsTabSelected === 1) {
          refetchEVChargePostData();
        } else if (evDetailsTabSelected === 2) {
          refetchHydrogenOverviewData();
        } else if (evDetailsTabSelected === 3) {
          refetchHydrogenMobilityData();
        } else if (evDetailsTabSelected === 5) {
          refetchLocalMarketTypeData();
        } else if (evDetailsTabSelected === 6) {
          refetchSiteContactData();
        } else if (evDetailsTabSelected === 7) {
          refetchCurrencyData();
        }
      }
    }, [refetchAllData, evDetailsTabSelected]);

    useEffect(() => {
      if (siteId) {
        if (evDetailsTabSelected === 1) {
          refetchEVChargePostData();
        } else if (evDetailsTabSelected === 2) {
          refetchHydrogenOverviewData();
        } else if (evDetailsTabSelected === 3) {
          refetchHydrogenMobilityData();
        } else if (evDetailsTabSelected === 5) {
          refetchLocalMarketTypeData();
        } else if (evDetailsTabSelected === 6) {
          refetchSiteContactData();
        } else if (evDetailsTabSelected === 7) {
          refetchCurrencyData();
        }
      }
    }, [evDetailsTabSelected]);

    useEffect(() => {
      if (classifiedSiteType) {
        if (
          classifiedSiteType === AppConst.COMPETITOR_SITE_TYPE ||
          classifiedSiteType === AppConst.PARTNER_SITE_TYPE
        ) {
          setEVDetailsTabSelected(7);
        } else if (
          classifiedSiteType === AppConst.GSAP_SITE_TYPE ||
          classifiedSiteType === AppConst.NON_GSAP_SITE_TYPE
        ) {
          setEVDetailsTabSelected(0);
        } else {
          setEVDetailsTabSelected(undefined);
        }
      }
    }, [classifiedSiteType]);

    const handleEVDetailsTabSelect = (event: TabStripSelectEventArguments) => {
      setEVDetailsTabSelected(event.selected);
    };

    const onEvOverviewValueChanged = (flag: boolean) => {
      setIsEvOverviewValueChanged(flag);
    };

    const onEVOverviewTabValidChange = (isValid: any) => {
      setIsEVOverviewSectionValid(isValid);
    };

    const onHydrogenOverviewValidValueChange = (isValid: number) => {
      setIsHydrogenOverviewSectionValid(isValid);
    };

    return (
      <div className="mdm--site-detail ev-section-container">
        <TabStrip
          selected={evDetailsTabSelected}
          onSelect={handleEVDetailsTabSelect}
          className={"mdm--sd-details-child-tab"}
        >
          <TabStripTab
            title={
              <>
                <span>
                  {
                    Locale.site_details?.ev_details.ev_overview
                      .ev_overview_label
                  }
                </span>
                {isEVOverviewSectionValid === -1 &&
                  isEditing &&
                  (classifiedSiteType === AppConst.GSAP_SITE_TYPE ||
                    classifiedSiteType === AppConst.NON_GSAP_SITE_TYPE) && (
                    <Tooltip anchorElement="target" position="top">
                      <div className="tab-error-icon">
                        <span
                          title={
                            Locale.create_site?.error_messages?.invalid_data
                          }
                          className="k-icon k-i-warning"
                        />
                      </div>
                    </Tooltip>
                  )}
              </>
            }
            disabled={
              !classifiedSiteType ||
              (classifiedSiteType !== AppConst.GSAP_SITE_TYPE &&
                classifiedSiteType !== AppConst.NON_GSAP_SITE_TYPE &&
                classifiedSiteType !== AppConst.RBLA_SITE_TYPE
              )
            }
          >
            <EVOverviewTab
              siteId={siteId}
              isEditing={isEditing}
              evDetailsTabSelected={evDetailsTabSelected}
              basicSiteDetails={basicSiteDetails}
              isBasicSiteDetailsLoading={isBasicSiteDetailsLoading}
              onPatchRequestValueSet={onBasicDetailsPatchDataValueSet}
              onSectionValueSet={onBasicDetailsEditedDataValueSet}
              isSectionValueChanged={isEvOverviewValueChanged}
              onSectionValueChanged={onEvOverviewValueChanged}
              sectionEditedData={sectionEditedData}
              isCreate={isCreate}
              classifiedSiteType={classifiedSiteType}
              setEVOverviewTabValid={onEVOverviewTabValidChange}
            />
            {!isCreate && (
              <p className="ev-notification-text">
                <b>Note</b>
                {Locale.site_details?.ev_details.ev_save_notification_text}
              </p>
            )}
          </TabStripTab>
          <TabStripTab
            title={
              Locale.site_details?.ev_details.ev_charge_posts
                .ev_charge_posts_label
            }
            disabled={
              !classifiedSiteType ||
              (classifiedSiteType !== AppConst.GSAP_SITE_TYPE &&
                classifiedSiteType !== AppConst.NON_GSAP_SITE_TYPE &&
                classifiedSiteType !== AppConst.RBLA_SITE_TYPE
              )
            }
          >
            <EVChargePostsTab
              siteId={siteId}
              isEditing={isEditing}
              evDetailsTabSelected={evDetailsTabSelected}
              onPatchRequestValueSet={onBasicDetailsPatchDataValueSet}
              isSectionValueChanged={isEvOverviewValueChanged}
              onSectionValueChanged={onEvOverviewValueChanged}
              sectionEditedData={sectionEditedData}
              onSectionValueSet={onBasicDetailsEditedDataValueSet}
              isCreate={isCreate}
              classifiedSiteType={classifiedSiteType}
              fetchedEVChargePostData={fetchedEVChargePostData}
              refetchAllData={refetchAllData}
              isEVChargePostDataLoading={
                isEVChargePostDataLoading || isEVChargePostDatarefetching
              }
            />
            {!isCreate && (
              <p className="ev-notification-text">
                <b>Note</b>
                {Locale.site_details?.ev_details.ev_save_notification_text}
              </p>
            )}
          </TabStripTab>

          <TabStripTab
            title={
              <>
                <span>
                  {
                    Locale.site_details?.ev_details.hydrogen_section
                      .hydrogen_overview.hydrogen_overview_title
                  }
                </span>
                {isHydrogenOverviewSectionValid === -1 &&
                  isEditing &&
                  (classifiedSiteType === AppConst.GSAP_SITE_TYPE ||
                    classifiedSiteType === AppConst.NON_GSAP_SITE_TYPE) && (
                    <Tooltip anchorElement="target" position="top">
                      <div className="tab-error-icon">
                        <span
                          title={
                            Locale.create_site?.error_messages?.invalid_data
                          }
                          className="k-icon k-i-warning"
                        />
                      </div>
                    </Tooltip>
                  )}
              </>
            }
            disabled={
              !classifiedSiteType ||
              (classifiedSiteType !== AppConst.GSAP_SITE_TYPE &&
                classifiedSiteType !== AppConst.NON_GSAP_SITE_TYPE &&
                classifiedSiteType !== AppConst.RBLA_SITE_TYPE)
            }
          >
            <HydrogenOverview
              //  siteId={siteId}
              isCreate={isCreate}
              isEditing={isEditing}
              evDetailsTabSelected={evDetailsTabSelected}
              onPatchRequestValueSet={onBasicDetailsPatchDataValueSet}
              onSectionValueSet={onBasicDetailsEditedDataValueSet}
              isSectionValueChanged={isEvOverviewValueChanged}
              onSectionValueChanged={onEvOverviewValueChanged}
              sectionEditedData={sectionEditedData}
              fetchedHydrogenOverviewData={
                fetchedHydrogenOverviewData?.data?.result
              }
              isHydrogenOverviewDataLoading={isHydrogenOverviewDataLoading}
              setHydrogenOverviewSectionValid={
                onHydrogenOverviewValidValueChange
              }
            />
            {!isCreate && (
              <p className="ev-notification-text">
                <b>{Locale?.common?.note + " "}</b>
                {Locale.site_details?.ev_details.hy_save_notification_text}
              </p>
            )}
          </TabStripTab>

          <TabStripTab
            title={
              Locale.site_details?.ev_details.hydrogen_section.hydrogen_mobility
                .hydrogen_mobility_title
            }
            disabled={
              !classifiedSiteType ||
              (classifiedSiteType !== AppConst.GSAP_SITE_TYPE &&
                classifiedSiteType !== AppConst.NON_GSAP_SITE_TYPE &&
                classifiedSiteType !== AppConst.RBLA_SITE_TYPE)
            }
          >
            <HydrogenMobility
              //  siteId={siteId}
              isCreate={isCreate}
              isEditing={isEditing}
              evDetailsTabSelected={evDetailsTabSelected}
              onPatchRequestValueSet={onBasicDetailsPatchDataValueSet}
              onSectionValueSet={onBasicDetailsEditedDataValueSet}
              isSectionValueChanged={isEvOverviewValueChanged}
              onSectionValueChanged={onEvOverviewValueChanged}
              sectionEditedData={sectionEditedData}
              fetchedHydrogenMobilityData={
                fetchedHydrogenMobilityData?.data?.result?.HydrogenMobility
                  ?.item
              }
              isHydrogenMobilityDataLoading={isHydrogenMobilityDataLoading}
              //  classifiedSiteType={classifiedSiteType}
            />
            
          </TabStripTab>           
          <TabStripTab
            title={
              Locale.site_details?.ev_details.environmental.environmental_label
            }
            disabled={
              !classifiedSiteType ||
              (classifiedSiteType !== AppConst.GSAP_SITE_TYPE &&
                classifiedSiteType !== AppConst.NON_GSAP_SITE_TYPE &&
                classifiedSiteType !== AppConst.RBLA_SITE_TYPE)
            }
          >
            <EnvironmentalTab
              siteId={siteId}
              isEditing={isEditing}
              evDetailsTabSelected={evDetailsTabSelected}
              basicSiteDetails={basicSiteDetails}
              isBasicSiteDetailsLoading={isBasicSiteDetailsLoading}
              onPatchRequestValueSet={onBasicDetailsPatchDataValueSet}
              onSectionValueSet={onBasicDetailsEditedDataValueSet}
              isSectionValueChanged={isEvOverviewValueChanged}
              onSectionValueChanged={onEvOverviewValueChanged}
              sectionEditedData={sectionEditedData}
              isCreate={isCreate}
              classifiedSiteType={classifiedSiteType}
            />
          </TabStripTab>
          
          <TabStripTab
            title={
              Locale.site_details?.ev_details.local_custom_fields
                .local_custom_fields_label
            }
            disabled={
              !classifiedSiteType ||
              (classifiedSiteType !== AppConst.GSAP_SITE_TYPE &&
                classifiedSiteType !== AppConst.NON_GSAP_SITE_TYPE &&
                classifiedSiteType !== AppConst.RBLA_SITE_TYPE
                
              )
            }
          >
            
            <LocalCustomFieldsTab
              siteId={siteId}
              isEditing={isEditing}
              evDetailsTabSelected={evDetailsTabSelected}
              basicSiteDetails={basicSiteDetails}
              isBasicSiteDetailsLoading={isBasicSiteDetailsLoading}
              onPatchRequestValueSet={onBasicDetailsPatchDataValueSet}
              isSectionValueChanged={isEvOverviewValueChanged}
              onSectionValueChanged={onEvOverviewValueChanged}
              sectionEditedData={sectionEditedData}
              onSectionValueSet={onBasicDetailsEditedDataValueSet}
              isCreate={isCreate}
              classifiedSiteType={classifiedSiteType}
              fetchedLocalMarketTypeData={fetchedLocalMarketTypeData}
              isLocalMarketTypeDataLoading={isLocalMarketTypeDataLoading}
            />
          </TabStripTab>
          <TabStripTab
            title={
              Locale.site_details?.ev_details.site_contact.site_contact_label
            }
            disabled={
              !classifiedSiteType ||
              (classifiedSiteType !== AppConst.GSAP_SITE_TYPE &&
                classifiedSiteType !== AppConst.NON_GSAP_SITE_TYPE) ||
              roleName === RoleConst.GI_ACCESS_USER
            }
          >
            <SiteContactTab
              siteId={siteId}
              isEditing={isEditing}
              evDetailsTabSelected={evDetailsTabSelected}
              basicSiteDetails={basicSiteDetails}
              isBasicSiteDetailsLoading={isBasicSiteDetailsLoading}
              onPatchRequestValueSet={onBasicDetailsPatchDataValueSet}
              isSectionValueChanged={isEvOverviewValueChanged}
              onSectionValueChanged={onEvOverviewValueChanged}
              sectionEditedData={sectionEditedData}
              onSectionValueSet={onBasicDetailsEditedDataValueSet}
              isCreate={isCreate}
              classifiedSiteType={classifiedSiteType}
              fetchedSiteContactData={fetchedSiteContactData}
              isSiteContactDataLoading={isSiteContactDataLoading}
            />
          </TabStripTab>

          <TabStripTab
            title={Locale.site_details?.ev_details.currency.currency_label}
            disabled={
              !classifiedSiteType ||(
              classifiedSiteType !== AppConst.RBLA_SITE_TYPE)
            }
          >
            <CurrenciesTab
              siteId={siteId}
              isEditing={isEditing}
              evDetailsTabSelected={evDetailsTabSelected}
              basicSiteDetails={basicSiteDetails}
              isBasicSiteDetailsLoading={isBasicSiteDetailsLoading}
              onPatchRequestValueSet={onBasicDetailsPatchDataValueSet}
              isSectionValueChanged={isEvOverviewValueChanged}
              onSectionValueChanged={onEvOverviewValueChanged}
              sectionEditedData={sectionEditedData}
              onSectionValueSet={onBasicDetailsEditedDataValueSet}
              isCreate={isCreate}
              classifiedSiteType={classifiedSiteType}
              fetchedCurrencyData={fetchedCurrencyData}
              isCurrencyDataLoading={isCurrencyDataLoading}
            />
          </TabStripTab>
         
        </TabStrip>
        {isCreate && (
          <div className="notification-message">{`${
            !classifiedSiteType ? Locale.create_site?.select_site_type : ""
          }`}</div>
        )}
      </div>
    );
  })
);
