import React, { useEffect, useState } from "react";

import { DropDownListChangeEvent } from "@progress/kendo-react-dropdowns";
import { NumericTextBoxChangeEvent } from "@progress/kendo-react-inputs";

import {
  useCoreStatusCodesData,
  useNetworkSegmentCodesData,
  useRoadTypeCodesData,
} from "../../../../../../../../hooks";
import { Locale } from "../../../../../../../../utils/localization";
import { DetailsViewComponent } from "../../../../../../../components";
import { EditFieldTypeConst } from "../../../../../../../../constants";

export const NetworkTab = (props: any) => {
  const {
    isEditing,
    basicDetailsTabSelected,
    basicSiteDetails,
    isBasicSiteDetailsLoading,

    onPatchRequestValueSet,
    onSectionValueSet,

    isSectionValueChanged,
    onSectionValueChanged,
    sectionEditedData,
    isCreate,
    isSectionValid,
  } = props;

  const [isVisibilityScoreValid, setIsVisibilityScoreValid] = useState(0);
  const [isAccessibilityScoreValid, setIsAccessibilityScoreValid] = useState(0);
  const [isSiteScoreValid, setIsSiteScoreValid] = useState(0);
  const [isBuyingAreaScoreValid, setIsBuyingAreaScoreValid] = useState(0);
  const [isTrafficFlowScoreValid, setIsTrafficFlowScoreValid] = useState(0);
  const [isSiteLocationScoreValid, setIsSiteLocationScoreValid] = useState(0);
  const [isPrimaryNetworkSegmentValid, setIsPrimaryNetworkSegmentValid] =
    useState(0);
  const [isSecondaryNetworkSegmentValid, setIsSecondaryNetworkSegmentValid] =
    useState(0);

  const [visibilityScore, setVisibilityScore] = useState<number | null>();
  const [accessibilityScore, setAccessibilityScore] = useState<number | null>();
  const [roadTypeSelectedValue, setRoadTypeSelectedValue] = useState<any>();

  const [siteScore, setSiteScore] = useState<number | null>();
  const [buyingAreaScore, setBuyingAreaScore] = useState<number | null>();
  const [trafficFlowScore, setTrafficFlowScore] = useState<number | null>();
  const [siteLocationScore, setSiteLocationScore] = useState<number | null>();
  const [coreStatusSelectedValue, setCoreStatusSelectedValue] = useState<any>();

  const [
    primaryNetworkSegmentSelectedValue,
    setPrimaryNetworkSegmentSelectedValue,
  ] = useState<any>();

  const [
    secondaryNetworkSegmentSelectedValue,
    setSecondaryNetworkSegmentSelectedValue,
  ] = useState<any>();

  const {
    isLoading: isCoreStatusCodesLoading,
    data: coreStatusCodes,
    refetch: refetchCoreStatusCodes,
  } = useCoreStatusCodesData();

  const {
    isLoading: isRoadTypeCodesLoading,
    data: roadTypeCodes,
    refetch: refetchRoadTypeCodes,
  } = useRoadTypeCodesData();

  const {
    isLoading: isNetworkSegmentCodesLoading,
    data: networkSegmentCodes,
    refetch: refetchNetworkSegmentCodes,
  } = useNetworkSegmentCodesData();

  useEffect(() => {
    if (basicDetailsTabSelected === 7 && isEditing) {
      refetchRoadTypeCodes();
      refetchNetworkSegmentCodes();
      refetchCoreStatusCodes();
    }
  }, [basicDetailsTabSelected, isEditing]);

  const triggerValidation = () => {
    if (visibilityScore || visibilityScore === 0) {
      setIsVisibilityScoreValid(1);
    } else {
      setIsVisibilityScoreValid(-1);
    }
    if (accessibilityScore || accessibilityScore === 0) {
      setIsAccessibilityScoreValid(1);
    } else {
      setIsAccessibilityScoreValid(-1);
    }
    if (siteScore || siteScore === 0) {
      setIsSiteScoreValid(1);
    } else {
      setIsSiteScoreValid(-1);
    }
    if (buyingAreaScore || buyingAreaScore === 0) {
      setIsBuyingAreaScoreValid(1);
    } else {
      setIsBuyingAreaScoreValid(-1);
    }
    if (trafficFlowScore || trafficFlowScore === 0) {
      setIsTrafficFlowScoreValid(1);
    } else {
      setIsTrafficFlowScoreValid(-1);
    }
    if (siteLocationScore || siteLocationScore === 0) {
      setIsSiteLocationScoreValid(1);
    } else {
      setIsSiteLocationScoreValid(-1);
    }
    if (primaryNetworkSegmentSelectedValue?.value) {
      setIsPrimaryNetworkSegmentValid(1);
    } else {
      setIsPrimaryNetworkSegmentValid(-1);
    }
    if (secondaryNetworkSegmentSelectedValue?.value) {
      setIsSecondaryNetworkSegmentValid(1);
    } else {
      setIsSecondaryNetworkSegmentValid(-1);
    }
  };

  useEffect(() => {
    if ((sectionEditedData && isSectionValueChanged) || basicSiteDetails) {
      setVisibilityScore(
        sectionEditedData?.visibilityScore
          ? sectionEditedData?.visibilityScore
          : sectionEditedData?.visibilityScore === 0
          ? 0
          : sectionEditedData?.visibilityScore === ""
          ? undefined
          : basicSiteDetails?.visibilityScore
      );
      setAccessibilityScore(
        sectionEditedData?.accessibilityScore
          ? sectionEditedData?.accessibilityScore
          : sectionEditedData?.accessibilityScore === 0
          ? 0
          : sectionEditedData?.accessibilityScore === ""
          ? undefined
          : basicSiteDetails?.accessibilityScore
      );
      setSiteScore(
        sectionEditedData?.siteScore
          ? sectionEditedData?.siteScore
          : sectionEditedData?.siteScore === 0
          ? 0
          : sectionEditedData?.siteScore === ""
          ? undefined
          : basicSiteDetails?.siteScore
      );
      setBuyingAreaScore(
        sectionEditedData?.buyingAreaScore
          ? sectionEditedData?.buyingAreaScore
          : sectionEditedData?.buyingAreaScore === 0
          ? 0
          : sectionEditedData?.buyingAreaScore === ""
          ? undefined
          : basicSiteDetails?.buyingAreaScore
      );
      setTrafficFlowScore(
        sectionEditedData?.trafficFlowScore
          ? sectionEditedData?.trafficFlowScore
          : sectionEditedData?.trafficFlowScore === 0
          ? 0
          : sectionEditedData?.trafficFlowScore === ""
          ? undefined
          : basicSiteDetails?.trafficFlowScore
      );
      setSiteLocationScore(
        sectionEditedData?.siteLocationScore
          ? sectionEditedData?.siteLocationScore
          : sectionEditedData?.siteLocationScore === 0
          ? 0
          : sectionEditedData?.siteLocationScore === ""
          ? undefined
          : basicSiteDetails?.siteLocationScore
      );
      setCoreStatusSelectedValue(
        sectionEditedData?.coreStatusCd || {
          id: basicSiteDetails?.coreStatusCd?.coreStatusTypeCd,
          value: basicSiteDetails?.coreStatusCd?.coreStatusTypeName,
        }
      );
      setRoadTypeSelectedValue(
        sectionEditedData?.roadTypeCd || {
          id: basicSiteDetails?.roadTypeCd?.roadTypeCd,
          value: basicSiteDetails?.roadTypeCd?.roadTypeName,
        }
      );
      setPrimaryNetworkSegmentSelectedValue(
        sectionEditedData?.networkSegmentPrimary || {
          id: basicSiteDetails?.networkSegmentPrimary?.networkSegmentCd,
          value: basicSiteDetails?.networkSegmentPrimary?.networkSegmentName,
        }
      );
      setSecondaryNetworkSegmentSelectedValue(
        sectionEditedData?.networkSegmentSecondary || {
          id: basicSiteDetails?.networkSegmentSecondary?.networkSegmentCd,
          value: basicSiteDetails?.networkSegmentSecondary?.networkSegmentName,
        }
      );
    }
  }, [sectionEditedData, isSectionValueChanged, basicSiteDetails]);

  useEffect(() => {
    if (isSectionValid === -1) {
      triggerValidation();
    }
  }, [
    isSectionValid,
    visibilityScore,
    accessibilityScore,
    siteScore,
    buyingAreaScore,
    trafficFlowScore,
    siteLocationScore,
    primaryNetworkSegmentSelectedValue?.value,
    secondaryNetworkSegmentSelectedValue?.value,
  ]);

  const onVisibilityScoreChange = (event: NumericTextBoxChangeEvent) => {
    onSectionValueChanged(true);
    const score = event.value;
    setVisibilityScore(score);

    if (score || score === 0) {
      setIsVisibilityScoreValid(1);
    } else if (isSectionValid === -1) {
      setIsVisibilityScoreValid(-1);
    }
    onSectionValueSet({
      visibilityScore: score === null ? "" : score,
    });
    onPatchRequestValueSet(
      {
        visibilityScore: score === null ? "" : score?.toString(),
      },
      +basicSiteDetails?.visibilityScore !== score
    );
  };

  const onAccessibilityScoreChange = (event: NumericTextBoxChangeEvent) => {
    onSectionValueChanged(true);
    const score = event?.value;
    setAccessibilityScore(score);
    if (score || score === 0) {
      setIsAccessibilityScoreValid(1);
    } else if (isSectionValid === -1) {
      setIsAccessibilityScoreValid(-1);
    }
    onSectionValueSet({
      accessibilityScore: score === null ? "" : score,
    });
    onPatchRequestValueSet(
      {
        accessibilityScore: score === null ? "" : score?.toString(),
      },
      +basicSiteDetails?.accessibilityScore !== score
    );
  };

  const onSiteScoreChange = (event: NumericTextBoxChangeEvent) => {
    onSectionValueChanged(true);
    const score = event?.value;
    setSiteScore(score);

    if (score || score === 0) {
      setIsSiteScoreValid(1);
    } else if (isSectionValid === -1) {
      setIsSiteScoreValid(-1);
    }
    onSectionValueSet({
      siteScore: score === null ? "" : score,
    });
    onPatchRequestValueSet(
      {
        siteScore: score === null ? "" : score?.toString(),
      },
      +basicSiteDetails?.siteScore !== score
    );
  };

  const onBuyingAreaScoreChange = (event: NumericTextBoxChangeEvent) => {
    onSectionValueChanged(true);
    const score = event?.value;
    setBuyingAreaScore(score);

    if (score || score === 0) {
      setIsBuyingAreaScoreValid(1);
    } else if (isSectionValid === -1) {
      setIsBuyingAreaScoreValid(-1);
    }
    onSectionValueSet({
      buyingAreaScore: score === null ? "" : score,
    });
    onPatchRequestValueSet(
      {
        buyingAreaScore: score === null ? "" : score?.toString(),
      },
      +basicSiteDetails?.buyingAreaScore !== score
    );
  };

  const onTrafficFlowScoreChange = (event: NumericTextBoxChangeEvent) => {
    onSectionValueChanged(true);
    const score = event?.value;
    setTrafficFlowScore(score);

    if (score || score === 0) {
      setIsTrafficFlowScoreValid(1);
    } else if (isSectionValid === -1) {
      setIsTrafficFlowScoreValid(-1);
    }
    onSectionValueSet({
      trafficFlowScore: score === null ? "" : score,
    });
    onPatchRequestValueSet(
      {
        trafficFlowScore: score === null ? "" : score?.toString(),
      },
      +basicSiteDetails?.trafficFlowScore !== score
    );
  };

  const onSiteLocationScoreChange = (event: NumericTextBoxChangeEvent) => {
    onSectionValueChanged(true);
    const score = event?.value;
    setSiteLocationScore(score);

    if (score || score === 0) {
      setIsSiteLocationScoreValid(1);
    } else if (isSectionValid === -1) {
      setIsSiteLocationScoreValid(-1);
    }
    onSectionValueSet({
      siteLocationScore: score === null ? "" : score,
    });
    onPatchRequestValueSet(
      {
        siteLocationScore: score === null ? "" : score?.toString(),
      },
      +basicSiteDetails?.siteLocationScore !== score
    );
  };

  const onCoreStatusChange = (event: DropDownListChangeEvent) => {
    onSectionValueChanged(true);
    const coreStatus = event?.target?.value;
    setCoreStatusSelectedValue(coreStatus);
    onSectionValueSet({
      coreStatusCd: coreStatus,
    });
    onPatchRequestValueSet(
      {
        coreStatusCd: {
          coreStatusTypeCd: coreStatus?.id,
        },
      },
      +basicSiteDetails?.coreStatusCd?.coreStatusTypeCd !== +coreStatus?.id
    );
  };

  const onRoadTypeChange = (event: DropDownListChangeEvent) => {
    onSectionValueChanged(true);
    const roadType = event?.target?.value;
    setRoadTypeSelectedValue(roadType);
    onSectionValueSet({
      roadTypeCd: roadType,
    });
    onPatchRequestValueSet(
      {
        roadTypeCd: {
          roadTypeCd: roadType?.id,
        },
      },
      +basicSiteDetails?.roadTypeCd?.roadTypeCd !== +roadType?.id
    );
  };

  const onPrimaryNetworkSegmentChange = (event: DropDownListChangeEvent) => {
    onSectionValueChanged(true);
    const primaryNetwork = event?.target?.value;
    if (primaryNetwork?.id) {
      setIsPrimaryNetworkSegmentValid(1);
    } else if (isSectionValid === -1) {
      setIsPrimaryNetworkSegmentValid(-1);
    }
    setPrimaryNetworkSegmentSelectedValue(primaryNetwork);
    onSectionValueSet({
      networkSegmentPrimary: primaryNetwork,
    });
    onPatchRequestValueSet(
      {
        networkSegmentPrimary: {
          networkSegmentCd: primaryNetwork?.id,
        },
      },
      +basicSiteDetails?.networkSegmentPrimary?.networkSegmentCd !==
        +primaryNetwork?.id
    );
  };

  const onSecondaryNetworkSegmentChange = (event: DropDownListChangeEvent) => {
    onSectionValueChanged(true);
    const secondaryNetwork = event?.target?.value;
    if (secondaryNetwork?.id) {
      setIsSecondaryNetworkSegmentValid(1);
    } else if (isSectionValid === -1) {
      setIsSecondaryNetworkSegmentValid(-1);
    }
    setSecondaryNetworkSegmentSelectedValue(secondaryNetwork);
    onSectionValueSet({
      networkSegmentSecondary: secondaryNetwork,
    });
    onPatchRequestValueSet(
      {
        networkSegmentSecondary: {
          networkSegmentCd: secondaryNetwork?.id,
        },
      },
      +basicSiteDetails?.networkSegmentSecondary?.networkSegmentCd !==
        +secondaryNetwork?.id
    );
  };

  const getNetworkData = () => [
    //GSAP NGSAP RBLA	Competitor Partner
    //Level 3	Level 3	Not Applicable	Not Applicable	Not Applicable
    //Optional - validation applies to active CO sites only
    {
      label: Locale.site_details?.retail_admin_update.network.visibility_score,
      value: basicSiteDetails?.visibilityScore,
      editType: EditFieldTypeConst.NUMBER_FIELD,
      onChange: onVisibilityScoreChange,
      editValue: visibilityScore,
      isVisible: true,
      maxLength: 10,
      minLength: 0,
      isDisabled: false,
      isValid: isVisibilityScoreValid !== -1,
      validationMessage: Locale.common?.required_field,
    },
    //GSAP NGSAP RBLA	Competitor Partner
    //Level 3	Level 3	Not Applicable	Not Applicable	Not Applicable
    //Optional - (validation applies to active CO sites only)
    {
      label:
        Locale.site_details?.retail_admin_update.network.accessibility_score,
      value: basicSiteDetails?.accessibilityScore,
      editType: EditFieldTypeConst.NUMBER_FIELD,
      onChange: onAccessibilityScoreChange,
      editValue: accessibilityScore,
      isVisible: true,
      maxLength: 10,
      minLength: 0,
      isDisabled: false,
      isValid: isAccessibilityScoreValid !== -1,
      validationMessage: Locale.common?.required_field,
    },
    //GSAP NGSAP RBLA	Competitor Partner
    //Level 3	Level 3	Not Applicable	Not Applicable	Not Applicable
    //Optional- (validation applies to active CO sites only)
    {
      label: Locale.site_details?.retail_admin_update.network.site_score,
      value: basicSiteDetails?.siteScore,
      editType: EditFieldTypeConst.NUMBER_FIELD,
      onChange: onSiteScoreChange,
      isVisible: true,
      editValue: siteScore,
      maxLength: 20,
      minLength: 0,
      isDisabled: false,
      isValid: isSiteScoreValid !== -1,
      validationMessage: Locale.common?.required_field,
    },
    //GSAP NGSAP RBLA	Competitor Partner
    //Level 3	Level 3	Not Applicable	Not Applicable	Not Applicable
    //Optional- (validation applies to active CO sites only)
    {
      label: Locale.site_details?.retail_admin_update.network.buying_area_score,
      value: basicSiteDetails?.buyingAreaScore,
      editType: EditFieldTypeConst.NUMBER_FIELD,
      isVisible: true,
      onChange: onBuyingAreaScoreChange,
      editValue: buyingAreaScore,
      maxLength: 10,
      minLength: 0,
      isDisabled: false,
      isValid: isBuyingAreaScoreValid !== -1,
      validationMessage: Locale.common?.required_field,
    },
    //GSAP NGSAP RBLA	Competitor Partner
    //Level 3	Level 3	Not Applicable	Not Applicable	Not Applicable
    //Optional- (validation applies to active CO sites only)
    {
      label:
        Locale.site_details?.retail_admin_update.network.traffic_flow_score,
      value: basicSiteDetails?.trafficFlowScore,
      editType: EditFieldTypeConst.NUMBER_FIELD,
      isVisible: true,
      onChange: onTrafficFlowScoreChange,
      editValue: trafficFlowScore,
      maxLength: 10,
      minLength: 0,
      isDisabled: false,
      isValid: isTrafficFlowScoreValid !== -1,
      validationMessage: Locale.common?.required_field,
    },
    //GSAP NGSAP RBLA	Competitor Partner
    //Level 3	Level 3	Not Applicable	Not Applicable	Not Applicable
    //Optional- (validation applies to active CO sites only)
    {
      label:
        Locale.site_details?.retail_admin_update.network.site_locaiton_score,
      value: basicSiteDetails?.siteLocationScore,
      editType: EditFieldTypeConst.NUMBER_FIELD,
      isVisible: true,
      onChange: onSiteLocationScoreChange,
      editValue: siteLocationScore,
      maxLength: 20,
      minLength: 0,
      isDisabled: false,
      isValid: isSiteLocationScoreValid !== -1,
      validationMessage: Locale.common?.required_field,
    },
    //GSAP NGSAP RBLA	Competitor Partner
    //Level 3	Level 3	Not Applicable	Not Applicable	Not Applicable
    //Optional
    {
      label: Locale.site_details?.retail_admin_update.network.core_status,
      value: basicSiteDetails?.coreStatusCd?.coreStatusTypeName,
      editType: EditFieldTypeConst.DROPDOWN_FIELD,
      isVisible: true,
      isEditDataLoading: isCoreStatusCodesLoading,
      onChange: onCoreStatusChange,
      editData: coreStatusCodes || [],
      editValue: coreStatusSelectedValue,
    },
    //GSAP NGSAP RBLA	Competitor Partner
    //Level 3	Level 3	Not Applicable	Not Applicable	Not Applicable
    //Optional
    {
      label: Locale.site_details?.retail_admin_update.network.road_type,
      value: basicSiteDetails?.roadTypeCd?.roadTypeName,
      editType: EditFieldTypeConst.DROPDOWN_FIELD,
      isVisible: true,
      isEditDataLoading: isRoadTypeCodesLoading,
      onChange: onRoadTypeChange,
      editData: roadTypeCodes || [],
      editValue: roadTypeSelectedValue,
    },
    //GSAP NGSAP RBLA	Competitor Partner
    //Level 3	Level 3	Not Applicable	Not Applicable	Not Applicable
    //"Optional    (validation applies to active CO sites only)"
    {
      label:
        Locale.site_details?.retail_admin_update.network
          .network_segment_primary,
      value: basicSiteDetails?.networkSegmentPrimary?.networkSegmentName,
      isVisible: true,
      editType: EditFieldTypeConst.DROPDOWN_FIELD,
      isEditDataLoading: isNetworkSegmentCodesLoading,
      onChange: onPrimaryNetworkSegmentChange,
      editData:
        networkSegmentCodes?.filter(
          (segment: any) =>
            segment?.id !== secondaryNetworkSegmentSelectedValue?.id
        ) || [],
      editValue: primaryNetworkSegmentSelectedValue,
      isValid: isPrimaryNetworkSegmentValid !== -1,
      validationMessage: Locale.common?.required_field,
    },
    //GSAP NGSAP RBLA	Competitor Partner
    //Level 3	Level 3	Not Applicable	Not Applicable	Not Applicable
    //"Optional    (validation applies to active CO sites only)"
    {
      label:
        Locale.site_details?.retail_admin_update.network
          .network_segment_secondary,
      value: basicSiteDetails?.networkSegmentSecondary?.networkSegmentName,
      editType: EditFieldTypeConst.DROPDOWN_FIELD,
      isEditDataLoading: isNetworkSegmentCodesLoading,
      onChange: onSecondaryNetworkSegmentChange,
      editData:
        networkSegmentCodes?.filter(
          (segment: any) =>
            segment?.id !== primaryNetworkSegmentSelectedValue?.id
        ) || [],
      isVisible: true,
      editValue: secondaryNetworkSegmentSelectedValue,
      isValid: isSecondaryNetworkSegmentValid !== -1,
      validationMessage: Locale.common?.required_field,
    },
  ];

  return (
    <>
      <DetailsViewComponent
        data={getNetworkData()}
        classValue="mdm--row mdm--sd-data network-info"
        isLoading={isBasicSiteDetailsLoading}
        isEditing={isEditing}
        isCreate={isCreate}
      />
    </>
  );
};
