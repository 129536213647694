export const AdobeAnalyticsService = {
  findSection: async (key: any) => {
    switch (key) {
      case "siteName":
        return { name: key, section: "left" };
      case "siteShortName":
        return { name: key, section: "left" };
      case "latitude":
        return { name: key, section: "left" };
      case "longitude":
        return { name: key, section: "left" };
      case "siteOpeningDate":
        return { name: key, section: "left" };
      case "siteClosingDate":
        return { name: key, section: "left" };
      case "siteCategoryCd":
        return { name: key, section: "left" };
      case "marketArea":
        return { name: key, section: "left" };
      case "cityName":
        return { name: key, section: "left" };
      case "streetAddress":
        return { name: key, section: "left" };
      case "postalCode":
        return { name: key, section: "left" };
      case "telephoneNo":
        return { name: key, section: "left" };
      case "fpsInd":
        return { name: key, section: "left" };
      case "postalCode":
        return { name: key, section: "left" };
      case "territoryManager":
        return { name: key, section: "left" };
      case "districtManager":
        return { name: key, section: "left" };

      case "siteTypeCd":
        return { name: "siteTypeName", section: "left" };
      case "siteStatusCd":
        return { name: "siteStatusName", section: "left" };
      case "operatingPlatformCd":
        return { name: "operatingPlatformName", section: "left" };
      case "siteCategoryCd":
        return { name: "siteCategoryCd", section: "left" };

      case "tenureTypeCd":
        return { name: "tenureTypeName", section: "left" };

      case "countryCd":
        return { name: "countryName", section: "left" };

      case "territoryCd":
        return { name: "territoryName", section: "left" };

      case "countyCd":
        return { name: "countyName", section: "left" };

      case "salesOrgCd":
        return { name: "salesOrgCd", section: "left" };

      case "countryDiallingCd":
        return { name: "countryDiallingCd", section: "left" };

      case "crSiteShopTypeCd":
        return { name: "crSiteShopTypeName", section: "left" };

      case "crFormatTypeCd":
        return { name: "crFormatTypeName", section: "left" };

      case "orgAffiliationCd":
        return { name: "orgAffiliationName", section: "left" };

      case "orgAffiliationTypeCd":
        return { name: "orgAffiliationTypeName", section: "left" };

      case "salesOfficeCd":
        return { name: "salesOfficeCd", section: "left" };

      case "neraRiskCd":
        return { name: "neraRiskName", section: "left" };
      case "neraCertificationDate":
        return { name: "neraCertificationDate", section: "left" };

      case "neraRiskCd":
        return { name: "neraRiskName", section: "left" };

      case "visibilityScore":
        return { name: "Network>visibilityScore", section: "top" };
      case "accessibilityScore":
        return { name: "Network>accessibilityScore", section: "top" };
      case "siteScore":
        return { name: "Network>siteScore", section: "top" };
      case "buyingAreaScore":
        return { name: "Network>buyingAreaScore", section: "top" };
      case "trafficFlowScore":
        return { name: "Network>trafficFlowScore", section: "top" };
      case "siteLocationScore":
        return { name: "Network>siteLocationScore", section: "top" };
      case "coreStatusCd":
        return {
          name: "Network>coreStatusCd>coreStatusTypeName",
          section: "top",
        };
      case "roadTypeCd":
        return { name: "Network>roadTypeCd>roadTypeName", section: "top" };
      case "networkSegmentPrimary":
        return {
          name: "Network>networkSegmentPrimary",
          section: "top",
        };

      case "networkSegmentSecondary":
        return { name: "Network>networkSegmentSecondary", section: "top" };
      case "SiteFacilitySubtype":
        return { name: "Facility", section: "top" };

      case "SiteProductClass":
        return { name: "Fuel Products", section: "top" };

      case "SiteOpeningHours":
        return { name: "Operating Hours", section: "top" };

      case "SiteRetailer":
        return { name: "Retailer", section: "top" };

      case "SiteLegacyKeys":
        return { name: "Legacy Ids", section: "top" };

      case "shellNonPublicSite":
        return { name: "AditionalInfo>shellNonPublicSite", section: "top" };
      case "brandCovenantCd":
        return { name: "AditionalInfo>brandCovenantName", section: "top" };
      case "brandCovenantExpiryDate":
        return {
          name: "AditionalInfo>brandCovenantExpiryDate",
          section: "top",
        };
      case "loyaltyProgramInd":
        return { name: "AditionalInfo>loyaltyProgramInd", section: "top" };
      case "differentiatedDiscountGroupCd":
        return {
          name: "AditionalInfo>differentiatedDiscountGroupCd",
          section: "top",
        };
      case "vmiIndicatorName":
        return { name: "AditionalInfo>vmiIndicatorName", section: "top" };
      case "faxNo":
        return { name: "AditionalInfo>faxNo", section: "top" };
      case "crModelCd1":
        return { name: "AditionalInfo>crModelName", section: "top" };
      case "customerAccountGroup":
        return { name: "AditionalInfo>customerAccountGroup", section: "top" };
      case "salesGroup":
        return { name: "AditionalInfo>salesGroup", section: "top" };
      case "differentiator":
        return { name: "AditionalInfo>differentiator", section: "top" };
      case "evStartDate":
        return { name: "AditionalInfo>evStartDate", section: "top" };
      case "pricingAreaCd":
        return { name: "AditionalInfo>pricingAreaCd", section: "top" };
      case "shipToDistributChannel":
        return { name: "AditionalInfo>shipToDistributChannel", section: "top" };
      case "shipToProductDivision":
        return { name: "AditionalInfo>shipToProductDivision", section: "top" };
      case "siteGroup":
        return { name: "AditionalInfo>siteGroup", section: "top" };
      case "sitePriceGroup":
        return { name: "AditionalInfo>sitePriceGroup", section: "top" };

      case "evChargerPostCount":
        return { name: "EV Overview>evChargerPostCount", section: "bottom" };
      case "parkingOwnerCd":
        return { name: "EV Overview>parkingOwnerName", section: "bottom" };
      case "evChargerPointCount":
        return { name: "EV Overview>evChargerPointCount", section: "bottom" };
      case "destinationHostCd":
        return { name: "EV Overview>destinationHostName", section: "bottom" };
      case "evChargerMaxPowerKw":
        return { name: "EV Overview>evChargerMaxPowerKw", section: "bottom" };
      case "chargerOwnershipCd":
        return { name: "EV Overview>chargerOwnershipName", section: "bottom" };
      case "EVCharger":
        return { name: "EV Charge Posts", section: "bottom" };
      case "customerMissionPrimaryCd":
        return {
          name: "Customer Mission>customerMissionPrimary",
          section: "bottom",
        };
      case "customerMissionSecondaryCd":
        return {
          name: "Customer Mission>customerMissionSecondaryCd",
          section: "bottom",
        };
      case "customerMissionTertiaryCd":
        return {
          name: "Customer Mission>customerMissionTertiaryCd",
          section: "bottom",
        };

      case "customerMissionQuaternaryCd":
        return {
          name: "Customer Mission>customerMissionQuaternaryCd",
          section: "bottom",
        };
      case "SiteMarketType":
        return { name: "Local Custom ", section: "bottom" };
      case "SiteEmployee":
        return { name: "Site Contact", section: "bottom" };
      case "siteFrontage":
        return { name: "Space Utilisation>siteFrontage", section: "bottom" };
      case "siteDepth":
        return { name: "Space Utilisation>siteDepth", section: "bottom" };
      case "siteSizeArea":
        return { name: "Space Utilisation>siteSizeArea", section: "bottom" };
      case "shopBuildingSize":
        return {
          name: "Space Utilisation>shopBuildingSize",
          section: "bottom",
        };
      case "shopSellingSpaceSize":
        return {
          name: "Space Utilisation>shopSellingSpaceSize",
          section: "bottom",
        };
      case "SiteExternalId":
        return { name: "External Ids", section: "bottom" };
      case "SiteCurrency":
        return { name: "Currency", section: "bottom" };

      default:
        break;
    }
  },
};
