import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useQuery } from "react-query";

import { Skeleton } from "@progress/kendo-react-indicators";

import { Locale } from "../../../../../utils/localization";
import { SitesListRequestData } from "../../../../../models";
import {
  AppConst,
  SiteStatusTextConst,
  SiteTypeNameConst,
  RoleConst
} from "../../../../../constants";

import { SitesService } from "../../../../../services/sites.service";

import menuIcon from "./../../../../../assets/images/icons/menu-icon.svg";

import "./StatusComponent.scss";

interface IProps {
  onTotalCountChange: (total: number) => void;
}

export const StatusComponent = (props: IProps) => {
  const { onTotalCountChange } = props;
  const userDetails = useSelector((state: any) => state?.auth?.user);
  const selectedGlobalCountry = useSelector((state: any) => {
    return state?.selectedGlobalCountry.selectedGlobalCountry;
  });

  const defaultPayload: SitesListRequestData = {
    filter:
      selectedGlobalCountry === AppConst.ALL_SITES
        ? `search.in(SiteType, '${SiteTypeNameConst.SHELL_IN_NETWORK_RETAIL_SITE},${SiteTypeNameConst.SHELL_COMMERCIAL_SITE}', ',')`
        : `search.in(SiteType, '${SiteTypeNameConst.SHELL_IN_NETWORK_RETAIL_SITE},${SiteTypeNameConst.SHELL_COMMERCIAL_SITE}', ',') and search.in(Country,'${selectedGlobalCountry}', ',')`,
    facetFilter:
      selectedGlobalCountry === AppConst.ALL_SITES
        ? `search.in(SiteType, '${SiteTypeNameConst.SHELL_IN_NETWORK_RETAIL_SITE},${SiteTypeNameConst.SHELL_COMMERCIAL_SITE}', ',')`
        : `search.in(SiteType, '${SiteTypeNameConst.SHELL_IN_NETWORK_RETAIL_SITE},${SiteTypeNameConst.SHELL_COMMERCIAL_SITE}', ',') and search.in(Country,'${selectedGlobalCountry}', ',')`,
    searchText: "*",
    orderby: "",
    page: 0,
    pageSize: 1,
    facets: "Status,count:20,sort:value",
  };

  const [requestPayload, setRequestPayload] = useState(defaultPayload);

  const [activeSiteCount, setActiveSiteCount] = useState(0);
  const [closedTempSiteCount, setClosedTempSiteCount] = useState(0);
  const [wipSiteCount, setWipSiteCount] = useState(0);
  const [notSpecifiedSiteCount, setNotSpecifiedSiteCount] = useState(0);
  const [invalidSiteCount, setInvalidSiteCount] = useState(0);
  const [otherSiteCount, setOtherSiteCount] = useState(0);

  const { isLoading, data, refetch } = useQuery(
    "search-sites-list-status-facet",
    () => {
      return SitesService.getSitesList(requestPayload);
    },
    {
      staleTime: AppConst.VIEW_SITE_DETAILS_STALE_TIME,
      enabled: true,
      refetchOnMount: true,
      refetchOnWindowFocus: false,
      select: (data) => data?.data?.result?.facets?.Status,
    }
  );

  useEffect(() => {
    refetch();
  }, [requestPayload]);

  useEffect(() => {
    setRequestPayload({
      filter:
        selectedGlobalCountry === AppConst.ALL_SITES
          ? `search.in(SiteType, '${SiteTypeNameConst.SHELL_IN_NETWORK_RETAIL_SITE},${SiteTypeNameConst.SHELL_COMMERCIAL_SITE}', ',')`
          : `search.in(SiteType, '${SiteTypeNameConst.SHELL_IN_NETWORK_RETAIL_SITE},${SiteTypeNameConst.SHELL_COMMERCIAL_SITE}', ',') and search.in(Country,'${selectedGlobalCountry}', ',') `,
      facetFilter:
        selectedGlobalCountry === AppConst.ALL_SITES
          ? `search.in(SiteType, '${SiteTypeNameConst.SHELL_IN_NETWORK_RETAIL_SITE},${SiteTypeNameConst.SHELL_COMMERCIAL_SITE}', ',')`
          : `search.in(SiteType, '${SiteTypeNameConst.SHELL_IN_NETWORK_RETAIL_SITE},${SiteTypeNameConst.SHELL_COMMERCIAL_SITE}', ',') and search.in(Country,'${selectedGlobalCountry}', ',')`,
      searchText: "*",
      orderby: "",
      page: 0,
      pageSize: 1,
      facets: "Status,count:50,sort:value",
    });
  }, [selectedGlobalCountry]);

  useEffect(() => {
    if (data?.length) {
      const activeStatusFacet = data?.find(
        (status: { value: string }) =>
          status?.value === SiteStatusTextConst.ACTIVE_STATUS_TEXT
      );
      setActiveSiteCount(activeStatusFacet?.count || 0);

      const ctStatusFacet = data?.find(
        (status: { value: string }) =>
          status?.value === SiteStatusTextConst.CLOSED_TEMPORARILY_STATUS_TEXT
      );
      setClosedTempSiteCount(ctStatusFacet?.count || 0);

      const activeCTTotal =
        (activeStatusFacet?.count || 0) + (ctStatusFacet?.count || 0);

      onTotalCountChange(activeCTTotal);

      const wipStatusFacet = data?.find(
        (status: { value: string }) =>
          status?.value === SiteStatusTextConst.WIP_STATUS_TEXT
      );
      setWipSiteCount(wipStatusFacet?.count || 0);

      const notSpecifiedStatusFacet = data?.find(
        (status: { value: string }) =>
          status?.value === SiteStatusTextConst.NOT_SPECIFIED_STATUS_TEXT
      );
      setNotSpecifiedSiteCount(notSpecifiedStatusFacet?.count || 0);

      const invalidStatusFacet = data?.find(
        (status: { value: string }) =>
          status?.value === SiteStatusTextConst.INVALID_STATUS_TEXT
      );
      setInvalidSiteCount(invalidStatusFacet?.count || 0);

      const otherStatusFacet = data?.filter(
        (status: { value: string }) =>
          status?.value !== SiteStatusTextConst.ACTIVE_STATUS_TEXT &&
          status?.value !==
            SiteStatusTextConst.CLOSED_TEMPORARILY_STATUS_TEXT &&
          status?.value !==
            SiteStatusTextConst.CLOSED_PERMANENTLY_STATUS_TEXT &&
          status?.value !== SiteStatusTextConst.WIP_STATUS_TEXT &&
          status?.value !== SiteStatusTextConst.NOT_SPECIFIED_STATUS_TEXT &&
          status?.value !== SiteStatusTextConst.INVALID_STATUS_TEXT
      );
      const otherSitesCount: number = otherStatusFacet?.reduce(
        (acc: number, obj: { count: number }) => acc + obj?.count,
        0
      );
      setOtherSiteCount(otherSitesCount);
    } else {
      onTotalCountChange(0);
    }
  }, [data]);

  return (
    <div className="mdm--status-data-container">
      <h5 className="mdm--landing-page-item-title">
      {userDetails?.roleDetail?.userRoleName === RoleConst.SALES_OPS_SME ? Locale.landing_page?.status_section.status_shell_section_title : Locale.landing_page?.status_section.status_section_title}
      </h5>
      <div className="mdm--landing-page-item-body mdm--status-data-body  mdm--row">
        {isLoading ? (
          <div className="loading">
            {[0, 1, 2]?.map((item: number) => (
              <div className="mdm--row" key={item}>
                <Skeleton shape={"text"} style={{ width: "55%" }} />
                <Skeleton shape={"text"} style={{ width: "40%" }} />
              </div>
            ))}
          </div>
        ) : data?.length ? (
          <>
            <div className="mdm--primary-status-section">
              <div className="table">
                <div className="row">
                  <span className="cell label">
                    {
                      Locale.landing_page?.status_section
                        .active_site_count_label
                    }
                  </span>
                  <span className="cell">:</span>
                  <span className="cell value">{activeSiteCount}</span>
                </div>

                <div className="row">
                  <span className="cell label">
                    {Locale.landing_page?.status_section.ct_site_count_label}
                  </span>
                  <span className="cell">:</span>
                  <span className="cell value">{closedTempSiteCount}</span>
                </div>
              </div>
              <div className="total_item_container">
                <img src={menuIcon} className="image" />
                <span>
                  {Locale.formatString(
                    Locale.landing_page?.status_section.total_site_count_label,
                    activeSiteCount + closedTempSiteCount
                  )}
                </span>
              </div>
            </div>

            <div className="mdm--secondary-status-section">
              <div className="table">
                <div className="row">
                  <span className="cell label">
                    {Locale.landing_page?.status_section.wip_site_count_label}
                  </span>
                  <span className="cell">:</span>
                  <span className="cell value">{wipSiteCount}</span>
                </div>

                <div className="row">
                  <span className="cell label">
                    {
                      Locale.landing_page?.status_section
                        .not_specified_site_count_label
                    }
                  </span>
                  <span className="cell">:</span>
                  <span className="cell value">{notSpecifiedSiteCount}</span>
                </div>
                <div className="row">
                  <span className="cell label">
                    {
                      Locale.landing_page?.status_section
                        .invalid_site_count_label
                    }
                  </span>
                  <span className="cell">:</span>
                  <span className="cell value">{invalidSiteCount}</span>
                </div>

                <div className="row">
                  <span className="cell label">
                    {Locale.landing_page?.status_section.other_site_count_label}
                  </span>
                  <span className="cell">:</span>
                  <span className="cell value">{otherSiteCount}</span>
                </div>
              </div>
            </div>
          </>
        ) : (
          <div className="no-records-container box-shadow">
            <p className="no-records">{Locale.common?.no_records_to_view}</p>
          </div>
        )}
      </div>
    </div>
  );
};
