export class SiteConst {
  public static SITE_LIST_TABLE_HEADER_KEY = {
    GLOBAL_SITE_ID: "globalSiteId",
    SITE_NAME: "siteName",
    STATUS: "status",
    SITE_CATEGORY: "siteCategory",
    SITE_TYPE: "siteType",
    OPERATING_PLATFORM: "operatingPlatform",
    ORG_AFFILIATION_CODE: "orgAffiliationCode",
    COUNTRY: "country",
    COUNTRY_CODE: "countryCode",
    CITY: "city",
  };

  public static COLUMN_CONFIG: any = {
    globalSiteId: "GlobalSiteId",
    siteName: "SiteName",
    status: "Status",
    siteType: "SiteType",
    siteCategory: "SiteCategory",
    operatingPlatform: "OperatingPlatform",
    country: "Country",
    city: "City",
  };
  public static COLUMN_CHANGE_CONFIG: any = {
    id: "Id",
    globalSiteId: "GlobalSiteId",
    siteName: "SiteName",
    createdDate: "CreatedDate",
    fieldName: "FieldName",
    groupName: "GroupName",
    newValue: "NewValue",
    oldValue: "OldValue",
    createdAt: "CreatedAt",
    createdBy: "CreatedBy",
    updatedBy: "UpdatedBy",
    status: "Status",
    country: "Country",
    view: "View",
  };

  public static SITE_STATUS_LIST = {
    ACTIVE: "Active",
    INVALID: "Invalid",
    CLOSED_TEMPORARILY: "Closed Temporarily",
    CLOSED_PERMANENTLY: "Closed Permanently",
    WORK_IN_PROCESS: "Work in Process",
    RECYCLE_BIN: "Recycle Bin",
    NOT_SPECIFIED: "Not Specified",
    NON_RETAIL_SHELL_LOCATION: "Non-Retail Shell Location",
    COMPLETED_NOT_OPENED: "Completed/Not Opened",
    LANDBANK: "Landbank",
    COMPETITOR_SITE: "Competitor Site"
  };

  public static SITE_STATUS_LIST_SHORTHAND: any = {
    ACTIVE: "Active",
    INVALID: "Invalid",
    CLOSED_PERMANENTLY: "C P",
    CLOSED_TEMPORARILY: "C T",
    WORK_IN_PROCESS: "W I P",
    RECYCLE_BIN: "R B",
    NOT_SPECIFIED: "N S",
    NON_RETAIL_SHELL_LOCATION: "N-RSL",
    COMPLETED_NOT_OPENED: " C/NO",
    LANDBANK: "L B",
  };
  public static PROFILE_USER_DETAILS: any = {
    UUID: "ID",
    EMAIL: "Email",
    ROLE: "Role",
    FIRST_NAME: "First Name",
    LAST_NAME: "Last Name",
    USER_TYPE: "User Type",
    ACTIVE: "Active",
    COUNTRY: "Country",
  };
}
