import { ContryConst, SiteTypeConst } from "../../../../../../constants";

export class ValidateEditSite {
  public static isGSAPSite(
    country: { id: string; value: string },
    siteType: { id: string; value: string }
  ) {
    if (
      country?.id === ContryConst.UKRAINE ||
      country?.id === ContryConst.CHINA ||
      siteType?.id === SiteTypeConst.COMPETITOR_SITE ||
      siteType?.id === SiteTypeConst.SHELL_LICENSED_MARKET_SITE ||
      siteType?.id === SiteTypeConst.SHELL_CARD_PARTNER_SITE
    ) {
      return false;
    }
    return true;
  }

  public static isNonGSAPSite(
    country: { id: string; value: string },
    siteType: { id: string; value: string }
  ) {
    if (
      (siteType?.id === SiteTypeConst.SHELL_IN_NETWORK_RETAIL_SITE ||
        siteType?.id === SiteTypeConst.SHELL_COMMERCIAL_SITE) &&
      (country?.id === ContryConst.UKRAINE || country?.id === ContryConst.CHINA)
    ) {
      return true;
    }
    return false;
  }
}
