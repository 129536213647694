import axios from "axios";
import { appConfig } from "../../config";
import store from "../../redux/store";

export const API = axios.create({
  baseURL: appConfig.baseUrl,
  headers: {
    apikey: appConfig?.apgApiKey || "",
  },
});

API.interceptors.request.use((config: any) => {
  const state = store.getState();
  // const userDetails = useSelector((state: any) => state?.auth?.user);
  // console.log("State of the user", state, state.auth?.user?.userType);

  // const state = useSelector((state: any) => state);
  const token = state.auth?.auth?.accessToken;
  config.headers.common.logintype =
    state.auth?.user?.userType?.toLowerCase() === "shell" ? 1 : 2;
  // const token = auth?.auth?.accessToken;

  config.headers.common.Authorization = `${token}`;
  // console.log("State of the user", config.headers.common);
  // config.headers.common["apikey"] = appConfig.apgApiKey;

  // config.headers.common["x-userId"] = state.auth?.user?.emailAddress;

  // config.headers.common["x-userRole"] =
  //   state.auth?.user?.roleDetail?.userRoleName;

  return config;
});
