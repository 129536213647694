const {
  REACT_APP_INST_KEY,
  REACT_APP_ALLOW_TELEMETERY,
  REACT_APP_TELEMETERY_LOG_LEVEL,
  REACT_APP_CIPM_CLIENT_ID,
  REACT_APP_PING_CLIENT_ID,
  REACT_APP_IS_DEV,
  REACT_APP_CIPM_LOGIN_HOST,
  REACT_APP_BASE_URL,
  REACT_APP_BASE_URL_APG_KEY,
  REACT_APP_SIGNALR_URL,
} = process.env;
export const appConfig = {
  isDev: REACT_APP_IS_DEV?.toLowerCase() === "true",
  instrumentationKey: REACT_APP_INST_KEY,
  allowTelemetery: REACT_APP_ALLOW_TELEMETERY?.toLowerCase() === "true",
  telemeteryLogLevel: REACT_APP_TELEMETERY_LOG_LEVEL
    ? REACT_APP_TELEMETERY_LOG_LEVEL?.toLowerCase()
    : "error",
  pingAuthentication: {
    clientId: REACT_APP_PING_CLIENT_ID,
    authorizationGrantType: "authorization_code",
    refreshGrantType: "refresh_token",
    responseType: "code",
    codeChallengeMethod: "S256",
  },
  cipmAuthentication: {
    loginHost: REACT_APP_CIPM_LOGIN_HOST,
    clientId: REACT_APP_CIPM_CLIENT_ID,
    authorizationGrantType: "authorization_code",
    refreshGrantType: "refresh_token",
    responseType: "code",
    codeChallengeMethod: "S256",
  },
  baseUrl: REACT_APP_BASE_URL,
  apgApiKey: REACT_APP_BASE_URL_APG_KEY,
  signalRUrl: REACT_APP_SIGNALR_URL,
};
