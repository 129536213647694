import React from "react";

import { Notification } from "@progress/kendo-react-notification";
import { Fade } from "@progress/kendo-react-animation";

interface IProps {
  show: boolean;
  type: "info" | "error" | "warning";
  onClose?: () => void;
  children?: React.ReactNode;
  closable?: boolean;
}

export const FadeNotification = (props: IProps) => {
  const { type, onClose, show, closable } = props;
  return (
    <Fade>
      {show && (
        <Notification
          closable={closable}
          onClose={onClose}
          type={{ style: type, icon: true }}
        >
          {props.children}
        </Notification>
      )}
    </Fade>
  );
};
