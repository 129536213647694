import React from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "@progress/kendo-react-buttons";

import "./DashboardToggleComponent.scss";

interface IProps {
  titleText: string;
  navigateText: string;
  path: string;
}

export const DashboardToggleComponent = (props: IProps) => {
  const navigate = useNavigate();
  const { titleText, navigateText, path } = props;

  const onChangeHistoryButtonClick = () => {
    navigate(path);
  };

  return (
    <div className="mdm--dashboard-toggle-container">
      <div className="mdm--landing-page-item-body mdm--dashboard-toggle-body mdm--row">
        <div className="mdm--dashboard-toggle-text-section">
          <div className="mdm--row left-btn-section">
            <span className="dashboard-toggle-text">{titleText}</span>
          </div>
        </div>
        <Button
          className="buttons-container-button"
          size="small"
          fillMode="flat"
          onClick={onChangeHistoryButtonClick}
        >
          {navigateText}
          <span className="arrow-icon" />
        </Button>
      </div>
    </div>
  );
};
