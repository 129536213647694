import { useEffect, useState } from "react";
import { Card, CardBody } from "@progress/kendo-react-layout";
import "./preApproval.scss";
import { Button } from "@progress/kendo-react-buttons";
import { ConfigurationService } from "../../../../services/configuration.service";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { useMasterDataSet1 } from "../../../../hooks/useSiteDetailsCodes";
import { ToastNotification } from "../../../components/toast-notification/ToastNotification";
interface MasterDataType {
  id: string;
  value: string;
  countrydiallingcd?: string;
}

export const PreApproval = () => {
  const [preApprovalData, setPreApprovalData] = useState<any>();
  const [success, setSuccess] = useState(false);
  const [countryCodes, setCountryCodes] = useState<MasterDataType[]>();
  const [countryName, setCountryName] = useState<any>();
  const [countryId, setCountryId] = useState();
  const [preApprovalUpdateValue, setPreApprovalUpdateValue] = useState<any>([]);
  const options = [
    {
      value: "Invalid",
      id: 2,
    },
    {
      value: "Auto Approval",
      id: 1,
    },
    {
      value: "Manual Approval",
      id: 0,
    },
  ];

  const fetchData = async (id: any) => {
    const data = await ConfigurationService.getPreApprovalList(id);
    setPreApprovalData(data?.data);
  };

  const handleChangeEvent = (e: any, index: number) => {
    const newApprovalData = preApprovalData;
    newApprovalData[index].approvalStatus = e.target.value.id;
    newApprovalData[index].countryCd =
      newApprovalData[index].countryCd || countryId;
    setPreApprovalData(newApprovalData);
    setPreApprovalUpdateValue([
      ...preApprovalUpdateValue,
      { ...newApprovalData[index] },
    ]);
  };
  const onClickSaveHandler = async () => {
    try {
      const response = await ConfigurationService.postPreApprovalData(
        preApprovalUpdateValue
      );
      if (response.Error) {
        setSuccess(false);
      } else {
        setSuccess(true);
      }
    } catch (error) {
      setSuccess(false);
    }
  };
  const {
    isLoading: isMasterDataSet1Loading,
    data: masterDataSet1,
    refetch: refetchMasterDataSet1,
  } = useMasterDataSet1();

  useEffect(() => {
    refetchMasterDataSet1();
  }, []);

  useEffect(() => {
    if (masterDataSet1) {
      const countryTypes = masterDataSet1?.countryCodes?.map((type: any) => {
        return {
          id: type?.countrycd,
          value: type?.countryname || "",
          countrydiallingcd: type?.countrydiallingcd || "",
        };
      });
      setCountryCodes(countryTypes);
    }
  }, [masterDataSet1]);
  const handleCountryChange = (e: any) => {
    setPreApprovalUpdateValue([]);
    setCountryId(e.target.value.id);
    setCountryName(e.target.value.value);
    fetchData(e.target.value.id);
  };
  const defaultItem = { value: "Select Country" };
  return (
    <div>
      <div className="dropdown-country-container">
        <span className="dropdown-country-preapproval-title">
          {defaultItem.value}
        </span>
        <span>:</span>
        <DropDownList
          dataItemKey="id"
          className="preApproval-dropdown-country"
          textField="value"
          defaultItem={defaultItem}
          onChange={handleCountryChange}
          data={countryCodes}
          popupSettings={{ className: "cofiguration-country-dropdown" }}
        />
        <h6>{countryName}</h6>
        {countryName && (
          <div className="Button-container-preapproval">
            <Button
              className="buttons-preapproval-button"
              onClick={onClickSaveHandler}
              themeColor="primary"
              rounded="large"
            >
              Save
            </Button>
          </div>
        )}
      </div>
      <div className="preaaproval-container">
        <div style={{ height: "100%", width: "100%", display: "flex" }}>
          {countryName && (
            <>
              <Card className="preapproval-card">
                <h5 className="configration-title">Facility</h5>
                <CardBody className="preapproval-card-body">
                  {preApprovalData?.map((item: any, index: number) => {
                    return (
                      <>
                        {item?.fieldType === "FacilitySubType" ? (
                          <div className="preapproval-dropdown-container">
                            {/* item.fieldType ? */}
                            <div className="preapproval-dropdown-title">
                              <span className="preapproval-list-title">
                                {item?.fieldName}
                              </span>
                              <span className="preApproval-backtick">:</span>
                              <div>
                                <DropDownList
                                  className="preApproval-checkbox"
                                  key={index}
                                  textField="value"
                                  dataItemKey="id"
                                  data={options}
                                  // defaultItem={options}
                                  value={options.find(
                                    (x) => x.id === item.approvalStatus
                                  )}
                                  onChange={(e) => handleChangeEvent(e, index)}
                                />
                              </div>
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                        {item?.fieldType === "Facility" ? (
                          <div className="preapproval-dropdown-container">
                            {/* item.fieldType ? */}
                            <div className="preapproval-dropdown-title">
                              <span className="preapproval-list-title">
                                {item?.fieldName}
                              </span>
                              <span className="preApproval-backtick">:</span>
                              <div>
                                <DropDownList
                                  className="preApproval-checkbox"
                                  key={index}
                                  textField="value"
                                  dataItemKey="id"
                                  data={options}
                                  // defaultItem={options}
                                  value={options.find(
                                    (x) => x.id === item.approvalStatus
                                  )}
                                  onChange={(e) => handleChangeEvent(e, index)}
                                />
                              </div>
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                      </>
                    );
                  })}
                </CardBody>
              </Card>
              <Card className="preapproval-card">
                <h5 className="configration-title">Common</h5>
                <ToastNotification
                  show={success}
                  type={"success"}
                  message={["Your Data Has Been Saved"]}
                />
                <CardBody className="preapproval-card-body">
                  {preApprovalData?.map((item: any, index: number) => {
                    return (
                      <>
                        {item?.fieldType === "Product" ? (
                          <div className="preapproval-dropdown-container">
                            {/* item.fieldType ? */}
                            <div className="preapproval-dropdown-title">
                              <span className="preapproval-list-title">
                                {item?.fieldName}
                              </span>
                              <span className="preApproval-backtick">:</span>
                              <div>
                                <DropDownList
                                  className="preApproval-checkbox"
                                  key={index}
                                  textField="value"
                                  dataItemKey="id"
                                  data={options}
                                  // defaultItem={options}
                                  value={options.find(
                                    (x) => x.id === item.approvalStatus
                                  )}
                                  onChange={(e) => handleChangeEvent(e, index)}
                                />
                              </div>
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                        {item?.fieldType !== "FacilitySubType" &&
                        item?.fieldType !== "Facility" &&
                        item?.fieldType !== "Product" ? (
                          <div className="preapproval-dropdown-container">
                            {/* item.fieldType ? */}
                            <div className="preapproval-dropdown-title">
                              <span className="preapproval-list-title">
                                {item?.fieldName}
                              </span>
                              <span className="preApproval-backtick">:</span>
                              <div>
                                <DropDownList
                                  className="preApproval-checkbox"
                                  key={index}
                                  textField="value"
                                  dataItemKey="id"
                                  data={options}
                                  // defaultItem={options}
                                  value={options.find(
                                    (x) => x.id === item.approvalStatus
                                  )}
                                  onChange={(e) => handleChangeEvent(e, index)}
                                />
                              </div>
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                      </>
                    );
                  })}
                </CardBody>
              </Card>
            </>
          )}
        </div>
      </div>
    </div>
  );
};
export default PreApproval;
