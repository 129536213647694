import { useQuery } from "react-query";
import { AppConst } from "../constants";
import { SitesService } from "../services/sites.service";
import { Console } from "console";
import { Value } from "sass";

const fetchMasterDataSet1 = () => SitesService.getSiteMasterDataSync("Set1");

const fetchMasterDataSet2 = () => SitesService.getSiteMasterDataSync("Set2");

const fetchTerritoryCodesData = ({ queryKey }: any) => {
  const country = queryKey[1];
  return SitesService.getSiteMasterDataSync(
    `TerritoryCodes?countryCode=${country}`
  );
};

const fetchCountyCodesData = ({ queryKey }: any) => {
  const country = queryKey[1];
  return SitesService.getSiteMasterDataSync(
    `CountyCodes?countryCode=${country}`
  );
};

const fetchBrandCovenantCodesData = () =>
  SitesService.getSiteMasterDataSync("BrandCovenantCodes");

const fetchChargerOwnershipCodesData = () =>
  SitesService.getSiteMasterDataSync("ChargerOwnershipCodes");

const fetchCoreStatusCodesData = () =>
  SitesService.getSiteMasterDataSync("CoreStatusCodes");

const fetchCountryCodesData = () =>
  SitesService.getSiteMasterDataSync("CountryCodes");

const fetchCountryDiallingCodesData = () =>
  SitesService.getSiteMasterDataSync("CountryDiallingCodes");

const fetchCrFormatTypeCodesData = () =>
  SitesService.getSiteMasterDataSync("CrFormatTypeCodes");

const fetchCrModelCodesData = () =>
  SitesService.getSiteMasterDataSync("CrModelCodes");

const fetchCrSiteShopTypeCodesData = () =>
  SitesService.getSiteMasterDataSync("CrSiteShopTypeCodes");

const fetchCurrencyCodesData = () =>
  SitesService.getSiteMasterDataSync("Currencies");

const fetchDestinationHostCodesData = () =>
  SitesService.getSiteMasterDataSync("DestinationHostCodes");

const fetchEmployeeTypeCodesData = () =>
  SitesService.getSiteMasterDataSync("EmployeeTypeCodes");

const fetchExternalIdTypeCodesData = () =>
  SitesService.getSiteMasterDataSync("ExternalIdTypeCodes");

const fetchFacilitySubtypeCodesData = () =>
  SitesService.getSiteMasterDataSync("FacilitySubtypeCodes");

const fetchFacilityTypeCodesData = () =>
  SitesService.getSiteMasterDataSync("FacilityTypeCodes");

const fetchMarketTypesCodesData = () =>
  SitesService.getSiteMasterDataSync("MarketTypes");

const fetchNeraRiskCodesData = () =>
  SitesService.getSiteMasterDataSync("NeraRiskCodes");

const fetchNetworkSegmentCodesData = () =>
  SitesService.getSiteMasterDataSync("NetworkSegments");

const fetchOperatingPlatformCodesData = () =>
  SitesService.getSiteMasterDataSync("OperatingPlatformCodes");

const fetchOrgAffiliationCodesData = () =>
  SitesService.getSiteMasterDataSync("OrgAffiliationCodes");

const fetchOrgAffiliationTypeCodesData = () =>
  SitesService.getSiteMasterDataSync("OrgAffiliationTypeCodes");

const fetchParkingOwnerCodesData = () =>
  SitesService.getSiteMasterDataSync("ParkingOwnerCodes");

const fetchProductClassCodesData = () =>
  SitesService.getSiteMasterDataSync("ProductClassCodes");

const fetchRoadTypeCodesData = () =>
  SitesService.getSiteMasterDataSync("RoadTypeCodes");

const fetchSiteCategoryCodesData = () =>
  SitesService.getSiteMasterDataSync("SiteCategories");

const fetchSiteStatusCodesData = () =>
  SitesService.getSiteMasterDataSync("SiteStatusCodes");

const fetchSiteTypeCodesData = () =>
  SitesService.getSiteMasterDataSync("SiteTypeCodes");

const fetchTenureTypeCodesData = () =>
  SitesService.getSiteMasterDataSync("TenureTypeCodes");

const fetchVmiIndicatorCodesData = () =>
  SitesService.getSiteMasterDataSync("VmiIndicatorCodes");

const fetchDiffDiscountCodesData = () =>
  SitesService.getSiteMasterDataSync("DiffDiscountCodes");

const fetchUSACompanyCodesData = () =>
  SitesService.getSiteMasterDataSync("UsaCompanyCodes");

const fetchSiteOperatorNameCodesData = () =>
  SitesService.getSiteMasterDataSync("SiteOperatorCodes");

const fetchStationArchetypeCodeCodesData = () =>
  SitesService.getSiteMasterDataSync("StationArchetypeCodes");

const fetchBusinessApplicationNameCodesData = () =>
  SitesService.getSiteMasterDataSync("BusinessApplicationCodes");

const fetchFuelPressureCodesData = () =>
  SitesService.getSiteMasterDataSync("FuelPressureCodes");

const fetchSupportedVehicleNameCodesData = () =>
  SitesService.getSiteMasterDataSync("VehicleSupportedCodes");

const fetchTollGateLayoutCodesData = () => {
  return {
    data: {
      data: [
        { tollgateLayoutCd: "No", tollgateLayoutName: "No" },
        { tollgateLayoutCd: "Yes", tollgateLayoutName: "Yes" },
      ],
    },
  };
};

const fetchCustomerMissionCodesData = () =>
  SitesService.getSiteMasterDataSync("customermissioncodes");
const fetchBiodiversitySensitiveAreaCode = () =>
  SitesService.getSiteMasterDataSync("BiodiversitySensitiveAreaCode");
const fetchBaselineWaterStressCode = () =>
  SitesService.getSiteMasterDataSync("BaselineWaterStressCode");
const fetchFloodRiskCode = () =>
  SitesService.getSiteMasterDataSync("FloodRiskCode");

const fetchLegacySiteTypesCodesData = () =>
  SitesService.getSiteMasterDataSync("LegacySiteTypes");

const fetchEVChargerBrandCodesData = () =>
  SitesService.getSiteMasterDataSync("EVChargerBrands");

const fetchEVChargerOperatorCodesData = () =>
  SitesService.getSiteMasterDataSync("EVChargerOperators");

const fetchEVConnectorTypeCodesData = () =>
  SitesService.getSiteMasterDataSync("EVConnectorTypes");

const fetchPowerTypeCodesData = () =>
  SitesService.getSiteMasterDataSync("PowerTypes");

export const useMasterDataSet1 = () => {
  return useQuery([`master-data-set-1`], fetchMasterDataSet1, {
    staleTime: AppConst.VIEW_SITE_DETAILS_STALE_TIME,
    enabled: false,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    select: (data) => data?.data?.data,
  });
};

export const useMasterDataSet2 = () => {
  return useQuery([`master-data-set-2`], fetchMasterDataSet2, {
    staleTime: AppConst.VIEW_SITE_DETAILS_STALE_TIME,
    enabled: false,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    select: (data) => data?.data?.data,
  });
};

export const useTerritoryCodesData = (countryCd: string, enabled?: boolean) => {
  return useQuery([`territory-codes`, countryCd], fetchTerritoryCodesData, {
    staleTime: AppConst.VIEW_SITE_DETAILS_STALE_TIME,
    enabled: enabled,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    select: (data) => {
      const types = data?.data?.data?.map(
        (type: { territoryCd: string; territoryName: string }) => {
          return {
            id: type.territoryCd,
            value: type.territoryName,
          };
        }
      );
      return types;
    },
  });
};

export const useCountyCodesData = (countryCd: string, enabled?: boolean) => {
  return useQuery([`county-codes`, countryCd], fetchCountyCodesData, {
    staleTime: AppConst.VIEW_SITE_DETAILS_STALE_TIME,
    enabled: enabled,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    select: (data) => {
      const types = data?.data?.data?.map(
        (type: { countyCd: string; countyName: string }) => {
          return {
            id: type.countyCd,
            value: type.countyName,
          };
        }
      );
      return types;
    },
  });
};

export const useBrandCovenantCodesData = () => {
  return useQuery([`brand-covenant-codes`], fetchBrandCovenantCodesData, {
    staleTime: AppConst.VIEW_SITE_DETAILS_STALE_TIME,
    enabled: false,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    select: (data) => {
      const types = data?.data?.data?.map(
        (type: { brandCovenantCd: string; brandCovenantName: string }) => {
          return {
            id: type.brandCovenantCd,
            value: type.brandCovenantName,
          };
        }
      );
      return types;
    },
  });
};

export const useChargerOwnershipCodesData = () => {
  return useQuery([`charger-ownership-codes`], fetchChargerOwnershipCodesData, {
    staleTime: AppConst.VIEW_SITE_DETAILS_STALE_TIME,
    enabled: false,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    select: (data) => {
      const types = data?.data?.data?.map(
        (type: {
          chargerOwnershipCd: string;
          chargerOwnershipName: string;
        }) => {
          return {
            id: type.chargerOwnershipCd,
            value: type.chargerOwnershipName,
          };
        }
      );
      return types;
    },
  });
};

export const useCoreStatusCodesData = () => {
  return useQuery([`core-status-codes`], fetchCoreStatusCodesData, {
    staleTime: AppConst.VIEW_SITE_DETAILS_STALE_TIME,
    enabled: false,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    select: (data) => {
      const types = data?.data?.data?.map(
        (type: { coreStatusTypeCd: string; coreStatusTypeName: string }) => {
          return {
            id: type.coreStatusTypeCd,
            value: type.coreStatusTypeName,
          };
        }
      );
      return types;
    },
  });
};

export const useCountryCodesData = () => {
  return useQuery([`country-codes`], fetchCountryCodesData, {
    staleTime: AppConst.VIEW_SITE_DETAILS_STALE_TIME,
    enabled: false,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    select: (data) => {
      const types = data?.data?.data?.map(
        (type: { countryCd: string; countryName: string }) => {
          return {
            id: type.countryCd,
            value: type.countryName,
          };
        }
      );
      return types;
    },
  });
};

export const useCountryDiallingCodeData = (
  countryCd?: string,
  enabled?: boolean
) => {
  return useQuery(
    [`country-dialling-codes`, countryCd],
    fetchCountryDiallingCodesData,
    {
      staleTime: AppConst.VIEW_SITE_DETAILS_STALE_TIME,
      enabled: enabled,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      select: (data) => {
        const types = data?.data?.data?.map(
          (type: { countryCd: string; countryDiallingCd: string }) => {
            return {
              id: type.countryCd,
              value: type.countryDiallingCd,
            };
          }
        );
        const countryDiallingCode = types?.find((type: { id: string }) => {
          return type.id === countryCd;
        });
        return countryDiallingCode;
      },
    }
  );
};

export const useCrFormatTypeCodesData = () => {
  return useQuery([`cr-format-type-codes`], fetchCrFormatTypeCodesData, {
    staleTime: AppConst.VIEW_SITE_DETAILS_STALE_TIME,
    enabled: false,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    select: (data) => {
      const types = data?.data?.data?.map(
        (type: { crFormatTypeCd: string; crFormatTypeName: string }) => {
          return {
            id: type.crFormatTypeCd,
            value: type.crFormatTypeName,
          };
        }
      );
      return types;
    },
  });
};

export const useCrModelCodesData = () => {
  return useQuery([`cr-model-codes`], fetchCrModelCodesData, {
    staleTime: AppConst.VIEW_SITE_DETAILS_STALE_TIME,
    enabled: false,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    select: (data) => {
      const types = data?.data?.data?.map(
        (type: { crModelCd: string; crModelName: string }) => {
          return {
            id: type.crModelCd,
            value: type.crModelName,
          };
        }
      );
      return types;
    },
  });
};

export const useCrSiteShopTypeCodesData = () => {
  return useQuery([`cr-site-shop-type-codes`], fetchCrSiteShopTypeCodesData, {
    staleTime: AppConst.VIEW_SITE_DETAILS_STALE_TIME,
    enabled: false,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    select: (data) => {
      const types = data?.data?.data?.map(
        (type: { crSiteShopTypeCd: string; crSiteShopTypeName: string }) => {
          return {
            id: type.crSiteShopTypeCd,
            value: type.crSiteShopTypeName,
          };
        }
      );
      return types;
    },
  });
};

export const useCurrencyCodesData = () => {
  return useQuery([`currency-codes`], fetchCurrencyCodesData, {
    staleTime: AppConst.VIEW_SITE_DETAILS_STALE_TIME,
    enabled: false,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    select: (data) => {
      const types = data?.data?.data?.map(
        (type: { currencyCd: string; currencyName: string }) => {
          return {
            id: type.currencyCd,
            value: type.currencyName,
          };
        }
      );
      return types;
    },
  });
};

export const useDestinationHostCodesData = () => {
  return useQuery([`destination-host-codes`], fetchDestinationHostCodesData, {
    staleTime: AppConst.VIEW_SITE_DETAILS_STALE_TIME,
    enabled: false,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    select: (data) => {
      const types = data?.data?.data?.map(
        (type: { destinationHostCd: string; destinationHostName: string }) => {
          return {
            id: type.destinationHostCd,
            value: type.destinationHostName,
          };
        }
      );
      return types;
    },
  });
};

export const useEmployeeTypeCodesData = () => {
  return useQuery([`employee-type-codes`], fetchEmployeeTypeCodesData, {
    staleTime: AppConst.VIEW_SITE_DETAILS_STALE_TIME,
    enabled: false,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    select: (data) => {
      const types = data?.data?.data?.map(
        (type: {
          siteEmployeeTypeCd: string;
          siteEmployeeTypeName: string;
        }) => {
          return {
            id: type.siteEmployeeTypeCd,
            value: type.siteEmployeeTypeName,
          };
        }
      );
      return types;
    },
  });
};

export const useExternalIdTypeCodesData = () => {
  return useQuery([`external-id-type-codes`], fetchExternalIdTypeCodesData, {
    staleTime: AppConst.VIEW_SITE_DETAILS_STALE_TIME,
    enabled: false,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    select: (data) => {
      const types = data?.data?.data?.map(
        (type: { externalIdTypeCd: string; externalIdTypeName: string }) => {
          return {
            id: type.externalIdTypeCd,
            value: type.externalIdTypeName,
          };
        }
      );
      return types;
    },
  });
};

export const useFacilitySubtypeCodesData = () => {
  return useQuery([`facility-subtype-codes`], fetchFacilitySubtypeCodesData, {
    staleTime: AppConst.VIEW_SITE_DETAILS_STALE_TIME,
    enabled: false,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    select: (data) => {
      const types = data?.data?.data?.map(
        (type: {
          facilitySubTypeCd: string;
          facilitySubTypeName: string;
          facilityTypeCd: string;
        }) => {
          return {
            id: type.facilitySubTypeCd,
            value: type.facilitySubTypeName || "",
            facilityTypeCd: type.facilityTypeCd,
          };
        }
      );
      return types;
    },
  });
};

export const useFacilityTypeCodesData = () => {
  return useQuery([`facility-type-codes`], fetchFacilityTypeCodesData, {
    staleTime: AppConst.VIEW_SITE_DETAILS_STALE_TIME,
    enabled: false,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    select: (data) => {
      const types = data?.data?.data?.map(
        (type: { facilityTypeCd: string; facilityTypeName: string }) => {
          return {
            id: type.facilityTypeCd,
            value: type.facilityTypeName,
          };
        }
      );
      return types;
    },
  });
};

export const useMarketTypesCodesData = () => {
  return useQuery([`market-type-codes`], fetchMarketTypesCodesData, {
    staleTime: AppConst.VIEW_SITE_DETAILS_STALE_TIME,
    enabled: false,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    select: (data) => {
      const types = data?.data?.data?.map(
        (type: { localMarketTypeCd: string; localMarketTypeName: string }) => {
          return {
            id: type.localMarketTypeCd,
            value: type.localMarketTypeName || "",
          };
        }
      );
      return types;
    },
  });
};

export const useNeraRiskCodesData = () => {
  return useQuery([`nera-risk-codes`], fetchNeraRiskCodesData, {
    staleTime: AppConst.VIEW_SITE_DETAILS_STALE_TIME,
    enabled: false,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    select: (data) => {
      const types = data?.data?.data?.map(
        (type: { neraRiskCd: string; neraRiskName: string }) => {
          return {
            id: type.neraRiskCd,
            value: type.neraRiskName,
          };
        }
      );
      return types;
    },
  });
};

export const useNetworkSegmentCodesData = () => {
  return useQuery([`network-segment-codes`], fetchNetworkSegmentCodesData, {
    staleTime: AppConst.VIEW_SITE_DETAILS_STALE_TIME,
    enabled: false,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    select: (data) => {
      const types = data?.data?.data?.map(
        (type: { networkSegmentCd: string; networkSegmentName: string }) => {
          return {
            id: type.networkSegmentCd,
            value: type.networkSegmentName,
          };
        }
      );
      return types;
    },
  });
};

export const useOperatingPlatformCodesData = () => {
  return useQuery(
    [`site-operating-platform-codes`],
    fetchOperatingPlatformCodesData,
    {
      staleTime: AppConst.VIEW_SITE_DETAILS_STALE_TIME,
      enabled: false,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      select: (data) => {
        const types = data?.data?.data?.map(
          (type: {
            operatingPlatformCd: string;
            operatingPlatformName: string;
          }) => {
            return {
              id: type.operatingPlatformCd,
              value: type.operatingPlatformName,
            };
          }
        );
        return types;
      },
    }
  );
};

export const useOrgAffiliationCodesData = () => {
  return useQuery([`org-affiliation-codes`], fetchOrgAffiliationCodesData, {
    staleTime: AppConst.VIEW_SITE_DETAILS_STALE_TIME,
    enabled: false,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    select: (data) => {
      const types = data?.data?.data?.map(
        (type: { orgAffiliationCd: string; orgAffiliationName: string }) => {
          return {
            id: type.orgAffiliationCd,
            value: type.orgAffiliationName,
          };
        }
      );
      return types;
    },
  });
};

export const useOrgAffiliationTypeCodesData = () => {
  return useQuery(
    [`org-affiliation-type-codes`],
    fetchOrgAffiliationTypeCodesData,
    {
      staleTime: AppConst.VIEW_SITE_DETAILS_STALE_TIME,
      enabled: false,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      select: (data) => {
        const types = data?.data?.data?.map(
          (type: {
            orgAffiliationTypeCd: string;
            orgAffiliationTypeName: string;
          }) => {
            return {
              id: type.orgAffiliationTypeCd,
              value: type.orgAffiliationTypeName || "",
            };
          }
        );
        return types;
      },
    }
  );
};

export const useParkingOwnerCodesData = () => {
  return useQuery([`parking-owner-codes`], fetchParkingOwnerCodesData, {
    staleTime: AppConst.VIEW_SITE_DETAILS_STALE_TIME,
    enabled: false,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    select: (data) => {
      const types = data?.data?.data?.map(
        (type: { parkingOwnerCd: string; parkingOwnerName: string }) => {
          return {
            id: type.parkingOwnerCd,
            value: type.parkingOwnerName,
          };
        }
      );
      return types;
    },
  });
};

export const useProductClassCodesData = () => {
  return useQuery([`product-class-codes`], fetchProductClassCodesData, {
    staleTime: AppConst.VIEW_SITE_DETAILS_STALE_TIME,
    enabled: false,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    select: (data) => {
      const types = data?.data?.data?.map(
        (type: { productClassCd: string; productClassName: string }) => {
          return {
            id: type.productClassCd,
            value: type.productClassName,
          };
        }
      );
      return types;
    },
  });
};

export const useRoadTypeCodesData = () => {
  return useQuery([`road-type-codes`], fetchRoadTypeCodesData, {
    staleTime: AppConst.VIEW_SITE_DETAILS_STALE_TIME,
    enabled: false,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    select: (data) => {
      const types = data?.data?.data?.map(
        (type: { roadTypeCd: string; roadTypeName: string }) => {
          return {
            id: type.roadTypeCd,
            value: type.roadTypeName,
          };
        }
      );
      return types;
    },
  });
};

export const useSiteCategoryCodesData = () => {
  return useQuery([`site-category-codes`], fetchSiteCategoryCodesData, {
    staleTime: AppConst.VIEW_SITE_DETAILS_STALE_TIME,
    enabled: true,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    select: (data) => {
      const types = data?.data?.data?.map(
        (type: { siteCategoryCd: string }, index: number) => {
          return {
            id: index?.toString(),
            value: type.siteCategoryCd,
          };
        }
      );
      return types;
    },
  });
};

export const useSiteStatusCodesData = () => {
  return useQuery([`site-Status-codes`], fetchSiteStatusCodesData, {
    staleTime: AppConst.VIEW_SITE_DETAILS_STALE_TIME,
    enabled: false,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    select: (data) => {
      const statusData = data?.data?.data?.map(
        (status: { siteStatusCd: string; siteStatusName: string }) => {
          return {
            id: status.siteStatusCd,
            value: status.siteStatusName,
          };
        }
      );
      return statusData;
    },
  });
};

export const useSiteTypeCodesData = () => {
  return useQuery([`site-type-codes`], fetchSiteTypeCodesData, {
    staleTime: AppConst.VIEW_SITE_DETAILS_STALE_TIME,
    enabled: false,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    select: (data) => {
      const types = data?.data?.data?.map(
        (type: { siteTypeCd: string; siteTypeName: string }) => {
          return {
            id: type.siteTypeCd,
            value: type.siteTypeName,
          };
        }
      );
      return types;
    },
  });
};

export const useTenureTypeCodesData = () => {
  return useQuery([`tenure-type-codes`], fetchTenureTypeCodesData, {
    staleTime: AppConst.VIEW_SITE_DETAILS_STALE_TIME,
    enabled: false,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    select: (data) => {
      const types = data?.data?.data?.map(
        (type: { tenureTypeCd: string; tenureTypeName: string }) => {
          return {
            id: type.tenureTypeCd,
            value: type.tenureTypeName,
          };
        }
      );
      return types;
    },
  });
};

export const useVmiIndicatorCodesData = () => {
  return useQuery([`vmi-indicator-codes`], fetchVmiIndicatorCodesData, {
    staleTime: AppConst.VIEW_SITE_DETAILS_STALE_TIME,
    enabled: false,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    select: (data) => {
      const types = data?.data?.data?.map(
        (type: { vmiIndicatorCd: string; vmiIndicatorName: string }) => {
          return {
            id: type.vmiIndicatorCd,
            value: type.vmiIndicatorName,
          };
        }
      );
      return types;
    },
  });
};

export const useDiffDiscountCodesData = () => {
  return useQuery([`diff-discount-codes`], fetchDiffDiscountCodesData, {
    staleTime: AppConst.VIEW_SITE_DETAILS_STALE_TIME,
    enabled: false,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    select: (data) => {
      const types = data?.data?.data?.map(
        (type: { differentiatedDiscountGroupCd: string }) => {
          return {
            id: type.differentiatedDiscountGroupCd,
            value: type.differentiatedDiscountGroupCd,
          };
        }
      );
      return types;
    },
  });
};

export const useUSACompanyCodesData = () => {
  return useQuery([`usa-company-codes`], fetchUSACompanyCodesData, {
    staleTime: AppConst.VIEW_SITE_DETAILS_STALE_TIME,
    enabled: false,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    select: (data) => {
      const types = data?.data?.data?.map(
        (type: { usaCompanyCd: string; usaCompanyDesc: string }) => {
          return {
            id: type.usaCompanyCd,
            value: type.usaCompanyDesc,
          };
        }
      );
      return types;
    },
  });
};

export const useSiteOperatorNameCodesData = () => {
  return useQuery(
    [`hy-site-operator-name-codes`],
    fetchSiteOperatorNameCodesData,
    {
      staleTime: AppConst.VIEW_SITE_DETAILS_STALE_TIME,
      enabled: false,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      select: (data) => {
        const types = data?.data?.data?.map(
          (type: { siteOperatorCd: string }) => {
            return {
              id: type.siteOperatorCd,
              value: type.siteOperatorCd,
            };
          }
        );
        return types;
      },
    }
  );
};

export const useStationArchetypeCodeCodesData = () => {
  return useQuery(
    [`hy-station-archetype-code-codes`],
    fetchStationArchetypeCodeCodesData,
    {
      staleTime: AppConst.VIEW_SITE_DETAILS_STALE_TIME,
      enabled: false,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      select: (data) => {
        const types = data?.data?.data?.map(
          (type: {
            stationArchetypeCd: string;
            stationArchetypeCodeName: string;
          }) => {
            return {
              id: type.stationArchetypeCd,
              value: type.stationArchetypeCd,
            };
          }
        );
        return types;
      },
    }
  );
};

export const useBusinessApplicationNameCodesData = () => {
  return useQuery(
    [`hy-business-app-name-codes`],
    fetchBusinessApplicationNameCodesData,
    {
      staleTime: AppConst.VIEW_SITE_DETAILS_STALE_TIME,
      enabled: false,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      select: (data) => {
        //todo check keys - no desc/name provided
        const types = data?.data?.data?.map(
          (type: { businessApplicationCd: string }) => {
            return {
              id: type.businessApplicationCd,
              value: type.businessApplicationCd,
            };
          }
        );
        return types;
      },
    }
  );
};

export const useFuelPressureCodesData = () => {
  return useQuery([`hy-fuel-pressure-codes`], fetchFuelPressureCodesData, {
    staleTime: AppConst.VIEW_SITE_DETAILS_STALE_TIME,
    enabled: false,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    select: (data) => {
      //todo check keys - no desc/name provided
      const types = data?.data?.data?.map(
        (type: { fuelPressureCd: string }) => {
          return {
            id: type.fuelPressureCd,
            value: type.fuelPressureCd,
          };
        }
      );
      return types;
    },
  });
};

export const useSupportedVehicleNameCodesData = () => {
  return useQuery(
    [`hy-supported-vehicle-name-codes`],
    fetchSupportedVehicleNameCodesData,
    {
      staleTime: AppConst.VIEW_SITE_DETAILS_STALE_TIME,
      enabled: false,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      select: (data) => {
        //todo check keys - no desc/name provided
        const types = data?.data?.data?.map(
          (type: { vehicleSupportedCd: string }) => {
            return {
              id: type.vehicleSupportedCd,
              value: type.vehicleSupportedCd,
            };
          }
        );
        return types;
      },
    }
  );
};

export const useTollGateLayoutCodesData = () => {
  return useQuery([`toll-gate-layout-codes`], fetchTollGateLayoutCodesData, {
    staleTime: AppConst.VIEW_SITE_DETAILS_STALE_TIME,
    enabled: false,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    select: (data) => {
      const types = data?.data?.data?.map(
        (type: { tollgateLayoutCd: string; tollgateLayoutName: string }) => {
          return {
            id: type.tollgateLayoutCd,
            value: type.tollgateLayoutName,
          };
        }
      );
      return types;
    },
  });
};

export const useCustomerMissionCodesData = () => {
  return useQuery([`customer-mission-codes`], fetchCustomerMissionCodesData, {
    staleTime: AppConst.VIEW_SITE_DETAILS_STALE_TIME,
    enabled: false,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    select: (data) => {
      const types = data?.data?.data?.map(
        (type: { customermissioncode: string }) => {
          return {
            id: type.customermissioncode,
            value: type.customermissioncode,
          };
        }
      );
      return types;
    },
  });
};
export const useFloodRiskData = () => {
  return useQuery([`Flood-Risk-Code`], fetchFloodRiskCode, {
    staleTime: AppConst.VIEW_SITE_DETAILS_STALE_TIME,
    enabled: false,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    select: (data) => {
      const types = data?.data?.data?.map(
        (type: { floodRiskCd: string }) => {
          return {
            id: type.floodRiskCd,
            value: type.floodRiskCd,
          };
        }
      );
      return types;
    },
  });
};
export const useBaselineWaterStressData = () => {
  return useQuery([`Baseline-Water-Stress-Code`], fetchBaselineWaterStressCode, {
    staleTime: AppConst.VIEW_SITE_DETAILS_STALE_TIME,
    enabled: false,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    select: (data) => {
      const types = data?.data?.data?.map(
        (type: { baselineWaterStressCd: string }) => {
          return {
            id: type.baselineWaterStressCd,
            value: type.baselineWaterStressCd,
          };
        }
      );
      return types;
    },
  });
};

export const useBiodiversitySensitiveAreaData = () => {
  return useQuery([`Biodiversity-Sensitive-Area-Code`], fetchBiodiversitySensitiveAreaCode, {
    staleTime: AppConst.VIEW_SITE_DETAILS_STALE_TIME,
    enabled: false,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    select: (data) => {
      const types = data?.data?.data?.map(
        (type: { biodiversitySensitiveAreaCd: string }) => {
          return {
            id: type.biodiversitySensitiveAreaCd,
            value: type.biodiversitySensitiveAreaCd,
           
            
          };
        }
      );
      return types;
    },
  });
};


export const useLegacySiteTypesCodesData = () => {
  return useQuery([`legacy-site-type-codes`], fetchLegacySiteTypesCodesData, {
    staleTime: AppConst.VIEW_SITE_DETAILS_STALE_TIME,
    enabled: false,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    select: (data) => {
      const types = data?.data?.data?.map(
        (type: { legacySiteTypeCd: string; legacySiteTypeName: string }) => {
          return {
            id: type.legacySiteTypeCd,
            value: type.legacySiteTypeName,
          };
        }
      );
      return types;
    },
  });
};

export const useEVChargerBrandCodesData = () => {
  return useQuery([`ev-charger-brand-codes`], fetchEVChargerBrandCodesData, {
    staleTime: AppConst.VIEW_SITE_DETAILS_STALE_TIME,
    enabled: false,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    select: (data) => {
      const types = data?.data?.data?.map(
        (type: { evChargerBrandCd: string }) => {
          return {
            id: type.evChargerBrandCd,
            value: type.evChargerBrandCd,
          };
        }
      );
      return types;
    },
  });
};

export const useEVChargerOperatorCodesData = () => {
  return useQuery(
    [`ev-charger-operator-codes`],
    fetchEVChargerOperatorCodesData,
    {
      staleTime: AppConst.VIEW_SITE_DETAILS_STALE_TIME,
      enabled: false,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      select: (data) => {
        const uniqueOperatorList = data?.data?.data
          .map(
            (item: { evChargerOperatorCd: string }) => item.evChargerOperatorCd
          )
          .filter(
            (value: any, index: number, self: any) =>
              self.indexOf(value) === index
          );
        const types = uniqueOperatorList?.map((type: string) => {
          return {
            id: type,
            value: type,
          };
        });
        return types;
      },
    }
  );
};

export const useEVConnectorTypeCodesData = () => {
  return useQuery([`ev-connector-type-codes`], fetchEVConnectorTypeCodesData, {
    staleTime: AppConst.VIEW_SITE_DETAILS_STALE_TIME,
    enabled: false,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    select: (data) => {
      const types = data?.data?.data?.map(
        (type: { evConnectorTypeCd: string }) => {
          return {
            id: type.evConnectorTypeCd,
            value: type.evConnectorTypeCd,
          };
        }
      );
      return types;
    },
  });
};

export const useEVPowerTypeCodesData = () => {
  return useQuery([`ev-power-type-codes`], fetchPowerTypeCodesData, {
    staleTime: AppConst.VIEW_SITE_DETAILS_STALE_TIME,
    enabled: false,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    select: (data) => {
      const types = data?.data?.data?.map((type: { powerTypeCd: string }) => {
        return {
          id: type.powerTypeCd,
          value: type.powerTypeCd,
        };
      });
      return types;
    },
  });
};
