import { useEffect, useState } from "react";
import {
  Input,
  InputChangeEvent,
  NumericTextBox,
  NumericTextBoxChangeEvent,
} from "@progress/kendo-react-inputs";
import {
  DropDownList,
  DropDownListChangeEvent,
} from "@progress/kendo-react-dropdowns";
import { Error } from "@progress/kendo-react-labels";
import { Button } from "@progress/kendo-react-buttons";
import { Tooltip } from "@progress/kendo-react-tooltip";
import { Skeleton } from "@progress/kendo-react-indicators";

import {
  useCountryCodesData,
  useEVChargerBrandCodesData,
  useEVChargerOperatorCodesData,
  useEVConnectorTypeCodesData,
  useEVPowerTypeCodesData,
} from "../../../../../../../../hooks";
import { Locale } from "../../../../../../../../utils/localization";

export const EVChargePostsTab = (props: any) => {
  const {
    isEditing,
    evDetailsTabSelected,
    onPatchRequestValueSet,
    isSectionValueChanged,
    onSectionValueChanged,
    sectionEditedData,
    onSectionValueSet,
    fetchedEVChargePostData,
    isEVChargePostDataLoading,
    isCreate,
    refetchAllData,
    siteId,
  } = props;

  const [currentData, setCurrentData] = useState<any>();
  const [savedData, setSavedData] = useState<any>();

  const [evChargerConnectorData, setEvChargerConnectorData] = useState<any>();
  const [isAddNewPostDisabled, setIsAddNewPostDisabled] = useState(false);
  const [isAddNewConnectorDisabled, setIsAddNewConnectorDisabled] =
    useState(false);
  const [selectedPostId, setSelectedPostId] = useState("");

  const [newChargePostId, setNewChargePostId] = useState("");
  const [isNewChargePostIdValid, setIsNewChargePostIdValid] = useState(0);
  const [newChargerBrandSelectedValue, setNewChargerBrandSelectedValue] =
    useState<any>();
  const [isNewChargerBrandValid, setIsNewChargerBrandValid] = useState(0);
  const [newChargerOperatorSelectedValue, setNewChargerOperatorSelectedValue] =
    useState<any>();
  const [isNewChargerOperatorValid, setIsNewChargerOperatorValid] = useState(0);
  const [newCountrySelectedValue, setCountrySelectedValue] = useState<any>();
  const [isCountryValid, setIsCountryValid] = useState(0);
  const [newOCPIEVSEId, setNewOCPIEVSEId] = useState("");
  const [newExternalEvseId, setNewExternalEvseId] = useState("");
  const [isNewOCPIEVSEIdValid, setIsNewOCPIEVSEIdValid] = useState(0);
  const [isNewExternalEvseIdValid, setIsNewExternalEvseIdValid] = useState(0);
  const [newConnectorTypeSelectedValue, setNewConnectorTypeSelectedValue] =
    useState<any>();
  const [newPowerTypeSelectedValue, setNewPowerTypeSelectedValue] =
    useState<any>();
  const [isNewPowerTypeValid, setIsNewPowerTypeValid] = useState(0);
  const [isNewConnectorTypeValid, setIsNewConnectorTypeValid] = useState(0);
  const [isNewPowerKWValid, setIsNewPowerKWValid] = useState(0);
  const [newPowerKW, setNewPowerKW] = useState("");

  const [isNewChargePostRowValid, setIsNewChargePostRowValid] = useState(0);
  const [newChargePostRowInvalidText, setNewChargePostRowInvalidText] =
    useState("");

  const [isNewConnectorRowValid, setIsNewConnectorRowValid] = useState(0);
  const [newConnectorRowInvalidText, setNewConnectorRowInvalidText] =
    useState("");
  const [defaultPostData, setDefaultPostData] = useState<any>([]);

  const [payloadToSend, setPayloadToSend] = useState<any[]>([]);

  const {
    isLoading: isEVChargerBrandCodesLoading,
    data: evChargerBrandCodes,
    refetch: refetchEVChargerBrandCodes,
  } = useEVChargerBrandCodesData();

  const {
    isLoading: isEVChargerOperatorCodesLoading,
    data: evChargerOperatorCodes,
    refetch: refetchEVChargerOperatorCodes,
  } = useEVChargerOperatorCodesData();

  const {
    isLoading: isCountryCodesLoading,
    data: countryCodes,
    refetch: refetchCountryCodes,
  } = useCountryCodesData();

  const {
    isLoading: isEVPowerTypeCodesLoading,
    data: evPowerTypeCodes,
    refetch: refetchEVPowerTypeCodes,
  } = useEVPowerTypeCodesData();

  const {
    isLoading: isEVConnectorTypeCodesLoading,
    data: evConnectorTypeCodes,
    refetch: refetchEVConnectorTypeCodes,
  } = useEVConnectorTypeCodesData();

  useEffect(() => {
    if (isEditing && evDetailsTabSelected === 1) {
      refetchEVChargerBrandCodes();
      refetchEVChargerOperatorCodes();
      refetchEVPowerTypeCodes();
      refetchEVConnectorTypeCodes();
      refetchCountryCodes();
      setSelectedPostId("");
    }
  }, [isEditing]);

  useEffect(() => {
    let list: any = [];
    if (isSectionValueChanged && sectionEditedData?.EVCharger) {
      list = sectionEditedData?.EVCharger?.map((item: any, index: number) => {
        return {
          id: index,
          ...item,
        };
      });
    } else if (fetchedEVChargePostData) {
      list = fetchedEVChargePostData?.map((item: any, index: number) => {
        return {
          id: index,
          ...item,
        };
      });
    }
    setCurrentData([...list]);
    setSavedData([...list]);
    setEvChargerConnectorData({});
    setPayloadToSend([]);
  }, [isEditing, isEVChargePostDataLoading]);

  useEffect(() => {
    if (refetchAllData && siteId) {
      setPayloadToSend([]);
    }
  }, [refetchAllData]);

  const getChangeClassName = (defaultData: any, row: any, field: string) => {
    const d = defaultData?.find((i: any) => i.index === row?.id);
    if (d?.[field] !== row?.[field] && !isCreate) {
      return `${field}-changed`;
    }
    return "";
  };

  const getConnectorChangeClassName = (
    defaultData: any,
    row: any,
    field: string
  ) => {
    const d = defaultData?.find((i: any) => i.connectorId === row?.connectorId);
    if (!isCreate) {
      if (
        (field === "powerTypeCd" &&
          d?.[field]?.powerTypeCd !== row?.[field]?.powerTypeCd) ||
        (field === "connectorTypeCd" &&
          d?.[field]?.connectorTypeCd !== row?.[field]?.connectorTypeCd) ||
        (field === "powerKW" && d?.[field] !== row?.[field])
      ) {
        return `${field}-changed`;
      }
      return "";
    }
    return "";
  };

  const getTooltipText = (defaultData: any, row: any, field: string) => {
    const d = defaultData?.find((i: any) => i.index === row?.id);
    if (d?.[field] !== row?.[field]) {
      return d?.[field] || "";
    }
    return "";
  };

  const getConnectionTooltipText = (
    defaultData: any,
    row: any,
    field: string
  ) => {
    const d = defaultData?.find(
      (i: any) => i?.connectorId === row?.connectorId
    );
    if (
      field === "powerTypeCd" &&
      d?.[field]?.powerTypeCd !== row?.[field]?.powerTypeCd
    ) {
      return d?.[field]?.powerTypeCd || "";
    } else if (
      field === "connectorTypeCd" &&
      d?.[field]?.connectorTypeCd !== row?.[field]?.connectorTypeCd
    ) {
      return d?.[field]?.connectorTypeCd || "";
    } else if (field === "powerKW" && d?.[field] !== row?.[field]) {
      return d?.[field] || "";
    }
    return "";
  };

  const showConnectorData = (data: any) => {
    const newConnectorData = data?.EVChargerConnector?.map(
      (item: any, index: number) => {
        return {
          id: index,
          ...item,
        };
      }
    );
    const newPostData = {
      ...data,
      EVChargerConnector: newConnectorData,
    };
    setEvChargerConnectorData(newPostData);
    setSelectedPostId(data.id);
    var elems = document.querySelectorAll(".selected");
    [].forEach.call(elems, (el: any) => {
      el.classList.remove("selected");
    });

    let element: any = document.getElementById(`post-${data.id}`);
    element.classList.add("selected");
  };

  const onAddNewPost = () => {
    setNewChargePostId("");
    setNewChargerBrandSelectedValue(undefined);
    setNewChargerOperatorSelectedValue(undefined);
    setCountrySelectedValue(undefined);
    setNewOCPIEVSEId("");
    setNewExternalEvseId("");
    setIsNewChargePostIdValid(0);
    setIsNewChargerBrandValid(0);
    setIsNewChargerOperatorValid(0);
    setIsCountryValid(0);
    setIsNewOCPIEVSEIdValid(0);
    setIsNewExternalEvseIdValid(0);
    setIsNewChargePostRowValid(0);
    setIsAddNewPostDisabled(true);
  };

  const onAddNewConnector = () => {
    setIsNewConnectorRowValid(1);
    setIsNewPowerKWValid(1);
    setIsNewPowerTypeValid(1);
    setIsNewConnectorTypeValid(1);
    setNewConnectorTypeSelectedValue(undefined);
    setNewPowerTypeSelectedValue(undefined);
    setNewPowerKW("");
    setIsAddNewConnectorDisabled(true);
  };

  const editRowData = (row: any) => {
    var element: any = document.getElementById(`post-${row.id}`);
    element.classList.add("edit-row");
  };

  const editConnectorRowData = (row: any) => {
    var element: any = document.getElementById(`connector-${row.connectorId}`);
    element.classList.add("edit-row");
  };

  const discardRowData = (row: any) => {
    setCurrentData([...savedData]);
    var element: any = document.getElementById(`post-${row.id}`);
    element.classList.remove("edit-row");
  };

  const saveRowData = (row: any) => {
    let isValid: boolean = true;

    let newCurrentState: any = [...(currentData || [])];

    if (!row?.chargepostId || !row?.ocpiEvseId) {
      isValid = false;
      newCurrentState = currentData?.map((currentRow: any) => {
        if (currentRow.id === row.id) {
          return {
            ...currentRow,
            isValid: false,
            validationMessage:
              Locale.create_site?.error_messages
                .ev_charge_posts_required_fields,
          };
        }
        return currentRow;
      });
    }

    setCurrentData([...newCurrentState]);

    if (isValid) {
      setSavedData([...currentData]);
      onSectionValueChanged(true);
      onSectionValueSet({
        EVCharger: [...currentData],
      });

      const evChargeConnectorData = row?.EVChargerConnector?.map(
        (conn: any) => {
          if (conn?.connectorId?.includes("custom-")) {
            const { connectorId, id, ...rest } = conn;
            return { ...rest };
          } else {
            const { id, ...rest } = conn;
            return { ...rest };
          }
        }
      );

      const payload = {
        id: row?.id,
        chargepostId: row?.chargepostId,
        evChargerBrandCd: {
          evChargerBrandCd: row?.evChargerBrandCd,
        },
        evChargerOperatorCd: {
          evChargerOperatorCd: row?.evChargerOperatorCd,
        },
        countryCd: {
          countryCd: row?.countryCd,
        },
        ocpiEvseId: row?.ocpiEvseId,

        ...(row?.externalEvseId && {
          externalEvseId: row?.externalEvseId,
        }),
        ...(row?.evseId && {
          evseId: row.evseId,
        }),
        deleteInd: row?.deleteInd,
        EVChargerConnector: {
          item: evChargeConnectorData,
        },
      };
      const filteredData = payloadToSend?.filter(
        (item: { id: number }) => item?.id !== row?.id
      );
      setPayloadToSend([...filteredData, { ...payload }]);
      const { id, ...restPayload } = payload;
      const newFilteredData = filteredData?.map(
        ({ id, ...keepAttrs }) => keepAttrs
      );
      onPatchRequestValueSet(
        {
          EVCharger: {
            item: [...newFilteredData, { ...restPayload }],
          },
        },
        true
      );

      var element: any = document.getElementById(`post-${row.id}`);
      element.classList.remove("edit-row");
    }
    return isValid;
  };

  const saveConnectorRowData = (row: any) => {
    let isValid: boolean = true;

    if (!row?.powerKW) {
      isValid = false;
      const selectedChargePost = currentData?.find((currentRow: any) => {
        return currentRow.id === selectedPostId;
      });

      const connectorData = selectedChargePost?.EVChargerConnector || [];
      const newConnectionState = connectorData?.map((currentRow: any) => {
        if (currentRow.id === row.id) {
          return {
            ...currentRow,
            isValid: false,
            validationMessage:
              Locale.create_site?.error_messages
                .ev_charge_posts_required_fields,
          };
        }
        return currentRow;
      });

      const newCurrentData = [...currentData]?.map((currentRow: any) => {
        if (currentRow?.id === selectedPostId) {
          return { ...currentRow, EVChargerConnector: newConnectionState };
        }
        return currentRow;
      });
      setCurrentData([...newCurrentData]);
      setEvChargerConnectorData({
        EVChargerConnector: [...newConnectionState],
      });
    }

    if (isValid) {
      var element: any = document.getElementById(
        `connector-${row.connectorId}`
      );
      element.classList.remove("edit-row");
      const currentRowFilteredData = [...currentData].find(
        (post) => post?.id === selectedPostId
      );
      setEvChargerConnectorData(currentRowFilteredData || {});
      setSavedData([...currentData]);
      onSectionValueChanged(true);
      onSectionValueSet({
        EVCharger: [...currentData],
      });

      const evChargeConnectorData =
        currentRowFilteredData?.EVChargerConnector?.map((conn: any) => {
          if (conn?.connectorId?.includes("custom-")) {
            const { connectorId, id, ...rest } = conn;
            return { ...rest };
          } else {
            const { id, ...rest } = conn;
            return { ...rest };
          }
        });

      const payload = {
        id: currentRowFilteredData?.id,
        chargepostId: currentRowFilteredData?.chargepostId,
        evChargerBrandCd: {
          evChargerBrandCd: currentRowFilteredData?.evChargerBrandCd,
        },
        evChargerOperatorCd: {
          evChargerOperatorCd: currentRowFilteredData?.evChargerOperatorCd,
        },
        countryCd: {
          countryCd: currentRowFilteredData?.countryCd,
        },
        ocpiEvseId: currentRowFilteredData?.ocpiEvseId,

        ...(currentRowFilteredData?.externalEvseId && {
          externalEvseId: currentRowFilteredData?.externalEvseId,
        }),
        ...(currentRowFilteredData?.evseId && {
          evseId: currentRowFilteredData.evseId,
        }),
        deleteInd: currentRowFilteredData?.deleteInd,
        EVChargerConnector: {
          item: evChargeConnectorData,
        },
      };
      const filteredData = payloadToSend?.filter(
        (item: { id: number }) => item?.id !== +selectedPostId
      );
      setPayloadToSend([...filteredData, { ...payload }]);
      const { id, ...restPayload } = payload;
      const newFilteredData = filteredData?.map(
        ({ id, ...keepAttrs }) => keepAttrs
      );
      onPatchRequestValueSet(
        {
          EVCharger: {
            item: [...newFilteredData, { ...restPayload }],
          },
        },
        true
      );
    }
  };

  const onEdit = (value: any, id: string, field: string) => {
    const newCurrentState = [...(currentData || [])].map((row: any) => {
      const { isValid, validationMessage, ...allData } = row;
      return allData;
    });

    const newData = [...newCurrentState].map((post) => {
      if (id === post?.id) {
        if (field === "countryCd") {
          return { ...post, [field]: value.id, countryName: value.value };
        }
        return { ...post, [field]: value };
      }
      return { ...post };
    });
    setCurrentData([...newData]);
  };

  const onConnectorEdit = (value: string, id: string, field: string) => {
    const newCurrentState = [...(currentData || [])].map((row: any) => {
      const { isValid, validationMessage, ...allData } = row;
      return allData;
    });
    const newData = [...newCurrentState].map((post) => {
      if (post?.id === selectedPostId) {
        const d = post.EVChargerConnector?.map(
          (connector: any, index: number) => {
            if (connector?.connectorId === id) {
              if (field === "powerTypeCd") {
                return {
                  ...connector,
                  id: index,
                  powerTypeCd: { powerTypeCd: value },
                };
              } else if (field === "connectorTypeCd") {
                return {
                  ...connector,
                  id: index,
                  connectorTypeCd: { connectorTypeCd: value },
                };
              } else {
                return {
                  ...connector,
                  id: index,
                  [field]: value,
                };
              }
            }
            return { ...connector };
          }
        );
        return { ...post, EVChargerConnector: d };
      }
      return { ...post };
    });
    const newConnectorData = [...newData].filter(
      (post) => post?.id === selectedPostId
    );
    setEvChargerConnectorData(newConnectorData?.[0] || {});
    setCurrentData([...newData]);
  };

  const deleteRow = (rowId: string) => {
    const newData = [...currentData]?.map((row: any) => {
      if (row.id === rowId) {
        return { ...row, deleteInd: "Y" };
      }
      return { ...row };
    });
    setCurrentData([...newData]);
    setSavedData([...newData]);
    onSectionValueChanged(true);
    onSectionValueSet({
      EVCharger: [...newData],
    });

    const currentRowData = [...newData]?.find(
      (item: { id: string }) => item?.id === rowId
    );

    const evChargeConnectorData = currentRowData?.EVChargerConnector?.map(
      (conn: any) => {
        if (conn?.connectorId?.includes("custom-")) {
          const { connectorId, id, ...rest } = conn;
          return { ...rest };
        } else {
          const { id, ...rest } = conn;
          return { ...rest };
        }
      }
    );

    const payload = {
      id: currentRowData?.id,
      chargepostId: currentRowData?.chargepostId,
      evChargerBrandCd: {
        evChargerBrandCd: currentRowData?.evChargerBrandCd,
      },
      evChargerOperatorCd: {
        evChargerOperatorCd: currentRowData?.evChargerOperatorCd,
      },
      countryCd: {
        countryCd: currentRowData?.countryCd,
      },
      ocpiEvseId: currentRowData?.ocpiEvseId,

      ...(currentRowData?.externalEvseId && {
        externalEvseId: currentRowData?.externalEvseId,
      }),
      ...(currentRowData?.evseId && {
        evseId: currentRowData.evseId,
      }),
      deleteInd: currentRowData?.deleteInd,
      EVChargerConnector: {
        item: evChargeConnectorData,
      },
    };

    const filteredData = payloadToSend?.filter(
      (item: { id: number }) => item?.id !== currentRowData?.id
    );
    setPayloadToSend([...filteredData, payload?.evseId && { ...payload }]);
    const { id, ...restPayload } = payload;
    const newFilteredData = filteredData?.map(
      ({ id, ...keepAttrs }) => keepAttrs
    );
    onPatchRequestValueSet(
      {
        EVCharger: {
          item: [
            ...newFilteredData,
            ...(restPayload.evseId ? [restPayload] : []),
          ],
        },
      },
      true
    );
  };

  const discardConnectorRow = (row: any) => {
    setCurrentData([...savedData]);
    var element: any = document.getElementById(`connector-${row.connectorId}`);
    element.classList.remove("edit-row");
    const newData = [...savedData].filter(
      (post) => post?.id === selectedPostId
    );
    setEvChargerConnectorData(newData?.[0] || {});
  };

  const deleteConnectorRow = (rowId: string) => {
    const newData = [...savedData].map((post) => {
      if (post?.id === selectedPostId) {
        const d = post.EVChargerConnector?.map((connector: any) => {
          if (connector?.connectorId === rowId) {
            return { ...connector, deleteInd: "Y" };
          }
          return { ...connector };
        });
        return { ...post, EVChargerConnector: d };
      }
      return { ...post };
    });
    const newConnectorData = [...newData].filter(
      (post) => post?.id === selectedPostId
    );
    setEvChargerConnectorData(newConnectorData?.[0] || {});
    setCurrentData([...newData]);
    setSavedData([...newData]);
    onSectionValueChanged(true);
    onSectionValueSet({
      EVCharger: [...newData],
    });

    const currentRowData = [...newData]?.find(
      (item: { id: string }) => item?.id === selectedPostId
    );

    const evChargeConnectorData = currentRowData?.EVChargerConnector?.map(
      (conn: any) => {
        if (conn?.connectorId?.includes("custom-")) {
          const { connectorId, id, ...rest } = conn;
          return { ...rest };
        } else {
          const { id, ...rest } = conn;
          return { ...rest };
        }
      }
    );

    const payload = {
      id: currentRowData?.id,
      chargepostId: currentRowData?.chargepostId,
      evChargerBrandCd: {
        evChargerBrandCd: currentRowData?.evChargerBrandCd,
      },
      evChargerOperatorCd: {
        evChargerOperatorCd: currentRowData?.evChargerOperatorCd,
      },
      countryCd: {
        countryCd: currentRowData?.countryCd,
      },
      ocpiEvseId: currentRowData?.ocpiEvseId,

      ...(currentRowData?.externalEvseId && {
        externalEvseId: currentRowData?.externalEvseId,
      }),
      ...(currentRowData?.evseId && {
        evseId: currentRowData.evseId,
      }),
      deleteInd: currentRowData?.deleteInd,
      EVChargerConnector: {
        item: evChargeConnectorData,
      },
    };

    const filteredData = payloadToSend?.filter(
      (item: { id: number }) => item?.id !== currentRowData?.id
    );
    setPayloadToSend([...filteredData, payload?.evseId && { ...payload }]);
    const { id, ...restPayload } = payload;
    const newFilteredData = filteredData?.map(
      ({ id, ...keepAttrs }) => keepAttrs
    );
    onPatchRequestValueSet(
      {
        EVCharger: {
          item: [
            ...newFilteredData,
            ...(restPayload.evseId ? [restPayload] : []),
          ],
        },
      },
      true
    );
  };

  const onNewPowerKWChange = (event: NumericTextBoxChangeEvent) => {
    const val: string = event?.value?.toString() || "";
    setNewPowerKW(val);
    setIsNewPowerKWValid(1);
    setIsNewConnectorRowValid(1);
  };

  const onNewConnectorTypeChange = (event: DropDownListChangeEvent) => {
    setNewConnectorTypeSelectedValue(event.target.value);
    setIsNewConnectorTypeValid(1);
    setIsNewConnectorRowValid(1);
  };

  const onNewPowerTypeChange = (event: DropDownListChangeEvent) => {
    setNewPowerTypeSelectedValue(event.target.value);
    setIsNewPowerTypeValid(1);
    setIsNewConnectorRowValid(1);
  };

  const onNewChargePostIdChange = (event: InputChangeEvent) => {
    setNewChargePostId(event.value);
    setIsNewChargePostIdValid(1);
    setIsNewChargePostRowValid(1);
  };

  const onNewChargerBrandChange = (event: DropDownListChangeEvent) => {
    setNewChargerBrandSelectedValue(event.target.value);
    setIsNewChargerBrandValid(1);
    setIsNewChargePostRowValid(1);
  };

  const onNewChargerOperatorChange = (event: DropDownListChangeEvent) => {
    setNewChargerOperatorSelectedValue(event.target.value);
    setIsNewChargerOperatorValid(1);
    setIsNewChargePostRowValid(1);
  };

  const onCountryChange = (event: DropDownListChangeEvent) => {
    setCountrySelectedValue(event.target.value);
    setIsCountryValid(1);
    setIsNewChargePostRowValid(1);
  };

  const onNewOCPIEVSEIdChange = (event: InputChangeEvent) => {
    setNewOCPIEVSEId(event.value);
    setIsNewOCPIEVSEIdValid(1);
    setIsNewChargePostRowValid(1);
  };

  const onNewExternalEvseIdChange = (event: InputChangeEvent) => {
    setNewExternalEvseId(event.value);
    setIsNewExternalEvseIdValid(1);
    setIsNewChargePostRowValid(1);
  };

  const onCancel = () => {
    setNewChargePostId("");
    setNewChargerBrandSelectedValue(null);
    setNewChargerOperatorSelectedValue(null);
    setCountrySelectedValue(null);
    setNewOCPIEVSEId("");
    setNewExternalEvseId("");
    setIsAddNewPostDisabled(false);
  };

  const onConnectorCancel = () => {
    setNewPowerKW("");
    setNewPowerTypeSelectedValue(null);
    setNewConnectorTypeSelectedValue(null);
    setIsAddNewConnectorDisabled(false);
  };

  const onAddNewPostSave = () => {
    let valid = true;
    if (newChargePostId) {
      setIsNewChargePostIdValid(1);
    } else {
      setIsNewChargePostIdValid(-1);
      setIsNewChargePostRowValid(-1);
      setNewChargePostRowInvalidText(
        Locale.create_site?.error_messages.ev_charge_posts_required_fields
      );
      valid = false;
    }

    if (newChargerOperatorSelectedValue) {
      setIsNewChargerOperatorValid(1);
    } else {
      setIsNewChargerOperatorValid(-1);
      setIsNewChargePostRowValid(-1);
      setNewChargePostRowInvalidText(
        Locale.create_site?.error_messages.ev_charge_posts_required_fields
      );
      valid = false;
    }
    if (newCountrySelectedValue) {
      setIsCountryValid(1);
    } else {
      setIsCountryValid(-1);
      setIsNewChargePostRowValid(-1);
      setNewChargePostRowInvalidText(
        Locale.create_site?.error_messages.ev_charge_posts_required_fields
      );
      valid = false;
    }
    if (newOCPIEVSEId) {
      setIsNewOCPIEVSEIdValid(1);
    } else {
      setIsNewOCPIEVSEIdValid(-1);
      setIsNewChargePostRowValid(-1);
      setNewChargePostRowInvalidText(
        Locale.create_site?.error_messages.ev_charge_posts_required_fields
      );
      valid = false;
    }

    if (valid) {
      setIsNewChargePostRowValid(1);
      const newData: any = {
        id: new Date().getMilliseconds() + 1000,
        ocpiEvseId: newOCPIEVSEId,
        externalEvseId: newExternalEvseId,
        chargepostId: newChargePostId,
        countryCd: newCountrySelectedValue?.id,
        countryName: newCountrySelectedValue?.value,
        evChargerOperatorCd: newChargerOperatorSelectedValue?.value,
        evChargerBrandCd: newChargerBrandSelectedValue?.value,
        EVChargerConnector: [],
        deleteInd: "N",
        // editActionRow: "",
      };
      const currentRecords = currentData?.length ? [...currentData] : [];
      currentRecords.unshift(newData);
      setCurrentData([...currentRecords]);
      setSavedData([...currentRecords]);
      setNewChargePostId("");
      setNewChargerBrandSelectedValue(null);
      setNewChargerOperatorSelectedValue(null);
      setCountrySelectedValue(null);
      setNewOCPIEVSEId("");
      setNewExternalEvseId("");
      setIsAddNewPostDisabled(false);
      onSectionValueChanged(true);
      onSectionValueChanged(true);
      setSelectedPostId("");
      setEvChargerConnectorData({});
      var elems = document.querySelectorAll(".selected");
      [].forEach.call(elems, (el: any) => {
        el.classList.remove("selected");
      });
      onSectionValueSet({
        EVCharger: [...currentRecords],
      });

      const payload: any = {
        id: newData?.id,
        chargepostId: newChargePostId,
        evChargerBrandCd: {
          evChargerBrandCd: newChargerBrandSelectedValue?.id,
        },
        evChargerOperatorCd: {
          evChargerOperatorCd: newChargerOperatorSelectedValue?.id,
        },
        countryCd: {
          countryCd: newCountrySelectedValue?.id,
        },
        ocpiEvseId: newOCPIEVSEId,
        ...(newExternalEvseId && {
          externalEvseId: newExternalEvseId,
        }),
        EVChargerConnector: {
          item: [],
        },
        deleteInd: "N",
      };
      setPayloadToSend([...payloadToSend, payload]);
      const { id, ...restPayload } = payload;
      const newFilteredData = payloadToSend?.map(
        ({ id, ...keepAttrs }) => keepAttrs
      );
      onPatchRequestValueSet(
        {
          EVCharger: {
            item: [...newFilteredData, restPayload],
          },
        },
        true
      );
    }
  };

  const onAddNewConnectorSave = () => {
    let valid = true;
    if (newPowerKW) {
      setIsNewPowerKWValid(1);
    } else {
      setIsNewPowerKWValid(-1);
      setIsNewConnectorRowValid(-1);
      setNewConnectorRowInvalidText(
        Locale.create_site?.error_messages.ev_charge_connector_required_fields
      );
      valid = false;
    }
    if (newPowerTypeSelectedValue) {
      setIsNewPowerTypeValid(1);
    } else {
      setIsNewPowerTypeValid(-1);
      setIsNewConnectorRowValid(-1);
      setNewConnectorRowInvalidText(
        Locale.create_site?.error_messages.ev_charge_connector_required_fields
      );
      valid = false;
    }
    if (newConnectorTypeSelectedValue) {
      setIsNewConnectorTypeValid(1);
    } else {
      setIsNewConnectorTypeValid(-1);
      setIsNewConnectorRowValid(-1);
      setNewConnectorRowInvalidText(
        Locale.create_site?.error_messages.ev_charge_connector_required_fields
      );
      valid = false;
    }

    if (valid) {
      setIsNewConnectorRowValid(1);
      const newId = new Date().getMilliseconds() + 1000;
      const newData: any = {
        connectorId: `custom-${newId?.toString()}`,
        powerKW: newPowerKW?.toString(),
        powerTypeCd: { powerTypeCd: newPowerTypeSelectedValue?.value },
        connectorTypeCd: {
          connectorTypeCd: newConnectorTypeSelectedValue?.value,
        },
        deleteInd: "N",
        // editActionRow: "",
      };

      const newSavedData = [...savedData].map((post: any) => {
        if (post?.id === selectedPostId) {
          if (!post.EVChargerConnector?.length) {
            post.EVChargerConnector = [];
          }
          post.EVChargerConnector?.unshift(newData);
          return { ...post };
        }
        return { ...post };
      });

      const newConnectorData = [...newSavedData].find(
        (post) => post?.id === selectedPostId
      );
      setEvChargerConnectorData(newConnectorData || {});

      setCurrentData([...newSavedData]);
      setSavedData([...newSavedData]);
      setNewConnectorTypeSelectedValue(null);
      setNewPowerTypeSelectedValue(null);
      setNewPowerKW("");
      setIsAddNewConnectorDisabled(false);
      onSectionValueChanged(true);
      onSectionValueSet({
        EVCharger: [...newSavedData],
      });

      const evChargeConnectorData = newConnectorData?.EVChargerConnector?.map(
        (conn: any) => {
          if (conn?.connectorId?.includes("custom-")) {
            const { connectorId, id, ...rest } = conn;
            return { ...rest };
          } else {
            const { id, ...rest } = conn;
            return { ...rest };
          }
        }
      );

      const payload = {
        id: newConnectorData?.id,
        chargepostId: newConnectorData?.chargepostId,
        evChargerBrandCd: {
          evChargerBrandCd: newConnectorData?.evChargerBrandCd,
        },
        evChargerOperatorCd: {
          evChargerOperatorCd: newConnectorData?.evChargerOperatorCd,
        },
        countryCd: {
          countryCd: newConnectorData?.countryCd,
        },
        ocpiEvseId: newConnectorData?.ocpiEvseId,

        ...(newConnectorData?.externalEvseId && {
          externalEvseId: newConnectorData?.externalEvseId,
        }),
        ...(newConnectorData?.evseId && {
          evseId: newConnectorData.evseId,
        }),
        deleteInd: newConnectorData?.deleteInd,
        EVChargerConnector: {
          item: evChargeConnectorData,
        },
      };

      const filteredData = payloadToSend?.filter(
        (item: { id: number }) => item?.id !== +selectedPostId
      );
      setPayloadToSend([...filteredData, { ...payload }]);
      const { id, ...restPayload } = payload;
      const newFilteredData = filteredData?.map(
        ({ id, ...keepAttrs }) => keepAttrs
      );
      onPatchRequestValueSet(
        {
          EVCharger: {
            item: [...newFilteredData, { ...restPayload }],
          },
        },
        true
      );
    }
  };

  const renderAddNewPostUI = () => (
    <>
      <div className="mdm--table-td">
        <Input
          type={"text"}
          value={newChargePostId}
          onChange={onNewChargePostIdChange}
          valid={isNewChargePostIdValid !== -1 ? true : false}
          maxLength={80}
          required={true}
        />
      </div>
      <div className="mdm--table-td">
        {isEVChargerBrandCodesLoading ? (
          <Skeleton shape={"text"} style={{ width: "100%" }} />
        ) : (
          <DropDownList
            data={evChargerBrandCodes || []}
            value={newChargerBrandSelectedValue}
            onChange={onNewChargerBrandChange}
            textField="value"
            dataItemKey="id"
            valid={true}
          />
        )}
      </div>
      <div className="mdm--table-td">
        {isEVChargerOperatorCodesLoading ? (
          <Skeleton shape={"text"} style={{ width: "100%" }} />
        ) : (
          <DropDownList
            data={evChargerOperatorCodes || []}
            value={newChargerOperatorSelectedValue}
            onChange={onNewChargerOperatorChange}
            textField="value"
            dataItemKey="id"
            valid={isNewChargerOperatorValid !== -1 ? true : false}
          />
        )}
      </div>
      <div className="mdm--table-td">
        {isCountryCodesLoading ? (
          <Skeleton shape={"text"} style={{ width: "100%" }} />
        ) : (
          <DropDownList
            data={countryCodes || []}
            value={newCountrySelectedValue}
            onChange={onCountryChange}
            textField="value"
            dataItemKey="id"
            valid={isCountryValid !== -1 ? true : false}
          />
        )}
      </div>
      <div className="mdm--table-td">
        <Input
          type={"text"}
          value={newOCPIEVSEId}
          onChange={onNewOCPIEVSEIdChange}
          valid={isNewOCPIEVSEIdValid !== -1 ? true : false}
          maxLength={80}
          required={true}
        />
      </div>
      <div className="mdm--table-td">
        <Input
          type={"text"}
          value={newExternalEvseId}
          onChange={onNewExternalEvseIdChange}
          valid={isNewExternalEvseIdValid !== -1 ? true : false}
          maxLength={80}
          required={true}
        />
      </div>
    </>
  );

  const renderAddNewPostActionButtonsUI = () => (
    <div className="mdm--table-edit-row-action-btn-section mdm--table-td mdm--row">
      <Button
        className="buttons-container-button"
        icon="check"
        size="small"
        rounded="full"
        onClick={onAddNewPostSave}
      />
      <Button
        className="buttons-container-button"
        icon="clear"
        size="small"
        rounded="full"
        onClick={onCancel}
      />
    </div>
  );

  const renderAddNewConnectorUI = () => (
    <>
      <div className="mdm--table-td">
        {isEVPowerTypeCodesLoading ? (
          <Skeleton shape={"text"} style={{ width: "100%" }} />
        ) : (
          <DropDownList
            data={evPowerTypeCodes || []}
            value={newPowerTypeSelectedValue}
            onChange={onNewPowerTypeChange}
            textField="value"
            dataItemKey="id"
            valid={isNewPowerTypeValid !== -1 ? true : false}
          />
        )}
      </div>
      <div className="mdm--table-td">
        <NumericTextBox
          value={newPowerKW ? Number(newPowerKW) : null}
          onChange={onNewPowerKWChange}
          valid={isNewPowerKWValid !== -1 ? true : false}
          required={true}
          min={0}
          format="0"
          spinners={false}
        />
      </div>
      <div className="mdm--table-td">
        {isEVConnectorTypeCodesLoading ? (
          <Skeleton shape={"text"} style={{ width: "100%" }} />
        ) : (
          <DropDownList
            data={evConnectorTypeCodes || []}
            value={newConnectorTypeSelectedValue}
            onChange={onNewConnectorTypeChange}
            textField="value"
            dataItemKey="id"
            valid={isNewConnectorTypeValid !== -1 ? true : false}
          />
        )}
      </div>
    </>
  );

  const renderAddNewConnectorActionButtonsUI = () => (
    <div className="mdm--table-edit-row-action-btn-section mdm--table-td mdm--row">
      <Button
        className="buttons-container-button"
        icon="check"
        size="small"
        rounded="full"
        onClick={onAddNewConnectorSave}
      />
      <Button
        className="buttons-container-button"
        icon="clear"
        size="small"
        rounded="full"
        onClick={onConnectorCancel}
      />
    </div>
  );

  useEffect(() => {
    if (fetchedEVChargePostData) {
      const defaultFacility = fetchedEVChargePostData?.map(
        (item: any, index: number) => {
          const connectionData = item?.EVChargerConnector?.map(
            (connector: any, i: number) => {
              return {
                index: i,
                ...connector,
              };
            }
          );
          return {
            ...item,
            EVChargerConnector: connectionData,
            index: index,
          };
        }
      );
      setDefaultPostData(defaultFacility);
    }
  }, [fetchedEVChargePostData]);

  return (
    <div className={`mdm--row mdm--sd-rad-table-details ev-charge-post`}>
      <div className="mdm--sd-rad-table-detail">
        <div className="table-heading">
          Select respective row to view EV Charge post connector data
        </div>
        <div className="mdm--sd-rad-table-headers mdm--row">
          <div className="mdm--table-th">
            {Locale.site_details?.ev_details.ev_charge_posts.charge_post_id}
          </div>
          <div className="mdm--table-th">
            {Locale.site_details?.ev_details.ev_charge_posts.charger_brand}
          </div>
          <div className="mdm--table-th">
            {Locale.site_details?.ev_details.ev_charge_posts.charger_operator}
          </div>
          <div className="mdm--table-th">
            {Locale.site_details?.ev_details.ev_charge_posts.cpo_country}
          </div>
          <div className="mdm--table-th">
            {Locale.site_details?.ev_details.ev_charge_posts.ocpi_evse_id}
          </div>
          <div className="mdm--table-th">
            {Locale.site_details?.ev_details.ev_charge_posts.external_evse_id}
          </div>
          <div className="mdm--table-th">
            <div
              className={
                "mdm--sd-rad-table-edit-add-btn  " +
                (!isEditing && "visibility-hidden")
              }
            >
              <Button
                className="buttons-container-button"
                icon="add"
                onClick={onAddNewPost}
                disabled={isAddNewPostDisabled}
              />
            </div>
          </div>
        </div>
        <div className="mdm--sd-rad-table-body">
          {isEditing && isAddNewPostDisabled && !isEVChargePostDataLoading && (
            <>
              <div className="mdm--row">
                {renderAddNewPostUI()}
                {renderAddNewPostActionButtonsUI()}
              </div>
              {isNewChargePostRowValid === -1 &&
                newChargePostRowInvalidText && (
                  <Error>{newChargePostRowInvalidText}</Error>
                )}
            </>
          )}
          {isEVChargePostDataLoading ? (
            <div className="mdm--row">
              <div className={`mdm--table-td`}>
                <Skeleton shape={"text"} style={{ width: "100%" }} />
              </div>
              <div className={`mdm--table-td`}>
                <Skeleton shape={"text"} style={{ width: "100%" }} />
              </div>
              <div className={`mdm--table-td`}>
                <Skeleton shape={"text"} style={{ width: "100%" }} />
              </div>
              <div className={`mdm--table-td`}>
                <Skeleton shape={"text"} style={{ width: "100%" }} />
              </div>
              <div className={`mdm--table-td`}>
                <Skeleton shape={"text"} style={{ width: "100%" }} />
              </div>
              <div className={`mdm--table-td`}>
                <Skeleton shape={"text"} style={{ width: "100%" }} />
              </div>
              <div className={`mdm--table-td`}></div>
            </div>
          ) : currentData?.length ? (
            currentData?.map((post: any, index: number) => (
              <div key={index}>
                {post?.deleteInd !== "Y" && (
                  <>
                    <div
                      id={`post-${post?.id}`}
                      className={`mdm--row selectable ${
                        isEditing ? "editable" : ""
                      }`}
                    >
                      <div
                        className={`mdm--table-td chargepostId ${getChangeClassName(
                          defaultPostData,
                          post,
                          "chargepostId"
                        )}`}
                      >
                        <span className="edit-field">
                          <Input
                            value={post?.chargepostId}
                            onChange={(e) =>
                              onEdit(e.value, post?.id, "chargepostId")
                            }
                            valid={post.chargepostId?.length}
                            maxLength={80}
                          />
                        </span>
                        <div
                          className="plain-text-field"
                          onClick={() => showConnectorData(post)}
                        >
                          {getChangeClassName(
                            defaultPostData,
                            post,
                            "chargepostId"
                          ) && !isCreate ? (
                            <Tooltip anchorElement="target" position="top">
                              <span
                                className="plain-text-field"
                                title={`${
                                  "Before:" +
                                  (getTooltipText(
                                    defaultPostData,
                                    post,
                                    "chargepostId"
                                  ) || "")
                                }`}
                              >
                                {post?.chargepostId}
                              </span>
                            </Tooltip>
                          ) : (
                            post?.chargepostId
                          )}
                        </div>
                      </div>

                      <div
                        className={`mdm--table-td evChargerBrandCd ${getChangeClassName(
                          defaultPostData,
                          post,
                          "evChargerBrandCd"
                        )}`}
                      >
                        <span className="edit-field">
                          <DropDownList
                            data={evChargerBrandCodes || []}
                            value={evChargerBrandCodes?.find(
                              (c: any) => c.value === post.evChargerBrandCd
                            )}
                            onChange={(e) =>
                              onEdit(
                                e.target.value.value,
                                post?.id,
                                "evChargerBrandCd"
                              )
                            }
                            textField="value"
                            dataItemKey="id"
                          />
                        </span>
                        <div
                          className="plain-text-field"
                          onClick={() => showConnectorData(post)}
                        >
                          {getChangeClassName(
                            defaultPostData,
                            post,
                            "evChargerBrandCd"
                          ) && !isCreate ? (
                            <Tooltip anchorElement="target" position="top">
                              <span
                                className="plain-text-field"
                                title={`${
                                  "Before:" +
                                  (getTooltipText(
                                    defaultPostData,
                                    post,
                                    "evChargerBrandCd"
                                  ) || "")
                                }`}
                              >
                                {post.evChargerBrandCd}
                              </span>
                            </Tooltip>
                          ) : (
                            post.evChargerBrandCd
                          )}
                        </div>
                      </div>

                      <div
                        className={`mdm--table-td evChargerOperatorCd ${getChangeClassName(
                          defaultPostData,
                          post,
                          "evChargerOperatorCd"
                        )}`}
                      >
                        <span className="edit-field">
                          <DropDownList
                            data={evChargerOperatorCodes || []}
                            value={evChargerOperatorCodes?.find(
                              (c: any) => c.value === post.evChargerOperatorCd
                            )}
                            onChange={(e) =>
                              onEdit(
                                e.target.value.value,
                                post?.id,
                                "evChargerOperatorCd"
                              )
                            }
                            textField="value"
                            dataItemKey="id"
                          />
                        </span>
                        <div
                          className="plain-text-field"
                          onClick={() => showConnectorData(post)}
                        >
                          {getChangeClassName(
                            defaultPostData,
                            post,
                            "evChargerOperatorCd"
                          ) && !isCreate ? (
                            <Tooltip anchorElement="target" position="top">
                              <span
                                className="plain-text-field"
                                title={`${
                                  "Before:" +
                                  (getTooltipText(
                                    defaultPostData,
                                    post,
                                    "evChargerOperatorCd"
                                  ) || "")
                                }`}
                              >
                                {post.evChargerOperatorCd}
                              </span>
                            </Tooltip>
                          ) : (
                            post.evChargerOperatorCd
                          )}
                        </div>
                      </div>

                      <div
                        className={`mdm--table-td countryCd ${getChangeClassName(
                          defaultPostData,
                          post,
                          "countryCd"
                        )}`}
                      >
                        <span className="edit-field">
                          <DropDownList
                            data={countryCodes || []}
                            value={countryCodes?.find(
                              (c: any) => c.id === post.countryCd
                            )}
                            onChange={(e) =>
                              onEdit(e.target.value, post?.id, "countryCd")
                            }
                            textField="value"
                            dataItemKey="id"
                          />
                        </span>
                        <div
                          className="plain-text-field"
                          onClick={() => showConnectorData(post)}
                        >
                          {getChangeClassName(
                            defaultPostData,
                            post,
                            "countryCd"
                          ) && !isCreate ? (
                            <Tooltip anchorElement="target" position="top">
                              <span
                                className="plain-text-field"
                                title={`${
                                  "Before:" +
                                  (getTooltipText(
                                    defaultPostData,
                                    post,
                                    "countryName"
                                  ) || "")
                                }`}
                              >
                                {post.countryName}
                              </span>
                            </Tooltip>
                          ) : (
                            post.countryName
                          )}
                        </div>
                      </div>

                      <div
                        className={`mdm--table-td ocpiEvseId ${getChangeClassName(
                          defaultPostData,
                          post,
                          "ocpiEvseId"
                        )}`}
                      >
                        <span className="edit-field">
                          <Input
                            value={post.ocpiEvseId}
                            onChange={(e) =>
                              onEdit(e.value, post?.id, "ocpiEvseId")
                            }
                            maxLength={80}
                            valid={post.ocpiEvseId?.length}
                          />
                        </span>
                        <div
                          className="plain-text-field"
                          onClick={() => showConnectorData(post)}
                        >
                          {getChangeClassName(
                            defaultPostData,
                            post,
                            "ocpiEvseId"
                          ) && !isCreate ? (
                            <Tooltip anchorElement="target" position="top">
                              <span
                                className="plain-text-field"
                                title={`${
                                  "Before:" +
                                  (getTooltipText(
                                    defaultPostData,
                                    post,
                                    "ocpiEvseId"
                                  ) || "")
                                }`}
                              >
                                {post.ocpiEvseId}
                              </span>
                            </Tooltip>
                          ) : (
                            post.ocpiEvseId
                          )}
                        </div>
                      </div>

                      <div
                        className={`mdm--table-td externalEvseId ${getChangeClassName(
                          defaultPostData,
                          post,
                          "externalEvseId"
                        )}`}
                      >
                        <span className="edit-field">
                          <Input
                            value={post.externalEvseId}
                            onChange={(e) =>
                              onEdit(e.value, post?.id, "externalEvseId")
                            }
                            maxLength={80}
                          />
                        </span>
                        <div
                          className="plain-text-field"
                          onClick={() => showConnectorData(post)}
                        >
                          {getChangeClassName(
                            defaultPostData,
                            post,
                            "externalEvseId"
                          ) && !isCreate ? (
                            <Tooltip anchorElement="target" position="top">
                              <span
                                className="plain-text-field"
                                title={`${
                                  "Before:" +
                                  (getTooltipText(
                                    defaultPostData,
                                    post,
                                    "externalEvseId"
                                  ) || "")
                                }`}
                              >
                                {post.externalEvseId}
                              </span>
                            </Tooltip>
                          ) : (
                            post.externalEvseId
                          )}
                        </div>
                      </div>

                      <div className={`mdm--table-td editActionRow`}>
                        <>
                          <Button
                            className="buttons-container-button visibility-hidden"
                            id="edit-btn"
                            size="small"
                            rounded="full"
                            icon="edit"
                            onClick={() => editRowData(post)}
                          />
                          <Button
                            className="buttons-container-button visibility-hidden"
                            size="small"
                            id="save-btn"
                            rounded="full"
                            icon="check"
                            onClick={() => saveRowData(post)}
                          />
                          <Button
                            className="buttons-container-button visibility-hidden"
                            size="small"
                            rounded="full"
                            id="discard-btn"
                            icon="clear"
                            onClick={() => discardRowData(post)}
                          />
                          <Button
                            className="buttons-container-button visibility-hidden"
                            size="small"
                            rounded="full"
                            id="delete-btn"
                            icon="delete"
                            onClick={(e) => deleteRow(post?.id)}
                          />
                        </>
                      </div>
                    </div>
                    {!post.isValid && post.validationMessage && (
                      <Error>{post.validationMessage}</Error>
                    )}
                  </>
                )}
              </div>
            ))
          ) : (
            !isAddNewPostDisabled && (
              <p className="no-records">{Locale.common?.no_records_to_view}</p>
            )
          )}
        </div>
      </div>
      <div className="mdm--sd-rad-table-detail connector-data">
        <div className="table-heading"> EV Charge Connectors</div>
        <div className="mdm--sd-rad-table-headers mdm--row">
          <div className="mdm--table-th">
            {Locale.site_details?.ev_details.ev_charge_posts.power_type}
          </div>
          <div className="mdm--table-th">
            {Locale.site_details?.ev_details.ev_charge_posts.power_kw}
          </div>
          <div className="mdm--table-th">
            {Locale.site_details?.ev_details.ev_charge_posts.connector_type}
          </div>
          <div className="mdm--table-th">
            <div
              className={
                isEditing &&
                (selectedPostId || selectedPostId?.toString() === "0")
                  ? "mdm--sd-rad-table-edit-add-btn"
                  : "mdm--sd-rad-table-edit-add-btn visibility-hidden"
              }
            >
              <Button
                className="buttons-container-button"
                icon="add"
                onClick={onAddNewConnector}
                disabled={isAddNewConnectorDisabled}
              />
            </div>
          </div>
        </div>
        <div className="mdm--sd-rad-table-body">
          {isEditing && isAddNewConnectorDisabled && (
            <>
              <div className="mdm--row">
                {renderAddNewConnectorUI()}
                {renderAddNewConnectorActionButtonsUI()}
              </div>
              {isNewConnectorRowValid === -1 && newConnectorRowInvalidText && (
                <Error>{newConnectorRowInvalidText}</Error>
              )}
            </>
          )}
          {evChargerConnectorData?.EVChargerConnector?.length
            ? evChargerConnectorData?.EVChargerConnector?.map(
                (connector: any, index: number) => (
                  <div key={index}>
                    {connector?.deleteInd !== "Y" && (
                      <>
                        <div
                          id={`connector-${connector?.connectorId}`}
                          className={`mdm--row ${isEditing ? "editable" : ""}`}
                        >
                          <div
                            className={`mdm--table-td powerTypeCd ${getConnectorChangeClassName(
                              defaultPostData?.[selectedPostId]
                                ?.EVChargerConnector,
                              { ...connector, id: connector?.connectorId },
                              "powerTypeCd"
                            )}`}
                          >
                            <span className="edit-field">
                              <DropDownList
                                data={evPowerTypeCodes || []}
                                value={evPowerTypeCodes?.find(
                                  (c: any) =>
                                    c.value ===
                                    connector.powerTypeCd?.powerTypeCd
                                )}
                                onChange={(e) =>
                                  onConnectorEdit(
                                    e.target.value.value,
                                    connector?.connectorId,
                                    "powerTypeCd"
                                  )
                                }
                                textField="value"
                                dataItemKey="id"
                              />
                            </span>
                            {getConnectorChangeClassName(
                              defaultPostData?.[selectedPostId]
                                ?.EVChargerConnector,
                              { ...connector, id: connector?.connectorId },
                              "powerTypeCd"
                            ) && !isCreate ? (
                              <Tooltip anchorElement="target" position="top">
                                <span
                                  className="plain-text-field"
                                  title={`${
                                    "Before:" +
                                    (getConnectionTooltipText(
                                      defaultPostData?.[selectedPostId]
                                        ?.EVChargerConnector,
                                      { ...connector, id: index },
                                      "powerTypeCd"
                                    ) || "")
                                  }`}
                                >
                                  {connector.powerTypeCd.powerTypeCd}
                                </span>
                              </Tooltip>
                            ) : (
                              <span className="plain-text-field">
                                {connector.powerTypeCd.powerTypeCd}
                              </span>
                            )}
                          </div>
                          <div
                            className={`mdm--table-td powerKW ${getConnectorChangeClassName(
                              defaultPostData?.[selectedPostId]
                                ?.EVChargerConnector,
                              { ...connector, id: connector?.connectorId },
                              "powerKW"
                            )}`}
                          >
                            <span className="edit-field">
                              <NumericTextBox
                                value={connector.powerKW}
                                onChange={(e: NumericTextBoxChangeEvent) =>
                                  onConnectorEdit(
                                    e?.value?.toString() || "",
                                    connector?.connectorId,
                                    "powerKW"
                                  )
                                }
                                min={0}
                                format="0"
                                spinners={false}
                              />
                            </span>
                            <span className="plain-text-field">
                              {getConnectorChangeClassName(
                                defaultPostData?.[selectedPostId]
                                  ?.EVChargerConnector,
                                { ...connector, id: connector?.connectorId },
                                "powerKW"
                              ) && !isCreate ? (
                                <Tooltip anchorElement="target" position="top">
                                  <span
                                    className="plain-text-field"
                                    title={`${
                                      "Before:" +
                                      (getConnectionTooltipText(
                                        defaultPostData?.[selectedPostId]
                                          ?.EVChargerConnector,
                                        { ...connector, id: index },
                                        "powerKW"
                                      ) || "")
                                    }`}
                                  >
                                    {connector.powerKW}
                                  </span>
                                </Tooltip>
                              ) : (
                                <span className="plain-text-field">
                                  {connector.powerKW}
                                </span>
                              )}
                            </span>
                          </div>
                          <div
                            className={`mdm--table-td connectorTypeCd ${getConnectorChangeClassName(
                              defaultPostData?.[selectedPostId]
                                ?.EVChargerConnector,
                              { ...connector, id: connector?.connectorId },
                              "connectorTypeCd"
                            )}`}
                          >
                            <span className="edit-field">
                              <DropDownList
                                data={evConnectorTypeCodes || []}
                                value={evConnectorTypeCodes?.find(
                                  (c: any) =>
                                    c.value ===
                                    connector.connectorTypeCd?.connectorTypeCd
                                )}
                                onChange={(e) =>
                                  onConnectorEdit(
                                    e.target.value.value,
                                    connector?.connectorId,
                                    "connectorTypeCd"
                                  )
                                }
                                textField="value"
                                dataItemKey="id"
                              />
                            </span>
                            <span className="plain-text-field">
                              {getConnectorChangeClassName(
                                defaultPostData?.[selectedPostId]
                                  ?.EVChargerConnector,
                                { ...connector, id: connector?.connectorId },
                                "connectorTypeCd"
                              ) && !isCreate ? (
                                <Tooltip anchorElement="target" position="top">
                                  <span
                                    className="plain-text-field"
                                    title={`${
                                      "Before:" +
                                      (getConnectionTooltipText(
                                        defaultPostData?.[selectedPostId]
                                          ?.EVChargerConnector,
                                        { ...connector, id: index },
                                        "connectorTypeCd"
                                      ) || "")
                                    }`}
                                  >
                                    {connector.connectorTypeCd.connectorTypeCd}
                                  </span>
                                </Tooltip>
                              ) : (
                                <span className="plain-text-field">
                                  {connector.connectorTypeCd.connectorTypeCd}
                                </span>
                              )}
                            </span>
                          </div>
                          <div className={`mdm--table-td editActionRow`}>
                            <>
                              <Button
                                className="buttons-container-button visibility-hidden"
                                id="edit-btn"
                                size="small"
                                rounded="full"
                                icon="edit"
                                onClick={() => editConnectorRowData(connector)}
                              />
                              <Button
                                className="buttons-container-button visibility-hidden"
                                size="small"
                                id="save-btn"
                                rounded="full"
                                icon="check"
                                onClick={() => saveConnectorRowData(connector)}
                              />
                              <Button
                                className="buttons-container-button visibility-hidden"
                                size="small"
                                rounded="full"
                                id="discard-btn"
                                icon="clear"
                                onClick={(e) => discardConnectorRow(connector)}
                              />
                              <Button
                                className="buttons-container-button visibility-hidden"
                                size="small"
                                rounded="full"
                                id="delete-btn"
                                icon="delete"
                                onClick={(e) =>
                                  deleteConnectorRow(connector?.connectorId)
                                }
                              />
                            </>
                          </div>
                        </div>
                        {!connector?.isValid &&
                          connector?.validationMessage && (
                            <Error>{connector?.validationMessage}</Error>
                          )}
                      </>
                    )}
                  </div>
                )
              )
            : !isAddNewConnectorDisabled && (
                <div>
                  {selectedPostId ? (
                    <p className="no-records">
                      {Locale.common?.no_records_to_view}
                    </p>
                  ) : (
                    "Please select EV Charger Post"
                  )}
                </div>
              )}
        </div>
      </div>
    </div>
  );
};
