export class OperatingPlatformConst {
  public static RBA = "0";
  public static RFA = "1";
  public static DO = "2";
  public static WHOLESALE = "3";
  public static COCO_LOCAL = "4";
  public static CODO_LOCAL = "5";
  public static DODO_LOCAL = "6";
  public static MSO_LOCAL = "7";
  public static CORO_LOCAL = "8";
  public static RORO_LOCAL = "9";
  public static ORO_LOCAL = "10";
  public static WHOLESALE_LOCAL = "11";
  public static DOES_NOT_APPLY = "12";
  public static OTHER = "13";
  public static NOT_SPECIFIED = "14";
  public static CCDO = "15";
  public static DODM = "16";
  public static SAT = "17";
  public static RETAIL_ALLIANCE = "18";
  public static LEGACY_CO = "19";
  public static LEGACY_DO = "20";
  public static RBLA = "21";
  public static MBA = "22";
}

export class OperatingPlatformDecConst {
  public static AN_OLD_OPERATING_PLATFORM_FROM_OU =
    "An old operating platform from OU";
  public static COMPANY_OWNED_COMPANY_OPERATED =
    "Company Owned Company Operated";
  public static COMPANY_OWNED_DEALER_OPERATED = "Company Owned Dealer Operated";
  public static COMPANY_OWNED_RETAILER_OPERATED =
    "Company Owned Retailer Operated";
  public static DEALER_OWNED = "Dealer Owned";
  public static DEALER_OWNED_DEALER_OPERATED = "Dealer Owned Dealer Operated";
  public static USED_FOR_COMPETITOR_SITES_AND_COMMERCIAL_SITES =
    "Used for Competitor Sites and Commercial Sites";
  public static MOBILITY_BUSINESS_ALLIANCE = "Mobility Business Alliance";
  public static USED_FOR_INACTIVE_SHELL_IN_NETWORK_RETAIL_SITES =
    "Used for inactive Shell In Network Retail Sites";
  public static NOT_SPECIFIED = "Not Specified";
  public static RETAILER_BUSINESS_AGREEMENT = "Retailer Business Agreement";
  public static RETAIL_BRAND_LICENCE_AGREEMENT =
    "Retail Brand Licence Agreement";
  public static RETAIL_ALLIANCE = "Retail Alliance";
  public static RETAIL_FEE_AGREEMENT = "Retail Fee Agreement";
  public static RETAILER_OWNED_RETAILER_OPERATED =
    "Retailer Owned Retailer Operated";
  public static WHOLESALE = "Wholesale";
  public static LOCAL_WHOLESALE = "Local Wholesale";
  public static LEGACY_COMPANY_OWNED = "Legacy Company Owned";
  public static LEGACY_DEALER_OWNED = "Legacy Dealer Owned";
}
