import { useEffect, useState } from "react";
import {
  DropDownList,
  DropDownListChangeEvent,
  DropDownListFilterChangeEvent,
} from "@progress/kendo-react-dropdowns";
import {
  CompositeFilterDescriptor,
  filterBy,
  FilterDescriptor,
} from "@progress/kendo-data-query";
import { Skeleton } from "@progress/kendo-react-indicators";
import { Input, InputChangeEvent } from "@progress/kendo-react-inputs";

import {
  useCountryCodesData,
  useMarketTypesCodesData,
} from "../../../../../../../../hooks";
import { EditFieldTypeConst } from "../../../../../../../../constants";
import { TableDetailsViewData } from "../../../../../../../../models";
import { Locale } from "../../../../../../../../utils/localization";
import { TableDetailsView } from "../../../../../../../components/table-details-view/TableDetailsView";

export const LocalCustomFieldsTab = (props: any) => {
  const {
    isEditing,
    isCreate,
    evDetailsTabSelected,
    onPatchRequestValueSet,
    isSectionValueChanged,
    onSectionValueChanged,
    sectionEditedData,
    onSectionValueSet,
    fetchedLocalMarketTypeData,
    isLocalMarketTypeDataLoading,
  } = props;

  const [currentStateOfLocalMarketData, setCurrentStateOfLocalMarketData] =
    useState<TableDetailsViewData>({ tableHeaders: [] });
  const [savedStateOfLocalMarketData, setSavedStateOfLocalMarketData] =
    useState<any>();

  const [newCountrySelectedValue, setNewCountrySelectedValue] = useState<any>();

  const [newLocalMarketSelectedValue, setNewLocalMarketSelectedValue] =
    useState<any>();

  const [newLocalMarketValue, setNewLocalMarketValue] = useState<
    string | undefined
  >();

  const [isNewLocalCustomFieldRowValid, setIsNewLocalCustomFieldRowValid] =
    useState(0);

  const [
    newLocalCustomFieldRowInvalidText,
    setNewLocalCustomFieldRowInvalidText,
  ] = useState("");

  const [isNewCountryValid, setIsNewCountryValid] = useState(0);
  const [isNewLocalMarketValid, setIsNewLocalMarketValid] = useState(0);

  const [initialCountryList, setInitialCountryList] = useState();
  const [countryList, setCountryList] = useState<any>();

  const {
    isLoading: isMarketTypeCodesLoading,
    data: marketTypeCodes,
    refetch: refetchMarketTypeCodes,
  } = useMarketTypesCodesData();

  const {
    isLoading: isCountryCodesLoading,
    data: countryCodes,
    refetch: refetchCountryCodes,
  } = useCountryCodesData();

  useEffect(() => {
    if (isEditing && evDetailsTabSelected === 5) {
      refetchMarketTypeCodes();
      refetchCountryCodes();
    }
  }, [isEditing, evDetailsTabSelected]);

  const [defaultData, setDefaultData] = useState<any>([]);

  useEffect(() => {
    if (fetchedLocalMarketTypeData) {
      const defaultMarket =
        fetchedLocalMarketTypeData?.data?.result?.SiteMarketType?.item?.map(
          (item: any, index: number) => {
            return {
              index: index,
              countryName: item.countryCd?.countryCd,
              countryCd: item.countryCd?.countryCd,
              localMarketTypeName: item.localMarketTypCd?.localMarketTypeName,
              localMarketTypeCd: item.localMarketTypCd.localMarketTypeCd,
              localMarketTypeValue: item.localMarketTypeValue,
            };
          }
        );

      setDefaultData(defaultMarket);
    }
  }, [fetchedLocalMarketTypeData]);

  const filterData = (
    filter: FilterDescriptor | CompositeFilterDescriptor,
    codes: any
  ) => {
    const data = codes.slice();
    return filterBy(data, filter);
  };

  const onNewCountryChange = (event: DropDownListChangeEvent) => {
    setNewCountrySelectedValue(event.target.value);
    setIsNewCountryValid(1);
    setIsNewLocalMarketValid(1);
    setIsNewLocalCustomFieldRowValid(1);
  };

  const onNewLocalMarketChange = (event: DropDownListChangeEvent) => {
    setNewLocalMarketSelectedValue(event.target.value);
    setIsNewCountryValid(1);
    setIsNewLocalMarketValid(1);
    setIsNewLocalCustomFieldRowValid(1);
  };

  const onNewLocalMarketValueChange = (event: InputChangeEvent) => {
    setNewLocalMarketValue(event.value);
    setIsNewCountryValid(1);
    setIsNewLocalMarketValid(1);
    setIsNewLocalCustomFieldRowValid(1);
  };

  const onAddNewLocalMarketClick = () => {
    let isValid = false;
    if (newCountrySelectedValue) {
      setIsNewCountryValid(1);
    } else {
      setIsNewCountryValid(-1);
      setIsNewLocalCustomFieldRowValid(-1);
      setNewLocalCustomFieldRowInvalidText(
        Locale.create_site?.error_messages.custom_local_required_fields
      );
    }
    if (newLocalMarketSelectedValue) {
      setIsNewLocalMarketValid(1);
    } else {
      setIsNewLocalMarketValid(-1);
      setIsNewLocalCustomFieldRowValid(-1);
      setNewLocalCustomFieldRowInvalidText(
        Locale.create_site?.error_messages.custom_local_required_fields
      );
    }
    if (newCountrySelectedValue && newLocalMarketSelectedValue) {
      const findIndex = savedStateOfLocalMarketData?.data?.find(
        (market: any) => {
          return (
            market.countryCd === newCountrySelectedValue?.id &&
            market?.localMarketTypeCd === newLocalMarketSelectedValue?.id &&
            (market?.localMarketTypeValue || "-") ===
              (newLocalMarketValue || "-")
          );
        }
      );
      if (findIndex) {
        setIsNewCountryValid(-1);
        setIsNewLocalCustomFieldRowValid(-1);
        setNewLocalCustomFieldRowInvalidText(
          Locale.create_site?.error_messages.custom_local_already_exists
        );
      } else {
        isValid = true;
      }
    }

    if (isValid) {
      setIsNewLocalCustomFieldRowValid(1);
      const newData: any = {
        id: new Date().getMilliseconds() + 1000,
        localMarketTypeName: newLocalMarketSelectedValue?.value || "-",
        localMarketTypeCd: newLocalMarketSelectedValue?.id || "-",
        countryName: newCountrySelectedValue?.value || "-",
        countryCd: newCountrySelectedValue?.id || "-",
        localMarketTypeValue: newLocalMarketValue,
        editActionRow: "",
      };
      const currentData = [...(currentStateOfLocalMarketData?.data || [])];
      currentData.unshift(newData);

      var currentState: any = {
        ...currentStateOfLocalMarketData,
        data: [...currentData],
      };
      setCurrentStateOfLocalMarketData(currentState);
      setNewCountrySelectedValue(null);
      setNewLocalMarketSelectedValue(null);
      setNewLocalMarketValue(undefined);

      onSectionValueChanged(true);
      setSavedStateOfLocalMarketData({
        ...savedStateOfLocalMarketData,
        data: currentState.data,
      });
      onSectionValueSet({
        SiteMarketType: {
          ...savedStateOfLocalMarketData,
          data: currentState.data,
        },
      });
      const payload = currentState?.data?.map((market: any) => {
        return {
          localMarketTypeValue: market.localMarketTypeValue,
          countryCd: {
            countryCd: market.countryCd,
          },
          localMarketTypCd: {
            localMarketTypeCd: market.localMarketTypeCd,
          },
        };
      });
      onPatchRequestValueSet(
        {
          SiteMarketType: {
            item: payload,
          },
        },
        true
      );
    }
    return isValid;
  };

  const onEditRow = (currentState: any, event: any, row: any, field: any) => {
    const newCurrentState = [...(currentState?.rows || [])].map((row: any) => {
      const { isValid, validationMessage, ...allData } = row;
      return allData;
    });
    const currentData = [...(newCurrentState || [])];

    const filteredData = currentData?.map((item: any) => {
      if (row.id === item.id) {
        if (field === "countryName") {
          return { ...item, [field]: event?.value, countryCd: event?.id };
        } else if (field === "localMarketTypCd") {
          return {
            ...item,
            [field]: event?.value,
            localMarketTypCd: event?.id,
          };
        }
        return { ...item, [field]: event?.value };
      }
      return { ...item };
    });
    setCurrentStateOfLocalMarketData({ ...currentState, data: filteredData });
  };

  const onDiscardRow = (rowId: string) => {
    const discardRow = [...savedStateOfLocalMarketData?.data]?.filter(
      (row: any) => row.id === rowId
    )?.[0];

    const currentState = currentStateOfLocalMarketData?.data?.map(
      (row: any) => {
        if (row.id === rowId) {
          return discardRow;
        }
        return { ...row };
      }
    );

    const filteredArray = currentState?.filter((row: any) => {
      return row;
    });

    setCurrentStateOfLocalMarketData({
      ...currentStateOfLocalMarketData,
      data: filteredArray,
    });
  };

  const onDeleteRow = (rowId: string) => {
    const currentState = currentStateOfLocalMarketData?.data?.filter(
      (row: any) => {
        return row.id !== rowId;
      }
    );
    setCurrentStateOfLocalMarketData({
      ...currentStateOfLocalMarketData,
      data: currentState,
    });

    const savedState = savedStateOfLocalMarketData?.data?.filter((row: any) => {
      return row.id !== rowId;
    });

    onSectionValueChanged(true);
    setSavedStateOfLocalMarketData({
      ...savedStateOfLocalMarketData,
      data: savedState,
    });
    onSectionValueSet({
      SiteMarketType: { ...savedStateOfLocalMarketData, data: savedState },
    });
    const payload = savedState?.map((market: any) => {
      return {
        localMarketTypeValue: market.localMarketTypeValue,
        countryCd: {
          countryCd: market.countryCd,
        },
        localMarketTypCd: {
          localMarketTypeCd: market.localMarketTypeCd,
        },
      };
    });
    onPatchRequestValueSet(
      {
        SiteMarketType: {
          item: payload,
        },
      },
      true
    );
  };

  const onSaveRow = (rowId: string) => {
    let isValid: boolean = true;

    const filteredData: any = currentStateOfLocalMarketData?.data?.filter(
      (row: any) => {
        return row.id === rowId;
      }
    )?.[0];

    let newCurrentState: any = [...(currentStateOfLocalMarketData?.data || [])];

    const existedRecord: any = currentStateOfLocalMarketData?.data?.find(
      (row: any) => {
        return (
          row.countryCd === filteredData?.countryCd &&
          row.localMarketTypeCd === filteredData?.localMarketTypeCd &&
          (row.localMarketTypeValue || "-") ===
            (filteredData?.localMarketTypeValue || "-") &&
          row?.id !== filteredData?.id
        );
      }
    );
    if (existedRecord?.countryCd) {
      isValid = false;
      newCurrentState = currentStateOfLocalMarketData?.data?.map((row: any) => {
        if (row.id === rowId) {
          return {
            ...row,
            isValid: false,
            validationMessage:
              Locale.create_site?.error_messages.custom_local_already_exists,
          };
        }
        return row;
      });
    }

    setCurrentStateOfLocalMarketData({
      ...currentStateOfLocalMarketData,
      data: newCurrentState,
    });

    if (isValid) {
      const savedState = savedStateOfLocalMarketData?.data?.map((row: any) => {
        if (row.id === rowId) {
          return filteredData;
        }
        return { ...row };
      });

      onSectionValueChanged(true);
      onSectionValueSet({
        SiteMarketType: {
          ...currentStateOfLocalMarketData,
          data: savedState,
        },
      });
      setSavedStateOfLocalMarketData({
        ...currentStateOfLocalMarketData,
        data: savedState,
      });
      const payload = savedState?.map((market: any) => {
        return {
          localMarketTypeValue: market.localMarketTypeValue,
          countryCd: {
            countryCd: market.countryCd,
          },
          localMarketTypCd: {
            localMarketTypeCd: market.localMarketTypeCd,
          },
        };
      });
      onPatchRequestValueSet(
        {
          SiteMarketType: {
            item: payload,
          },
        },
        true
      );
    }
    return isValid;
  };

  const onShowNewRowClick = () => {
    setNewCountrySelectedValue(undefined);
    setNewLocalMarketSelectedValue(undefined);
    setNewLocalMarketValue("");
    setIsNewCountryValid(1);
    setIsNewLocalMarketValid(1);
    setIsNewLocalCustomFieldRowValid(1);
    setCurrentStateOfLocalMarketData({
      ...currentStateOfLocalMarketData,
      data: savedStateOfLocalMarketData.data,
    });
  };

  const countyFilterChange = (event: DropDownListFilterChangeEvent) => {
    setCountryList(filterData(event.filter, initialCountryList));
  };

  useEffect(() => {
    if (countryCodes) {
      const codes = countryCodes.map((country: any) => {
        return {
          id: country.id,
          value: country.id,
        };
      });
      setInitialCountryList(codes);
      setCountryList(codes);
    }
  }, [countryCodes]);

  useEffect(() => {
    let list: string[] = [];
    const tbHeaders = [
      {
        label: Locale.site_details?.ev_details.local_custom_fields.country,
        field: "countryName",
        editType: EditFieldTypeConst.DROPDOWN_FIELD,
        editData: countryList,
        filterable: true,
        onFilterChange: countyFilterChange,
        onChange: onEditRow,
      },
      {
        label:
          Locale.site_details?.ev_details.local_custom_fields.local_market_type,
        field: "localMarketTypeName",
        editType: EditFieldTypeConst.DROPDOWN_FIELD,
        editData: marketTypeCodes,
        onChange: onEditRow,
      },
      {
        label:
          Locale.site_details?.ev_details.local_custom_fields
            .local_market_type_value,
        field: "localMarketTypeValue",
        editType: EditFieldTypeConst.TEXT_FIELD,
        maxLength: 100,
        onChange: onEditRow,
      },
      {
        label: "",
        field: "editActionRow",
      },
    ];
    if (isSectionValueChanged && sectionEditedData?.SiteMarketType) {
      list =
        sectionEditedData?.SiteMarketType?.data?.map((item: any) => {
          return {
            id: item.id,
            countryName: item.countryName,
            countryCd: item.countryCd,
            localMarketTypeName: item.localMarketTypeName,
            localMarketTypeCd: item.localMarketTypeCd,
            localMarketTypeValue: item.localMarketTypeValue,
            editActionRow: "",
          };
        }) || [];
    } else {
      list =
        fetchedLocalMarketTypeData?.data?.result?.SiteMarketType?.item?.map(
          (item: any, index: number) => {
            return {
              id: index,

              countryName: item.countryCd?.countryCd,
              countryCd: item.countryCd?.countryCd,
              localMarketTypeName: item.localMarketTypCd?.localMarketTypeName,
              localMarketTypeCd: item.localMarketTypCd.localMarketTypeCd,
              localMarketTypeValue: item.localMarketTypeValue,

              // ...item,
              editActionRow: "",
            };
          }
        ) || [];
    }
    const localMarketData: TableDetailsViewData = {
      tableHeaders: tbHeaders,
      data: list,
    };
    setCurrentStateOfLocalMarketData(localMarketData);
    setSavedStateOfLocalMarketData(localMarketData);
  }, [
    fetchedLocalMarketTypeData,
    countryList,
    marketTypeCodes,
    isEditing,
    evDetailsTabSelected,
  ]);

  const renderAddNewLocalMarketUI = () => {
    return (
      <>
        <div className="mdm--table-td">
          {isCountryCodesLoading ? (
            <Skeleton shape={"text"} style={{ width: "100%" }} />
          ) : (
            <DropDownList
              data={countryList}
              value={newCountrySelectedValue}
              onChange={onNewCountryChange}
              textField="value"
              dataItemKey="id"
              valid={isNewCountryValid !== -1 ? true : false}
              filterable={true}
              onFilterChange={countyFilterChange}
            />
          )}
        </div>
        <div className="mdm--table-td">
          {isMarketTypeCodesLoading ? (
            <Skeleton shape={"text"} style={{ width: "100%" }} />
          ) : (
            <>
              <DropDownList
                data={marketTypeCodes || []}
                value={newLocalMarketSelectedValue}
                onChange={onNewLocalMarketChange}
                textField="value"
                dataItemKey="id"
                valid={isNewLocalMarketValid !== -1 ? true : false}
              />
            </>
          )}
        </div>
        <div className="mdm--table-td">
          <Input
            value={newLocalMarketValue}
            onChange={onNewLocalMarketValueChange}
          />
        </div>
      </>
    );
  };

  return (
    <TableDetailsView
      className={"ev-custom-field"}
      data={{
        tableHeaders: currentStateOfLocalMarketData.tableHeaders,
        rows: currentStateOfLocalMarketData?.data,
        defaultData: defaultData,
      }}
      isLoading={isLocalMarketTypeDataLoading}
      isEditing={isEditing}
      renderAddNewRowUI={renderAddNewLocalMarketUI}
      deleteRow={onDeleteRow}
      onAddRowClick={onAddNewLocalMarketClick}
      onEditDiscard={onDiscardRow}
      onSaveRow={onSaveRow}
      onShowNewRowClick={onShowNewRowClick}
      isNewRowValid={isNewLocalCustomFieldRowValid}
      newRowInvalidText={newLocalCustomFieldRowInvalidText}
      isCreate={isCreate}
    />
  );
};
