export const initialState = { predefinedFilters: {} };

interface ActionProps {
  type: String;
  payload?: any;
}

export default function sitesListPredefinedFiltersReducer(
  state = initialState,
  action: ActionProps
) {
  switch (action.type) {
    case "UPDATE_SITES_LIST_PREDEFINED_FILTERS":
      state.predefinedFilters = action.payload;
      return { ...state };

    default:
      return { ...state };
  }
}
