import { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { useQuery } from "react-query";

import {
  MultiSelect,
  MultiSelectChangeEvent,
  MultiSelectFilterChangeEvent,
} from "@progress/kendo-react-dropdowns";
import {
  TabStrip,
  TabStripTab,
  TabStripSelectEventArguments,
} from "@progress/kendo-react-layout";
import { filterBy } from "@progress/kendo-data-query";
import { Tooltip } from "@progress/kendo-react-tooltip";
import { RadioGroup } from "@progress/kendo-react-inputs";
import { GridSortChangeEvent } from "@progress/kendo-react-grid";

import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";

import _cloneDeep from "lodash/cloneDeep";
import _uniq from "lodash/uniq";
import _map from "lodash/map";

import { AppDataGrid, Loader } from "./../../../components";

import {
  SitesListRequestData,
  PageState,
  PaginationState,
  SitesListDocumentData,
} from "./../../../../models";
import {
  APP_ROUTES,
  RoleConst,
  SiteConst,
  adobeAnalyticsConst,
  AppConst,
  SiteTypeNameConst,
} from "../../../../constants";

import { SitesService } from "./../../../../services/sites.service";
import { Locale } from "../../../../utils/localization";
import { setGlobalCountry } from "../../../../redux/site-listing/actions";
import { updateSitesListPredefinedFilters } from "../../../../redux/actions/sitesListPredefinedFilters";

import "./SitesList.scss";

interface FacetState {
  value: string;
  count: number;
}

interface TableHeaderType {
  label: string;
  field: string;
  width?: string;
  minWidth?: string;
}

const TABLE_MAX_HEIGHT = "92%";

export const SitesList = ({ onExpand, isExpanded }: any) => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const valueRef = useRef<any>();
  const [isCountriesDispatched, setIsCountriesDispatched] = useState(false);
  const selectedGlobalCountry = useSelector((state: any) => {
    return state?.selectedGlobalCountry?.selectedGlobalCountry;
  });
  let predefinedFilters = useSelector((state: any) => {
    return state?.sitesListPredefinedFiltersReducer.predefinedFilters;
  });

  const fileType = AppConst.EXPORT_FILE_TYPE;
  const fileExtension = AppConst.EXPORT_FILE_TYPE_EXTENSION;

  const userDetails = useSelector((state: any) => state?.auth?.user);
  const [roleName, setRoleName] = useState("");

  const [siteTypeItems, setSiteTypeItems] = useState<string[]>([]);
  const [selectedSiteTypeItems, setSelectedSiteTypeItems] = useState<string[]>(
    []
  );

  const [statusItems, setStatusItems] = useState<string[]>([]);
  const [selectedStatusItems, setSelectedStatusItems] = useState<string[]>([]);
  const [filterBadgeText, setFilterBadgeText] = useState("0");

  let dFilter: string = "";
  if (selectedGlobalCountry === AppConst.ALL_SITES &&
    userDetails?.roleDetail?.userRoleName === RoleConst.SALES_OPS_SME) {
    dFilter = `search.in(SiteType,'${SiteTypeNameConst.SHELL_IN_NETWORK_RETAIL_SITE},${SiteTypeNameConst.SHELL_COMMERCIAL_SITE},${SiteTypeNameConst.SHELL_LICENSED_SITE},${SiteTypeNameConst.SHELL_COMPETITOR_SITE}', ',')`;
  }
  else if (selectedGlobalCountry !== AppConst.ALL_SITES && userDetails?.roleDetail?.userRoleName === RoleConst.SALES_OPS_SME) {
    dFilter = `Country eq '${selectedGlobalCountry}'`;
  }
  if (selectedGlobalCountry === AppConst.ALL_SITES &&
    userDetails?.roleDetail?.userRoleName === RoleConst.GLOBAL_USER) {
    dFilter = `search.in(Status,'${SiteConst.SITE_STATUS_LIST.ACTIVE},${SiteConst.SITE_STATUS_LIST.CLOSED_TEMPORARILY}', ',') and search.in(SiteType,'${SiteTypeNameConst.SHELL_IN_NETWORK_RETAIL_SITE},${SiteTypeNameConst.SHELL_COMMERCIAL_SITE},${SiteTypeNameConst.SHELL_LICENSED_SITE},${SiteTypeNameConst.SHELL_COMPETITOR_SITE}', ',')`;
  } else if (selectedGlobalCountry !== AppConst.ALL_SITES) {
    dFilter = `Country eq '${selectedGlobalCountry}'`;
  }

  const defaultPayload: SitesListRequestData = {
    filter: dFilter,
    facetFilter:
      selectedGlobalCountry === AppConst.ALL_SITES
        ? ""
        : `Country eq '${selectedGlobalCountry}'`,
    searchText: "*",
    orderby: "",
    page: 0,
    pageSize: 25,
    facets:
      "Country,count:250,sort:value|SiteType,sort:value|Status,count:20,sort:value|SiteCategory,count:150,sort:value|OperatingPlatform,count:150,sort:value",
  };

  const initialPageState: PageState = {
    skip: 0,
    take: 25,
  };

  const initialPaginationState: PaginationState = {
    buttonCount: 4,
    info: true,
    type: "numeric",
    previousNext: true,
  };

  const defaultSort: { field: string; dir: string }[] = [
    {
      field: "",
      dir: "",
    },
  ];

  const [selected, setSelected] = useState<number>(0);
  const [initialSort, setInitialSort] = useState<any>();
  const [sitesListData, setSitesListData] = useState<SitesListDocumentData[]>();
  const [sitesListDataCount, setSitesListDataCount] = useState(0);
  const [allShellSitesListCount, setAllShellSitesListCount] = useState(0);
  const [activeSitesListCount, setActiveSitesListCount] = useState(0);
  const [closedTemporarilySitesListCount, setClosedTemporarilySitesListCount] =
    useState(0);
  const [competitorSitesListCount, setCompetitorSitesListCount] =
    useState(0);
  const  [saleofsmeActiveSitesListCount, setSalesofsmeActiveSitesFacet] = useState(0);
  const  [salesofsmeClosedTemporarilySitesListCount, setSalesofsmeClosedTemporarilySitesListCount] = useState(0);
  const  [salesofsmeAllShellSitesListCount, setSalesofsmeAllShellSitesListCount] = useState(0);
  const [initialStatusFilterQuery, setInitialStatusFilterQuery] = useState("");
  const [searchText, setSearchText] = useState("*");

  const [statusList, setStatusList] = useState<string[]>([]);
  const [countryList, setCountryList] = useState<string[]>([]);
  const [categoryList, setCategoryList] = useState<string[]>([]);
  const [operatingPlatformList, setOperatingPlatformList] = useState<string[]>(
    []
  );
  const [siteTypeList, setSiteTypeList] = useState<string[]>([]);

  const [isDataLoading, setIsDataLoading] = useState(true);
  const [isExportDataLoading, setIsExportDataLoading] = useState(false);
  const [requestPayload, setRequestPayload] =
    useState<SitesListRequestData>(defaultPayload);

  const [pageState, setPageState] = useState<{ skip: number; take: number }>();
  const [pageable, setPageable] = useState<PaginationState | boolean>(
    initialPaginationState
  );

  const [countryItems, setCountryItems] = useState<string[]>([]);
  const [selectedCountryItems, setSelectedCountryItems] = useState<string[]>(
    []
  );

  const [categoryItems, setCategoryItems] = useState<string[]>([]);
  const [selectedCategoryItems, setSelectedCategoryItems] = useState<string[]>(
    []
  );

  const [opearatingPlatformItems, setOpearatingPlatformItems] = useState<
    string[]
  >([]);
  const [selectedOpearatingPlatformItems, setSelectedOpearatingPlatformItems] =
    useState<string[]>([]);

  const [exportData, setExportData] = useState<SitesListDocumentData[]>();
  const [rblaExportData, setRBLAExportData] = useState<any>();
  const [checked, setChecked] = useState(
    AppConst.EXPORT_DATA_TYPE.CURRENT_PAGE
  );

  const [isShellRole, setIsShellRole] = useState(false);
  const [nonShellRoleTableTitle, setNonShellRoleTableTitle] = useState("");
  const [showCountryFilter, setShowCountryFilter] = useState(false);

  const [sortFilterPayload, setSortFilterPayload] = useState<any>();

  const [predefinedSearchTextValue, setPredefinedSearchTextValue] =
    useState("");

  const isMounted = useRef(false);

  // let tableHeaders = [];
  let tableHeaders: TableHeaderType[] = [];

  const shellGlobalRoleTableHeaders = [
    {
      label: Locale.landing_page?.sites_list.table_headers.global_id_label,
      field: SiteConst.SITE_LIST_TABLE_HEADER_KEY.GLOBAL_SITE_ID,
      // width: "90",
      minWidth: "11",
    },
    {
      label: Locale.landing_page?.sites_list.table_headers.site_name_label,
      field: SiteConst.SITE_LIST_TABLE_HEADER_KEY.SITE_NAME,
      minWidth: "25",
    },
    {
      label: Locale.landing_page?.sites_list.table_headers.status_label,
      field: SiteConst.SITE_LIST_TABLE_HEADER_KEY.STATUS,
      // width: "90",
      minWidth: "10",
    },
    {
      label: Locale.landing_page?.sites_list.table_headers.site_type_label,
      field: SiteConst.SITE_LIST_TABLE_HEADER_KEY.SITE_TYPE,
      // width: "180",
      minWidth: "15",
    },
    {
      label:
        Locale.landing_page?.sites_list.table_headers.operating_platform_label,
      field: SiteConst.SITE_LIST_TABLE_HEADER_KEY.OPERATING_PLATFORM,
      // width: "140",
      minWidth: "15",
    },
    {
      label: Locale.landing_page?.sites_list.table_headers.site_category_label,
      field: SiteConst.SITE_LIST_TABLE_HEADER_KEY.SITE_CATEGORY,
      // width: "230",
      minWidth: "14",
    },
  ];

  const nonShellRoleTableheaders = [
    {
      label: Locale.landing_page?.sites_list.table_headers.global_id_label,
      field: SiteConst.SITE_LIST_TABLE_HEADER_KEY.GLOBAL_SITE_ID,
      // width: "120",
      minWidth: "11",
    },
    {
      label: Locale.landing_page?.sites_list.table_headers.site_name_label,
      field: SiteConst.SITE_LIST_TABLE_HEADER_KEY.SITE_NAME,
      minWidth: "33.75",
    },
    {
      label: Locale.landing_page?.sites_list.table_headers.status_label,
      field: SiteConst.SITE_LIST_TABLE_HEADER_KEY.STATUS,
      // width: "90",
      minWidth: "10",
    },
  ];

  if (
    roleName === RoleConst.SALES_OPS_SME ||
    roleName === RoleConst.MARKET_DATA_LEAD ||
    roleName === RoleConst.FUEL_PRICING_GLOBAL_SME ||
    roleName === RoleConst.GLOBAL_USER
  ) {
    shellGlobalRoleTableHeaders.push({
      label: Locale.landing_page?.sites_list.table_headers.country_label,
      field: SiteConst.SITE_LIST_TABLE_HEADER_KEY.COUNTRY,
      // width: "130",
      minWidth: "10",
    });
  } else if (
    roleName === RoleConst.TM_AND_DM ||
    roleName === RoleConst.RETAILER ||
    roleName === RoleConst.GI_ACCESS_USER ||
    roleName === RoleConst.FUEL_PRICING_LOCAL_SME
  ) {
    if (userDetails?.userRoleMapping?.length > 1) {
      shellGlobalRoleTableHeaders.push({
        label: Locale.landing_page?.sites_list.table_headers.country_label,
        field: SiteConst.SITE_LIST_TABLE_HEADER_KEY.COUNTRY,
        // width: "130",
        minWidth: "10",
      });
    } else {
      shellGlobalRoleTableHeaders.push({
        label: Locale.landing_page?.sites_list.table_headers.city_label,
        field: SiteConst.SITE_LIST_TABLE_HEADER_KEY.CITY,
        // width: "130",
        minWidth: "10",
      });
    }
  }

  if (
    roleName === RoleConst.SALES_OPS_SME ||
    roleName === RoleConst.GI_ACCESS_USER ||
    roleName === RoleConst.MARKET_DATA_LEAD ||
    roleName === RoleConst.TM_AND_DM ||
    roleName === RoleConst.RETAILER ||
    roleName === RoleConst.FUEL_PRICING_GLOBAL_SME ||
    roleName === RoleConst.FUEL_PRICING_LOCAL_SME ||
    roleName === RoleConst.GLOBAL_USER
  ) {
    tableHeaders = shellGlobalRoleTableHeaders;
  } else if (roleName) {
    if (
      roleName === RoleConst.RBLA_GLOBAL_SME ||
      roleName === RoleConst.RBLA_LOCAL_SME
      // || roleName === RoleConst.FUEL_PRICING_GLOBAL_SME ||
      // roleName === RoleConst.FUEL_PRICING_LOCAL_SME
    ) {
      nonShellRoleTableheaders?.push({
        label:
          Locale.landing_page?.sites_list.table_headers
            .operating_platform_label,
        field: SiteConst.SITE_LIST_TABLE_HEADER_KEY.OPERATING_PLATFORM,
        // width: "180",
        minWidth: "18",
      });
    } else if (
      roleName === RoleConst.PARTNER_GLOBAL_SME ||
      roleName === RoleConst.PARTNER_LOCAL_SME
    ) {
      nonShellRoleTableheaders?.push({
        label:
          Locale.landing_page?.sites_list.table_headers.org_affiliation_label,
        field: SiteConst.SITE_LIST_TABLE_HEADER_KEY.ORG_AFFILIATION_CODE,
        // width: "170",
        minWidth: "18",
      });
    }

    nonShellRoleTableheaders?.push(
      {
        label: Locale.landing_page?.sites_list.table_headers.city_label,
        field: SiteConst.SITE_LIST_TABLE_HEADER_KEY.CITY,
        // width: "200",
        minWidth: "14",
      },
      {
        label: Locale.landing_page?.sites_list.table_headers.country_label,
        field: SiteConst.SITE_LIST_TABLE_HEADER_KEY.COUNTRY,
        // width: "160",
        minWidth: "13",
      }
    );
    tableHeaders = nonShellRoleTableheaders;
  }

  const { isLoading, data, refetch } = useQuery(
    "sites-list",
    () => {
      return SitesService.getSitesList(requestPayload);
    },
    {
      staleTime: AppConst.VIEW_SITE_DETAILS_STALE_TIME,
      enabled: false,
      refetchOnMount: true,
      refetchOnWindowFocus: false,
    }
  );

  const { data: competitorTypeCountData, refetch: refetchCompetitorSiteCount, } = useQuery(
    "search-sites-competitor-type-facet", 
    () => {
      return SitesService.getSitesList( 
        {
          ...defaultPayload,
          filter: 
          selectedGlobalCountry === AppConst.ALL_SITES ?
          `search.in(SiteType,'${SiteTypeNameConst.SHELL_COMPETITOR_SITE}', ',')` :
          `search.in(SiteType,'${SiteTypeNameConst.SHELL_COMPETITOR_SITE}', ',') and search.in(Country,'${selectedGlobalCountry}', ',')`,
          facetFilter:  
          selectedGlobalCountry === AppConst.ALL_SITES ?
          `search.in(SiteType,'${SiteTypeNameConst.SHELL_COMPETITOR_SITE}', ',')` :
          `search.in(SiteType,'${SiteTypeNameConst.SHELL_COMPETITOR_SITE}', ',') and search.in(Country,'${selectedGlobalCountry}', ',')`,
        }
      );
    },
    {
      staleTime: AppConst.VIEW_SITE_DETAILS_STALE_TIME,
      enabled: true,
      refetchOnMount: true,
      refetchOnWindowFocus: false,
      select: (data) => data?.data?.result?.facets,
    }
  );

  const { data: shellSiteCountData, refetch: refetchShellSitesCount, } = useQuery(
    "search-sites-shell-type-facet", 
    () => {
      return SitesService.getSitesList( 
        {
          ...defaultPayload,
          filter:
          selectedGlobalCountry === AppConst.ALL_SITES
            ? `search.in(SiteType, '${SiteTypeNameConst.SHELL_IN_NETWORK_RETAIL_SITE},${SiteTypeNameConst.SHELL_COMMERCIAL_SITE}', ',')`
            : `search.in(SiteType, '${SiteTypeNameConst.SHELL_IN_NETWORK_RETAIL_SITE},${SiteTypeNameConst.SHELL_COMMERCIAL_SITE}', ',') and search.in(Country,'${selectedGlobalCountry}', ',') `,
        facetFilter:
          selectedGlobalCountry === AppConst.ALL_SITES
            ? `search.in(SiteType, '${SiteTypeNameConst.SHELL_IN_NETWORK_RETAIL_SITE},${SiteTypeNameConst.SHELL_COMMERCIAL_SITE}', ',')`
            : `search.in(SiteType, '${SiteTypeNameConst.SHELL_IN_NETWORK_RETAIL_SITE},${SiteTypeNameConst.SHELL_COMMERCIAL_SITE}', ',') and search.in(Country,'${selectedGlobalCountry}', ',')`,
        }
      );
    },
    {
      staleTime: AppConst.VIEW_SITE_DETAILS_STALE_TIME,
      enabled: true,
      refetchOnMount: true,
      refetchOnWindowFocus: false,
      select: (data) => data?.data?.result,
    }
  );

  useEffect(() => {
    if (
      predefinedFilters !== null &&
      predefinedFilters !== undefined &&
      Object.keys(predefinedFilters)?.length !== 0
    ) {
      setSelected(predefinedFilters?.lastSelectedTab);
      setPredefinedSearchTextValue(predefinedFilters?.searchTextValue);
      // setSearchText(predefinedFilters?.searchTextValue);
      setRequestPayload(predefinedFilters?.requestPayload);
      setSortFilterPayload(predefinedFilters?.sort);
      setSelectedStatusItems(predefinedFilters?.filterItems.statusFilter);
      setSelectedSiteTypeItems(predefinedFilters?.filterItems.siteTypeFilter);
      setSelectedOpearatingPlatformItems(
        predefinedFilters?.filterItems.operatingPlatformFilter
      );
      setSelectedCategoryItems(predefinedFilters?.filterItems.categoryFilter);
      setSelectedCountryItems(predefinedFilters?.filterItems.countryFilter);

      setPageState({
        skip:
          predefinedFilters?.requestPayload?.page *
          predefinedFilters?.requestPayload?.pageSize || 0,
        take: predefinedFilters?.requestPayload?.pageSize || 25,
      });
      setInitialSort(predefinedFilters?.sort || defaultSort);

      const filterArr = [
        ...predefinedFilters?.filterItems.statusFilter,
        ...predefinedFilters?.filterItems.siteTypeFilter,
        ...predefinedFilters?.filterItems.operatingPlatformFilter,
        ...predefinedFilters?.filterItems.categoryFilter,
        ...predefinedFilters?.filterItems.countryFilter,
      ];
      setFilterBadgeText(filterArr.length?.toString());

      if (predefinedFilters?.lastSelectedTab === 0) {
        setInitialStatusFilterQuery("");
      } else if (predefinedFilters?.lastSelectedTab === 1) {
        setInitialStatusFilterQuery(SiteConst.SITE_STATUS_LIST.ACTIVE);
      } else if (predefinedFilters?.lastSelectedTab === 2) {
        setInitialStatusFilterQuery(
          SiteConst.SITE_STATUS_LIST.CLOSED_TEMPORARILY
        );
      }
    } else {
      setPageState(initialPageState);
      setInitialSort(defaultSort);
      setSortFilterPayload(defaultSort);
      onRefreshClick();
    }
  }, [predefinedFilters, selectedGlobalCountry, roleName]);

  useEffect(() => {
    valueRef.current = {
      lastSelectedTab: selected,
      searchTextValue: predefinedSearchTextValue,
      requestPayload: requestPayload,
      sort: sortFilterPayload,
      filterItems: {
        statusFilter: [...selectedStatusItems],
        siteTypeFilter: [...selectedSiteTypeItems],
        operatingPlatformFilter: [...selectedOpearatingPlatformItems],
        categoryFilter: [...selectedCategoryItems],
        countryFilter: [...selectedCountryItems],
      },
    };
  }, [
    requestPayload,
    selected,
    predefinedSearchTextValue,
    sortFilterPayload,
    selectedStatusItems,
    selectedSiteTypeItems,
    selectedOpearatingPlatformItems,
    selectedCategoryItems,
    selectedCountryItems,
  ]);

  useEffect(() => {
    return () => {
      dispatch(updateSitesListPredefinedFilters(valueRef.current));
    };
  }, []);

  useEffect(() => {
    setRoleName(userDetails?.roleDetail?.userRoleName || "");
    if (userDetails?.roleDetail?.userRoleName) {
      const roleName = userDetails?.roleDetail?.userRoleName;
      if (
        roleName === RoleConst.SALES_OPS_SME ||
        roleName === RoleConst.GI_ACCESS_USER ||
        roleName === RoleConst.MARKET_DATA_LEAD ||
        roleName === RoleConst.TM_AND_DM ||
        roleName === RoleConst.RETAILER ||
        roleName === RoleConst.FUEL_PRICING_LOCAL_SME ||
        roleName === RoleConst.FUEL_PRICING_GLOBAL_SME ||
        roleName === RoleConst.GLOBAL_USER
      ) {
        setIsShellRole(true);
        if (
          roleName === RoleConst.SALES_OPS_SME ||
          roleName === RoleConst.MARKET_DATA_LEAD ||
          roleName === RoleConst.FUEL_PRICING_GLOBAL_SME ||
          roleName === RoleConst.GLOBAL_USER ||
          userDetails?.userRoleMapping?.length > 1
        ) {
          setShowCountryFilter(true);
        } else {
          setShowCountryFilter(false);
        }
      } else {
        setIsShellRole(false);
        // if (
        //   roleName === RoleConst.FUEL_PRICING_LOCAL_SME ||
        //   roleName === RoleConst.FUEL_PRICING_GLOBAL_SME
        // ) {
        //   setNonShellRoleTableTitle(
        //     Locale.landing_page?.sites_list.tab_titles.all_competitor_sites
        //   );
        // } else
        if (
          roleName === RoleConst.RBLA_LOCAL_SME ||
          roleName === RoleConst.RBLA_GLOBAL_SME
        ) {
          setNonShellRoleTableTitle(
            Locale.landing_page?.sites_list.tab_titles.all_licensee_sites
          );
        } else if (
          roleName === RoleConst.PARTNER_LOCAL_SME ||
          roleName === RoleConst.PARTNER_GLOBAL_SME
        ) {
          setNonShellRoleTableTitle(
            Locale.landing_page?.sites_list.tab_titles.partner_sites
          );
        }

        if (
          // roleName === RoleConst.FUEL_PRICING_GLOBAL_SME ||
          roleName === RoleConst.RBLA_GLOBAL_SME ||
          roleName === RoleConst.PARTNER_GLOBAL_SME ||
          userDetails?.userRoleMapping?.length > 1
        ) {
          setShowCountryFilter(true);
        }
      }
    }
  }, [userDetails]);

  useEffect(() => {
    if (data) {
      const uniqueStatusList = data?.data?.result?.facets?.Status?.map(
        function (item: FacetState) {
          return item?.value;
        }
      );
      if (uniqueStatusList?.length) {
        setStatusList(uniqueStatusList);
      }

      const uniqueCountryList = data?.data?.result?.facets?.Country?.map(
        function (item: FacetState) {
          return item?.value;
        }
      );

      if (uniqueCountryList?.length) {
        setCountryList(uniqueCountryList);

        if (!isCountriesDispatched) {
          setIsCountriesDispatched(true);
          dispatch(setGlobalCountry(uniqueCountryList));
        }
      }

      const uniqueCategoryList = data?.data?.result?.facets?.SiteCategory?.map(
        function (item: FacetState) {
          return item?.value;
        }
      );

      if (uniqueCategoryList?.length) {
        setCategoryList(uniqueCategoryList);
      }

      const uniqueOperatingPlatformList =
        data?.data?.result?.facets?.OperatingPlatform?.map(function (
          item: FacetState
        ) {
          return item?.value;
        });

      if (uniqueOperatingPlatformList?.length) {
        setOperatingPlatformList(uniqueOperatingPlatformList);
      }

      const uniqueSiteTypeList = data?.data?.result?.facets?.SiteType?.map(
        function (item: FacetState) {
          return item?.value;
        }
      );

      if (uniqueSiteTypeList?.length) {
        setSiteTypeList(uniqueSiteTypeList);
      }

      const salesofsmeStatusFacet = shellSiteCountData?.facets?.Status;
      const salesofsmeActiveSitesFacet: { value: string; count: number } =
      salesofsmeStatusFacet?.find(
        (item: FacetState) =>
          item?.value === SiteConst.SITE_STATUS_LIST.ACTIVE
      );

      const salesofsmectSitesFacet: { value: string; count: number } = salesofsmeStatusFacet?.find(
        (item: FacetState) =>
          item?.value === SiteConst.SITE_STATUS_LIST.CLOSED_TEMPORARILY
      );

      const statusFacet = data?.data?.result?.facets?.Status;
      const activeSitesFacet: { value: string; count: number } =
        statusFacet?.find(
          (item: FacetState) =>
            item?.value === SiteConst.SITE_STATUS_LIST.ACTIVE
        );

      const ctSitesFacet: { value: string; count: number } = statusFacet?.find(
        (item: FacetState) =>
          item?.value === SiteConst.SITE_STATUS_LIST.CLOSED_TEMPORARILY
      );

      const allSiteCount: number = statusFacet?.reduce(
        (acc: number, obj: { value: string; count: number }) =>
          acc + obj?.count,
        0
      );

      const siteTypeFacet = data?.data?.result?.facets?.SiteType;

      const competitorSiteTypeFacet = competitorTypeCountData?.SiteType;

      const competitorSiteCount: { value: string; count: number } = competitorSiteTypeFacet?.find(
        (item: FacetState) =>
          item?.value === "Competitor Site"
      );

      const allCompetitorSitesCount: number = siteTypeFacet?.find(
        (item: { value: string; count: number }) =>
          item?.value.trim() === "Competitor Site"
      )?.count;

      if (selected === 4) {
        setAllShellSitesListCount(
          allSiteCount - (allCompetitorSitesCount || 0)
        );
      } else {
        setAllShellSitesListCount(allSiteCount || 0);
        setActiveSitesListCount(activeSitesFacet?.count || 0);
        setClosedTemporarilySitesListCount(ctSitesFacet?.count || 0);
        setCompetitorSitesListCount(competitorSiteCount?.count || 0)
        setSalesofsmeActiveSitesFacet (salesofsmeActiveSitesFacet?.count || 0)
        setSalesofsmeClosedTemporarilySitesListCount(salesofsmectSitesFacet?.count || 0)
        setSalesofsmeAllShellSitesListCount (shellSiteCountData?.totalCount || 0)
      }

      setSitesListDataCount(data?.data?.result?.totalCount);
      setSitesListData(data?.data?.result?.siteDocuments);
      if (!isExportDataLoading) {
        setIsDataLoading(false);
      }
      setPageable(initialPaginationState);
      if (data?.data?.result?.totalCount <= 25) {
        setPageable(false);
      }
    }
  }, [data, shellSiteCountData, competitorTypeCountData]);

  useEffect(() => {
    setStatusItems(statusList?.slice());
  }, [statusList]);

  useEffect(() => {
    setCountryItems(countryList?.slice());
  }, [countryList]);

  useEffect(() => {
    setCategoryItems(categoryList?.slice());
  }, [categoryList]);

  useEffect(() => {
    setOpearatingPlatformItems(operatingPlatformList?.slice());
  }, [categoryList]);

  useEffect(() => {
    roleName !== RoleConst.SALES_OPS_SME &&
      setSiteTypeItems(siteTypeList?.slice());
  }, [siteTypeList]);

  useEffect(() => {
    if (isMounted?.current) {
      setIsDataLoading(true);
      refetch();
      refetchShellSitesCount();
      refetchCompetitorSiteCount();
    }
  }, [requestPayload]);

  useEffect(() => {
    if (isMounted?.current) {
      const delayDebounceFn = setTimeout(() => {
        if (searchText) {
          if (searchText?.length >= 2) {
            setPageState({
              ...initialPageState,
              take: requestPayload?.pageSize,
            });
            setIsDataLoading(true);
            setRequestPayload({ ...requestPayload, page: 0, searchText });
          }
        } else {
          setPageState({ ...initialPageState, take: requestPayload?.pageSize });
          setIsDataLoading(true);
          setRequestPayload({ ...requestPayload, page: 0, searchText: "*" });
        }
      }, 2000);

      return () => clearTimeout(delayDebounceFn);
    } else {
      isMounted.current = true;
    }
  }, [searchText]);

  useEffect(() => {
    if (exportData) {
      const ws = XLSX.utils.json_to_sheet(exportData);
      const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const data = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(data, "Export" + fileExtension);
      setIsDataLoading(false);
      setIsExportDataLoading(false);
    }
  }, [exportData]);

  AppConst.useEffectSkipFirst(() => {
    if (rblaExportData?.length) {
      const ws = XLSX.utils.json_to_sheet(rblaExportData);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Data");
      XLSX.writeFileXLSX(wb, "Export.xlsx");
      console.log("Data Exported");
      console.log(new Date());
    }
  }, [rblaExportData]);

  const replaceAll = async (str: string, find: string, replace: string) => {
    const wordArray: string[] = str
      .replaceAll("&", " and ")
      .split(" ")
      .filter((val) => val);
    const convertedWordArray = wordArray?.map(
      (value: string, index: number) => {
        let word = value;
        if (value === find && index !== 0 && index !== wordArray?.length - 1) {
          word = value.replace(new RegExp(find, "ig"), replace);
        }
        return word;
      }
    );
    return convertedWordArray.join(" ");
  };

  const handleSelect = (event: TabStripSelectEventArguments) => {
    setIsDataLoading(true);
    setPageState(initialPageState);
    setSelectedStatusItems([]);
    setSelectedSiteTypeItems([]);
    setSelectedCountryItems([]);
    setSelectedCategoryItems([]);
    setSelectedOpearatingPlatformItems([]);
    setSortFilterPayload(defaultSort);
    setInitialSort(defaultSort);
    setChecked(AppConst.EXPORT_DATA_TYPE.CURRENT_PAGE);
    setFilterBadgeText("0");
    if (event.selected === 0) {
      setInitialStatusFilterQuery("");
      setRequestPayload(defaultPayload);
      if (roleName === RoleConst.GLOBAL_USER) {
        setFilterBadgeText("5");
        setSelectedStatusItems([
          SiteConst.SITE_STATUS_LIST.ACTIVE,
          SiteConst.SITE_STATUS_LIST.CLOSED_TEMPORARILY,
          SiteConst.SITE_STATUS_LIST.COMPETITOR_SITE
        ]);
        setSelectedSiteTypeItems([
          SiteTypeNameConst.SHELL_COMMERCIAL_SITE,
          SiteTypeNameConst.SHELL_IN_NETWORK_RETAIL_SITE,
          SiteTypeNameConst.SHELL_LICENSED_SITE,
          SiteTypeNameConst.SHELL_COMPETITOR_SITE
        ]);
      }
      if (userDetails?.roleDetail?.userRoleName === RoleConst.SALES_OPS_SME && selectedGlobalCountry === AppConst.ALL_SITES) {
        setRequestPayload({
          ...defaultPayload,
          filter: `search.in(SiteType,'${SiteTypeNameConst.SHELL_IN_NETWORK_RETAIL_SITE},${SiteTypeNameConst.SHELL_COMMERCIAL_SITE}', ',')`,
          facetFilter: `search.in(SiteType,'${SiteTypeNameConst.SHELL_IN_NETWORK_RETAIL_SITE},${SiteTypeNameConst.SHELL_COMMERCIAL_SITE}', ',')`,
        });
        setSiteTypeItems([
          SiteTypeNameConst.SHELL_COMMERCIAL_SITE,
          SiteTypeNameConst.SHELL_IN_NETWORK_RETAIL_SITE,
        ]);
      } else if (selectedGlobalCountry !== AppConst.ALL_SITES && userDetails?.roleDetail?.userRoleName === RoleConst.SALES_OPS_SME) {
        setRequestPayload({
          ...defaultPayload,
          filter: `search.in(SiteType,'${SiteTypeNameConst.SHELL_IN_NETWORK_RETAIL_SITE},${SiteTypeNameConst.SHELL_COMMERCIAL_SITE}', ',') and search.in(Country,'${selectedGlobalCountry}', ',')`,
          facetFilter: `search.in(SiteType,'${SiteTypeNameConst.SHELL_IN_NETWORK_RETAIL_SITE},${SiteTypeNameConst.SHELL_COMMERCIAL_SITE}', ',') and search.in(Country,'${selectedGlobalCountry}', ',')`
        })
      }
    } else if (event.selected === 1) {
      setInitialStatusFilterQuery(SiteConst.SITE_STATUS_LIST.ACTIVE);
      if (userDetails?.roleDetail?.userRoleName === RoleConst.SALES_OPS_SME && selectedGlobalCountry === AppConst.ALL_SITES) {
        setRequestPayload({
          ...defaultPayload,
          filter: `search.in(Status,'${SiteConst.SITE_STATUS_LIST.ACTIVE}', ',') and search.in(SiteType,'${SiteTypeNameConst.SHELL_IN_NETWORK_RETAIL_SITE},${SiteTypeNameConst.SHELL_COMMERCIAL_SITE}', ',')`,
          facetFilter: `search.in(SiteType,'${SiteTypeNameConst.SHELL_IN_NETWORK_RETAIL_SITE},${SiteTypeNameConst.SHELL_COMMERCIAL_SITE}', ',')`,
        });
        setSiteTypeItems([
          SiteTypeNameConst.SHELL_COMMERCIAL_SITE,
          SiteTypeNameConst.SHELL_IN_NETWORK_RETAIL_SITE,
        ]);
      }else if (selectedGlobalCountry !== AppConst.ALL_SITES && userDetails?.roleDetail?.userRoleName === RoleConst.SALES_OPS_SME) {
        setRequestPayload({
          ...defaultPayload,
          filter: `search.in(Status,'${SiteConst.SITE_STATUS_LIST.ACTIVE}', ',') and search.in(SiteType,'${SiteTypeNameConst.SHELL_IN_NETWORK_RETAIL_SITE},${SiteTypeNameConst.SHELL_COMMERCIAL_SITE}', ',') and search.in(Country,'${selectedGlobalCountry}', ',')`,
          facetFilter: `search.in(Status,'${SiteConst.SITE_STATUS_LIST.ACTIVE}', ',') and search.in(SiteType,'${SiteTypeNameConst.SHELL_IN_NETWORK_RETAIL_SITE},${SiteTypeNameConst.SHELL_COMMERCIAL_SITE}', ',') and search.in(Country,'${selectedGlobalCountry}', ',')`
        })
      }
      else if (selectedGlobalCountry === AppConst.ALL_SITES) {
        setRequestPayload({
          ...defaultPayload,
          filter: `search.in(Status,'${SiteConst.SITE_STATUS_LIST.ACTIVE}', ',')`,
        });
      } else {
        setRequestPayload({
          ...defaultPayload,
          filter: `search.in(Status,'${SiteConst.SITE_STATUS_LIST.ACTIVE}', ',') and search.in(Country,'${selectedGlobalCountry}', ',')`,
        });
      }
    } else if (event.selected === 2) {
      setInitialStatusFilterQuery(
        SiteConst.SITE_STATUS_LIST.CLOSED_TEMPORARILY
      );
      if (userDetails?.roleDetail?.userRoleName === RoleConst.SALES_OPS_SME && selectedGlobalCountry === AppConst.ALL_SITES) {
        setRequestPayload({
          ...defaultPayload,
          filter: `search.in(Status,'${SiteConst.SITE_STATUS_LIST.CLOSED_TEMPORARILY}', ',') and search.in(SiteType,'${SiteTypeNameConst.SHELL_IN_NETWORK_RETAIL_SITE},${SiteTypeNameConst.SHELL_COMMERCIAL_SITE}', ',')`,
          facetFilter: `search.in(Status,'${SiteConst.SITE_STATUS_LIST.CLOSED_TEMPORARILY}', ',') and search.in(SiteType,'${SiteTypeNameConst.SHELL_IN_NETWORK_RETAIL_SITE},${SiteTypeNameConst.SHELL_COMMERCIAL_SITE}', ',')`,
        });
        setSiteTypeItems([
          SiteTypeNameConst.SHELL_COMMERCIAL_SITE,
          SiteTypeNameConst.SHELL_IN_NETWORK_RETAIL_SITE,
        ]);
      }else if (selectedGlobalCountry !== AppConst.ALL_SITES && userDetails?.roleDetail?.userRoleName === RoleConst.SALES_OPS_SME) {
        setRequestPayload({
          ...defaultPayload,
          filter: `search.in(Status,'${SiteConst.SITE_STATUS_LIST.CLOSED_TEMPORARILY}', ',') and search.in(SiteType,'${SiteTypeNameConst.SHELL_IN_NETWORK_RETAIL_SITE},${SiteTypeNameConst.SHELL_COMMERCIAL_SITE}', ',') and search.in(Country,'${selectedGlobalCountry}', ',')`,
          facetFilter: `search.in(Status,'${SiteConst.SITE_STATUS_LIST.CLOSED_TEMPORARILY}', ',') and search.in(SiteType,'${SiteTypeNameConst.SHELL_IN_NETWORK_RETAIL_SITE},${SiteTypeNameConst.SHELL_COMMERCIAL_SITE}', ',') and search.in(Country,'${selectedGlobalCountry}', ',')`
        })
      } else if (selectedGlobalCountry === AppConst.ALL_SITES) {
          setRequestPayload({
            ...defaultPayload,
            filter: `search.in(Status,'${SiteConst.SITE_STATUS_LIST.CLOSED_TEMPORARILY}', ',')`,
          });
        } else {
          setRequestPayload({
            ...defaultPayload,
            filter: `search.in(Status,'${SiteConst.SITE_STATUS_LIST.CLOSED_TEMPORARILY}', ',') and search.in(Country ,'${selectedGlobalCountry}', ',')`,
          });
        }
    }
    else if (event.selected === 3) {
      setInitialStatusFilterQuery("");
      if (selectedGlobalCountry === AppConst.ALL_SITES && userDetails?.roleDetail?.userRoleName === RoleConst.SALES_OPS_SME) {
        setRequestPayload({
          ...defaultPayload,
          filter: `search.in(SiteType,'${SiteTypeNameConst.SHELL_COMPETITOR_SITE}', ',')`,
          facetFilter: `search.in(SiteType,'${SiteTypeNameConst.SHELL_COMPETITOR_SITE}', ',')`
        });
      } else {
        setRequestPayload({
          ...defaultPayload,
          filter: `search.in(SiteType,'${SiteTypeNameConst.SHELL_COMPETITOR_SITE}', ',') and search.in(Country,'${selectedGlobalCountry}', ',')`,
          facetFilter: `search.in(SiteType,'${SiteTypeNameConst.SHELL_COMPETITOR_SITE}', ',') and search.in(Country,'${selectedGlobalCountry}', ',')`
        });
      }
    }
    setSelected(event.selected);
    setPredefinedSearchTextValue("");
  };

  const pageChange = (event: PageState) => {
    setIsDataLoading(true);
    setPageState(event);
    const page: number = event.skip / event.take; //pageSize;
    setRequestPayload({ ...requestPayload, page, pageSize: event.take });
  };

  const pageSort = (event: GridSortChangeEvent) => {
    setIsDataLoading(true);
    setSortFilterPayload(event.sort);
    if (event.sort?.length) {
      setRequestPayload({
        ...requestPayload,
        orderby: `${SiteConst.COLUMN_CONFIG[event.sort?.[0].field]} ${event.sort?.[0].dir
          }`,
      });
    } else {
      setRequestPayload({
        ...requestPayload,
        orderby: "",
      });
    }
  };

  const onRefreshClick = () => {
    setFilterBadgeText("0");
    setSelectedStatusItems([]);
    setSelectedSiteTypeItems([]);
    setSelectedCountryItems([]);
    setSelectedCategoryItems([]);
    setSelectedOpearatingPlatformItems([]);
    setChecked(AppConst.EXPORT_DATA_TYPE.CURRENT_PAGE);
    setPageState(initialPageState);
    setInitialSort(defaultSort);
    setSortFilterPayload(defaultSort);
    if (selected === 0) {
      setInitialStatusFilterQuery("");
      setRequestPayload({
        ...defaultPayload,
      });
      if (
        roleName === RoleConst.GLOBAL_USER &&
        selectedGlobalCountry === AppConst.ALL_SITES
      ) {
        setFilterBadgeText("5");
        setSelectedStatusItems([
          SiteConst.SITE_STATUS_LIST.ACTIVE,
          SiteConst.SITE_STATUS_LIST.CLOSED_TEMPORARILY,
        ]);
        setSelectedSiteTypeItems([
          SiteTypeNameConst.SHELL_COMMERCIAL_SITE,
          SiteTypeNameConst.SHELL_IN_NETWORK_RETAIL_SITE,
          SiteTypeNameConst.SHELL_LICENSED_SITE,
        ]);
      }
      if (userDetails?.roleDetail?.userRoleName === RoleConst.SALES_OPS_SME && selectedGlobalCountry === AppConst.ALL_SITES) {
        setRequestPayload({
          ...defaultPayload,
          filter: `search.in(SiteType,'${SiteTypeNameConst.SHELL_IN_NETWORK_RETAIL_SITE},${SiteTypeNameConst.SHELL_COMMERCIAL_SITE}', ',')`,
          facetFilter: `search.in(SiteType,'${SiteTypeNameConst.SHELL_IN_NETWORK_RETAIL_SITE},${SiteTypeNameConst.SHELL_COMMERCIAL_SITE}', ',')`,
        });
        setSiteTypeItems([
          SiteTypeNameConst.SHELL_COMMERCIAL_SITE,
          SiteTypeNameConst.SHELL_IN_NETWORK_RETAIL_SITE,
        ]);
      }
      else if (selectedGlobalCountry !== AppConst.ALL_SITES && userDetails?.roleDetail?.userRoleName === RoleConst.SALES_OPS_SME) {
        setRequestPayload({
          ...defaultPayload,
          filter: `search.in(SiteType,'${SiteTypeNameConst.SHELL_IN_NETWORK_RETAIL_SITE},${SiteTypeNameConst.SHELL_COMMERCIAL_SITE}', ',') and search.in(Country,'${selectedGlobalCountry}', ',')`,
          facetFilter: `search.in(SiteType,'${SiteTypeNameConst.SHELL_IN_NETWORK_RETAIL_SITE},${SiteTypeNameConst.SHELL_COMMERCIAL_SITE}', ',') and search.in(Country,'${selectedGlobalCountry}', ',')`
        })
      }
    } 
    else if (selected === 1) {
      setInitialStatusFilterQuery(SiteConst.SITE_STATUS_LIST.ACTIVE);
      if (userDetails?.roleDetail?.userRoleName === RoleConst.SALES_OPS_SME && selectedGlobalCountry === AppConst.ALL_SITES) {
        setRequestPayload({
          ...defaultPayload,
          filter: `search.in(Status,'${SiteConst.SITE_STATUS_LIST.ACTIVE}', ',') and search.in(SiteType,'${SiteTypeNameConst.SHELL_IN_NETWORK_RETAIL_SITE},${SiteTypeNameConst.SHELL_COMMERCIAL_SITE}', ',')`,
          facetFilter: `search.in(SiteType,'${SiteTypeNameConst.SHELL_IN_NETWORK_RETAIL_SITE},${SiteTypeNameConst.SHELL_COMMERCIAL_SITE}', ',')`,
        });
        setSiteTypeItems([
          SiteTypeNameConst.SHELL_COMMERCIAL_SITE,
          SiteTypeNameConst.SHELL_IN_NETWORK_RETAIL_SITE,
        ]);
      }else if (selectedGlobalCountry !== AppConst.ALL_SITES && userDetails?.roleDetail?.userRoleName === RoleConst.SALES_OPS_SME) {
        setRequestPayload({
          ...defaultPayload,
          filter: `search.in(Status,'${SiteConst.SITE_STATUS_LIST.ACTIVE}', ',') and search.in(SiteType,'${SiteTypeNameConst.SHELL_IN_NETWORK_RETAIL_SITE},${SiteTypeNameConst.SHELL_COMMERCIAL_SITE}', ',') and search.in(Country,'${selectedGlobalCountry}', ',')`,
          facetFilter: `search.in(SiteType,'${SiteTypeNameConst.SHELL_IN_NETWORK_RETAIL_SITE},${SiteTypeNameConst.SHELL_COMMERCIAL_SITE}', ',') and search.in(Country,'${selectedGlobalCountry}', ',')`
        })
      }
      else if (selectedGlobalCountry === AppConst.ALL_SITES) {
        setRequestPayload({
          ...defaultPayload,
          filter: `search.in(Status,'${SiteConst.SITE_STATUS_LIST.ACTIVE}', ',')`,
        });
      } else {
        setRequestPayload({
          ...defaultPayload,
          filter: `search.in(Status,'${SiteConst.SITE_STATUS_LIST.ACTIVE}', ',') and search.in(Country,'${selectedGlobalCountry}', ',')`,
        });
      }
    } else if (selected === 2) {
      setInitialStatusFilterQuery(
        SiteConst.SITE_STATUS_LIST.CLOSED_TEMPORARILY
      );
      if (userDetails?.roleDetail?.userRoleName === RoleConst.SALES_OPS_SME && selectedGlobalCountry === AppConst.ALL_SITES) {
        setRequestPayload({
          ...defaultPayload,
          filter: `search.in(Status,'${SiteConst.SITE_STATUS_LIST.CLOSED_TEMPORARILY}', ',') and search.in(SiteType,'${SiteTypeNameConst.SHELL_IN_NETWORK_RETAIL_SITE},${SiteTypeNameConst.SHELL_COMMERCIAL_SITE}', ',')`,
          facetFilter: `search.in(SiteType,'${SiteTypeNameConst.SHELL_IN_NETWORK_RETAIL_SITE},${SiteTypeNameConst.SHELL_COMMERCIAL_SITE}', ',')`,
        });
        setSiteTypeItems([
          SiteTypeNameConst.SHELL_COMMERCIAL_SITE,
          SiteTypeNameConst.SHELL_IN_NETWORK_RETAIL_SITE,
        ]);
      }else if (selectedGlobalCountry !== AppConst.ALL_SITES && userDetails?.roleDetail?.userRoleName === RoleConst.SALES_OPS_SME) {
        setRequestPayload({
          ...defaultPayload,
          filter: `search.in(Status,'${SiteConst.SITE_STATUS_LIST.CLOSED_TEMPORARILY}', ',') and search.in(SiteType,'${SiteTypeNameConst.SHELL_IN_NETWORK_RETAIL_SITE},${SiteTypeNameConst.SHELL_COMMERCIAL_SITE}', ',') and search.in(Country,'${selectedGlobalCountry}', ',')`,
          facetFilter: `search.in(SiteType,'${SiteTypeNameConst.SHELL_IN_NETWORK_RETAIL_SITE},${SiteTypeNameConst.SHELL_COMMERCIAL_SITE}', ',') and search.in(Country,'${selectedGlobalCountry}', ',')`
        })
      }
      else if (selectedGlobalCountry === AppConst.ALL_SITES) {
        setRequestPayload({
          ...defaultPayload,
          filter: `search.in(Status,'${SiteConst.SITE_STATUS_LIST.CLOSED_TEMPORARILY}', ',')`,
        });
      } else {
        setRequestPayload({
          ...defaultPayload,
          filter: `search.in(Status,'${SiteConst.SITE_STATUS_LIST.CLOSED_TEMPORARILY}', ',') and search.in(Country,'${selectedGlobalCountry}', ',')`
        });
      }
    }
    else if (selected === 3) {
     setInitialStatusFilterQuery("");
      if (userDetails?.roleDetail?.userRoleName === RoleConst.SALES_OPS_SME && selectedGlobalCountry === AppConst.ALL_SITES) {
        setRequestPayload({
          ...defaultPayload,
         filter: `search.in(SiteType,'${SiteTypeNameConst.SHELL_COMPETITOR_SITE}', ',')`,
         facetFilter: `search.in(SiteType,'${SiteTypeNameConst.SHELL_COMPETITOR_SITE}', ',')`
        });
      } else {
        setRequestPayload({
          ...defaultPayload,
        filter: `search.in(SiteType,'${SiteTypeNameConst.SHELL_COMPETITOR_SITE}', ',') and search.in(Country,'${selectedGlobalCountry}', ',')`,
        facetFilter: `search.in(SiteType,'${SiteTypeNameConst.SHELL_COMPETITOR_SITE}', ',') and search.in(Country,'${selectedGlobalCountry}', ',')`
        });
      }
    }
  };

  const onSearch = async (searchText: string) => {
    setPredefinedSearchTextValue(searchText);
    setSearchText("");
    let modifiedSearchText = await replaceAll(searchText, "and", "AND");
    modifiedSearchText = await replaceAll(modifiedSearchText, "or", "OR");
    setSearchText(modifiedSearchText);
  };

  const excelExport = () => {
    setIsDataLoading(true);
    setIsExportDataLoading(true);

    let totalSitesCountTobeExported: number = sitesListDataCount;
    let countriesSelectedForExport = selectedCountryItems || [];

    if ((searchText && searchText !== "*") || predefinedSearchTextValue) {
      totalSitesCountTobeExported = allShellSitesListCount;
    }
    if (selectedGlobalCountry !== AppConst.ALL_SITES && selectedGlobalCountry) {
      countriesSelectedForExport = [selectedGlobalCountry];
    }

    if (
      roleName === RoleConst.RBLA_LOCAL_SME ||
      roleName === RoleConst.RBLA_GLOBAL_SME
    ) {
      console.log("Export started", new Date());
      SitesService.getRBLAExportData({
        status: selectedStatusItems,
        countries: countriesSelectedForExport,
        totalSitesCount: totalSitesCountTobeExported,
      })
        .then((response: any) => {
          let resData: any = [...response];
          const newOptArr: any[] = [];

          console.log("response data:", resData);
          console.log(new Date());

          var ind = -1;
          var siteId = "";

          resData.forEach((item: any) => {
            //const existInResult = ind?.findIndex(i=>i === item.global_site_id);
            if (siteId !== item.global_site_id) {
              ind = ind + 1;
              siteId = item.global_site_id;
              newOptArr.push({
                ["Global site ID "]: item.global_site_id,
                [item.field_name]: item.field_value,
              });
            } else {
              newOptArr[ind] = {
                ...newOptArr[ind],
                ...{
                  [item.field_name]: item.field_value,
                },
              };
            }
          });

          // resData.forEach((item: any) => {
          //   const existInResultIndex = newOptArr?.findIndex(
          //     (i) => i["Client ID"] === item.global_site_id
          //   );
          //   if (existInResultIndex === -1) {
          //     newOptArr.push({
          //       ["Client ID"]: item.global_site_id,
          //       [item.field_name]: item.field_value,
          //     });
          //   } else {
          //     newOptArr[existInResultIndex] = {
          //       ...newOptArr[existInResultIndex],
          //       ...{
          //         [item.field_name]: item.field_value,
          //       },
          //     };
          //   }
          // });

          console.log("Traversed data:", newOptArr);
          console.log(new Date());

          const columnHeaders = Object.keys(newOptArr[0]);
          const deleteHeaders: any[] = [];

          new Promise((resolve: any) => {
            columnHeaders.forEach((header: any, index: number, array: any) => {
              let objArr: any[] = [];
              new Promise((resolve1: any) => {
                objArr = newOptArr.map(
                  (item: any, index1: number, array1: any) => {
                    if (index1 === array1.length - 1) resolve1();
                    return item[header];
                  }
                );
              }).then(() => {
                if (objArr.every((val: any) => val === "No")) {
                  deleteHeaders.push(header);
                }
              });
              if (index === array.length - 1) {
                resolve();
              }
            });
          }).then(() => {
            new Promise((resolve2: any) => {
              newOptArr.forEach((res: any, index2: number, array2: any) => {
                deleteHeaders?.map((dataHeader) => delete res[dataHeader]);
                if (index2 === array2.length - 1) resolve2();
              });
            }).then(() => {
              setRBLAExportData(newOptArr);
              setIsDataLoading(false);
              setIsExportDataLoading(false);
            });
          });
        })
        .catch((err) => {
          setIsDataLoading(false);
          setIsExportDataLoading(false);
        });
    } else {
      let totalRows = requestPayload?.pageSize;
      let currentPage = requestPayload?.page;
      if (checked === AppConst.EXPORT_DATA_TYPE.ALL) {
        totalRows = sitesListDataCount;
        currentPage = 0;
      }
      if (totalRows > 10000) {
        const pagesLength = totalRows / 10000;
        let linksArray: number[] = [];
        for (let i = 0; i < pagesLength; i++) {
          linksArray.push(i);
        }
        SitesService.getAllSitesList(linksArray, {
          ...requestPayload,
        }).then((response) => {
          setExportData(response);
        });
      } else {
        SitesService.getSitesList({
          ...requestPayload,
          pageSize: totalRows,
          page: currentPage,
        }).then((response) =>
          setExportData(response.data?.result?.siteDocuments)
        );
      }
    }
  };

  const filterRows = () => {
    let filterQuery = "";

    if (initialStatusFilterQuery) {
      filterQuery = `search.in(Status,'${initialStatusFilterQuery}', ',')`;
    } 
    else if (selected === 0 && selectedStatusItems?.length && roleName === RoleConst.SALES_OPS_SME){
      filterQuery = `search.in(Status,'${selectedStatusItems?.join(",")}', ',') and search.in(SiteType,'${SiteTypeNameConst.SHELL_IN_NETWORK_RETAIL_SITE},${SiteTypeNameConst.SHELL_COMMERCIAL_SITE}', ',')`;
    }
    else if (selected === 0 && selectedStatusItems?.length === 0 && roleName === RoleConst.SALES_OPS_SME){
      filterQuery = `search.in(SiteType,'${SiteTypeNameConst.SHELL_IN_NETWORK_RETAIL_SITE},${SiteTypeNameConst.SHELL_COMMERCIAL_SITE}', ',')`;
    }
    else if (selected === 3 && selectedStatusItems?.length && roleName === RoleConst.SALES_OPS_SME){
      filterQuery = `search.in(Status,'${selectedStatusItems?.join(",")}', ',') and search.in(SiteType,'${SiteTypeNameConst.SHELL_COMPETITOR_SITE}', ',')`;
    }
    else if (selected === 3 && selectedStatusItems?.length === 0 && roleName === RoleConst.SALES_OPS_SME){
      filterQuery = `search.in(SiteType,'${SiteTypeNameConst.SHELL_COMPETITOR_SITE}', ',')`;
    }
    else if (selectedStatusItems?.length) {
      filterQuery = `search.in(Status,'${selectedStatusItems?.join(
        ","
      )}', ',')`;
    } 

    if (filterQuery && selectedSiteTypeItems?.length) {
      filterQuery = `${filterQuery} and search.in(SiteType,'${selectedSiteTypeItems?.join(
        ","
      )}', ',')`;
    }
    else if (selected === 1 && selectedSiteTypeItems?.length && roleName === RoleConst.SALES_OPS_SME){
      filterQuery = `${filterQuery} and search.in(SiteType,'${SiteTypeNameConst.SHELL_COMPETITOR_SITE}', ',')`;
    }
    else if (selected === 1 && selectedSiteTypeItems?.length === 0 && roleName === RoleConst.SALES_OPS_SME){
      filterQuery = `${filterQuery} and search.in(SiteType,'${SiteTypeNameConst.SHELL_IN_NETWORK_RETAIL_SITE},${SiteTypeNameConst.SHELL_COMMERCIAL_SITE}', ',')`;
    }
    else if (selected === 2 && selectedSiteTypeItems?.length && roleName === RoleConst.SALES_OPS_SME){
      filterQuery = `${filterQuery} and search.in(SiteType,'${selectedSiteTypeItems?.join(",")}', ',') and search.in(SiteType,'${SiteTypeNameConst.SHELL_COMPETITOR_SITE}', ',')`;
    }
    else if (selected === 2 && selectedSiteTypeItems?.length === 0 && roleName === RoleConst.SALES_OPS_SME){
      filterQuery = `${filterQuery} and search.in(SiteType,'${SiteTypeNameConst.SHELL_IN_NETWORK_RETAIL_SITE},${SiteTypeNameConst.SHELL_COMMERCIAL_SITE}', ',')`;
    }
    else if (selectedSiteTypeItems?.length) {
      filterQuery = `search.in(SiteType,'${selectedSiteTypeItems?.join(
        ","
      )}', ',')`;
    }

    if (filterQuery && selectedCategoryItems?.length) {
      filterQuery = `${filterQuery} and search.in(SiteCategory,'${selectedCategoryItems?.join(
        ","
      )}', ',')`;
    } else if (selectedCategoryItems?.length) {
      filterQuery = `search.in(SiteCategory,'${selectedCategoryItems?.join(
        ","
      )}', ',')`;
    }

    if (filterQuery && selectedOpearatingPlatformItems?.length) {
      filterQuery = `${filterQuery} and search.in(OperatingPlatform,'${selectedOpearatingPlatformItems?.join(
        ","
      )}', ',')`;
    } else if (selectedOpearatingPlatformItems?.length) {
      filterQuery = `search.in(OperatingPlatform,'${selectedOpearatingPlatformItems?.join(
        ","
      )}', ',')`;
    }

    if (selectedGlobalCountry === AppConst.ALL_SITES) {
      if (filterQuery && selectedCountryItems?.length) {
        filterQuery = `${filterQuery} and search.in(Country,'${selectedCountryItems?.join(
          ","
        )}', ',')`;
      } else if (selectedCountryItems?.length) {
        filterQuery = `search.in(Country,'${selectedCountryItems?.join(
          ","
        )}', ',')`;
      }
    } else if (filterQuery) {
      filterQuery = `${filterQuery} and search.in(Country,'${selectedGlobalCountry}', ',')`;
    } else {
      filterQuery = `search.in(Country,'${selectedGlobalCountry}', ',')`;
    }

    const filterArr = [
      ...selectedStatusItems,
      ...selectedSiteTypeItems,
      ...selectedOpearatingPlatformItems,
      ...selectedCategoryItems,
      ...selectedCountryItems,
    ];

    setFilterBadgeText(filterArr.length?.toString());

    setPageState({ ...initialPageState, take: requestPayload?.pageSize });
    setRequestPayload({ ...requestPayload, page: 0, filter: filterQuery });
  };

  const clearFilter = () => {
    setSelectedStatusItems([]);
    setSelectedSiteTypeItems([]);
    setSelectedCountryItems([]);
    setSelectedCategoryItems([]);
    setSelectedOpearatingPlatformItems([]);
    setFilterBadgeText("0");

    if (selected === 0) {
      setInitialStatusFilterQuery(``);

      if (selectedGlobalCountry === AppConst.ALL_SITES) {
        setRequestPayload({
          ...requestPayload,
          filter: ``,
        });
      } else {
        setRequestPayload({
          ...requestPayload,
          filter: `search.in(Country,'${selectedGlobalCountry}', ',')`,
        });
      }
    } else if (selected === 1) {
      setInitialStatusFilterQuery(SiteConst.SITE_STATUS_LIST.ACTIVE);
      if (selectedGlobalCountry === AppConst.ALL_SITES) {
        setRequestPayload({
          ...requestPayload,
          filter: `search.in(Status,'${SiteConst.SITE_STATUS_LIST.ACTIVE}', ',')`,
        });
      } else {
        setRequestPayload({
          ...requestPayload,
          filter: `search.in(Status,'${SiteConst.SITE_STATUS_LIST.ACTIVE}', ',') and search.in(Country,'${selectedGlobalCountry}', ',')`,
        });
      }
    } else if (selected === 2) {
      setInitialStatusFilterQuery(
        SiteConst.SITE_STATUS_LIST.CLOSED_TEMPORARILY
      );
      if (selectedGlobalCountry === AppConst.ALL_SITES) {
        setRequestPayload({
          ...requestPayload,
          filter: `search.in(Status,'${SiteConst.SITE_STATUS_LIST.CLOSED_TEMPORARILY}', ',')`,
        });
      } else {
        setRequestPayload({
          ...requestPayload,
          filter: `search.in(Status,'${SiteConst.SITE_STATUS_LIST.CLOSED_TEMPORARILY}', ',') and search.in(Country,'${selectedGlobalCountry}', ',')`,
        });
      }
    }
    else if (selected === 3) {
      setInitialStatusFilterQuery("");
       if (userDetails?.roleDetail?.userRoleName === RoleConst.SALES_OPS_SME && selectedGlobalCountry === AppConst.ALL_SITES) {
         setRequestPayload({
           ...defaultPayload,
          filter: `search.in(SiteType,'${SiteTypeNameConst.SHELL_COMPETITOR_SITE}', ',')`,
          facetFilter: `search.in(SiteType,'${SiteTypeNameConst.SHELL_COMPETITOR_SITE}', ',')`
         });
       } else {
         setRequestPayload({
           ...defaultPayload,
         filter: `search.in(SiteType,'${SiteTypeNameConst.SHELL_COMPETITOR_SITE}', ',') and search.in(Country,'${selectedGlobalCountry}', ',')`,
         facetFilter: `search.in(SiteType,'${SiteTypeNameConst.SHELL_COMPETITOR_SITE}', ',') and search.in(Country,'${selectedGlobalCountry}', ',')`
         });
       }
     }
  };

  const handleStatusFilterChange = (event: MultiSelectChangeEvent) => {
    setSelectedStatusItems(event.value);
  };

  const handleCountryFilterChange = (event: MultiSelectChangeEvent) => {
    setSelectedCountryItems(event.value);
  };

  const handleCategoryFilterChange = (event: MultiSelectChangeEvent) => {
    setSelectedCategoryItems(event.value);
  };

  const handleOperatingPlatformFilterChange = (
    event: MultiSelectChangeEvent
  ) => {
    setSelectedOpearatingPlatformItems(event.value);
  };

  const handleSiteTypeFilterChange = (event: MultiSelectChangeEvent) => {
    setSelectedSiteTypeItems(event.value);
  };

  const statusFilterChange = (event: MultiSelectFilterChangeEvent) => {
    const filter = event.filter;
    const allData = statusList?.slice();
    const newData =
      filter?.value?.length >= 3 ? filterBy(allData, filter) : allData;

    setStatusItems(newData);
  };

  const countryFilterChange = (event: MultiSelectFilterChangeEvent) => {
    const filter = event.filter;
    const allData = countryList?.slice();
    const newData =
      filter?.value?.length >= 3 ? filterBy(allData, filter) : allData;

    setCountryItems(newData);
  };

  const categoryFilterChange = (event: MultiSelectFilterChangeEvent) => {
    const filter = event.filter;
    const allData = categoryList?.slice();
    const newData =
      filter?.value?.length >= 3 ? filterBy(allData, filter) : allData;

    setCategoryItems(newData);
  };

  const operatingPlatformFilterChange = (
    event: MultiSelectFilterChangeEvent
  ) => {
    const filter = event.filter;
    const allData = operatingPlatformList?.slice();
    const newData =
      filter?.value?.length >= 3 ? filterBy(allData, filter) : allData;

    setOpearatingPlatformItems(newData);
  };

  const siteTypeFilterChange = (event: MultiSelectFilterChangeEvent) => {
    const filter = event.filter;
    const allData = siteTypeList?.slice();
    const newData =
      filter?.value?.length >= 3 ? filterBy(allData, filter) : allData;
    setSiteTypeItems(newData);
  };

  const handleChange = (e: any) => {
    setChecked(e.value);
  };

  const exportContent = () => {
    const exportTypes: { label: string; value: string }[] = [
      {
        label: Locale.formatString(
          Locale.landing_page?.sites_list.export.export_current,
          pageState?.take || 25
        ),
        value: AppConst.EXPORT_DATA_TYPE.CURRENT_PAGE,
      },
      {
        label: Locale.formatString(
          Locale.landing_page?.sites_list.export.export_all,
          sitesListDataCount || 0
        ),
        value: AppConst.EXPORT_DATA_TYPE.ALL,
      },
    ];

    const roleName = userDetails?.roleDetail?.userRoleName;
    if (
      roleName === RoleConst.RBLA_LOCAL_SME ||
      roleName === RoleConst.RBLA_GLOBAL_SME
    ) {
      return (
        <p className="export-warming-msg">
          {Locale.landing_page?.sites_list.export.rbla_export_text}
        </p>
      );
    }

    return (
      <>
        <fieldset className="mdm--grid-filter">
          <legend className="mdm--grid-filter-label">
            {Locale.landing_page?.sites_list.export.export_label}
          </legend>
          <RadioGroup
            data={exportTypes}
            value={checked}
            onChange={handleChange}
          />
        </fieldset>
      </>
    );
  };

  const filterContent = () => {
    return (
      <>
        {(selected === 0 || selected === 3) && (
          <fieldset className="mdm--grid-filter">
            <legend className="mdm--grid-filter-label">
              {Locale.landing_page?.sites_list.table_headers.status_label}
            </legend>
            <MultiSelect
              autoClose={false}
              className="mdm--multiselect-dropdown"
              placeholder=" Select status"
              style={{ width: "18vw", height: "4vh" }}
              data={statusItems}
              value={selectedStatusItems}
              onChange={handleStatusFilterChange}
              filterable={true}
              onFilterChange={statusFilterChange}
              tags={
                selectedStatusItems?.length > 0
                  ? [
                    {
                      text: `${selectedStatusItems?.length} ${Locale.landing_page?.sites_list.filter_placeholder.status_selected}`,
                      data: [...selectedStatusItems],
                    },
                  ]
                  : []
              }
            />
          </fieldset>
        )}

        {isShellRole && selected !== 3 && (
          <>
            <fieldset className="mdm--grid-filter">
              <legend className="mdm--grid-filter-label">Site Type</legend>
              <MultiSelect
                autoClose={false}
                className="mdm--multiselect-dropdown"
                placeholder={" Select site types"}
                style={{ width: "18vw", height: "4vh" }}
                data={siteTypeItems}
                value={selectedSiteTypeItems}
                filterable={true}
                onFilterChange={siteTypeFilterChange}
                onChange={handleSiteTypeFilterChange}
                tags={
                  selectedSiteTypeItems?.length > 0
                    ? [
                      {
                        text: `${selectedSiteTypeItems?.length} ${Locale.landing_page?.sites_list.filter_placeholder.site_type_selected}`,
                        data: [...selectedSiteTypeItems],
                      },
                    ]
                    : []
                }
              />
            </fieldset>
          </>
        )}

        {isShellRole && (
          <fieldset className="mdm--grid-filter">
            <legend className="mdm--grid-filter-label">
              {
                Locale.landing_page?.sites_list.table_headers
                  .operating_platform_label
              }
            </legend>
            <MultiSelect
              autoClose={false}
              className="mdm--multiselect-dropdown"
              placeholder=" Select operating platform"
              style={{ width: "18vw", height: "4vh" }}
              data={opearatingPlatformItems}
              value={selectedOpearatingPlatformItems}
              onChange={handleOperatingPlatformFilterChange}
              filterable={true}
              onFilterChange={operatingPlatformFilterChange}
              tags={
                selectedOpearatingPlatformItems?.length > 0
                  ? [
                    {
                      text: `${selectedOpearatingPlatformItems?.length} ${Locale.landing_page?.sites_list.filter_placeholder.operating_platform_selected}`,
                      data: [...selectedOpearatingPlatformItems],
                    },
                  ]
                  : []
              }
            />
          </fieldset>
        )}

        {isShellRole && (
          <fieldset className="mdm--grid-filter">
            <legend className="mdm--grid-filter-label">
              {
                Locale.landing_page?.sites_list.table_headers
                  .site_category_label
              }
            </legend>
            <MultiSelect
              autoClose={false}
              className="mdm--multiselect-dropdown"
              placeholder=" Select categories"
              style={{ width: "18vw", height: "4vh" }}
              data={categoryItems}
              value={selectedCategoryItems}
              onChange={handleCategoryFilterChange}
              filterable={true}
              onFilterChange={categoryFilterChange}
              tags={
                selectedCategoryItems?.length > 0
                  ? [
                    {
                      text: `${selectedCategoryItems?.length} ${Locale.landing_page?.sites_list.filter_placeholder.category_selected}`,
                      data: [...selectedCategoryItems],
                    },
                  ]
                  : []
              }
            />
          </fieldset>
        )}
        {showCountryFilter && selectedGlobalCountry === AppConst.ALL_SITES && (
          <fieldset className="mdm--grid-filter">
            <legend className="mdm--grid-filter-label">
              {Locale.landing_page?.sites_list.table_headers.country_label}
            </legend>
            <MultiSelect
              autoClose={false}
              className="mdm--multiselect-dropdown"
              placeholder=" Select countries"
              style={{ width: "18vw", height: "4vh" }}
              data={countryItems}
              value={selectedCountryItems}
              onChange={handleCountryFilterChange}
              filterable={true}
              onFilterChange={countryFilterChange}
              tags={
                selectedCountryItems?.length > 0
                  ? [
                    {
                      text: `${selectedCountryItems?.length} ${Locale.landing_page?.sites_list.filter_placeholder.country_selected}`,
                      data: [...selectedCountryItems],
                    },
                  ]
                  : []
              }
            />
          </fieldset>
        )}
      </>
    );
  };

  const sendSiteClickedEventAdobeAnalytics = (clickedGlobalSiteId: string) => {
    window.adobeDataLayer = window.adobeDataLayer || [];
    window.adobeDataLayer.push({
      event: "globalIDClicked",
      globalIDSelected: clickedGlobalSiteId, //'1250983'
      globalIDClickArea: pathname.slice(1), //name of area where tile is clicked for example Home Screen, Reports screen,
      metric: "engage",
      page: {
        pageName: "Site Data Web | " + pathname.slice(1),
        language: adobeAnalyticsConst.language,
        platform: adobeAnalyticsConst.platform,
        countryCode: selectedGlobalCountry,
        cvp: "Site Data Web",
        siteSection: pathname.slice(1),
      },
      user: {
        userId: userDetails.userLoginId,
        loginStatus: "active",
        userType: userDetails.userType,
        customerRole: userDetails?.roleDetail?.userRoleName,
      },
    });
  };

  const onRedirectLinkClick = (siteId: string) => {
    sendSiteClickedEventAdobeAnalytics(siteId);
  };

  const renderGridCell = (props: any) => {
    if (props.field === SiteConst.SITE_LIST_TABLE_HEADER_KEY.GLOBAL_SITE_ID) {
      return (
        <td>
          <Link
            to={`${APP_ROUTES.SITES_PATH}/${props.dataItem[props.field]}`}
            onClick={() => onRedirectLinkClick(props.dataItem[props.field])}
          >
            {props.dataItem[props.field]}
          </Link>
        </td>
      );
    } else if (props.field === SiteConst.SITE_LIST_TABLE_HEADER_KEY.STATUS) {
      return (
        <td
          className={`mdm-grid-custom-td mdm--action-item-${props.dataItem[
            props.field
          ]
            ?.toLowerCase()
            .replace(/ /g, "_")
            .replace(/\//g, "_")}`}
        >
          <span
            className="mdm-grid-td-text"
            title={
              SiteConst.SITE_STATUS_LIST_SHORTHAND[props.dataItem[props.field]]
            }
          >
            {
              SiteConst.SITE_STATUS_LIST_SHORTHAND[
              props.dataItem[props.field]
                ?.toUpperCase()
                .replace(/ /g, "_")
                .replace(/\//g, "_")
                .replace("-", "_")
              ]
            }
          </span>
          {props.dataItem[props.field] === SiteConst.SITE_STATUS_LIST.ACTIVE ? (
            // <span className="k-icon k-i-check-circle" />
            <span className={"k-icon k-i-check-circle-icon-circle"} />
          ) : props.dataItem[props.field] ===
            SiteConst.SITE_STATUS_LIST.LANDBANK ? (
            <Tooltip anchorElement="target" position="top">
              <span
                title={props.dataItem[props.field]}
                className={"k-icon k-i-information-red-icon-circle"}
              />
            </Tooltip>
          ) : props.dataItem[props.field] ===
            SiteConst.SITE_STATUS_LIST.INVALID ? (
            <span className="k-icon k-i-warning-icon-circle" />
          ) : props.dataItem[props.field] ===
            SiteConst.SITE_STATUS_LIST.NON_RETAIL_SHELL_LOCATION ||
            props.dataItem[props.field] ===
            SiteConst.SITE_STATUS_LIST.COMPLETED_NOT_OPENED ||
            props.dataItem[props.field] ===
            SiteConst.SITE_STATUS_LIST.CLOSED_PERMANENTLY ? (
            <Tooltip anchorElement="target" position="top">
              <span
                title={props.dataItem[props.field]}
                className={"k-icon k-i-information-red-icon-circle"}
              />
            </Tooltip>
          ) : props.dataItem[props.field] ===
            SiteConst.SITE_STATUS_LIST.WORK_IN_PROCESS ||
            props.dataItem[props.field] ===
            SiteConst.SITE_STATUS_LIST.CLOSED_TEMPORARILY ||
            props.dataItem[props.field] ===
            SiteConst.SITE_STATUS_LIST.RECYCLE_BIN ||
            props.dataItem[props.field] ===
            SiteConst.SITE_STATUS_LIST.NOT_SPECIFIED ? (
            <Tooltip anchorElement="target" position="top">
              <span
                title={props.dataItem[props.field]}
                className={"k-icon k-i-information-icon-circle"}
              />
            </Tooltip>
          ) : (
            <span className="k-icon k-i-warning-icon-circle" />
          )}
        </td>
      );
    }

    return (
      <td
        title={props.dataItem[props.field]}
        className={props.dataItem[props.field] ? "" : "mdm-grid-cell-empty"}
      >
        {props.dataItem[props.field] || "--"}
      </td>
    );
  };

  return (
    <div className="mdm--site-list-container">
      <h5 className="mdm--site-list-container-title mdm--landing-page-item-title">
        {Locale.landing_page?.sites_list.sites_title}
      </h5>
      <div className="mdm--landing-page-item-body mdm--sites-list-body mdm--row">
        {isShellRole ? (
          <TabStrip
            className="mdm--site-list-tabs"
            selected={selected}
            onSelect={handleSelect}
          >
            <TabStripTab
              key="shellsite"
              title={
                <>
                  <span
                    className="mdm--lp-sites-list-tab-title"
                    data-testid="shell_sites_title"
                  >
                    {userDetails?.roleDetail?.userRoleName === RoleConst.SALES_OPS_SME ? Locale.landing_page?.sites_list.tab_titles.all_shell_sites : Locale.landing_page?.sites_list.tab_titles.all_sites}
                  </span>
                  <span className="mdm--dot">{userDetails?.roleDetail?.userRoleName === RoleConst.SALES_OPS_SME || selected === 3 ? salesofsmeAllShellSitesListCount : allShellSitesListCount}</span>
                </>
              }
              disabled={isDataLoading && selected !== 0}
            >
              <AppDataGrid
                data={sitesListData || []}
                tableHeaders={tableHeaders}
                initialSort={initialSort}
                renderGridCell={renderGridCell}
                searchPlaceHolderText={
                  Locale.landing_page?.sites_list.table_placeholder_text
                }
                onSearch={onSearch}
                onFilter={filterRows}
                onExport={excelExport}
                onClear={clearFilter}
                filterContent={filterContent}
                exportContent={exportContent}
                totalCount={sitesListDataCount}
                // totalCount={allShellSitesListCount}
                pageChange={pageChange}
                pageSort={pageSort}
                pageState={pageState}
                onRefreshClick={onRefreshClick}
                pageable={pageable}
                isLoading={isDataLoading || isLoading}
                // maxHeight={"15rem"}
                maxHeight={TABLE_MAX_HEIGHT}
                searchValue={predefinedSearchTextValue}
                showFilterBadge={
                  filterBadgeText && +filterBadgeText !== 0 ? true : false
                }
                filterBadgeText={filterBadgeText}
              />
              {(isLoading || isDataLoading) && <Loader />}
            </TabStripTab>

            <TabStripTab
              title={
                <>
                  <span className="mdm--lp-sites-list-tab-title">
                    {userDetails?.roleDetail?.userRoleName === RoleConst.SALES_OPS_SME ? Locale.landing_page?.sites_list.tab_titles.active_shell_sites : Locale.landing_page?.sites_list.tab_titles.active_sites}
                  </span>
                  <span className="mdm--dot">{userDetails?.roleDetail?.userRoleName === RoleConst.SALES_OPS_SME || selected === 3 ? saleofsmeActiveSitesListCount : activeSitesListCount}</span>
                </>
              }
              disabled={isDataLoading && selected !== 1}
            >
              <AppDataGrid
                data={sitesListData || []}
                tableHeaders={tableHeaders}
                initialSort={initialSort}
                renderGridCell={renderGridCell}
                searchPlaceHolderText={
                  Locale.landing_page?.sites_list.table_placeholder_text
                }
                onSearch={onSearch}
                onFilter={filterRows}
                onExport={excelExport}
                exportContent={exportContent}
                onClear={clearFilter}
                filterContent={filterContent}
                totalCount={sitesListDataCount}
                // totalCount={activeSitesListCount}
                pageSort={pageSort}
                pageState={pageState}
                pageChange={pageChange}
                onRefreshClick={onRefreshClick}
                pageable={pageable}
                isLoading={isDataLoading || isLoading}
                // maxHeight={"15rem"}
                maxHeight={TABLE_MAX_HEIGHT}
                searchValue={predefinedSearchTextValue || ""}
                showFilterBadge={
                  filterBadgeText && +filterBadgeText !== 0 ? true : false
                }
                filterBadgeText={filterBadgeText}
              />
              {(isLoading || isDataLoading) && <Loader />}
            </TabStripTab>
            <TabStripTab
              title={
                <>
                  <span className="mdm--lp-sites-list-tab-title">
                    {userDetails?.roleDetail?.userRoleName === RoleConst.SALES_OPS_SME ? Locale.landing_page?.sites_list.tab_titles.c_t_shell_sites : Locale.landing_page?.sites_list.tab_titles.c_t_sites}{" "}
                  </span>
                  <span className="mdm--dot">
                    {userDetails?.roleDetail?.userRoleName === RoleConst.SALES_OPS_SME || selected === 3 ? salesofsmeClosedTemporarilySitesListCount : closedTemporarilySitesListCount}
                  </span>
                </>
              }
              disabled={isDataLoading && selected !== 2}
            >
              <AppDataGrid
                data={sitesListData || []}
                tableHeaders={tableHeaders}
                initialSort={initialSort}
                renderGridCell={renderGridCell}
                searchPlaceHolderText={
                  Locale.landing_page?.sites_list.table_placeholder_text
                }
                onSearch={onSearch}
                onFilter={filterRows}
                onExport={excelExport}
                exportContent={exportContent}
                onClear={clearFilter}
                filterContent={filterContent}
                totalCount={sitesListDataCount}
                // totalCount={closedTemporarilySitesListCount}
                pageSort={pageSort}
                pageState={pageState}
                pageChange={pageChange}
                onRefreshClick={onRefreshClick}
                pageable={pageable}
                isLoading={isDataLoading || isLoading}
                // maxHeight={"15rem"}
                maxHeight={TABLE_MAX_HEIGHT}
                searchValue={predefinedSearchTextValue || ""}
                showFilterBadge={
                  filterBadgeText && +filterBadgeText !== 0 ? true : false
                }
                filterBadgeText={filterBadgeText}
              />
              {(isLoading || isDataLoading) && <Loader />}
            </TabStripTab>
            {userDetails?.roleDetail?.userRoleName === RoleConst.SALES_OPS_SME && (
              <TabStripTab
                title={
                  <>
                    <span className="mdm--lp-sites-list-tab-title">
                      {Locale.landing_page?.sites_list.tab_titles.competitor_sites}{" "}
                    </span>
                    <span className="mdm--dot">
                      {competitorSitesListCount}
                    </span>
                  </>
                }
                disabled={isDataLoading && selected !== 3}
              >
                <AppDataGrid
                  data={sitesListData || []}
                  tableHeaders={tableHeaders}
                  initialSort={initialSort}
                  renderGridCell={renderGridCell}
                  searchPlaceHolderText={
                    Locale.landing_page?.sites_list.table_placeholder_text
                  }
                  onSearch={onSearch}
                  onFilter={filterRows}
                  onExport={excelExport}
                  exportContent={exportContent}
                  onClear={clearFilter}
                  filterContent={filterContent}
                  totalCount={sitesListDataCount}
                  // totalCount={competitorSitesListCount}
                  pageSort={pageSort}
                  pageState={pageState}
                  pageChange={pageChange}
                  onRefreshClick={onRefreshClick}
                  pageable={pageable}
                  isLoading={isDataLoading || isLoading}
                  // maxHeight={"15rem"}
                  maxHeight={TABLE_MAX_HEIGHT}
                  searchValue={predefinedSearchTextValue || ""}
                  showFilterBadge={
                    filterBadgeText && +filterBadgeText !== 0 ? true : false
                  }
                  filterBadgeText={filterBadgeText}
                />
                {(isLoading || isDataLoading) && <Loader />}
              </TabStripTab>
            )}
          </TabStrip>
        ) : (
          <TabStrip
            className="mdm--site-list-tabs mdm-non-shell-search-sites"
            selected={selected}
            onSelect={handleSelect}
          >
            <TabStripTab
              key="nonshellsite"
              title={
                <>
                  <span
                    className="mdm--lp-sites-list-tab-title"
                    data-testid="shell_sites_title"
                  >
                    {nonShellRoleTableTitle}
                  </span>
                  <span className="mdm--dot">{allShellSitesListCount}</span>
                </>
              }
              disabled={isDataLoading && selected !== 0}
            >
              <AppDataGrid
                data={sitesListData || []}
                tableHeaders={tableHeaders}
                initialSort={initialSort}
                renderGridCell={renderGridCell}
                searchPlaceHolderText={
                  Locale.landing_page?.sites_list.table_placeholder_text
                }
                onSearch={onSearch}
                onFilter={filterRows}
                onExport={excelExport}
                onClear={clearFilter}
                filterContent={filterContent}
                exportContent={exportContent}
                totalCount={sitesListDataCount}
                pageChange={pageChange}
                pageSort={pageSort}
                pageState={pageState}
                onRefreshClick={onRefreshClick}
                pageable={pageable}
                isLoading={isDataLoading || isLoading}
                // maxHeight={"15rem"}
                maxHeight={TABLE_MAX_HEIGHT}
                searchValue={predefinedSearchTextValue || ""}
                showFilterBadge={
                  filterBadgeText && +filterBadgeText !== 0 ? true : false
                }
                filterBadgeText={filterBadgeText}
              />
              {(isLoading || isDataLoading) && <Loader />}
            </TabStripTab>
          </TabStrip>
        )}
        {/* <Button
          className="buttons-container-button"
          id={"expandBtn"}
          size="small"
          icon={isExpanded ? "collapse" : "expand"}
          onClick={onExpand}
        /> */}
      </div>
    </div>
  );
};
