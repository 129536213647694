import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { useSelector } from "react-redux";

import {
  CompositeFilterDescriptor,
  filterBy,
  FilterDescriptor,
} from "@progress/kendo-data-query";
import {
  DropDownListFilterChangeEvent,
  DropDownListChangeEvent,
} from "@progress/kendo-react-dropdowns";
import { formatDate } from "@progress/kendo-intl";
import { InputChangeEvent } from "@progress/kendo-react-inputs";
import { Skeleton } from "@progress/kendo-react-indicators";
import { DateInputChangeEvent } from "@progress/kendo-react-dateinputs";

import { format } from "date-fns";

import {
  AppConst,
  ContryConst,
  CrSiteShopTypeConst,
  EditFieldTypeConst,
  OperatingPlatformConst,
  OrgAffiliationConst,
  OrgAffiliationTypeConst,
  RoleConst,
  SiteStatusConst,
  SiteTypeConst,
} from "./../../../../../../constants";
import { Locale } from "../../../../../../utils/localization";
import { DetailsViewComponent, Loader } from "../../../../../components";
import { ValidateEditSite } from "./Edit-Site-Details-Validations";

import "./BasicSiteDetails.scss";

const fpsIndicatorCodes: { id: string; value: string }[] = [
  { id: "Y", value: "Y" },
  { id: "N", value: "N" },
];

interface IProps {
  isBasicSiteDetailsLoading: boolean;
  basicSiteDetails: any;
  isEditing: boolean;
  isCreate?: boolean;
  isMasterDataLoading: boolean;
  siteStatusCodes: any;
  siteTypeCodes: any;
  isCountyCodesLoading: boolean;
  isTerritoryCodesLoading: boolean;
  countryCodes: any;
  countyCodes: any;
  territoryCodes: any;
  siteCategoryCodes: any;
  operatingPlatformCodes: any;
  crSiteShopTypeCodes: any;
  crFormatTypeCodes: any;
  orgAffiliationCodes: any;
  isOrgAffiliationCodesLoading: boolean;
  orgAffiliationTypeCodes: any;
  neraRiskCodes: any;
  refetchTerritoryCodes: any;
  refetchCountyCodes: any;
  tenureTypeCodes: any;
  onSectionValueSet: any;
  setSelectedCategory: any;
  setSelectedStatus: any;
  onSiteTypeSelected: any;
  classifiedSiteType: string;
  onClassifiedSiteTypeChange: any;
  setOperatingPlatform: any;
  onLocationChange?: any;
  countryDiallingCd?: string;
  usaCompanyCodes: { id: string; value: string }[];
  isUSACompanyCodesLoading: boolean;
}

export const BasicSiteDetails = React.memo(
  forwardRef((props: IProps, ref) => {
    const {
      basicSiteDetails,
      isBasicSiteDetailsLoading,
      isEditing,
      isCreate,
      isMasterDataLoading,
      siteStatusCodes,
      siteTypeCodes,
      siteCategoryCodes,
      operatingPlatformCodes,
      crSiteShopTypeCodes,
      crFormatTypeCodes,
      orgAffiliationCodes,
      isOrgAffiliationCodesLoading,
      orgAffiliationTypeCodes,
      neraRiskCodes,
      countryCodes,
      countyCodes,
      territoryCodes,
      isCountyCodesLoading,
      isTerritoryCodesLoading,
      refetchTerritoryCodes,
      refetchCountyCodes,
      tenureTypeCodes,
      onSectionValueSet,
      setSelectedCategory,
      setSelectedStatus,
      onSiteTypeSelected,
      classifiedSiteType,
      onClassifiedSiteTypeChange,
      setOperatingPlatform,
      onLocationChange,
      countryDiallingCd,
      usaCompanyCodes,
      isUSACompanyCodesLoading,
    } = props;

    const userDetails = useSelector((state: any) => state?.auth?.user);
    const roleName = userDetails?.roleDetail?.userRoleName;

    const [statusTypeList, setStatusTypeList] = useState<any[]>([]);

    const [showLoader, setShowLoader] = useState(false);

    const [siteName, setSiteName] = useState("");
    const [siteStatusSelectedValue, setSiteStatusSelectedValue] =
      useState<any>();
    const [siteTypeSelectedValue, setSiteTypeSelectedValue] = useState<any>();
    const [orgAffiliationSelectedValue, setOrgAffiliationSelectedValue] =
      useState<any>();
    const [
      orgAffiliationTypeSelectedValue,
      setOrgAffiliationTypeSelectedValue,
    ] = useState<any>();
    const [crSiteShopTypeSelectedValue, setCrSiteShopTypeSelectedValue] =
      useState<any>();
    const [crFormatTypeSelectedValue, setCrFormatTypeSelectedValue] =
      useState<any>();
    const [siteCategorySelectedValue, setSiteCategorySelectedValue] =
      useState<any>();
    const [siteTenureTypeSelectedValue, setSiteTenureTypeSelectedValue] =
      useState<any>();
    const [neraRiskNameSelectedValue, setNeraRiskNameSelectedValue] =
      useState<any>();

    const [operatingPlatformSelectedValue, setOperatingPlatformSelectedValue] =
      useState<any>();
    const [siteOpeningDate, setSiteOpeningDate] = useState<any>();
    const [siteClosingDate, setSiteClosingDate] = useState<any>();
    const [siteShortName, setSiteShortName] = useState("");
    const [latitude, setLatitude] = useState("");
    const [longitude, setLongitude] = useState("");
    const [salesOrganisationName, setSalesOrganisationName] = useState("");
    const [fpsIndicatorSelectedValue, setFPSIndicatorSelectedValue] =
      useState<any>();
    const [salesOfficeCode, setSalesOfficeCode] = useState("");
    const [neraCertificationDate, setNERACertificationDate] = useState<any>("");
    const [countrySelectedValue, setCountrySelectedValue] = useState<any>();
    const [countySelectedValue, setCountySelectedValue] = useState<any>();
    const [territorySelectedValue, setTerritorySelectedValue] = useState<any>();
    const [postalCode, setPostalCode] = useState("");
    const [countryDiallingCode, setCountryDiallingCode] = useState<any>("");
    const [streetAddress, setStreetAddress] = useState("");
    const [cityName, setCityName] = useState<any>();
    const [telephoneNumber, setTelephoneNumber] = useState("");
    const [countryList, setCountryList] = useState<any>();
    const [nonGSAPCountryList, setNonGSAPCountryList] = useState<any>();
    const [localUserCountryList, setLocalUserCountryList] = useState<any>();
    const [countyList, setCountyList] = useState<any>();
    const [territoryList, setTerritoryList] = useState<any>();
    const [orgAffiliationList, setOrgAffiliationList] = useState<any>();
    const [marketArea, setMarketArea] = useState("");
    const [operatingPlatformCodesList, setOperatingPlatformCodesList] =
      useState<any>();
    const [isSiteNameValid, setIsSiteNameValid] = useState(0);
    const [isSiteStatusValid, setIsSiteStatusValid] = useState(0);

    const [isSiteTypeValid, setIsSiteTypeValid] = useState(0);

    const [isOperatingPlatformValid, setIsOperatingPlatformValid] = useState(0);
    const [
      operatingPlatofrmValidationText,
      setOperatingPlatofrmValidationText,
    ] = useState(Locale.common?.required_field);
    const [isLatitudeValid, setIsLatitudeValid] = useState(0);
    const [latitudeValidationText, setLatitudeValidationText] = useState(
      Locale.common?.required_field
    );
    const [isLongitudeValid, setIsLongitudeValid] = useState(0);
    const [longitudeValidationText, setLongitudeValidationText] = useState(
      Locale.common?.required_field
    );
    const [isOpeningDateValid, setIsOpeningDateValid] = useState(0);
    const [openingDateValidationText, setOpeningDateValidationText] = useState(
      Locale.common?.required_field
    );
    const [isClosingDateValid, setIsClosingDateValid] = useState(0);
    const [closingDateValidationText, setClosingDateValidationText] = useState(
      Locale.common?.required_field
    );
    const [isCategoryValid, setIsCategoryValid] = useState(0);

    const [isRETypeValid, setIsRETypeValid] = useState(0);

    const [isSalesOrgNameValid, setIsSalesOrgNameValid] = useState(0);

    const [isFPSIndicatorValid, setIsFPSIndicatorValid] = useState(0);

    const [isCRSiteShopTypeValid, setIsCRSiteShopTypeValid] = useState(0);

    const [isCRFomatNameValid, setIsCRFomatNameValid] = useState(0);

    const [isOrgAffiliationValid, setIsOrgAffiliationValid] = useState(0);

    const [isOrgAffiliationTypeValid, setIsOrgAffiliationTypeValid] =
      useState(0);

    const [isSalesOfficeCodeValid, setIsSalesOfficeCodeValid] = useState(0);

    const [isNERARiskNameValid, setIsNERARiskNameValid] = useState(0);

    const [isNERACertificationDateValid, setIsNERACertificationDateValid] =
      useState(0);

    const [isStreetAddressValid, setIsStreetAddressValid] = useState(0);

    const [isCityValid, setIsCityValid] = useState(0);

    const [isCountryValid, setIsCountryValid] = useState(0);

    const [isPostalCodeValid, setIsPostalCodeValid] = useState(0);

    const [isTelephoneNoValid, setIsTelephoneNoValid] = useState(0);

    const [siteTypeList, setSiteTypeList] = useState<any[]>([]);
    const [isCountryChanged, setIsCountryChanged] = useState(false);

    const [usaCompanySelectedValue, setUSACompanySelectedValue] = useState<{
      id: string;
      value: string;
    }>();

    useEffect(() => {
      setCountyList(countyCodes?.slice());
    }, [countyCodes]);

    useEffect(() => {
      setTerritoryList(territoryCodes?.slice());
    }, [territoryCodes]);

    useEffect(() => {
      setOrgAffiliationList(orgAffiliationCodes?.slice());
    }, [orgAffiliationCodes]);

    useEffect(() => {
      if (basicSiteDetails && isEditing) {
        setSiteStatusSelectedValue({
          value: basicSiteDetails?.siteStatusCd?.siteStatusName,
          id: basicSiteDetails?.siteStatusCd?.siteStatusCd,
        });
        setSelectedStatus({
          value: basicSiteDetails?.siteStatusCd?.siteStatusName,
          id: basicSiteDetails?.siteStatusCd?.siteStatusCd,
        });

        onSiteTypeSelected({
          value: basicSiteDetails?.siteTypeCd?.siteTypeName,
          id: basicSiteDetails?.siteTypeCd?.siteTypeCd,
        });

        setSelectedCategory(basicSiteDetails?.siteStatusCd?.siteStatusCd);
        setSiteTypeSelectedValue({
          value: basicSiteDetails?.siteTypeCd?.siteTypeName,
          id: basicSiteDetails?.siteTypeCd?.siteTypeCd,
        });
        setSiteCategorySelectedValue({
          value: basicSiteDetails?.siteCategoryCd?.siteCategoryCd,
          id: siteCategoryCodes?.find(
            (i: { value: string }) =>
              i.value == basicSiteDetails?.siteCategoryCd?.siteCategoryCd
          )?.id,
        });
        setSelectedCategory(basicSiteDetails?.siteCategoryCd?.siteCategoryCd);
        setSiteTenureTypeSelectedValue({
          id: basicSiteDetails?.tenureTypeCd?.tenureTypeCd,
          value: basicSiteDetails?.tenureTypeCd?.tenureTypeName,
        });

        setOperatingPlatformSelectedValue({
          value: basicSiteDetails?.operatingPlatformCd?.operatingPlatformName,
          id: basicSiteDetails?.operatingPlatformCd?.operatingPlatformCd,
        });
        setOperatingPlatform({
          value: basicSiteDetails?.operatingPlatformCd?.operatingPlatformName,
          id: basicSiteDetails?.operatingPlatformCd?.operatingPlatformCd,
        });
        setSiteName(basicSiteDetails?.siteName?.toUpperCase());
        setSiteShortName(basicSiteDetails?.siteShortName?.toUpperCase());

        setSiteOpeningDate(
          basicSiteDetails?.siteOpeningDate
            ? format(
                new Date(basicSiteDetails?.siteOpeningDate),
                AppConst.VIEW_DATE_FORMAT
              )
            : null
        );
        setSiteClosingDate(
          basicSiteDetails?.siteClosingDate
            ? format(
                new Date(basicSiteDetails?.siteClosingDate),
                AppConst.VIEW_DATE_FORMAT
              )
            : null
        );
        setUSACompanySelectedValue({
          value: basicSiteDetails?.usaCompanyCd?.usaCompanyDesc,
          id: basicSiteDetails?.usaCompanyCd?.usaCompanyCd,
        });
        setLatitude(basicSiteDetails?.latitude);
        setLongitude(basicSiteDetails?.longitude);
        setSalesOrganisationName(basicSiteDetails?.salesOrgCd);
        setSalesOfficeCode(basicSiteDetails?.salesOfficeCd);
        setMarketArea(basicSiteDetails?.marketArea);

        setNERACertificationDate(
          basicSiteDetails?.neraCertificationDate
            ? format(
                new Date(basicSiteDetails?.neraCertificationDate),
                AppConst.VIEW_DATE_FORMAT
              )
            : null
        );

        setFPSIndicatorSelectedValue({
          value: basicSiteDetails?.fpsInd,
          id: fpsIndicatorCodes?.find(
            (i: { value: string }) => i.value == basicSiteDetails?.fpsInd
          )?.id,
        });

        setCrSiteShopTypeSelectedValue({
          value: basicSiteDetails?.crSiteShopTypeCd?.crSiteShopTypeName,
          id: basicSiteDetails?.crSiteShopTypeCd?.crSiteShopTypeCd,
        });
        setCrFormatTypeSelectedValue({
          value: basicSiteDetails?.crFormatTypeCd?.crFormatTypeName,
          id: basicSiteDetails?.crFormatTypeCd?.crFormatTypeCd,
        });

        setOrgAffiliationSelectedValue({
          value: basicSiteDetails?.orgAffiliationCd?.orgAffiliationName,
          id: basicSiteDetails?.orgAffiliationCd?.orgAffiliationCd,
        });
        setOrgAffiliationTypeSelectedValue({
          value: basicSiteDetails?.orgAffiliationTypeCd?.orgAffiliationTypeName,
          id: basicSiteDetails?.orgAffiliationTypeCd?.orgAffiliationTypeCd,
        });
        setNeraRiskNameSelectedValue({
          value: basicSiteDetails?.neraRiskCd?.neraRiskName,
          id: basicSiteDetails?.neraRiskCd?.neraRiskCd,
        });

        setCountrySelectedValue({
          value: basicSiteDetails?.countryCd?.countryName,
          id: basicSiteDetails?.countryCd?.countryCd,
        });
        refetchTerritoryCodes({
          value: basicSiteDetails?.countryCd?.countryName,
          id: basicSiteDetails?.countryCd?.countryCd,
        });
        setTerritorySelectedValue({
          value: basicSiteDetails?.territoryCd?.territoryName,
          id: basicSiteDetails?.territoryCd?.territoryCd,
        });
        refetchCountyCodes({
          value: basicSiteDetails?.territoryCd?.territoryName,
          id: basicSiteDetails?.territoryCd?.territoryCd,
        });
        setCountySelectedValue({
          value: basicSiteDetails?.countyCd?.countyName,
          id: basicSiteDetails?.countyCd?.countyCd,
        });
        setPostalCode(basicSiteDetails?.postalCode);
        setCountryDiallingCode(
          basicSiteDetails?.countryDiallingCd?.countryDiallingCd
        );
        setStreetAddress(basicSiteDetails?.streetAddress);
        setCityName(basicSiteDetails?.cityName);
        setTelephoneNumber(basicSiteDetails?.telephoneNo);
      }
    }, [basicSiteDetails, siteCategoryCodes, isEditing]);

    useEffect(() => {
      if (siteTypeCodes) {
        if (isCreate) {
          const typeList = [...siteTypeCodes].filter(
            (type: { id: string; value: string }) => {
              if (
                roleName === RoleConst.RBLA_GLOBAL_SME ||
                roleName === RoleConst.RBLA_LOCAL_SME
              ) {
                return type.id === SiteTypeConst.SHELL_LICENSED_MARKET_SITE;
              } else if (
                roleName === RoleConst.FUEL_PRICING_LOCAL_SME ||
                roleName === RoleConst.FUEL_PRICING_GLOBAL_SME ||
                roleName === RoleConst.SALES_OPS_SME
              ) {
                return type.id === SiteTypeConst.COMPETITOR_SITE;
              } else if (
                roleName === RoleConst.PARTNER_GLOBAL_SME ||
                roleName === RoleConst.PARTNER_LOCAL_SME
              ) {
                return type.id === SiteTypeConst.SHELL_CARD_PARTNER_SITE;
              } else if (
                roleName === RoleConst.TM_AND_DM ||
                roleName === RoleConst.RETAILER ||
                roleName === RoleConst.MARKET_DATA_LEAD ||
                roleName === RoleConst.GI_ACCESS_USER
              ) {
                return (
                  type.id === SiteTypeConst.SHELL_IN_NETWORK_RETAIL_SITE ||
                  type.id === SiteTypeConst.SHELL_COMMERCIAL_SITE
                );
              }
            }
          );
          setSiteTypeList([...typeList]);
        } else {
          setSiteTypeList([...siteTypeCodes]);
        }
      }
    }, [siteTypeCodes]);

    useEffect(() => {
      if (isCreate && siteStatusCodes) {
        setStatusTypeList([...siteStatusCodes]);
      }
    }, [siteStatusCodes]);

    // General scroll to element function
    useImperativeHandle(ref, () => ({
      setNewCategory(categoryFromFuelProducts: string): any {
        if (categoryFromFuelProducts) {
          onSectionValueSet(
            {
              siteCategoryCd: {
                siteCategoryCd: categoryFromFuelProducts?.toString(),
              },
            },
            basicSiteDetails?.siteCategoryCd?.siteCategoryCd !==
              categoryFromFuelProducts
          );
          const selectedCategory = siteCategoryCodes?.find(
            (category: { value: string }) =>
              category?.value === categoryFromFuelProducts
          );
          setSiteCategorySelectedValue(selectedCategory);
          setSelectedCategory(selectedCategory);
        } else {
          onSectionValueSet(
            {
              siteCategoryCd: {
                siteCategoryCd: "",
              },
            },
            false
          );
          setSiteCategorySelectedValue(undefined);
          setSelectedCategory(undefined);
        }
      },
      triggerValidations(): boolean {
        let isValid = true;
        let isScroll = true;

        //Mandatory for All
        //Max 35 chars in Uppercase
        if (!siteName?.length) {
          setIsSiteNameValid(-1);
          isValid = false;
          isScroll = false;
        } else {
          setIsSiteNameValid(1);
        }

        //Mandatory for all
        if (!siteTypeSelectedValue?.value) {
          setIsSiteTypeValid(-1);
          isValid = false;
          isScroll = false;
        } else {
          setIsSiteTypeValid(1);
        }

        // Mandatory for all
        if (!siteStatusSelectedValue?.value) {
          setIsSiteStatusValid(-1);
          isValid = false;
          isScroll = false;
        } else {
          setIsSiteStatusValid(1);
        }

        //Mandatatory for all active sites
        if (
          siteStatusSelectedValue?.id === SiteStatusConst.ACTIVE &&
          !operatingPlatformSelectedValue?.value
        ) {
          setIsOperatingPlatformValid(-1);
          setOperatingPlatofrmValidationText(Locale.common?.required_field);
          isValid = false;
          isScroll = false;
        } else {
          setIsOperatingPlatformValid(1);
        }

        //Mandatory for GSAP,NGSAP and RBLA Active sites
        //GSAP || NGSAP  - Min 5 decimal places
        //RBLA || Partner, Competitor == MIn 4 decimal places
        // if latitude is touched and changed then only check
        if (
          siteStatusSelectedValue?.id === SiteStatusConst.ACTIVE &&
          (siteTypeSelectedValue?.id ===
            SiteTypeConst.SHELL_IN_NETWORK_RETAIL_SITE ||
            siteTypeSelectedValue?.id === SiteTypeConst.SHELL_COMMERCIAL_SITE ||
            siteTypeSelectedValue?.id ===
              SiteTypeConst.SHELL_LICENSED_MARKET_SITE) &&
          !latitude
        ) {
          isValid = false;
          isScroll = false;
          setIsLatitudeValid(-1);
          setLatitudeValidationText(Locale.common?.required_field);
        } else if (latitude && latitude !== basicSiteDetails?.latitude) {
          if (
            classifiedSiteType === AppConst.GSAP_SITE_TYPE ||
            classifiedSiteType === AppConst.NON_GSAP_SITE_TYPE
          ) {
            if (!AppConst.SHELL_LATITUDE_REGULAR_EXP?.test(latitude)) {
              setIsLatitudeValid(-1);
              setLatitudeValidationText(
                Locale.create_site?.error_messages
                  ?.invalid_latitude_for_shell_sites
              );
              isValid = false;
              isScroll = false;
            } else {
              setIsLatitudeValid(1);
            }
          } else if (!AppConst.NON_SHELL_LATITUDE_REGULAR_EXP?.test(latitude)) {
            setIsLatitudeValid(-1);
            setLatitudeValidationText(
              Locale.create_site?.error_messages
                ?.invalid_latitude_for_non_shell_sites
            );
            isValid = false;
            isScroll = false;
          } else {
            setIsLatitudeValid(1);
          }
        } else {
          setIsLatitudeValid(1);
        }

        //Mandatory for GSAP, NGSAP and RBLA Active sites
        //GSAP || NGSAP  - Min 5 decimal places
        //RBLA || Partner, Competitor == MIn 4 decimal places
        // if longitude is touched and changed then only check
        if (
          siteStatusSelectedValue?.id === SiteStatusConst.ACTIVE &&
          (siteTypeSelectedValue?.id ===
            SiteTypeConst.SHELL_IN_NETWORK_RETAIL_SITE ||
            siteTypeSelectedValue?.id === SiteTypeConst.SHELL_COMMERCIAL_SITE ||
            siteTypeSelectedValue?.id ===
              SiteTypeConst.SHELL_LICENSED_MARKET_SITE) &&
          !longitude
        ) {
          isValid = false;
          isScroll = false;
          setIsLongitudeValid(-1);
          setLongitudeValidationText(Locale.common?.required_field);
        } else if (longitude && longitude !== basicSiteDetails?.longitude) {
          if (
            classifiedSiteType === AppConst.GSAP_SITE_TYPE ||
            classifiedSiteType === AppConst.NON_GSAP_SITE_TYPE
          ) {
            if (!AppConst.SHELL_LONGITUDE_REGULAR_EXP?.test(longitude)) {
              setIsLongitudeValid(-1);
              setLongitudeValidationText(
                Locale.create_site?.error_messages
                  ?.invalid_longitude_for_shell_sites
              );
              isValid = false;
              isScroll = false;
            } else {
              setIsLongitudeValid(1);
            }
          } else if (
            !AppConst.NON_SHELL_LONGITUDE_REGULAR_EXP?.test(longitude)
          ) {
            setIsLongitudeValid(-1);
            setLongitudeValidationText(
              Locale.create_site?.error_messages
                ?.invalid_longitude_for_non_shell_sites
            );
            isValid = false;
            isScroll = false;
          } else {
            setIsLongitudeValid(1);
          }
        } else {
          setIsLongitudeValid(1);
        }

        //Mandatory for Active sites
        //Opening date can't be a future date for Active sites
        if (
          siteStatusSelectedValue?.id === SiteStatusConst.ACTIVE &&
          (!siteOpeningDate || siteOpeningDate === null)
        ) {
          setIsOpeningDateValid(-1);
          setOpeningDateValidationText(Locale.common?.required_field);
          isValid = false;
          isScroll = false;
        } else if (
          siteStatusSelectedValue?.id === SiteStatusConst.ACTIVE &&
          siteOpeningDate &&
          siteOpeningDate !== null &&
          new Date(siteOpeningDate).getTime() > new Date().getTime() // Opening date greater than today
        ) {
          setIsOpeningDateValid(-1);
          setOpeningDateValidationText(
            Locale.create_site?.error_messages
              ?.invalid_opening_date_for_active_sites
          );
          isValid = false;
          isScroll = false;
        } else {
          setIsOpeningDateValid(1);
        }

        //Mandatory for Closed permanently/temporarily
        //For Active Sites Closing Date can't be from past
        if (
          (siteStatusSelectedValue?.id === SiteStatusConst.CLOSED_TEMPORARILY ||
            siteStatusSelectedValue?.id ===
              SiteStatusConst.CLOSED_PERMANENTLY) &&
          (!siteClosingDate || siteClosingDate === null)
        ) {
          setIsClosingDateValid(-1);
          setClosingDateValidationText(Locale.common?.required_field);
          isValid = false;
          isScroll = false;
        } else if (
          siteStatusSelectedValue?.id === SiteStatusConst.ACTIVE &&
          siteClosingDate &&
          siteClosingDate !== null &&
          new Date(siteClosingDate).getTime() <= new Date().getTime() // Closing date today or a past date
        ) {
          setIsClosingDateValid(-1);
          setClosingDateValidationText(
            Locale.create_site?.error_messages
              ?.invalid_closing_date_for_active_sites
          );
          isValid = false;
          isScroll = false;
        } else if (
          (siteStatusSelectedValue?.id === SiteStatusConst.CLOSED_TEMPORARILY ||
            siteStatusSelectedValue?.id ===
              SiteStatusConst.CLOSED_PERMANENTLY) &&
          siteClosingDate &&
          siteClosingDate !== null
        ) {
          if (new Date(siteClosingDate).getTime() > new Date().getTime()) {
            // Site Closing date is future date
            setIsClosingDateValid(-1);
            setClosingDateValidationText(
              Locale.create_site?.error_messages
                ?.invalid_closing_date_for_closed_sites
            );
            isValid = false;
            isScroll = false;
          } else {
            setIsClosingDateValid(1);
          }
        } else {
          setIsClosingDateValid(1);
        }

        //Site Opening date can't be greater than Closing date
        if (
          siteOpeningDate &&
          siteOpeningDate !== null &&
          siteClosingDate &&
          siteClosingDate !== null
        ) {
          if (
            new Date(siteOpeningDate).getTime() >
            new Date(siteClosingDate).getTime()
          ) {
            setIsClosingDateValid(-1);
            setClosingDateValidationText(
              Locale.create_site?.error_messages
                ?.invalid_closing_date_is_less_than_opening_date
            );
            isValid = false;
            isScroll = false;
          }
        }

        //Mandatory for GSAP||NGSAP||RBLA  Active site
        if (
          (siteTypeSelectedValue?.id ===
            SiteTypeConst.SHELL_IN_NETWORK_RETAIL_SITE ||
            siteTypeSelectedValue?.id === SiteTypeConst.SHELL_COMMERCIAL_SITE ||
            siteTypeSelectedValue?.id ===
              SiteTypeConst.SHELL_LICENSED_MARKET_SITE) &&
          siteStatusSelectedValue?.id === SiteStatusConst.ACTIVE &&
          !siteCategorySelectedValue?.value
        ) {
          setIsCategoryValid(-1);
          isValid = false;
          isScroll = false;
        } else {
          setIsCategoryValid(1);
        }

        //Mandatory for  GSAP/NGSAP && company owned  && Active Sites
        // Editable for RBLA but not Mandatory
        if (
          (siteTypeSelectedValue?.id ===
            SiteTypeConst.SHELL_IN_NETWORK_RETAIL_SITE ||
            siteTypeSelectedValue?.id ===
              SiteTypeConst.SHELL_COMMERCIAL_SITE) &&
          siteStatusSelectedValue?.id === SiteStatusConst.ACTIVE &&
          AppConst.COMPANY_OWNED_RE_TYPE_LIST?.includes(
            operatingPlatformSelectedValue?.value
          ) &&
          !siteTenureTypeSelectedValue?.value
        ) {
          setIsRETypeValid(-1);
          isValid = false;
          isScroll = false;
        } else {
          setIsRETypeValid(1);
        }

        // Mandatory for all
        if (!countrySelectedValue?.value) {
          setIsCountryValid(-1);
          isValid = false;
          isScroll = false;
        } else {
          setIsCountryValid(1);
        }

        // Mandatory for all
        if (!cityName) {
          setIsCityValid(-1);
          isValid = false;
          isScroll = false;
        } else {
          setIsCityValid(1);
        }

        // Mandatory for all
        if (!streetAddress) {
          setIsStreetAddressValid(-1);
          isValid = false;
          isScroll = false;
        } else {
          setIsStreetAddressValid(1);
        }

        //Mandatory for GSAP Active sites
        if (
          (siteTypeSelectedValue?.id ===
            SiteTypeConst.SHELL_IN_NETWORK_RETAIL_SITE ||
            siteTypeSelectedValue?.id ===
              SiteTypeConst.SHELL_COMMERCIAL_SITE) &&
          siteStatusSelectedValue?.id === SiteStatusConst.ACTIVE &&
          classifiedSiteType === AppConst.GSAP_SITE_TYPE &&
          !salesOrganisationName
        ) {
          setIsSalesOrgNameValid(-1);
          isValid = false;
        } else {
          setIsSalesOrgNameValid(1);
        }

        //Mandatory for GSAP, NGSAP, Competitor Active sites
        //Only applicable for GSAP/NGSAP and Competitor, RBLA
        if (
          siteStatusSelectedValue?.id === SiteStatusConst.ACTIVE &&
          (siteTypeSelectedValue?.id ===
            SiteTypeConst.SHELL_IN_NETWORK_RETAIL_SITE ||
            siteTypeSelectedValue?.id === SiteTypeConst.SHELL_COMMERCIAL_SITE ||
            siteTypeSelectedValue?.id === SiteTypeConst.COMPETITOR_SITE) &&
          !fpsIndicatorSelectedValue?.value
        ) {
          setIsFPSIndicatorValid(-1);
          isValid = false;
        } else {
          setIsFPSIndicatorValid(1);
        }

        //Mandatory for GSAP/NGSAP Company owned Active site
        if (
          (siteTypeSelectedValue?.id ===
            SiteTypeConst.SHELL_IN_NETWORK_RETAIL_SITE ||
            siteTypeSelectedValue?.id ===
              SiteTypeConst.SHELL_COMMERCIAL_SITE) &&
          siteStatusSelectedValue?.id === SiteStatusConst.ACTIVE &&
          AppConst.COMPANY_OWNED_RE_TYPE_LIST?.includes(
            operatingPlatformSelectedValue?.value
          ) &&
          !crSiteShopTypeSelectedValue?.value
        ) {
          setIsCRSiteShopTypeValid(-1);
          isValid = false;
        } else {
          setIsCRSiteShopTypeValid(1);
        }

        //Org Affiliation Mandatory for active sites
        if (
          !orgAffiliationSelectedValue?.value &&
          siteStatusSelectedValue?.id === SiteStatusConst.ACTIVE
        ) {
          setIsOrgAffiliationValid(-1);
          isValid = false;
        } else {
          setIsOrgAffiliationValid(1);
        }

        //Mandatory for all active sites
        if (
          siteStatusSelectedValue?.id === SiteStatusConst.ACTIVE &&
          !orgAffiliationTypeSelectedValue?.value
        ) {
          setIsOrgAffiliationTypeValid(-1);
          isValid = false;
        } else {
          setIsOrgAffiliationTypeValid(1);
        }

        //Mandatory for GSAP Active site
        if (
          !salesOfficeCode &&
          siteStatusSelectedValue?.id === SiteStatusConst.ACTIVE &&
          classifiedSiteType === AppConst.GSAP_SITE_TYPE
        ) {
          setIsSalesOfficeCodeValid(-1);
          isValid = false;
        } else {
          setIsSalesOfficeCodeValid(1);
        }

        // Mandatory for GSAP/NGSAP CO Active sites
        if (
          siteStatusSelectedValue?.id === SiteStatusConst.ACTIVE &&
          (siteTypeSelectedValue?.id ===
            SiteTypeConst.SHELL_IN_NETWORK_RETAIL_SITE ||
            siteTypeSelectedValue?.id ===
              SiteTypeConst.SHELL_COMMERCIAL_SITE) &&
          AppConst.COMPANY_OWNED_RE_TYPE_LIST?.includes(
            operatingPlatformSelectedValue?.value
          ) &&
          !neraRiskNameSelectedValue?.value
        ) {
          isValid = false;
          setIsNERARiskNameValid(-1);
        } else {
          setIsNERARiskNameValid(1);
        }

        //Mandatory for GSAP/NGSAP CO Active sites
        if (
          (!neraCertificationDate || neraCertificationDate === null) &&
          siteStatusSelectedValue?.id === SiteStatusConst.ACTIVE &&
          (siteTypeSelectedValue?.id ===
            SiteTypeConst.SHELL_IN_NETWORK_RETAIL_SITE ||
            siteTypeSelectedValue?.id ===
              SiteTypeConst.SHELL_COMMERCIAL_SITE) &&
          AppConst.COMPANY_OWNED_RE_TYPE_LIST?.includes(
            operatingPlatformSelectedValue?.value
          ) &&
          neraRiskNameSelectedValue?.id !== "N/A"
        ) {
          setIsNERACertificationDateValid(-1);
          isValid = false;
        } else {
          setIsNERACertificationDateValid(1);
        }

        if (isScroll && !isValid) {
          document.getElementById("orgAffiliation")?.scrollIntoView();
        }
        return isValid;
      },
    }));

    const filterData = (
      filter: FilterDescriptor | CompositeFilterDescriptor,
      codes: any
    ) => {
      const data = codes.slice();
      return filterBy(data, filter);
    };

    const onSiteNameChange = (event: InputChangeEvent) => {
      const name = event?.value?.toUpperCase();

      if (name?.length) {
        setIsSiteNameValid(1);
      } else {
        setIsSiteNameValid(-1);
      }

      setSiteName(name);
      onSectionValueSet(
        {
          siteName: name,
        },
        basicSiteDetails?.siteName?.toUpperCase() !== name
      );
    };

    const onSiteStatusChange = (event: DropDownListChangeEvent) => {
      const status = event?.target?.value;
      const statusId: string = event?.target?.value?.id?.toString();

      if (statusId) {
        setIsSiteStatusValid(1);
      }
      setSiteStatusSelectedValue(status);
      setSelectedStatus(status);

      onSectionValueSet(
        {
          siteStatusCd: {
            siteStatusCd: statusId,
          },
        },
        +statusId !== +basicSiteDetails?.siteStatusCd?.siteStatusCd
      );
    };

    const onSiteShortNameChange = (event: InputChangeEvent) => {
      const shortName = event?.value?.toUpperCase();
      setSiteShortName(shortName);
      onSectionValueSet(
        {
          siteShortName: shortName?.toUpperCase(),
        },
        basicSiteDetails?.siteShortName?.toUpperCase() !== shortName
      );
    };

    useEffect(() => {
      if (isEditing) {
        if (
          isCreate &&
          (siteTypeSelectedValue?.id ===
            SiteTypeConst.SHELL_IN_NETWORK_RETAIL_SITE ||
            siteTypeSelectedValue?.id === SiteTypeConst.SHELL_COMMERCIAL_SITE)
        ) {
          onClassifiedSiteTypeChange(AppConst.NON_GSAP_SITE_TYPE);
        }
        if (
          (siteTypeSelectedValue?.id ===
            SiteTypeConst.SHELL_IN_NETWORK_RETAIL_SITE ||
            siteTypeSelectedValue?.id ===
              SiteTypeConst.SHELL_COMMERCIAL_SITE) &&
          countrySelectedValue
        ) {
          const isGSAP = ValidateEditSite.isGSAPSite(
            countrySelectedValue,
            siteTypeSelectedValue
          );
          if (isGSAP) {
            onClassifiedSiteTypeChange(AppConst.GSAP_SITE_TYPE);
            if (siteStatusCodes) {
              const GSAP_SITE_TYPES = [...siteStatusCodes]?.filter(
                (type) => type.id !== SiteStatusConst.NOT_SPECIFIED
              );
              setStatusTypeList([...GSAP_SITE_TYPES]);
            }
          }
          const isNonGSAP = ValidateEditSite.isNonGSAPSite(
            countrySelectedValue,
            siteTypeSelectedValue
          );
          if (isNonGSAP) {
            onClassifiedSiteTypeChange(AppConst.NON_GSAP_SITE_TYPE);
          }
        } else if (
          siteTypeSelectedValue?.id === SiteTypeConst.COMPETITOR_SITE
        ) {
          onClassifiedSiteTypeChange(AppConst.COMPETITOR_SITE_TYPE);
        } else if (
          siteTypeSelectedValue?.id === SiteTypeConst.SHELL_LICENSED_MARKET_SITE
        ) {
          onClassifiedSiteTypeChange(AppConst.RBLA_SITE_TYPE);
        } else if (
          siteTypeSelectedValue?.id === SiteTypeConst.SHELL_CARD_PARTNER_SITE
        ) {
          onClassifiedSiteTypeChange(AppConst.PARTNER_SITE_TYPE);
        }
        if (isCreate) {
          if (
            siteTypeSelectedValue?.id ===
              SiteTypeConst.SHELL_IN_NETWORK_RETAIL_SITE ||
            siteTypeSelectedValue?.id === SiteTypeConst.SHELL_COMMERCIAL_SITE
          ) {
            const NON_GSAP_COUNTRY_LIST = countryCodes?.filter(
              (country: any) =>
                country.id === ContryConst.UKRAINE ||
                country.id === ContryConst.CHINA
            );
            setNonGSAPCountryList(NON_GSAP_COUNTRY_LIST || []);
            setCountryList(NON_GSAP_COUNTRY_LIST?.slice());
          } else {
            if (
              roleName === RoleConst.PARTNER_GLOBAL_SME ||
              roleName === RoleConst.RBLA_GLOBAL_SME ||
              roleName === RoleConst.FUEL_PRICING_GLOBAL_SME
            ) {
              setCountryList(countryCodes?.slice());
            } else {
              const userCountyCodes = userDetails?.userRoleMapping?.map(
                (country: { countryCode: string }) => country?.countryCode
              );
              const userCountryList = countryCodes?.filter((country: any) =>
                userCountyCodes?.includes(country.id)
              );
              setLocalUserCountryList(userCountryList || []);
              setCountryList(userCountryList?.slice() || []);
            }
          }
        } else {
          setCountryList(countryCodes?.slice());
        }
      }
    }, [
      isEditing,
      isCreate,
      countryCodes,
      siteTypeSelectedValue,
      countrySelectedValue,
      siteStatusCodes,
    ]);

    const onSiteTypeChange = (event: DropDownListChangeEvent) => {
      const siteType = event?.target?.value;
      const siteTypeId = event?.target?.value?.id?.toString();
      onSiteTypeSelected(siteType);
      if (siteType) {
        setIsSiteTypeValid(1);
      }
      setSiteTypeSelectedValue(siteType);

      const selectedStatus = siteStatusCodes?.find(
        (status: { id: string; value: string }) =>
          status?.id === SiteStatusConst.NOT_SPECIFIED
      );
      setSiteStatusSelectedValue(selectedStatus);

      if (siteTypeId === SiteTypeConst.COMPETITOR_SITE) {
        const opDNA = operatingPlatformCodes?.find(
          (op: { id: string; value: string }) =>
            op?.id === OperatingPlatformConst.DOES_NOT_APPLY
        );
        setOperatingPlatformSelectedValue(opDNA);
        setOperatingPlatform(opDNA);

        onSectionValueSet(
          {
            siteTypeCd: {
              siteTypeCd: siteTypeId,
            },
            operatingPlatformCd: {
              operatingPlatformCd: OperatingPlatformConst.DOES_NOT_APPLY,
            },
            siteStatusCd: {
              siteStatusCd: SiteStatusConst.NOT_SPECIFIED,
            },
          },
          true
        );
      } else if (siteTypeId === SiteTypeConst.SHELL_LICENSED_MARKET_SITE) {
        const opRBLA = operatingPlatformCodes?.find(
          (op: { id: string; value: string }) =>
            op?.id === OperatingPlatformConst.RBLA
        );
        setOperatingPlatformSelectedValue(opRBLA);
        setOperatingPlatform(opRBLA);

        onSectionValueSet(
          {
            siteTypeCd: {
              siteTypeCd: siteTypeId,
            },
            operatingPlatformCd: {
              operatingPlatformCd: OperatingPlatformConst.RBLA,
            },
            siteStatusCd: {
              siteStatusCd: SiteStatusConst.NOT_SPECIFIED,
            },
          },
          true
        );
      } else {
        const oplist = operatingPlatformCodes.filter(
          (op: any) => op.id !== OperatingPlatformConst.RBLA
        );
        setOperatingPlatformCodesList(oplist);

        if (
          siteTypeId === SiteTypeConst.SHELL_IN_NETWORK_RETAIL_SITE ||
          siteTypeId === SiteTypeConst.SHELL_COMMERCIAL_SITE
        ) {
          const otherShopType = crSiteShopTypeCodes?.find(
            (shop: { id: string; value: string }) =>
              shop.id === CrSiteShopTypeConst.OTHER
          );
          setCrSiteShopTypeSelectedValue(otherShopType);

          onSectionValueSet(
            {
              siteTypeCd: {
                siteTypeCd: siteTypeId,
              },
              crSiteShopTypeCd: {
                crSiteShopTypeCd: otherShopType?.id?.toString(),
              },
              siteStatusCd: {
                siteStatusCd: SiteStatusConst.NOT_SPECIFIED,
              },
            },
            true
          );
        } else {
          onSectionValueSet(
            {
              siteTypeCd: {
                siteTypeCd: siteTypeId,
              },
              siteStatusCd: {
                siteStatusCd: SiteStatusConst.NOT_SPECIFIED,
              },
            },
            true
          );
        }
      }
    };

    const onOperatingPlatformChange = (event: DropDownListChangeEvent) => {
      const oPlatform = event?.target?.value;
      if (oPlatform) {
        setIsOperatingPlatformValid(1);
      }
      setOperatingPlatformSelectedValue(oPlatform);
      setOperatingPlatform(oPlatform);
      onSectionValueSet(
        {
          operatingPlatformCd: {
            operatingPlatformCd: oPlatform?.id?.toString(),
          },
        },
        +basicSiteDetails?.operatingPlatformCd?.operatingPlatformCd !==
          +oPlatform?.id
      );
    };

    const onLatitudeChange = (event: InputChangeEvent) => {
      const lat = event?.value?.toString();
      setIsLatitudeValid(1);
      setLatitude(lat);
      onSectionValueSet(
        {
          latitude: lat,
        },
        basicSiteDetails?.latitude !== lat
      );
    };

    const onLongitudeChange = (event: InputChangeEvent) => {
      const long = event.value?.toString();
      setIsLongitudeValid(1);
      setLongitude(long);
      onSectionValueSet(
        {
          longitude: long,
        },
        basicSiteDetails?.longitude !== long
      );
    };

    const onMarketAreaChange = (event: InputChangeEvent) => {
      const market = event?.value;
      setMarketArea(market);
      onSectionValueSet(
        {
          marketArea: market,
        },
        basicSiteDetails?.marketArea !== market
      );
    };

    const onSiteOpeningDateChange = (event: DateInputChangeEvent) => {
      const openingDate: any = event.value;
      if (openingDate && openingDate !== null) {
        setIsOpeningDateValid(1);
        setSiteOpeningDate(
          openingDate
            ? format(new Date(openingDate), AppConst.VIEW_DATE_FORMAT)
            : null
        );
        const date: number = openingDate?.getDate() || 0;
        const month: number = openingDate?.getMonth() || 0;
        const year: number = openingDate?.getFullYear() || 2000;
        const formattedUTCTime = formatDate(new Date(year, month, date), "s");
        onSectionValueSet(
          {
            siteOpeningDate: formattedUTCTime?.toString(),
          },
          basicSiteDetails?.siteOpeningDate !== formattedUTCTime
        );
      } else {
        setSiteOpeningDate(undefined);
        onSectionValueSet(
          {
            siteOpeningDate: null,
          },
          basicSiteDetails?.siteOpeningDate === undefined ||
            basicSiteDetails?.siteOpeningDate === null
            ? false
            : true
        );
      }
    };

    const onSiteClosingDateChange = (event: DateInputChangeEvent) => {
      const closingDate: any = event.value;
      if (closingDate && closingDate !== null) {
        setIsClosingDateValid(1);
        setSiteClosingDate(
          closingDate
            ? format(new Date(closingDate), AppConst.VIEW_DATE_FORMAT)
            : null
        );

        const date: number = closingDate?.getDate() || 0;
        const month: number = closingDate?.getMonth() || 0;
        const year: number = closingDate?.getFullYear() || 2000;
        const formattedUTCTime = formatDate(new Date(year, month, date), "s");
        onSectionValueSet(
          {
            siteClosingDate: formattedUTCTime?.toString(),
          },
          basicSiteDetails?.siteClosingDate !== formattedUTCTime
        );
      } else {
        setSiteClosingDate(undefined);
        onSectionValueSet(
          {
            siteClosingDate: null,
          },
          basicSiteDetails?.siteClosingDate === undefined ||
            basicSiteDetails?.siteClosingDate === null
            ? false
            : true
        );
      }
    };

    const onSiteCategoryChange = (event: DropDownListChangeEvent) => {
      const catgory = event?.target?.value;
      setSiteCategorySelectedValue(catgory);
      setIsCategoryValid(1);
      onSectionValueSet(
        {
          siteCategoryCd: {
            siteCategoryCd: catgory?.value?.toString(),
          },
        },
        basicSiteDetails?.siteCategoryCd?.siteCategoryCd !== catgory?.value
      );
      setSelectedCategory(catgory?.value);
    };

    const onUSACompanyChange = (event: DropDownListChangeEvent) => {
      const usaCompany = event?.target?.value;
      setUSACompanySelectedValue(usaCompany);
      onSectionValueSet(
        {
          usaCompanyCd: {
            usaCompanyCd: usaCompany?.id?.toString(),
          },
        },
        basicSiteDetails?.usaCompanyCd?.usaCompanyCd !== usaCompany?.id
      );
    };

    const onSiteTenureTypeChange = (event: DropDownListChangeEvent) => {
      const reType = event?.target?.value;
      setIsRETypeValid(1);
      setSiteTenureTypeSelectedValue(reType);
      onSectionValueSet(
        {
          tenureTypeCd: {
            tenureTypeCd: reType?.id?.toString(),
          },
        },
        basicSiteDetails?.tenureTypeCd?.tenureTypeCd !== reType?.id
      );
    };

    const onCountryChange = (event: DropDownListChangeEvent) => {
      const country = event?.target?.value;
      setIsCountryValid(1);
      setCountrySelectedValue(country);
      setCountryDiallingCode("");
      setIsCountryChanged(true);
      setPostalCode("");
      refetchTerritoryCodes(country);
      setTerritorySelectedValue(null);
      setCountySelectedValue(null);
      onSectionValueSet(
        {
          countryCd: {
            countryCd: country?.id?.toString(),
          },
        },
        basicSiteDetails?.countryCd?.countryCd !== country?.id
      );
    };

    const countryFilterChange = (event: DropDownListFilterChangeEvent) => {
      if (classifiedSiteType === AppConst.NON_GSAP_SITE_TYPE) {
        setCountryList(filterData(event.filter, nonGSAPCountryList));
      } else if (
        roleName === RoleConst.PARTNER_LOCAL_SME ||
        roleName === RoleConst.RBLA_LOCAL_SME ||
        roleName === RoleConst.FUEL_PRICING_LOCAL_SME
      ) {
        setCountryList(filterData(event.filter, localUserCountryList));
      } else {
        setCountryList(filterData(event.filter, countryCodes));
      }
    };

    useEffect(() => {
      if (countryDiallingCd && isCountryChanged) {
        setCountryDiallingCode(countryDiallingCd);
        onSectionValueSet(
          {
            countryDiallingCd: {
              countryDiallingCd: countryDiallingCd?.toString(),
            },
          },
          basicSiteDetails?.countryDiallingCd?.countryDiallingCd !==
            countryDiallingCd
        );
        setIsCountryChanged(false);
      }
    }, [countryDiallingCd]);

    const onTerritoryChange = (event: DropDownListChangeEvent) => {
      const territory = event.target.value;
      setTerritorySelectedValue(territory);
      setCountySelectedValue(null);
      setPostalCode("");
      onSectionValueSet(
        {
          territoryCd: {
            territoryCd: territory?.id?.toString(),
          },
        },
        basicSiteDetails?.territoryCd?.territoryCd !== territory?.id
      );
    };

    const territoryFilterChange = (event: DropDownListFilterChangeEvent) => {
      setTerritoryList(filterData(event.filter, territoryCodes));
    };

    const onCountyChange = (event: DropDownListChangeEvent) => {
      const county = event?.target?.value;
      setCountySelectedValue(county);
      onSectionValueSet(
        {
          countyCd: {
            countyCd: county?.id?.toString(),
          },
        },
        basicSiteDetails?.countyCd?.countyCd !== county?.id
      );
    };

    const countyFilterChange = (event: DropDownListFilterChangeEvent) => {
      setCountyList(filterData(event.filter, countyCodes));
    };

    const onCityChange = (event: InputChangeEvent) => {
      const city = event?.value;
      if (city) {
        setIsCityValid(1);
      }
      setCityName(city);
      onSectionValueSet(
        {
          cityName: city,
        },
        basicSiteDetails?.cityName !== city
      );
    };

    const onStreetAddressChange = (event: InputChangeEvent) => {
      const street = event?.value;
      street?.length && setIsStreetAddressValid(1);
      setStreetAddress(street);
      onSectionValueSet(
        {
          streetAddress: street,
        },
        basicSiteDetails?.streetAddress !== street
      );
    };

    const onPostalCodeChange = (event: InputChangeEvent) => {
      const postal = event?.value;
      setPostalCode(postal);
      setIsPostalCodeValid(0);
      onSectionValueSet(
        {
          postalCode: postal,
        },
        basicSiteDetails?.postalCode !== postal
      );
    };

    const onCountryDiallingCodeChange = (event: InputChangeEvent) => {
      const diallingCode = event?.value;
      setCountryDiallingCode(diallingCode);
      onSectionValueSet(
        {
          countryDiallingCd: {
            countryDiallingCd: diallingCode,
          },
        },
        basicSiteDetails?.countryDiallingCd?.countryDiallingCd?.toUpperCase() !==
          diallingCode?.toUpperCase()
      );
    };

    const onTelephoneNumberChange = (event: InputChangeEvent) => {
      const telephone = event?.value;
      setTelephoneNumber(telephone);
      setIsTelephoneNoValid(0);
      onSectionValueSet(
        {
          telephoneNo: telephone,
        },
        basicSiteDetails?.telephoneNo !== telephone
      );
    };

    const onSalesOrganisationNameChange = (event: InputChangeEvent) => {
      const salesOrg = event?.value;
      salesOrg ? setIsSalesOrgNameValid(1) : setIsSalesOrgNameValid(-1);
      setSalesOrganisationName(salesOrg);
      onSectionValueSet(
        {
          salesOrgCd: salesOrg,
        },
        basicSiteDetails?.salesOrgCd !== event.value
      );
    };

    const onFPSIndicatorChange = (event: DropDownListChangeEvent) => {
      const fps = event?.target?.value;
      setIsFPSIndicatorValid(1);
      setFPSIndicatorSelectedValue(fps);
      onSectionValueSet(
        {
          fpsInd: fps?.value?.toString(),
        },
        basicSiteDetails?.fpsInd !== fps?.value
      );
    };

    const onCrSiteShopTypeChange = (event: DropDownListChangeEvent) => {
      const shop = event?.target?.value;
      setIsCRSiteShopTypeValid(1);
      setCrSiteShopTypeSelectedValue(shop);
      onSectionValueSet(
        {
          crSiteShopTypeCd: {
            crSiteShopTypeCd: shop?.id?.toString(),
          },
        },
        basicSiteDetails?.crSiteShopTypeCd?.crSiteShopTypeCd !== shop?.id
      );
    };

    const onCrFormatTypeChange = (event: DropDownListChangeEvent) => {
      const type = event?.target?.value;
      setCrFormatTypeSelectedValue(type);
      setIsCRFomatNameValid(1);
      onSectionValueSet(
        {
          crFormatTypeCd: {
            crFormatTypeCd: type?.id?.toString(),
          },
        },
        basicSiteDetails?.crFormatTypeCd?.crFormatTypeCd !== type?.id
      );
    };

    const OrgAffiliationFilterChange = (
      event: DropDownListFilterChangeEvent
    ) => {
      setOrgAffiliationList(filterData(event.filter, orgAffiliationCodes));
    };

    const onOrgAffiliationChange = (event: DropDownListChangeEvent) => {
      setIsOrgAffiliationValid(1);
      const orgAffiliation = event?.target?.value;
      setOrgAffiliationSelectedValue(orgAffiliation);
      onSectionValueSet(
        {
          orgAffiliationCd: {
            orgAffiliationCd: orgAffiliation?.id?.toString(),
          },
        },
        basicSiteDetails?.orgAffiliationCd?.orgAffiliationCd !==
          orgAffiliation.id
      );
    };

    const onOrgAffiliationTypeChange = (event: DropDownListChangeEvent) => {
      setIsOrgAffiliationTypeValid(1);
      const orgAffiliationType = event?.target?.value;
      setOrgAffiliationTypeSelectedValue(orgAffiliationType);
      onSectionValueSet(
        {
          orgAffiliationTypeCd: {
            orgAffiliationTypeCd: orgAffiliationType?.id?.toString(),
          },
        },
        basicSiteDetails?.orgAffiliationTypeCd?.orgAffiliationTypeCd !==
          orgAffiliationType?.id
      );
    };

    const onSalesOfficeCodeChange = (event: InputChangeEvent) => {
      const salesOffice = event?.value;
      salesOffice
        ? setIsSalesOfficeCodeValid(1)
        : setIsSalesOfficeCodeValid(-1);

      setSalesOfficeCode(salesOffice);
      onSectionValueSet(
        {
          salesOfficeCd: salesOffice,
        },
        basicSiteDetails?.salesOfficeCd !== salesOffice
      );
    };

    const onNeraRiskNameChange = (event: DropDownListChangeEvent) => {
      const neraRisk = event?.target?.value;
      setIsNERARiskNameValid(1);
      setNeraRiskNameSelectedValue(neraRisk);
      onSectionValueSet(
        {
          neraRiskCd: {
            neraRiskCd: neraRisk?.id?.toString(),
          },
        },
        basicSiteDetails?.neraRiskCd?.neraRiskCd !== event.target.value.id
      );
    };

    const onNERACertificationDateChange = (event: InputChangeEvent) => {
      const neraDate: any = event?.value;
      setIsNERACertificationDateValid(1);
      setNERACertificationDate(
        neraDate ? format(new Date(neraDate), AppConst.VIEW_DATE_FORMAT) : null
      );
      const date: number = neraDate?.getDate() || 0;
      const month: number = neraDate?.getMonth() || 0;
      const year: number = neraDate?.getFullYear() || 2000;
      const formattedUTCTime = formatDate(new Date(year, month, date), "s");
      onSectionValueSet(
        {
          neraCertificationDate: formattedUTCTime?.toString(),
        },
        basicSiteDetails?.neraCertificationDate !== formattedUTCTime
      );
    };

    useEffect(() => {
      if ((isMasterDataLoading || isOrgAffiliationCodesLoading) && isEditing) {
        setShowLoader(true);
      } else {
        setShowLoader(false);
      }
    }, [isMasterDataLoading, isOrgAffiliationCodesLoading]);

    useEffect(() => {
      if (
        basicSiteDetails &&
        siteStatusCodes &&
        countrySelectedValue &&
        siteTypeSelectedValue &&
        isEditing
      ) {
        setStatusTypeList([...siteStatusCodes]);
        if (
          siteTypeSelectedValue?.id ===
            SiteTypeConst.SHELL_IN_NETWORK_RETAIL_SITE ||
          siteTypeSelectedValue?.id === SiteTypeConst.SHELL_COMMERCIAL_SITE
        ) {
          const isGSAP = ValidateEditSite.isGSAPSite(
            countrySelectedValue,
            siteTypeSelectedValue
          );
          if (isGSAP) {
            onClassifiedSiteTypeChange(AppConst.GSAP_SITE_TYPE);
            const GSAP_SITE_TYPES = [...siteStatusCodes].filter(
              (type) => type.id !== SiteStatusConst.NOT_SPECIFIED
            );
            setStatusTypeList([...GSAP_SITE_TYPES]);
          }
          const isNonGSAP = ValidateEditSite.isNonGSAPSite(
            countrySelectedValue,
            siteTypeSelectedValue
          );
          if (isNonGSAP) {
            onClassifiedSiteTypeChange(AppConst.NON_GSAP_SITE_TYPE);
          }
        } else if (
          siteTypeSelectedValue?.id === SiteTypeConst.COMPETITOR_SITE
        ) {
          onClassifiedSiteTypeChange(AppConst.COMPETITOR_SITE_TYPE);
        } else if (
          siteTypeSelectedValue?.id === SiteTypeConst.SHELL_LICENSED_MARKET_SITE
        ) {
          onClassifiedSiteTypeChange(AppConst.RBLA_SITE_TYPE);
        } else if (
          siteTypeSelectedValue?.id === SiteTypeConst.SHELL_CARD_PARTNER_SITE
        ) {
          onClassifiedSiteTypeChange(AppConst.PARTNER_SITE_TYPE);
        }
      } else if (basicSiteDetails) {
        if (
          basicSiteDetails?.siteTypeCd?.siteTypeCd ===
            SiteTypeConst.SHELL_IN_NETWORK_RETAIL_SITE ||
          basicSiteDetails?.siteTypeCd?.siteTypeCd ===
            SiteTypeConst.SHELL_COMMERCIAL_SITE
        ) {
          const isGSAP = ValidateEditSite.isGSAPSite(
            {
              id: basicSiteDetails?.countryCd?.countryCd,
              value: basicSiteDetails?.countryCd?.countryName,
            },
            {
              id: basicSiteDetails?.siteTypeCd?.siteTypeCd,
              value: basicSiteDetails?.siteTypeCd?.siteTypeName,
            }
          );
          if (isGSAP) {
            onClassifiedSiteTypeChange(AppConst.GSAP_SITE_TYPE);
          }
          const isNonGSAP = ValidateEditSite.isNonGSAPSite(
            {
              id: basicSiteDetails?.countryCd?.countryCd,
              value: basicSiteDetails?.countryCd?.countryName,
            },
            {
              id: basicSiteDetails?.siteTypeCd?.siteTypeCd,
              value: basicSiteDetails?.siteTypeCd?.siteTypeName,
            }
          );
          if (isNonGSAP) {
            onClassifiedSiteTypeChange(AppConst.NON_GSAP_SITE_TYPE);
          }
        } else if (
          basicSiteDetails?.siteTypeCd?.siteTypeCd ===
          SiteTypeConst.COMPETITOR_SITE
        ) {
          onClassifiedSiteTypeChange(AppConst.COMPETITOR_SITE_TYPE);
        } else if (
          basicSiteDetails?.siteTypeCd?.siteTypeCd ===
          SiteTypeConst.SHELL_LICENSED_MARKET_SITE
        ) {
          onClassifiedSiteTypeChange(AppConst.RBLA_SITE_TYPE);
        } else if (
          basicSiteDetails?.siteTypeCd?.siteTypeCd ===
          SiteTypeConst.SHELL_CARD_PARTNER_SITE
        ) {
          onClassifiedSiteTypeChange(AppConst.PARTNER_SITE_TYPE);
        }
      }
    }, [
      basicSiteDetails,
      siteStatusCodes,
      countrySelectedValue,
      siteTypeSelectedValue,
      isEditing,
    ]);

    const getMinOpeningDate = () => {
      if (
        siteStatusSelectedValue?.id === SiteStatusConst.WORK_IN_PROCESS ||
        siteStatusSelectedValue?.id === SiteStatusConst.COMPLETED_NOT_OPENED
      ) {
        return new Date();
      }
      return undefined;
    };

    const getMaxOpeningDate = () => {
      const todaysDate = new Date();
      if (siteStatusSelectedValue?.id === SiteStatusConst.ACTIVE) {
        return todaysDate;
      } else if (
        siteStatusSelectedValue?.id === SiteStatusConst.CLOSED_TEMPORARILY ||
        siteStatusSelectedValue?.id === SiteStatusConst.CLOSED_PERMANENTLY
      ) {
        todaysDate.setDate(todaysDate.getDate() - 1);
        return todaysDate;
      }
      return undefined;
    };

    const getMinClosingDate = () => {
      const todaysDate = new Date();
      if (siteStatusSelectedValue?.id === SiteStatusConst.ACTIVE) {
        todaysDate.setDate(todaysDate.getDate() + 1);
        return todaysDate;
      } else if (
        siteStatusSelectedValue?.id === SiteStatusConst.CLOSED_TEMPORARILY ||
        siteStatusSelectedValue?.id === SiteStatusConst.CLOSED_PERMANENTLY
      ) {
        if (siteOpeningDate) {
          const openingDate = new Date(siteOpeningDate);
          openingDate.setDate(openingDate?.getDate() + 1);
          return openingDate;
        }
        return undefined;
      }
      if (siteOpeningDate) {
        const openingDate = new Date(siteOpeningDate);
        openingDate.setDate(openingDate?.getDate() + 1);
        return openingDate;
      }
      return undefined;
    };

    const getMaxClosingDate = () => {
      if (
        siteStatusSelectedValue?.id === SiteStatusConst.CLOSED_TEMPORARILY ||
        siteStatusSelectedValue?.id === SiteStatusConst.CLOSED_TEMPORARILY
      ) {
        return new Date();
      }
      return undefined;
    };

    useEffect(() => {
      if (operatingPlatformCodes) {
        setOperatingPlatformCodesList(operatingPlatformCodes);
      }
    }, [operatingPlatformCodes]);

    const onSiteNameBlur = () => {
      if (siteName) {
        setIsSiteNameValid(1);
      } else {
        setIsSiteNameValid(-1);
      }
    };

    const onLatitudeBlur = () => {
      if (
        siteStatusSelectedValue?.id === SiteStatusConst.ACTIVE &&
        (siteTypeSelectedValue?.id ===
          SiteTypeConst.SHELL_IN_NETWORK_RETAIL_SITE ||
          siteTypeSelectedValue?.id === SiteTypeConst.SHELL_COMMERCIAL_SITE ||
          siteTypeSelectedValue?.id ===
            SiteTypeConst.SHELL_LICENSED_MARKET_SITE) &&
        !latitude
      ) {
        setIsLatitudeValid(-1);
        setLatitudeValidationText(Locale.common?.required_field);
      } else if (latitude) {
        if (
          (classifiedSiteType === AppConst.GSAP_SITE_TYPE ||
            classifiedSiteType === AppConst.NON_GSAP_SITE_TYPE) &&
          !AppConst.SHELL_LATITUDE_REGULAR_EXP?.test(latitude)
        ) {
          setIsLatitudeValid(-1);
          setLatitudeValidationText(
            Locale.create_site?.error_messages?.invalid_latitude_for_shell_sites
          );
        } else if (!AppConst.NON_SHELL_LATITUDE_REGULAR_EXP?.test(latitude)) {
          setIsLatitudeValid(-1);
          setLatitudeValidationText(
            Locale.create_site?.error_messages
              ?.invalid_latitude_for_non_shell_sites
          );
        } else {
          onLocationChange({ latitude });
        }
      }
    };

    const onLongitudeBlur = () => {
      if (
        siteStatusSelectedValue?.id === SiteStatusConst.ACTIVE &&
        (siteTypeSelectedValue?.id ===
          SiteTypeConst.SHELL_IN_NETWORK_RETAIL_SITE ||
          siteTypeSelectedValue?.id === SiteTypeConst.SHELL_COMMERCIAL_SITE ||
          siteTypeSelectedValue?.id ===
            SiteTypeConst.SHELL_LICENSED_MARKET_SITE) &&
        !longitude
      ) {
        setIsLongitudeValid(-1);
        setLongitudeValidationText(Locale.common?.required_field);
      } else if (longitude) {
        if (
          classifiedSiteType === AppConst.GSAP_SITE_TYPE ||
          classifiedSiteType === AppConst.NON_GSAP_SITE_TYPE
        ) {
          if (!AppConst.SHELL_LONGITUDE_REGULAR_EXP?.test(longitude)) {
            setIsLongitudeValid(-1);
            setLongitudeValidationText(
              Locale.create_site?.error_messages
                ?.invalid_longitude_for_shell_sites
            );
          }
        } else if (!AppConst.NON_SHELL_LONGITUDE_REGULAR_EXP?.test(longitude)) {
          setIsLongitudeValid(-1);
          setLongitudeValidationText(
            Locale.create_site?.error_messages
              ?.invalid_longitude_for_non_shell_sites
          );
        } else {
          onLocationChange({ longitude });
        }
      }
    };

    useEffect(() => {
      if (isCreate) {
        if (
          (classifiedSiteType === AppConst.GSAP_SITE_TYPE ||
            classifiedSiteType === AppConst.NON_GSAP_SITE_TYPE ||
            classifiedSiteType === AppConst.COMPETITOR_SITE_TYPE) &&
          siteStatusSelectedValue?.id === SiteStatusConst.ACTIVE &&
          (countrySelectedValue?.id === ContryConst.CHINA ||
            countrySelectedValue?.id === ContryConst.GERMANY ||
            countrySelectedValue?.id === ContryConst.AUSTRIA ||
            countrySelectedValue?.id === ContryConst.SWITZERLAND ||
            countrySelectedValue)
        ) {
          const fpsValue = fpsIndicatorCodes?.find(
            (fps: { id: string; value: string }) => fps?.id === "N"
          );
          setFPSIndicatorSelectedValue(fpsValue);
          onSectionValueSet(
            {
              fpsInd: fpsValue?.value?.toString(),
            },
            basicSiteDetails?.fpsInd !== fpsValue?.value
          );
        } else if (
          (classifiedSiteType === AppConst.GSAP_SITE_TYPE ||
            classifiedSiteType === AppConst.NON_GSAP_SITE_TYPE ||
            classifiedSiteType === AppConst.COMPETITOR_SITE_TYPE) &&
          siteStatusSelectedValue?.id === SiteStatusConst.ACTIVE
        ) {
          const fpsValue = fpsIndicatorCodes?.find(
            (fps: { id: string; value: string }) => fps?.id === "Y"
          );
          setFPSIndicatorSelectedValue(fpsValue);
          onSectionValueSet(
            {
              fpsInd: fpsValue?.value?.toString(),
            },
            basicSiteDetails?.fpsInd !== fpsValue?.value
          );
        } else {
          setFPSIndicatorSelectedValue(undefined);
        }
      }
    }, [siteStatusSelectedValue, countrySelectedValue]);

    useEffect(() => {
      if (
        isCreate &&
        (classifiedSiteType === AppConst.GSAP_SITE_TYPE ||
          AppConst.NON_GSAP_SITE_TYPE)
      ) {
        const otherShopType = crSiteShopTypeCodes?.find(
          (shop: { id: string; value: string }) => +shop.id === 3
        );
        setCrSiteShopTypeSelectedValue(otherShopType);
      }
    }, [classifiedSiteType, isCreate]);

    useEffect(() => {
      if (isCreate) {
        if (
          siteStatusSelectedValue?.id === SiteStatusConst.ACTIVE &&
          classifiedSiteType === AppConst.RBLA_SITE_TYPE
        ) {
          const orgAff = orgAffiliationCodes?.find(
            (affiliation: { id: string }) =>
              affiliation?.id === OrgAffiliationConst.SHELL
          );
          setOrgAffiliationSelectedValue(orgAff);
          onSectionValueSet(
            {
              orgAffiliationCd: {
                orgAffiliationCd: orgAff?.id?.toString(),
              },
            },
            basicSiteDetails?.orgAffiliationCd?.orgAffiliationCd !== orgAff.id
          );
        }
      }
    }, [siteStatusSelectedValue, isCreate, classifiedSiteType]);

    useEffect(() => {
      if (isCreate) {
        if (
          orgAffiliationSelectedValue?.id === OrgAffiliationConst.SHELL ||
          orgAffiliationSelectedValue?.id ===
            OrgAffiliationConst.Shell_EXPRESS ||
          orgAffiliationSelectedValue?.id === OrgAffiliationConst.SHELL_MOTORWAY
        ) {
          const orgAffType = orgAffiliationTypeCodes?.find(
            (affiliationType: { id: string }) =>
              affiliationType?.id === OrgAffiliationTypeConst.MAJOR_OIL
          );
          setOrgAffiliationTypeSelectedValue(orgAffType);
          onSectionValueSet(
            {
              orgAffiliationTypeCd: {
                orgAffiliationTypeCd: orgAffType?.id?.toString(),
              },
            },
            basicSiteDetails?.orgAffiliationTypeCd?.orgAffiliationTypeCd !==
              orgAffType?.id
          );
        }
      }
    }, [isCreate, orgAffiliationSelectedValue]);

    const getBasicSiteData = () => {
      return [
        //GSAP NGSAP RBLA	Competitor Partner
        //Level 1	Level 3	Level 3	Level 3	Level 3
        //Mandatory
        {
          placeholder:
            Locale.landing_page?.sites_list.table_headers.site_name_label,
          label: "",
          value: basicSiteDetails?.siteName?.toUpperCase(),
          editType: EditFieldTypeConst.TEXT_FIELD,
          editValue: siteName?.toUpperCase(),
          classValue: `mdm--sd-bsd-site-name ${!isEditing && "display-none"}`,
          onChange: onSiteNameChange,
          onBlur: onSiteNameBlur,
          isVisible: true,
          isDisabled: classifiedSiteType === AppConst.GSAP_SITE_TYPE,

          maxLength: (roleName === RoleConst.RBLA_GLOBAL_SME ||
            roleName === RoleConst.RBLA_LOCAL_SME) ? 100 : 35,
          isValid: isSiteNameValid !== -1,
          validationMessage: Locale.common?.required_field,
          required: true,
        },
        //GSAP NGSAP RBLA	Competitor Partner
        //Level 2	Level 3	Level 3	Level 3	Level 3
        //Site type will not be editable, Confirmed by 'Namrata'
        //Mandatory
        {
          label: Locale.landing_page?.sites_list.table_headers.site_type_label,
          value: basicSiteDetails?.siteTypeCd?.siteTypeName?.trim(),
          classValue: "mdm--sd-bsd-site-type",
          editType: EditFieldTypeConst.DROPDOWN_FIELD,
          isEditDataLoading: isMasterDataLoading,
          onChange: onSiteTypeChange,
          // editData: siteTypeCodes || [], // for local dev purpose only
          // isDisabled: false, // for local dev purpose only
          editData: siteTypeList || [],
          isDisabled: isCreate ? false : true,
          editValue: siteTypeSelectedValue,
          isVisible: true,
          isValid: isSiteTypeValid !== -1,
          validationMessage: Locale.common?.required_field,
          required: true,
        },
        //GSAP NGSAP RBLA	Competitor Partner
        //Level 3	Level 3	Level 3	Level 3	Level 3
        //Mandatory with default value "Not Specified"
        {
          label: Locale.landing_page?.sites_list.table_headers.status_label,
          value: basicSiteDetails?.siteStatusCd?.siteStatusName,
          editType: EditFieldTypeConst.DROPDOWN_FIELD,
          editValue: siteStatusSelectedValue,
          classValue: `mdm--sd-column-value mdm--sd-bsd-status-tag ${basicSiteDetails?.siteStatusCd?.siteStatusName
            ?.toLowerCase()
            .replace(/ /g, "_")
            .replace(/\//g, "_")}`,
          onChange: onSiteStatusChange,
          editData: statusTypeList || [],
          isEditDataLoading: isMasterDataLoading,
          isVisible: true,
          isDisabled: false,
          isValid: isSiteStatusValid !== -1,
          validationMessage: Locale.common?.required_field,
          required: true,
        },
        //GSAP NGSAP RBLA	Competitor Partner
        //Level 1	Level 3	Level 3	Level 3	Level 3
        //Optional
        {
          label:
            Locale.site_details?.retail_admin_update.basic_details
              .site_short_name,
          value: basicSiteDetails?.siteShortName?.toUpperCase(),
          editType: EditFieldTypeConst.TEXT_FIELD,
          editValue: siteShortName,
          onChange: onSiteShortNameChange,
          isVisible: true,
          isDisabled: classifiedSiteType === AppConst.GSAP_SITE_TYPE,
          maxLength: 20,
          required: false,
        },
        //GSAP NGSAP RBLA	Competitor Partner
        //Level 0	Level 2	Level 2	Level 2	Level 2
        {
          label:
            Locale.site_details?.retail_admin_update.basic_details
              .global_side_id,
          value: basicSiteDetails?.globalSiteId,
          classValue: "mdm--sd-bsd-site-id",
          editType: EditFieldTypeConst.TEXT_FIELD,
          isDisabled: true,
          isVisible: isCreate ? false : true,
          editValue: basicSiteDetails?.globalSiteId,
        },
        //GSAP NGSAP RBLA	Competitor Partner
        //Level 2	Level 3	Level 2	Level 2	Level 2
        //Mandatory for active sites
        {
          label:
            Locale.site_details?.retail_admin_update.basic_details
              .operating_platform,
          value: basicSiteDetails?.operatingPlatformCd?.operatingPlatformName,
          classValue: "border-top",
          editType: EditFieldTypeConst.DROPDOWN_FIELD,
          isEditDataLoading: isMasterDataLoading,
          onChange: onOperatingPlatformChange,
          editData: operatingPlatformCodesList || [],
          editValue: operatingPlatformSelectedValue,
          isVisible: true,
          isDisabled: isCreate
            ? classifiedSiteType === AppConst.RBLA_SITE_TYPE ||
              classifiedSiteType === AppConst.COMPETITOR_SITE_TYPE
              ? true
              : false
            : classifiedSiteType &&
              classifiedSiteType !== AppConst.NON_GSAP_SITE_TYPE
            ? true
            : false,
          isValid: isOperatingPlatformValid !== -1,
          validationMessage: operatingPlatofrmValidationText,
          required: siteStatusSelectedValue?.id === SiteStatusConst.ACTIVE,
        },
        //GSAP NGSAP RBLA	Competitor Partner
        //Level 1	Level 3	Level 3	Level 3	Level 3
        //Mandatory for GSAP, Non-GSAP, RBLA active sites,Optional for Competitor and Partner sites
        {
          label:
            Locale.site_details?.retail_admin_update.basic_details.latitude,
          value: basicSiteDetails?.latitude,
          editType: EditFieldTypeConst.TEXT_FIELD,
          editValue: latitude,
          onChange: onLatitudeChange,
          onBlur: onLatitudeBlur,
          isVisible: true,
          isDisabled: classifiedSiteType === AppConst.GSAP_SITE_TYPE,
          maxLength: 20,
          isValid: isLatitudeValid !== -1,
          validationMessage: latitudeValidationText,
          required:
            siteStatusSelectedValue?.id === SiteStatusConst.ACTIVE &&
            (siteTypeSelectedValue?.id ===
              SiteTypeConst.SHELL_IN_NETWORK_RETAIL_SITE ||
              siteTypeSelectedValue?.id ===
                SiteTypeConst.SHELL_COMMERCIAL_SITE ||
              siteTypeSelectedValue?.id ===
                SiteTypeConst.SHELL_LICENSED_MARKET_SITE),
        },
        //GSAP NGSAP RBLA	Competitor Partner
        //Level 1	Level 3	Level 3	Level 3	Level 3
        //Mandatory for GSAP, Non-GSAP, RBLA active sites,Optional for Competitor and Partner sites
        {
          label:
            Locale.site_details?.retail_admin_update.basic_details.longitude,
          value: basicSiteDetails?.longitude,
          editType: EditFieldTypeConst.TEXT_FIELD,
          editValue: longitude,
          onChange: onLongitudeChange,
          onBlur: onLongitudeBlur,
          isVisible: true,
          isDisabled: classifiedSiteType === AppConst.GSAP_SITE_TYPE,
          maxLength: 20,
          isValid: isLongitudeValid !== -1,
          validationMessage: longitudeValidationText,
          required:
            siteStatusSelectedValue?.id === SiteStatusConst.ACTIVE &&
            (siteTypeSelectedValue?.id ===
              SiteTypeConst.SHELL_IN_NETWORK_RETAIL_SITE ||
              siteTypeSelectedValue?.id ===
                SiteTypeConst.SHELL_COMMERCIAL_SITE ||
              siteTypeSelectedValue?.id ===
                SiteTypeConst.SHELL_LICENSED_MARKET_SITE),
        },
        //GSAP NGSAP RBLA	Competitor Partner
        //Level 3	Level 3	Level 3	Level 3	Level 3
        //Conditional Mandatory
        //- If Site Status is Active, mandatory to provide Site Opening date
        //If opening date is already there, then field set to level 2(disabled)
        {
          label:
            Locale.site_details?.retail_admin_update.basic_details
              .site_opening_date,
          value: basicSiteDetails?.siteOpeningDate
            ? format(
                new Date(basicSiteDetails?.siteOpeningDate),
                AppConst.VIEW_DATE_FORMAT
              )
            : null,
          editType: EditFieldTypeConst.DATE_FIELD,
          editValue: siteOpeningDate,
          onChange: onSiteOpeningDateChange,
          isEditDataLoading: false,
          isVisible: true,
          isDisabled: basicSiteDetails?.siteOpeningDate ? true : false,
          isValid: isOpeningDateValid !== -1,
          validationMessage: openingDateValidationText,
          minDate: getMinOpeningDate(),
          maxDate: getMaxOpeningDate(),
          required: siteStatusSelectedValue?.id === SiteStatusConst.ACTIVE,
        },
        //GSAP NGSAP RBLA	Competitor Partner
        //Level 3	Level 3	Level 3	Level 3	Level 3
        //Conditional Mandatory
        //- If Closed Permanently/Temporarily mandatory to provide Site Closing date
        {
          label:
            Locale.site_details?.retail_admin_update.basic_details
              .site_closing_date,
          value: basicSiteDetails?.siteClosingDate
            ? format(
                new Date(basicSiteDetails?.siteClosingDate),
                AppConst.VIEW_DATE_FORMAT
              )
            : null,
          editType: EditFieldTypeConst.DATE_FIELD,
          editValue: siteClosingDate,
          isEditDataLoading: false,
          onChange: onSiteClosingDateChange,
          isVisible: true,
          isDisabled: false,
          isValid: isClosingDateValid !== -1,
          validationMessage: closingDateValidationText,
          minDate: getMinClosingDate(),
          maxDate: getMaxClosingDate(),
          required:
            siteStatusSelectedValue?.id ===
              SiteStatusConst.CLOSED_TEMPORARILY ||
            siteStatusSelectedValue?.id === SiteStatusConst.CLOSED_PERMANENTLY,
        },
        //GSAP NGSAP RBLA	Competitor Partner
        //Level 2/3	Level 2/3	Level 3 .	Not Applicable	Not Applicable
        //**Refer category validation */
        //Product at site is Level 3 for GSAP NGSAP and RBLA
        {
          label:
            Locale.site_details?.retail_admin_update.basic_details
              .site_category,
          value: basicSiteDetails?.siteCategoryCd?.siteCategoryCd,
          editType: EditFieldTypeConst.DROPDOWN_FIELD,
          isEditDataLoading: isMasterDataLoading,
          onChange: onSiteCategoryChange,
          editData: siteCategoryCodes || [],
          editValue: siteCategorySelectedValue,
          isVisible:
            classifiedSiteType === AppConst.GSAP_SITE_TYPE ||
            classifiedSiteType === AppConst.NON_GSAP_SITE_TYPE ||
            classifiedSiteType === AppConst.RBLA_SITE_TYPE,
          isDisabled: false,
          isValid: isCategoryValid !== -1,
          validationMessage: Locale.common?.required_field,
          required:
            (siteTypeSelectedValue?.id ===
              SiteTypeConst.SHELL_IN_NETWORK_RETAIL_SITE ||
              siteTypeSelectedValue?.id ===
                SiteTypeConst.SHELL_COMMERCIAL_SITE ||
              siteTypeSelectedValue?.id ===
                SiteTypeConst.SHELL_LICENSED_MARKET_SITE) &&
            siteStatusSelectedValue?.id === SiteStatusConst.ACTIVE,
        },
        {
          label:
            Locale.site_details?.retail_admin_update.basic_details.usa_company,
          value: basicSiteDetails?.usaCompanyCd?.usaCompanyDesc,
          editType: EditFieldTypeConst.DROPDOWN_FIELD,
          onChange: onUSACompanyChange,
          editData: usaCompanyCodes || [],
          editValue: usaCompanySelectedValue,
          isValid: true,
          validationMessage: Locale.common?.required_field,
          isEditDataLoading: isUSACompanyCodesLoading,
          isVisible:
            classifiedSiteType === AppConst.GSAP_SITE_TYPE ||
            classifiedSiteType === AppConst.NON_GSAP_SITE_TYPE,
          isDisabled: false,
          required: false,
        },
        //GSAP NGSAP RBLA	Competitor Partner
        //Level 3	Level 3	Level 3	Not Applicable	Not Applicable
        //Mandatory for CO active sites
        {
          label: Locale.site_details?.retail_admin_update.basic_details.re_type,
          value: basicSiteDetails?.tenureTypeCd?.tenureTypeName,
          editType: EditFieldTypeConst.DROPDOWN_FIELD,
          isEditDataLoading: isMasterDataLoading,
          onChange: onSiteTenureTypeChange,
          editData: tenureTypeCodes || [],
          editValue: siteTenureTypeSelectedValue,
          isValid: isRETypeValid !== -1,
          validationMessage: Locale.common?.required_field,
          isVisible:
            classifiedSiteType === AppConst.GSAP_SITE_TYPE ||
            classifiedSiteType === AppConst.NON_GSAP_SITE_TYPE ||
            classifiedSiteType === AppConst.RBLA_SITE_TYPE,
          isDisabled: false,
          required:
            (siteTypeSelectedValue?.id ===
              SiteTypeConst.SHELL_IN_NETWORK_RETAIL_SITE ||
              siteTypeSelectedValue?.id ===
                SiteTypeConst.SHELL_COMMERCIAL_SITE) &&
            siteStatusSelectedValue?.id === SiteStatusConst.ACTIVE &&
            AppConst.COMPANY_OWNED_RE_TYPE_LIST?.includes(
              operatingPlatformSelectedValue?.value
            ),
        },
        //GSAP NGSAP RBLA	Competitor Partner
        //Level 3	Not Applicable	Not Applicable	Not Applicable	Not Applicable
        //Optional
        {
          label:
            Locale.site_details?.retail_admin_update.basic_details.market_area,
          value: basicSiteDetails?.marketArea,
          editType: EditFieldTypeConst.TEXT_FIELD,
          onChange: onMarketAreaChange,
          editValue: marketArea,
          isVisible: classifiedSiteType === AppConst.GSAP_SITE_TYPE,
          isDisabled: false,
          maxLength: 100,
        },
        //GSAP NGSAP RBLA	Competitor Partner
        //Level 1	Level 3	Level 3	Level 3	Level 3
        //Country can't be changed, Confirmed with 'Namrata'
        //Mandatory
        {
          label:
            Locale.site_details?.retail_admin_update.basic_details.country_name,
          value: basicSiteDetails?.countryCd?.countryName,
          classValue: "border-top",
          editType: EditFieldTypeConst.DROPDOWN_FIELD,
          isEditDataLoading: isMasterDataLoading,
          onChange: onCountryChange,
          editData: countryList,
          editValue: countrySelectedValue,
          filterable: true,
          filterChange: countryFilterChange,
          isValid: isCountryValid !== -1,
          validationMessage: Locale.common?.required_field,
          isDisabled: isCreate ? false : true,
          isVisible: true,
          required: true,
        },
        //GSAP NGSAP RBLA	Competitor Partner
        //Level 1	Level 3	Level 3	Level 3	Level 3
        //Optional
        {
          label:
            Locale.site_details?.retail_admin_update.basic_details
              .territory_name,
          value: basicSiteDetails?.territoryCd?.territoryName,
          editType: EditFieldTypeConst.DROPDOWN_FIELD,
          isEditDataLoading: isTerritoryCodesLoading,
          onChange: onTerritoryChange,
          editData: territoryList,
          editValue: territorySelectedValue,
          filterable: true,
          filterChange: territoryFilterChange,
          isDisabled: classifiedSiteType === AppConst.GSAP_SITE_TYPE,
          isVisible: true,
          required: false,
        },
        //GSAP NGSAP RBLA	Competitor Partner
        //Level 1	Level 3	Level 3	Level 3	Level 3
        //Optional
        {
          label:
            Locale.site_details?.retail_admin_update.basic_details.county_name,
          value: basicSiteDetails?.countyCd?.countyName,
          editType: EditFieldTypeConst.DROPDOWN_FIELD,
          isEditDataLoading: isCountyCodesLoading,
          onChange: onCountyChange,
          editData: countyList,
          editValue: countySelectedValue,
          filterable: true,
          filterChange: countyFilterChange,
          isDisabled: classifiedSiteType === AppConst.GSAP_SITE_TYPE,
          isVisible: true,
          required: false,
        },
        //GSAP NGSAP RBLA	Competitor Partner
        //Level 1	Level 3	Level 3	Level 3	Level 3
        //Mandatory
        {
          label:
            Locale.site_details?.retail_admin_update.basic_details.city_name,
          value: basicSiteDetails?.cityName,
          editType: EditFieldTypeConst.TEXT_FIELD,
          onChange: onCityChange,
          editValue: cityName,
          isValid: isCityValid !== -1,
          validationMessage: Locale.common?.required_field,
          isDisabled: classifiedSiteType === AppConst.GSAP_SITE_TYPE,
          isVisible: true,
          maxLength: 50,
          required: true,
        },
        //GSAP NGSAP RBLA	Competitor Partner
        //Level 1	Level 3	Level 3	Level 3	Level 3
        //35 char max
        {
          label:
            Locale.site_details?.retail_admin_update.basic_details
              .street_address,
          value: basicSiteDetails?.streetAddress,
          editType: EditFieldTypeConst.TEXT_FIELD,
          onChange: onStreetAddressChange,
          editValue: streetAddress,
          isValid: isStreetAddressValid !== -1,
          validationMessage: Locale.common?.required_field,
          isDisabled: classifiedSiteType === AppConst.GSAP_SITE_TYPE,
          isVisible: true,
          maxLength: (roleName === RoleConst.RBLA_GLOBAL_SME ||
            roleName === RoleConst.RBLA_LOCAL_SME) ? 150 : 35,
          required: true,
        },
        //GSAP NGSAP RBLA	Competitor Partner
        //Level 1	Level 3	Level 3	Level 3	Level 3
        //Optional
        {
          label:
            Locale.site_details?.retail_admin_update.basic_details.postal_code,
          value: basicSiteDetails?.postalCode,
          editType: EditFieldTypeConst.TEXT_FIELD,
          isDisabled: classifiedSiteType === AppConst.GSAP_SITE_TYPE,
          isVisible: true,
          onChange: onPostalCodeChange,
          editValue: postalCode,
          isValid: isPostalCodeValid !== -1,
          validationMessage: Locale.common?.required_field,
          maxLength: 20,
          required: false,
        },
        //GSAP NGSAP RBLA	Competitor Partner
        //Level 1	Level 3	Level 3	Level 3	Not Applicable
        //Optional
        {
          label:
            Locale.site_details?.retail_admin_update.basic_details
              .country_dialling_code,
          value: basicSiteDetails?.countryDiallingCd?.countryDiallingCd,
          editType: EditFieldTypeConst.TEXT_FIELD,
          isEditDataLoading: isMasterDataLoading,
          onChange: onCountryDiallingCodeChange,
          editValue: countryDiallingCode,
          isVisible: true,
          isDisabled: true,
          maxLength: 100,
          required: false,
        },
        //GSAP NGSAP RBLA	Competitor Partner
        //Level 1	Level 3	Level 3	Level 3	Not Applicable
        //Optional
        //Telephone no is there for partner sites
        {
          label:
            Locale.site_details?.retail_admin_update.basic_details
              .telephone_number,
          value: basicSiteDetails?.telephoneNo,
          editType: EditFieldTypeConst.TEXT_FIELD,
          onChange: onTelephoneNumberChange,
          editValue: telephoneNumber,
          isValid: isTelephoneNoValid !== -1,
          validationMessage:
            Locale.create_site?.error_messages?.invalid_telephone_no,
          isDisabled: isCreate
            ? false
            : classifiedSiteType === AppConst.GSAP_SITE_TYPE ||
              classifiedSiteType === AppConst.PARTNER_SITE_TYPE
            ? true
            : false,
          isVisible: true,
          maxLength: 100,
          required: false,
        },
        //GSAP NGSAP RBLA	Competitor Partner
        //Level 1	Level 3	Not Applicable	Not Applicable	Not Applicable
        //Mandatory for GSAP active sites
        {
          label:
            Locale.site_details?.retail_admin_update.basic_details
              .sales_organisation_name,
          value: basicSiteDetails?.salesOrgCd,
          classValue: "border-top",
          editType: EditFieldTypeConst.TEXT_FIELD,
          onChange: onSalesOrganisationNameChange,
          editValue: salesOrganisationName,
          isValid: isSalesOrgNameValid !== -1,
          validationMessage: Locale.common?.required_field,
          isDisabled: classifiedSiteType === AppConst.GSAP_SITE_TYPE,
          isVisible:
            classifiedSiteType === AppConst.GSAP_SITE_TYPE ||
            classifiedSiteType === AppConst.NON_GSAP_SITE_TYPE,
          maxLength: classifiedSiteType === AppConst.GSAP_SITE_TYPE ? 4 : 20,
          required:
            (siteTypeSelectedValue?.id ===
              SiteTypeConst.SHELL_IN_NETWORK_RETAIL_SITE ||
              siteTypeSelectedValue?.id ===
                SiteTypeConst.SHELL_COMMERCIAL_SITE) &&
            siteStatusSelectedValue?.id === SiteStatusConst.ACTIVE &&
            classifiedSiteType === AppConst.GSAP_SITE_TYPE,
        },
        //GSAP NGSAP RBLA	Competitor Partner
        //Level 3	Level 3	Level 3	Level 3	Not Applicable
        //Mandatory for active sites
        {
          label:
            Locale.site_details?.retail_admin_update.basic_details
              .fps_indicator,
          value: basicSiteDetails?.fpsInd,
          editType: EditFieldTypeConst.DROPDOWN_FIELD,
          onChange: onFPSIndicatorChange,
          editData: fpsIndicatorCodes || [],
          editValue: fpsIndicatorSelectedValue,
          isValid: isFPSIndicatorValid !== -1,
          validationMessage: Locale.common?.required_field,
          isDisabled: false,
          isVisible:
            classifiedSiteType === AppConst.GSAP_SITE_TYPE ||
            classifiedSiteType === AppConst.NON_GSAP_SITE_TYPE ||
            classifiedSiteType === AppConst.RBLA_SITE_TYPE ||
            classifiedSiteType === AppConst.COMPETITOR_SITE_TYPE,
          required:
            siteStatusSelectedValue?.id === SiteStatusConst.ACTIVE &&
            (siteTypeSelectedValue?.id ===
              SiteTypeConst.SHELL_IN_NETWORK_RETAIL_SITE ||
              siteTypeSelectedValue?.id ===
                SiteTypeConst.SHELL_COMMERCIAL_SITE ||
              siteTypeSelectedValue?.id === SiteTypeConst.COMPETITOR_SITE),
        },
        //GSAP NGSAP RBLA	Competitor Partner
        //Level 3	Level 3	Level 3	Not Applicable	Not Applicable
        //Mandatory for CO active sites
        {
          label:
            Locale.site_details?.retail_admin_update.basic_details
              .cr_site_shop_type_name,
          value: basicSiteDetails?.crSiteShopTypeCd?.crSiteShopTypeName,
          editType: EditFieldTypeConst.DROPDOWN_FIELD,
          isEditDataLoading: isMasterDataLoading,
          onChange: onCrSiteShopTypeChange,
          editData: crSiteShopTypeCodes || [],
          editValue: crSiteShopTypeSelectedValue,
          isValid: isCRSiteShopTypeValid !== -1,
          validationMessage: Locale.common?.required_field,
          isDisabled: false,
          isVisible:
            classifiedSiteType === AppConst.GSAP_SITE_TYPE ||
            classifiedSiteType === AppConst.NON_GSAP_SITE_TYPE ||
            classifiedSiteType === AppConst.RBLA_SITE_TYPE,
          required:
            (siteTypeSelectedValue?.id ===
              SiteTypeConst.SHELL_IN_NETWORK_RETAIL_SITE ||
              siteTypeSelectedValue?.id ===
                SiteTypeConst.SHELL_COMMERCIAL_SITE) &&
            siteStatusSelectedValue?.id === SiteStatusConst.ACTIVE &&
            AppConst.COMPANY_OWNED_RE_TYPE_LIST?.includes(
              operatingPlatformSelectedValue?.value
            ),
        },
        //GSAP NGSAP RBLA	Competitor Partner
        //Level 3	Level 3	Not Applicable	Not Applicable	Not Applicable
        //Optional
        {
          label:
            Locale.site_details?.retail_admin_update.basic_details
              .cr_format_name,
          value: basicSiteDetails?.crFormatTypeCd?.crFormatTypeName,
          editType: EditFieldTypeConst.DROPDOWN_FIELD,
          isEditDataLoading: isMasterDataLoading,
          onChange: onCrFormatTypeChange,
          editData: crFormatTypeCodes || [],
          editValue: crFormatTypeSelectedValue,
          isValid: isCRFomatNameValid !== -1,
          validationMessage: Locale.common?.required_field,
          isDisabled: false,
          isVisible:
            classifiedSiteType === AppConst.GSAP_SITE_TYPE ||
            classifiedSiteType === AppConst.NON_GSAP_SITE_TYPE,
          required: false,
        },
        //GSAP NGSAP RBLA	Competitor Partner
        //Level 1	Level 3	Level 2	Level 3	Level 3
        //Mandatory for active sites
        {
          label:
            Locale.site_details?.retail_admin_update.basic_details
              .organization_affiliation,
          value: basicSiteDetails?.orgAffiliationCd?.orgAffiliationName,
          editType: EditFieldTypeConst.DROPDOWN_FIELD,
          isEditDataLoading: isOrgAffiliationCodesLoading,
          onChange: onOrgAffiliationChange,
          editData: orgAffiliationList || [],
          editValue: orgAffiliationSelectedValue,
          filterable: true,
          filterChange: OrgAffiliationFilterChange,
          isValid: isOrgAffiliationValid !== -1,
          validationMessage: Locale.common?.required_field,
          isDisabled: isCreate
            ? false
            : classifiedSiteType === AppConst.GSAP_SITE_TYPE ||
              classifiedSiteType === AppConst.RBLA_SITE_TYPE
            ? true
            : false,
          isVisible: true,
          required: siteStatusSelectedValue?.id === SiteStatusConst.ACTIVE,
        },
        //GSAP NGSAP RBLA	Competitor Partner
        //Level 2	Level 3	Level 3	Level 3	Level 3
        //Mandatory for active sites
        {
          id: "orgAffiliation",
          label:
            Locale.site_details?.retail_admin_update.basic_details
              .organization_affiliation_type,
          value: basicSiteDetails?.orgAffiliationTypeCd?.orgAffiliationTypeName,
          editType: EditFieldTypeConst.DROPDOWN_FIELD,
          isEditDataLoading: isMasterDataLoading,
          onChange: onOrgAffiliationTypeChange,
          editData: orgAffiliationTypeCodes || [],
          editValue: orgAffiliationTypeSelectedValue,
          isValid: isOrgAffiliationTypeValid !== -1,
          validationMessage: Locale.common?.required_field,
          isDisabled: classifiedSiteType === AppConst.GSAP_SITE_TYPE,
          isVisible: true,
          required: siteStatusSelectedValue?.id === SiteStatusConst.ACTIVE,
        },
        //GSAP NGSAP RBLA	Competitor Partner
        //Level 1	Level 3	Not Applicable	Not Applicable	Not Applicable
        //Mandatory for GSAP active sites
        {
          label:
            Locale.site_details?.retail_admin_update.basic_details
              .sales_office_code,
          value: basicSiteDetails?.salesOfficeCd,
          editType: EditFieldTypeConst.TEXT_FIELD,
          onChange: onSalesOfficeCodeChange,
          editValue: salesOfficeCode,
          isValid: isSalesOfficeCodeValid !== -1,
          validationMessage: Locale.common?.required_field,
          isDisabled: classifiedSiteType === AppConst.GSAP_SITE_TYPE,
          isVisible:
            classifiedSiteType === AppConst.GSAP_SITE_TYPE ||
            classifiedSiteType === AppConst.NON_GSAP_SITE_TYPE,
          maxLength: classifiedSiteType === AppConst.GSAP_SITE_TYPE ? 4 : 20,
          required:
            siteStatusSelectedValue?.id === SiteStatusConst.ACTIVE &&
            classifiedSiteType === AppConst.GSAP_SITE_TYPE,
        },
        //GSAP NGSAP RBLA	Competitor Partner
        //Level 2	Level 2	Not Applicable	Not Applicable	Not Applicable
        //Optional
        {
          label:
            Locale.site_details?.retail_admin_update.basic_details
              .nera_risk_name,
          value: basicSiteDetails?.neraRiskCd?.neraRiskName,
          editType: EditFieldTypeConst.DROPDOWN_FIELD,
          isEditDataLoading: isMasterDataLoading,
          onChange: onNeraRiskNameChange,
          editData: neraRiskCodes || [],
          editValue: neraRiskNameSelectedValue,
          isValid: isNERARiskNameValid !== -1,
          validationMessage: Locale.common?.required_field,
          isDisabled: false,
          isVisible:
            classifiedSiteType === AppConst.GSAP_SITE_TYPE ||
            classifiedSiteType === AppConst.NON_GSAP_SITE_TYPE,
          required:
            siteStatusSelectedValue?.id === SiteStatusConst.ACTIVE &&
            (siteTypeSelectedValue?.id ===
              SiteTypeConst.SHELL_IN_NETWORK_RETAIL_SITE ||
              siteTypeSelectedValue?.id ===
                SiteTypeConst.SHELL_COMMERCIAL_SITE) &&
            AppConst.COMPANY_OWNED_RE_TYPE_LIST?.includes(
              operatingPlatformSelectedValue?.value
            ),
        },
        //GSAP NGSAP RBLA	Competitor Partner
        //Level 3	Level 3	Not Applicable	Not Applicable	Not Applicable
        //Mandatory for CO active sites
        {
          label:
            Locale.site_details?.retail_admin_update.basic_details
              .nera_certification_date,
          value: basicSiteDetails?.neraCertificationDate
            ? format(
                new Date(basicSiteDetails?.neraCertificationDate),
                AppConst.VIEW_DATE_FORMAT
              )
            : null,
          editType: EditFieldTypeConst.DATE_FIELD,
          onChange: onNERACertificationDateChange,
          editValue: neraCertificationDate,
          isValid: isNERACertificationDateValid !== -1,
          validationMessage: Locale.common?.required_field,
          isDisabled: false,
          isVisible:
            classifiedSiteType === AppConst.GSAP_SITE_TYPE ||
            classifiedSiteType === AppConst.NON_GSAP_SITE_TYPE,
          required:
            siteStatusSelectedValue?.id === SiteStatusConst.ACTIVE &&
            (siteTypeSelectedValue?.id ===
              SiteTypeConst.SHELL_IN_NETWORK_RETAIL_SITE ||
              siteTypeSelectedValue?.id ===
                SiteTypeConst.SHELL_COMMERCIAL_SITE) &&
            AppConst.COMPANY_OWNED_RE_TYPE_LIST?.includes(
              operatingPlatformSelectedValue?.value
            ),
        },
        //Visible only for GSAP sites
        //Hide for GI Access User
        //Level 1
        {
          label:
            Locale.site_details?.retail_admin_update.basic_details
              .territory_manager,
          value: basicSiteDetails?.territoryManager,
          classValue: "border-top",
          editType: EditFieldTypeConst.TEXT_FIELD,
          editValue: basicSiteDetails?.territoryManager,
          isDisabled: true,
          isVisible:
            classifiedSiteType === AppConst.GSAP_SITE_TYPE &&
            roleName !== RoleConst.GI_ACCESS_USER,
          required: false,
        },
        //Visible only for GSAP sites
        //Hide for GI Access User
        //Level 1
        {
          label:
            Locale.site_details?.retail_admin_update.basic_details
              .district_manager,
          value: basicSiteDetails?.districtManager,
          classValue: "mdm--sd-bsd-site-manager",
          editType: EditFieldTypeConst.TEXT_FIELD,
          editValue: basicSiteDetails?.districtManager,
          isDisabled: true,
          isVisible:
            classifiedSiteType === AppConst.GSAP_SITE_TYPE &&
            roleName !== RoleConst.GI_ACCESS_USER,
          required: false,
        },
      ];
    };

    return (
      <>
        {isBasicSiteDetailsLoading ? (
          <Skeleton
            className="mdm--sd-site-name-title"
            shape={"text"}
            style={{ width: "50%" }}
          />
        ) : (
          <p
            className={`mdm--sd-site-name-title ${
              isEditing && "visibility-hidden"
            }`}
            title={basicSiteDetails?.siteName?.toUpperCase()}
          >
            {basicSiteDetails?.siteName?.toUpperCase() || "Site Name"}
          </p>
        )}

        <div className="mdm--sd-site-basic-details">
          <DetailsViewComponent
            data={getBasicSiteData()}
            classValue="mdm--sd-sbd-content"
            isLoading={isBasicSiteDetailsLoading}
            isEditing={isEditing}
            isCreate={isCreate}
          />
          {showLoader && <Loader className="basic-site-details-loader" />}
        </div>
      </>
    );
  })
);
