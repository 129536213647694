import React, { useEffect, useState } from 'react';
import { DropDownListChangeEvent } from '@progress/kendo-react-dropdowns';
import {
  useCustomerMissionCodesData,
  useMasterDataSet1,
  useMasterDataSet2,
  useFloodRiskData,
  useBiodiversitySensitiveAreaData,
  useBaselineWaterStressData,
} from '../../../../../../../../hooks';
import { Locale } from '../../../../../../../../utils/localization';
import { DetailsViewComponent } from '../../../../../../../components';
import { AppConst, EditFieldTypeConst } from '../../../../../../../../constants';

interface MasterDataType {
  id: string;
  value: string;
}

export const EnvironmentalTab = (props: any) => {
  const {
    isEditing,
    basicSiteDetails,
    isBasicSiteDetailsLoading,
    onPatchRequestValueSet,
    onSectionValueSet,
    isSectionValueChanged,
    onSectionValueChanged,
    sectionEditedData,
    isCreate,
    evDetailsTabSelected
  } = props;

  const {
    isLoading: isBiodiversitySensitiveArea,
    data: BiodiversitySensitiveAreaCode,
    refetch: refetchBiodiversitySensitiveArea,
  } = useBiodiversitySensitiveAreaData();
  const {
    isLoading: isfloodriskCodeloading,
    data: floodriskCode,
    refetch: refetchfloodriskCode,
  } = useFloodRiskData();
  const {
    isLoading: isbaselinewaterstressloading,
    data: BaselineWaterStressCode,
    refetch: refetchBaselineWaterStressCode,
  } = useBaselineWaterStressData();
  const {
    isLoading: isMasterDataSet2Loading,
    data: masterDataSet2,
    refetch: refetchMasterDataSet2,
  } = useMasterDataSet2();

  const [biodiversitySensitiveAreaCodes, setBiodiversitySensitiveAreaCodes] = useState<MasterDataType[]>([]);
  const [BiodiversitySensitiveAreaSelectedValue, setBiodiversitySensitiveAreaSelectedValue] = useState<any>();
  const [BaselineWaterStressCodes, setBaselineWaterStressCodes] = useState<MasterDataType[]>([]);
  const [BaselineWaterStressSelectedValue, setBaselineWaterStressSelectedValue] = useState<any>();
  const [floodRiskCodes, setfloodRiskCodes] = useState<MasterDataType[]>([]);
  const [floodRiskSelectedValue, setfloodRiskSelectedValue] = useState<any>();

  const onBiodiversitySensitiveAreaChange = (event: DropDownListChangeEvent) => {
    onSectionValueChanged(true);
    const biodiversitySensitiveArea = event.target.value;
    setBiodiversitySensitiveAreaSelectedValue(biodiversitySensitiveArea);
    onSectionValueSet({
      BiodiversitySensitiveAreaSelectedValue: biodiversitySensitiveArea,
    });
    onPatchRequestValueSet(
      {
        biodiversitySensitiveAreaCd: {
          biodiversitySensitiveAreaCd: biodiversitySensitiveArea?.id,
        },
      },
      basicSiteDetails?.biodiversitySensitiveAreaCd !== biodiversitySensitiveArea?.id
    );
  };

  const onBaselineWaterStressChange = (event: DropDownListChangeEvent) => {
    onSectionValueChanged(true);
    const baselineWaterStress = event.target.value;
    setBaselineWaterStressSelectedValue(baselineWaterStress);
    onSectionValueSet({
      BaselineWaterStressSelectedValue: baselineWaterStress,
    });
    onPatchRequestValueSet(
      {
        baselineWaterStressCd: {
          baselineWaterStressCd: baselineWaterStress?.id,
        },
      },
      basicSiteDetails?.baselineWaterStressCd !== baselineWaterStress?.id
    );
  };

  const onfloodRiskChange = (event: DropDownListChangeEvent) => {
    onSectionValueChanged(true);
    const floodRisk = event.target.value;
    setfloodRiskSelectedValue(floodRisk);
    onSectionValueSet({
      floodRiskSelectedValue: floodRisk,
    });
    debugger;
    onPatchRequestValueSet(
      {
        floodRiskCd: {
          floodRiskCd: floodRisk?.id,
        },
      },
      basicSiteDetails?.floodRiskCd !== floodRisk?.id
    );
  };

  useEffect(() => {
    if ( isEditing) {
      refetchBiodiversitySensitiveArea();
      refetchfloodriskCode();
      refetchBaselineWaterStressCode();
      refetchMasterDataSet2();
    }
  }, [isEditing]);

  useEffect(() => {
    if (BiodiversitySensitiveAreaCode || BaselineWaterStressCode || floodriskCode) {
      const biodiversitySensitiveAreaTypes = BiodiversitySensitiveAreaCode?.biodiversitySensitiveAreaCodes?.map((type: any) => ({
        id: type?.biodiversitySensitiveAreaCd,
        value: type?.biodiversitySensitiveAreaCd,
      })) || [];

      const baselineWaterStressTypes = BaselineWaterStressCode?.BaselineWaterStressCodes?.map((type: any) => ({
        id: type?.baselineWaterStressCd,
        value: type?.baselineWaterStressCd ,
      })) || [];

      const floodRiskTypes = floodriskCode?.floodRiskCodes?.map((type: any) => ({
        id: type?.floodRiskCd,
        value: type?.floodRiskname ,
      })) || [];

      

      setBiodiversitySensitiveAreaCodes(biodiversitySensitiveAreaTypes);
      setBaselineWaterStressCodes(baselineWaterStressTypes);
      setfloodRiskCodes(floodRiskTypes);
    }
  }, [masterDataSet2]);

  useEffect(() => {
    if ((sectionEditedData && isSectionValueChanged) || basicSiteDetails) {
      setBiodiversitySensitiveAreaSelectedValue(
        sectionEditedData?.BiodiversitySensitiveAreaSelectedValue || {
          id: basicSiteDetails?.biodiversitySensitiveAreaCd?.biodiversitySensitiveAreaCd ,
          value: basicSiteDetails?.biodiversitySensitiveAreaCd?.biodiversitySensitiveAreaCd,
        }
      );

      setBaselineWaterStressSelectedValue(
        sectionEditedData?.BaselineWaterStressSelectedValue || {
          id: basicSiteDetails?.baselineWaterStressCd?.baselineWaterStressCd,
          value: basicSiteDetails?.baselineWaterStressCd?.baselineWaterStressCd,
        }
      );

      setfloodRiskSelectedValue(
        sectionEditedData?.floodRiskSelectedValue || {
          id: basicSiteDetails?.floodRiskCd?.floodRiskCd,
          value: basicSiteDetails?.floodRiskCd?.floodRiskCd,
        }
      );
    }
  }, [sectionEditedData, basicSiteDetails]);

  
  const getEnvironmentalData = () => [
    {
      label: Locale.site_details?.ev_details.environmental.Biodiversity_Sensitive_Area,
      value: basicSiteDetails?.biodiversitySensitiveAreaCd?.biodiversitySensitiveAreaCd,
      editType: EditFieldTypeConst.DROPDOWN_FIELD,
      isEditDataLoading: isBiodiversitySensitiveArea,
      onChange: onBiodiversitySensitiveAreaChange,
      editData: BiodiversitySensitiveAreaCode || [],
      editValue: BiodiversitySensitiveAreaSelectedValue,
      isVisible: true,
    },
    {
      label: Locale.site_details?.ev_details.environmental.Baseline_Water_Stress,
      value: basicSiteDetails?.baselineWaterStressCd?.baselineWaterStressCd,
      editType: EditFieldTypeConst.DROPDOWN_FIELD,
      isEditDataLoading: isbaselinewaterstressloading,
      onChange: onBaselineWaterStressChange,
      editData: BaselineWaterStressCode || [],
      editValue: BaselineWaterStressSelectedValue,
      isVisible: true,
    },
    {
      label: Locale.site_details?.ev_details.environmental.Flood_Risk,
      value: basicSiteDetails?.floodRiskCd?.floodRiskCd,
      editType: EditFieldTypeConst.DROPDOWN_FIELD,
      isEditDataLoading: isfloodriskCodeloading,
      onChange: onfloodRiskChange,
      editData: floodriskCode || [],
      editValue: floodRiskSelectedValue,
      isVisible: true,
    },
  ];

  return (
    <DetailsViewComponent
      data={getEnvironmentalData()}
      classValue="mdm--row mdm--sd-data environmental"
      isLoading={isBasicSiteDetailsLoading}
      isEditing={isEditing}
      isCreate={isCreate}
    />
  );
};
