import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

import {
  Drawer,
  DrawerContent,
  DrawerItem,
  DrawerSelectEvent,
  DrawerItemProps,
} from "@progress/kendo-react-layout";

import AddIcon from "./../../../assets/images/icons/add-icon.svg";
import ScreenIcon from "./../../../assets/images/icons/screen-icon.svg";
import WorkflowIcon from "./../../../assets/images/icons/workflow-icon.svg";
import ContactIcon from "./../../../assets/images/icons/contact-icon.svg";
import HistoryIcon from "./../../../assets/images/icons/history-icon.svg";

import { Locale } from "../../../utils/localization";
import { DrawerItemData } from "../../../models";
import { AppConst, APP_ROUTES, RoleConst } from "../../../constants";
import { SidePanel } from "../sidepanel/SidePanel";
import { adobeAnalyticsConst } from "../../../constants/adobe-analytics.constant";

import "./DrawerRouterContainer.scss";

export const DrawerRouterContainer = (props: any) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const selectedGlobalCountry = useSelector((state: any) => {
    return state?.selectedGlobalCountry.selectedGlobalCountry;
  });
  const [expanded, setExpanded] = useState(false);

  const userDetails = useSelector((state: any) => state.auth.user);
  const roleName = userDetails?.roleDetail?.userRoleName;
  const roleDetails = userDetails?.roleDetail;
  const [showSidepanel, setShowSidepanel] = useState(false);

  const adminDrawerItems = [
    {
      text: Locale.common?.drawer_items.my_sites_label,
      selected: false,
      icon: ScreenIcon,
      route: APP_ROUTES.DASHBOARD_PATH,
      optionalpath: APP_ROUTES.SITES_PATH,
    },
    {
      text: Locale.common?.drawer_items.change_history_label,
      selected: false,
      icon: HistoryIcon,
      route: APP_ROUTES.CHANGE_HISTORY_PATH,
    },
    {
      text: Locale.common?.drawer_items.contact_us_label,
      selected: false,
      icon: ContactIcon,
      route: "/",
    },
  ];

  if (
    roleName === RoleConst.MARKET_DATA_LEAD ||
    roleName === RoleConst.SALES_OPS_SME ||
    roleName === RoleConst.GI_ACCESS_USER ||
    roleName === RoleConst.RETAILER ||
    roleName === RoleConst.FUEL_PRICING_GLOBAL_SME ||
    roleName === RoleConst.FUEL_PRICING_LOCAL_SME
  ) {
    adminDrawerItems?.splice(1, 0, {
      text: Locale.common?.drawer_items.workflow_label,
      selected: false,
      icon: WorkflowIcon,
      route: APP_ROUTES.WORKFLOW_ACTIONS_PATH,
    });
  }

  if (
    roleName &&
    (roleName === RoleConst.SALES_OPS_SME ||
      roleName === RoleConst.FUEL_PRICING_GLOBAL_SME ||
      roleName === RoleConst.FUEL_PRICING_LOCAL_SME ||
      roleName === RoleConst.RBLA_GLOBAL_SME ||
      roleName === RoleConst.RBLA_LOCAL_SME ||
      roleName === RoleConst.PARTNER_GLOBAL_SME)
  ) {
    adminDrawerItems.unshift({
      text: Locale.common?.drawer_items.create_site_label,
      selected: false,
      icon: AddIcon,
      route: APP_ROUTES.CREATE_SITE,
    });
  }

  if (roleDetails?.permission?.RSMAChangeRequestView) {
    adminDrawerItems.push({
      text: Locale.common?.drawer_items.rsma_change_request,
      selected: false,
      icon: HistoryIcon,
      route: APP_ROUTES.RSMA_CONFIGURATION_PATH,
    });
  }

  const onSelect = (event: DrawerSelectEvent) => {
    if (
      event?.itemTarget?.props?.text ===
      Locale.common?.drawer_items.contact_us_label
    ) {
      setShowSidepanel(true);
    } else {
      window.adobeDataLayer = window.adobeDataLayer || [];
      window.adobeDataLayer.push({
        event: "leftMenuClicked",
        menuClickedItem: event?.itemTarget.props.route.slice(1), // Home, Watch list, Contact Us, Reports
        menuClickArea: pathname.slice(1), // name of area where tile is clicked for example Home Screen, Reports screen,
        metric: "engage",
        page: {
          pageName: "Site Data Web | " + pathname.slice(1),
          language: adobeAnalyticsConst.language,
          platform: adobeAnalyticsConst.platform,
          countryCode: selectedGlobalCountry,
          cvp: "Site Data Web",
          siteSection: pathname.slice(1),
        },
        user: {
          userId: userDetails.userLoginId,
          loginStatus: "active",
          userType: userDetails.userType,
          customerRole: userDetails?.roleDetail?.userRoleName,
        },
      });
      navigate(event?.itemTarget.props.route);
    }
  };

  const onHideSidePanelClick = () => {
    setShowSidepanel(false);
  };

  const items = adminDrawerItems;

  const setSelectedItem = (pathName: string) => {
    let currentPath: DrawerItemData | undefined = items.find(
      (item) =>
        item.route === pathName ||
        item.optionalpath === pathname.substring(0, pathname.lastIndexOf("/"))
    );
    if (currentPath?.text) {
      return currentPath.text;
    }
  };

  let selected = setSelectedItem(pathname);

  const renderPanelContent = () => (
    <div className="mdm--sidepanel-body-content">
      {Locale?.common?.contact_us_text + " "}
      { roleName === RoleConst.RBLA_GLOBAL_SME ||
      roleName === RoleConst.RBLA_LOCAL_SME 
       ?  <a href="#">{AppConst.CONTACT_US_RBLA_LOCAL_GlOBAL_EMAIL}</a>: 
       <a href="#">{AppConst.CONTACT_US_EMAIL}</a>
      }
      {/* <a href="#">{AppConst.CONTACT_US_EMAIL}</a> */}
    </div>
  );

  const CustomItem = (props: DrawerItemProps) => {
    return (
      <div
        className={props.classvalue}
        onMouseEnter={() => {
          setExpanded(true);
        }}
        onMouseLeave={() => {
          setExpanded(false);
        }}
      >
        <DrawerItem {...props} className="mdm--drawer-item">
          <span className={"k-icon"}>
            <img src={props.icon} alt="img" />
          </span>
          <div className="item-descr-wrap">
            <div className="item-descr-text"> {props.text}</div>
          </div>
        </DrawerItem>
      </div>
    );
  };

  return (
    <div className="mdm--drawer-container">
      <Drawer
        expanded={expanded}
        position={"start"}
        mode={"push"}
        mini={true}
        items={items.map((item) => ({
          ...item,
          selected: item.text === selected,
        }))}
        item={CustomItem}
        onSelect={onSelect}
      >
        <DrawerContent>{props?.children}</DrawerContent>
      </Drawer>
      <SidePanel
        title={Locale.common?.drawer_items.contact_us_label}
        onClose={onHideSidePanelClick}
        showSidePanel={showSidepanel}
      >
        {showSidepanel && renderPanelContent()}
      </SidePanel>
    </div>
  );
};
