export const authenticationSuccess = (data: any) => ({
    type: "AUTHENTICATION_SUCCESS",
    payload: data
})

export const setUser = (data: any) => ({
    type: "SET_USER",
    payload: data
})

export const logoutAction = () => ({
    type: "LOGOUT",
})