import React, { useRef, useState, useEffect } from "react";
import { useNavigate, Link, useLocation } from "react-router-dom";
import { batch, useDispatch, useSelector } from "react-redux";

import Cookies from "js-cookie";
import BarLoader from "react-spinners/BarLoader";

import {
  Avatar,
  AppBar as Header,
  AppBarSection,
} from "@progress/kendo-react-layout";
import {
  CompositeFilterDescriptor,
  filterBy,
  FilterDescriptor,
} from "@progress/kendo-data-query";
import {
  DropDownList,
  DropDownListChangeEvent,
  DropDownListFilterChangeEvent,
} from "@progress/kendo-react-dropdowns";
import { Button } from "@progress/kendo-react-buttons";
import { Popup } from "@progress/kendo-react-popup";
import { Badge, BadgeContainer } from "@progress/kendo-react-indicators";

import {
  AppConst,
  APP_ROUTES,
  RoleConst,
  SignalRNotificationStatusConst,
  SignalRNotificationTypeConst,
  UserTypeConst,
} from "../../../constants";

import { SidePanel } from "../sidepanel/SidePanel";
import { Locale } from "../../../utils/localization";
import ShellLogo from "./../../../assets/images/shell-logo.svg";
import ProfileLogo from "./../../../assets/images/avatar-default.svg";
import { UserManagementService } from "../../../services/user-management.service";
import { setSelectedGlobalCountry } from "../../../redux/actions/selectedGlobalCountry";
import { updateNotificationState } from "../../../redux/actions/notificationState.actions";
import Profile from "../profile/Profile";

import { useCountryCodesData } from "../../../hooks";
import { updateSitesListPredefinedFilters } from "../../../redux/actions/sitesListPredefinedFilters";

import "./AppBar.scss";

export const AppBar = () => {
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  let navigate = useNavigate();

  const notificationState = useSelector(
    (state: any) => state.notificationState?.notificationState
  );
  const userDetails = useSelector((state: any) => state?.auth?.user);

  const { data: countryCodes, refetch: refetchCountryCodes } =
    useCountryCodesData();

  const roleName = userDetails?.roleDetail?.userRoleName;

  const [allCountryList, setAllCountryList] = useState<string[]>([]);
  const [countryListData, setCountryListData] = useState([AppConst.ALL_SITES]);
  const [countrySelectedValue, setCountrySelectedValue] = useState(
    AppConst.ALL_SITES
  );
  const [showLoadingBorder, setShowLoadingBorder] = useState(true);

  const [showAvatarOptions, setShowAvatarOptions] = useState(false);
  const [isProfileVisible, setIsProfileVisible] = useState(false);
  const [showSidePanel, setShowSidePanel] = useState(false);
  const [newNotificationCount, setNewNotificationCount] = useState(0);
  const [notificationData, setNotificationData] = useState([]);

  const avatar = useRef<HTMLButtonElement>(null);
  const avatarContentRef = useRef<any>(null);
  const blurTimeoutAvatarRef = useRef<any>(null);

  const [showCountryDropdown, setShowCountryDropdown] = useState(false);

  useEffect(() => {
    refetchCountryCodes();
  }, []);

  useEffect(() => {
    let countries = [];
    if (
      roleName === RoleConst.SALES_OPS_SME ||
      roleName === RoleConst.MARKET_DATA_LEAD ||
      roleName === RoleConst.FUEL_PRICING_GLOBAL_SME ||
      roleName === RoleConst.RBLA_GLOBAL_SME ||
      roleName === RoleConst.PARTNER_GLOBAL_SME ||
      roleName === RoleConst.GLOBAL_USER
    ) {
      countries = countryCodes?.map(
        (country: { value: string }) => country?.value
      );
    } else if (userDetails?.userRoleMapping?.length > 1) {
      const userAssignedCountryCodes = userDetails?.userRoleMapping?.map(
        (item: { countryCode: string }) => item.countryCode
      );
      countries = countryCodes
        ?.filter((country: { id: string }) =>
          userAssignedCountryCodes?.includes(country.id)
        )
        ?.map((country: { value: string }) => country?.value);
    }
    setAllCountryList(["All Sites", ...(countries || [])]);
    setCountryListData(["All Sites", ...(countries || [])]);
  }, [countryCodes]);

  useEffect(() => {
    if (userDetails) {
      if (
        (roleName === RoleConst.SALES_OPS_SME ||
          roleName === RoleConst.MARKET_DATA_LEAD ||
          roleName === RoleConst.FUEL_PRICING_GLOBAL_SME ||
          roleName === RoleConst.RBLA_GLOBAL_SME ||
          roleName === RoleConst.PARTNER_GLOBAL_SME ||
          roleName === RoleConst.GLOBAL_USER ||
          userDetails?.userRoleMapping?.length > 1) &&
        (pathname === APP_ROUTES.DASHBOARD_PATH ||
          pathname === APP_ROUTES.CHANGE_HISTORY_PATH ||
          pathname === APP_ROUTES.RSMA_CONFIGURATION_PATH)
      ) {
        setShowCountryDropdown(true);
      } else {
        setShowCountryDropdown(false);
      }
    }
  }, [userDetails, pathname]);

  useEffect(() => {
    if (notificationState) {
      const newNotifications = notificationState?.filter(
        (notification: any) => notification.isNewNotification === true
      );
      setNewNotificationCount(newNotifications?.length);
      setNotificationData(notificationState);

      const inProgressStatusData = notificationState?.find(
        (notification: { status: string }) =>
          notification?.status ===
          SignalRNotificationStatusConst.IN_PROGRESS_NOTIFICATION
      );

      if (inProgressStatusData?.status) {
        setShowLoadingBorder(true);
      } else {
        setShowLoadingBorder(false);
      }
    }
  }, [notificationState]);

  useEffect(() => {
    setCountryListData([...(allCountryList || [])]);
  }, [showCountryDropdown]);

  const onGlobalCountryChange = (event: DropDownListChangeEvent) => {
    setCountrySelectedValue(event.target.value);
    dispatch(setSelectedGlobalCountry(event.target.value));
    dispatch(updateSitesListPredefinedFilters(null));
  };

  const onAvatarOpen = () => {
    avatarContentRef.current && avatarContentRef.current.focus();
  };

  const onAvatarFocus = () => {
    // the user is still inside the content
    clearTimeout(blurTimeoutAvatarRef.current);
  };

  const onBlurAvatarTimeout = () => {
    // the user is now outside the popup
    setShowAvatarOptions(false);
  };

  const onAvatarBlur = () => {
    clearTimeout(blurTimeoutAvatarRef.current);
    blurTimeoutAvatarRef.current = setTimeout(onBlurAvatarTimeout, 200);
  };

  const handleAvatarClick = () => {
    setShowAvatarOptions(!showAvatarOptions);
  };

  const onHomeBtnClick = () => {
    navigate(`${APP_ROUTES.DASHBOARD_PATH}`);
  };

  const handleProfileClick = () => {
    setIsProfileVisible(true);
  };

  const onClose = () => {
    setIsProfileVisible(false);
  };

  const handleProfileLogout = () => {
    setIsProfileVisible(false);

    UserManagementService.logOutUser();

    if (userDetails.userType === UserTypeConst.NON_SHELL_USER) {
      UserManagementService.nonShellUserLogout();
    }
    Cookies.remove("loginPayload");
    Cookies.remove("reduxstoredata");
    Cookies.remove("expiry");
    navigate(`${APP_ROUTES.LOGIN_PATH}`);
  };

  const onShowSidePanelClick = () => {
    setShowSidePanel(true);
    const newNotifications = notificationState?.map((notification: any) => {
      return { ...notification, isNewNotification: false };
    });

    batch(() => {
      dispatch(updateNotificationState([...newNotifications]));
    });

    setNewNotificationCount(0);
    setNotificationData(newNotifications);
  };

  const onHideSidePanelClick = () => {
    setShowSidePanel(false);
    setNewNotificationCount(0);
  };

  const filterData = (filter: FilterDescriptor | CompositeFilterDescriptor) => {
    const data = allCountryList.slice();
    return filterBy(data, filter);
  };

  const filterChange = (event: DropDownListFilterChangeEvent) => {
    setCountryListData(filterData(event.filter));
  };

  const renderPanelContent = () => (
    <>
      {notificationData?.length ? (
        <ul className="mdm--sidepanel-body-content">
          {notificationData?.map((notification: any) => {
            return (
              <li
                className="mdm--sidepanel-body-content-item"
                key={notification?.id}
              >
                {notification?.type ===
                SignalRNotificationTypeConst.EDIT_NOTIFICATION ? (
                  <>
                    <span>Edit Site ID - </span>
                    <Link
                      onClick={onHideSidePanelClick}
                      to={`${APP_ROUTES.SITES_PATH}/${notification?.id}`}
                    >
                      {notification?.id}
                    </Link>
                  </>
                ) : (
                  <>
                    <span>Create New Site - </span>
                    <span className="sidepanel-create-site-name">
                      {notification?.id}
                    </span>
                  </>
                )}

                <p className={`${notification?.status} notification-status`}>
                  <span
                    className={`k-icon ${
                      notification?.status ===
                      SignalRNotificationStatusConst.SUCCESS_NOTIFICATION
                        ? "k-i-check-outline"
                        : "k-i-information"
                    }`}
                  />
                  <span>
                    {notification?.status ===
                    SignalRNotificationStatusConst.IN_PROGRESS_NOTIFICATION
                      ? "In Progress"
                      : notification?.status ===
                        SignalRNotificationStatusConst.FAILED_NOTIFICATION
                      ? "Failed"
                      : "Success"}
                  </span>
                </p>

                {((notification?.status ===
                  SignalRNotificationStatusConst.SUCCESS_NOTIFICATION &&
                  notification?.type ===
                    SignalRNotificationTypeConst.EDIT_NOTIFICATION) ||
                  notification?.status ===
                    SignalRNotificationStatusConst.FAILED_NOTIFICATION ||
                  notification?.errorMessage?.includes(
                    Locale.create_site?.error_messages?.workflow_triggered_msg
                  )) && (
                  <p>
                    {notification?.errorMessage?.substring(
                      notification?.errorMessage?.indexOf("-") + 1
                    )}
                  </p>
                )}

                {notification?.status ===
                  SignalRNotificationStatusConst.SUCCESS_NOTIFICATION &&
                  notification?.type !==
                    SignalRNotificationTypeConst.EDIT_NOTIFICATION && (
                    <p>
                      <span>GlobalSiteId : </span>
                      <Link
                        onClick={onHideSidePanelClick}
                        to={`${
                          APP_ROUTES.SITES_PATH
                        }/${notification?.errorMessage?.substring(
                          notification?.errorMessage?.indexOf(":") + 2,
                          notification?.errorMessage?.indexOf("Created") - 1
                        )}`}
                      >
                        {notification?.errorMessage?.substring(
                          notification?.errorMessage?.indexOf(":") + 2,
                          notification?.errorMessage?.indexOf("Created") - 1
                        )}
                      </Link>
                      <span> Created Successfully </span>
                    </p>
                  )}

                {notification?.status ===
                  SignalRNotificationStatusConst.IN_PROGRESS_NOTIFICATION && (
                  <>
                    <p>
                      "Your request has been successfully submitted, Currently
                      we are updating changes we will notify once its ready."
                    </p>
                    <BarLoader
                      className="notification-loader"
                      color={"#FFC727"}
                      height={10}
                      speedMultiplier={0.7}
                    />
                  </>
                )}
              </li>
            );
          })}
        </ul>
      ) : (
        <p className="sidepanel-no-notification-text">
          {Locale.notifications?.no_notification}
        </p>
      )}
    </>
  );

  return (
    <>
      <Header className="mdm--appbar-container">
        <div className="mdm--app-bar-left-section  mdm--row">
          <AppBarSection className="mdm--appbar-logo">
            <Button
              className="mdm-appbar-icon"
              imageUrl={ShellLogo}
              data-testid="title"
              onClick={onHomeBtnClick}
            />
          </AppBarSection>
          <AppBarSection className="title">
            <h1 className="title" onClick={onHomeBtnClick}>
              {Locale.title}
            </h1>
          </AppBarSection>
        </div>
        <div className="mdm--app-bar-right-section mdm--row">
          {showCountryDropdown && (
            <AppBarSection>
              <DropDownList
                className="mdm--appbar-dropdown mdm--appbar-country-dropdown"
                style={{ width: "8vw", marginRight: "2vh", height: "4vh" }}
                data={countryListData}
                onChange={onGlobalCountryChange}
                filterable={true}
                onFilterChange={filterChange}
                value={countrySelectedValue}
              />
            </AppBarSection>
          )}
          {pathname !== APP_ROUTES.LEADERSHIP_DASHBOARD_PATH &&
            roleName !== RoleConst.GLOBAL_USER && (
              <AppBarSection style={{ margin: "0vh 1vh 0vh 0vh" }}>
                <BadgeContainer>
                  <span
                    onClick={onShowSidePanelClick}
                    className="k-icon k-i-notification"
                  ></span>
                  <>
                    {showLoadingBorder && <span className="border" />}
                    {newNotificationCount >= 1 && (
                      <Badge>{newNotificationCount}</Badge>
                    )}
                  </>
                </BadgeContainer>
              </AppBarSection>
            )}

          <AppBarSection>
            <button
              id="avatar-btn"
              className="k-button k-button-md k-rounded-md k-button-flat k-button-flat-base overflow-button"
              ref={avatar}
              onClick={handleAvatarClick}
            >
              <Avatar type="image" size={"small"}>
                <img src={ProfileLogo} alt="avatar" />
              </Avatar>
            </button>

            <Popup
              anchor={avatar.current}
              className={"mdm--popup-options-container"}
              anchorAlign={{
                horizontal: "right",
                vertical: "bottom",
              }}
              popupAlign={{
                horizontal: "right",
                vertical: "top",
              }}
              onOpen={onAvatarOpen}
              show={showAvatarOptions}
            >
              <div
                className="content"
                ref={avatarContentRef}
                tabIndex={0}
                onFocus={onAvatarFocus}
                onBlur={onAvatarBlur}
              >
                <ul>
                  <li onClick={handleProfileClick}>
                    <span>Profile</span>
                  </li>
                  <li onClick={handleProfileLogout}>
                    <span>Logout</span>
                  </li>
                </ul>
              </div>
            </Popup>
          </AppBarSection>
          <AppBarSection>
            {isProfileVisible && (
              <Profile
                visibleDialog={true}
                onClose={onClose}
                handleProfileLogout={handleProfileLogout}
              />
            )}
          </AppBarSection>
        </div>
      </Header>
      <SidePanel
        title={
          notificationData?.length
            ? Locale.common?.in_progress_requests_label
            : ""
        }
        onClose={onHideSidePanelClick}
        showSidePanel={showSidePanel}
      >
        {showSidePanel && renderPanelContent()}
      </SidePanel>
    </>
  );
};
