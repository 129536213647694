export class ChangeHistoryConst {
  public static CHANGE_HISTORY_TABLE_HEADER_KEY = {
    GLOBAL_SITE_ID: "globalSiteId",
    SITE_NAME: "siteName",
    UPDATE_ON: "updatedOn",
    UPDATED_BY: "updatedBy",
    COUNTRY: "country",
    ROLE: "role",
    USER_NAME: "userName",
    CHANGES: "changes",
  };

  public static COLUMN_CONFIG: any = {
    globalSiteId: "GlobalSiteId",
    siteName: "SiteName",
    sectionName: "SectionName",
    updatedOn: "UpdatedOn",
    updatedBy: "UpdatedBy",
    country: "Country",
    role: "Role",
    userName: "UserName",
    changes: "Changes",
  };
}
