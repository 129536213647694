import { ITokenService, ITokenData } from "./token-manager.interface";
import store from "../../redux/store";
import { authenticationSuccess } from "../../redux/actions/auth";

export default class TokenService implements ITokenService {
  setAuthData(data: ITokenData, expiry: number, userType: string): void {
    // console.log("Data for auth in set Auth", data);

    const {
      access_token,
      refresh_token,
      expires_in,
      token_type,
      access_token_cipm,
    } = data;
    const tokenData = {
      accessToken: access_token,
      refreshToken: refresh_token,
      expiryTime: expires_in,
      tokenType: token_type,
      accessTokenCipm: access_token_cipm,
    };
    store.dispatch(authenticationSuccess(tokenData));
    // console.log("Refresh TokenLogic", tokenData, expiry, refresh_token);
    console.log("refresh_token", refresh_token);

    // setTimeout(() => {
    //   if (refresh_token) {
    //     if (userType === "shell") {
    //       renewShellAccessToken(refresh_token);
    //     } else {
    //       renewNonShellAccessToken(refresh_token);
    //     }
    //   }
    // }, expiry * 1000);
    // }, 60000);
  }

  // setAccessToken(token: string): void {
  //   const decodedToken: any = jwtDecode(token);
  //   console.log(decodedToken);
  //   Cookies.set("token", token, { expires: decodedToken["exp"] });
  //   const user = {
  //     firstName: decodedToken["givenName"],
  //     lastName: decodedToken["sn"],
  //     email: decodedToken["mail"],
  //   };
  //   Cookies.set("user", JSON.stringify(user), {
  //     expires: decodedToken["exp"],
  //   });
  // }

  // getAccessToken(): string | undefined {
  //   return Cookies.get("token") || undefined;
  // }

  // setRefreshToken(refreshToken: string): void {
  //   Cookies.set("refreshToken", refreshToken, { expires: 1 });
  // }

  // renewAccessToken(): string | undefined {
  //   return Cookies.get("refreshToken") || undefined;
  // }

  // clearAllTokens(): void {
  //   Cookies.remove("token");
  //   Cookies.remove("refreshToken");
  //   Cookies.remove("user");
  // }

  // silentlyRefreshAccessToken(): void {
  //   console.log("Send req to refresh a token");
  // }

  // tokenExpired(): boolean {
  //   const token = this.getAccessToken();
  //   const decodedToken: any = token && jwtDecode(token);
  //   if (!decodedToken) return true;
  //   const dateNow = new Date();
  //   const tokenExpired = decodedToken["exp"] * 1000 < dateNow.getTime();
  //   return tokenExpired;
  // }
}
