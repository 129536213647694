export class SiteStatusConst {
  public static NOT_SPECIFIED = "0";
  public static ACTIVE = "1";
  public static CLOSED_TEMPORARILY = "2";
  public static CLOSED_PERMANENTLY = "3";
  public static LANDBANK = "4";
  public static WORK_IN_PROCESS = "5";
  public static COMPLETED_NOT_OPENED = "6";
  public static INVALID = "7";
  public static NON_RETAIL_SHELL_LOCATION = "8";
  public static RECYCLE_BIN = "9";
}

export class SiteStatusTextConst {
  public static ACTIVE_STATUS_TEXT = "Active";
  public static CLOSED_TEMPORARILY_STATUS_TEXT = "Closed Temporarily";
  public static WIP_STATUS_TEXT = "Work in Process";
  public static NOT_SPECIFIED_STATUS_TEXT = "Not Specified";
  public static INVALID_STATUS_TEXT = "Invalid";
  public static CLOSED_PERMANENTLY_STATUS_TEXT = "Closed Permanently";
}
