import React, { useEffect, useRef } from "react";

export class AppConst {
  public static CONTACT_US_EMAIL = "GXSBOBNGMDMWebPortal@shell.com";
  public static CONTACT_US_RBLA_LOCAL_GlOBAL_EMAIL = "SiteDataRequest@shell.com";
  public static SNOW_URL =
    "https://servicenow.shell.com/sp?id=sc_cat_item&table=sc_cat_item&sys_id=fd04510387661910b6f933770cbb359d";
  public static EXPORT_FILE_TYPE =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  public static EXPORT_FILE_TYPE_EXTENSION = ".xlsx";
  public static EXPORT_DATA_TYPE = {
    ALL: "All",
    CURRENT_PAGE: "Current",
  };
  public static VIEW_DATE_FORMAT = "dd/MMM/yyyy";
  public static VIEW_DETAIL_DATE_FORMAT = "dd/MMM/yyyy HH:mm:ss";
  public static VIEW_SITE_DETAILS_STALE_TIME = 6000;
  public static OWNED_BY_DEALER_RE_TYPE = "Owned by Dealer";
  public static COMPANY_OWNED_RE_TYPE = "Company Owned";
  public static RETAIL_ALLIANCE_RE_TYPE = "Retail Alliance";
  public static NOTIFICATION_HUB_INITIATOR_ID =
    "99e0cc76-716a-4303-ab52-238b8b34eb92";
  public static OPENING_HOURS_VALID_FACILITY_CODE_IDS = [25, 944, 950];
  public static SHELL_LATITUDE_REGULAR_EXP =
    /^[+-]?(([1-8]?[0-9]?\.[0-9]{5,6})?|90(\.0{5,6})?)$/;
  public static SHELL_LONGITUDE_REGULAR_EXP =
    /^[+-]?(([1-9]?[0-9]|1[0-7][0-9])?\.[0-9]{5,6}?|180(\.0{5,6})?)$/;

  public static NON_SHELL_LATITUDE_REGULAR_EXP =
    /^[+-]?(([1-8]?[0-9]?\.[0-9]{4,6})?|90(\.0{4,6})?)$/;
  public static NON_SHELL_LONGITUDE_REGULAR_EXP =
    /^[+-]?(([1-9]?[0-9]|1[0-7][0-9])?\.[0-9]{4,6}?|180(\.0{4,6})?)$/;
  public static POSTAL_CODE_REGULAR_EXP = /^[a-z0-9][a-z0-9\- ]{0,10}[a-z0-9]$/;
  public static NUMBER_ONLY_FIELD_REGULAR_EXP = /^\d+$/;
  public static STRING_ONLY_FIELD_REGULAR_EXP = /^[A-Z]+$/;
  public static FAX_NO_REGULAR_EXP =
    /^(\+)?(((((\d+)|(\(\d+\))|(\(\d+\s(\d)\)))(\s|-|\d+))+)|((\d+)|(\(\d+\))$)+)+\d$/;
  public static EMAIL_REGULAR_EXP = /^[a-z0-9]+@[a-z]+\.[a-z]{2,3}$/;

  public static WEB_URL_REGULAR_EXP =
    /[-a-zA-Z0-9@:%_\+.~#?&//=]{2,256}\.[a-z]{2,4}\b(\/[-a-zA-Z0-9@:%_\+.~#?&//=]*)?/gi;

  public static OWNED_BY_DEALER_RE_TYPE_LIST = [
    "Do",
    "Wholesale",
    "DODO Local",
    "DODM",
    "ORO Local",
    "Wholesale Local",
    "LEGACY DO",
  ];

  public static COMPANY_OWNED_RE_TYPE_LIST = [
    "RBA",
    "RFA",
    "COCO Local",
    "CCDO",
    "MSO Local",
    "RORO Local",
    "LEGACY CO",
    "CODO Local",
  ];
  public static COMPANY_OWNED_TENURE_TYPE_LIST = [
    "Freehold",
    "Leasehold",
    "Freehold/Leasehold combo",
  ];
  public static OWNED_BY_DEALER_TENURE_TYPE = "Owned by Dealer";

  public static WEEK_DAY_LIST_CODES = [
    { id: "MON", value: "Monday" },
    { id: "TUE", value: "Tuesday" },
    { id: "WED", value: "Wednesday" },
    { id: "THU", value: "Thursday" },
    { id: "FRI", value: "Friday" },
    { id: "SAT", value: "Saturday" },
    { id: "SUN", value: "Sunday" },
  ];

  public static DEFAULT_ROLE = "Market Data Lead";
  public static GSAP_SITE_TYPE = "GSAP";
  public static NON_GSAP_SITE_TYPE = "NGSAP";
  public static RBLA_SITE_TYPE = "RBLA";
  public static PARTNER_SITE_TYPE = "Partner";
  public static COMPETITOR_SITE_TYPE = "Competitor";
  public static ALL_SITES = "All Sites";
  public static LOYALTY_FACILITIES_CODE_LIST = [16, 907];
  public static EV_CHARGING_CODE_LIST = [30, 31];
  public static DIESEL_PRODUCT_CODE_LIST = [
    11, 13, 16, 20, 24, 29, 30, 31, 32, 35, 28,
  ];
  public static _24_HOUR_FUEL_SERVICE_FACILITY_CODE = 922;
  public static FORECOURT_FACILITY_CODE = 950;
  public static SHOP_FACILITY_CODE = 944;
  public static CAR_WASH_FACILITY_CODE = 25;
  public static CRT_FACILITY_CODE = 904;
  public static HYDROGEN_PRODUCT_CODE = 20;
  public static LNG_PRODUCT_CODE = 28;
  public static ELECTRIC_CHARGING_SHELL_RECHARGE_PRODUCT_CODE = 30;
  public static ELECTRIC_CHARGING_OTHER_PRODUCT_CODE = 31;
  public static HGV_LANE_SUB_FACILITY_CODE = 94236;

  public static useEffectSkipFirst = (
    fn: () => void,
    value?: React.DependencyList
  ) => {
    const isFirst = useRef(true);
    useEffect(() => {
      if (isFirst.current) {
        isFirst.current = false;
        return;
      }
      fn();
    }, value);
  };
}
