export class WorkFlowActionsConst {
  public static WORKFLOW_ACTIONS_TABLE_HEADER_KEY = {
    SITE_NAME: "siteName",
    COUNTRY: "country",
    CREATED_BY: "createdBy",
    CREATION_DATE: "creationDate",
    DUE_BY: "dueBy",
    STATUS: "status",
    COMMENTS: "comments",
    GLOBAL_SITE_ID: "siteId",
    UPDATED_DATE: "updateDate",
  };
}
