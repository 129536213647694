import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { Tooltip } from "@progress/kendo-react-tooltip";
import { TabStrip, TabStripTab } from "@progress/kendo-react-layout";
import { format } from "date-fns";
import { Skeleton } from "@progress/kendo-react-indicators";

import { Locale } from "../../../../../utils/localization";
import { TooltipContentTemplate } from "../../../../components/tooltip-content-template/TooltipContentTemplate";
import CommentIcon from "./../../../../../assets/images/comment-icon.svg";
import FullScreenIcon from "./../../../../../assets/images/full-screen.svg";
import { APP_ROUTES } from "../../../../../constants/app-routes.constant";
import useWindowDimensions from "./windowDimensions";
import { AppConst, ChangeHistoryConst } from "../../../../../constants";
import {
  useChangeHistoryByUserData,
  useChangeHistoryData,
} from "../../../../../hooks";

import "./RecentUpdatesComponent.scss";

interface IProps {
  className?: string;
}

export const RecentUpdatesComponent = (props: IProps) => {
  const { className } = props;
  const navigate = useNavigate();

  const selectedGlobalCountry = useSelector(
    (state: any) => state?.selectedGlobalCountry.selectedGlobalCountry
  );

  const { height } = useWindowDimensions();
  const [selected, setSelected] = useState(0);

  const defaultPayload = {
    Country: "",
    PageNo: 1,
    PageSize: 25,
    SortBy: ChangeHistoryConst.CHANGE_HISTORY_TABLE_HEADER_KEY.UPDATE_ON,
    SortOrder: "desc",
  };

  const [requestDataPayload, setRequestDataPayload] = useState(defaultPayload);

  const {
    isLoading: isChangeHistoryDataLoading,
    data: changeHistoryData,
    refetch: refetchChangeHistoryData,
  } = useChangeHistoryData(requestDataPayload);

  const {
    isLoading: isChangeHistoryByUserDataLoading,
    data: changeHistoryByUserData,
    refetch: refetchChangeHistoryByUserData,
  } = useChangeHistoryByUserData(requestDataPayload);

  useEffect(() => {
    if (selectedGlobalCountry === AppConst.ALL_SITES) {
      setRequestDataPayload({ ...defaultPayload });
    } else {
      setRequestDataPayload({
        ...defaultPayload,
        Country: selectedGlobalCountry,
      });
    }
  }, [selectedGlobalCountry]);

  useEffect(() => {
    refetchChangeHistoryData();
    refetchChangeHistoryByUserData();
  }, [requestDataPayload]);

  const onViewAllClicked = (e: any) => {
    navigate(APP_ROUTES.CHANGE_HISTORY_PATH);
  };

  const handleSelect = (e: any) => {
    setSelected(e.selected);
  };

  const renderLoading = () => (
    <ul className="mdm--ul-list-change-history">
      {[1, 2, 3, 4, 5, 6]?.map((item: number, index: number) => (
        <li key={index} className="mdm--recent-updates-item">
          <div className="mdm--row">
            <div className="mdm--recent-updates-item-details">
              <div className="mdm--recent-updates-item-title">
                <span className="mdm--recent-updates-item-title-Ankertag">
                  <Skeleton shape={"text"} style={{ width: "90%" }} />
                </span>
              </div>
              <div className="mdm--row loading">
                <Skeleton shape={"text"} style={{ width: "42%" }} />
                <Skeleton shape={"text"} style={{ width: "42%" }} />
              </div>
            </div>
            <div>
              <Skeleton
                shape={"circle"}
                style={{
                  width: "5rem",
                  height: "1.5rem",
                  marginTop: "0.5rem",
                }}
              />
            </div>
          </div>
        </li>
      ))}
    </ul>
  );

  return (
    <div className={`${className} mdm--recent-updates-container`}>
      <h5 className="mdm--landing-page-item-title">
        {Locale.landing_page?.change_history.change_history_title}
      </h5>
      <div className="mdm--landing-page-item-body mdm--recent-updates-body">
        <div className="mdm-row">
          {/* &nbsp; */}
          <span className="mdm--recent-updates-comment-icon">
            <img src={CommentIcon} alt="icon" />
          </span>
          &nbsp;
          <span className="mdm--recent-updates-header">
            {selected === 0
              ? Locale.landing_page?.change_history
                  .change_history_site_title_label
              : Locale.landing_page?.change_history
                  .change_history_user_title_label}
          </span>
          <span
            className="mdm--view-all-changes mdm--recent-updates-comment-icon"
            onClick={onViewAllClicked}
          >
            <img src={FullScreenIcon} alt="icon" />
          </span>
        </div>

        <div className="mdm--change-history-tabstrip">
          <section className="mdm--change-history-section">
            <TabStrip
              selected={selected}
              onSelect={handleSelect}
              tabPosition="top"
            >
              <TabStripTab
                title={
                  <>
                    <span
                      className={
                        selected === 0
                          ? "mdm--lp-sites-list-tab-title-active"
                          : "mdm--lp-sites-list-tab-title"
                      }
                      data-testid="shell_sites_title"
                    >
                      {Locale.landing_page?.sites_list.sites_title}
                    </span>
                  </>
                }
              >
                {isChangeHistoryDataLoading ? (
                  renderLoading()
                ) : changeHistoryData?.data?.changeHistoryResult?.length > 0 ? (
                  <ul className="mdm--ul-list-change-history">
                    {changeHistoryData?.data?.changeHistoryResult?.map(
                      (item: any, index: any) => (
                        <li
                          key={"site" + index}
                          className="mdm--recent-updates-item"
                        >
                          <div className="mdm--row">
                            <div className="mdm--recent-updates-item-details">
                              <Tooltip
                                key={"site" + index}
                                content={(props: any) => (
                                  <TooltipContentTemplate
                                    height={height}
                                    index={index}
                                    title={props.title}
                                    data={item}
                                  />
                                )}
                                className="mdm--tooltip-change-history"
                                anchorElement="target"
                                position="left"
                              >
                                <div
                                  title={item.siteName}
                                  className="mdm--recent-updates-item-title"
                                >
                                  <span
                                    className="mdm--recent-updates-item-title-Ankertag"
                                    title={item.siteName}
                                  >
                                    {item.siteName}
                                  </span>
                                </div>
                              </Tooltip>
                              <div className="mdm--row">
                                <div className="mdm--recent-updates-item-time">
                                  {format(
                                    new Date(item?.updatedOn),
                                    AppConst.VIEW_DATE_FORMAT
                                  )}
                                </div>
                                <div className="mdm--recent-updates-item-id">
                                  {<span>{item.changes}</span>}
                                </div>
                              </div>
                            </div>
                            <div className="mdm--recent-updates-item-tag">
                              By : {item.role.toUpperCase()}
                            </div>
                          </div>
                        </li>
                      )
                    )}
                  </ul>
                ) : (
                  <span className="mdm-change-no-records">
                    {Locale.common?.no_records_to_view}
                  </span>
                )}
              </TabStripTab>
              <TabStripTab
                //  title="USER (me)"
                title={
                  <>
                    <span
                      className={
                        selected === 1
                          ? "mdm--lp-sites-list-tab-title-active"
                          : "mdm--lp-sites-list-tab-title"
                      }
                      data-testid="shell_sites_title"
                    >
                      USER (me)
                    </span>
                    {/* <span className="mdm--dot">{allShellSitesListCount}</span> */}
                  </>
                }
                // disabled={selected !== 0}
              >
                {isChangeHistoryByUserDataLoading ? (
                  renderLoading()
                ) : changeHistoryByUserData?.data?.changeHistoryResult?.length >
                  0 ? (
                  <ul className="mdm--ul-list-change-history">
                    {changeHistoryByUserData?.data?.changeHistoryResult?.map(
                      (item: any, index: any) => (
                        <li
                          key={"user" + index}
                          className="mdm--recent-updates-item"
                        >
                          <div className="mdm--row">
                            <div className="mdm--recent-updates-item-details">
                              <Tooltip
                                key={"user" + index}
                                content={(props: any) => (
                                  <TooltipContentTemplate
                                    height={height}
                                    index={index}
                                    title={props.title}
                                    data={item}
                                  />
                                )}
                                className="mdm--tooltip-change-history"
                                anchorElement="target"
                                position="left"
                              >
                                <div
                                  title={item.userName}
                                  className="mdm--recent-updates-item-title"
                                >
                                  <a
                                    className="mdm--recent-updates-item-title-Ankertag"
                                    title={item.userName}
                                  >
                                    {item.userName}
                                  </a>
                                </div>
                              </Tooltip>
                              <div className="mdm--row">
                                <div className="mdm--recent-updates-item-time">
                                  {format(
                                    new Date(item.updatedOn),
                                    AppConst.VIEW_DATE_FORMAT
                                  )}
                                </div>
                                <div className="mdm--recent-updates-item-id">
                                  {<span>{item.changes}</span>}
                                </div>
                              </div>
                            </div>
                            <div className="mdm--recent-updates-item-tag">
                              {item.siteName}
                            </div>
                          </div>
                        </li>
                      )
                    )}
                  </ul>
                ) : (
                  <span className="mdm-change-no-records">
                    {Locale.common?.no_records_to_view}
                  </span>
                )}
              </TabStripTab>
            </TabStrip>
          </section>
        </div>
      </div>
    </div>
  );
};
