import { useEffect, useState } from "react";
import { useQuery } from "react-query";

import {
  CompositeFilterDescriptor,
  filterBy,
  FilterDescriptor,
} from "@progress/kendo-data-query";
import {
  DropDownListChangeEvent,
  DropDownListFilterChangeEvent,
} from "@progress/kendo-react-dropdowns";
import { DateInputChangeEvent } from "@progress/kendo-react-dateinputs";
import { InputChangeEvent } from "@progress/kendo-react-inputs";
import { formatDate } from "@telerik/kendo-intl";

import { format } from "date-fns";

import {
  AppConst,
  EditFieldTypeConst,
} from "../../../../../../../../constants";
import { DropDownMenuItem } from "../../../../../../../../models";
import { SitesService } from "../../../../../../../../services/sites.service";
import { DetailsViewComponent } from "../../../../../../../components";
import { Locale } from "../../../../../../../../utils/localization";

const fetchSiteRetailerdData = ({ queryKey }: any) => {
  const siteId = queryKey[1];
  return SitesService.getSiteDetails({
    globalSiteId: siteId || "",
    viewName: "SiteRetailerResult",
  });
};

export const RetailerTab = (props: any) => {
  const {
    siteId,
    isEditing,
    basicDetailsTabSelected,
    refetchTerritoryCodes,
    territoryCodes,
    countryCodes,
    isTerritoryCodesLoading,
    isCountryCodesLoading,

    onPatchRequestValueSet,
    onSectionValueSet,

    isSectionValueChanged,
    onSectionValueChanged,
    sectionEditedData,
    isCreate,
    classifiedSiteType,
    setIsSectionValid,
    isSectionValid,
    refetchAllData,
  } = props;

  const [customerNo, setCustomerNo] = useState<string | undefined>();
  const [customerName, setCustomerName] = useState<string | undefined>();
  const [language, setLangauge] = useState<string | undefined>();
  const [cityName, setCityName] = useState<string | undefined>();
  const [streetAddress, setStreetAddress] = useState<string | undefined>("");
  const [postalCode, setPostalCode] = useState<string | undefined>("");

  const [countrySelectedValue, setCountrySelectedValue] =
    useState<DropDownMenuItem>();
  const [territorySelectedValue, setTerritorySelectedValue] =
    useState<DropDownMenuItem>();

  const [customerStartDate, setCustomerStartDate] = useState<
    Date | string | null
  >(null);
  const [customerEndDate, setCustomerEndDate] = useState<Date | string | null>(
    null
  );

  const [isCustomerNoValid, setIsCustomerNoValid] = useState(0);
  const [isCustomerNameValid, setIsCustomerNameValid] = useState(0);
  const [isCustomerStartDateValid, setIsCustomerStartDateValid] = useState(0);
  const [isCustomerEndDateValid, setIsCustomerEndDateValid] = useState(0);

  const [countryList, setCountryList] = useState<any>();
  const [territoryList, setTerritoryList] = useState<any>();

  const [retailerObj, setRetailerObj] = useState<any>(
    sectionEditedData?.SiteRetailer
  );

  const {
    isLoading: isSiteRetailerDataLoading,
    data: siteRetailerDetails,
    refetch: refetchSiteRetailerData,
  } = useQuery(
    [`site-retailer-data-${siteId}`, siteId],
    fetchSiteRetailerdData,
    {
      staleTime: AppConst.VIEW_SITE_DETAILS_STALE_TIME,
      enabled: false,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      select: (data) => {
        const newData = data?.data?.result?.SiteRetailer?.item?.find(
          (d: any) => {
            const tDate = new Date();
            const start = new Date(d.customerStartDate);
            const end = new Date(d.customerEndDate);
            return tDate > start && tDate < end;
          }
        );
        return newData;
      },
    }
  );

  useEffect(() => {
    if (refetchAllData && siteId && basicDetailsTabSelected === 3) {
      refetchSiteRetailerData();
    }
  }, [refetchAllData, basicDetailsTabSelected]);

  useEffect(() => {
    if ((sectionEditedData && isSectionValueChanged) || siteRetailerDetails) {
      setCustomerNo(
        sectionEditedData?.SiteRetailer?.Retailer?.customerNo ||
          siteRetailerDetails?.Retailer?.customerNo
      );
      setCustomerName(
        sectionEditedData?.SiteRetailer?.Retailer?.customerName ||
          siteRetailerDetails?.Retailer?.customerName
      );
      setLangauge(
        sectionEditedData?.SiteRetailer?.Retailer?.language ||
          siteRetailerDetails?.Retailer?.languageCd
      );
      setCountrySelectedValue(
        sectionEditedData?.SiteRetailer?.Retailer?.countryCd || {
          value: siteRetailerDetails?.Retailer?.countryCd?.countryName,
          id: siteRetailerDetails?.Retailer?.countryCd?.countryCd,
        }
      );
      setTerritorySelectedValue(
        sectionEditedData?.SiteRetailer?.Retailer?.territoryCd || {
          value: siteRetailerDetails?.Retailer?.territoryCd?.territoryName,
          id: siteRetailerDetails?.Retailer?.territoryCd?.territoryCd,
        }
      );
      setCityName(
        sectionEditedData?.SiteRetailer?.Retailer?.cityName ||
          siteRetailerDetails?.Retailer?.cityName
      );
      setStreetAddress(
        sectionEditedData?.SiteRetailer?.Retailer?.streetAddress ||
          siteRetailerDetails?.Retailer?.streetAddress
      );
      setPostalCode(
        sectionEditedData?.SiteRetailer?.Retailer?.postalCd?.trim() ||
          siteRetailerDetails?.Retailer?.postalCd?.trim()
      );
      setCustomerStartDate(
        sectionEditedData?.SiteRetailer?.customerStartDate ||
          siteRetailerDetails?.customerStartDate
      );
      setCustomerEndDate(
        sectionEditedData?.SiteRetailer?.customerEndDate ||
          siteRetailerDetails?.customerEndDate
      );
    }
  }, [sectionEditedData, siteRetailerDetails]);

  useEffect(() => {
    if (basicDetailsTabSelected === 3 && siteId) {
      refetchSiteRetailerData();
    }
  }, [basicDetailsTabSelected]);

  AppConst.useEffectSkipFirst(() => {
    if (isSectionValueChanged) {
      if (customerNo && customerName && customerStartDate && customerEndDate) {
        setIsSectionValid(1);
      } else {
        setIsSectionValid(0);
      }
    }
  }, [
    customerNo,
    customerName,
    customerStartDate,
    customerEndDate,
    isSectionValid,
  ]);

  useEffect(() => {
    if (isSectionValid === -1) {
      triggerValidation();
    }
  }, [
    isSectionValid,
    customerNo,
    customerName,
    customerStartDate,
    customerEndDate,
  ]);

  const triggerValidation = () => {
    if (customerNo) {
      setIsCustomerNoValid(1);
    } else {
      setIsCustomerNoValid(-1);
    }
    if (customerName) {
      setIsCustomerNameValid(1);
    } else {
      setIsCustomerNameValid(-1);
    }
    if (customerStartDate) {
      setIsCustomerStartDateValid(1);
    } else {
      setIsCustomerStartDateValid(-1);
    }
    if (customerEndDate) {
      setIsCustomerEndDateValid(1);
    } else {
      setIsCustomerEndDateValid(-1);
    }
  };

  const countryFilterChange = (event: DropDownListFilterChangeEvent) => {
    setCountryList(filterData(event.filter, countryCodes));
  };

  const territoryFilterChange = (event: DropDownListFilterChangeEvent) => {
    setTerritoryList(filterData(event.filter, territoryCodes));
  };

  useEffect(() => {
    setCountryList(countryCodes?.slice());
  }, [countryCodes]);

  useEffect(() => {
    setTerritoryList(territoryCodes?.slice());
  }, [territoryCodes]);

  const filterData = (
    filter: FilterDescriptor | CompositeFilterDescriptor,
    codes: any
  ) => {
    const data = codes.slice();
    return filterBy(data, filter);
  };

  const onCustomerNoChange = (event: InputChangeEvent) => {
    onSectionValueChanged(true);
    const number = event.value;
    if (number?.length) {
      setIsCustomerNoValid(1);
    } else if (isSectionValid === -1) {
      setIsCustomerNoValid(-1);
    }
    setCustomerNo(number);
    setRetailerObj({
      ...retailerObj,
      Retailer: { ...retailerObj?.Retailer, customerNo: number },
    });
    onSectionValueSet({
      SiteRetailer: {
        ...retailerObj,
        Retailer: { ...retailerObj?.Retailer, customerNo: number },
      },
    });
    onPatchRequestValueSet(
      {
        SiteRetailer: {
          item: [
            {
              ...retailerObj,
              Retailer: { ...retailerObj?.Retailer, customerNo: number },
            },
          ],
        },
      },
      siteRetailerDetails?.Retailer?.customerNo !== number
    );
  };

  const onCustomerNameChange = (event: InputChangeEvent) => {
    onSectionValueChanged(true);
    const name = event.value;
    if (name?.length) {
      setIsCustomerNameValid(1);
    } else if (isSectionValid === -1) {
      setIsCustomerNameValid(-1);
    }
    setCustomerName(name);
    setRetailerObj({
      ...retailerObj,
      Retailer: { ...retailerObj?.Retailer, customerName: name },
    });
    onSectionValueSet({
      SiteRetailer: {
        ...retailerObj,
        Retailer: { ...retailerObj?.Retailer, customerName: name },
      },
    });
    onPatchRequestValueSet(
      {
        SiteRetailer: {
          item: [
            {
              ...retailerObj,
              Retailer: {
                ...retailerObj?.Retailer,
                customerName: name,
              },
            },
          ],
        },
      },
      siteRetailerDetails?.Retailer?.customerName !== name
    );
  };

  const onLanguageChange = (event: InputChangeEvent) => {
    onSectionValueChanged(true);
    const langauge = event.value;
    setLangauge(langauge);
    setRetailerObj({
      ...retailerObj,
      Retailer: { ...retailerObj?.Retailer, language: langauge },
    });
    onSectionValueSet({
      SiteRetailer: {
        ...retailerObj,
        Retailer: { ...retailerObj?.Retailer, language: langauge },
      },
    });
    onPatchRequestValueSet(
      {
        SiteRetailer: {
          item: [
            {
              ...retailerObj,
              Retailer: { ...retailerObj?.Retailer, languageCd: langauge },
            },
          ],
        },
      },
      siteRetailerDetails?.Retailer?.languageCd?.toUpperCase() !==
        langauge?.toUpperCase()
    );
  };

  const onCityChange = (event: InputChangeEvent) => {
    onSectionValueChanged(true);
    const city = event.value;
    setCityName(city);
    setRetailerObj({
      ...retailerObj,
      Retailer: { ...retailerObj?.Retailer, cityName: city },
    });
    onSectionValueSet({
      SiteRetailer: {
        ...retailerObj,
        Retailer: { ...retailerObj?.Retailer, cityName: city },
      },
    });
    onPatchRequestValueSet(
      {
        SiteRetailer: {
          item: [
            {
              ...retailerObj,
              Retailer: { ...retailerObj?.Retailer, cityName: city },
            },
          ],
        },
      },
      siteRetailerDetails?.Retailer?.cityName?.toUpperCase() !==
        city?.toUpperCase()
    );
  };

  const onStreetAddressChange = (event: InputChangeEvent) => {
    onSectionValueChanged(true);
    const address = event.value;
    setStreetAddress(address);
    setRetailerObj({
      ...retailerObj,
      Retailer: { ...retailerObj?.Retailer, streetAddress: address },
    });
    onSectionValueSet({
      SiteRetailer: {
        ...retailerObj,
        Retailer: { ...retailerObj?.Retailer, streetAddress: address },
      },
    });
    onPatchRequestValueSet(
      {
        SiteRetailer: {
          item: [
            {
              ...retailerObj,
              Retailer: {
                ...retailerObj?.Retailer,
                streetAddress: address,
              },
            },
          ],
        },
      },
      siteRetailerDetails?.Retailer?.streetAddress !== address
    );
  };

  const onPostalCodeChange = (event: InputChangeEvent) => {
    onSectionValueChanged(true);
    const postal = event.value;
    setPostalCode(postal);
    setRetailerObj({
      ...retailerObj,
      Retailer: { ...retailerObj?.Retailer, postalCd: postal },
    });
    onSectionValueSet({
      SiteRetailer: {
        ...retailerObj,
        Retailer: { ...retailerObj?.Retailer, postalCd: postal },
      },
    });
    onPatchRequestValueSet(
      {
        SiteRetailer: {
          item: [
            {
              ...retailerObj,
              Retailer: {
                ...retailerObj?.Retailer,
                postalCd: postal,
              },
            },
          ],
        },
      },
      siteRetailerDetails?.Retailer?.postalCd !== postal
    );
  };

  const onCustomerStartDateChange = (event: DateInputChangeEvent) => {
    onSectionValueChanged(true);
    const startDate: any = event.value;
    if (startDate) {
      setIsCustomerStartDateValid(1);
    } else if (isSectionValid === -1) {
      setIsCustomerStartDateValid(-1);
    }
    setCustomerStartDate(
      startDate ? format(new Date(startDate), AppConst.VIEW_DATE_FORMAT) : null
    );

    setCustomerEndDate(null);

    const date: number = startDate?.getDate() || 0;
    const month: number = startDate?.getMonth() || 0;
    const year: number = startDate?.getFullYear() || 2000;
    const formattedUTCTime = formatDate(new Date(year, month, date), "s");
    setRetailerObj({
      ...retailerObj,
      Retailer: { ...retailerObj?.Retailer },
      customerStartDate: formattedUTCTime,
      customerEndDate: null,
    });
    onSectionValueSet({
      SiteRetailer: {
        ...retailerObj,
        Retailer: { ...retailerObj?.Retailer },
        customerStartDate: formattedUTCTime,
        customerEndDate: null,
      },
    });
    onPatchRequestValueSet(
      {
        SiteRetailer: {
          item: [
            {
              ...retailerObj,
              customerStartDate: formattedUTCTime,
              customerEndDate: null,
              Retailer: {
                ...retailerObj?.Retailer,
              },
            },
          ],
        },
      },
      siteRetailerDetails?.customerStartDate !== formattedUTCTime
    );
  };

  const onCustomerEndDateChange = (event: DateInputChangeEvent) => {
    onSectionValueChanged(true);
    const endDate: any = event.value;
    if (endDate) {
      setIsCustomerEndDateValid(1);
    } else if (isSectionValid === -1) {
      setIsCustomerEndDateValid(-1);
    }
    setCustomerEndDate(
      endDate ? format(new Date(endDate), AppConst.VIEW_DATE_FORMAT) : null
    );

    const date: number = endDate?.getDate() || 0;
    const month: number = endDate?.getMonth() || 0;
    const year: number = endDate?.getFullYear() || 2000;
    const formattedUTCTime = formatDate(new Date(year, month, date), "s");
    setRetailerObj({
      ...retailerObj,
      Retailer: { ...retailerObj?.Retailer },
      customerEndDate: formattedUTCTime,
    });
    onSectionValueSet({
      SiteRetailer: {
        ...retailerObj,
        Retailer: { ...retailerObj?.Retailer },
        customerEndDate: formattedUTCTime,
      },
    });
    onPatchRequestValueSet(
      {
        SiteRetailer: {
          item: [
            {
              ...retailerObj,
              Retailer: {
                ...retailerObj?.Retailer,
              },
              customerEndDate: formattedUTCTime,
            },
          ],
        },
      },
      siteRetailerDetails?.customerEndDate !== formattedUTCTime
    );
  };

  const onCountryChange = (event: DropDownListChangeEvent) => {
    refetchTerritoryCodes(event.target.value);
    setCountrySelectedValue(event.target.value);
    setTerritorySelectedValue(undefined);
    onSectionValueChanged(true);
    setRetailerObj({
      ...retailerObj,
      Retailer: { ...retailerObj?.Retailer, countryCd: event.target.value },
    });
    setRetailerObj({
      ...retailerObj,
      Retailer: { ...retailerObj?.Retailer, countryCd: event.target.value },
    });
    onPatchRequestValueSet(
      {
        SiteRetailer: {
          item: [
            {
              ...retailerObj,
              Retailer: {
                ...retailerObj?.Retailer,
                countryCd: event.target.value?.id,
              },
            },
          ],
        },
      },
      siteRetailerDetails?.Retailer?.countryCd?.countryName?.toUpperCase() !==
        event.target.value?.value?.toUpperCase()
    );
  };

  const onTerritoryChange = (event: DropDownListChangeEvent) => {
    setTerritorySelectedValue(event.target.value);
    onSectionValueChanged(true);
    setRetailerObj({
      ...retailerObj,
      Retailer: { ...retailerObj?.Retailer, territoryCd: event.target.value },
    });
    setRetailerObj({
      ...retailerObj,
      Retailer: { ...retailerObj?.Retailer, territoryCd: event.target.value },
    });
    onPatchRequestValueSet(
      {
        SiteRetailer: {
          item: [
            {
              ...retailerObj,
              Retailer: {
                ...retailerObj?.Retailer,
                territoryCd: event.target.value?.id,
              },
            },
          ],
        },
      },
      siteRetailerDetails?.Retailer?.territoryCd?.territoryName?.toUpperCase() !==
        event.target.value?.value?.toUpperCase()
    );
  };

  const getReatilerData = () => [
    {
      label: Locale.site_details?.retail_admin_update.retailer.customer_no,
      value: siteRetailerDetails?.Retailer?.customerNo,
      editType: EditFieldTypeConst.TEXT_FIELD,
      onChange: onCustomerNoChange,
      editValue: customerNo,
      isDisabled: classifiedSiteType === AppConst.GSAP_SITE_TYPE,
      isVisible: true,
      isValid: isCustomerNoValid !== -1,
      validationMessage: Locale.common?.required_field,
      maxLength: 8,
      required: true,
    },
    {
      label: Locale.site_details?.retail_admin_update.retailer.customer_name,
      value: siteRetailerDetails?.Retailer?.customerName,
      editType: EditFieldTypeConst.TEXT_FIELD,
      onChange: onCustomerNameChange,
      isDisabled: classifiedSiteType === AppConst.GSAP_SITE_TYPE,
      isVisible: true,
      editValue: customerName,
      isValid: isCustomerNameValid !== -1,
      validationMessage: Locale.common?.required_field,
      maxLength: 150,
      required: true,
    },
    {
      label: Locale.site_details?.retail_admin_update.retailer.language,
      value: siteRetailerDetails?.Retailer?.languageCd,
      editType: EditFieldTypeConst.TEXT_FIELD,
      isVisible: classifiedSiteType === AppConst.GSAP_SITE_TYPE,
      onChange: onLanguageChange,
      isDisabled: true,
      editValue: language,
      maxLength: 50,
    },
    {
      label: Locale.landing_page?.sites_list.table_headers.country_label,
      value: siteRetailerDetails?.Retailer?.countryCd?.countryName,
      editType: EditFieldTypeConst.DROPDOWN_FIELD,
      isEditDataLoading: isCountryCodesLoading,
      onChange: onCountryChange,
      editData: countryList,
      editValue: countrySelectedValue,
      filterable: true,
      isDisabled: true,
      isVisible: classifiedSiteType === AppConst.GSAP_SITE_TYPE,
      filterChange: countryFilterChange,
    },
    {
      label: Locale.site_details?.retail_admin_update.retailer.territory,
      value: siteRetailerDetails?.Retailer?.territoryCd?.territoryName,
      editType: EditFieldTypeConst.DROPDOWN_FIELD,
      isDisabled: true,
      isVisible: classifiedSiteType === AppConst.GSAP_SITE_TYPE,
      isEditDataLoading: isTerritoryCodesLoading,
      onChange: onTerritoryChange,
      editData: territoryList,
      editValue: territorySelectedValue,
      filterable: true,
      filterChange: territoryFilterChange,
    },
    {
      label: Locale.landing_page?.sites_list.table_headers.city_label,
      value: siteRetailerDetails?.Retailer?.cityName,
      editType: EditFieldTypeConst.TEXT_FIELD,
      isDisabled: true,
      isVisible: classifiedSiteType === AppConst.GSAP_SITE_TYPE,
      editValue: cityName,
      onChange: onCityChange,
      maxLength: 150,
    },
    {
      label:
        Locale.site_details?.retail_admin_update.basic_details.street_address,
      value: siteRetailerDetails?.Retailer?.streetAddress,
      editType: EditFieldTypeConst.TEXT_FIELD,
      isDisabled: true,
      isVisible: classifiedSiteType === AppConst.GSAP_SITE_TYPE,
      editValue: streetAddress,
      onChange: onStreetAddressChange,
      maxLength: 35,
    },
    {
      label: Locale.site_details?.retail_admin_update.basic_details.postal_code,
      value: siteRetailerDetails?.Retailer?.postalCd?.trim(),
      editType: EditFieldTypeConst.TEXT_FIELD,
      isDisabled: true,
      isVisible: classifiedSiteType === AppConst.GSAP_SITE_TYPE,
      editValue: postalCode,
      onChange: onPostalCodeChange,
      maxLength: 35,
    },
    { label: "", value: "", isVisible: true },
    {
      label: Locale.site_details?.retail_admin_update.retailer.start_date,
      value: siteRetailerDetails?.customerStartDate
        ? format(
            new Date(siteRetailerDetails?.customerStartDate),
            AppConst.VIEW_DATE_FORMAT
          )
        : null,
      editType: EditFieldTypeConst.DATE_FIELD,
      editValue: customerStartDate
        ? format(new Date(customerStartDate), AppConst.VIEW_DATE_FORMAT)
        : null,
      onChange: onCustomerStartDateChange,
      isDisabled: classifiedSiteType === AppConst.GSAP_SITE_TYPE,
      isVisible:
        classifiedSiteType === AppConst.GSAP_SITE_TYPE ||
        classifiedSiteType === AppConst.NON_GSAP_SITE_TYPE,
      maxDate: new Date(),
      isValid: isCustomerStartDateValid !== -1,
      validationMessage: Locale.common?.required_field,
      required: true,
    },
    {
      label: Locale.site_details?.retail_admin_update.retailer.end_date,
      value: siteRetailerDetails?.customerEndDate
        ? format(
            new Date(siteRetailerDetails?.customerEndDate),
            AppConst.VIEW_DATE_FORMAT
          )
        : null,
      editType: EditFieldTypeConst.DATE_FIELD,
      editValue: customerEndDate
        ? format(new Date(customerEndDate), AppConst.VIEW_DATE_FORMAT)
        : null,
      onChange: onCustomerEndDateChange,
      isDisabled:
        classifiedSiteType === AppConst.GSAP_SITE_TYPE ||
        (classifiedSiteType === AppConst.NON_GSAP_SITE_TYPE &&
          !customerStartDate &&
          !sectionEditedData?.SiteRetailer?.customerStartDate),
      isVisible:
        classifiedSiteType === AppConst.GSAP_SITE_TYPE ||
        classifiedSiteType === AppConst.NON_GSAP_SITE_TYPE,
      minDate:
        new Date(
          (isSectionValueChanged &&
            sectionEditedData?.SiteRetailer?.customerStartDate) ||
            customerStartDate
        ) || undefined,
      isValid: isCustomerEndDateValid !== -1,
      validationMessage: Locale.common?.required_field,
      required: true,
    },
    { label: "", value: "", isVisible: true },
  ];

  return (
    <DetailsViewComponent
      data={getReatilerData()}
      classValue="mdm--row mdm--sd-data retailer-data"
      isLoading={isSiteRetailerDataLoading}
      isEditing={isEditing}
      isCreate={isCreate}
    />
  );
};
