export class RoleConst {
  // Shell Roles
  public static SALES_OPS_SME = "Sales Ops SME"; // Local role but can access sites globally
  public static MARKET_DATA_LEAD = "Market Data Lead"; // Local role but can access sites globally
  public static FUEL_PRICING_GLOBAL_SME = "Fuel Pricing Global SME"; // Global role

  public static TM_AND_DM = "TM & DM"; //Local role
  public static RETAILER = "Retailer"; //Local role
  public static GI_ACCESS_USER = "GI Access User"; //Local role
  public static FUEL_PRICING_LOCAL_SME = "Fuel Pricing Local SME"; //Local role

  public static GLOBAL_USER = "Global User"; //Read Only Role

  // Non-Shell Roles
  public static RBLA_GLOBAL_SME = "RBLA Global SME"; // Global role
  public static PARTNER_GLOBAL_SME = "Partner Global SME"; // Global role

  public static RBLA_LOCAL_SME = "RBLA Local SME"; //Local role
  public static PARTNER_LOCAL_SME = "Partner Local SME"; //Local role
}
