import IAppInisghtsService from "./app-insights/app-insights.interface";
import IAuthService from "./auth/auth.interface";
import AppInsightsService from "./app-insights/app-insights";
import AuthService from "./auth/auth";
import HttpService from "./http/http";
import TokenService from "./token-manager/token-manager";
import { IHttpService } from "./http/http.interface";
import { ITokenService } from "./token-manager/token-manager.interface";

export const appInsightsService: IAppInisghtsService = new AppInsightsService();
export const authService: IAuthService = new AuthService();
export const http: IHttpService = new HttpService();
export const tokenService: ITokenService = new TokenService();
