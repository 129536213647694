import axios from "axios";
import { http } from ".";
import { appConfig } from "../config";

export const ConfigurationService = {
  getConfigurationList: async (id: any) => {
    return await http.get(
      `${appConfig.baseUrl}/changerequest/configuration?countryCode=${id}`
    );
  },

  postConfigurationValue: (payload: any) => {
    return http.post(
      `${appConfig.baseUrl}/changerequest/configuration`,
      payload
    );
  },

  getPreApprovalList: async (id: any) => {
    return await http.get(
      `${appConfig.baseUrl}/changerequest/preapproval?countryCode=${id}`
    );
  },

  postPreApprovalData: (payload: any) => {
    return http.post(`${appConfig.baseUrl}/changerequest/preapproval`, payload);
  },

  getChangeRequestList: (payload: any) => {
    return http.get(
      `${appConfig.baseUrl}/changerequest/changerequest/?pageNo=${payload.page}&pageSize=${payload.pageSize}&orderBy=${payload.orderby}&search=${payload.searchText}&filter=${payload.filter}`
    );
  },
  getAllChangeRequestList: (linksArray: number[], payload: any) => {
    
    return axios
      .all(
        linksArray.map((link, index) =>
          http.get(`${appConfig.baseUrl}/changerequest/changerequest/?pageNo=${link}&pageSize=${100}&orderBy=${payload.orderby}&search=${payload.searchText}&filter=${payload.filter}`)
        )
      )
      .then(
        axios.spread((...responses) => {
          let resData: any[] = [];
          responses.forEach((response) => {
            resData = [...resData, ...response.data?.items];
          });
          return resData;
        })
      );
  },

  getChangeRequestViewDetails: (id: number) => {
    return http.get(
      `${appConfig.baseUrl}/changerequest/changerequest/detail/${id}`
    );
  },

  putChangeRequestApproveReject: (body: any , config:any) => {    
    return http.put(
      `${appConfig.baseUrl}/changerequest/changerequest/`,
      body,
      config
    );
  },
};
