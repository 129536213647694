import React, { useEffect, useState } from "react";
import { useSelector, useDispatch, batch } from "react-redux";
import {
  Routes,
  Route,
  BrowserRouter as Router,
  Navigate,
} from "react-router-dom";
import Cookies from "js-cookie";
import { HubConnection, HubConnectionBuilder } from "@microsoft/signalr";

import {
  APP_ROUTES,
  RoleConst,
  SignalRNotificationStatusConst,
} from "./../../constants";
import {
  ChangeHistory,
  Dashboard,
  LeadershipDashboard,
  LoginPage,
  SiteDetails,
  WorkflowActions,
} from "./../pages";

import { tokenService } from "../../services";
import ProtectedRoute from "../components/protected-route";
import { authenticationSuccess, setUser } from "../../redux/actions/auth";
import { setSiteUpdateConnection } from "../../redux/actions/siteUpdateConnection";
import { ToastNotification } from "../components/toast-notification/ToastNotification";
import { CreateSite } from "../pages/sites/create-site";
import { appConfig } from "../../config";
import { updateNotificationState } from "../../redux/actions/notificationState.actions";
import { refetchSiteData } from "../../redux/actions/refetchSiteData";
import ChangeRequest from "../pages/change-request/ChangeRequest";
import { Locale } from "../../utils/localization";
import { SessionExpiredNotification } from "../components";
import ChangeRequestHistory from "../pages/change-request/change-request-list/changerequestHistory/ChangeRequestHistory";

export default function AppRoutes() {
  const dispatch = useDispatch();

  const notificationState = useSelector(
    (state: any) => state.notificationState?.notificationState
  );

  const errorState = useSelector(
    (state: any) => state.apiErrorState?.apiErrorState
  );

  if (process.env.NODE_ENV === "development") {
    dispatch(
      authenticationSuccess({
        accessToken: "eyJhbGciOiJSUzI1NiIsImtpZCI6IlNJTV9TaWduXzEiLCJwaS5hdG0iOiJtcGVyIn0.eyJzY29wZSI6W10sImNsaWVudF9pZCI6IjY0MmQzOWI0MDc1YWE5OWMxZDM4YWMyYzcwZTcwNTU5IiwiaXNzIjoiaHR0cHM6Ly9zc28tdWF0LnNoZWxsLmNvbSIsImF1ZCI6InNzby11YXQuc2hlbGwuY29tIiwidWlkIjoiSU5BQ1NIIiwibWFpbCI6IkFudXBhbWEuQ2hhbmRyYXNla2FyQHNoZWxsLmNvbSIsImdpdmVuTmFtZSI6IkFudXBhbWEiLCJzbiI6IkNoYW5kcmFzZWthciIsImVtYWlsIjoiQW51cGFtYS5DaGFuZHJhc2VrYXJAc2hlbGwuY29tIiwiZXhwIjoxNjk0Nzg1NDM2fQ.qW0U_wCgD9f3GTpXNgct_c5O_2nobajiS0-ThJ7wf0SGcmdI62fK6TtbJOrY2ZC3nYEx-Y016pnv42C4X7OyrwalOjo8n6D6F6-9cGkouHlOu3i0bq4jnNHlg76KpEbnHyjFvMrEAjw3THtVLSdb_jl3PFo7m_B4dZsLOVde6pWIWgJILdXWmMZGCxNRnMoht9ZThdTE6gWZLFY7npHGYa7Y3dyJkgdsb4N7wWKuU5iD7rwF9SLSHpE4vjdP4LwBp8X4rftzUzw24wxggxB2W7VZEozW1U1OHkEw-ak3ht1ehBabIKoAbiJqeHyWk2QDubkZoU4GkyZ1qhi4mmhDwg",
        expiryTime: 899,
        tokenType: "Bearer",
      })
    );

    dispatch(
      setUser({
        userLoginId: "INVCKZ",
        userType: "shell",
        firstName: "Vishal",
        lastName: "Chavan",
        emailAddress: "Vishal.Chavan@shell.com",
        userStatus: "Active",
        lastLogInTime: "2022-09-15T00:00:00",
        lastUpdateUserId: "INVCKZ",
        lastUpdateGMTdtm: "2022-09-02T00:00:00",
        leadershipRoleInd: true,
        userRoleMapping: [
          {
            countryCode: "DE",
            businessCustomerNumber: null,
          },
        ],
        roleDetail: {
          userRoleId: "8",
          userRoleName: RoleConst.SALES_OPS_SME,
          globalRoleIndicator: true,
          retailerRoleIndicator: false,
          retailSiteTypeCodes: ["6"],
          lastUpdateGMT: "0001-01-01T00:00:00",
          permission: {
            addSiteType: "PartnerSite",
            updateSite: true,
            searchSite: true,
            viewSite: true,
            viewConfidential: true,
            viewWorkflowTaskList: false,
            dataValidationRequest: false,
            workFlowForAssignedCountry: false,
            workFlowForNonAssignedCountry: false,
            disableSiteDashboardForLeadership: false,
            RSMAChangeRequestView: true,
          },
        },
      })
    );
  }

  const [connection, setConnection] = useState<null | HubConnection | any>(
    null
  );

  const [showToastNotification, setShowToastNotification] = useState(false);
  const [toastNotificationMessage, setToastNotificationMessage] = useState<
    string[]
  >([""]);
  const [toastNotificationType, setToastNotificationType] = useState("");
  const [requestId, setRequestId] = useState("");
  const [isSessionExpired, setIsSessionExpired] = useState(false);

  const [popupTitle, setPopupTitle] = useState("");
  const [popupMessage, setPopupMessage] = useState("");

  useEffect(() => {
    const connect = new HubConnectionBuilder()
      .withUrl(appConfig.signalRUrl || "")
      .withAutomaticReconnect()
      .build();
    setConnection(connect);
  }, []);

  useEffect(() => {
    if (
      showToastNotification &&
      toastNotificationType !== "none" &&
      toastNotificationMessage?.length &&
      requestId
    ) {
      const newData = notificationState?.map((notification: any) => {
        if (notification?.requestId === requestId) {
          return {
            ...notification,
            errorMessage: toastNotificationMessage[1],
            status:
              toastNotificationType === "error"
                ? SignalRNotificationStatusConst.FAILED_NOTIFICATION
                : toastNotificationType === "success"
                ? SignalRNotificationStatusConst.SUCCESS_NOTIFICATION
                : "",
          };
        }
        return { ...notification };
      });
      dispatch(updateNotificationState([...newData]));
    }
  }, [showToastNotification, toastNotificationType, toastNotificationMessage]);

  useEffect(() => {
    if (connection) {
      connection
        .start()
        .then(() => {
          console.log("SignalR Started:", connection?.connection.connectionId);
        })
        .catch((error: string) => console.log(error));
      connection.on("SiteUpdateNotification", (message: any) => {
        const msg = message;
        console.log("SignalR Response-", msg);
        const gSiteId = msg?.globalSiteId;

        setRequestId(msg?.requestId);

        if (msg?.message === "Success" && msg?.isEdit !== "True" && gSiteId) {
          setShowToastNotification(true);
          setToastNotificationType("success");
          dispatch(refetchSiteData({ siteId: gSiteId, isRefetch: true }));
          setToastNotificationMessage([
            "Success",
            `GlobalSiteId: ${gSiteId} Created Successfully`,
          ]);
        } else if (
          msg?.message === "Success" &&
          msg?.isEdit === "True" &&
          gSiteId
        ) {
          setShowToastNotification(true);
          setToastNotificationType("success");
          dispatch(refetchSiteData({ siteId: gSiteId, isRefetch: true }));
          setToastNotificationMessage([
            "Success",
            `GlobalSiteId: ${gSiteId} - Edited Successfully`,
          ]);
        } else if (
          msg?.message?.includes(
            Locale.create_site?.error_messages?.workflow_triggered_msg
          ) &&
          msg?.isEdit === "True" &&
          gSiteId
        ) {
          setShowToastNotification(true);
          setToastNotificationType("success");
          dispatch(refetchSiteData({ siteId: gSiteId, isRefetch: true }));
          setToastNotificationMessage([
            "Success",
            `GlobalSiteId : ${gSiteId} - ${Locale.create_site?.error_messages?.workflow_triggered_msg}, Changes will reflect once workflow is approved.`,
          ]);
        } else if (gSiteId) {
          const err = msg?.message || msg?.errorMessage || msg?.cause || "";
          setShowToastNotification(true);
          setToastNotificationType("error");
          setToastNotificationMessage([
            "Error",
            `GlobalSiteId : ${gSiteId} - ${err}`,
          ]);
          // Removed this bcoz of error message - "Mandatory to provide atleast one of the following fuel products, if HGV Lane facility is present. Fuel Products - Premium Diesel, Fuelsave Regular Diesel, Regular Diesel, Hydrogen, Truck Diesel, DieselFit, Electric Charging: Shell Recharge, Electric Charging: Other, Biodiesel, RNG, LNG"
          // setToastNotificationMessage([
          //   "Error",
          //   `GlobalSiteId : ${gSiteId} - ${err?.substring(
          //     err?.indexOf(":") + 1
          //   )}`,
          // ]);
        } else if (msg) {
          if (msg[0].includes("successfully")) {
            setShowToastNotification(true);
            setToastNotificationType("success");
            setToastNotificationMessage(["Success", ...msg]);
          } else {
            setShowToastNotification(true);
            setToastNotificationType("error");
            setToastNotificationMessage(["Error", ...msg]);
          }
        } else {
          const err = msg?.message || msg?.errorMessage || msg?.cause || "";
          setShowToastNotification(true);
          setToastNotificationType("error");
          setToastNotificationMessage([
            "Error",
            `Create new site failed - ${err?.substring(err?.indexOf(":") + 1)}`,
          ]);
        }
        setTimeout(() => {
          setShowToastNotification(false);
          setToastNotificationMessage([]);
          setToastNotificationType("none");
        }, 15000);
      });
      batch(() => {
        dispatch(setSiteUpdateConnection(connection));
      });
    }
  }, [connection]);

  useEffect(() => {
    if (+errorState?.error?.response?.status === 401) {
      setIsSessionExpired(true);
      if (errorState?.error?.response?.data?.errorMessages) {
        setPopupTitle(Locale.login_page?.session_expired?.unauthorized);
        setPopupMessage(errorState?.error?.response?.data?.errorMessages?.[0]);
      } else {
        Cookies.remove("loginPayload");
        Cookies.remove("reduxstoredata");
        Cookies.remove("expiry");
        setPopupTitle(
          Locale.login_page?.session_expired?.session_expired_title
        );
        setPopupMessage(
          Locale.login_page?.session_expired?.session_expired_desc
        );
        //Authorization value is incorrect to process the request.
      }
      tokenService.setAuthData({ access_token: "" }, 0, "shell");
      tokenService.setAuthData({ access_token: "" }, 0, "nonshell");
    } else if (Object.keys(errorState).length && errorState?.error?.message) {
      setShowToastNotification(true);
      setToastNotificationType("error");
      setToastNotificationMessage([errorState?.error?.message || ""]);
    }
  }, [errorState]);

  useEffect(() => {
    const sessionData = Cookies.get("reduxstoredata");
    const parsedSessionData = sessionData ? JSON.parse(sessionData) : {};
    const url = window.location.origin;
    const href = window.location.href;
    if (
      parsedSessionData?.auth?.accessToken &&
      (url === href || href === url + APP_ROUTES.LOGIN_PATH)
    ) {
      if (parsedSessionData?.user?.leadershipRoleInd) {
        window.location.replace(url + APP_ROUTES.LEADERSHIP_DASHBOARD_PATH);
      } else {
        window.location.replace(url + APP_ROUTES.DASHBOARD_PATH);
      }
    }
  }, []);

  return (
    <>
      <Router>
        <Routes>
          <Route
            path={APP_ROUTES.ROOT_PATH}
            element={<Navigate to={APP_ROUTES.LOGIN_PATH} />}
          />
          <Route path={APP_ROUTES.LOGIN_PATH} element={<LoginPage />} />
          <Route element={<ProtectedRoute />}>
            <Route
              path={APP_ROUTES.LEADERSHIP_DASHBOARD_PATH}
              element={<LeadershipDashboard />}
            />
            <Route path={APP_ROUTES.DASHBOARD_PATH} element={<Dashboard />} />
            <Route path={APP_ROUTES.SITES_PATH}>
              <Route path={APP_ROUTES.SITE_DETAILS} element={<SiteDetails />} />
            </Route>
            <Route path={"/changerequestdetails"}>
              <Route
                path={APP_ROUTES.SITE_DETAILS}
                element={<ChangeRequestHistory />}
              />
            </Route>
            <Route
              path={APP_ROUTES.CHANGE_HISTORY_PATH}
              element={<ChangeHistory />}
            />
            <Route
              path={APP_ROUTES.WORKFLOW_ACTIONS_PATH}
              element={<WorkflowActions />}
            />
            <Route
              path={APP_ROUTES.RSMA_CONFIGURATION_PATH}
              element={<ChangeRequest />}
            />
            <Route path={APP_ROUTES.CREATE_SITE} element={<CreateSite />} />
          </Route>
        </Routes>
      </Router>
      <ToastNotification
        show={showToastNotification}
        type={toastNotificationType || "none"}
        message={toastNotificationMessage}
      />
      {isSessionExpired && (
        <SessionExpiredNotification
          showPopup={isSessionExpired}
          popupTitle={popupTitle}
          popupMessage={popupMessage}
        />
      )}
    </>
  );
}
