import React, { useState, useEffect } from "react";
import Cookies from "js-cookie";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { Locale } from "../../../utils/localization";

import "./SessionExpiredNotification.scss";

interface IProps {
  showPopup: boolean;
  popupMessage: string;
  popupTitle: string;
}

export const SessionExpiredNotification = (props: IProps) => {
  const { showPopup, popupMessage, popupTitle } = props;

  const [showDialog, setShowDialog] = useState(false);

  useEffect(() => {
    if (showPopup) {
      setShowDialog(true);
    }
  }, [showPopup]);

  const okBtnClick = () => {
    setShowDialog(false);
    const url = window.location.origin;
    window.location.replace(url);
  };

  return (
    <>
      {showDialog && (
        <Dialog
          title={popupTitle}
          className="mdm--session-expired-portal"
          closeIcon={false}
        >
          <p style={{ margin: "25px", textAlign: "center" }}>{popupMessage}</p>
          <DialogActionsBar>
            <button
              className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
              onClick={okBtnClick}
            >
              {Locale.common?.ok}
            </button>
          </DialogActionsBar>
        </Dialog>
      )}
    </>
  );
};
