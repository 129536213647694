import { useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Cookies from "js-cookie";

import { AppBar } from "../appbar";
import { DrawerRouterContainer } from "../drawer-router-container";
import { LeadershipDashboard } from "../../pages";
import { APP_ROUTES, RoleConst } from "../../../constants";
import { authenticationSuccess, setUser } from "../../../redux/actions/auth";
import {
  renewNonShellAccessToken,
  renewShellAccessToken,
} from "../../../services/endpoints";
import { tokenService } from "../../../services";

const ProtectedRoute = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { pathname } = useLocation();

  const sessionData = Cookies.get("reduxstoredata");
  const parsedSessionData = sessionData ? JSON.parse(sessionData) : {};
  const auth = useSelector((state: any) => state?.auth);

  useEffect(() => {
    if (parsedSessionData?.auth?.accessToken) {
      const storageData = parsedSessionData;
      dispatch(authenticationSuccess(storageData?.auth));
      dispatch(setUser(storageData?.user));
    }
  }, [parsedSessionData]);

  // const auth = useSelector((state: any) => {
  //   // const sessionData = localStorage.getItem("reduxstoredata");
  //   const sessionData = Cookies.get("reduxstoredata");
  //   console.log(sessionData && JSON.parse(sessionData));
  //   console.log(state);
  //   if (firstAttempt && sessionData && !state?.auth?.accessToken) {
  //     firstAttempt = false;
  //     const storageData = JSON.parse(sessionData);
  //     dispatch(authenticationSuccess(storageData.auth));
  //     dispatch(setUser(storageData?.user));
  //     // localStorage.removeItem("reduxstoredata");
  //     return storageData;
  //   }
  //   // localStorage.removeItem("reduxstoredata");
  //   return state?.auth;
  // });

  // useEffect(() => {
  //   console.log(auth);
  //   if (auth) {
  //     const inFifteenMinutes = new Date(new Date().getTime() + 15 * 60 * 1000);
  //     Cookies.set("reduxstoredata", JSON.stringify(auth), {
  //       expires: inFifteenMinutes,
  //     });
  //   }
  //   // auth && localStorage.setItem("reduxstoredata", JSON.stringify(auth));
  // }, [auth]);

  // window.addEventListener("beforeunload", () => {
  //   localStorage.setItem("reduxstoredata", JSON.stringify(auth));
  // });

  useEffect(() => {
    localStorage.setItem("previous", pathname);
    setTimeout(() => {
      localStorage.removeItem("previous");
    }, 15000);
  }, []);

  useEffect(() => {
    setInterval(() => {
      const sessionExpiryTimeString = Cookies.get("expiry");
      const sessionData = Cookies.get("reduxstoredata");
      const storageData = sessionData && JSON.parse(sessionData);
      const expiryTime =
        sessionExpiryTimeString &&
        new Date(new Date(sessionExpiryTimeString).getTime() - 20000);
      const refreshToken: string = storageData?.auth?.refreshToken;
      const userType: string = storageData?.user?.userType;

      if (
        sessionExpiryTimeString &&
        expiryTime &&
        expiryTime <= new Date() &&
        refreshToken &&
        userType
      )
        if (userType === "shell") {
          renewShellAccessToken(refreshToken)?.catch((err) => {
            tokenService.setAuthData({ access_token: "" }, 0, "shell");
            console.log(err);
          });
        } else {
          renewNonShellAccessToken(refreshToken)?.catch((err) => {
            tokenService.setAuthData({ access_token: "" }, 0, "nonshell");
            console.log(err);
          });
        }
    }, 5000);
  }, []);

  useEffect(() => {
    const roleName = auth?.user?.roleDetail?.userRoleName;
    const RSMAChangeRequestView =
      auth?.user?.roleDetail?.permission?.RSMAChangeRequestView;

    if (!auth?.auth?.accessToken) {
      navigate(`/`);
    } else if (
      pathname === APP_ROUTES.LEADERSHIP_DASHBOARD_PATH && // if pathname is leadership dashbaord & user dont have permission
      !auth?.user?.leadershipRoleInd
    ) {
      navigate(`/`);
    } else if (
      pathname !== APP_ROUTES.LEADERSHIP_DASHBOARD_PATH && // if sites dashboard permission is disabled and pathname is apart from leadership dashboard
      auth?.user?.roleDetail?.permission?.disableSiteDashboardForLeadership
    ) {
      navigate(`/`);
    } else if (
      //disable workflow path for other roles
      pathname === APP_ROUTES.WORKFLOW_ACTIONS_PATH &&
      roleName !== RoleConst.MARKET_DATA_LEAD &&
      roleName !== RoleConst.SALES_OPS_SME &&
      roleName !== RoleConst.GI_ACCESS_USER &&
      roleName !== RoleConst.RETAILER &&
      roleName !== RoleConst.FUEL_PRICING_GLOBAL_SME &&
      roleName !== RoleConst.FUEL_PRICING_LOCAL_SME
    ) {
      navigate(`/`);
    } else if (
      //disable create site path for other roles
      pathname === APP_ROUTES.CREATE_SITE &&
      roleName !== RoleConst.FUEL_PRICING_GLOBAL_SME &&
      roleName !== RoleConst.FUEL_PRICING_LOCAL_SME &&
      roleName !== RoleConst.RBLA_GLOBAL_SME &&
      roleName !== RoleConst.RBLA_LOCAL_SME &&
      roleName !== RoleConst.PARTNER_GLOBAL_SME &&
      roleName !== RoleConst.SALES_OPS_SME
    ) {
      navigate(`/`);
    } else if (
      pathname === APP_ROUTES.RSMA_CONFIGURATION_PATH && // if pathname is RSMA config and dont have permission to view
      !RSMAChangeRequestView
    ) {
      navigate(`/`);
    }
  }, [pathname, auth]);

  const localEnv = process.env.NODE_ENV === "development";

  return (
    (localEnv || auth?.auth?.accessToken) && (
      <>
        <AppBar />
        {pathname === APP_ROUTES.LEADERSHIP_DASHBOARD_PATH ? (
          <LeadershipDashboard />
        ) : (
          <DrawerRouterContainer>
            <Outlet />
          </DrawerRouterContainer>
        )}
      </>
    )
  );
};

export default ProtectedRoute;
