export const initialState = { connection: {} };

interface ActionProps {
  type: String;
  payload?: any;
}

export default function siteUpdateConnectionReducer(
  state = initialState,
  action: ActionProps
) {
  switch (action.type) {
    case "SET_SITE_UPDATE_CONNECTION":
      state.connection = action.payload;
      return state;

    default:
      return state;
  }
}
