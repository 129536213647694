import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";

import {
  DropDownList,
  DropDownListChangeEvent,
} from "@progress/kendo-react-dropdowns";
import { Skeleton } from "@progress/kendo-react-indicators";
import { Input, InputChangeEvent } from "@progress/kendo-react-inputs";

import {
  AppConst,
  EditFieldTypeConst,
} from "../../../../../../../../constants";
import { useLegacySiteTypesCodesData } from "../../../../../../../../hooks";
import { TableDetailsViewData } from "../../../../../../../../models";
import { SitesService } from "../../../../../../../../services/sites.service";
import { Locale } from "../../../../../../../../utils/localization";
import { TableDetailsView } from "../../../../../../../components/table-details-view/TableDetailsView";

const fetchSiteLegacyIDData = ({ queryKey }: any) => {
  const siteId = queryKey[1];
  return SitesService.getSiteDetails({
    globalSiteId: siteId || "",
    viewName: "SiteLegacyKeysResults",
  });
};

export const LegacyIdsTab = (props: any) => {
  const {
    siteId,
    isEditing,
    onPatchRequestValueSet,
    onSectionValueSet,

    isSectionValueChanged,
    onSectionValueChanged,
    sectionEditedData,
    basicDetailsTabSelected,
    isCreate,
    classifiedSiteType,
    refetchAllData,
  } = props;

  const [currentStateOfLegacySitesData, setCurrentStateOfLegacySitesData] =
    useState<TableDetailsViewData>({ tableHeaders: [] });
  const [savedStateOfLegacySitesData, setSavedStateOfLegacySitesData] =
    useState<any>();

  const [newLegacyTypeSelectedValue, setNewLegacyTypeSelectedValue] =
    useState<any>();
  const [newLegacySiteId, setNewLegacySiteId] = useState<string | undefined>();

  const [isNewLegacyRowValid, setIsNewLegacyRowValid] = useState(0);
  const [isNewLegacyTypeValid, setIsNewLegacyTypeValid] = useState(0);
  const [isNewLegacySiteIdValid, setIsNewLegacySiteIdValid] = useState(0);
  const [newLegacyRowInvalidText, setNewLegacyRowInvalidText] = useState("");

  const {
    isLoading: isSiteLegacyDataLoading,
    data: fetchedSiteLegacyData,
    refetch: refetchSiteLegacyIDData,
  } = useQuery([`site-legacy-data-${siteId}`, siteId], fetchSiteLegacyIDData, {
    staleTime: AppConst.VIEW_SITE_DETAILS_STALE_TIME,
    enabled: false,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    if (basicDetailsTabSelected === 4 && siteId) {
      refetchSiteLegacyIDData();
    }
  }, [basicDetailsTabSelected]);

  useEffect(() => {
    if (refetchAllData && siteId && basicDetailsTabSelected === 4) {
      refetchSiteLegacyIDData();
    }
  }, [refetchAllData, basicDetailsTabSelected]);

  const {
    isLoading: isLegacySiteTypeCodesLoading,
    data: legacySiteTypeCodes,
    refetch: refetchLegacySiteTypeCodes,
  } = useLegacySiteTypesCodesData();

  useEffect(() => {
    if (isEditing && basicDetailsTabSelected === 4) {
      refetchLegacySiteTypeCodes();
    }
  }, [isEditing, basicDetailsTabSelected]);

  const [defaultData, setDefaultData] = useState<any>([]);
  useEffect(() => {
    if (fetchedSiteLegacyData) {
      const defaultFacility =
        fetchedSiteLegacyData?.data?.result?.SiteLegacyKeys?.item?.map(
          (item: any, index: number) => {
            return {
              index: index,
              legacySiteId: item.legacySiteId,
              legacySiteTypeName: item.legacySiteTypeCd?.legacySiteTypeName,
              legacySiteTypeCd: item.legacySiteTypeCd?.legacySiteTypeCd,
            };
          }
        );
      setDefaultData(defaultFacility);
    }
  }, [fetchedSiteLegacyData]);

  const onEditRow = (currentState: any, event: any, row: any, field: any) => {
    const newCurrentState = [...(currentState?.rows || [])].map((row: any) => {
      const { isValid, validationMessage, ...allData } = row;
      return allData;
    });
    const currentData = [...(newCurrentState || [])];
    const filterData = currentData?.map((item: any) => {
      if (row.id === item.id) {
        if (field === "legacySiteTypeName") {
          return {
            ...item,
            [field]: event?.value,
            legacySiteTypeCd: event?.id,
          };
        }
        return { ...item, [field]: event?.value };
      }
      return { ...item };
    });
    setCurrentStateOfLegacySitesData({ ...currentState, data: filterData });
  };

  const onDiscardRow = (rowId: string) => {
    const discardRow = [...savedStateOfLegacySitesData?.data]?.filter(
      (row: any) => row.id === rowId
    )?.[0];

    const currentState = currentStateOfLegacySitesData?.data?.map(
      (row: any) => {
        if (row.id === rowId) {
          return discardRow;
        }
        return { ...row };
      }
    );

    const filteredArray = currentState?.filter((row: any) => {
      return row;
    });

    setCurrentStateOfLegacySitesData({
      ...currentStateOfLegacySitesData,
      data: filteredArray,
    });
  };

  const onDeleteRow = (rowId: string) => {
    const currentState = currentStateOfLegacySitesData?.data?.filter(
      (row: any) => {
        return row.id !== rowId;
      }
    );
    setCurrentStateOfLegacySitesData({
      ...currentStateOfLegacySitesData,
      data: currentState,
    });

    const savedState = savedStateOfLegacySitesData?.data?.filter((row: any) => {
      return row.id !== rowId;
    });

    onSectionValueChanged(true);
    setSavedStateOfLegacySitesData({
      ...savedStateOfLegacySitesData,
      data: savedState,
    });
    onSectionValueSet({
      SiteLegacyKeys: { ...savedStateOfLegacySitesData, data: savedState },
    });
    const payload = savedState?.map((legacy: any) => {
      return {
        legacySiteId: legacy.legacySiteId,
        legacySiteTypeCd: {
          legacySiteTypeCd: legacy.legacySiteTypeCd,
        },
      };
    });
    onPatchRequestValueSet(
      {
        SiteLegacyKeys: {
          item: payload,
        },
      },
      true
    );
  };

  const onSaveRow = (rowId: string) => {
    let isValid: boolean = true;

    const filteredData: any = currentStateOfLegacySitesData?.data?.filter(
      (row: any) => {
        return row.id === rowId;
      }
    )?.[0];

    let newCurrentState: any = [...(currentStateOfLegacySitesData?.data || [])];

    if (!filteredData?.legacySiteId) {
      isValid = false;
      newCurrentState = currentStateOfLegacySitesData?.data?.map((row: any) => {
        if (row.id === rowId) {
          return {
            ...row,
            isValid: false,
            validationMessage:
              Locale.create_site?.error_messages.legacy_required_fields,
          };
        }
        return row;
      });
    } else {
      const existedRecord: any = currentStateOfLegacySitesData?.data?.find(
        (row: any) => {
          return (
            row.legacySiteId === filteredData?.legacySiteId &&
            row.legacySiteTypeCd === filteredData?.legacySiteTypeCd &&
            row?.id !== filteredData?.id
          );
        }
      );

      if (existedRecord?.legacySiteId) {
        isValid = false;
        newCurrentState = currentStateOfLegacySitesData?.data?.map(
          (row: any) => {
            if (row.id === rowId) {
              return {
                ...row,
                isValid: false,
                validationMessage:
                  Locale.create_site?.error_messages.legacy_already_exists,
              };
            }
            return row;
          }
        );
      }
    }

    setCurrentStateOfLegacySitesData({
      ...currentStateOfLegacySitesData,
      data: newCurrentState,
    });

    if (isValid) {
      const savedState = savedStateOfLegacySitesData?.data?.map((row: any) => {
        if (row.id === rowId) {
          return filteredData;
        }
        return { ...row };
      });

      onSectionValueChanged(true);
      onSectionValueSet({
        SiteLegacyKeys: {
          ...currentStateOfLegacySitesData,
          data: savedState,
        },
      });
      setSavedStateOfLegacySitesData({
        ...currentStateOfLegacySitesData,
        data: savedState,
      });
      const payload = savedState.map((legacy: any) => {
        return {
          legacySiteId: legacy.legacySiteId,
          legacySiteTypeCd: {
            legacySiteTypeCd: legacy.legacySiteTypeCd,
          },
        };
      });
      onPatchRequestValueSet(
        {
          SiteLegacyKeys: {
            item: payload,
          },
        },
        true
      );
    }
    return isValid;
  };

  const onAddNewLegacyIdClick = () => {
    if (newLegacyTypeSelectedValue) {
      setIsNewLegacyTypeValid(1);
    } else {
      setIsNewLegacyTypeValid(-1);
      setIsNewLegacyRowValid(-1);
      setNewLegacyRowInvalidText(
        Locale.create_site?.error_messages.legacy_required_fields
      );
    }
    if (newLegacySiteId) {
      setIsNewLegacySiteIdValid(1);
    } else {
      setIsNewLegacySiteIdValid(-1);
      setIsNewLegacyRowValid(-1);
      setNewLegacyRowInvalidText(
        Locale.create_site?.error_messages.legacy_required_fields
      );
    }
    if (newLegacyTypeSelectedValue && newLegacySiteId) {
      const findIndex = savedStateOfLegacySitesData?.data?.find((site: any) => {
        return (
          site.legacySiteTypeCd === newLegacyTypeSelectedValue?.id &&
          site?.legacySiteId === newLegacySiteId
        );
      });

      if (findIndex) {
        setIsNewLegacyRowValid(-1);
        setIsNewLegacyTypeValid(-1);
        setIsNewLegacySiteIdValid(-1);
        setNewLegacyRowInvalidText(
          Locale.create_site?.error_messages.legacy_already_exists
        );
      } else {
        setIsNewLegacyRowValid(1);
        const newData: any = {
          id: new Date().getMilliseconds() + 1000,
          legacySiteId: newLegacySiteId || "-",
          legacySiteTypeName: newLegacyTypeSelectedValue?.value || "-",
          legacySiteTypeCd: newLegacyTypeSelectedValue?.id || "",
          editActionRow: "",
        };
        const currentData = [...(currentStateOfLegacySitesData?.data || [])];
        currentData.unshift(newData);

        var currentState: any = {
          ...currentStateOfLegacySitesData,
          data: [...currentData],
        };
        setCurrentStateOfLegacySitesData(currentState);
        setNewLegacyTypeSelectedValue(null);
        setNewLegacySiteId(undefined);

        onSectionValueChanged(true);
        setSavedStateOfLegacySitesData({
          ...savedStateOfLegacySitesData,
          data: currentState.data,
        });
        onSectionValueSet({
          SiteLegacyKeys: {
            ...savedStateOfLegacySitesData,
            data: currentState.data,
          },
        });
        const payload = currentState?.data?.map((legacyRow: any) => {
          return {
            legacySiteId: legacyRow.legacySiteId,
            legacySiteTypeCd: {
              legacySiteTypeCd: legacyRow.legacySiteTypeCd,
            },
          };
        });
        onPatchRequestValueSet(
          {
            SiteLegacyKeys: {
              item: payload,
            },
          },
          true
        );
        return true;
      }
      return false;
    }
    return false;
  };

  const onNewLegacySiteTypeChange = (event: DropDownListChangeEvent) => {
    setNewLegacyTypeSelectedValue(event.target.value);
    setIsNewLegacyTypeValid(1);
    setIsNewLegacyRowValid(1);
  };

  const onNewLegacyIdChange = (event: InputChangeEvent) => {
    setNewLegacySiteId(event.value);
    setIsNewLegacyTypeValid(1);
    setIsNewLegacySiteIdValid(1);
    setIsNewLegacyRowValid(1);
  };

  const onShowNewRowClick = () => {
    setNewLegacySiteId("");
    setIsNewLegacySiteIdValid(1);
    setIsNewLegacyRowValid(1);
    setIsNewLegacyTypeValid(1);
    setNewLegacyTypeSelectedValue(undefined);
    setCurrentStateOfLegacySitesData({
      ...currentStateOfLegacySitesData,
      data: savedStateOfLegacySitesData.data,
    });
  };

  const renderAddNewLegacyIdUI = () => {
    return (
      <>
        <div className="mdm--table-td">
          {isLegacySiteTypeCodesLoading ? (
            <Skeleton shape={"text"} style={{ width: "100%" }} />
          ) : (
            <>
              <DropDownList
                data={legacySiteTypeCodes}
                value={newLegacyTypeSelectedValue}
                onChange={onNewLegacySiteTypeChange}
                textField="value"
                dataItemKey="id"
                valid={isNewLegacyTypeValid !== -1 ? true : false}
              />
            </>
          )}
        </div>
        <div className="mdm--table-td">
          <Input
            type={"text"}
            value={newLegacySiteId}
            onChange={onNewLegacyIdChange}
            valid={isNewLegacySiteIdValid !== -1 ? true : false}
            maxLength={10}
            required={true}
          />
        </div>
      </>
    );
  };

  useEffect(() => {
    let list: string[] = [];
    const tbHeaders = [
      {
        label:
          Locale.site_details?.retail_admin_update.legacy_ids.legacy_site_type,
        field: "legacySiteTypeName",
        editType: EditFieldTypeConst.DROPDOWN_FIELD,
        editData: legacySiteTypeCodes,
        onChange: onEditRow,
      },
      {
        label:
          Locale.site_details?.retail_admin_update.legacy_ids.legacy_site_id,
        field: "legacySiteId",
        editType: EditFieldTypeConst.TEXT_FIELD,
        onChange: onEditRow,
        maxLength: 10,
      },
      {
        label: "",
        field: "editActionRow",
      },
    ];
    if (isSectionValueChanged && sectionEditedData?.SiteLegacyKeys) {
      list =
        sectionEditedData?.SiteLegacyKeys?.data?.map((item: any) => {
          return {
            id: item.id,
            legacySiteId: item.legacySiteId,
            legacySiteTypeName: item.legacySiteTypeName,
            legacySiteTypeCd: item.legacySiteTypeCd,
            editActionRow: "",
          };
        }) || [];
    } else {
      list =
        fetchedSiteLegacyData?.data?.result?.SiteLegacyKeys?.item?.map(
          (item: any, index: number) => {
            return {
              id: index,
              legacySiteId: item.legacySiteId,
              legacySiteTypeName: item.legacySiteTypeCd?.legacySiteTypeName,
              legacySiteTypeCd: item.legacySiteTypeCd?.legacySiteTypeCd,
              editActionRow: "",
            };
          }
        ) || [];
    }
    const legacyData: TableDetailsViewData = {
      tableHeaders: tbHeaders,
      data: list,
    };
    setCurrentStateOfLegacySitesData(legacyData);
    setSavedStateOfLegacySitesData(legacyData);
  }, [
    fetchedSiteLegacyData,
    legacySiteTypeCodes,
    isEditing,
    basicDetailsTabSelected,
  ]);

  return (
    <TableDetailsView
      className="legacy-ids"
      data={{
        tableHeaders: currentStateOfLegacySitesData.tableHeaders,
        rows: currentStateOfLegacySitesData?.data,
        defaultData: defaultData,
      }}
      isLoading={isSiteLegacyDataLoading}
      isEditing={isEditing}
      renderAddNewRowUI={renderAddNewLegacyIdUI}
      deleteRow={onDeleteRow}
      onAddRowClick={onAddNewLegacyIdClick}
      onEditDiscard={onDiscardRow}
      onSaveRow={onSaveRow}
      onShowNewRowClick={onShowNewRowClick}
      isCreate={isCreate}
      isNewRowValid={isNewLegacyRowValid}
      newRowInvalidText={newLegacyRowInvalidText}
    />
  );
};
