import * as ReactDOM from "react-dom";

export function Loader(props: any) {
  const loadingPanel = (
    <div className={`k-loading-mask ${props.className}`}>
      <span className="k-loading-text">Loading</span>
      <div className="k-loading-image" />
      <div className="k-loading-color" />
    </div>
  );

  const gridContent = document && document.querySelector(".k-grid-content");
  return gridContent
    ? ReactDOM.createPortal(loadingPanel, gridContent)
    : loadingPanel;
}
