import React, { useEffect, useState } from "react";
import { Skeleton } from "@progress/kendo-react-indicators";
import { Tooltip } from "@progress/kendo-react-tooltip";
import { Button } from "@progress/kendo-react-buttons";
import { Input, NumericTextBox } from "@progress/kendo-react-inputs";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { TimePicker } from "@progress/kendo-react-dateinputs";
import { Error } from "@progress/kendo-react-labels";

import { TableDetailsViewData } from "../../../models";
import { EditFieldTypeConst } from "../../../constants";
import { Locale } from "./../../../utils/localization";

import "./TableDetailsView.scss";

export const TableDetailsView = (props: any) => {
  const {
    data,
    className,
    isLoading,
    isEditing,
    renderAddNewRowUI,
    onAddRowClick,
    onEditDiscard,
    deleteRow,
    onSaveRow,
    onShowNewRowClick,
    onEditClicked,
    isNewRowValid,
    newRowInvalidText,
    isCreate,
  } = props;

  const [showAddRecordRowDisabled, setShowAddRecordRowDisabled] =
    useState(false);
  const [columnData, setColumnData] = useState<any>();

  const onShowAddNewRecordClick = () => {
    setShowAddRecordRowDisabled(true);
    onShowNewRowClick();
  };

  const onAddRecordClick = () => {
    const a = onAddRowClick();
    setShowAddRecordRowDisabled(true);
    if (a) {
      setShowAddRecordRowDisabled(false);
    }
  };

  const onCancelAddRecordClick = () => {
    setShowAddRecordRowDisabled(false);
  };

  const renderAddNewRowActionButtonsUI = () => (
    <div className="mdm--table-edit-row-action-btn-section mdm--table-td mdm--row">
      <Button
        className="buttons-container-button"
        icon="check"
        size="small"
        rounded="full"
        onClick={onAddRecordClick}
      />
      <Button
        className="buttons-container-button"
        icon="clear"
        size="small"
        rounded="full"
        onClick={onCancelAddRecordClick}
      />
    </div>
  );
  const editRowData = (row: any) => {
    var elems = document.querySelectorAll(`.${className} .edit-row`);
    [].forEach.call(elems, (el: any) => {
      el.classList.remove("edit-row");
    });

    var elems = document.querySelectorAll(`.${className} .editable`);
    [].forEach.call(elems, (el: any) => {
      el.classList.remove("editable");
    });

    var element: any = document.querySelector(
      `.${className} #${className}-${row.id}`
    );
    element.classList.add("editable");
    element.classList.add("edit-row");

    if (className === "facility" || className === "hydrogen-mobility") {
      onEditClicked(row);
    }
  };

  const discardRowData = (row: any) => {
    var elems = document.querySelectorAll(
      `.${className} .mdm--sd-rad-table-body > .table--row > .mdm--row`
    );
    [].forEach.call(elems, (el: any) => {
      el.classList.add("editable");
    });
    onEditDiscard(row.id);
    var element: any = document.querySelector(
      `.${className} #${className}-${row.id}`
    );

    element.classList.remove("edit-row");
  };

  const saveRowData = (row: any) => {
    const valid = onSaveRow(row.id);
    if (valid) {
      var elems = document.querySelectorAll(
        ".mdm--sd-rad-table-body > .table--row > .mdm--row"
      );
      [].forEach.call(elems, (el: any) => {
        el.classList.add("editable");
      });

      var element: any = document.getElementById(`${className}-${row.id}`);
      element.classList.remove("edit-row");
    }
  };

  const renderEditRowActionButtonsUI = (row: any) => (
    <>
      <Button
        className="buttons-container-button visibility-hidden"
        id="edit-btn"
        size="small"
        rounded="full"
        icon="edit"
        onClick={() => editRowData(row)}
      />
      <Button
        className="buttons-container-button visibility-hidden"
        size="small"
        id="save-btn"
        rounded="full"
        icon="check"
        onClick={() => saveRowData(row)}
      />
      <Button
        className="buttons-container-button visibility-hidden"
        size="small"
        rounded="full"
        id="discard-btn"
        icon="clear"
        onClick={() => discardRowData(row)}
      />
      <Button
        className="buttons-container-button visibility-hidden"
        size="small"
        rounded="full"
        id="delete-btn"
        icon="delete"
        onClick={(e) => deleteRow(row.id)}
      />
    </>
  );

  useEffect(() => {
    let firstHalf = ["", "", ""];
    let secondHalf = [];
    if (data && data?.rows) {
      let list: any = [...data.rows];
      const middleIndex = list?.length ? Math.ceil(list?.length / 2) : 0;

      firstHalf = [...list];
      secondHalf = [];

      if (list?.length <= 14) {
        firstHalf = list.splice(0, 7);
        secondHalf = list.splice(-7);
      } else if (list?.length > 14) {
        firstHalf = list.splice(0, middleIndex);
        secondHalf = list.splice(-middleIndex);
      }
    }

    const listData: any = [];

    if (firstHalf) {
      listData.push({
        tableHeaders: data?.tableHeaders,
        rows: firstHalf,
      });
    }

    if (secondHalf?.length) {
      listData.push({
        tableHeaders: data?.tableHeaders,
        rows: secondHalf,
      });
    }

    setColumnData(listData);
  }, [data]);

  const getChangeClassName = (defaultData: any, row: any, field: string) => {
    const d = defaultData?.find((i: any) => i.index === row?.id);
    if (d?.[field] !== row?.[field] && !isCreate) {
      return `${field}-changed`;
    }
    return "";
  };

  const getTooltipText = (defaultData: any, row: any, field: string) => {
    const d = defaultData?.find((i: any) => i.index === row?.id);
    if (d?.[field] !== row?.[field]) {
      return d?.[field] || "";
    }
    return "";
  };

  return (
    <div className={`mdm--row mdm--sd-rad-table-details ${className}`}>
      {columnData?.map((item: TableDetailsViewData, index: number) => {
        return (
          <div
            key={`${className}-table-details-${index}`}
            className="mdm--sd-rad-table-detail"
          >
            <>
              <div className="mdm--sd-rad-table-headers mdm--row">
                {item.tableHeaders?.map(
                  (
                    header: {
                      label: string;
                      field: string;
                      editType?: string;
                      onChange?: any;
                    },
                    indexNo: number
                  ) => (
                    <div
                      key={`${className}-table-headers-${indexNo}`}
                      className="mdm--table-th"
                    >
                      {header.label}
                    </div>
                  )
                )}
              </div>
              <div className="mdm--sd-rad-table-body">
                {isLoading ? (
                  <div className="mdm--row loading--row">
                    {item.tableHeaders?.map((head: any, hIndex: number) => (
                      <div
                        key={`${className}-td-data${hIndex}`}
                        className={`mdm--table-td ${head?.field}`}
                      >
                        <Skeleton shape={"text"} style={{ width: "100%" }} />
                      </div>
                    ))}
                  </div>
                ) : !columnData?.[0]?.rows?.length &&
                  !showAddRecordRowDisabled ? (
                  <p className="mdm--no-records">
                    {Locale.common?.no_records_to_view}
                  </p>
                ) : (
                  isEditing &&
                  showAddRecordRowDisabled &&
                  index === 0 && (
                    <>
                      <div className="mdm--row">
                        {renderAddNewRowUI()}
                        {renderAddNewRowActionButtonsUI()}
                      </div>
                      {isNewRowValid === -1 && newRowInvalidText && (
                        <Error>{newRowInvalidText || "Required field"}</Error>
                      )}
                    </>
                  )
                )}

                {item.rows?.map((row: any, indexNum: number) => (
                  <div
                    className="table--row"
                    key={`${className}-table-body-${indexNum}`}
                  >
                    <div
                      id={`${className}-${row?.id}`}
                      className={`mdm--row ${
                        isEditing && !showAddRecordRowDisabled ? "editable" : ""
                      }`}
                    >
                      {item.tableHeaders?.map(
                        (
                          header: {
                            label: string;
                            field: string;
                            editType?: string;
                            editData?: any;
                            onChange?: any;
                            filterable?: boolean;
                            onFilterChange?: any;
                            isValid?: boolean;
                            maxLength?: number;
                          },
                          indexN: number
                        ) => (
                          <div
                            key={`${className}-td-data${indexN}`}
                            className={`mdm--table-td ${
                              header?.field
                            } ${getChangeClassName(
                              data?.defaultData,
                              row,
                              header?.field
                            )}`}
                          >
                            {
                              <>
                                {/* <NumericTextBox value={1} /> */}
                                {header?.field === "editActionRow" ? (
                                  renderEditRowActionButtonsUI(row)
                                ) : (
                                  <>
                                    <span className="edit-field">
                                      {header.editType ===
                                      EditFieldTypeConst.DROPDOWN_FIELD ? (
                                        <DropDownList
                                          data={header?.editData || []}
                                          value={
                                            header?.editData?.filter(
                                              (code: any) => {
                                                return (
                                                  code?.value ===
                                                  row[header.field]
                                                );
                                              }
                                            )?.[0] || {}
                                          }
                                          onChange={(e) => {
                                            header?.onChange(
                                              data,
                                              e.value,
                                              row,
                                              header.field
                                            );
                                          }}
                                          textField="value"
                                          dataItemKey="id"
                                          filterable={
                                            header.filterable || false
                                          }
                                          onFilterChange={header.onFilterChange}
                                          valid={row.isValid}
                                        />
                                      ) : header?.editType ===
                                        EditFieldTypeConst.NUMBER_FIELD ? (
                                        <NumericTextBox
                                          spinners={false}
                                          value={
                                            row[header.field] &&
                                            row[header.field] !== null
                                              ? +row[header.field]
                                              : row[header.field] === 0
                                              ? 0
                                              : null
                                          }
                                          valid={row.isValid}
                                          onChange={(e) => {
                                            header?.onChange(
                                              data,
                                              e,
                                              row,
                                              header.field
                                            );
                                          }}
                                        />
                                      ) : header?.editType ===
                                        EditFieldTypeConst.TEXT_FIELD ? (
                                        <Input
                                          value={row[header.field] || ""}
                                          valid={row.isValid}
                                          maxLength={header?.maxLength}
                                          onChange={(e) => {
                                            header?.onChange(
                                              data,
                                              e,
                                              row,
                                              header.field
                                            );
                                          }}
                                        />
                                      ) : header?.editType ===
                                        EditFieldTypeConst.TIME_FIELD ? (
                                        <TimePicker
                                          className="time-picker-table-view"
                                          valid={row.isValid}
                                          value={
                                            new Date(
                                              2010,
                                              2,
                                              10,
                                              +row[header.field]?.split(
                                                ":"
                                              )?.[0],
                                              +row[header.field]?.split(
                                                ":"
                                              )?.[1]
                                            )
                                          }
                                          format="HH:mm"
                                          onChange={(e) => {
                                            header?.onChange(
                                              data,
                                              e,
                                              row,
                                              header.field
                                            );
                                          }}
                                        />
                                      ) : null}
                                    </span>
                                    {getChangeClassName(
                                      data?.defaultData,
                                      row,
                                      header?.field
                                    ) && !isCreate ? (
                                      <Tooltip
                                        anchorElement="target"
                                        position="top"
                                      >
                                        <span
                                          className="plain-text-field"
                                          title={`${
                                            "Before:" +
                                            (getTooltipText(
                                              data?.defaultData,
                                              row,
                                              header?.field
                                            ) || "")
                                          }`}
                                        >
                                          {row[header.field]}
                                        </span>
                                      </Tooltip>
                                    ) : (
                                      <span className="plain-text-field">
                                        {row[header.field]}
                                      </span>
                                    )}
                                  </>
                                )}
                              </>
                            }
                          </div>
                        )
                      )}
                    </div>
                    {!row.isValid && <Error>{row?.validationMessage}</Error>}
                  </div>
                ))}
              </div>
            </>
          </div>
        );
      })}
      <div
        className={
          "mdm--sd-rad-table-edit-add-btn  " +
          (!isEditing && "visibility-hidden")
        }
      >
        <Button
          className="buttons-container-button"
          icon="add"
          onClick={onShowAddNewRecordClick}
          disabled={showAddRecordRowDisabled}
        />
      </div>
    </div>
  );
};
