import { useEffect, useState, useCallback, useMemo } from "react";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import "hammerjs";

import {
  MultiSelectTree,
  MultiSelectTreeChangeEvent,
  MultiSelectTreeExpandEvent,
  getMultiSelectTreeValue,
  MultiSelectTreeFilterChangeEvent,
} from "@progress/kendo-react-dropdowns";
import { FilterDescriptor } from "@progress/kendo-data-query";
import { Switch } from "@progress/kendo-react-inputs";
import { Skeleton } from "@progress/kendo-react-indicators";
import { formatDate } from "@telerik/kendo-intl";

import {
  AppConst,
  APP_ROUTES,
  OperatingPlatformDecConst,
  RoleConst,
  SiteTypeNameConst,
} from "../../../constants";
import {
  CategoryDonutChart,
  OperatingPlatormDonutChart,
  SiteTypeDonutChart,
  StatusChangeStackedChart,
} from "./components";
import { LeadershipCountriesData } from "../../../models";
import { Locale } from "../../../utils/localization";
import { DashboardToggleComponent } from "../../components/dashboard-toggle";

import { LeadershipSitesService } from "../../../services/leadership.sites.service";

import withTelemetry from "../../components/hoc/with-telemetry";

import {
  processMultiSelectTreeData,
  expandedState,
} from "./multiselecttree-data-operations";

import { adobeAnalyticsConst } from "../../../constants/adobe-analytics.constant";

import "./LeadershipDashboard.scss";

const dataItemKey = "id";
const checkField = "checkField";
const checkIndeterminateField = "checkIndeterminateField";
const subItemsField = "items";
const expandField = "expanded";
const textField = "text";

const fields = {
  dataItemKey,
  checkField,
  checkIndeterminateField,
  expandField,
  subItemsField,
};

const CHART_CSS_PROPS = {
  TITLE_FONT: "1vw Arial",
  LEGEND_FONT: "0.7vw Arial",
};

interface CountriesInputDataType {
  text: string;
  id: string;
  items?: {
    text: string;
    id: string;
    items?: { text: string; id: string }[];
  }[];
}

const LeadershipDashboard = () => {
  const userDetails = useSelector((state: any) => state?.auth?.user);
  const roleName = userDetails?.roleDetail?.userRoleName;

  const { pathname } = useLocation();

  const disableSiteDashboardForLeadership =
    userDetails?.roleDetail?.permission?.disableSiteDashboardForLeadership ||
    false;

  const [checked, setChecked] = useState<boolean>(false);

  const [countryList, setCountryList] = useState<CountriesInputDataType[]>([]);

  const [shellSitesSelectedCountryCodes, setShellSitesSelectedCountryCodes] =
    useState<string[]>([]);
  const [rblaSitesSelectedCountryCodes, setRBLASitesSelectedCountryCodes] =
    useState<string[]>([]);

  const [totalActiveSitesCount, setTotalActiveSitesCount] = useState(0);
  const [totalCTSitesCount, setTotalCTSitesCount] = useState(0);

  const [value, setValue] = useState<{ id: string; text: string }[]>([]);
  const [expanded, setExpanded] = useState<any>([]);
  const [filter, setFilter] = useState<FilterDescriptor | null>(null);

  const [statusCountData, setStatusCountData] = useState<
    {
      monthgroup: string;
      sitestatus: string;
      total: number;
    }[]
  >([]);
  const [categoryCountData, setCategoryCountData] = useState<any[]>([]);
  const [operatingPlatformCountData, setOperatingPlatformCountData] =
    useState<{ label: string; total: number; color: string }[]>();
  const [siteTypeCountData, setSiteTypeCountData] =
    useState<{ label: string; total: number }[]>();
  const [tags, setTags] = useState<any[]>([]);

  const [isToggleClicked, setIsToggleClicked] = useState(false);

  const [isCountryDropdownDisabled, setIsCountryDropdownDisabled] =
    useState(true);

  const getSiteTypeColor = (type: string) => {
    if (type === SiteTypeNameConst.SHELL_IN_NETWORK_RETAIL_SITE) {
      return "#29becd";
    } else if (type === SiteTypeNameConst.SHELL_COMMERCIAL_SITE) {
      return "#175670";
    }
    return "#f68d2f";
  };

  const getOPColor = (type: string) => {
    if (type === OperatingPlatformDecConst.RETAIL_BRAND_LICENCE_AGREEMENT) {
      return "#771469";
    } else if (type === OperatingPlatformDecConst.WHOLESALE) {
      return "#008FC3";
    } else if (type === OperatingPlatformDecConst.RETAILER_BUSINESS_AGREEMENT) {
      return "#BA95BE";
    } else if (type === OperatingPlatformDecConst.DEALER_OWNED) {
      return "#F49600";
    } else if (type === OperatingPlatformDecConst.NOT_SPECIFIED) {
      return "#5D84B8";
    } else if (type === OperatingPlatformDecConst.RETAIL_FEE_AGREEMENT) {
      return "#AD7949";
    } else if (
      type === OperatingPlatformDecConst.AN_OLD_OPERATING_PLATFORM_FROM_OU
    ) {
      return "#CCFF99";
    } else if (
      type === OperatingPlatformDecConst.COMPANY_OWNED_COMPANY_OPERATED
    ) {
      return "#CAD400";
    } else if (
      type ===
      OperatingPlatformDecConst.USED_FOR_COMPETITOR_SITES_AND_COMMERCIAL_SITES
    ) {
      return "#EE4657";
    } else if (type === OperatingPlatformDecConst.RETAIL_ALLIANCE) {
      return "#7D3E00";
    } else if (
      type ===
      OperatingPlatformDecConst.USED_FOR_INACTIVE_SHELL_IN_NETWORK_RETAIL_SITES
    ) {
      return "#DFE379";
    } else if (
      type === OperatingPlatformDecConst.COMPANY_OWNED_DEALER_OPERATED
    ) {
      return "##9F6599";
    } else if (type === OperatingPlatformDecConst.MOBILITY_BUSINESS_ALLIANCE) {
      return "#FABE6C";
    } else if (type === OperatingPlatformDecConst.LEGACY_COMPANY_OWNED) {
      return "#FF82AD";
    } else if (type === OperatingPlatformDecConst.LEGACY_DEALER_OWNED) {
      return "#BF4A42";
    }
    // else if (
    //   type === OperatingPlatformDecConst.COMPANY_OWNED_RETAILER_OPERATED
    // ) {
    //   return "#46B6DB";
    // } else if (
    //   type === OperatingPlatformDecConst.RETAILER_OWNED_RETAILER_OPERATED
    // ) {
    //   return "#29becd";
    // } else if (type === OperatingPlatformDecConst.LOCAL_WHOLESALE) {
    //   return "#29becd";
    // } else if (
    //   type === OperatingPlatformDecConst.DEALER_OWNED_DEALER_OPERATED
    // ) {
    //   return "#29becd";
    // }
  };

  const getSelectedRegionAndClusters = (
    selectedCountries: { id: string; text: string }[]
  ) => {
    let filteredData: CountriesInputDataType[] = [...selectedCountries];
    //Filter Non RBLA sites toggle off
    if (!checked) {
      filteredData = [...filteredData]?.filter(
        (country: { text: string; id: string }) => {
          if (country?.id?.includes("-Licensee")) {
            return false;
          }
          return true;
        }
      );
    }

    const regionList = countryCodes
      ?.map((item: LeadershipCountriesData) => item?.regionName)
      .filter(
        (value: string, index: number, self: any) =>
          self.indexOf(value) === index && value
      );

    const clusterList = countryCodes
      ?.map((item: LeadershipCountriesData) => item.clusterName)
      .filter(
        (value: string, index: number, self: any) =>
          self.indexOf(value) === index && value
      );

    const filteredDataCountryCodesArray = [...filteredData]?.map(
      (country: { text: string; id: string }) => country?.id
    );

    regionList?.forEach((region: string) => {
      const regionsAllCountryList = countryCodes?.filter(
        (country: LeadershipCountriesData) => {
          if (checked && country?.regionName === region) {
            return true;
          } else if (
            !checked &&
            country?.countryCd?.includes("-Licensee") &&
            country?.regionName === region
          ) {
            return false;
          } else if (country?.regionName === region) {
            return true;
          }
          return false;
        }
      );
      const checkIfAllCountriesExists = regionsAllCountryList?.every(
        (country: LeadershipCountriesData) => {
          return filteredDataCountryCodesArray?.includes(
            `${country?.countryCd}-SHELL`
          );
        }
      );
      if (
        checkIfAllCountriesExists &&
        regionsAllCountryList?.length &&
        filteredDataCountryCodesArray?.length
      ) {
        filteredData = filteredData?.filter(
          (country: { id: string }) => country?.id !== `SHELL--RG--${region}`
        );
        filteredData.push({
          text: region,
          id: `SHELL--RG--${region}`,
        });
      } else {
        filteredData = [...filteredData]?.filter(
          (country: { text: string; id: string }) =>
            country?.text !== region || !country?.id?.includes("SHELL--RG--")
        );
      }
    });

    clusterList?.forEach((cluster: string) => {
      const clustersAllCountryList = countryCodes?.filter(
        (country: LeadershipCountriesData) => {
          if (checked && country?.clusterName === cluster) {
            return true;
          } else if (
            !checked &&
            country?.countryCd?.includes("-Licensee") &&
            country?.clusterName === cluster
          ) {
            return false;
          } else if (country?.clusterName === cluster) {
            return true;
          }
          return false;
        }
      );

      const checkIfAllCountriesExists = clustersAllCountryList?.every(
        (country: LeadershipCountriesData) => {
          return filteredDataCountryCodesArray?.includes(
            `${country?.countryCd}-SHELL`
          );
        }
      );
      if (
        checkIfAllCountriesExists &&
        clustersAllCountryList?.length &&
        filteredDataCountryCodesArray?.length
      ) {
        filteredData = filteredData?.filter(
          (country: { id: string }) => country?.id !== `SHELL--CL--${cluster}`
        );
        filteredData.push({
          text: cluster,
          id: `SHELL--CL--${cluster}`,
        });
      } else {
        filteredData = [...filteredData]?.filter(
          (country: { text: string; id: string }) =>
            country?.text !== cluster || !country?.id?.includes("SHELL--CL--")
        );
      }
    });

    const allSelectedCountryCodes = selectedCountries?.map(
      (country: { id: string }) => country?.id
    );
    const visibleCountryCodes = countryCodes
      ?.filter((country: LeadershipCountriesData) => {
        if (country?.countryCd?.includes("-Licensee") && !checked) {
          return false;
        }
        return true;
      })
      ?.map((country: { countryCd: string }) => country?.countryCd);
    const isAllCountriesSelected = visibleCountryCodes?.every(
      (country: string) => allSelectedCountryCodes?.includes(`${country}-SHELL`)
    );
    if (isAllCountriesSelected) {
      filteredData = filteredData?.filter(
        (country: { id: string }) =>
          country?.id !== "select_all" && country?.id !== "shell_sites"
      );
      filteredData?.push(
        { id: "select_all", text: "Select All" },
        { id: "shell_sites", text: "Direct" }
      );
    } else {
      filteredData = filteredData?.filter(
        (country: { id: string }) => country?.id !== "select_all"
      );
    }
    filteredData = filteredData?.filter(
      (value: { id: string; text: string }, index: number, self: any) =>
        index ===
        self.findIndex(
          (t: { id: string; text: string }) =>
            t.id === value.id && t.text === value.text
        )
    );
    return [...filteredData];
  };

  const setTotalCountData = (
    inputStatusData: { monthgroup: string; sitestatus: string; total: number }[]
  ) => {
    const newData = inputStatusData?.map(
      (item: { monthgroup: string; sitestatus: string; total: number }) => {
        return {
          monthgroup: item?.monthgroup?.replace(/ +/g, ""),
          sitestatus: item?.sitestatus,
          total: item?.total,
        };
      }
    );
    const dataAfterSort = newData?.sort(
      (
        prev: { monthgroup: string; sitestatus: string; total: number },
        next: { monthgroup: string; sitestatus: string; total: number }
      ) => {
        const prevDate = formatDate(new Date(`01-${prev?.monthgroup}`), "s");
        const nextDate = formatDate(new Date(`01-${next?.monthgroup}`), "s");
        return new Date(prevDate)?.getTime() - new Date(nextDate)?.getTime();
      }
    );
    let activeCount = 0;
    let ctCount = 0;
    dataAfterSort?.forEach(
      (d: { monthgroup: string; sitestatus: string; total: number }) => {
        const formattedUTCDate = formatDate(
          new Date(`01-${d?.monthgroup}`),
          "s"
        );
        const monthGroupNo = new Date(formattedUTCDate)?.getMonth();
        if (
          monthGroupNo === new Date()?.getMonth() &&
          d?.sitestatus ===
            Locale.landing_page?.status_section.active_site_count_label
        ) {
          activeCount = d?.total;
        }
        if (
          monthGroupNo === new Date()?.getMonth() &&
          d?.sitestatus ===
            Locale.landing_page?.status_section.ct_site_count_label
        ) {
          ctCount = d?.total;
        }
      }
    );
    setTotalActiveSitesCount(activeCount);
    setTotalCTSitesCount(ctCount);
  };

  const { isLoading: isCountryCodesLoading, data: countryCodes } = useQuery(
    "leadership-country-codes-data",
    () => {
      return LeadershipSitesService.getCountryCodesData();
    },
    {
      staleTime: 5000,
      enabled: true,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      select: (data) => data?.data?.result,
    }
  );

  const {
    isLoading: isSitesStatusCountDataLoading,
    isRefetching: isSitesStatusCountDataRefetching,
    data: siteStatusCountData,
    refetch: refetchSitesStatusCountData,
  } = useQuery(
    "leadership-sites-status-count-data",
    () => {
      return LeadershipSitesService.getStatusCountData({
        shellCountries: [...shellSitesSelectedCountryCodes],
        rblaCountries: [...rblaSitesSelectedCountryCodes],
        isChecked: checked,
      });
    },
    {
      staleTime: 0,
      enabled: false,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      cacheTime: 0,
      select: (data) => data?.data?.result,
    }
  );

  const {
    isLoading: isSitesAllChartDataLoading,
    isRefetching: isSitesAllChartDataRefetching,
    data: sitesAllChartData,
    refetch: refetchSitesAllChartData,
  } = useQuery(
    "leadership-sites-all-chart-data",
    () => {
      return LeadershipSitesService.getSiteCategorySiteTypeAndSiteOPData({
        shellCountries: [...shellSitesSelectedCountryCodes],
        rblaCountries: [...rblaSitesSelectedCountryCodes],
        isChecked: checked,
      });
    },
    {
      staleTime: 0,
      enabled: false,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      cacheTime: 0,
      select: (data) => data?.data?.result,
    }
  );

  useEffect(() => {
    window.adobeDataLayer = window.adobeDataLayer || [];
    window.adobeDataLayer.push({
      event: "Page Loaded",
      metric: "attract",
      timestamp: new Date().toISOString(),
      page: {
        pageName: "Site Data Web | " + pathname.slice(1),
        language: adobeAnalyticsConst.language,
        platform: adobeAnalyticsConst.platform,
        cvp: "Site Data Web",
        siteSection: pathname.slice(1),
      },
      user: {
        userId: userDetails.userLoginId,
        loginStatus: "active",
        userType: userDetails.userType,
        customerRole: userDetails?.roleDetail?.userRoleName,
      },
    });
  }, []);

  useEffect(() => {
    const isSelectAll = value?.find(
      (item: { id: string }) => item?.id === "select_all"
    );
    const selectedCountriesList = value?.filter(
      (item: { id: string }) =>
        !item?.id?.includes("select_all") &&
        !item?.id?.includes("shell_sites") &&
        !item?.id?.includes("rbla_sites") &&
        !item?.id?.includes("SHELL--RG") &&
        !item?.id?.includes("RBLA--RG") &&
        !item?.id?.includes("SHELL--CL--") &&
        !item?.id?.includes("RBLA--CL--")
    );

    const [item1, ...rest] = selectedCountriesList;

    if (selectedCountriesList?.length === 0 || isSelectAll?.id) {
      setTags([
        {
          text: "All",
          data: [],
        },
      ]);
    } else if (selectedCountriesList?.length === 1) {
      setTags([
        {
          text: item1?.text,
          data: [item1],
        },
      ]);
    } else if (selectedCountriesList?.length > 1) {
      setTags([
        {
          text: item1?.text,
          data: [item1],
        },
        { text: `${rest?.length} more`, data: [...rest] },
      ]);
    }
  }, [value]);

  useEffect(() => {
    if (countryCodes?.length) {
      const shellCountries = countryCodes?.filter(
        (country: LeadershipCountriesData) =>
          !country?.countryCd?.includes("-Licensee")
      );
      const rblaCountries = countryCodes?.filter(
        (country: LeadershipCountriesData) =>
          country?.countryCd?.includes("-Licensee")
      );
      const uniqueShellRegions = shellCountries
        .map((item: LeadershipCountriesData) => item.regionName)
        .filter(
          (value: string, index: number, self: any) =>
            self.indexOf(value) === index && value
        );
      const uniqueRBLARegions = rblaCountries
        .map((item: LeadershipCountriesData) => item.regionName)
        .filter(
          (value: string, index: number, self: any) =>
            self.indexOf(value) === index && value
        );
      const shellCountriesFinalData: CountriesInputDataType[] =
        uniqueShellRegions?.map((reg: string) => {
          return { text: reg, id: `SHELL--RG--${reg}`, items: [] };
        });
      const rblaCountriesFinalData: CountriesInputDataType[] =
        uniqueRBLARegions?.map((reg: string) => {
          return { text: reg, id: `RBLA--RG--${reg}`, items: [] };
        });
      countryCodes?.forEach((country: LeadershipCountriesData) => {
        if (
          !country?.regionName &&
          !country?.countryCd?.includes("-Licensee")
        ) {
          shellCountriesFinalData?.push({
            text: country?.countryName,
            id: `${country?.countryCd}-SHELL`,
            items: [],
          });
        } else if (
          !country?.regionName &&
          country?.countryCd?.includes("-Licensee")
        ) {
          rblaCountriesFinalData?.push({
            text: country?.countryName,
            id: country?.countryCd,
            items: [],
          });
        }
      });

      shellCountries?.forEach((item: LeadershipCountriesData) => {
        if (item?.clusterName) {
          shellCountriesFinalData?.forEach((region: CountriesInputDataType) => {
            if (region?.text === item?.regionName) {
              if (region?.items?.length) {
                region?.items?.forEach((cluster: CountriesInputDataType) => {
                  const uniqueClusterNamesFromRegion = region?.items
                    ?.filter(
                      (cl: { text: string; id: string }) =>
                        cl.text && cl.id?.includes("SHELL--CL--")
                    )
                    ?.map((cl: { text: string; id: string }) => cl?.text);

                  if (cluster?.text === item?.clusterName) {
                    cluster?.items?.push({
                      text: item?.countryName,
                      id: `${item?.countryCd}-SHELL`,
                    });
                  } else if (
                    item?.clusterName &&
                    !uniqueClusterNamesFromRegion?.includes(item?.clusterName)
                  ) {
                    region?.items?.push({
                      text: item?.clusterName || "",
                      id: `SHELL--CL--${item?.clusterName}`,
                      items: [
                        {
                          text: item?.countryName,
                          id: `${item?.countryCd}-SHELL`,
                        },
                      ],
                    });
                  }
                });
              } else {
                region?.items?.push({
                  text: item?.clusterName || "",
                  id: `SHELL--CL--${item?.clusterName}`,
                  items: [
                    { text: item?.countryName, id: `${item?.countryCd}-SHELL` },
                  ],
                });
              }
            }
          });
        } else {
          shellCountriesFinalData?.forEach((region: any) => {
            if (region?.text === item?.regionName) {
              region?.items?.push({
                text: item?.countryName,
                id: `${item?.countryCd}-SHELL`,
              });
            }
          });
        }
      });

      rblaCountries?.forEach((item: LeadershipCountriesData) => {
        if (item?.clusterName) {
          rblaCountriesFinalData?.forEach((region: CountriesInputDataType) => {
            if (region?.text === item?.regionName) {
              if (region?.items?.length) {
                region?.items?.forEach((cluster: CountriesInputDataType) => {
                  const uniqueClusterNamesFromRegion = region?.items
                    ?.filter(
                      (cl: { text: string; id: string }) =>
                        cl.text && cl.id?.includes("RBLA--CL--")
                    )
                    ?.map((cl: { text: string; id: string }) => cl?.text);

                  if (cluster?.text === item?.clusterName) {
                    cluster?.items?.push({
                      text: item?.countryName,
                      id: `${item?.countryCd}`,
                    });
                  } else if (
                    item?.clusterName &&
                    !uniqueClusterNamesFromRegion?.includes(item?.clusterName)
                  ) {
                    region?.items?.push({
                      text: item?.clusterName || "",
                      id: `RBLA--CL--${item?.clusterName}`,
                      items: [
                        {
                          text: item?.countryName,
                          id: `${item?.countryCd}`,
                        },
                      ],
                    });
                  }
                });
              } else {
                region?.items?.push({
                  text: item?.clusterName || "",
                  id: `RBLA--CL--${item?.clusterName}`,
                  items: [
                    { text: item?.countryName, id: `${item?.countryCd}` },
                  ],
                });
              }
            }
          });
        } else {
          rblaCountriesFinalData?.forEach((region: any) => {
            if (region?.text === item?.regionName) {
              region?.items?.push({
                text: item?.countryName,
                id: `${item?.countryCd}`,
              });
            }
          });
        }
      });
      let finalDataTree = [];
      if (checked) {
        finalDataTree = [
          {
            id: "select_all",
            text: "Select All",
            items: [
              {
                id: "shell_sites",
                text: "Direct",
                items: [...shellCountriesFinalData],
              },
              {
                id: "rbla_sites",
                text: "Licensee",
                items: [...rblaCountriesFinalData],
              },
            ],
          },
        ];
      } else {
        finalDataTree = [
          {
            id: "select_all",
            text: "Select All",
            items: [
              {
                id: "shell_sites",
                text: "Direct",
                items: [...shellCountriesFinalData],
              },
            ],
          },
        ];
      }
      setCountryList([...finalDataTree]);
    }
  }, [countryCodes, checked]);

  AppConst.useEffectSkipFirst(() => {
    if (countryList?.length) {
      setExpanded([countryList?.[0][dataItemKey]]);
    }
  }, [countryList]);

  useEffect(() => {
    if (roleName === RoleConst.GI_ACCESS_USER) {
      setValue([]);
    } else if (
      userDetails &&
      countryCodes &&
      roleName !== RoleConst.GI_ACCESS_USER
    ) {
      const usersDefaultCountryCodes = [
        ...(userDetails?.userRoleMapping || []),
      ]?.map((item: { countryCode: string }) => item?.countryCode);

      const selectedCountryItemsForDropdown: { id: string; text: string }[] = [
        ...countryCodes,
      ]
        ?.filter((item: { countryCd: string }) =>
          usersDefaultCountryCodes?.includes(item?.countryCd)
        )
        ?.map((country: { countryCd: string; countryName: string }) => {
          return {
            id: `${country?.countryCd}-SHELL`,
            text: country?.countryName,
          };
        });

      setValue(
        getSelectedRegionAndClusters([
          ...(selectedCountryItemsForDropdown || []),
        ])
      );
    }
  }, [userDetails, countryCodes]);

  AppConst.useEffectSkipFirst(() => {
    let shellSitesCountryCodes: any = value?.filter(
      (country: { id: string; text: string }) =>
        !country?.id?.includes("RG--") &&
        !country?.id?.includes("CL--") &&
        !country?.id?.includes("-Licensee") &&
        country?.id !== "select_all" &&
        country?.id !== "shell_sites" &&
        country?.id !== "rbla_sites"
    );
    shellSitesCountryCodes = shellSitesCountryCodes?.map(
      (country: { id: string; text: string }) =>
        country?.id?.substring(0, country?.id?.indexOf("-SHELL"))
    );

    const rblaSitesCountryCodes = value
      ?.filter(
        (country: { id: string; text: string }) =>
          !country?.id?.includes("RG--") &&
          !country?.id?.includes("CL--") &&
          country?.id?.includes("-Licensee") &&
          country?.id !== "select_all" &&
          country?.id !== "shell_sites" &&
          country?.id !== "rbla_sites"
      )
      ?.map((country: { id: string; text: string }) =>
        country?.id?.substring(0, country?.id?.indexOf("-Licensee"))
      );
    shellSitesCountryCodes?.length
      ? setShellSitesSelectedCountryCodes([...shellSitesCountryCodes])
      : setShellSitesSelectedCountryCodes([]);

    rblaSitesCountryCodes?.length
      ? setRBLASitesSelectedCountryCodes([...rblaSitesCountryCodes])
      : setRBLASitesSelectedCountryCodes([]);
  }, [value]);

  AppConst.useEffectSkipFirst(() => {
    if (isToggleClicked) {
      setIsToggleClicked(false);
      if (!shellSitesSelectedCountryCodes?.length) {
        refetchSitesStatusCountData();
        refetchSitesAllChartData();
      }
    } else {
      refetchSitesStatusCountData();
      refetchSitesAllChartData();
    }
  }, [shellSitesSelectedCountryCodes, rblaSitesSelectedCountryCodes]);

  AppConst.useEffectSkipFirst(() => {
    if (checked) {
      setIsToggleClicked(true);
      const newValue = value?.filter(
        (country: { id: string }) => country?.id !== "select_all"
      );
      setValue([...newValue]);
    } else {
      setValue(getSelectedRegionAndClusters([...value]));
    }
  }, [checked]);

  useEffect(() => {
    setTotalCountData([...(siteStatusCountData || [])]);
    setStatusCountData([...(siteStatusCountData || [])]);
  }, [siteStatusCountData]);

  useEffect(() => {
    const scData: { label: string; total: number }[] =
      sitesAllChartData?.siteCategoryData?.map(
        (sc: { sitecategory: string; total: number }) => {
          return {
            label: sc.sitecategory,
            total: sc.total,
          };
        }
      ) || [];
    const stData: any[] =
      sitesAllChartData?.siteTypeData?.map(
        (st: { sitetype: string; total: number }) => {
          return {
            label: `${st.sitetype} - ${st.total}`,
            total: st.total,
            color: getSiteTypeColor(st.sitetype),
          };
        }
      ) || [];
    const opData: any[] =
      sitesAllChartData?.siteOperatingPlatformData?.map(
        (op: { siteoperatingplatform: string; total: number }) => {
          return {
            label: `${op.siteoperatingplatform} - ${op.total}`,
            total: op.total,
            color: getOPColor(op.siteoperatingplatform),
          };
        }
      ) || [];

    setCategoryCountData([...(scData || [])]);
    setSiteTypeCountData([...(stData || [])]);
    setOperatingPlatformCountData([...(opData || [])]);
  }, [sitesAllChartData]);

  useEffect(() => {
    const ele = document.querySelectorAll(".tree-options .k-checkbox");
    if (
      isCountryCodesLoading ||
      isSitesStatusCountDataLoading ||
      isSitesAllChartDataLoading ||
      isSitesAllChartDataRefetching ||
      isSitesStatusCountDataRefetching
    ) {
      setIsCountryDropdownDisabled(true);
      ele.forEach((e: any) => {
        e.classList.add("k-disabled");
      });
    } else {
      setIsCountryDropdownDisabled(false);
      ele.forEach((e: any) => {
        e.classList.remove("k-disabled");
      });
    }
  }, [
    isCountryCodesLoading,
    isSitesStatusCountDataLoading,
    isSitesAllChartDataLoading,
    isSitesAllChartDataRefetching,
    isSitesStatusCountDataRefetching,
  ]);

  const onFilterChange = (event: MultiSelectTreeFilterChangeEvent) =>
    setFilter(event.filter);

  const onSiteTypeToggleClick = () => {
    setChecked(!checked);
  };

  const onChange = (event: MultiSelectTreeChangeEvent) => {
    setValue(
      getMultiSelectTreeValue(countryList, { ...fields, ...event, value })
    );
  };

  const onExpandChange = useCallback(
    (event: MultiSelectTreeExpandEvent) =>
      setExpanded(expandedState(event.item, dataItemKey, expanded)),
    [expanded]
  );

  const treeData = useMemo(
    () =>
      processMultiSelectTreeData(countryList, {
        expanded,
        value,
        filter,
        ...fields,
      }),
    [expanded, value, filter, countryList]
  );

  return (
    <div className="mdm--leadership-landing-page">
      <div className="mdm--ld-button-container mdm--row">
        <div className="mdm-ld-country-dropdown">
          <legend className="mdm--ld-button-label">Markets</legend>
          <MultiSelectTree
            style={{ width: "20vw" }}
            data={treeData}
            value={value}
            onChange={onChange}
            textField={textField}
            dataItemKey={dataItemKey}
            checkField={checkField}
            checkIndeterminateField={checkIndeterminateField}
            expandField={expandField}
            subItemsField={subItemsField}
            onExpandChange={onExpandChange}
            filterable={true}
            onFilterChange={onFilterChange}
            tags={tags}
            className={"mdm--leadership-country-multiselect-tree"}
            popupSettings={{
              popupClass: `tree-options`,
            }}
            disabled={isCountryDropdownDisabled}
          />
        </div>
        <div className="mdm-ld-rbla-switch">
          <legend className="mdm--ld-button-label">
            {Locale.leadership_landing_page?.include_rbla_sites_toggle_label}
          </legend>
          <Switch
            size="large"
            trackRounded={"small"}
            thumbRounded="small"
            onChange={onSiteTypeToggleClick}
            checked={checked}
            disabled={
              isCountryCodesLoading ||
              isSitesStatusCountDataLoading ||
              isSitesStatusCountDataRefetching ||
              isSitesAllChartDataLoading ||
              isSitesAllChartDataRefetching
            }
          />
        </div>
      </div>
      <div className="mdm--row main-container">
        <div className="left-section">
          <div className="first-row">
            <div className="status-chart-container mdm--row">
              {useMemo(
                () => (
                  <StatusChangeStackedChart
                    titleFont={CHART_CSS_PROPS.TITLE_FONT}
                    legendFont={CHART_CSS_PROPS.LEGEND_FONT}
                    data={statusCountData}
                    isLoading={
                      isCountryCodesLoading ||
                      isSitesStatusCountDataLoading ||
                      isSitesAllChartDataRefetching
                    }
                    height="39vh"
                    width="54.6vw"
                  />
                ),
                [isCountryDropdownDisabled]
              )}
              <div className="dashboard-status-counter-blocks-container">
                <h5 className="mdm--landing-page-item-title">
                  {Locale.leadership_landing_page?.live_site_count?.toUpperCase()}
                </h5>
                <div className="box-shadow">
                  {isCountryCodesLoading ||
                  isSitesStatusCountDataLoading ||
                  isSitesAllChartDataRefetching ? (
                    [0, 1, 2]?.map((item: number) => (
                      <div className="counter-block-item active" key={item}>
                        <Skeleton
                          style={{ width: "10vw", marginLeft: "0.25vw" }}
                        />
                        <Skeleton
                          style={{ width: "5vw", marginLeft: "0.25vw" }}
                        />
                      </div>
                    ))
                  ) : (
                    <>
                      <div className="counter-block-item active">
                        <div className="dashbord-counter-label">
                          {
                            Locale.landing_page?.sites_list.tab_titles
                              .active_sites
                          }
                        </div>
                        <div className="dashbord-counter-value">
                          {totalActiveSitesCount?.toLocaleString() || 0}
                        </div>
                      </div>
                      <div className="counter-block-item closed-temporarily">
                        <div className="dashbord-counter-label">
                          {Locale.landing_page?.sites_list.tab_titles.c_t_sites}
                        </div>
                        <div className="dashbord-counter-value">
                          {totalCTSitesCount?.toLocaleString() || 0}
                        </div>
                      </div>
                      <div className="counter-block-item total-sites">
                        <div className="dashbord-counter-label">
                          {Locale.leadership_landing_page?.total_sites}
                        </div>
                        <div className="dashbord-counter-value">
                          {(
                            totalActiveSitesCount + totalCTSitesCount
                          )?.toLocaleString() || 0}
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="mdm--row second-row">
            {useMemo(
              () => (
                <>
                  <OperatingPlatormDonutChart
                    titleFont={CHART_CSS_PROPS.TITLE_FONT}
                    legendFont={CHART_CSS_PROPS.LEGEND_FONT}
                    height="calc(100% - 3.5vh)"
                    width="100%"
                    data={operatingPlatformCountData || []}
                    isLoading={
                      isCountryCodesLoading ||
                      isSitesAllChartDataLoading ||
                      isSitesAllChartDataRefetching
                    }
                  />
                  <SiteTypeDonutChart
                    titleFont={CHART_CSS_PROPS.TITLE_FONT}
                    legendFont={CHART_CSS_PROPS.LEGEND_FONT}
                    height="calc(100% - 3.5vh)"
                    width="100%"
                    data={siteTypeCountData || []}
                    isLoading={
                      isCountryCodesLoading ||
                      isSitesAllChartDataLoading ||
                      isSitesAllChartDataRefetching
                    }
                  />
                </>
              ),
              [isCountryDropdownDisabled]
            )}
          </div>
        </div>
        <div className="right-section">
          <CategoryDonutChart
            data={categoryCountData || []}
            isLoading={
              isCountryCodesLoading ||
              isSitesAllChartDataLoading ||
              isSitesAllChartDataRefetching
            }
            totalSitesCount={totalActiveSitesCount + totalCTSitesCount}
          />
          {!disableSiteDashboardForLeadership && (
            <DashboardToggleComponent
              titleText={Locale.leadership_landing_page?.sites_dashboard}
              navigateText={
                Locale.leadership_landing_page?.view_sites_dashboard
              }
              path={APP_ROUTES.DASHBOARD_PATH}
            />
          )}
        </div>
      </div>
    </div>
  );
};
export default withTelemetry(LeadershipDashboard);
