import { useEffect, useState } from "react";
import { Checkbox } from "@progress/kendo-react-inputs";
import { Card, CardBody } from "@progress/kendo-react-layout";
import "./configration.scss";
import { Button } from "@progress/kendo-react-buttons";
import { ConfigurationService } from "../../../../services/configuration.service";

import { uniqBy } from "lodash";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { useMasterDataSet1 } from "../../../../hooks";

import { ToastNotification } from "../../../components/toast-notification/ToastNotification";
interface MasterDataType {
  id: string;
  value: string;
  countrydiallingcd?: string;
}
export const Configruration = () => {
  const [configurationData, setConfigurationData] = useState<any>();
  const [success, setSuccess] = useState(false);
  const [fieldType, setFieldType] = useState<string[]>([]);
  const [countryName, setCountryName] = useState<any>();
  const [countryCd, setCountryCd] = useState<any>();
  const [groupData,setGroupData]= useState<any[]>([])

  const [countryCodes, setCountryCodes] = useState<MasterDataType[]>();
  const [configurationUpdateValue, setConfigurationUpdateValue] = useState<any>(
    []
  );

  const {
    isLoading: isMasterDataSet1Loading,
    data: masterDataSet1,
    refetch: refetchMasterDataSet1,
  } = useMasterDataSet1();

  useEffect(() => {
    refetchMasterDataSet1();
  }, []);

  useEffect(() => {
    if (masterDataSet1) {
      const countryTypes = masterDataSet1?.countryCodes?.map((type: any) => {
        return {
          id: type?.countrycd,
          value: type?.countryname || "",
          countrydiallingcd: type?.countrydiallingcd || "",
        };
      });
      setCountryCodes(countryTypes);
    }
  }, [masterDataSet1]);

  function groupBy(items: any, key: any) {
    return items?.reduce(
      (result: any, item: any) => ({
        ...result,
        [item[key]]: [...(result[item[key]] || []), item],
      }),
      {}
    );
  }

  const fetchData = async (id: any) => {
    const data = await ConfigurationService.getConfigurationList(id);
    const newData = groupBy(data?.data?.items, "fieldType");
    // console.log(data);
    
    setGroupData(data?.data?.groups.map((type:any)=>{      
      return {
        id: type?.groupId,
        value: type?.groupName || "",
      };
    }))
    setConfigurationData(newData);
    const keys = Object.keys(newData);

    setFieldType(keys);
  };

  const onCheckBoxClick = (e: any, index: any, key: any) => {
    const newProperty = configurationData[key];
    newProperty[index].isEnabled = !newProperty[index].isEnabled;
    newProperty[index].countryCd = newProperty[index].countryCd || countryCd;
    newProperty[index].groupId = newProperty[index].groupId ;

    setConfigurationData({
      ...configurationData,
      key: newProperty,
    });
    setConfigurationUpdateValue([
      ...configurationUpdateValue,
      newProperty[index],
    ]);
  };
  useEffect(() => {
    setTimeout(() => {
      setSuccess(false);
    }, 15000);
  }, [configurationUpdateValue]);
  const onClickSaveHandler = async () => {
    try {
   const newData=   uniqBy( configurationUpdateValue, "fieldName")
   
        
      const response = await ConfigurationService.postConfigurationValue(
        newData
      );
      if (response.Error) {
        setSuccess(false);
      } else {
        setSuccess(true);
      }
    } catch (error) {
      setSuccess(false);
    }
  };
  const handleCountryChange = (e: any) => {
    setConfigurationUpdateValue([]);
    fetchData(e.target.value.id);
    setCountryName(e.target.value.value);
    setCountryCd(e.target.value.id);
  };

  const handleGroupChange = (e: any, index: any, key: any) =>{ 
    console.log(e);
       
    const newProperty = configurationData[key];
    newProperty[index].isEnabled = newProperty[index].isEnabled;
    newProperty[index].countryCd = newProperty[index].countryCd || countryCd;
    newProperty[index].groupId = e.value.id ;
    setConfigurationData({
      ...configurationData,
      key: newProperty,
    });
    setConfigurationUpdateValue([
      ...configurationUpdateValue,
      newProperty[index],
    ]);
    
    
  };

  const defaultItem = { value: "Select Country" };
  return (
    <div>
      <div className="dropdown-country-container">
        <span className="dropdown-country-preapproval-title">
          {defaultItem.value}
        </span>
        <span>:</span>
        <DropDownList
          dataItemKey="id"
          className="preApproval-dropdown-country"
          // defaultValue={countryList[0]}
          textField="value"
          defaultItem={defaultItem}
          onChange={handleCountryChange}
          data={countryCodes}
          popupSettings={{ className: "cofiguration-country-dropdown" }}
        />
        <h6>{countryName}</h6>

        {countryName && (
          <div className="Button-container-preapproval">
            <Button
              className="buttons-preapproval-button"
              onClick={onClickSaveHandler}
              themeColor="primary"
              rounded="large"
            >
              Save
            </Button>
          </div>
        )}
      </div>

      <div style={{ display: "flex",height :"82vh" ,overflow :"auto"}}>
        {countryName && (
          <>
            <Card className="configruation-card">
              <h5 className="configration-title">Facility</h5>
              <CardBody style={{ display: "flex", flexFlow: "wrap" }}>
                {fieldType?.map((key: string) => {
                  return (
                    <>
                      {key === "FacilitySubType" &&
                        configurationData[key]?.map((item: any, index: any) => {
                          return (
                            <div style={{ width: "50%" }}>
                              <Checkbox
                                key={index}
                                className="configuration-checkbox"
                                checked={item.isEnabled}
                                onClick={(e) => onCheckBoxClick(e, index, key)}
                                size="medium"
                              >
                                
                                <DropDownList
                              className="small-checkbox-for-selection"
                              key={index}
                              textField="value"
                              dataItemKey="id"
                              size={'small'}
                              onChange={(e)=>handleGroupChange(e, index, key)}
                              data={groupData}
                              defaultItem={groupData}
                              value={groupData.find(
                                (x) => x.id === item.groupId
                              )}/>
                                {key === "FacilitySubType"
                                  ? item?.fieldName
                                  : ""}
                              </Checkbox> 
                             
                            </div>
                          );
                        })}
                      {key === "Facility" &&
                        configurationData[key]?.map((item: any, index: any) => {
                          return (
                            <div style={{ width: "50%" }}>
                              <Checkbox
                                className="configuration-checkbox"
                                checked={item.isEnabled}
                                onClick={(e) => onCheckBoxClick(e, index, key)}
                                size="medium"
                              >
                                {/* {groupData.map((item)=>{

                                })

                                } */}
                              <DropDownList
                              className="small-checkbox-for-selection"
                              key={index}
                              textField="value"
                              dataItemKey="id"
                              size={'small'}
                              data={groupData}
                              defaultItem={groupData}
                              onChange={(e)=>handleGroupChange(e, index, key)}

                              value={groupData.find(
                                (x) => x.id === item.groupId
                              )}
                              // onChange={(e) => handleChangeEvent(e, index)}
                            />
                                {key === "Facility" ? item?.fieldName : ""}
                              </Checkbox>
                            </div>
                          );
                        })}
                    </>
                  );
                })}
              </CardBody>
            </Card>
            <Card className="configruation-card">
              <h5 className="configration-title">Common </h5>
              <ToastNotification
                show={success}
                type={"success"}
                message={["Your Data Has Been Saved"]}
              />
              <CardBody style={{ display: "flex", flexFlow: "wrap" }}>
                {fieldType?.map((key: string) => {
                  return (
                    <>
                      {key !== "FacilitySubType" && key !== "Facility"  &&
                        configurationData[key]?.map((item: any, index: any) => {
                          return (
                            <div style={{ width: "50%" }}>
                              <Checkbox
                                key={index}
                                className="configuration-checkbox"
                                checked={item.isEnabled}
                                onClick={(e) => onCheckBoxClick(e, index, key)}
                                size="medium"
                              >
                                <DropDownList
                              className="small-checkbox-for-selection"
                              key={index}
                              textField="value"
                              dataItemKey="id"
                              size={'small'}
                              data={groupData}
                              onChange={(e)=>handleGroupChange(e, index, key)}

                              defaultItem={groupData}
                              value={groupData.find(
                                (x) => x.id === item.groupId
                              )}/>
                   {item?.fieldName}
                              </Checkbox>
                            </div>
                          );
                        })}
                      {/* {key !== "Facility" &&
                        configurationData[key]?.map((item: any, index: any) => {
                          return (
                            <div style={{ width: "50%" }}>
                              <Checkbox
                                key={index}
                                className="configuration-checkbox"
                                checked={item.isEnabled}
                                onClick={(e) => onCheckBoxClick(e, index, key)}
                                size="medium"
                              >
                                  <DropDownList
                              className="small-checkbox-for-selection"
                              key={index}
                              textField="value"
                              dataItemKey="id"
                              size={'small'}
                              data={groupData}
                              onChange={(e)=>handleGroupChange(e, index, key)}

                              defaultItem={groupData}
                              value={groupData.find(
                                (x) => x.id === item.groupId
                              )}/>
                               {item?.fieldName}
                              </Checkbox>
                            </div>
                          );
                        })} */}
                    </>
                  );
                })}
              </CardBody>
            </Card>
          </>
        )}
      </div>
    </div>
  );
};
