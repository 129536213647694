import React from "react";

import {
  Chart,
  ChartLegend,
  ChartSeries,
  ChartSeriesItem,
  ChartSeriesLabels,
  ChartTitle,
} from "@progress/kendo-react-charts";

interface Iprops {
  data: { label: string | null; total: string | number | null }[];
  height: string;
  width: string;
  chartTitle: string;
  titleFont: string;
  legendFont: string;
  legendMarginTop: number;
}

export const DonutChart = (props: Iprops) => {
  const {
    data,
    height,
    width,
    chartTitle,
    titleFont,
    legendFont,
    legendMarginTop,
  } = props;

  const labelContent = (e: any) => e.value;

  return (
    <Chart
      className="donut-chart box-shadow"
      style={{ height: height, width: width }}
    >
      <ChartTitle text={chartTitle} position="top" font={titleFont} />

      <ChartSeries>
        <ChartSeriesItem
          type="donut"
          data={data}
          categoryField="label"
          field="total"
        >
          <ChartSeriesLabels
            // color="#fff"
            background="none"
            content={labelContent}
            font={legendFont}
            visible={false}
          />
        </ChartSeriesItem>
      </ChartSeries>
      <ChartLegend
        position="right"
        orientation="vertical"
        labels={{ font: legendFont, margin: { top: legendMarginTop } }}
      />
    </Chart>
  );
};
