export const initialState = { apiErrorState: {} };

interface ActionProps {
  type: String;
  payload?: any;
}

export default function apiErrorStateReducer(
  state = initialState,
  action: ActionProps
) {
  switch (action.type) {
    case "API_ERROR_STATE":
      state.apiErrorState = action.payload;
      return { ...state };

    default:
      return { ...state };
  }
}
