
export const initialState = { details: {} ,pageSize:25};

interface ActionProps {
  type: String;
  payload?: any;
}

export default function changeRequestReducer(
  state = initialState,
  action: ActionProps
) {
  switch (action.type) {
    case "CHANGE_REQUEST_DETAIL":
      // state.selectedGlobalCountry = action.payload;
      return { ...state, details: action.payload };
case "CHANGE_REQUEST_LIST_PAGESIZE":
  return { ...state, pageSize: action.payload };
    default:
      return state;
  }
}