import React, { useState } from "react";
import { formatDate } from "@telerik/kendo-intl";
import { format } from "date-fns";
import {
  Chart,
  ChartAxisDefaults,
  ChartCategoryAxis,
  ChartCategoryAxisItem,
  ChartLegend,
  ChartSeries,
  ChartSeriesItem,
  ChartTitle,
  ChartValueAxis,
  ChartValueAxisItem,
  ChartSeriesItemTooltip,
  ChartTooltip,
} from "@progress/kendo-react-charts";
import { Skeleton } from "@progress/kendo-react-indicators";

import { Locale } from "../../../../../utils/localization";
import { AppConst } from "../../../../../constants";

const MONTH_NAMES = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

interface IProps {
  titleFont: string;
  legendFont: string;
  data: any;
  isLoading: boolean;
  height: string;
  width: string;
}

export const StatusChangeStackedChart = (props: IProps) => {
  const { isLoading, data, legendFont } = props;
  const [categories, setCategories] = useState<any[]>([]);
  const [series, setSeries] = useState<any[]>([]);
  const [seriesOfTotal, setSeriesOfTotal] = useState<number[]>([]);
  // const [maxValue, setMaxValue] = useState(0);
  // const [minValue, setMinValue] = useState(0);
  const [majorUnitValue, setMajorUnitValue] = useState<number | undefined>(0);

  const getLastDayOfMonth = (year: number, month: number) => {
    let lastDay = new Date(year, month + 1, 0).getDate();
    if (month === new Date()?.getMonth()) {
      //   const dateObj = new Date();
      //   lastDay = dateObj.getUTCDate();
      //   return format(new Date(year, month, lastDay), "dd-MMM-yyyy");
      return "Today";
    }
    return format(new Date(year, month, lastDay), "dd-MMM-yyyy");
  };

  AppConst.useEffectSkipFirst(() => {
    if (data?.length) {
      const newData = data?.map(
        (item: { monthgroup: string; sitestatus: string; total: number }) => {
          return {
            monthgroup: item?.monthgroup?.replace(/ +/g, ""),
            sitestatus: item?.sitestatus,
            total: item?.total,
          };
        }
      );
      const dataAfterSort = newData?.sort((prev: any, next: any) => {
        const prevDate = formatDate(new Date(`01-${prev?.monthgroup}`), "s");
        const nextDate = formatDate(new Date(`01-${next?.monthgroup}`), "s");
        return new Date(prevDate)?.getTime() - new Date(nextDate)?.getTime();
      });

      let activeValues: any = [];
      let ctValues: any = [];

      const today = new Date();
      let d;
      const uniqueMonths: string[] = [];

      for (var i = 9; i > 0; i -= 1) {
        d = new Date(today.getFullYear(), today.getMonth() - (i - 1), 1);
        const year = d.getFullYear();
        uniqueMonths.push(`${MONTH_NAMES[d.getMonth()]}-${year}`);
      }

      uniqueMonths?.forEach((month: string) => {
        const activeValue = dataAfterSort?.find(
          (dataObj: any) =>
            dataObj?.monthgroup === month && dataObj?.sitestatus === "Active"
        );
        activeValues?.push(activeValue?.total || 0);

        const ctValue = dataAfterSort?.find(
          (dataObj: any) =>
            dataObj?.monthgroup === month &&
            dataObj?.sitestatus === "Closed Temporarily"
        );
        ctValues?.push(ctValue?.total || 0);
      });

      const categoriesAfterFormat = uniqueMonths?.map((date: string) => {
        const formattedUTCDate = formatDate(new Date(`01-${date}`), "s");
        const monthGroupNo = new Date(formattedUTCDate)?.getMonth();
        const year = new Date(formattedUTCDate)?.getFullYear();
        return getLastDayOfMonth(year, monthGroupNo);
      });

      setCategories([...categoriesAfterFormat]);
      setSeries([
        {
          name: Locale.landing_page?.status_section.active_site_count_label,
          data: [...activeValues],
          color: "#0097BB",
        },
        {
          name: Locale.landing_page?.status_section.ct_site_count_label,
          data: [...ctValues],
          color: "#DD1D21",
        },
      ]);

      const result = [...activeValues]?.map((item: number, index: number) => {
        return item + ctValues[index];
      });
      const min = Math.min(...result);
      const max = Math.max(...result);
      // let yAxisMinValue = Math.floor((98 / 100) * min);
      // yAxisMinValue = yAxisMinValue > 0 ? yAxisMinValue : 0;
      let diff = max - min;
      let majorUnit = undefined;
      if (diff < 5 && (min !== max || max < 11)) {
        majorUnit = 1;
      }

      setSeriesOfTotal([...result]);
      setMajorUnitValue(majorUnit);
      // setMinValue(yAxisMinValue);
      // setMaxValue(yAxisMaxValue);
    }
  }, [data]);

  const nestedTooltipRender = ({ point }: any) => (
    <>
      <p
        style={{
          font: "0.7vw Arial",
          listStyleType: "disc",
          display: "list-item",
          color: "#fff",
          lineHeight: "1vw",
          marginLeft: "0.5vw",
        }}
      >{`${Locale.landing_page?.status_section.active_site_count_label}: ${
        series?.[0]?.data?.[point?.categoryIndex]
      }`}</p>
      <p
        style={{
          font: "0.7vw Arial",
          listStyleType: "disc",
          display: "list-item",
          color: "#fff",
          lineHeight: "1vw",
          marginLeft: "0.5vw",
        }}
      >{`${Locale.landing_page?.status_section.ct_site_count_label}: ${
        series?.[1]?.data?.[point?.categoryIndex]
      }`}</p>
    </>
  );

  return (
    <div className="status-chart">
      <h5 className="mdm--landing-page-item-title">
        {Locale.leadership_landing_page?.leadership_site_status_section_title}
      </h5>
      {isLoading ? (
        <div className="loading-container box-shadow">
          <div className="skeleton-container">
            <Skeleton shape="rectangle" className="rectangle-0" />
            <Skeleton shape="rectangle" className="rectangle-1" />

            <Skeleton shape="rectangle" className="rectangle-2" />
            <Skeleton shape="rectangle" className="rectangle-3" />
            <Skeleton shape="rectangle" className="rectangle-4" />

            <Skeleton shape="rectangle" className="rectangle-5" />
          </div>
        </div>
      ) : data?.length ? (
        <div className="chart-container box-shadow">
          <Chart className="status-bar-chart">
            <ChartTitle text="" position="top" font={props.titleFont} />
            <ChartLegend
              position="right"
              orientation="vertical"
              visible={true}
              labels={{ font: legendFont, margin: { top: 5 } }}
            />
            <ChartCategoryAxis>
              <ChartCategoryAxisItem
                categories={categories}
                majorGridLines={{ visible: false }}
              >
                {/* <ChartCategoryAxisTitle
                  text={Locale.leadership_landing_page?.months}
                  font="0.9vw Arial"
                /> */}
              </ChartCategoryAxisItem>
            </ChartCategoryAxis>
            <ChartValueAxis>
              <ChartValueAxisItem
                majorGridLines={{ visible: false }}
                majorUnit={majorUnitValue}
                // minorUnit={0}
                // min={minValue}
                // max={maxValue}
              >
                {/* <ChartValueAxisTitle
                  text={Locale.leadership_landing_page?.sites_count}
                  font="0.9vw Arial"
                /> */}
              </ChartValueAxisItem>
            </ChartValueAxis>

            {/* <ChartArea background="#1D2B44" /> */}

            <ChartAxisDefaults
              labels={{ font: "0.7vw Arial", format: "{0:0}" }}
              majorGridLines={{
                color: "#d3d3d3",
                dashType: "dot",
                // visible: false,
              }}
            />
            <ChartTooltip format="Default Content {0}" />
            <ChartSeries>
              <ChartSeriesItem
                type="column"
                tooltip={{ visible: true, font: "0.7vw Arial", color: "#fff" }}
                data={seriesOfTotal || []}
                color={"#0097BB"}
                style={"smooth"}
                gap={0.9}
                labels={{
                  visible: true,
                  position: "outsideEnd",
                  // background: "#0097BB",
                  color: "#000",
                  font: "0.75vw Arial",
                }}
              >
                <ChartSeriesItemTooltip render={nestedTooltipRender} />
              </ChartSeriesItem>
            </ChartSeries>
          </Chart>
        </div>
      ) : (
        <div className="no-records-container box-shadow">
          <p className="no-records">{Locale.common?.no_records_to_view}</p>
        </div>
      )}
    </div>
  );
};
