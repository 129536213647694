export const initialState = {
  theme: "light",
  auth: {
    accessToken: "",
    refreshToken: "",
    expiryTime: "",
    tokenType: "",
    accessTokenCipm: "",
  },
  user: {
    userType: "",
    userLoginId: "",
    firstName: "",
    lastName: "",
    emailAddress: "",
    orgAffiliationCode: "",
    userStatus: "",
    lastLogInTime: "",
    lastUpdateUserId: "",
    lastUpdateGMTdtm: "",
    userRoleMapping: [{ countryCode: "", businessCustomerNumber: "" }],
    roleDetail: {
      userRoleId: null,
      userRoleName: "",
      globalRoleIndicator: null,
      retailerRoleIndicator: null,
      tpnRoleIndicator: null,
      retailSiteTypeCode: "",
      lastUpdateGMT: "",
    },
  },
  reports: {
    expandedItems: [],
    filteredItems: [],
    selectedReport: { pageName: "", reportId: "" },
    searchText: "",
  },
};

interface Action {
  type: String;
  payload?: any;
}

export function authReducer(state = initialState, action: Action) {
  switch (action.type) {
    case "TOGGLE_THEME":
      const { theme } = state;
      state.theme = theme === "light" ? "dark" : "light";
      return state;

    case "AUTHENTICATION_SUCCESS":
      state.auth = action.payload;
      return state;

    case "SET_USER":
      state.user = action.payload;
      return state;

    case "SET_SEARCH_TEXT":
      state.reports.searchText = action.payload;
      return state;

    case "SET_SELECTED_REPORT":
      state.reports.selectedReport = action.payload;
      return state;

    case "SET_EXPANDED_ITEMS":
      state.reports.expandedItems = action.payload;
      return state;

    case "SET_FILTERED_ITEMS":
      state.reports.filteredItems = action.payload;
      return state;

    case "LOGOUT":
      state = initialState;
      return state;

    default:
      return state;
  }
}

export default authReducer;
