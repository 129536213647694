import axios from "axios";
import { SitesListDocumentData, SitesListRequestData } from "../models";
import { http } from ".";
import { appConfig } from "../config";

export const SitesService = {
  getSitesList: (payload: SitesListRequestData) => {
    return http.get(`${appConfig.baseUrl}/search/SearchSiteData`, {
      params: payload,
    });
  },

  getAllSitesList: (linksArray: number[], payload: SitesListRequestData) => {
    return axios
      .all(
        linksArray.map((link, index) =>
          http.get(`${appConfig.baseUrl}/search/SearchSiteData`, {
            params: {
              ...payload,
              pageSize: 10000,
              page: index,
            },
          })
        )
      )
      .then(
        axios.spread((...responses) => {
          let resData: SitesListDocumentData[] = [];
          responses.forEach((response) => {
            resData = [...resData, ...response.data?.result?.siteDocuments];
          });
          return resData;
        })
      );
  },

  getSiteDetails: (payload: { globalSiteId: string; viewName?: string }) => {
    return http.get("/sitedetails/SiteDetail", {
      params: payload,
    });
  },

  getSiteMasterDataSync: (type: string) => {
    return http.get(`/sitedetails/MasterDataSync/${type}`);
  },

  patchSiteDetailsAPI: (payload: any) => {
    let config = {
      headers: {
        "x-channel-Id": payload.channelId,
        "x-initiator-Id": payload.initiatorId,
      },
    };
    const data: any = payload.data;
    return http.patch(`/sitedetailsext/SiteDetail`, data, config);
  },

  postSiteDetailsAPI: (payload: any) => {
    let config = {
      headers: {
        "x-channel-Id": payload.channelId,
        "x-initiator-Id": payload.initiatorId,
      },
    };
    const data: any = payload.data;
    return http.post(`/sitedetailsext/SiteDetail`, data, config);
  },

  getRBLAExportData: async ({ status, countries, totalSitesCount }: any) => {
    const batchSize = totalSitesCount <= 600 ? 100 : 250;
    const totalPages = Math.ceil(totalSitesCount / batchSize);
    const noOfPagesArr = Array.apply(null, Array(totalPages)).map(
      (y, i) => i + 1
    );
    return axios
      .all(
        noOfPagesArr?.map(async (page: number) =>
          http.get(`/sitedetails/SiteDetail/ExportSites`, {
            params: {
              pageSize: batchSize?.toString(),
              pageNumber: page?.toString(),
              status: status?.join("|"),
              countries: countries?.join("|"),
            },
          })
        )
      )
      .then(
        axios.spread((...responses) => {
          let resData: any[] = [];
          responses.forEach((response: any) => {
            resData = [...resData, ...response.data];
          });
          return resData;
        })
      );
  },
};
