import { useEffect, useState } from "react";

import {
  InputChangeEvent,
  NumericTextBoxChangeEvent,
} from "@progress/kendo-react-inputs";
import { DropDownListChangeEvent } from "@progress/kendo-react-dropdowns";

import {
  useMasterDataSet1,
  useMasterDataSet2,
} from "../../../../../../../../hooks";
import { Locale } from "../../../../../../../../utils/localization";
import { DetailsViewComponent } from "../../../../../../../components";
import {
  AppConst,
  EditFieldTypeConst,
} from "../../../../../../../../constants";

interface MasterDataType {
  id: string;
  value: string;
}

export const EVOverviewTab = (props: any) => {
  const {
    isEditing,
    basicSiteDetails,
    isBasicSiteDetailsLoading,

    onPatchRequestValueSet,
    onSectionValueSet,

    isSectionValueChanged,
    onSectionValueChanged,
    sectionEditedData,
    isCreate,
    setEVOverviewTabValid,
  } = props;

  const {
    isLoading: isMasterDataSet1Loading,
    data: masterDataSet1,
    refetch: refetchMasterDataSet1,
  } = useMasterDataSet1();

  const {
    isLoading: isMasterDataSet2Loading,
    data: masterDataSet2,
    refetch: refetchMasterDataSet2,
  } = useMasterDataSet2();

  const [noOfChargePosts, setNoOfChargePosts] = useState<number | null>();
  const [parkingOwnerSelectedValue, setParkingOwnerSelectedValue] =
    useState<any>();
  const [noOfChargePoints, setNoOfChargePoints] = useState<number | null>();
  const [destinationHostSelectedValue, setDestinationHostSelectedValue] =
    useState<any>();
  const [maxPower, setMaxPower] = useState<number | null>();
  const [chargerOwnershipSelectedValue, setChargerOwnershipSelectedValue] =
    useState<any>();
  const [cpoTelephone, setCPOTelephone] = useState("");
  const [cpoEmail, setCPOEmail] = useState("");
  const [cpoWebsite, setCPOWebsite] = useState("");

  const [chargerOwnershipCodes, setChargerOwnershipCodes] =
    useState<MasterDataType[]>();

  const [parkingOwnerCodes, setParkingOwnerCodes] =
    useState<MasterDataType[]>();

  const [destinationHostCodes, setDestinationHostCodes] =
    useState<MasterDataType[]>();

  const [isCPOEmailValid, setIsCPOEmailValid] = useState(0);
  const [isCPOWebsiteValid, setIsCPOWebsiteValid] = useState(0);

  const onNoOfChargePostsChange = (event: NumericTextBoxChangeEvent) => {
    onSectionValueChanged(true);
    const posts = event.value;
    setNoOfChargePosts(posts);
    onSectionValueSet({
      noOfChargePosts: posts === null ? "" : posts,
    });
    onPatchRequestValueSet(
      {
        evChargerPostCount: posts === null ? "" : posts?.toString(),
      },
      +basicSiteDetails?.evChargerPostCount !== posts
    );
  };

  const onNoOfChargePointsChange = (event: NumericTextBoxChangeEvent) => {
    onSectionValueChanged(true);
    const points = event.value;
    setNoOfChargePoints(points);
    onSectionValueSet({
      noOfChargePoints: points === null ? "" : points,
    });
    onPatchRequestValueSet(
      {
        evChargerPointCount: points === null ? "" : points?.toString(),
      },
      +basicSiteDetails?.evChargerPointCount !== points
    );
  };

  const onMaxPowerChange = (event: NumericTextBoxChangeEvent) => {
    const power = event.value;
    setMaxPower(power);
    onSectionValueSet({
      maxPower: power === null ? "" : power,
    });
    onPatchRequestValueSet(
      {
        evChargerMaxPowerKw: power === null ? "" : power?.toString(),
      },
      +basicSiteDetails?.evChargerMaxPowerKw !== power
    );
    onSectionValueChanged(true);
  };

  const onParkingOwnerChange = (event: DropDownListChangeEvent) => {
    onSectionValueChanged(true);
    const parkingOwner = event.target.value;
    setParkingOwnerSelectedValue(parkingOwner);
    onSectionValueSet({
      parkingOwnerSelectedValue: parkingOwner,
    });
    onPatchRequestValueSet(
      {
        parkingOwnerCd: {
          parkingOwnerCd: parkingOwner?.id,
        },
      },
      basicSiteDetails?.parkingOwnerCd !== parkingOwner?.id
    );
  };

  const onDestinationHostChange = (event: DropDownListChangeEvent) => {
    onSectionValueChanged(true);
    const destinationHost = event.target.value;
    setDestinationHostSelectedValue(destinationHost);
    onSectionValueSet({
      destinationHostSelectedValue: destinationHost,
    });
    onPatchRequestValueSet(
      {
        destinationHostCd: {
          destinationHostCd: destinationHost?.id,
        },
      },
      basicSiteDetails?.destinationHostCd !== destinationHost?.id
    );
  };

  const onChargerOwnershipChange = (event: DropDownListChangeEvent) => {
    onSectionValueChanged(true);
    const chargerOwnership = event.target.value;
    setChargerOwnershipSelectedValue(chargerOwnership);
    onSectionValueSet({
      chargerOwnershipSelectedValue: chargerOwnership,
    });
    onPatchRequestValueSet(
      {
        chargerOwnershipCd: {
          chargerOwnershipCd: chargerOwnership?.id,
        },
      },
      basicSiteDetails?.chargerOwnershipCd !== chargerOwnership?.id
    );
  };

  const onCPOTelephoneChange = (event: InputChangeEvent) => {
    onSectionValueChanged(true);
    const cpoTelephone = event.value;
    setCPOTelephone(cpoTelephone);
    onSectionValueSet({
      cpoTelephoneNo: cpoTelephone,
    });
    onPatchRequestValueSet(
      {
        cpoTelephoneNo: cpoTelephone,
      },
      basicSiteDetails?.cpoTelephoneNo !== cpoTelephone
    );
  };

  const onCPOEmailChange = (event: InputChangeEvent) => {
    onSectionValueChanged(true);
    const cpoEmail = event.value?.toLowerCase();
    setCPOEmail(cpoEmail);
    setEVOverviewTabValid(1);
    if (cpoEmail) {
      const isEmailValid = AppConst.EMAIL_REGULAR_EXP.test(cpoEmail);
      if (isEmailValid) {
        setIsCPOEmailValid(1);
      } else {
        setIsCPOEmailValid(-1);
      }
    } else {
      setIsCPOEmailValid(0);
    }
    onSectionValueSet({
      cpoEmail: cpoEmail,
    });
    onPatchRequestValueSet(
      {
        cpoEmail: cpoEmail,
      },
      basicSiteDetails?.cpoEmail?.toLowerCase() !== cpoEmail
    );
  };

  const onCPOWebsiteChange = (event: InputChangeEvent) => {
    onSectionValueChanged(true);
    const cpoWebsite = event.value?.toLowerCase();
    setCPOWebsite(cpoWebsite);
    setEVOverviewTabValid(1);
    if (cpoWebsite) {
      const regex = new RegExp(AppConst.WEB_URL_REGULAR_EXP);
      const isWebsiteValid = cpoWebsite.match(regex);
      if (isWebsiteValid) {
        setIsCPOWebsiteValid(1);
      } else {
        setIsCPOWebsiteValid(-1);
      }
    } else {
      setIsCPOWebsiteValid(0);
    }
    onSectionValueSet({
      cpoWebsite: cpoWebsite,
    });
    onPatchRequestValueSet(
      {
        cpoWebsite: cpoWebsite,
      },
      basicSiteDetails?.cpoWebsite?.toLowerCase() !== cpoWebsite
    );
  };

  useEffect(() => {
    if (isEditing) {
      refetchMasterDataSet1();
      refetchMasterDataSet2();
      setIsCPOEmailValid(0);
      setIsCPOWebsiteValid(0);
    }
  }, [isEditing]);

  useEffect(() => {
    if (masterDataSet1 && masterDataSet2) {
      const chargerOwnershipTypes = masterDataSet1?.chargerOwnershipCodes?.map(
        (type: any) => {
          return {
            id: type.chargerOwnershipCd,
            value: type.chargerOwnershipName,
          };
        }
      );

      const parkingOwnerTypes = masterDataSet2?.parkingOwnerCodes?.map(
        (type: any) => {
          return {
            id: type?.parkingOwnerCd,
            value: type?.parkingOwnerName,
          };
        }
      );

      const destinationHostsTypes = masterDataSet2?.destinationHostCodes?.map(
        (type: any) => {
          return {
            id: type.destinationHostCd,
            value: type.destinationHostName,
          };
        }
      );

      setChargerOwnershipCodes(chargerOwnershipTypes);
      setParkingOwnerCodes(parkingOwnerTypes);
      setDestinationHostCodes(destinationHostsTypes);
    }
  }, [masterDataSet1, masterDataSet2]);

  useEffect(() => {
    if ((sectionEditedData && isSectionValueChanged) || basicSiteDetails) {
      setNoOfChargePosts(
        sectionEditedData?.noOfChargePosts
          ? sectionEditedData?.noOfChargePosts
          : sectionEditedData?.noOfChargePosts === 0
          ? 0
          : sectionEditedData?.noOfChargePosts === ""
          ? undefined
          : basicSiteDetails?.evChargerPostCount
      );
      setNoOfChargePoints(
        sectionEditedData?.noOfChargePoints
          ? sectionEditedData?.noOfChargePoints
          : sectionEditedData?.noOfChargePoints === 0
          ? 0
          : sectionEditedData?.noOfChargePoints === ""
          ? undefined
          : basicSiteDetails?.evChargerPointCount
      );
      setMaxPower(
        sectionEditedData?.maxPower
          ? sectionEditedData?.maxPower
          : sectionEditedData?.maxPower === 0
          ? 0
          : sectionEditedData?.maxPower === ""
          ? undefined
          : basicSiteDetails?.evChargerMaxPowerKw
      );
      setParkingOwnerSelectedValue(
        sectionEditedData?.parkingOwnerSelectedValue || {
          id: basicSiteDetails?.parkingOwnerCd?.parkingOwnerCd,
          value: basicSiteDetails?.parkingOwnerCd?.parkingOwnerName,
        }
      );
      setDestinationHostSelectedValue(
        sectionEditedData?.destinationHostSelectedValue || {
          id: basicSiteDetails?.destinationHostCd?.destinationHostCd,
          value: basicSiteDetails?.destinationHostCd?.destinationHostName,
        }
      );
      setChargerOwnershipSelectedValue(
        sectionEditedData?.chargerOwnershipSelectedValue || {
          id: basicSiteDetails?.chargerOwnershipCd?.chargerOwnershipCd,
          value: basicSiteDetails?.chargerOwnershipCd?.chargerOwnershipName,
        }
      );

      setCPOTelephone(
        sectionEditedData?.cpoTelephoneNo ||
          sectionEditedData?.cpoTelephoneNo === ""
          ? sectionEditedData?.cpoTelephoneNo
          : basicSiteDetails?.cpoTelephoneNo
      );

      setCPOEmail(
        sectionEditedData?.cpoEmail || sectionEditedData?.cpoEmail === ""
          ? sectionEditedData?.cpoEmail
          : basicSiteDetails?.cpoEmail
      );

      setCPOWebsite(
        sectionEditedData?.cpoWebsite === "" || sectionEditedData?.cpoWebsite
          ? sectionEditedData?.cpoWebsite
          : basicSiteDetails?.cpoWebsite
      );
    }
  }, [sectionEditedData, basicSiteDetails]);

  useEffect(() => {
    if (cpoEmail && cpoWebsite) {
      const isEmailValid: boolean = AppConst.EMAIL_REGULAR_EXP.test(cpoEmail);
      const regex = new RegExp(AppConst.WEB_URL_REGULAR_EXP);
      const isWebsiteValid = cpoWebsite.match(regex);
      if (isEmailValid) {
        setIsCPOEmailValid(1);
      } else {
        setIsCPOEmailValid(-1);
      }
      if (isWebsiteValid) {
        setIsCPOWebsiteValid(1);
      } else {
        setIsCPOWebsiteValid(-1);
      }
      if (isEmailValid && isWebsiteValid) {
        setEVOverviewTabValid(1);
      } else {
        setEVOverviewTabValid(-1);
      }
    } else if (cpoEmail) {
      const isEmailValid: boolean = AppConst.EMAIL_REGULAR_EXP.test(cpoEmail);
      if (isEmailValid) {
        setEVOverviewTabValid(1);
        setIsCPOEmailValid(1);
      } else {
        setEVOverviewTabValid(-1);
        setIsCPOEmailValid(-1);
      }
    } else if (cpoWebsite) {
      const regex = new RegExp(AppConst.WEB_URL_REGULAR_EXP);
      const isWebsiteValid = cpoWebsite.match(regex);
      if (isWebsiteValid) {
        setEVOverviewTabValid(1);
        setIsCPOWebsiteValid(1);
      } else {
        setEVOverviewTabValid(-1);
        setIsCPOWebsiteValid(-1);
      }
    } else {
      setIsCPOEmailValid(1);
      setIsCPOWebsiteValid(1);
      setEVOverviewTabValid(1);
    }
  }, [cpoEmail, cpoWebsite, isEditing]);

  const getEVOverviewData = () => [
    {
      label: Locale.site_details?.ev_details.ev_overview.no_of_charge_posts,
      value: basicSiteDetails?.evChargerPostCount,
      editType: EditFieldTypeConst.NUMBER_FIELD,
      onChange: onNoOfChargePostsChange,
      editValue: noOfChargePosts,
      isVisible: isCreate ? false : true,
      isDisabled: true,
      maxLength: 1000,
    },
    {
      label: Locale.site_details?.ev_details.ev_overview.max_power,
      value: basicSiteDetails?.evChargerMaxPowerKw,
      editType: EditFieldTypeConst.NUMBER_FIELD,
      onChange: onMaxPowerChange,
      editValue: maxPower,
      isVisible: isCreate ? false : true,
      isDisabled: true,
      maxLength: 9999999999,
    },
    {
      label: Locale.site_details?.ev_details.ev_overview.no_of_charge_points,
      value: basicSiteDetails?.evChargerPointCount,
      editType: EditFieldTypeConst.NUMBER_FIELD,
      onChange: onNoOfChargePointsChange,
      editValue: noOfChargePoints,
      isVisible: true,
      isDisabled: false,
      maxLength: 1000,
    },
    {
      label: Locale.site_details?.ev_details.ev_overview.parking_owner,
      value: basicSiteDetails?.parkingOwnerCd?.parkingOwnerName,
      editType: EditFieldTypeConst.DROPDOWN_FIELD,
      isEditDataLoading: isMasterDataSet2Loading,
      onChange: onParkingOwnerChange,
      editData: parkingOwnerCodes || [],
      editValue: parkingOwnerSelectedValue,
      isVisible: true,
    },

    {
      label: Locale.site_details?.ev_details.ev_overview.destination_host,
      value: basicSiteDetails?.destinationHostCd?.destinationHostName,
      editType: EditFieldTypeConst.DROPDOWN_FIELD,
      isEditDataLoading: isMasterDataSet2Loading,
      onChange: onDestinationHostChange,
      editData: destinationHostCodes || [],
      editValue: destinationHostSelectedValue,
      isVisible: true,
    },
    {
      label: Locale.site_details?.ev_details.ev_overview.charger_ownersip,
      value: basicSiteDetails?.chargerOwnershipCd?.chargerOwnershipName,
      editType: EditFieldTypeConst.DROPDOWN_FIELD,
      isEditDataLoading: isMasterDataSet1Loading,
      onChange: onChargerOwnershipChange,
      editData: chargerOwnershipCodes || [],
      editValue: chargerOwnershipSelectedValue,
      isVisible: true,
    },
    {
      label: Locale.site_details?.ev_details.ev_overview?.cpo_telephone,
      value: basicSiteDetails?.cpoTelephoneNo,
      editType: EditFieldTypeConst.TEXT_FIELD,
      onChange: onCPOTelephoneChange,
      isVisible: true,
      isDisabled: false,
      editValue: cpoTelephone,
      isValid: true,
      maxLength: 30,
    },
    {
      label: Locale.site_details?.ev_details.ev_overview?.cpo_email,
      value: basicSiteDetails?.cpoEmail,
      editType: EditFieldTypeConst.TEXT_FIELD,
      onChange: onCPOEmailChange,
      isVisible: true,
      isDisabled: false,
      editValue: cpoEmail,
      isValid: isCPOEmailValid !== -1,
      validationMessage: Locale.create_site?.error_messages?.invalid_email_msg,
      maxLength: 200,
    },
    {
      label: Locale.site_details?.ev_details.ev_overview?.cpo_website,
      value: basicSiteDetails?.cpoWebsite,
      editType: EditFieldTypeConst.TEXT_FIELD,
      onChange: onCPOWebsiteChange,
      isVisible: true,
      isDisabled: false,
      editValue: cpoWebsite,
      isValid: isCPOWebsiteValid !== -1,
      validationMessage:
        Locale.create_site?.error_messages?.invalid_website_url_msg,
      maxLength: 200,
    },
  ];

  return (
    <DetailsViewComponent
      data={getEVOverviewData()}
      classValue="mdm--row mdm--sd-data ev-overview"
      isLoading={isBasicSiteDetailsLoading}
      isEditing={isEditing}
      isCreate={isCreate}
    />
  );
};
