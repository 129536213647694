import { useState, useEffect } from "react";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { SitesService } from "../../../../../services/sites.service";
import { CategoryGraph } from "../../../../components/category";
import {
  AppConst,
  SiteConst,
  SiteTypeNameConst,
} from "../../../../../constants";

interface IProps {
  totalSitesCount: number;
}

export const CategoryGraphComponent = (props: IProps) => {
  const { totalSitesCount } = props;
  const selectedGlobalCountry = useSelector(
    (state: any) => state?.selectedGlobalCountry.selectedGlobalCountry
  );
  const defaultPayload = {
    filter:
      selectedGlobalCountry === AppConst.ALL_SITES
        ? `search.in(Status,'${SiteConst.SITE_STATUS_LIST.ACTIVE},${SiteConst.SITE_STATUS_LIST.CLOSED_TEMPORARILY}', ',') and search.in(SiteType,'${SiteTypeNameConst.SHELL_IN_NETWORK_RETAIL_SITE},${SiteTypeNameConst.SHELL_COMMERCIAL_SITE}', ',')`
        : `search.in(Status,'${SiteConst.SITE_STATUS_LIST.ACTIVE},${SiteConst.SITE_STATUS_LIST.CLOSED_TEMPORARILY}', ',') and search.in(SiteType, '${SiteTypeNameConst.SHELL_IN_NETWORK_RETAIL_SITE},${SiteTypeNameConst.SHELL_COMMERCIAL_SITE}', ',') and search.in(Country,'${selectedGlobalCountry}', ',')`,
    facetFilter:
      selectedGlobalCountry === AppConst.ALL_SITES
        ? `search.in(Status,'${SiteConst.SITE_STATUS_LIST.ACTIVE},${SiteConst.SITE_STATUS_LIST.CLOSED_TEMPORARILY}', ',') and search.in(SiteType, '${SiteTypeNameConst.SHELL_IN_NETWORK_RETAIL_SITE},${SiteTypeNameConst.SHELL_COMMERCIAL_SITE}', ',')`
        : `search.in(Status,'${SiteConst.SITE_STATUS_LIST.ACTIVE},${SiteConst.SITE_STATUS_LIST.CLOSED_TEMPORARILY}', ',') and search.in(SiteType, '${SiteTypeNameConst.SHELL_IN_NETWORK_RETAIL_SITE},${SiteTypeNameConst.SHELL_COMMERCIAL_SITE}', ',') and search.in(Country,'${selectedGlobalCountry}', ',')`,
    searchText: "*",
    orderby: "",
    page: 0,
    pageSize: 1,
    facets: "SiteCategory,count:50,sort:value",
  };

  const [requestPayload, setRequestPayload] = useState(defaultPayload);

  const { isLoading, data, refetch, isRefetching } = useQuery(
    "search-sites-list-category-facet",
    () => SitesService.getSitesList(requestPayload),
    {
      staleTime: AppConst.VIEW_SITE_DETAILS_STALE_TIME,
      enabled: true,
      refetchOnMount: true,
      refetchOnWindowFocus: false,
      select: (data) => {
        return data?.data?.result?.facets?.SiteCategory?.map(
          (category: { value: string; count: number }) => {
            return {
              label: category?.value,
              total: category?.count,
            };
          }
        );
      },
    }
  );

  useEffect(() => {
    setRequestPayload({
      filter:
        selectedGlobalCountry === AppConst.ALL_SITES
          ? `search.in(Status,'${SiteConst.SITE_STATUS_LIST.ACTIVE},${SiteConst.SITE_STATUS_LIST.CLOSED_TEMPORARILY}', ',') and search.in(SiteType, '${SiteTypeNameConst.SHELL_IN_NETWORK_RETAIL_SITE},${SiteTypeNameConst.SHELL_COMMERCIAL_SITE}', ',')`
          : `search.in(Status,'${SiteConst.SITE_STATUS_LIST.ACTIVE},${SiteConst.SITE_STATUS_LIST.CLOSED_TEMPORARILY}', ',') and search.in(SiteType, '${SiteTypeNameConst.SHELL_IN_NETWORK_RETAIL_SITE},${SiteTypeNameConst.SHELL_COMMERCIAL_SITE}', ',') and search.in(Country,'${selectedGlobalCountry}', ',')`,
      facetFilter:
        selectedGlobalCountry === AppConst.ALL_SITES
          ? `search.in(Status,'${SiteConst.SITE_STATUS_LIST.ACTIVE},${SiteConst.SITE_STATUS_LIST.CLOSED_TEMPORARILY}', ',') and search.in(SiteType, '${SiteTypeNameConst.SHELL_IN_NETWORK_RETAIL_SITE},${SiteTypeNameConst.SHELL_COMMERCIAL_SITE}', ',')`
          : `search.in(Status,'${SiteConst.SITE_STATUS_LIST.ACTIVE},${SiteConst.SITE_STATUS_LIST.CLOSED_TEMPORARILY}', ',') and search.in(SiteType, '${SiteTypeNameConst.SHELL_IN_NETWORK_RETAIL_SITE},${SiteTypeNameConst.SHELL_COMMERCIAL_SITE}', ',') and search.in(Country,'${selectedGlobalCountry}', ',')`,

      searchText: "*",
      orderby: "",
      page: 0,
      pageSize: 1,
      facets: "SiteCategory,count:50,sort:value",
    });
  }, [selectedGlobalCountry]);

  useEffect(() => {
    refetch();
  }, [requestPayload]);

  return (
    <CategoryGraph
      data={data}
      isLoading={isLoading || isRefetching}
      totalSitesCount={totalSitesCount}
    />
  );
};
