import React, { useEffect, useState } from "react";

import {
  TimePicker,
  TimePickerChangeEvent,
} from "@progress/kendo-react-dateinputs";
import {
  DropDownList,
  DropDownListChangeEvent,
} from "@progress/kendo-react-dropdowns";
import { Skeleton } from "@progress/kendo-react-indicators";

import {
  AppConst,
  EditFieldTypeConst,
} from "../../../../../../../../constants";
import { TableDetailsViewData } from "../../../../../../../../models";

import { TableDetailsView } from "../../../../../../../components/table-details-view/TableDetailsView";
import { Locale } from "../../../../../../../../utils/localization";
import { FadeNotification } from "../../../../../../../components";

export const OpeningHoursTab = (props: any) => {
  const {
    isEditing,
    onPatchRequestValueSet,
    onSectionValueSet,
    onOpeningHoursDataUpdate,
    isSectionValueChanged,
    onSectionValueChanged,
    sectionEditedData,
    basicDetailsTabSelected,
    facilityTypeCodes,
    isCreate,
    isSectionValid,
    sectionInvalidText,
    fetchedOpeningHoursData,
    isOpeningHoursDataLoading,
  } = props;

  const [currentStateOfOpeningHoursData, setCurrentStateOfOpeningHoursData] =
    useState<TableDetailsViewData>({ tableHeaders: [] });
  const [savedStateOfOpeningHoursData, setSavedStateOfOpeningHoursData] =
    useState<any>();

  const [newFacilitySelectedValue, setNewFacilitySelectedValue] =
    useState<any>();

  const [newOpeningTimeSelectedValue, setNewOpeningTimeSelectedValue] =
    useState<any>();

  const [newClosingTimeSelectedValue, setNewClosingTimeSelectedValue] =
    useState<any>();

  const [newOpeningTimeSelectedValue1, setNewOpeningTimeSelectedValue1] =
    useState<any>();

  const [newClosingTimeSelectedValue1, setNewClosingTimeSelectedValue1] =
    useState<any>();

  const [newDaySelectedValue, setNewDaySelectedValue] = useState<any>();

  const [isNewFacilityRowValid, setIsNewFacilityRowValid] = useState(0);
  const [isNewFacilityValid, setIsNewFacilityValid] = useState(0);
  const [newFacilityRowInvalidText, setNewFacilityRowInvalidText] = useState(
    Locale.create_site?.error_messages?.operating_hours_required_fields
  );
  const [isNewOpeningTimeValid, setIsNewOpeningTimeValid] = useState(0);
  const [isNewClosingTimeValid, setIsNewClosingTimeValid] = useState(0);
  const [minClosingDate, setMinClosingDate] = useState(new Date());
  const [isNewDayValid, setIsNewDayValid] = useState(0);

  const [filteredFacilityCode, setFilteredFacilityCode] = useState<
    { id: number; value: string }[]
  >([]);

  const [isFilteredFacilityCodesLoading, setIsFilteredFacilityCodesLoading] =
    useState(true);

  useEffect(() => {
    if (facilityTypeCodes) {
      const filteredFacilityCodes = facilityTypeCodes?.filter(
        (facility: { id: number; value: string }) =>
          AppConst.OPENING_HOURS_VALID_FACILITY_CODE_IDS?.includes(+facility.id)
      );
      setFilteredFacilityCode(filteredFacilityCodes);
      setIsFilteredFacilityCodesLoading(false);
    }
  }, [facilityTypeCodes]);

  const [defaultData, setDefaultData] = useState<any>([]);
  useEffect(() => {
    if (fetchedOpeningHoursData) {
      const defaultFacility =
        fetchedOpeningHoursData?.data?.result?.SiteOpeningHours?.item?.map(
          (item: any, index: number) => {
            return {
              index: index,
              facilityType: item.siteFacilityType.facilityTypeName || "-",
              facilityTypeCd: item.siteFacilityType.facilityTypeCd || "-",
              day: item.fromDayOfWeekCd?.dayOfWeekName || "-",
              dayOfWeekCd: item.fromDayOfWeekCd?.dayOfWeekCd || "-",
              openingToDate: item.openingToDate?.trim() || null,
              openingFromDate: item?.openingFromDate?.trim() || null,
            };
          }
        );
      setDefaultData(defaultFacility);
    }
  }, [fetchedOpeningHoursData]);

  const overlapping = (
    a: { start: string; end: string },
    b: { start: string; end: string }
  ) => {
    const getMinutes = (s: string) => {
      const p = s.split(":").map(Number);
      return p[0] * 60 + p[1];
    };
    return (
      getMinutes(a.end) > getMinutes(b.start) &&
      getMinutes(b.end) > getMinutes(a.start)
    );
  };
  const isOverlapping = (arr: { start: string; end: string }[]) => {
    let i, j;
    for (i = 0; i < arr.length - 1; i++) {
      for (j = i + 1; j < arr.length; j++) {
        if (overlapping(arr[i], arr[j])) {
          return true;
        }
      }
    }
    return false;
  };

  const map: any = {
    Monday: 1,
    Tuesday: 2,
    Wednesday: 3,
    Thursday: 4,
    Friday: 5,
    Saturday: 6,
    Sunday: 7,
  };

  const getNumber = (t: any) => +t?.replace(/:/g, "");

  const sortOperatingHours = (item: any) => {
    let carwashItems: { openingFromDate: string }[] = item?.filter(
      (facility: any) =>
        +facility?.facilityTypeCd === AppConst.CAR_WASH_FACILITY_CODE
    );
    carwashItems = carwashItems.sort(
      ({ openingFromDate: a }, { openingFromDate: b }) =>
        getNumber(a) - getNumber(b)
    );
    carwashItems = carwashItems.sort((a: any, b: any) => {
      return map[a.day] - map[b.day];
    });

    let forcourtItems: { openingFromDate: string }[] = item?.filter(
      (facility: any) =>
        +facility?.facilityTypeCd === AppConst.FORECOURT_FACILITY_CODE
    );
    forcourtItems = forcourtItems.sort(
      ({ openingFromDate: a }, { openingFromDate: b }) =>
        getNumber(a) - getNumber(b)
    );
    forcourtItems = forcourtItems.sort((a: any, b: any) => {
      return map[a.day] - map[b.day];
    });

    let shopItems: { openingFromDate: string }[] = item?.filter(
      (facility: any) =>
        +facility?.facilityTypeCd === AppConst.SHOP_FACILITY_CODE
    );
    shopItems = shopItems.sort(
      ({ openingFromDate: a }, { openingFromDate: b }) =>
        getNumber(a) - getNumber(b)
    );
    shopItems = shopItems.sort((a: any, b: any) => map[a.day] - map[b.day]);

    return [...carwashItems, ...forcourtItems, ...shopItems];
  };

  const onEditRow = (currentState: any, event: any, row: any, field: any) => {
    const newCurrentState = [...(currentState?.rows || [])].map((row: any) => {
      const { isValid, validationMessage, ...allData } = row;
      return allData;
    });
    const currentData = [...(newCurrentState || [])];
    const filterData = currentData?.map((item: any) => {
      if (row.id === item.id) {
        if (field === "facilityType") {
          return {
            ...item,
            [field]: event?.value,
            facilityTypeCd: event?.id,
          };
        } else if (field === "day") {
          return {
            ...item,
            [field]: event?.value,
            dayOfWeekCd: event?.id,
          };
        } else if (field === "openingToDate") {
          const hours = ("0" + event.value?.getHours()).slice(-2);
          const minutes = ("0" + event.value?.getMinutes()).slice(-2);
          const date = `${hours}:${minutes}`;
          return {
            ...item,
            [field]: event?.value,
            openingToDate: date?.trim(),
          };
        } else if (field === "openingFromDate") {
          const hours = ("0" + event.value?.getHours()).slice(-2);
          const minutes = ("0" + event.value?.getMinutes()).slice(-2);
          const date = `${hours}:${minutes}`;
          return {
            ...item,
            [field]: event?.value,
            openingFromDate: date?.trim(),
          };
        }
        return { ...item, [field]: event?.value };
      }
      return { ...item };
    });
    setCurrentStateOfOpeningHoursData({ ...currentState, data: filterData });
  };

  const onDiscardRow = (rowId: string) => {
    const discardRow = [...savedStateOfOpeningHoursData?.data]?.filter(
      (row: any) => row.id === rowId
    )?.[0];

    const currentState = currentStateOfOpeningHoursData?.data?.map(
      (row: any) => {
        if (row.id === rowId) {
          return discardRow;
        }
        return { ...row };
      }
    );

    const filteredArray = currentState?.filter((row: any) => {
      return row;
    });

    setCurrentStateOfOpeningHoursData({
      ...currentStateOfOpeningHoursData,
      data: filteredArray,
    });
  };

  const onDeleteRow = (rowId: string) => {
    const currentState = currentStateOfOpeningHoursData?.data?.filter(
      (row: any) => {
        return row.id !== rowId;
      }
    );
    setCurrentStateOfOpeningHoursData({
      ...currentStateOfOpeningHoursData,
      data: currentState,
    });

    let savedState = savedStateOfOpeningHoursData?.data?.filter((row: any) => {
      return row.id !== rowId;
    });

    savedState = sortOperatingHours(savedState);

    onSectionValueChanged(true);
    setSavedStateOfOpeningHoursData({
      ...savedStateOfOpeningHoursData,
      data: savedState,
    });
    onSectionValueSet({
      SiteOpeningHours: {
        ...savedStateOfOpeningHoursData,
        data: savedState,
      },
    });
    onOpeningHoursDataUpdate();
    const payload = savedState?.map((facility: any) => {
      return {
        openingToDate: facility.openingToDate?.trim(),
        openingFromDate: facility?.openingFromDate?.trim(),
        fromDayOfWeekCd: {
          dayOfWeekCd: facility.dayOfWeekCd,
        },
        siteFacilityType: {
          facilityTypeCd: facility.facilityTypeCd,
        },
      };
    });
    onPatchRequestValueSet(
      {
        SiteOpeningHours: {
          item: payload,
        },
      },
      true
    );
  };

  const onSaveRow = (rowId: string) => {
    let isValid: boolean = true;

    const filteredData: any = currentStateOfOpeningHoursData?.data?.find(
      (row: any) => {
        return row.id === rowId;
      }
    );

    const existedRecord: any = currentStateOfOpeningHoursData?.data?.filter(
      (row: any) => {
        return (
          row.facilityTypeCd === filteredData?.facilityTypeCd &&
          row.dayOfWeekCd === filteredData?.dayOfWeekCd &&
          row?.id !== filteredData?.id
        );
      }
    );

    const timesArray = existedRecord?.map((facility: any) => {
      return {
        start: facility?.openingFromDate,
        end: facility?.openingToDate,
      };
    });

    timesArray?.push({
      start: filteredData?.openingFromDate,
      end: filteredData?.openingToDate,
    });

    let newCurrentState: any = [
      ...(currentStateOfOpeningHoursData?.data || []),
    ];

    const openingTime = `1/1/2022 ${filteredData?.openingFromDate}:00`;
    const closingTime = `1/1/2022 ${filteredData?.openingToDate}:00`;

    if (isOverlapping(timesArray)) {
      isValid = false;

      newCurrentState = currentStateOfOpeningHoursData?.data?.map(
        (row: any) => {
          if (row.id === rowId) {
            return {
              ...row,
              isValid: false,
              validationMessage:
                Locale.create_site?.error_messages?.operating_hours_overlapping,
            };
          }
          return row;
        }
      );
    } else if (new Date(openingTime) > new Date(closingTime)) {
      isValid = false;
      newCurrentState = currentStateOfOpeningHoursData?.data?.map(
        (row: any) => {
          if (row.id === rowId) {
            return {
              ...row,
              isValid: false,
              validationMessage:
                Locale.create_site?.error_messages?.closing_time_error,
            };
          }
          return row;
        }
      );
    }
    newCurrentState = sortOperatingHours(newCurrentState);

    setCurrentStateOfOpeningHoursData({
      ...currentStateOfOpeningHoursData,
      data: newCurrentState,
    });
    if (isValid) {
      let savedState = savedStateOfOpeningHoursData?.data?.map((row: any) => {
        if (row.id === rowId) {
          return filteredData;
        }
        return { ...row };
      });

      savedState = sortOperatingHours(savedState);

      onSectionValueChanged(true);
      onSectionValueSet({
        SiteOpeningHours: {
          ...currentStateOfOpeningHoursData,
          data: savedState,
        },
      });
      setSavedStateOfOpeningHoursData({
        ...currentStateOfOpeningHoursData,
        data: savedState,
      });
      onOpeningHoursDataUpdate();
      const payload = savedState.map((facility: any) => {
        return {
          openingToDate: facility.openingToDate?.trim(),
          openingFromDate: facility?.openingFromDate?.trim(),
          fromDayOfWeekCd: {
            dayOfWeekCd: facility.dayOfWeekCd,
          },
          siteFacilityType: {
            facilityTypeCd: facility.facilityTypeCd,
          },
        };
      });
      onPatchRequestValueSet(
        {
          SiteOpeningHours: {
            item: payload,
          },
        },
        true
      );
    }
    return isValid;
  };

  const onShowNewRowClick = () => {
    setNewFacilitySelectedValue(null);
    setIsNewFacilityValid(1);
    setIsNewFacilityRowValid(1);
    setNewOpeningTimeSelectedValue(undefined);
    setNewOpeningTimeSelectedValue1(undefined);
    setIsNewOpeningTimeValid(1);
    setNewClosingTimeSelectedValue(undefined);
    setNewClosingTimeSelectedValue1(undefined);
    setIsNewClosingTimeValid(1);
    setNewDaySelectedValue(undefined);
    setIsNewDayValid(1);

    setCurrentStateOfOpeningHoursData({
      ...currentStateOfOpeningHoursData,
      data: savedStateOfOpeningHoursData.data,
    });
  };

  const onAddNewFacilityClick = () => {
    if (newFacilitySelectedValue) {
      setIsNewFacilityValid(1);
    } else {
      setIsNewFacilityValid(-1);
      setIsNewFacilityRowValid(-1);
      setNewFacilityRowInvalidText(
        Locale.create_site?.error_messages?.operating_hours_required_fields
      );
    }
    if (newOpeningTimeSelectedValue) {
      setIsNewOpeningTimeValid(1);
    } else {
      setIsNewOpeningTimeValid(-1);
      setIsNewFacilityRowValid(-1);
      setNewFacilityRowInvalidText(
        Locale.create_site?.error_messages?.operating_hours_required_fields
      );
    }
    if (newClosingTimeSelectedValue) {
      setIsNewClosingTimeValid(1);
    } else {
      setIsNewClosingTimeValid(-1);
      setIsNewFacilityRowValid(-1);
      setNewFacilityRowInvalidText(
        Locale.create_site?.error_messages?.operating_hours_required_fields
      );
    }
    if (newDaySelectedValue) {
      setIsNewDayValid(1);
    } else {
      setIsNewDayValid(-1);
      setIsNewFacilityRowValid(-1);
      setNewFacilityRowInvalidText(
        Locale.create_site?.error_messages?.operating_hours_required_fields
      );
    }

    if (
      newFacilitySelectedValue &&
      newOpeningTimeSelectedValue &&
      newClosingTimeSelectedValue &&
      newDaySelectedValue
    ) {
      const findIndex = savedStateOfOpeningHoursData?.data?.filter(
        (facility: any) => {
          return (
            facility.facilityTypeCd === newFacilitySelectedValue?.id &&
            facility?.dayOfWeekCd === newDaySelectedValue?.id
          );
        }
      );

      const timesArray = findIndex?.map((facility: any) => {
        return {
          start: facility?.openingFromDate,
          end: facility?.openingToDate,
        };
      });

      timesArray?.push({
        start: newOpeningTimeSelectedValue,
        end: newClosingTimeSelectedValue,
      });

      const openingFromDate = `1/1/2022 ${newOpeningTimeSelectedValue}:00`;
      const openingToDate = `1/1/2022 ${newClosingTimeSelectedValue}:00`;

      if (isOverlapping(timesArray)) {
        setIsNewFacilityRowValid(-1);
        setIsNewFacilityValid(-1);
        setIsNewDayValid(-1);
        setIsNewClosingTimeValid(-1);
        setIsNewOpeningTimeValid(-1);
        setNewFacilityRowInvalidText(
          Locale.create_site?.error_messages?.operating_hours_overlapping
        );
      } else if (new Date(openingFromDate) > new Date(openingToDate)) {
        setIsNewFacilityRowValid(-1);
        setIsNewClosingTimeValid(-1);
        setIsNewOpeningTimeValid(-1);
        setNewFacilityRowInvalidText(
          Locale.create_site?.error_messages?.closing_time_error
        );
      } else {
        setIsNewFacilityRowValid(1);

        const newData: any = {
          id: new Date().getMilliseconds() + 1000,
          facilityType: newFacilitySelectedValue?.value,
          facilityTypeCd: newFacilitySelectedValue?.id,
          openingFromDate: newOpeningTimeSelectedValue,
          openingToDate: newClosingTimeSelectedValue,
          day: newDaySelectedValue?.value,
          dayOfWeekCd: newDaySelectedValue?.id,
          editActionRow: "",
        };
        let currentData = [...(currentStateOfOpeningHoursData?.data || [])];
        currentData.unshift(newData);
        var currentState: any = {
          ...currentStateOfOpeningHoursData,
          data: [...sortOperatingHours(currentData)],
        };
        setCurrentStateOfOpeningHoursData(currentState);
        setNewFacilitySelectedValue(null);
        setNewOpeningTimeSelectedValue(null);
        setNewOpeningTimeSelectedValue1(undefined);
        setNewClosingTimeSelectedValue(null);
        setNewClosingTimeSelectedValue1(undefined);
        setNewDaySelectedValue(null);

        onSectionValueChanged(true);
        setSavedStateOfOpeningHoursData({
          ...savedStateOfOpeningHoursData,
          data: currentState.data,
        });
        onSectionValueSet({
          SiteOpeningHours: {
            ...savedStateOfOpeningHoursData,
            data: currentState.data,
          },
        });
        onOpeningHoursDataUpdate();
        const payload = currentState?.data?.map((facility: any) => {
          return {
            openingToDate: facility.openingToDate?.trim(),
            openingFromDate: facility?.openingFromDate?.trim(),
            fromDayOfWeekCd: {
              dayOfWeekCd: facility.dayOfWeekCd,
            },
            siteFacilityType: {
              facilityTypeCd: facility.facilityTypeCd,
            },
          };
        });
        onPatchRequestValueSet(
          {
            SiteOpeningHours: {
              item: payload,
            },
          },
          true
        );
        return true;
      }
      return false;
    }
    return false;
  };

  const onNewFacilityChange = (event: DropDownListChangeEvent) => {
    setNewFacilitySelectedValue(event.target.value);
    setIsNewFacilityValid(1);
    setIsNewOpeningTimeValid(1);
    setIsNewClosingTimeValid(1);
    setIsNewDayValid(1);
    setIsNewFacilityRowValid(1);
  };

  const onNewOpeningTimeChange = (event: TimePickerChangeEvent) => {
    setIsNewFacilityValid(1);
    setIsNewOpeningTimeValid(1);
    setIsNewClosingTimeValid(1);
    setIsNewDayValid(1);
    setIsNewFacilityRowValid(1);
    const hours = ("0" + event.target.value?.getHours()).slice(-2);
    const minutes = ("0" + event.target.value?.getMinutes()).slice(-2);
    const time = `${hours}:${minutes}`?.trim();
    setNewOpeningTimeSelectedValue1(event.target.value);
    setNewOpeningTimeSelectedValue(time?.trim());

    const selectedDate: any = event.target.value;
    if (
      new Date(selectedDate).getTime() >
      new Date(newClosingTimeSelectedValue1).getTime()
    ) {
      setNewClosingTimeSelectedValue1(null);
      setNewClosingTimeSelectedValue(undefined);
    }
    setMinClosingDate(selectedDate);
  };

  const onNewClosingTimeChange = (event: TimePickerChangeEvent) => {
    setIsNewFacilityValid(1);
    setIsNewOpeningTimeValid(1);
    setIsNewClosingTimeValid(1);
    setIsNewDayValid(1);
    setIsNewFacilityRowValid(1);
    const hours = ("0" + event.target.value?.getHours()).slice(-2);
    const minutes = ("0" + event.target.value?.getMinutes()).slice(-2);
    const time = `${hours}:${minutes}`?.trim();
    setNewClosingTimeSelectedValue1(event.target.value);
    setNewClosingTimeSelectedValue(time);
  };

  const onNewDayChange = (event: DropDownListChangeEvent) => {
    setNewDaySelectedValue(event.target.value);
    setIsNewFacilityValid(1);
    setIsNewOpeningTimeValid(1);
    setIsNewClosingTimeValid(1);
    setIsNewDayValid(1);
    setIsNewFacilityRowValid(1);
  };

  const renderAddNewFacilityUI = () => {
    return (
      <>
        <div className="mdm--table-td">
          {isFilteredFacilityCodesLoading ? (
            <Skeleton shape={"text"} style={{ width: "100%" }} />
          ) : (
            <DropDownList
              data={filteredFacilityCode}
              value={newFacilitySelectedValue}
              onChange={onNewFacilityChange}
              textField="value"
              dataItemKey="id"
              valid={isNewFacilityValid !== -1 ? true : false}
            />
          )}
        </div>
        <div className="mdm--table-td">
          <>
            <TimePicker
              size={"small"}
              value={newOpeningTimeSelectedValue1}
              onChange={onNewOpeningTimeChange}
              format="HH:mm"
              valid={isNewOpeningTimeValid !== -1 ? true : false}
            />
          </>
        </div>
        <div className="mdm--table-td">
          <>
            <TimePicker
              size={"small"}
              format="HH:mm"
              value={newClosingTimeSelectedValue1}
              onChange={onNewClosingTimeChange}
              valid={isNewClosingTimeValid !== -1 ? true : false}
              min={minClosingDate}
            />
          </>
        </div>
        <div className="mdm--table-td">
          <>
            <DropDownList
              data={AppConst.WEEK_DAY_LIST_CODES}
              value={newDaySelectedValue}
              onChange={onNewDayChange}
              textField="value"
              dataItemKey="id"
              valid={isNewDayValid !== -1 ? true : false}
            />
          </>
        </div>
      </>
    );
  };

  useEffect(() => {
    let list: string[] = [];
    const tbHeaders = [
      {
        label: Locale.site_details?.retail_admin_update.facility.facility_type,
        field: "facilityType",
        editType: EditFieldTypeConst.DROPDOWN_FIELD,
        editData: filteredFacilityCode,
        onChange: onEditRow,
      },
      {
        label:
          Locale.site_details?.retail_admin_update.opening_hours.opening_time,
        field: "openingFromDate",
        editType: EditFieldTypeConst.TIME_FIELD,
        onChange: onEditRow,
      },
      {
        label:
          Locale.site_details?.retail_admin_update.opening_hours.closing_time,
        field: "openingToDate",
        editType: EditFieldTypeConst.TIME_FIELD,
        onChange: onEditRow,
      },
      {
        label: Locale.site_details?.retail_admin_update.opening_hours.day,
        field: "day",
        editType: EditFieldTypeConst.DROPDOWN_FIELD,
        editData: AppConst.WEEK_DAY_LIST_CODES,
        onChange: onEditRow,
      },
      {
        label: "",
        field: "editActionRow",
      },
    ];
    if (isSectionValueChanged && sectionEditedData?.SiteOpeningHours) {
      list =
        sectionEditedData?.SiteOpeningHours?.data?.map((item: any) => {
          return {
            id: item.id,
            facilityType: item.facilityType || "-",
            facilityTypeCd: item.facilityTypeCd || "-",
            day: item.day || "-",
            dayOfWeekCd: item.dayOfWeekCd || "-",
            openingToDate: item.openingToDate?.trim(),
            openingFromDate: item?.openingFromDate?.trim(),
            editActionRow: "",
          };
        }) || [];
    } else {
      list =
        fetchedOpeningHoursData?.data?.result?.SiteOpeningHours?.item?.map(
          (item: any, index: number) => {
            return {
              id: index,
              facilityType: item.siteFacilityType.facilityTypeName || "-",
              facilityTypeCd: item.siteFacilityType.facilityTypeCd || "-",
              day: item.fromDayOfWeekCd?.dayOfWeekName || "-",
              dayOfWeekCd: item.fromDayOfWeekCd?.dayOfWeekCd || "-",
              openingToDate: item.openingToDate?.trim() || null,
              openingFromDate: item?.openingFromDate?.trim() || null,
              editActionRow: "",
            };
          }
        ) || [];
    }

    const facilityData: TableDetailsViewData = {
      tableHeaders: tbHeaders,
      data: sortOperatingHours(list),
    };
    setCurrentStateOfOpeningHoursData(facilityData);
    setSavedStateOfOpeningHoursData(facilityData);
  }, [
    fetchedOpeningHoursData,
    filteredFacilityCode,
    isEditing,
    basicDetailsTabSelected,
  ]);

  return (
    <>
      {isSectionValid === -1 && isEditing && (
        <div className="error-notification">
          <FadeNotification
            show={isSectionValid === -1 && isEditing}
            closable={false}
            type="error"
          >
            {sectionInvalidText}
          </FadeNotification>
        </div>
      )}
      <TableDetailsView
        className="opening-hours"
        data={{
          tableHeaders: currentStateOfOpeningHoursData.tableHeaders,
          rows: currentStateOfOpeningHoursData?.data,
          defaultData: defaultData,
        }}
        isLoading={isOpeningHoursDataLoading}
        isEditing={isEditing}
        renderAddNewRowUI={renderAddNewFacilityUI}
        deleteRow={onDeleteRow}
        onAddRowClick={onAddNewFacilityClick}
        onEditDiscard={onDiscardRow}
        onSaveRow={onSaveRow}
        onShowNewRowClick={onShowNewRowClick}
        isCreate={isCreate}
        isNewRowValid={isNewFacilityRowValid}
        newRowInvalidText={newFacilityRowInvalidText}
      />
    </>
  );
};
