import React from "react";
import { Locale } from "../../../../../utils/localization";

import { DonutChart } from "../../../../components";

interface IProps {
  titleFont: string;
  legendFont: string;
  height: string;
  width: string;
  data: { label: string; total: number }[];
  isLoading: boolean;
}

export const OperatingPlatormDonutChart = (props: IProps) => {
  const { data, height, width, isLoading } = props;

  return (
    <div>
      <h5 className="mdm--landing-page-item-title">
        {
          Locale.leadership_landing_page
            ?.leadership_operating_latform_section_title
        }
      </h5>
      {isLoading ? (
        <div className="loading-container box-shadow">
          <span className="loading-doughnut" />
        </div>
      ) : data?.length ? (
        <DonutChart
          data={data}
          height={height}
          width={width}
          chartTitle=""
          titleFont={props.titleFont}
          legendFont={props.legendFont}
          legendMarginTop={data?.length <= 12 ? 3 : 0}
        />
      ) : (
        <div className="no-records-container box-shadow">
          <p className="no-records">{Locale.common?.no_records_to_view}</p>
        </div>
      )}
    </div>
  );
};
