import React from "react";
import { Tooltip } from "@progress/kendo-react-tooltip";
import { Skeleton } from "@progress/kendo-react-indicators";
import { Input, NumericTextBox } from "@progress/kendo-react-inputs";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { DatePicker } from "@progress/kendo-react-dateinputs";
import { Error } from "@progress/kendo-react-labels";

import { AppConst, EditFieldTypeConst, SiteConst } from "../../../constants";
import { DetailsViewComponentModel, DetailsViewData } from "../../../models";

import "./DetailsViewComponent.scss";

export const DetailsViewComponent = (props: DetailsViewComponentModel) => {
  const { data, classValue, isLoading, isEditing, isCreate } = props;
  const liStyle = { width: "40%", marginRight: "5%" };
  const tooltip = React.useRef<Tooltip>(null);
  return (
    <div className={`mdm--details-view-component ${classValue}`}>
      {data?.map((item: DetailsViewData, index: number) => {
        return (
          <div
            key={index}
            className={`mdm--sd-column mdm--row ${item.classValue || ""} ${
              !isLoading && !item.isVisible && "display-none"
            }`}
          >
            {isLoading ? (
              <>
                <Skeleton shape={"text"} style={liStyle} />
                <Skeleton shape={"text"} style={{ width: "50%" }} />
              </>
            ) : item.isVisible ? (
              <>
                <span
                  id={item?.id || ""}
                  className={`mdm--sd-column-label ${
                    item.classValue?.includes("mdm--sd-bsd-status-tag") &&
                    "mdm--sd-bsd-status-tag-label "
                  } ${
                    item?.required &&
                    isEditing &&
                    "mdm--sd-column-label-required"
                  }`}
                >
                  {item?.label}
                  {/* {item?.required && isEditing && (
                    <span className="mdm--sd-column-label-required"> *</span>
                  )} */}
                </span>

                {item?.label && (
                  <span className="mdm--sd-column-label-value-seperator">
                    :
                  </span>
                )}
                {
                  <>
                    {isEditing ? (
                      item?.isEditDataLoading ? (
                        <Skeleton shape={"text"} style={liStyle} />
                      ) : item?.editType ===
                        EditFieldTypeConst.DROPDOWN_FIELD ? (
                        <div
                          onMouseOver={(event) =>
                            tooltip.current &&
                            tooltip.current.handleMouseOver(event)
                          }
                          onMouseOut={(event) =>
                            tooltip.current &&
                            tooltip.current.handleMouseOut(event)
                          }
                        >
                          <div className={"k-form-field-wrap"}>
                            <DropDownList
                              className={`mdm--sd-column-value ${
                                !isCreate &&
                                item?.value !== item.editValue?.value
                                  ? "edit-value-changed"
                                  : ""
                              }`}
                              valid={item.isValid}
                              disabled={item.isDisabled}
                              data={item?.editData}
                              value={item?.editValue}
                              onChange={item?.onChange}
                              textField="value"
                              dataItemKey="id"
                              filterable={item.filterable}
                              onFilterChange={item.filterChange}
                              title={`${
                                item?.value &&
                                item?.value !== null &&
                                item?.value != item.editValue &&
                                !isCreate
                                  ? "Before:" + item?.value
                                  : !isCreate &&
                                    (!item?.value || item?.value === null) &&
                                    item?.value != item.editValue
                                  ? "Before: "
                                  : ""
                              }`}
                            />
                            {!item.isValid && item.validationMessage && (
                              <Error>
                                {item.validationMessage || "Required field"}
                              </Error>
                            )}
                          </div>
                        </div>
                      ) : item?.editType === EditFieldTypeConst.DATE_FIELD ? (
                        <div
                          onMouseOver={(event) =>
                            tooltip.current &&
                            tooltip.current.handleMouseOver(event)
                          }
                          onMouseOut={(event) =>
                            tooltip.current &&
                            tooltip.current.handleMouseOut(event)
                          }
                        >
                          <div className={"k-form-field-wrap"}>
                            <DatePicker
                              className={`mdm--sd-column-value ${
                                !isCreate && item?.value != item.editValue
                                  ? "edit-value-changed"
                                  : ""
                              }`}
                              defaultValue={null}
                              value={
                                item?.editValue
                                  ? new Date(item?.editValue)
                                  : null
                              }
                              format={AppConst.VIEW_DATE_FORMAT}
                              weekNumber={false}
                              onChange={item?.onChange}
                              disabled={item.isDisabled}
                              title={`${
                                item?.value &&
                                item?.value !== null &&
                                item?.value != item.editValue &&
                                !isCreate
                                  ? "Before:" + item?.value
                                  : !isCreate &&
                                    (!item?.value || item?.value === null) &&
                                    item?.value != item.editValue
                                  ? "Before: "
                                  : ""
                              }`}
                              valid={item.isValid}
                              min={item?.minDate}
                              max={item?.maxDate}
                            />
                            {!item.isValid && item.validationMessage && (
                              <Error>
                                {item.validationMessage || "Required field"}
                              </Error>
                            )}
                          </div>
                        </div>
                      ) : item?.editType === EditFieldTypeConst.TEXT_FIELD ? (
                        <Tooltip anchorElement="target" position="top">
                          <div className={"k-form-field-wrap"}>
                            <Input
                              className={`mdm--sd-column-value ${
                                !isCreate && item?.value !== item.editValue
                                  ? "edit-value-changed"
                                  : ""
                              }`}
                              valid={item.isValid}
                              maxLength={item.maxLength}
                              validationMessage={item.validationMessage}
                              type={"text"}
                              value={item?.editValue}
                              onChange={item?.onChange}
                              disabled={item?.isDisabled}
                              onBlur={item.onBlur}
                              title={`${
                                item?.value &&
                                item?.value !== null &&
                                item?.value != item.editValue &&
                                !isCreate
                                  ? "Before:" + item?.value
                                  : !isCreate &&
                                    (!item?.value || item?.value === null) &&
                                    item?.value != item.editValue
                                  ? "Before: "
                                  : ""
                              }`}
                              placeholder={item.placeholder}
                            />
                            {!item.isValid && item.validationMessage && (
                              <Error>
                                {item.validationMessage || "Required field"}
                              </Error>
                            )}
                          </div>
                        </Tooltip>
                      ) : item?.editType === EditFieldTypeConst.NUMBER_FIELD ? (
                        <Tooltip anchorElement="target" position="top">
                          <div className={"k-form-field-wrap"}>
                            <NumericTextBox
                              className={`mdm--sd-column-value ${
                                !isCreate &&
                                (item?.editValue === undefined ||
                                  +item?.editValue === 0 ||
                                  item?.editValue === null ||
                                  item?.editValue) &&
                                item?.value !== item.editValue
                                  ? "edit-value-changed"
                                  : ""
                              }`}
                              valid={item.isValid}
                              value={
                                +item?.editValue
                                  ? +item.editValue
                                  : +item.editValue === 0
                                  ? 0
                                  : null
                              }
                              onChange={item?.onChange}
                              disabled={item?.isDisabled}
                              validationMessage={item.validationMessage}
                              title={`${
                                !isCreate &&
                                (item?.editValue === undefined ||
                                  +item?.editValue === 0 ||
                                  item?.editValue === null ||
                                  item?.editValue) &&
                                item?.value !== item.editValue
                                  ? "Before:" +
                                    (item?.value === undefined
                                      ? ""
                                      : item?.value)
                                  : ""
                              }`}
                              // spinners={false}
                              max={item?.maxLength || 99}
                              min={item?.minLength || 0}
                              format="0"
                            />
                            {!item.isValid && item.validationMessage && (
                              <Error>
                                {item.validationMessage || "Required field"}
                              </Error>
                            )}
                          </div>
                        </Tooltip>
                      ) : (
                        <span className="mdm--sd-column-value">
                          {item?.value}
                        </span>
                      )
                    ) : (
                      <span className="mdm--sd-column-value">
                        {item.classValue?.includes("mdm--sd-bsd-status-tag") ? (
                          <Tooltip anchorElement="target" position="top">
                            <span
                              title={item?.value}
                              className={`${item.classValue || ""}`}
                            >
                              {item?.value
                                ? SiteConst.SITE_STATUS_LIST_SHORTHAND[
                                    item?.value
                                      ?.toUpperCase()
                                      .replace(/ /g, "_")
                                      .replace(/\//g, "_")
                                      .replace("-", "_")
                                  ]
                                : ""}
                            </span>
                          </Tooltip>
                        ) : item?.label ? (
                          item?.value || "-"
                        ) : (
                          ""
                        )}
                      </span>
                    )}
                  </>
                }
              </>
            ) : (
              <></>
            )}
          </div>
        );
      })}
      <Tooltip
        ref={tooltip}
        anchorElement="target"
        position="top"
        openDelay={300}
      />
    </div>
  );
};
