import { useEffect, useState } from "react";
import { format } from "date-fns";
//import { CustomerMissionTab } from "../../../ev-site-details/components";


import {
  InputChangeEvent,
  NumericTextBoxChangeEvent,
} from "@progress/kendo-react-inputs";
import { formatDate } from "@progress/kendo-intl";
import { DateInputChangeEvent } from "@progress/kendo-react-dateinputs";
import { DropDownListChangeEvent } from "@progress/kendo-react-dropdowns";

import {
  useBrandCovenantCodesData,
  useCrModelCodesData,
  useCustomerMissionCodesData,
  useDiffDiscountCodesData,
  useVmiIndicatorCodesData,
} from "../../../../../../../../hooks";
import {
  AppConst,
  EditFieldTypeConst,
} from "../../../../../../../../constants";
import { Locale } from "../../../../../../../../utils/localization";
import { DetailsViewComponent } from "../../../../../../../components";
import { DetailsViewData } from "../../../../../../../../models";

const shellNonPublicRetailCodes = [
  { id: "Y", value: "Y" },
  { id: "N", value: "N" },
];

export const AdditionalInfoTab = (props: any) => {
  const {
    isEditing,
    basicDetailsTabSelected,
    basicSiteDetails,
    isBasicSiteDetailsLoading,
    onPatchRequestValueSet,
    onSectionValueSet,
    isSectionValueChanged,
    onSectionValueChanged,
    sectionEditedData,
    classifiedSiteType,
    isCreate,
    isFaxNoValid,
    onFaxNoUpdate,
    isLoyaltyIndicatorValid,
    onLoyaltyIndicatorUpdate,
    loyaltyIndicatorInvalidText,
    
  } = props;
  

  const [brandCovenantSelectedValue, setBrandCovenantSelectedValue] =
    useState<any>();
  const [brandCovenantExpiryDate, setBrandCovenantExpiryDate] = useState<any>();
  const [loyaltyIndicatorSelectedValue, setLoyaltyIndicatorSelectedValue] =
    useState<any>();
  const [
    differentiatedDiscountGroupSelectedValue,
    setDifferentiatedDiscountGroupSelectedValue,
  ] = useState<any>();
  const [faxNo, setFaxNo] = useState("");
  const [customerAccountGroup, setCustomerAccountGroup] = useState("");
  const [salesGroup, setSalesGroup] = useState("");
  const [differentiator, setDifferentiator] = useState<number | null>();
  const [evStartDate, setEvStartDate] = useState<any>();
  const [pricingArea, setPricingArea] = useState("");
  const [pricingAreaName, setPricingAreaName] = useState("");
  const [shipToDistributionChannel, setShipToDistributionChannel] = useState<
    number | null | undefined
  >();
  const [shipToProductDivision, setShipToProductDivision] = useState<
    number | null | undefined
  >();
  const [tpnSiteGroupId, setTpnSiteGroupId] = useState("");
  const [tpnSitePriceGroupId, setTpnSitePriceGroupId] = useState("");

  const [vmiIndicatorSelectedValue, setVmiIndicatorSelectedValue] =
    useState<any>();
  const [crModelSelectedValue, setCrModelSelectedValue] = useState<any>();
  const [
    shellNonPublicRetailSelectedValue,
    setShellNonPublicRetailSelectedValue,
  ] = useState<any>();

  const {
    isLoading: isVmiIndicatorCodesLoading,
    data: vmiIndicatorCodes,
    refetch: refetchVmiIndicatorCodes,
  } = useVmiIndicatorCodesData();
  

  const {
    isLoading: isBrandCovenantCodesLoading,
    data: brandCovenantCodes,
    refetch: refetchBrandCovenantCodes,
  } = useBrandCovenantCodesData();

 

  const {
    isLoading: isCrModelCodesLoading,
    data: crModelCodes,
    refetch: refetchCrModelCodes,
  } = useCrModelCodesData();

  const {
    isLoading: isDiffDiscountCodesLoading,
    data: diffDiscountCodes,
    refetch: refetchDiffDiscountCodes,
  } = useDiffDiscountCodesData();

  //customer mission
  const [siteCustomerMissionData, setSiteCustomerMissionData] = useState<
    DetailsViewData[]
  >([]);
  const [customerPrimaryMission, setCustomerPrimaryMission] = useState<any>();
  const [customerSecondaryMission, setCustomerSecondaryMission] =
    useState<any>();
  const [customerTertiaryMission, setCustomerTertiaryMission] = useState<any>();
  const [customerQuaternaryMission, setCustomerQuaternaryMission] =
    useState<any>();

  const {
    isLoading: isCustomerMissionCodesLoading,
    data: customerMissionCodes,
    refetch: refetchCustomerMissionCodes,
  } = useCustomerMissionCodesData();
  useEffect(() => {
    if ( isEditing) {
      refetchCustomerMissionCodes();
    }
  }, [ isEditing]);

 
  const onCustomerPrimaryMissionChange = (event: DropDownListChangeEvent) => {
    onSectionValueChanged(true);
    const primaryMission = event.target.value;
    setCustomerPrimaryMission(primaryMission);
    onSectionValueSet({
      customerPrimaryMission: primaryMission,
    });
    debugger;
    onPatchRequestValueSet(
      {
        customerMissionPrimaryCd: {
          customerMissionPrimaryCd: primaryMission.value,
        },
      },
      basicSiteDetails?.customerMissionPrimaryCd?.customerMissionPrimaryCd !==
        primaryMission.value
    );
  };

  const onCustomerSecondaryMissionChange = (event: DropDownListChangeEvent) => {
    onSectionValueChanged(true);
    const secondaryMission = event.target.value;
    setCustomerSecondaryMission(secondaryMission);
    onSectionValueSet({
      customerSecondaryMission: secondaryMission,
    });
    onPatchRequestValueSet(
      {
        customerMissionSecondaryCd: {
          customerMissionSecondaryCd: secondaryMission?.value,
        },
      },
      basicSiteDetails?.customerMissionSecondaryCd
        ?.customerMissionSecondaryCd !== secondaryMission.value
    );
  };

  const onCustomerTertiaryMissionChange = (event: DropDownListChangeEvent) => {
    onSectionValueChanged(true);
    const tertiaryMission = event.target.value;
    setCustomerTertiaryMission(tertiaryMission);
    onSectionValueSet({
      customerTertiaryMission: tertiaryMission,
    });
    onPatchRequestValueSet(
      {
        customerMissionTertiaryCd: {
          customerMissionTertiaryCd: tertiaryMission?.value,
        },
      },
      basicSiteDetails?.customerMissionTertiaryCd?.customerMissionTertiaryCd !==
        tertiaryMission.value
    );
  };

  const onCustomerQuaternaryMissionChange = (
    event: DropDownListChangeEvent
  ) => {
    onSectionValueChanged(true);
    const quaternaryMission = event.target.value;
    setCustomerQuaternaryMission(quaternaryMission);
    onSectionValueSet({
      customerQuaternaryMission: quaternaryMission,
    });
    onPatchRequestValueSet(
      {
        customerMissionQuaternaryCd: {
          customerMissionQuaternaryCd: quaternaryMission.value,
        },
      },
      basicSiteDetails?.customerMissionQuaternaryCd
        ?.customerMissionQuaternaryCd !== quaternaryMission.value
    );
  };

  useEffect(() => {
    if (basicDetailsTabSelected === 8 && isEditing) {
      refetchCrModelCodes();
      refetchBrandCovenantCodes();
      refetchVmiIndicatorCodes();
      refetchDiffDiscountCodes();
    }
  }, [basicDetailsTabSelected, isEditing]);

  useEffect(() => {
    if ((sectionEditedData && isSectionValueChanged) || basicSiteDetails) {
      setShellNonPublicRetailSelectedValue(
        sectionEditedData?.shellNonPublicSite || {
          id: basicSiteDetails?.shellNonPublicSite,
          value: basicSiteDetails?.shellNonPublicSite,
        }
      );
      setBrandCovenantSelectedValue(
        sectionEditedData?.brandCovenantCd || {
          id: basicSiteDetails?.brandCovenantCd?.brandCovenantCd,
          value: basicSiteDetails?.brandCovenantCd?.brandCovenantName,
        }
      );
      setBrandCovenantExpiryDate(
        sectionEditedData?.brandCovenantExpiryDate ||
          basicSiteDetails?.brandCovenantExpiryDate
      );
      setLoyaltyIndicatorSelectedValue(
        sectionEditedData?.loyaltyProgramInd || {
          id: basicSiteDetails?.loyaltyProgramInd,
          value: basicSiteDetails?.loyaltyProgramInd,
        }
      );
      setDifferentiatedDiscountGroupSelectedValue(
        sectionEditedData?.differentiatedDiscountGroupCd || {
          id: basicSiteDetails?.differentiatedDiscountGroupCd
            ?.differentiatedDiscountGroupCd,
          value:
            basicSiteDetails?.differentiatedDiscountGroupCd
              ?.differentiatedDiscountGroupCd,
        }
      );
      setVmiIndicatorSelectedValue(
        sectionEditedData?.vmiInd || {
          id: basicSiteDetails?.vmiInd?.vmiIndicatorCd,
          value: basicSiteDetails?.vmiInd?.vmiIndicatorName,
        }
      );
      setFaxNo(sectionEditedData?.faxNo || basicSiteDetails?.faxNo);
      setCrModelSelectedValue(
        sectionEditedData?.crModelCd1 || {
          id: basicSiteDetails?.crModelCd1?.crModelCd,
          value: basicSiteDetails?.crModelCd1?.crModelName,
        }
      );

      setCustomerAccountGroup(
        sectionEditedData?.customerAccountGroup ||
          basicSiteDetails?.customerAccountGroup
      );
      setSalesGroup(
        sectionEditedData?.salesGroup || basicSiteDetails?.salesGroup
      );

      setDifferentiator(
        sectionEditedData?.differentiator
          ? sectionEditedData?.differentiator
          : sectionEditedData?.differentiator === 0
          ? 0
          : sectionEditedData?.differentiator === ""
          ? undefined
          : basicSiteDetails?.differentiator
      );

      setEvStartDate(
        sectionEditedData?.evStartDate || basicSiteDetails?.evStartDate
      );

      setPricingArea(
        sectionEditedData?.pricingAreaCd || basicSiteDetails?.pricingAreaCd
      );
      setPricingAreaName(
        sectionEditedData?.pricingAreaName || basicSiteDetails?.pricingAreaName
      );
      setShipToDistributionChannel(
        sectionEditedData?.shipToDistributChannel
          ? sectionEditedData?.shipToDistributChannel
          : sectionEditedData?.shipToDistributChannel === 0
          ? 0
          : sectionEditedData?.shipToDistributChannel === ""
          ? undefined
          : basicSiteDetails?.shipToDistributChannel
      );
      setShipToProductDivision(
        sectionEditedData?.shipToProductDivision
          ? sectionEditedData?.shipToProductDivision
          : sectionEditedData?.shipToProductDivision === 0
          ? 0
          : sectionEditedData?.shipToProductDivision === ""
          ? undefined
          : basicSiteDetails?.shipToProductDivision
      );
      setTpnSiteGroupId(
        sectionEditedData?.siteGroup || basicSiteDetails?.siteGroup
      );
      setTpnSitePriceGroupId(
        sectionEditedData?.tpnSitePriceGroupId ||
          basicSiteDetails?.tpnSitePriceGroup
      );
     
      //customer mission
      setCustomerPrimaryMission(
        sectionEditedData?.customerPrimaryMission || {
          id: basicSiteDetails?.customerMissionPrimaryCd
            ?.customerMissionPrimaryCd,
          value:
            basicSiteDetails?.customerMissionPrimaryCd
              ?.customerMissionPrimaryCd,
        }
      );
      setCustomerSecondaryMission(
        sectionEditedData?.customerSecondaryMission || {
          id: basicSiteDetails?.customerMissionSecondaryCd
            ?.customerMissionSecondaryCd,
          value:
            basicSiteDetails?.customerMissionSecondaryCd
              ?.customerMissionSecondaryCd,
        }
      );
      setCustomerTertiaryMission(
        sectionEditedData?.customerTertiaryMission || {
          id: basicSiteDetails?.customerMissionTertiaryCd
            ?.customerMissionTertiaryCd,
          value:
            basicSiteDetails?.customerMissionTertiaryCd
              ?.customerMissionTertiaryCd,
        }
      );
      setCustomerQuaternaryMission(
        sectionEditedData?.customerQuaternaryMission || {
          id: basicSiteDetails?.customerMissionQuaternaryCd
            ?.customerMissionQuaternaryCd,
          value:
            basicSiteDetails?.customerMissionQuaternaryCd
              ?.customerMissionQuaternaryCd,
        }
      );
      
      
    }
  }, [sectionEditedData, basicSiteDetails]);

  const onShellNonPublicRetailSiteChange = (event: DropDownListChangeEvent) => {
    setShellNonPublicRetailSelectedValue(event.target.value);
    onSectionValueChanged(true);
    onSectionValueSet({
      shellNonPublicSite: event.target.value,
    });
    onPatchRequestValueSet(
      {
        shellNonPublicSite: event.target.value.id,
      },
      basicSiteDetails?.shellNonPublicSite !== event.target.value.id
    );
  };

  const onBrandCovenantNameChange = (event: DropDownListChangeEvent) => {
    setBrandCovenantSelectedValue(event.target.value);
    onSectionValueChanged(true);
    onSectionValueSet({
      brandCovenantCd: event.target.value,
    });
    onPatchRequestValueSet(
      {
        brandCovenantCd: {
          brandCovenantCd: event.target.value.id,
        },
      },
      basicSiteDetails?.brandCovenantCd?.brandCovenantCd !==
        event.target.value.id
    );
  };

  const onBrandCovenantExpiryDateChange = (event: DateInputChangeEvent) => {
    onSectionValueChanged(true);

    const expiryDate = event.value;
    const date: number = expiryDate?.getDate() || 0;
    const month: number = expiryDate?.getMonth() || 0;
    const year: number = expiryDate?.getFullYear() || 2000;
    const formattedUTCTime = formatDate(new Date(year, month, date), "s");

    setBrandCovenantExpiryDate(
      expiryDate
        ? format(new Date(expiryDate), AppConst.VIEW_DATE_FORMAT)
        : null
    );
    onSectionValueSet({
      brandCovenantExpiryDate: formattedUTCTime,
    });
    onPatchRequestValueSet(
      {
        brandCovenantExpiryDate: formattedUTCTime,
      },
      basicSiteDetails?.brandCovenantExpiryDate !== formattedUTCTime
    );
  };

  const onLoyaltyIndicatorPrgChange = (event: DropDownListChangeEvent) => {
    setLoyaltyIndicatorSelectedValue(event.target.value);
    onSectionValueChanged(true);
    onLoyaltyIndicatorUpdate();
    onSectionValueSet({
      loyaltyProgramInd: event.target.value,
    });
    onPatchRequestValueSet(
      {
        loyaltyProgramInd: event.target.value.id,
      },
      basicSiteDetails?.loyaltyProgramInd !== event.target.value.id
    );
  };

  const onDifferentiatedDiscountGroupChange = (
    event: DropDownListChangeEvent
  ) => {
    setDifferentiatedDiscountGroupSelectedValue(event.target.value);
    onSectionValueChanged(true);
    onSectionValueSet({
      differentiatedDiscountGroupCd: event.target.value,
    });
    onPatchRequestValueSet(
      {
        differentiatedDiscountGroupCd: {
          differentiatedDiscountGroupCd: event.target.value.id,
        },
      },
      basicSiteDetails?.differentiatedDiscountGroupCd
        ?.differentiatedDiscountGroupCd !== event.target.value.id
    );
  };

  const onVmiIndicatorChange = (event: DropDownListChangeEvent) => {
    onSectionValueChanged(true);
    const indValue = event.target.value;
    setVmiIndicatorSelectedValue(indValue);
    onSectionValueSet({
      vmiInd: indValue,
    });
    onPatchRequestValueSet(
      {
        vmiInd: {
          vmiIndicatorCd: indValue?.id,
        },
      },
      basicSiteDetails?.vmiInd?.vmiIndicatorCd !== indValue?.id
    );
  };

  const onFaxNoChange = (event: InputChangeEvent) => {
    setFaxNo(event.value);
    onSectionValueChanged(true);
    onFaxNoUpdate();
    onSectionValueSet({
      faxNo: event.value,
    });
    onPatchRequestValueSet(
      {
        faxNo: event.value,
      },
      basicSiteDetails?.faxNo !== event.value
    );
  };

  const onCrModelChange = (event: DropDownListChangeEvent) => {
    setCrModelSelectedValue(event.target.value);
    onSectionValueChanged(true);
    onSectionValueSet({
      crModelCd1: event.target.value,
    });
    onPatchRequestValueSet(
      {
        crModelCd1: {
          crModelCd: event.target.value.id,
        },
      },
      basicSiteDetails?.crModelCd?.crModelCd !== event.target.value.id
    );
  };

  const onCustomerAccountGroupChange = (event: InputChangeEvent) => {
    setCustomerAccountGroup(event.value);
    onSectionValueChanged(true);
    onSectionValueSet({
      customerAccountGroup: event.value,
    });
    onPatchRequestValueSet(
      {
        customerAccountGroup: event.value,
      },
      basicSiteDetails?.customerAccountGroup !== event.value
    );
  };

  const onSalesGroupChange = (event: InputChangeEvent) => {
    setSalesGroup(event.value);
    onSectionValueChanged(true);
    onSectionValueSet({
      salesGroup: event.value,
    });
    onPatchRequestValueSet(
      {
        salesGroup: event.value,
      },
      basicSiteDetails?.salesGroup !== event.value
    );
  };

  const onDifferentiatorChange = (event: NumericTextBoxChangeEvent) => {
    setDifferentiator(event.value);
    onSectionValueChanged(true);
    onSectionValueSet({
      differentiator: event.value === null ? "" : event.value,
    });
    onPatchRequestValueSet(
      {
        differentiator: event.value === null ? "" : event.value?.toString(),
      },
      +basicSiteDetails?.differentiator !== event.value
    );
  };

  const onEvStartDateChange = (event: DateInputChangeEvent) => {
    onSectionValueChanged(true);
    const evStartDate = event.value;

    const date: number = evStartDate?.getDate() || 0;
    const month: number = evStartDate?.getMonth() || 0;
    const year: number = evStartDate?.getFullYear() || 2000;
    const formattedUTCTime = formatDate(new Date(year, month, date), "s");

    setEvStartDate(
      evStartDate
        ? format(new Date(evStartDate), AppConst.VIEW_DATE_FORMAT)
        : null
    );
    onSectionValueSet({
      evStartDate: formattedUTCTime,
    });
    onPatchRequestValueSet(
      {
        evStartDate: formattedUTCTime,
      },
      basicSiteDetails?.evStartDate !== formattedUTCTime
    );
  };

  const onPricingAreaChange = (event: InputChangeEvent) => {
    setPricingArea(event.value);
    onSectionValueChanged(true);
    onSectionValueSet({
      pricingAreaCd: event.value,
    });
    onPatchRequestValueSet(
      {
        pricingAreaCd: event.value,
      },
      basicSiteDetails?.pricingAreaCd !== event.value
    );
  };

  const onPricingAreaNameChange = (event: InputChangeEvent) => {
    setPricingAreaName(event.value);
    onSectionValueChanged(true);
    onSectionValueSet({
      pricingAreaName: event.value,
    });
    onPatchRequestValueSet(
      {
        pricingAreaName: event.value,
      },
      basicSiteDetails?.pricingAreaName !== event.value
    );
  };

  const onShipToDistributionChannelChange = (
    event: NumericTextBoxChangeEvent
  ) => {
    setShipToDistributionChannel(event.value);
    onSectionValueChanged(true);
    onSectionValueSet({
      shipToDistributChannel: event.value === null ? "" : event.value,
    });
    onPatchRequestValueSet(
      {
        shipToDistributChannel:
          event.value === null ? "" : event.value?.toString(),
      },
      +basicSiteDetails?.shipToDistributChannel !== event.value
    );
  };

  const onShipToProductDivisionChange = (event: NumericTextBoxChangeEvent) => {
    setShipToProductDivision(event.value);
    onSectionValueChanged(true);
    onSectionValueSet({
      shipToProductDivision: event.value === null ? "" : event.value,
    });
    onPatchRequestValueSet(
      {
        shipToProductDivision:
          event.value === null ? "" : event.value?.toString(),
      },
      +basicSiteDetails?.shipToProductDivision !== event.value
    );
  };

  const onTpnSiteGroupIdChange = (event: InputChangeEvent) => {
    setTpnSiteGroupId(event.value);
    onSectionValueChanged(true);
    onSectionValueSet({
      siteGroup: event.value,
    });
    onPatchRequestValueSet(
      {
        siteGroup: event.value,
      },
      +basicSiteDetails?.siteGroup !== +(event.value || 0)
    );
  };

  const onTpnSitePriceGroupIdChange = (event: InputChangeEvent) => {
    setTpnSitePriceGroupId(event.value);
    onSectionValueChanged(true);
    onSectionValueSet({
      tpnSitePriceGroupId: event.value,
    });
    onPatchRequestValueSet(
      {
        tpnSitePriceGroupId: event.value,
      },
      basicSiteDetails?.tpnSitePriceGroupId !== event.value
    );
  };

  const getAdditionalInfoData = () => [
    //GSAP NGSAP RBLA	Competitor Partner
    //Level 3	Level 3	Not Applicable	Not Applicable	Not Applicable
    //Optional
    {
      label:
        Locale.site_details?.retail_admin_update.additional_site_info
          .shell_non_public_retail_site,
      value: basicSiteDetails?.shellNonPublicSite,
      editType: EditFieldTypeConst.DROPDOWN_FIELD,
      onChange: onShellNonPublicRetailSiteChange,
      editData: shellNonPublicRetailCodes || [],
      editValue: shellNonPublicRetailSelectedValue,
      isVisible:
        classifiedSiteType === AppConst.GSAP_SITE_TYPE ||
        classifiedSiteType === AppConst.NON_GSAP_SITE_TYPE,
      isDisabled: false,
    },
    //GSAP NGSAP RBLA	Competitor Partner
    //Level 3	Level 3	Not Applicable	Not Applicable	Not Applicable
    // Optional
    {
      label:
        Locale.site_details?.retail_admin_update.additional_site_info
          .brand_covenant_name,
      value: basicSiteDetails?.brandCovenantCd?.brandCovenantName,
      editType: EditFieldTypeConst.DROPDOWN_FIELD,
      isEditDataLoading: isBrandCovenantCodesLoading,
      onChange: onBrandCovenantNameChange,
      editData: brandCovenantCodes,
      editValue: brandCovenantSelectedValue,
      isVisible:
        classifiedSiteType === AppConst.GSAP_SITE_TYPE ||
        classifiedSiteType === AppConst.NON_GSAP_SITE_TYPE,
      isDisabled: false,
    },
    //GSAP NGSAP RBLA	Competitor Partner
    //Level 3	Level 3	Not Applicable	Not Applicable	Not Applicable
    // Optional
    {
      label:
        Locale.site_details?.retail_admin_update.additional_site_info
          .brand_covenant_expiry_date,
      value: basicSiteDetails?.brandCovenantExpiryDate
        ? format(
            new Date(basicSiteDetails?.brandCovenantExpiryDate),
            AppConst.VIEW_DATE_FORMAT
          )
        : null,
      editType: EditFieldTypeConst.DATE_FIELD,

      editValue: brandCovenantExpiryDate
        ? format(new Date(brandCovenantExpiryDate), AppConst.VIEW_DATE_FORMAT)
        : null,
      onChange: onBrandCovenantExpiryDateChange,
      isVisible:
        classifiedSiteType === AppConst.GSAP_SITE_TYPE ||
        classifiedSiteType === AppConst.NON_GSAP_SITE_TYPE,
      isDisabled: false,
    },
    //GSAP NGSAP RBLA	Competitor Partner
    //Level 3	Level 3	Level 3	Not Applicable	Not Applicable
    //"Conditional Mandatory (Mandatory for active sites)"
    {
      label:
        Locale.site_details?.retail_admin_update.additional_site_info
          .loyalty_indicator,
      value: basicSiteDetails?.loyaltyProgramInd,
      editType: EditFieldTypeConst.DROPDOWN_FIELD,
      onChange: onLoyaltyIndicatorPrgChange,
      editData: shellNonPublicRetailCodes || [],
      editValue: loyaltyIndicatorSelectedValue,
      isValid: isLoyaltyIndicatorValid !== -1,
      validationMessage: loyaltyIndicatorInvalidText,
      isVisible:
        classifiedSiteType === AppConst.GSAP_SITE_TYPE ||
        classifiedSiteType === AppConst.NON_GSAP_SITE_TYPE ||
        classifiedSiteType === AppConst.RBLA_SITE_TYPE,
      isDisabled: false,
      required: true,
    },
    //GSAP NGSAP RBLA	Competitor Partner
    //Level 3	Level 3	Level 3	Not Applicable	Level 3
    //Optional
    {
      label:
        Locale.site_details?.retail_admin_update.additional_site_info
          .ddg_differentiated_discount_group,
      value:
        basicSiteDetails?.differentiatedDiscountGroupCd
          ?.differentiatedDiscountGroupCd,
      editType: EditFieldTypeConst.DROPDOWN_FIELD,
      isEditDataLoading: isDiffDiscountCodesLoading,
      onChange: onDifferentiatedDiscountGroupChange,
      editData: diffDiscountCodes || [],
      editValue: differentiatedDiscountGroupSelectedValue,
      isVisible:
        classifiedSiteType === AppConst.GSAP_SITE_TYPE ||
        classifiedSiteType === AppConst.NON_GSAP_SITE_TYPE ||
        classifiedSiteType === AppConst.RBLA_SITE_TYPE ||
        classifiedSiteType === AppConst.PARTNER_SITE_TYPE,
      isDisabled: false,
    },
    //GSAP NGSAP RBLA	Competitor Partner
    //Level 1	Level 3	Not Applicable	Not Applicable	Not Applicable
    //Optional	Site Basic Data
    {
      label:
        Locale.site_details?.retail_admin_update.additional_site_info
          .vmi_ndicator,
      value: basicSiteDetails?.vmiInd?.vmiIndicatorName,
      editType: EditFieldTypeConst.DROPDOWN_FIELD,
      isEditDataLoading: isVmiIndicatorCodesLoading,
      onChange: onVmiIndicatorChange,
      editData: vmiIndicatorCodes || [],
      isVisible:
        classifiedSiteType === AppConst.GSAP_SITE_TYPE ||
        classifiedSiteType === AppConst.NON_GSAP_SITE_TYPE,
      isDisabled: classifiedSiteType === AppConst.GSAP_SITE_TYPE,
      editValue: vmiIndicatorSelectedValue,
    },
    //GSAP NGSAP RBLA	Competitor Partner
    //Level 1	Level 3	Level 3	Level 3	Not Applicable

    //Optional
    {
      label:
        Locale.site_details?.retail_admin_update.additional_site_info
          .fax_number,
      value: basicSiteDetails?.faxNo,
      editType: EditFieldTypeConst.TEXT_FIELD,
      onChange: onFaxNoChange,
      isVisible:
        classifiedSiteType === AppConst.GSAP_SITE_TYPE ||
        classifiedSiteType === AppConst.NON_GSAP_SITE_TYPE ||
        classifiedSiteType === AppConst.RBLA_SITE_TYPE ||
        classifiedSiteType === AppConst.COMPETITOR_SITE_TYPE,
      isDisabled: classifiedSiteType === AppConst.GSAP_SITE_TYPE,
      editValue: faxNo,
      isValid: isFaxNoValid !== -1,
      validationMessage: Locale.create_site?.error_messages.invalid_fax_no,
      maxLength: 50,
    },
    //GSAP NGSAP RBLA	Competitor Partner
    //Level 3	Level 3	Not Applicable	Not Applicable	Not Applicable
    //Optional
    {
      label:
        Locale.site_details?.retail_admin_update.additional_site_info
          .convenience_retail_model,
      value: basicSiteDetails?.crModelCd1?.crModelName,
      editType: EditFieldTypeConst.DROPDOWN_FIELD,
      isEditDataLoading: isCrModelCodesLoading,
      onChange: onCrModelChange,
      editData: crModelCodes || [],
      editValue: crModelSelectedValue,
      isVisible:
        classifiedSiteType === AppConst.GSAP_SITE_TYPE ||
        classifiedSiteType === AppConst.NON_GSAP_SITE_TYPE,
      isDisabled: false,
    },
    //GSAP NGSAP RBLA	Competitor Partner
    //Level 1	Level 3	Not Applicable	Not Applicable	Not Applicable
    //Optional for GSAP active sites
    {
      label:
        Locale.site_details?.retail_admin_update.additional_site_info
          .customer_account_group,
      value: basicSiteDetails?.customerAccountGroup,
      editType: EditFieldTypeConst.TEXT_FIELD,
      onChange: onCustomerAccountGroupChange,
      isVisible:
        classifiedSiteType === AppConst.GSAP_SITE_TYPE ||
        classifiedSiteType === AppConst.NON_GSAP_SITE_TYPE,
      isDisabled: classifiedSiteType === AppConst.GSAP_SITE_TYPE,
      editValue: customerAccountGroup,
      maxLength: classifiedSiteType === AppConst.GSAP_SITE_TYPE ? 4 : 20,
    },
    //GSAP NGSAP RBLA	Competitor Partner
    //Level 1	Level 3	Not Applicable	Not Applicable	Not Applicable
    //Optional for GSAP active sites
    {
      label:
        Locale.site_details?.retail_admin_update.additional_site_info
          .sales_group,
      value: basicSiteDetails?.salesGroup,
      editType: EditFieldTypeConst.TEXT_FIELD,
      onChange: onSalesGroupChange,
      isVisible:
        classifiedSiteType === AppConst.GSAP_SITE_TYPE ||
        classifiedSiteType === AppConst.NON_GSAP_SITE_TYPE,
      isDisabled: classifiedSiteType === AppConst.GSAP_SITE_TYPE,
      editValue: salesGroup,
      maxLength: classifiedSiteType === AppConst.GSAP_SITE_TYPE ? 3 : 20,
    },
    //GSAP NGSAP RBLA	Competitor Partner
    //Level 1	Level 3	Not Applicable	Not Applicable	Not Applicable
    //"Optional (validation applies to active sites only)"//
    {
      label:
        Locale.site_details?.retail_admin_update.additional_site_info
          .differentiator,
      value: basicSiteDetails?.differentiator,
      editType: EditFieldTypeConst.NUMBER_FIELD,
      onChange: onDifferentiatorChange,
      isVisible:
        classifiedSiteType === AppConst.GSAP_SITE_TYPE ||
        classifiedSiteType === AppConst.NON_GSAP_SITE_TYPE,
      isDisabled: classifiedSiteType === AppConst.GSAP_SITE_TYPE,
      editValue: differentiator,
      maxLength: 99,
      minLength: 0,
    },
    //GSAP NGSAP RBLA	Competitor Partner
    //Level 3	Level 3	Not Applicable	Not Applicable	Not Applicable
    //Optional
    {
      label:
        Locale.site_details?.retail_admin_update.additional_site_info
          .ev_start_date,
      value: basicSiteDetails?.evStartDate
        ? format(
            new Date(basicSiteDetails?.evStartDate),
            AppConst.VIEW_DATE_FORMAT
          )
        : null,
      editType: EditFieldTypeConst.DATE_FIELD,
      editValue: evStartDate
        ? format(new Date(evStartDate), AppConst.VIEW_DATE_FORMAT)
        : null,
      onChange: onEvStartDateChange,
      isVisible:
        classifiedSiteType === AppConst.GSAP_SITE_TYPE ||
        classifiedSiteType === AppConst.NON_GSAP_SITE_TYPE,
      isDisabled: false,
    },
    //GSAP NGSAP RBLA	Competitor Partner
    //Level 1	Level 3	Not Applicable	Not Applicable	Not Applicable
    //Optional
    {
      label:
        Locale.site_details?.retail_admin_update.additional_site_info
          .pricing_area,
      value: basicSiteDetails?.pricingAreaCd,
      editType: EditFieldTypeConst.TEXT_FIELD,
      onChange: onPricingAreaChange,
      isVisible:
        classifiedSiteType === AppConst.GSAP_SITE_TYPE ||
        classifiedSiteType === AppConst.NON_GSAP_SITE_TYPE,
      isDisabled: classifiedSiteType === AppConst.GSAP_SITE_TYPE,
      editValue: pricingArea,
      maxLength: 9,
    },
    //GSAP NGSAP RBLA	Competitor Partner
    //Level 1	Level 3	Not Applicable	Not Applicable	Not Applicable
    //Optional
    {
      label:
        Locale.site_details?.retail_admin_update.additional_site_info
          .pricing_area_name,
      value: basicSiteDetails?.pricingAreaName,
      editType: EditFieldTypeConst.TEXT_FIELD,
      onChange: onPricingAreaNameChange,
      isVisible:
        classifiedSiteType === AppConst.GSAP_SITE_TYPE ||
        classifiedSiteType === AppConst.NON_GSAP_SITE_TYPE,
      isDisabled: classifiedSiteType === AppConst.GSAP_SITE_TYPE,
      editValue: pricingAreaName,
      maxLength: 100,
    },
    //GSAP NGSAP RBLA	Competitor Partner
    //Level 1	Level 3	Not Applicable	Not Applicable	Not Applicable
    //Optional for GSAP active sites
    {
      label:
        Locale.site_details?.retail_admin_update.additional_site_info
          .ship_to_distribution_channel,
      value: basicSiteDetails?.shipToDistributChannel,
      editType: EditFieldTypeConst.NUMBER_FIELD,
      onChange: onShipToDistributionChannelChange,
      editValue: shipToDistributionChannel,
      isVisible:
        classifiedSiteType === AppConst.GSAP_SITE_TYPE ||
        classifiedSiteType === AppConst.NON_GSAP_SITE_TYPE,
      isDisabled: classifiedSiteType === AppConst.GSAP_SITE_TYPE,
      maxLength: 99,
      minLength: 0,
    },
    //GSAP NGSAP RBLA	Competitor Partner
    //Level 1	Level 3	Not Applicable	Not Applicable	Not Applicable
    //Optional for GSAP active sites
    {
      label:
        Locale.site_details?.retail_admin_update.additional_site_info
          .ship_to_product_division,
      value: basicSiteDetails?.shipToProductDivision,
      editType: EditFieldTypeConst.NUMBER_FIELD,
      onChange: onShipToProductDivisionChange,
      editValue: shipToProductDivision,
      isVisible:
        classifiedSiteType === AppConst.GSAP_SITE_TYPE ||
        classifiedSiteType === AppConst.NON_GSAP_SITE_TYPE,
      isDisabled: classifiedSiteType === AppConst.GSAP_SITE_TYPE,
      maxLength: 99,
      minLength: 0,
    },
    //GSAP NGSAP RBLA	Competitor Partner
    //Not Applicable	Not Applicable	Not Applicable	Not Applicable	Level 3
    //Optional
    {
      label:
        Locale.site_details?.retail_admin_update.additional_site_info
          .tpn_site_group,
      value: basicSiteDetails?.siteGroup || "",
      editType: EditFieldTypeConst.TEXT_FIELD,
      onChange: onTpnSiteGroupIdChange,
      editValue: tpnSiteGroupId || "",
      isVisible: classifiedSiteType === AppConst.PARTNER_SITE_TYPE,
      isDisabled: false,
      maxLength: 50,
    },
    //GSAP NGSAP RBLA	Competitor Partner
    //Not Applicable	Not Applicable	Not Applicable	Not Applicable	Level 3
    //Optional
    {
      label:
        Locale.site_details?.retail_admin_update.additional_site_info
          .tpn_site_price_group,
      value: basicSiteDetails?.sitePriceGroup || "",
      editType: EditFieldTypeConst.TEXT_FIELD,
      onChange: onTpnSitePriceGroupIdChange,
      editValue: tpnSitePriceGroupId || "",
      isVisible: classifiedSiteType === AppConst.PARTNER_SITE_TYPE,
      isDisabled: false,
      maxLength: 150,
    },
    {
      label: "",
      value: "",
      isVisible: true,
    },
    //customer mission
    {
      label:
        Locale.site_details?.ev_details.customer_mission
          .Primary_customer_mission,
      value:
        basicSiteDetails?.customerMissionPrimaryCd?.customerMissionPrimaryCd,
      editType: EditFieldTypeConst.DROPDOWN_FIELD,
      onChange: onCustomerPrimaryMissionChange,
      editValue: customerPrimaryMission,
      isEditDataLoading: isCustomerMissionCodesLoading,
      editData: customerMissionCodes?.filter((mission: any) => {
        return (
          mission?.id !== customerSecondaryMission?.id &&
          mission?.id !== customerTertiaryMission?.id &&
          mission?.id !== customerQuaternaryMission?.id
        );
      }),
      isVisible: true,
    },
    {
      label:
        Locale.site_details?.ev_details.customer_mission
          .secondary_customer_mission,
      value:
        basicSiteDetails?.customerMissionSecondaryCd
          ?.customerMissionSecondaryCd,
      editType: EditFieldTypeConst.DROPDOWN_FIELD,
      onChange: onCustomerSecondaryMissionChange,
      isEditDataLoading: isCustomerMissionCodesLoading,
      editData: customerMissionCodes?.filter((mission: any) => {
        return (
          mission?.id !== customerPrimaryMission?.id &&
          mission?.id !== customerTertiaryMission?.id &&
          mission?.id !== customerQuaternaryMission?.id
        );
      }),
      editValue: customerSecondaryMission,
      isVisible: true,
    },
    {
      label:
        Locale.site_details?.ev_details.customer_mission
          .tertiary_customer_mission,
      value:
        basicSiteDetails?.customerMissionTertiaryCd
          ?.customerMissionTertiaryCd,
      editType: EditFieldTypeConst.DROPDOWN_FIELD,
      onChange: onCustomerTertiaryMissionChange,
      isEditDataLoading: isCustomerMissionCodesLoading,
      editData: customerMissionCodes?.filter((mission: any) => {
        return (
          mission?.id !== customerPrimaryMission?.id &&
          mission?.id !== customerSecondaryMission?.id &&
          mission?.id !== customerQuaternaryMission?.id
        );
      }),
      editValue: customerTertiaryMission,
      isVisible: true,
    },
    {
      label:
        Locale.site_details?.ev_details.customer_mission
          .quaternary_customer_mission,
      value:
        basicSiteDetails?.customerMissionQuaternaryCd
          ?.customerMissionQuaternaryCd,
      editType: EditFieldTypeConst.DROPDOWN_FIELD,
      onChange: onCustomerQuaternaryMissionChange,
      isEditDataLoading: isCustomerMissionCodesLoading,
      editData: customerMissionCodes?.filter((mission: any) => {
        return (
          mission?.id !== customerPrimaryMission?.id &&
          mission?.id !== customerSecondaryMission?.id &&
          mission?.id !== customerTertiaryMission?.id
        );
      }),
      editValue: customerQuaternaryMission,
      isVisible: true,
    },
    { label: "", value: "", isVisible: true },
    
  ];
  

  return (
    <>
      <DetailsViewComponent
        data={getAdditionalInfoData()}
        classValue="mdm--row mdm--sd-data additional-info"
        isLoading={isBasicSiteDetailsLoading}
        isEditing={isEditing}
        isCreate={isCreate}
      />
    </>
  );
};
