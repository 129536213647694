import React from "react";
import { FieldWrapper } from "@progress/kendo-react-form";

import { Label, Hint, Error } from "@progress/kendo-react-labels";
import { Input, InputChangeEvent } from "@progress/kendo-react-inputs";
import { Button } from "@progress/kendo-react-buttons";

interface IProps {
  validationMessage?: string;
  touched?: boolean;
  label: string;
  id: string;
  valid: boolean;
  disabled: boolean;
  hint?: string;
  type: string;
  optional?: boolean;
  max?: number;
  value: string;
  placeholder?: string;
  onChange: (event: InputChangeEvent) => void;
  onPaste?: (event: React.ClipboardEvent<HTMLInputElement>) => void;
  showClearButton?: boolean;
  onClearSearchBtnClick?: () => void;
}
export const TextInput = (props: IProps) => {
  const {
    validationMessage,
    touched,
    label,
    id,
    valid,
    disabled,
    hint,
    type,
    optional,
    max,
    value,
    placeholder,
    onChange,
    onPaste,
    onClearSearchBtnClick,
    showClearButton,
  } = props;

  const showValidationMessage = touched && validationMessage;
  const showHint = !showValidationMessage && hint;
  const hindId = showHint ? `${id}_hint` : "";
  const errorId = showValidationMessage ? `${id}_error` : "";

  return (
    <FieldWrapper>
      <Label
        editorId={id}
        editorValid={valid}
        editorDisabled={disabled}
        optional={optional}
      >
        {label}
      </Label>
      <div className={"k-form-field-wrap"}>
        <Input
          valid={valid}
          type={type}
          id={id}
          disabled={disabled}
          maxLength={max}
          value={value}
          onChange={onChange}
          onPaste={onPaste}
          // ariaDescribedBy={`${hindId} ${errorId}`}
          placeholder={placeholder}
        />
        {/* <Hint direction={"end"} style={{ position: "absolute", right: 0 }}>
          {value?.length} / {max}
        </Hint> */}
        {showHint && <Hint id={hindId}>{hint}</Hint>}
        {showValidationMessage && (
          <Error id={errorId}>{validationMessage}</Error>
        )}
        {showClearButton && (
          <Button
            className="mdm--grid-search-clear-btn"
            icon="clear"
            onClick={onClearSearchBtnClick}
            data-testid="mdm--grid-search-clear-btn"
          />
        )}
      </div>
    </FieldWrapper>
  );
};

TextInput.defaultProps = {
  label: "",
  valid: true,
  disabled: false,
  type: "text",
  optional: false,
  showClearButton: false,
};
