import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Cookies from "js-cookie";

import {
  RadioButton,
  RadioButtonChangeEvent,
} from "@progress/kendo-react-inputs";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { Button } from "@progress/kendo-react-buttons";

import {
  authenticateNonShellUser,
  authenticateShellUser,
} from "../../../services/endpoints/auth";
import { setUser } from "../../../redux/actions/auth";
import withTelemetry from "../../components/hoc/with-telemetry";
import { appInsightsService } from "../../../services";
import { AppConst, APP_ROUTES, UserTypeConst } from "../../../constants";

import { Hint } from "@progress/kendo-react-labels";
import ShellFrameLogo from "../../../assets/images/framelogo.png";
import { authService } from "../../../services";

import { EVENT_NAMES } from "../../../constants";
import { Loader } from "../../components";
import { Locale } from "../../../utils/localization";
import { adobeAnalyticsConst } from "../../../constants/adobe-analytics.constant";

import "./LoginPage.scss";

const LoginPage = () => {
  const dispatch = useDispatch();
  const [error, setError] = useState("");
  const [searchParams] = useSearchParams();

  const navigate = useNavigate();

  const code = searchParams.get("code");
  // const savedPayload = localStorage.getItem("loginPayload");
  const savedPayload = Cookies.get("loginPayload");
  const parsedPayload = savedPayload && JSON.parse(savedPayload);
  const [selectedValue, setSelectedValue] = useState<string | null>("");
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [showSidePanel, setShowSidePanel] = useState(false);
  const [prevSearch, setprevSearch] = useState<string | null>("");
  const onHideSidePanelClick = () => {
    setShowSidePanel(false);
  };

  const handelShellUsers = (e: RadioButtonChangeEvent) => {
    setIsDataLoading(true);
    appInsightsService.publishCustomEvent({
      name: EVENT_NAMES.SHELL_LOGIN_CLICKED,
      properties: {},
    });
    setSelectedValue(e.value);
    authService.shellUserLogin();
  };

  const handelNonShellUsers = (e: RadioButtonChangeEvent) => {
    setIsDataLoading(true);
    appInsightsService.publishCustomEvent({
      name: EVENT_NAMES.NON_SHELL_LOGIN_CLICKED,
      properties: {},
    });
    setSelectedValue(e.value);
    authService.nonShellUserLogin();
  };

  const toggleDialog = () => {
    setError("");
  };

  const authData = useSelector((state: any) => state?.auth);

  const setCookies = () => {
    const inFifteenMinutes = new Date(
      new Date().getTime() +
        Math.ceil((authData?.auth?.expiryTime * 1000) / 60000) * 60 * 1000
    );
    Cookies.set("reduxstoredata", JSON.stringify(authData));
    Cookies.set("expiry", inFifteenMinutes?.toString());
  };

  useEffect(() => {
    const tempData = localStorage.getItem("previous");
    if (code && savedPayload) {
      if (parsedPayload["loginType"] === UserTypeConst.NON_SHELL_USER) {
        // Non shell users login
        setIsDataLoading(true);
        authenticateNonShellUser(code, parsedPayload["codeVerifier"]).then(
          (res: any) => {
            if (res.Error) {
              appInsightsService.publishCustomError({ exception: res.Error });

              setError(res.Error);
            } else {
              appInsightsService.publishCustomEvent({
                name: EVENT_NAMES.NON_SHELL_LOGIN_CLICKED,

                properties: res,
              });

              dispatch(setUser(res));
              setCookies();
              navigate(APP_ROUTES.DASHBOARD_PATH);
            }
            setIsDataLoading(false);
          }
        );
      } else if (parsedPayload["loginType"] === UserTypeConst.SHELL_USER) {
        setIsDataLoading(true);
        authenticateShellUser(code, parsedPayload["codeVerifier"]).then(
          (res: any) => {
            if (res.Error) {
              appInsightsService.publishCustomError({ exception: res.Error });
              setError(res.Error);
            } else {
              setError("");
              dispatch(setUser(res));
              setCookies();
              if (tempData) {
                navigate(tempData);
              } else {
                res?.leadershipRoleInd
                  ? navigate(APP_ROUTES.LEADERSHIP_DASHBOARD_PATH)
                  : navigate(APP_ROUTES.DASHBOARD_PATH);
              }
            }
            setIsDataLoading(false);
          }
        );
      }
    } else {
      window.adobeDataLayer = window.adobeDataLayer || [];
      window.adobeDataLayer.push({
        event: "Page Loaded",
        metric: "attract",
        timestamp: new Date().toISOString(),
        page: {
          pageName: "Site Data Web | Login",
          language: adobeAnalyticsConst.language,
          platform: adobeAnalyticsConst.platform,
          countryCode: "",
          cvp: "Site Data Web",
          siteSection: "",
        },
        user: {
          userId: "",
          loginStatus: "inactive",
          userType: "",
          customerRole: "",
        },
      });
    }
    // localStorage.removeItem("reduxstoredata");
    // localStorage.removeItem("loginPayload");
  }, []);

  const renderPanelContent = () => (
    <div className="mdm--sidepanel-body-content">
      {Locale?.common?.contact_us_text + " "}{" "}
      <a href="#">{AppConst.CONTACT_US_EMAIL}</a>
    </div>
  );

  const onChildClick = (e: any) => {
    e.stopPropagation();
  };

  return (
    <div className="login-bg">
      <div className="login">
        <div className="container-blur">
          <div className="login-main-container">
            <div className="login-container">
              <div className="header">
                <img src={ShellFrameLogo} alt="shell logo" className="logo" />
                <h1>{Locale.title}</h1>
              </div>
              <div className="form">
                <div className="groups">
                  <div className="group1">
                    <RadioButton
                      label={Locale.login_page?.shell_user_text}
                      tabIndex={2}
                      value={UserTypeConst.SHELL_USER}
                      checked={selectedValue === UserTypeConst.SHELL_USER}
                      disabled={selectedValue !== ""}
                      ariaDescribedBy={"shellHint"}
                      onChange={handelShellUsers}
                    />
                    <Hint id="shellHint" className="hint">
                      (E.g. John.smith@shell.com)
                    </Hint>
                  </div>
                  <div className="group2">
                    <RadioButton
                      value={UserTypeConst.NON_SHELL_USER}
                      checked={selectedValue === UserTypeConst.NON_SHELL_USER}
                      label={Locale.login_page?.non_shell_user_text}
                      disabled={selectedValue !== ""}
                      ariaDescribedBy={"nonShellHint"}
                      onChange={handelNonShellUsers}
                    />

                    <Hint id="nonShellHint" className="hint">
                      (E.g. John.smith@gmail.com)
                    </Hint>
                  </div>
                </div>
              </div>
            </div>
            {isDataLoading && <Loader />}
            {error && (
              <div>
                {error && (
                  <Dialog
                    className="mdm-dialog unauthorised_dialog_box"
                    title={Locale.login_page?.unauthorize_title}
                    onClose={toggleDialog}
                  >
                    <p
                      style={{
                        margin: "25px",
                        textAlign: "center",
                      }}
                    >
                      {Locale.login_page?.unauthorize_text_1}
                      <a target="_blank" href={AppConst.SNOW_URL}>
                        {Locale.login_page?.snow_request}
                      </a>
                      {Locale.login_page?.unauthorize_text_2}
                    </p>
                    <DialogActionsBar>
                      <div></div>
                      <div></div>
                      <div></div>
                      <button
                        className="k-button k-button-md primary-btn mdm-submit-btn k-rounded-md k-button-solid k-button-solid-base"
                        onClick={toggleDialog}
                      >
                        {Locale?.common?.ok}
                      </button>
                    </DialogActionsBar>
                  </Dialog>
                )}
              </div>
            )}

            <div className="login-footer">
              <h1>
                <a
                  href="https://www.shell.com/terms-of-use.html"
                  target="_blank"
                >
                  {Locale.login_page?.terms_and_conditions}
                </a>{" "}
                <a className="straight-line">|</a>{" "}
                <a href="https://www.shell.com/privacy.html" target="_blank">
                  {Locale.login_page?.privacy_policy}
                </a>
              </h1>

              <div className="login-helpsection">
                <p>
                  <span>
                    <a
                      href="#"
                      onClick={() =>
                        window.evidon.notice.showPreferencesDialog()
                      }
                    >
                      {Locale.login_page?.modify_cookies_preferences}
                    </a>
                    <a className="straight-line"> |</a>
                    <a href="#" onClick={() => setShowSidePanel(true)}>
                      {Locale.login_page?.help}
                    </a>
                    <a className="straight-line"> |</a>{" "}
                    <a href="#" onClick={() => setShowSidePanel(true)}>
                      {Locale.login_page?.contact_us}
                    </a>
                  </span>
                </p>
              </div>
              <div className="login-side-panel">
                <div
                  className={`mdm--sidepanel ${
                    showSidePanel ? "visible" : "hidden"
                  }`}
                  onClick={onHideSidePanelClick}
                >
                  <div
                    onClick={onChildClick}
                    className={`mdm-sidepanel-body ${
                      showSidePanel ? "visible" : "hidden"
                    }`}
                  >
                    <div className="mdm--sidepanel-title mdm--row">
                      <h6>{Locale.common?.drawer_items.contact_us_label}</h6>
                      <Button
                        icon="close"
                        size={"large"}
                        onClick={onHideSidePanelClick}
                      />
                    </div>
                    {showSidePanel && renderPanelContent()}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default withTelemetry(LoginPage);
