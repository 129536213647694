import React, { useState } from "react";
import { useEffect } from "react";

import {
  Notification,
  NotificationGroup,
} from "@progress/kendo-react-notification";
import { Fade } from "@progress/kendo-react-animation";

import "./ToastNotification.scss";

export const ToastNotification = (props: any) => {
  const [showToastNotification, setShowToastNotification] = useState(false);

  useEffect(() => {
    if (props.show) {
      setShowToastNotification(props.show);
      setTimeout(() => {
        setShowToastNotification(false);
      }, 15000);
    }
  }, [props.show]);

  return (
    <>
      <NotificationGroup
        style={{
          right: 0,
          top: 0,
          alignItems: "flex-start",
          flexWrap: "wrap-reverse",
        }}
      >
        <Fade enter={true} exit={true}>
          {showToastNotification && (
            <Notification
              closable={true}
              onClose={() => setShowToastNotification(false)}
              type={{ style: props.type, icon: true }}
            >
              {props.message?.map((message: string, index: number) => (
                <div
                  key={index}
                  className={`${
                    index === 0 && "notification-title"
                  } notification-message`}
                >
                  {message}
                </div>
              ))}
            </Notification>
          )}
        </Fade>
      </NotificationGroup>
    </>
  );
};
