import  { useEffect, useState } from "react";
import "./changeRequestHistory.scss";
import { useParams } from "react-router-dom";
import {  AppConst } from "../../../../../constants";
import { Locale } from "../../../../../utils/localization";

import { useSelector } from "react-redux";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { useQuery } from "react-query";
import { SitesService } from "../../../../../services/sites.service";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { ToastNotification } from "../../../../components/toast-notification/ToastNotification";
import { ConfigurationService } from "../../../../../services/configuration.service";
import axios from "axios";

function ChangeRequestHistory() {
  const { siteId } = useParams();

  const [currentConfigName, setCurrentConfigName] = useState<any>("");
  const [showApproveDialog, setShowApproveDialog] = useState(false);
  const [rejectionReason, setRejectionReason] = useState("");
  const [conformationtext, setConformationtext] = useState(false);
  const [success, setSuccess] = useState(false);
  const [show, setShow] = useState("");
  const [status, setStatus] = useState("");
  const [visible, setVisible] = useState<boolean>(false);

  const toggleDialog = () => {
    setVisible(!visible);
  };
  const details = useSelector((state: any) => {
    return state.changeRequestDetails.details;
  });
  const connection = useSelector((state: any) => {
    return state.siteUpdateConnection.connection;
  });
  const userData = useSelector((state: any) => state.auth.user);
  const okBtnClickCancel = () => {
    setShowApproveDialog(false);
    setConformationtext(false);
  };

  const handleChangeForApproveReject = (e: any) => {
    if (e === "Approve") {
      setCurrentConfigName("Approve");
      setShowApproveDialog(true);
    } else {
      setCurrentConfigName("Reject");
      setShowApproveDialog(true);
    }
  };
  const okBtnClick = async () => {
    if (currentConfigName === "Reject") {
      if (rejectionReason.length < 1) {
        setConformationtext(true);
      } else {
        const data = await approveReject(details?.Id, "Reject");

        // setShow(
        //   `Your change request data has been submited. It will take some time to process, we wil notify its ready.`
        // );
        setStatus("Reject")
        setVisible(true)

        // setSuccess(true);f
        setShowApproveDialog(false);
        setConformationtext(false);
      }
    } else {
      const data = await approveReject(details?.Id, "Approved");

      // setShow(
      //   `Your change request data has been submited. It will take some time to process, we wil notify its ready.`
      // );
      setStatus("Approved")

      setVisible(true)

      // setSuccess(true);
      setShowApproveDialog(false);
      setConformationtext(false);
    }
  };
  const approveReject = async (id: any, condition: string) => {
    let config = {
      headers: {
        "x-channel-Id": connection?.connection?.connectionId,
        "x-initiator-Id": AppConst.NOTIFICATION_HUB_INITIATOR_ID,
      },
    }; 
    if (condition === "Reject") {
      const rejectBody = {
        Status: 2,

        ChangeRequestIds: [details?.id],

        RejectionReason: rejectionReason,
      };
      return ConfigurationService.putChangeRequestApproveReject(rejectBody ,config)


      // return await axios({
      //   method: "put",
      //   url: `https://test-changerequest-api.azurewebsites.net/api/changerequest`,
      //   headers: {
      //     Authorization: "Bearer test",
      //     "x-userId": userData.emailAddress,
      //     "Content-Type": "application/json",
      //     "x-channel-Id": connection?.connection?.connectionId,
      //     "x-initiator-Id": AppConst.NOTIFICATION_HUB_INITIATOR_ID,
      //   },
      //   data: rejectBody,
      // });


      // return await axios({
      //   method: "put",
      //   url: `https://test-changerequest-api.azurewebsites.net/api/changerequest`,
      //   headers: {
      //     Authorization: "Bearer test",
      //     "x-userId": userData.emailAddress,
      //     "Content-Type": "application/json",
      //   },
      //   data: data,
      // });
    } else {
      const approveBody = {
        Status: 1,
        ChangeRequestIds: [details?.id],
      };
      
      return ConfigurationService.putChangeRequestApproveReject(approveBody ,config)


      // return await axios({
      //   method: "put",
      //   url: `https://test-changerequest-api.azurewebsites.net/api/changerequest`,
      //   headers: {
      //     Authorization: "Bearer test",
      //     "x-userId": userData.emailAddress,
      //     "Content-Type": "application/json",
      //     "x-channel-Id": connection?.connection?.connectionId,
      //     "x-initiator-Id": AppConst.NOTIFICATION_HUB_INITIATOR_ID,
      //   },
      //   data: approveBody,
      // });
    

      // return await axios({
      //   method: "put",
      //   url: `https://test-changerequest-api.azurewebsites.net/api/changerequest`,
      //   headers: {
      //     Authorization: "Bearer test",
      //     "x-userId": userData.emailAddress,
      //     "Content-Type": "application/json",
      //   },
      //   data: data,
      // });
    }
  };

  const fetchBasicSiteData = ({ queryKey }: any) => {
    const siteId = queryKey[1];
    return SitesService.getSiteDetails({
      globalSiteId: siteId || "",
      viewName: "BasicDetail",
    });
  };

  const {
    isLoading: isBasicSiteDetailsLoading,
    data: basicSiteDetails,
    refetch: refetchBasicDetails,
  } = useQuery([`basic-site-data-${siteId}`, siteId], fetchBasicSiteData, {
    staleTime: 6000,
    enabled: true,
    refetchOnMount: true,
    refetchOnWindowFocus: false,
  });
  useEffect(() => {
    refetchBasicDetails();
    setStatus(details?.status)
  }, [siteId]);

  const tableHeaders = [
    {
      label: "TIME",
      field: "TIME",
      // width: "90",
      minWidth: "3",
    },
    {
      label: "PREVIOUS VALUE",
      field: "createdDate",
      // width: "90",
      minWidth: "8",
    },
    {
      label: "NEW VALUE",
      field: "",
      // width: "90",
      minWidth: "7",
    },
    {
      label: "STATUS",
      field: "",
    },
  ];
  const renderGridCell = (props: any) => {
    if (props.field === "status") {
      return <td>by</td>;
    }
    if (props.field === "id") {
      return <td>'hi</td>;
    } else if (props.field === "oldValue") {
      return (
        <td>
          {props.dataItem[props.field] === null ? (
            <span className="status_tag blank error">Blank</span>
          ) : (
            props.dataItem[props.field]
          )}
        </td>
      );
    } else if (props.field === "newValue") {
      return (
        <td>
          {props.dataItem[props.field] === null ? (
            <span className="status_tag blank error">Blank</span>
          ) : (
            props.dataItem[props.field]
          )}
        </td>
      );
    }

    return (
      <td
        title={props.dataItem[props.field]}
        className={props.dataItem[props.field] ? "" : "mdm-grid-cell-empty"}
      >
        {props.dataItem[props.field] || "--"}
      </td>
    );
  };

  return (
    <>
      <div className="mdm--change-request-histroy header">
      <ToastNotification show={success} type="success" message={[show]} />
        <div className="main-div-details">
          <div className="button-main-div">
            <div className="itemDetail">
              Details
              {/* <button>Rejected</button> */}
            </div>
            {/* <span
          className={ details?.status === "Open" ? "success-open" : ''}
          style={{color:`${details?.status === "Approved" ? "#2a9041":"" } ` }}
        >
  {details?.status === "Rejected" ? (
            <span className="error" style={{color:'red'}}>{details?.status}</span>
          ) : (
            details?.status
          )}        </span> */}
            <div className="button-div">
              <button
                disabled={status !== "Open"}
                className="Approve-btn mdm-submit-btn primary-btn"
                onClick={() => handleChangeForApproveReject("Approve")}
              >
                Approve
              </button>
              <button
                disabled={status !== "Open"}
                className="Rejected-btn"
                onClick={() => handleChangeForApproveReject("Reject")}
              >
                Reject
              </button>
            </div>
          </div>
          <div className="itemSummary">
            Status :{" "}
            <span
              className={details?.status === "Open" ? "success-open" : ""}
              style={{
                color: `${details?.status === "Approved" ? "#2a9041" : ""} `,
              }}
            >
              {details?.status === "Rejected" ? (
                <span className="error" style={{ color: "red" }}>
                  {details?.status}
                </span>
              ) : (
                details?.status
              )}{" "}
            </span>
          </div>

          <table>
            <tr>
              <td className="colum1">NAME</td>
              <td className="colum2">{details?.fieldName}</td>
            </tr>
            <tr>
              <td className="colum1">PREVIOUS VALUE</td>
              <td className="colum2">{details?.oldValue}</td>
            </tr>
            <tr>
              <td className="colum1">UPDATED VALUE</td>
              <td className="colum2"> {details?.newValue}</td>
            </tr>

            <tr>
              <td className="other">Other details</td>
            </tr>
            <tr>
              <td className="colum1">SHELL SITE ID</td>
              <td className="colum2">{details?.globalSiteId}</td>
            </tr>

            <tr>
              <td className="colum1">SITE NAME</td>
              <td className="colum2">{details?.siteName}</td>
            </tr>

           

            {/* <tr>
            <td className="colum1">AFFILIATION</td>
          </tr> */}

            <tr>
              <td className="colum1">SITE ADDRESS</td>
              <td className="colum2">
                {basicSiteDetails?.data?.result?.streetAddress}
              </td>
            </tr>

            {/* <tr>
            <td className="colum1">DEALER NAME</td>
          </tr> */}

            {/* <tr>
            <td className="colum1">LEGACY ID</td>
            <td className="colum2">C001092</td>
          </tr> */}

            <tr>
              <td className="colum1">OPERATING PLATFORM</td>
              <td className="colum2">
                {
                  basicSiteDetails?.data?.result?.operatingPlatformCd
                    ?.operatingPlatformName
                }
              </td>
            </tr>

            <tr>
              <td className="colum1">COUNTRY </td>
              <td className="colum2">{details?.country}</td>
            </tr>

            <tr>
              <td className="colum1">DATE</td>
              <td className="colum2">{details.createdDate.substr(0, 10)}</td>
            </tr>

            <tr>
              <td className="colum1">TIME</td>
              <td className="colum2"> {details.createdDate.substr(12, 12)}</td>
            </tr>

            <tr>
              <td className="colum1">REQUEST ID</td>
              <td className="colum2">{details?.id}</td>
            </tr>

            {/* <tr>
            <td className="colum1">ASSIGNED GLOBAL TEAM</td>
            <td className="colum2">GSD</td>
          </tr> */}

            <tr>
              <td className="colum1">REQUESTOR</td>
              <td className="colum2">{details?.createdBy}</td>
            </tr>

            <tr>
              <td className="colum1">CATEGORY</td>
              <td className="colum2">
                {basicSiteDetails?.data?.result?.siteCategoryCd?.siteCategoryCd}
              </td>
            </tr>

            <tr>
              <td className="colum1">LAST UPDATED DATE</td>
              <td className="colum2">{details?.createdDate}</td>
            </tr>

            <tr>
              <td className="colum1">LAST PORTAL USER</td>
              <td className="colum2">{details?.updatedBy}</td>
            </tr>
            <tr>
            <td className="colum1">REMARK</td>
            <td className="colum2">{details?.rejectionReason}</td>
          </tr>
          </table>
        </div>

        
       

          <div className="main-div-change mdm--data-grid mdm--landing-page-item-body mdm--sites-list-body">
            <h6 className="change">Change Request History</h6>

            <Grid
              style={{
                maxHeight: "92%",
              }}
              // data={state.items}

              // pageSize={state.pageSize}
              data={details?.items}
            >
              {tableHeaders.map((item: any, index: number) => {
                return (
                  <GridColumn
                    key={index}
                    // width={tableHeaders[index]?.width}
                    field={tableHeaders[index].field}
                    title={tableHeaders[index].label}
                    cell={(props: any) => renderGridCell(props)}
                    // columnMenu={ColumnMenuCheckboxFilter}
                  />
                );
              })}
            </Grid>
          </div>
        </div>
      

      {showApproveDialog && (
        <Dialog
          style={{
            width: "95%",
            marginLeft: "0%",
          }}
          title={currentConfigName}
          className="mdm--session-expired-portal-aproved"
          closeIcon={false}
        >
          {currentConfigName === "Reject" ? (
            <>
              <textarea
                value={rejectionReason}
                name="rejectionReason"
                onChange={(e) => setRejectionReason(e.target.value)}
                placeholder="Enter Reason (Required)"
                style={{ width: "38vh" }}
              />
              {conformationtext ? (
                <div className="mdm--details-view-component-message-error">
                  Required field
                </div>
              ) : (
                ""
              )}
            </>
          ) : (
            <p style={{ margin: "2vh", textAlign: "center" }}>
              Are you sure you want to approve?
            </p>
          )}
          <DialogActionsBar>
            <div className="change-request-button-batch">
              <button
                className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
                onClick={okBtnClickCancel}
              >
                Cancel
              </button>
              <button
                className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
                onClick={okBtnClick}
              >
                {currentConfigName}
              </button>
            </div>
          </DialogActionsBar>
        </Dialog>
      )}
          {visible && (
            <Dialog
              className="mdm-edit-site-confim-dialog mdm-dialog"
              width={400}
              onClose={toggleDialog}
            >
              <p style={{ margin: "25px", textAlign: "center" }}>
                {Locale.site_details?.change_confirmation_msg}
              </p>
              <DialogActionsBar>
                <button
                  className="k-button k-button-md mdm-submit-btn primary-btn k-rounded-md k-button-solid k-button-solid-base"
                  onClick={toggleDialog}
                >
                  {Locale.common.ok}
                </button>
              </DialogActionsBar>
            </Dialog>
          )}
          </>
  );
}

export default ChangeRequestHistory;
