import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import { Skeleton } from "@progress/kendo-react-indicators";
import { Tooltip } from "@progress/kendo-react-tooltip";

import { Locale } from "../../../utils/localization";
import { CategoryConst, RoleConst, APP_ROUTES } from "../../../constants";

import { useSiteCategoryCodesData } from "../../../hooks";
import { useSelector } from "react-redux";

import "./CategoryGraph.scss";

interface IProps {
  data: { label: string; total: number }[];
  isLoading: boolean;
  totalSitesCount: number;
}

export const CategoryGraph = (props: IProps) => {
  const { data, isLoading, totalSitesCount } = props;
  const userDetails = useSelector((state: any) => state?.auth?.user);
  const { pathname } = useLocation();

  const [dataObj, setDataObj] = useState<{ label: string; total: number }[]>(
    []
  );

  const { data: categoryCodes } = useSiteCategoryCodesData();

  useEffect(() => {
    const conventionalFuelSites = data?.filter((category: any) =>
      CategoryConst.CONVENTIONAL_FUEL_SITE_CATEGORY_LIST?.includes(
        category?.label
      )
    );
    const conventionalFuelSitesCount: number = conventionalFuelSites?.reduce(
      (acc: number, obj: { label: string; total: number }) => acc + obj?.total,
      0
    );

    const conventionalFuelSitesWithEV = data?.filter((category: any) =>
      CategoryConst.CONVENTIONAL_FUEL_SITE_WITH_EV_CATEGORY_LIST?.includes(
        category?.label
      )
    );
    const conventionalFuelSitesWithEVCount: number =
      conventionalFuelSitesWithEV?.reduce(
        (acc: number, obj: { label: string; total: number }) =>
          acc + obj?.total,
        0
      );

    const evSites = data?.filter((category: any) =>
      CategoryConst.EV_CATEGORY_LIST?.includes(category?.label)
    );
    const evSitesCount: number = evSites?.reduce(
      (acc: number, obj: { label: string; total: number }) => acc + obj?.total,
      0
    );
    const evSitesCoalition = CategoryConst.EV_CATEGORY_LIST?.map(
      (category: string) => {
        const categoryCount = data?.find(
          (allCategory: { label: string; total: number }) =>
            allCategory?.label === category
        );
        return {
          label: category,
          total: categoryCount?.total || 0,
        };
      }
    );

    const newFuelSites = data?.filter((category: any) =>
      CategoryConst.NEW_FUEL_CATEGORY_LIST?.includes(category?.label)
    );
    const newFuelSitesCount: number = newFuelSites?.reduce(
      (acc: number, obj: { label: string; total: number }) => acc + obj?.total,
      0
    );
    const newFuelSitesCoalition = CategoryConst.NEW_FUEL_CATEGORY_LIST?.map(
      (category: string) => {
        const categoryCount = data?.find(
          (allCategory: { label: string; total: number }) =>
            allCategory?.label === category
        );
        return {
          label: category,
          total: categoryCount?.total || 0,
        };
      }
    );

    const nfrSites = data?.filter((category: any) =>
      CategoryConst.NFR_CATEGORY_LIST?.includes(category?.label)
    );
    const nfrSitesCount: number = nfrSites?.reduce(
      (acc: number, obj: { label: string; total: number }) => acc + obj?.total,
      0
    );

    const otherSites = data?.filter(
      (category: any) =>
        ![
          ...CategoryConst.CONVENTIONAL_FUEL_SITE_CATEGORY_LIST,
          ...CategoryConst.CONVENTIONAL_FUEL_SITE_WITH_EV_CATEGORY_LIST,
          ...CategoryConst.EV_CATEGORY_LIST,
          ...CategoryConst.NEW_FUEL_CATEGORY_LIST,
          ...CategoryConst.NFR_CATEGORY_LIST,
        ].includes(category?.label)
    );
    const otherSitesCount: number = otherSites?.reduce(
      (acc: number, obj: { label: string; total: number }) => acc + obj?.total,
      0
    );
    const otherSitesCoalitionFilter = categoryCodes?.filter((category: any) => {
      if (
        ![
          ...CategoryConst.CONVENTIONAL_FUEL_SITE_CATEGORY_LIST,
          ...CategoryConst.CONVENTIONAL_FUEL_SITE_WITH_EV_CATEGORY_LIST,
          ...CategoryConst.EV_CATEGORY_LIST,
          ...CategoryConst.NEW_FUEL_CATEGORY_LIST,
          ...CategoryConst.NFR_CATEGORY_LIST,
        ].includes(category?.value)
      ) {
        return true;
      }
      return false;
    });

    const aCount = [
      conventionalFuelSitesCount,
      conventionalFuelSitesWithEVCount,
      evSitesCount,
      newFuelSitesCount,
      nfrSitesCount,
      otherSitesCount,
    ];

    const categoryPresentForSitesCount = [...aCount].reduce(
      (partialSum, a) => partialSum + a,
      0
    );
    const categoryNotPresentForSitesCount =
      totalSitesCount - categoryPresentForSitesCount;

    const otherSitesCoalition = otherSitesCoalitionFilter?.map(
      (category: any) => {
        const categoryCount = data?.find(
          (allCategory: { label: string; total: number }) =>
            allCategory?.label === category?.value
        );
        if (category?.value === CategoryConst.OTHER) {
          return {
            label: category?.value,
            total:
              ((categoryCount?.total && categoryCount?.total) || 0) +
              categoryNotPresentForSitesCount,
          };
        }
        return {
          label: category?.value,
          total: categoryCount?.total || 0,
        };
      }
    );

    const categoriesData: {
      label: string;
      total: number;
      coalition?: any;
    }[] = [
      {
        label:
          Locale.landing_page?.categories_section.conventional_fuel_sites_label,
        total: conventionalFuelSitesCount,
      },
      {
        label:
          Locale.landing_page?.categories_section
            .conventional_fuel_sites_with_ev_label,
        total: conventionalFuelSitesWithEVCount,
      },
      {
        label: Locale.landing_page?.categories_section.ev_sites_label,
        total: evSitesCount,
        coalition: evSitesCoalition?.sort(
          (a: { total: number }, b: { total: number }) => b.total - a.total
        ),
      },
      {
        label: Locale.landing_page?.categories_section.new_fuel_sites_label,
        total: newFuelSitesCount,
        coalition: newFuelSitesCoalition?.sort(
          (a: { total: number }, b: { total: number }) => b.total - a.total
        ),
      },
      {
        label: Locale.landing_page?.categories_section.nfr_sites_label,
        total: nfrSitesCount,
      },
      {
        label: Locale.landing_page?.categories_section.other_sites_label,
        total: otherSitesCount + categoryNotPresentForSitesCount,
        coalition: otherSitesCoalition?.sort(
          (a: { total: number }, b: { total: number }) => b.total - a.total
        ),
      },
    ];
    // const categoriesDataAfterSort = categoriesData.sort(
    //   (a, b) => b.total - a.total
    // );
    setDataObj([...categoriesData]);
  }, [categoryCodes, data, totalSitesCount]);

  const TooltipContent = (category: any) => {
    return (
      <>
        {category?.length > 0
          ? category?.map((item: any, index: number) => (
              <div className="category-tooltip" key={index}>
                <span>{`${item?.label} - ${item?.total}`}</span>
              </div>
            ))
          : ""}
      </>
    );
  };

  const evSitesLabel = Locale.landing_page?.categories_section.ev_sites_label;
  const newFuelSitesLabel =
    Locale.landing_page?.categories_section.new_fuel_sites_label;
  const otherSitesLabel =
    Locale.landing_page?.categories_section.other_sites_label;

  return (
    <div className="mdm--category-graph-container">
      <h5 className="mdm--landing-page-item-title">
      {userDetails?.roleDetail?.userRoleName === RoleConst.SALES_OPS_SME && pathname === APP_ROUTES.DASHBOARD_PATH ? Locale.leadership_landing_page?.leadership_categories_shell_section_title : Locale.leadership_landing_page?.leadership_categories_section_title }
      </h5>
      <div className="mdm--landing-page-item-body mdm--category-graph-body box-shadow">
        <div className="doughnut_container">
          {isLoading ? (
            <>
              <span></span>

              {[0, 1, 2, 3, 4, 5]?.map((item: number) => (
                <div
                  className={`loading doughnut_container-item item-${item}`}
                  key={item}
                  data-testid="doughnut_container-item-loading"
                >
                  <div className="doughnut-legend">
                    {item % 2 === 0 ? (
                      <span></span>
                    ) : (
                      <Skeleton shape={"text"} style={{ width: "70%" }} />
                    )}
                  </div>
                  <div className="doughnut-item">
                    <span
                      className={`doughnut doughnut-${
                        item % 2 === 0 ? "right" : "left"
                      }  doughnut-${item}`}
                    >
                      <span className={`circle circle_${item}`}>
                        <span className="circle_label"></span>
                      </span>
                    </span>
                  </div>
                  <div className="doughnut-legend">
                    {item % 2 === 0 ? (
                      <Skeleton shape={"text"} style={{ width: "70%" }} />
                    ) : (
                      <span></span>
                    )}
                  </div>
                </div>
              ))}
            </>
          ) : (
            <>
              {dataObj?.length ? (
                <>
                  <hr className="start_line" />

                  {dataObj?.map(
                    (
                      category: {
                        label: string;
                        total: number;
                        coalition?: any;
                      },
                      index: number
                    ) => (
                      <div
                        className={`doughnut_container-item item-${index}`}
                        key={`${category?.label}_${index}`}
                      >
                        <div className="doughnut-legend">
                          {index % 2 !== 0 && (
                            <>
                              <span>
                                {(category?.label === evSitesLabel ||
                                  category?.label === newFuelSitesLabel ||
                                  category?.label === otherSitesLabel) && (
                                  <Tooltip
                                    key={"category-" + index}
                                    content={() =>
                                      TooltipContent(category?.coalition)
                                    }
                                    anchorElement="target"
                                    position="left"
                                  >
                                    <div
                                      title={"category"}
                                      className="k-icon k-i-information k-i-information-left"
                                    ></div>
                                  </Tooltip>
                                )}
                                {category?.label}
                              </span>
                            </>
                          )}
                        </div>
                        <div className="doughnut-item">
                          <span
                            className={`doughnut doughnut-${
                              index % 2 === 0 ? "right" : "left"
                            } doughnut-${index}`}
                          >
                            <span className={`circle circle_${index}`}>
                              <span className="circle_label">
                                {category?.total || 0}
                              </span>
                            </span>
                          </span>
                        </div>
                        <div className="doughnut-legend">
                          {index % 2 === 0 && (
                            <>
                              <span>{category?.label}</span>
                              {(category?.label === evSitesLabel ||
                                category?.label === newFuelSitesLabel ||
                                category?.label === otherSitesLabel) && (
                                <Tooltip
                                  key={"category-" + index}
                                  content={() =>
                                    TooltipContent(category?.coalition)
                                  }
                                  anchorElement="target"
                                  position="left"
                                >
                                  <div
                                    title={"category"}
                                    className="k-icon k-i-information k-i-information-right"
                                  ></div>
                                </Tooltip>
                              )}
                            </>
                          )}
                        </div>
                      </div>
                    )
                  )}

                  <hr className="end_line" />
                </>
              ) : (
                <div className="no-records-container">
                  <p className="no-records">
                    {Locale.common?.no_records_to_view}
                  </p>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};
