import React, { useEffect, useState, useLayoutEffect } from "react";
import {
  Grid,
  GridColumn,
  GridPageChangeEvent,
  GridSortChangeEvent,
} from "@progress/kendo-react-grid";
import { AppDataGridToolbar } from "./components/app-data-grid-toolbar";

import "./AppDataGrid.scss";

interface TableHeaderType {
  label: any | string;
  field: string;
  width?: string;
  minWidth?: string;
}
interface IProps {
  tableHeaders: TableHeaderType[];
  data: any;
  sortable?: any;
  initialSort?: any;
  onSortChange?: () => void;
  onFilter?: () => void;
  onExport?: () => void;
  onClear: () => void;
  onSearch: (searchText: string) => void;
  searchPlaceHolderText: string;
  renderGridCell: (props: any) => any;
  filterContent?: any;
  exportContent?: any;
  totalCount?: number;
  pageChange?: (event: PageState) => void;
  pageSort?: (event: GridSortChangeEvent) => void;
  pageState?: PageState;
  onRefreshClick?: () => void;
  pageable?: any;
  isLoading?: boolean;
  maxHeight?: string;
  isToolBarVisible?: boolean;
  actionButton?: any;
  statusItems?: any;
  CustomHeader?: any;
  searchValue?: string;
  showFilterBadge: boolean;
  filterBadgeText: string;
}

interface PageState {
  skip: number;
  take: number;
}

const ADJUST_PADDING: number = 1;

export const AppDataGrid = (props: IProps) => {
  const {
    data,
    tableHeaders,
    sortable,
    initialSort,
    searchPlaceHolderText,
    filterContent,
    exportContent,
    totalCount,
    renderGridCell,
    onFilter,
    onExport,
    onClear,
    onSearch,
    isLoading,
    maxHeight,
    statusItems,
    isToolBarVisible = true,
    actionButton,
    CustomHeader,
    searchValue,
    showFilterBadge,
    filterBadgeText,
  } = props;

  const initialDataState: PageState = { skip: 0, take: 20 };

  const minGridWidth = React.useRef<number>(0);
  const grid = React.useRef<any>(null);
  // const [applyMinWidth, setApplyMinWidth] = React.useState(false);
  const [gridCurrent, setGridCurrent] = React.useState(0);
  const [gridCurrentWidth, setGridCurrentWidth] = useState(0);
  const [sort, setSort] = useState(initialSort);
  const [page, setPage] = useState<PageState>(
    props.pageState || initialDataState
  );

  const onSortChange = (e: GridSortChangeEvent) => {
    setSort(e.sort);
    if (props.pageSort) {
      props.pageSort(e);
    }
  };

  useEffect(() => {
    if (props.pageState) {
      setPage(props.pageState);
    }
  }, [props.pageState]);

  useEffect(() => {
    setSort(initialSort);
  }, [initialSort]);

  const pageChange = (event: GridPageChangeEvent) => {
    if (props.pageChange) {
      props.pageChange(event.page);
    } else {
      setPage(event.page);
    }
  };

  const onRefreshClick = () => {
    setSort(initialSort);
    if (props.onRefreshClick) {
      props.onRefreshClick();
    }
  };

  useEffect(() => {
    if (gridCurrentWidth) {
      tableHeaders.map((item: any) =>
        item.minWidth !== undefined
          ? (minGridWidth.current += item.minWidth)
          : minGridWidth.current
      );
      setGridCurrent(grid?.current?.offsetWidth);
    }
  }, [gridCurrentWidth]);

  useEffect(() => {
    grid.current = document.querySelector(".k-grid");
    window.addEventListener("resize", handleResize);
    setGridCurrentWidth(grid?.current?.offsetWidth);
  }, []);

  // useLayoutEffect(() => {
  //   debugger;
  //   grid.current = document.querySelector(".k-grid");
  //   window.addEventListener("resize", handleResize);
  //   tableHeaders.map((item: any) =>
  //     item.minWidth !== undefined
  //       ? (minGridWidth.current += item.minWidth)
  //       : minGridWidth.current
  //   );
  //   setGridCurrent(grid.current.offsetWidth);
  //   setApplyMinWidth(grid.current.offsetWidth < minGridWidth.current);
  // }, []);

  const handleResize = () => {
    setTimeout(() => {
      grid.current = document.querySelector(".k-grid");
      setGridCurrentWidth(grid?.current?.offsetWidth);
    }, 1000);
  };

  const setWidth = (minWidth: number | undefined, field: string) => {
    minWidth === undefined ? (minWidth = 0) : null;
    let width = minWidth
      ? (minWidth / 100) * gridCurrent - ADJUST_PADDING
      : "100";
    return width;
  };

  return (
    <div className="mdm--data-grid">
      {isToolBarVisible && (
        <AppDataGridToolbar
          onSearch={onSearch}
          onFilter={onFilter}
          onExport={onExport}
          onClear={onClear}
          statusItems={statusItems}
          actionButton={actionButton}
          searchPlaceHolderText={searchPlaceHolderText}
          filterContent={filterContent}
          exportContent={exportContent}
          onRefreshClick={onRefreshClick}
          isLoading={isLoading}
          searchValue={searchValue || ""}
          showFilterBadge={showFilterBadge}
          filterBadgeText={filterBadgeText}
        />
      )}

      <Grid
        sortable={sortable}
        pageable={props.pageable}
        style={{
          maxHeight: `${maxHeight}`,
        }}
        selectable={{
          enabled: true,
          drag: false,
          cell: false,
          mode: "multiple",
        }}
        selectedField="selected"
        pageSize={page.take}
        data={data}
        sort={sort}
        onPageChange={pageChange}
        onSortChange={props.onSortChange || onSortChange}
        skip={page.skip}
        take={page.take}
        total={totalCount || data.length}
      >
        {CustomHeader ? (
          <GridColumn
            field="checkId"
            title="checkBox"
            cell={(props: any) => renderGridCell(props)}
            headerCell={CustomHeader}
            width={45}
          />
        ) : null}
        {tableHeaders.map((item: TableHeaderType, index: number) => {
          return (
            <GridColumn
              key={index}
              // width={tableHeaders[index]?.width}
              width={setWidth(
                Number(tableHeaders[index]?.minWidth),
                tableHeaders[index]?.field
              )}
              field={tableHeaders[index].field}
              title={tableHeaders[index].label}
              cell={(props: any) => renderGridCell(props)}
            />
          );
        })}
      </Grid>
    </div>
  );
};

AppDataGrid.defaultProps = {
  sortable: true,
};
