import { useEffect, useState } from "react";
import { NumericTextBoxChangeEvent } from "@progress/kendo-react-inputs";
import { DropDownListChangeEvent } from "@progress/kendo-react-dropdowns";

import { Locale } from "../../../../../../../../utils/localization";
import { DetailsViewComponent } from "../../../../../../../components";
import { useTollGateLayoutCodesData } from "../../../../../../../../hooks";
import {
  AppConst,
  EditFieldTypeConst,
  SiteStatusConst,
} from "../../../../../../../../constants";

export const SpaceUtilisationTab = (props: any) => {
  const {
    isEditing,
    basicSiteDetails,
    isBasicSiteDetailsLoading,

    onPatchRequestValueSet,
    onSectionValueSet,

    onSectionValueChanged,
    isSectionValueChanged,
    sectionEditedData,
    isCreate,
    isSiteSizeAreaValid,

    basicDetailsTabSelected,
    isShopBuildingSizeValid,
    onSiteSizeAreaUpdate,
    onShopBuildingSizeUpdate,

    selectedStatus,
    operatingPlatform,
  } = props;

  const [siteFrontage, setSiteFrontage] = useState<number | null>();
  const [siteDepth, setSiteDepth] = useState<number | null>();
  const [siteSizeArea, setSiteSizeArea] = useState<number | null>();
  const [shopBuildingSize, setShopBuildingSize] = useState<number | null>();
  const [shopSellingSpaceSize, setShopSellingSpaceSize] = useState<
    number | null
  >();
  const [noOfMogasAGOFillingPositions, setNoOfMogasAGOFillingPositions] =
    useState<number | null>();
  const [
    noOfAGOTruckHGVOnlyFillingPositions,
    setNoOfAGOTruckHGVOnlyFillingPositions,
  ] = useState<number | null>();
  const [noOfCarParkingSpots, setNoOfCarParkingSpots] = useState<
    number | null
  >();
  const [noOfTruckHGVParkingSpots, setNoOfTruckHGVParkingSpots] = useState<
    number | null
  >();
  const [tollGateLayout, setTollGateLayout] = useState<{
    id: string;
    value: string;
  }>();

  // const [isSiteFrontageValid, setIsSiteFrontageValid] = useState(0);
  // const [isSiteDepthValid, setIsSiteDepthValid] = useState(0);
  // const [isSiteSizeAreaValid, setIsSiteSizeAreaValid] = useState(0);
  // const [isShopBuildingSizeValid, setIsShopBuildingSizeValid] = useState(0);

  // const triggerValidation = () => {
  //   if (
  //     sectionEditedData?.siteFrontage ||
  //     +sectionEditedData?.siteFrontage === 0
  //   ) {
  //     setIsSiteFrontageValid(1);
  //   } else {
  //     setIsSiteFrontageValid(-1);
  //   }
  //   if (sectionEditedData?.siteDepth || +sectionEditedData?.siteDepth === 0) {
  //     setIsSiteDepthValid(1);
  //   } else {
  //     setIsSiteDepthValid(-1);
  //   }
  //   if (
  //     sectionEditedData?.siteSizeArea ||
  //     +sectionEditedData?.siteSizeArea === 0
  //   ) {
  //     setIsSiteSizeAreaValid(1);
  //   } else {
  //     setIsSiteSizeAreaValid(-1);
  //   }
  //   if (
  //     sectionEditedData?.shopBuildingSize ||
  //     +sectionEditedData?.shopBuildingSize === 0
  //   ) {
  //     setIsShopBuildingSizeValid(1);
  //   } else {
  //     setIsShopBuildingSizeValid(-1);
  //   }
  // };

  const {
    isLoading: isTollGateLayoutCodesLoading,
    data: tollGateLayoutCodes,
    refetch: refetchTollGateLayoutCodes,
  } = useTollGateLayoutCodesData();

  useEffect(() => {
    if (basicDetailsTabSelected === 6 && isEditing) {
      refetchTollGateLayoutCodes();
    }
  }, [basicDetailsTabSelected, isEditing]);

  useEffect(() => {
    if ((sectionEditedData && isSectionValueChanged) || basicSiteDetails) {
      setSiteFrontage(
        sectionEditedData?.siteFrontage
          ? sectionEditedData?.siteFrontage
          : sectionEditedData?.siteFrontage === 0
          ? 0
          : sectionEditedData?.siteFrontage === ""
          ? undefined
          : basicSiteDetails?.siteFrontage
      );
      setSiteDepth(
        sectionEditedData?.siteDepth
          ? sectionEditedData?.siteDepth
          : sectionEditedData?.siteDepth === 0
          ? 0
          : sectionEditedData?.siteDepth === ""
          ? undefined
          : basicSiteDetails?.siteDepth
      );
      setSiteSizeArea(
        sectionEditedData?.siteSizeArea
          ? sectionEditedData?.siteSizeArea
          : sectionEditedData?.siteSizeArea === 0
          ? 0
          : sectionEditedData?.siteSizeArea === ""
          ? undefined
          : basicSiteDetails?.siteSizeArea
      );
      setShopBuildingSize(
        sectionEditedData?.shopBuildingSize
          ? sectionEditedData?.shopBuildingSize
          : sectionEditedData?.shopBuildingSize === 0
          ? 0
          : sectionEditedData?.shopBuildingSize === ""
          ? undefined
          : basicSiteDetails?.shopBuildingSize
      );
      setShopSellingSpaceSize(
        sectionEditedData?.shopSellingSpaceSize
          ? sectionEditedData?.shopSellingSpaceSize
          : sectionEditedData?.shopSellingSpaceSize === 0
          ? 0
          : sectionEditedData?.shopSellingSpaceSize === ""
          ? undefined
          : basicSiteDetails?.shopSellingSpaceSize
      );

      setNoOfMogasAGOFillingPositions(
        sectionEditedData?.mogasTruckFilPosCount
          ? sectionEditedData?.mogasTruckFilPosCount
          : sectionEditedData?.mogasTruckFilPosCount === 0
          ? 0
          : sectionEditedData?.mogasTruckFilPosCount === ""
          ? undefined
          : basicSiteDetails?.mogasTruckFilPosCount
      );

      setNoOfAGOTruckHGVOnlyFillingPositions(
        sectionEditedData?.agoTruckFilPosCount
          ? sectionEditedData?.agoTruckFilPosCount
          : sectionEditedData?.agoTruckFilPosCount === 0
          ? 0
          : sectionEditedData?.agoTruckFilPosCount === ""
          ? undefined
          : basicSiteDetails?.agoTruckFilPosCount
      );

      setNoOfCarParkingSpots(
        sectionEditedData?.carParkingSpotCount
          ? sectionEditedData?.carParkingSpotCount
          : sectionEditedData?.carParkingSpotCount === 0
          ? 0
          : sectionEditedData?.carParkingSpotCount === ""
          ? undefined
          : basicSiteDetails?.carParkingSpotCount
      );

      setNoOfTruckHGVParkingSpots(
        sectionEditedData?.truckParkingSpotCount
          ? sectionEditedData?.truckParkingSpotCount
          : sectionEditedData?.truckParkingSpotCount === 0
          ? 0
          : sectionEditedData?.truckParkingSpotCount === ""
          ? undefined
          : basicSiteDetails?.truckParkingSpotCount
      );
      setTollGateLayout(
        sectionEditedData?.tollGateLayoutInd || {
          id: basicSiteDetails?.tollGateLayoutInd?.yesNoCd,
          value: basicSiteDetails?.tollGateLayoutInd?.yesNoCd,
        }
      );
    }
  }, [sectionEditedData, basicSiteDetails]);

  // AppConst.useEffectSkipFirst(() => {
  //   if (isSectionValueChanged) {
  //     if (
  //       sectionEditedData?.siteFrontage &&
  //       sectionEditedData?.siteDepth &&
  //       sectionEditedData?.siteSizeArea &&
  //       sectionEditedData?.shopBuildingSize
  //     ) {
  //       setIsSectionValid(1);
  //     } else {
  //       setIsSectionValid(0);
  //     }
  //   }
  // }, [siteFrontage, siteDepth, siteSizeArea, shopBuildingSize]);

  // useEffect(() => {
  //   if (isSectionValid === -1) {
  //     triggerValidation();
  //   }
  // }, [isSectionValid]);

  const onSiteFrontageChange = (event: NumericTextBoxChangeEvent) => {
    onSectionValueChanged(true);
    const frontage = event.value;
    setSiteFrontage(frontage);
    onSectionValueSet({
      siteFrontage: frontage === null ? "" : frontage,
    });
    onPatchRequestValueSet(
      {
        siteFrontage: frontage === null ? "" : frontage?.toString(),
      },
      +basicSiteDetails?.siteFrontage !== frontage
    );
  };

  const onSiteDepthChange = (event: NumericTextBoxChangeEvent) => {
    onSectionValueChanged(true);
    const depth = event.value;
    setSiteDepth(depth);
    onSectionValueSet({
      siteDepth: depth === null ? "" : depth,
    });
    onPatchRequestValueSet(
      {
        siteDepth: depth === null ? "" : depth?.toString(),
      },
      +basicSiteDetails?.siteDepth !== depth
    );
  };

  const onSiteSizeAreaChange = (event: NumericTextBoxChangeEvent) => {
    onSectionValueChanged(true);
    onSiteSizeAreaUpdate();
    const area = event.value;
    setSiteSizeArea(area);
    onSectionValueSet({
      siteSizeArea: area === null ? "" : area,
    });
    onPatchRequestValueSet(
      {
        siteSizeArea: area === null ? "" : area?.toString(),
      },
      +basicSiteDetails?.siteSizeArea !== area
    );
  };

  const onShopBuildingSizeChange = (event: NumericTextBoxChangeEvent) => {
    onSectionValueChanged(true);
    const bSize = event.value;
    setShopBuildingSize(bSize);
    onShopBuildingSizeUpdate();
    onSectionValueSet({
      shopBuildingSize: bSize === null ? "" : bSize,
    });
    onPatchRequestValueSet(
      {
        shopBuildingSize: bSize === null ? "" : bSize?.toString(),
      },
      +basicSiteDetails?.shopBuildingSize !== bSize
    );
  };

  const onShopSellingSpaceSizeChange = (event: NumericTextBoxChangeEvent) => {
    const space = event.value;
    setShopSellingSpaceSize(space);
    onSectionValueSet({
      shopSellingSpaceSize: space === null ? "" : space,
    });
    onPatchRequestValueSet(
      {
        shopSellingSpaceSize: space === null ? "" : space?.toString(),
      },
      +basicSiteDetails?.shopSellingSpaceSize !== space
    );
    onSectionValueChanged(true);
  };

  const onNoOfMogasAGOFillingPositionsChange = (
    event: NumericTextBoxChangeEvent
  ) => {
    const fPositions = event.value;
    setNoOfMogasAGOFillingPositions(fPositions);
    onSectionValueSet({
      mogasTruckFilPosCount: fPositions === null ? "" : fPositions,
    });
    onPatchRequestValueSet(
      {
        mogasTruckFilPosCount:
          fPositions === null ? "" : fPositions?.toString(),
      },
      +basicSiteDetails?.mogasTruckFilPosCount !== fPositions
    );
    onSectionValueChanged(true);
  };

  const onNoOfAGOTruckHGVOnlyFillingPositionsChange = (
    event: NumericTextBoxChangeEvent
  ) => {
    const fPositions = event.value;
    setNoOfAGOTruckHGVOnlyFillingPositions(fPositions);
    onSectionValueSet({
      agoTruckFilPosCount: fPositions === null ? "" : fPositions,
    });
    onPatchRequestValueSet(
      {
        agoTruckFilPosCount: fPositions === null ? "" : fPositions?.toString(),
      },
      +basicSiteDetails?.agoTruckFilPosCount !== fPositions
    );
    onSectionValueChanged(true);
  };

  const onNoOfCarParkingSpotsChange = (event: NumericTextBoxChangeEvent) => {
    const parkingSpots = event.value;
    setNoOfCarParkingSpots(parkingSpots);
    onSectionValueSet({
      carParkingSpotCount: parkingSpots === null ? "" : parkingSpots,
    });
    onPatchRequestValueSet(
      {
        carParkingSpotCount:
          parkingSpots === null ? "" : parkingSpots?.toString(),
      },
      +basicSiteDetails?.carParkingSpotCount !== parkingSpots
    );
    onSectionValueChanged(true);
  };

  const onNoOfTruckHGVParkingSpotsChange = (
    event: NumericTextBoxChangeEvent
  ) => {
    const parkingSpots = event.value;
    setNoOfTruckHGVParkingSpots(parkingSpots);
    onSectionValueSet({
      truckParkingSpotCount: parkingSpots === null ? "" : parkingSpots,
    });
    onPatchRequestValueSet(
      {
        truckParkingSpotCount:
          parkingSpots === null ? "" : parkingSpots?.toString(),
      },
      +basicSiteDetails?.truckParkingSpotCount !== parkingSpots
    );
    onSectionValueChanged(true);
  };

  const onTollGateLayoutChange = (event: DropDownListChangeEvent) => {
    onSectionValueChanged(true);
    const tollGate = event.target.value;
    setTollGateLayout(tollGate);
    onSectionValueSet({
      tollGateLayoutInd: tollGate,
    });
    onPatchRequestValueSet(
      {
        tollGateLayoutInd: {
          YesNoCd: tollGate.value,
        },
      },
      basicSiteDetails?.tollGateLayoutInd?.yesNoCd !== tollGate.value
    );
  };

  const getSpaceUtilizationData = () => [
    {
      label: Locale.site_details?.ev_details.space_utilisation.site_frontage,
      value: basicSiteDetails?.siteFrontage,
      editType: EditFieldTypeConst.NUMBER_FIELD,
      onChange: onSiteFrontageChange,
      editValue: siteFrontage,
      isVisible: true,
      minLength: 0,
      maxLength: 999999999,
      isvalid: true,
    },
    {
      label: Locale.site_details?.ev_details.space_utilisation.site_depth,
      value: basicSiteDetails?.siteDepth,
      editType: EditFieldTypeConst.NUMBER_FIELD,
      onChange: onSiteDepthChange,
      editValue: siteDepth,
      isVisible: true,
      minLength: 0,
      maxLength: 999999999,
      isvalid: true,
    },
    {
      label: Locale.site_details?.ev_details.space_utilisation.site_size_area,
      value: basicSiteDetails?.siteSizeArea,
      editType: EditFieldTypeConst.NUMBER_FIELD,
      onChange: onSiteSizeAreaChange,
      editValue: siteSizeArea,
      isVisible: true,
      minLength: 0,
      maxLength: 999999999,
      isValid: isSiteSizeAreaValid !== -1,
      validationMessage: Locale.create_site?.error_messages?.site_size_area,
      required:
        selectedStatus?.id === SiteStatusConst.ACTIVE &&
        operatingPlatform &&
        AppConst.COMPANY_OWNED_RE_TYPE_LIST?.includes(operatingPlatform?.value),
    },
    {
      label:
        Locale.site_details?.ev_details.space_utilisation.shop_building_size,
      value: basicSiteDetails?.shopBuildingSize,
      editType: EditFieldTypeConst.NUMBER_FIELD,
      onChange: onShopBuildingSizeChange,
      editValue: shopBuildingSize,
      isVisible: true,
      minLength: 0,
      maxLength: 999999999,
      isValid: isShopBuildingSizeValid !== -1,
      validationMessage: Locale.create_site?.error_messages?.shop_bulding_size,
      required:
        selectedStatus?.id === SiteStatusConst.ACTIVE &&
        operatingPlatform &&
        AppConst.COMPANY_OWNED_RE_TYPE_LIST?.includes(operatingPlatform?.value),
    },
    {
      label:
        Locale.site_details?.ev_details.space_utilisation
          .shop_selling_space_size,
      value: basicSiteDetails?.shopSellingSpaceSize,
      editType: EditFieldTypeConst.NUMBER_FIELD,
      onChange: onShopSellingSpaceSizeChange,
      editValue: shopSellingSpaceSize,
      isVisible: true,
      maxLength: 999999999,
      isvalid: true,
    },
    {
      label:
        Locale.site_details?.ev_details.space_utilisation
          .no_of_mogas_ago_filling_positions,
      value: basicSiteDetails?.mogasTruckFilPosCount,
      editType: EditFieldTypeConst.NUMBER_FIELD,
      onChange: onNoOfMogasAGOFillingPositionsChange,
      editValue: noOfMogasAGOFillingPositions,
      isVisible: true,
      maxLength: 999,
      isvalid: true,
    },
    {
      label:
        Locale.site_details?.ev_details.space_utilisation
          .no_of_ago_truck_hgv_only_filling_positions,
      value: basicSiteDetails?.agoTruckFilPosCount,
      editType: EditFieldTypeConst.NUMBER_FIELD,
      onChange: onNoOfAGOTruckHGVOnlyFillingPositionsChange,
      editValue: noOfAGOTruckHGVOnlyFillingPositions,
      isVisible: true,
      maxLength: 999,
      isvalid: true,
    },
    {
      label:
        Locale.site_details?.ev_details.space_utilisation
          .total_no_of_car_parking_spots,
      value: basicSiteDetails?.carParkingSpotCount,
      editType: EditFieldTypeConst.NUMBER_FIELD,
      onChange: onNoOfCarParkingSpotsChange,
      editValue: noOfCarParkingSpots,
      isVisible: true,
      maxLength: 999,
      isvalid: true,
    },
    {
      label:
        Locale.site_details?.ev_details.space_utilisation
          .no_of_truck_hgv_parking_spots,
      value: basicSiteDetails?.truckParkingSpotCount,
      editType: EditFieldTypeConst.NUMBER_FIELD,
      onChange: onNoOfTruckHGVParkingSpotsChange,
      editValue: noOfTruckHGVParkingSpots,
      isVisible: true,
      maxLength: 999,
      isvalid: true,
    },

    {
      label: Locale.site_details?.ev_details.space_utilisation.toll_gate_layout,
      value: basicSiteDetails?.tollGateLayoutInd?.yesNoCd,
      editType: EditFieldTypeConst.DROPDOWN_FIELD,
      onChange: onTollGateLayoutChange,
      isEditDataLoading: isTollGateLayoutCodesLoading,
      editData: tollGateLayoutCodes || [],
      editValue: tollGateLayout,
      isVisible: true,
    },
  ];

  return (
    <DetailsViewComponent
      data={getSpaceUtilizationData()}
      classValue="mdm--row mdm--sd-data space-utilisation"
      isLoading={isBasicSiteDetailsLoading}
      isEditing={isEditing}
      isCreate={isCreate}
    />
  );
};
