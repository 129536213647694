import { http } from ".";
import { appConfig } from "../config";
import axios from "axios";
import store from "../redux/store";

const cipmAuthentication = appConfig.cipmAuthentication;

export const UserManagementService = {
  logOutUser: () => {
    return http.get(`/userdetails/Logout`);
  },
  nonShellUserLogout: async () => {
    const state = store.getState();
    // const state = useSelector((state: any) => state);
    const token = state.auth?.auth?.accessToken;

    const options = {
      headers: {
        Authorization: `Bearer  ${token}`,
      },
      body: { client_id: cipmAuthentication.clientId, all_sessions: true },
    };

    const response: any = await axios.post(
      `${cipmAuthentication.loginHost}/idp/v1/account/logout`,
      { client_id: cipmAuthentication.clientId, all_sessions: true },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    // console.log("Logout success for nonshell::", response);
    if (response.Error) {
      return response;
    }
    return response && response.data;
  },
};
