import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector, useDispatch, batch } from "react-redux";

import {
  TabStrip,
  TabStripSelectEventArguments,
  TabStripTab,
} from "@progress/kendo-react-layout";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { Button } from "@progress/kendo-react-buttons";

// import {
//   useSiteCategoryCodesData,
//   useSiteStatusCodesData,
//   useSiteTypeCodesData,
//   useOperatingPlatformCodesData,
//   useCrSiteShopTypeCodesData,
//   useCrFormatTypeCodesData,
//   useOrgAffiliationCodesData,
//   useOrgAffiliationTypeCodesData,
//   useNeraRiskCodesData,
//   useCountryCodesData,
//   useCountyCodesData,
//   useTerritoryCodesData,
//   useCountryDiallingCodeData,
//   useTenureTypeCodesData,
//   usePostSiteDetailsMutation,
// } from "../../../../hooks";

import {
  useMasterDataSet1,
  useMasterDataSet2,
  useCountyCodesData,
  useTerritoryCodesData,
  useOrgAffiliationCodesData,
  usePostSiteDetailsMutation,
  useUSACompanyCodesData,
} from "../../../../hooks";

import {
  APP_ROUTES,
  EVENT_NAMES,
  SignalRNotificationStatusConst,
  AppConst,
  SignalRNotificationTypeConst,
  adobeAnalyticsConst,
} from "../../../../constants";

import {
  BasicSiteDetails,
  RetailAdminSiteDetails,
  EVSiteDetails,
} from "../site-details/components";

import withTelemetry from "../../../components/hoc/with-telemetry";
import { appInsightsService } from "../../../../services";

import { Locale } from "../../../../utils/localization";

import { ToastNotification } from "../../../components/toast-notification/ToastNotification";
import { Loader } from "../../../components";

import { updateNotificationState } from "../../../../redux/actions/notificationState.actions";

import "../site-details/SiteDetails.scss";

interface MasterDataType {
  id: string;
  value: string;
  countrydiallingcd?: string;
}

const CreateSite = () => {
  let navigate = useNavigate();
  const dispatch = useDispatch();

  appInsightsService.publishCustomEvent({
    name: EVENT_NAMES.SITE_DETAILS_PAGE_CLICKED,
    properties: {},
  });

  const connection = useSelector((state: any) => {
    return state.siteUpdateConnection.connection;
  });

  const notificationState = useSelector(
    (state: any) => state.notificationState?.notificationState
  );

  const basicSiteDetailsSectionRef = useRef<any>();
  const retailAdminSectionRef = useRef<any>();
  const evSectionRef = useRef<any>();

  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedStatus, setSelectedStatus] = useState<any>();

  const [classifiedSiteType, setClassifiedSiteType] = useState("");
  const [operatingPlatform, setOperatingPlatform] = useState<any>();

  const [showToastNotification, setShowToastNotification] = useState(false);
  const [visible, setVisible] = useState<boolean>(false);
  const [selectedCountry, setSelectedCountry] = useState<any>("");
  const [selectedTerritory, setSelectedTerritory] = useState<any>("");
  const [detailsTabSelected, setDetailsTabSelected] = useState<number>(0);
  const [isEditing, setIsEditing] = useState(true);
  const [siteGeoCoordinates, setSiteGeoCoordinates] = useState({
    longitude: "",
    latitude: "",
  });
  const [patchRequestData, setPatchRequestData] = useState<any>({});
  const [editedData, setEditedData] = useState<any>();
  const [patchRequestToastMessage, setPatchRequestToastMessage] = useState<
    string[]
  >([]);
  const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState(false);

  const [siteTypeCodes, setSiteTypeCodes] = useState<MasterDataType[]>();
  const [tenureTypeCodes, setTenureTypeCodes] = useState<MasterDataType[]>();
  const [crSiteShopTypeCodes, setCRSiteShopTypeCodes] =
    useState<MasterDataType[]>();
  const [crFormatTypeCodes, setCRFormatTypeCodes] =
    useState<MasterDataType[]>();
  const [countryCodes, setCountryCodes] = useState<MasterDataType[]>();
  const [siteStatusCodes, setSiteStatusCodes] = useState<MasterDataType[]>();
  const [siteCategoryCodes, setSiteCategoryCodes] =
    useState<MasterDataType[]>();
  const [operatingPlatformCodes, setOperatingPlatformCodes] =
    useState<MasterDataType[]>();
  const [orgAffiliationTypeCodes, setOrgAffiliationTypeCodes] =
    useState<MasterDataType[]>();
  const [neraRiskCodes, setNeraRiskCodes] = useState<MasterDataType[]>();
  const [isMasterDataLoading, setIsMasterDataLoading] = useState(true);
  const [countryDiallingCode, setCountryDiallingCode] = useState("");
  const [selectedSiteType, setSelectedSiteType] = useState<any>();

  const { data: masterDataSet1, refetch: refetchMasterDataSet1 } =
    useMasterDataSet1();

  const { data: masterDataSet2, refetch: refetchMasterDataSet2 } =
    useMasterDataSet2();

  const {
    isLoading: isUSACompanyCodesLoading,
    data: usaCompanyCodes,
    refetch: refetchUSACompanyCodes,
  } = useUSACompanyCodesData();

  useEffect(() => {
    if (masterDataSet1 && masterDataSet2) {
      const siteTypes = masterDataSet1?.siteTypeCodes?.map((type: any) => {
        return {
          id: type?.siteTypeCd,
          value: type?.siteTypeName,
        };
      });

      const tenureTypes = masterDataSet1?.tenureTypeCodes?.map((type: any) => {
        return {
          id: type?.tenureTypeCd,
          value: type?.tenureTypeName,
        };
      });

      const crSiteShopTypes = masterDataSet1?.crSiteShopTypeCodes?.map(
        (type: any) => {
          return {
            id: type?.crSiteShopTypeCd,
            value: type?.crSiteShopTypeName,
          };
        }
      );

      const crFormatTypes = masterDataSet1?.crFormatTypeCodes?.map(
        (type: any) => {
          return {
            id: type?.crFormatTypeCd,
            value: type?.crFormatTypeName,
          };
        }
      );

      const countryTypes = masterDataSet1?.countryCodes?.map((type: any) => {
        return {
          id: type?.countrycd,
          value: type?.countryname || "",
          countrydiallingcd: type?.countrydiallingcd || "",
        };
      });

      const statusTypes = masterDataSet2?.siteStatusCodes?.map((type: any) => {
        return {
          id: type?.siteStatusCd,
          value: type?.siteStatusName,
        };
      });

      const siteCategoryTypes = masterDataSet2?.siteCategories?.map(
        (type: any, index: number) => {
          return {
            id: index,
            value: type.siteCategoryCd,
          };
        }
      );

      const operatingPlatformTypes =
        masterDataSet2?.operatingPlatformCodes?.map((type: any) => {
          return {
            id: type.operatingPlatformCd,
            value: type.operatingPlatformName,
          };
        });

      const orgAffiliationTypeTypes =
        masterDataSet2?.orgAffiliationTypeCodes?.map((type: any) => {
          return {
            id: type.orgAffiliationTypeCd,
            value: type.orgAffiliationTypeName || "",
          };
        });

      const neraRiskTypes = masterDataSet2?.neraRiskCodes?.map((type: any) => {
        return {
          id: type.neraRiskCd,
          value: type.neraRiskName,
        };
      });

      setSiteTypeCodes(siteTypes);
      setTenureTypeCodes(tenureTypes);
      setCRSiteShopTypeCodes(crSiteShopTypes);
      setCRFormatTypeCodes(crFormatTypes);
      setCountryCodes(countryTypes);

      setSiteStatusCodes(statusTypes);
      setSiteCategoryCodes(siteCategoryTypes);
      setOperatingPlatformCodes(operatingPlatformTypes);
      setOrgAffiliationTypeCodes(orgAffiliationTypeTypes);
      setNeraRiskCodes(neraRiskTypes);
      setIsMasterDataLoading(false);
    }
  }, [masterDataSet1, masterDataSet2]);

  const {
    isLoading: isOrgAffiliationCodesLoading,
    data: orgAffiliationCodes,
    refetch: refetchOrgAffiliationCodes,
  } = useOrgAffiliationCodesData();

  const {
    isLoading: isTerritoryCodesLoading,
    data: territoryCodes,
    refetch: refetchTerritoryCodes,
  } = useTerritoryCodesData(
    selectedCountry?.id,
    selectedCountry?.length ? true : false
  );

  const {
    isLoading: isCountyCodesLoading,
    data: countyCodes,
    refetch: refetchCountyCodes,
  } = useCountyCodesData(
    selectedCountry?.id,
    selectedCountry?.length || selectedTerritory?.length ? true : false
  );

  const {
    mutate: patchRequest,
    isSuccess: isPostRequestSuccess,
    isLoading: isPostRequestLoading,
    data: postRequestResponse,
  } = usePostSiteDetailsMutation();

  useEffect(() => {
    if (postRequestResponse?.Error) {
      const error: any = postRequestResponse?.Error?.message;
      setShowToastNotification(true);
      setPatchRequestToastMessage([
        Locale.create_site?.error_messages?.create_site_request_failed,
        error,
      ]);
      setTimeout(() => {
        setShowToastNotification(false);
      }, 15000);
    } else if (postRequestResponse?.status) {
      batch(() => {
        dispatch(
          updateNotificationState([
            {
              type: SignalRNotificationTypeConst.CREATE_NOTIFICATION,
              id: patchRequestData?.siteName,
              requestId: postRequestResponse?.data?.requestId,
              status: SignalRNotificationStatusConst.IN_PROGRESS_NOTIFICATION,
              isNewNotification: true,
              newRequestData: editedData,
            },
            ...notificationState,
          ])
        );
      });
      setVisible(true);
    }
  }, [isPostRequestSuccess]);

  const onLocationChange = (data: any) => {
    setSiteGeoCoordinates({
      ...siteGeoCoordinates,
      ...data,
    });
  };

  useEffect(() => {
    if (selectedCountry) {
      refetchTerritoryCodes(selectedCountry?.id);
      refetchCountyCodes(selectedCountry?.id);
      const country = countryCodes?.find(
        (country: MasterDataType) => selectedCountry?.id === country?.id
      );
      setCountryDiallingCode(country?.countrydiallingcd || "");
    }
  }, [selectedCountry]);

  useEffect(() => {
    if (selectedTerritory) {
      const obj: any = {
        countryCd: selectedCountry.id,
        territoryCd: selectedTerritory?.id,
      };
      refetchCountyCodes(obj);
    }
  }, [selectedTerritory]);

  useEffect(() => {
    const isInProgress = notificationState?.find((notification: any) => {
      return (
        notification?.id === patchRequestData?.siteName &&
        (notification?.status ===
          SignalRNotificationStatusConst.IN_PROGRESS_NOTIFICATION 
          // || notification?.status ===
          //   SignalRNotificationStatusConst.SUCCESS_NOTIFICATION
            )
      );
    });
    setIsSaveButtonDisabled(false);
    if (isInProgress) {
      setIsSaveButtonDisabled(true);
    }
  }, [notificationState, patchRequestData]);

  useEffect(() => {
    sendAdobeAnalyticsData();
    refetchMasterDataSet1();
    refetchMasterDataSet2();
    refetchOrgAffiliationCodes();
    refetchUSACompanyCodes();
  }, []);

  const { pathname } = useLocation();
  const userDetails = useSelector((state: any) => state.auth.user);

  const selectedGlobalCountry = useSelector((state: any) => {
    return state?.selectedGlobalCountry.selectedGlobalCountry;
  });

  const sendAdobeAnalyticsData = () => {
    window.adobeDataLayer = window.adobeDataLayer || [];
    window.adobeDataLayer.push({
      event: "Page Loaded",
      metric: "attract",
      timestamp: new Date().toISOString(),
      page: {
        pageName: "Site Data Web | " + pathname.slice(1),
        language: adobeAnalyticsConst.language,
        platform: adobeAnalyticsConst.platform,
        countryCode: selectedGlobalCountry,
        cvp: "Site Data Web",
        siteSection: pathname.slice(1),
      },
      user: {
        userId: userDetails.userLoginId,
        loginStatus: "active",
        userType: userDetails.userType,
        customerRole: userDetails?.roleDetail?.userRoleName,
      },
    });
  };
  const handleDetailsTabSelect = (e: TabStripSelectEventArguments) => {
    setDetailsTabSelected(e.selected);
  };

  const onBasicDetailsPatchDataValueSet = (data: any) => {
    const field = Object.keys(data)[0];
    if (data[field] || data[field] == "0") {
      setPatchRequestData({ ...patchRequestData, ...data });
    } else {
      const { [field]: newField, ...patchRequestNewData } = patchRequestData;
      setPatchRequestData({ ...patchRequestNewData });
    }
  };

  const onEditedDataValueSet = (data: any) => {
    setEditedData({ ...editedData, ...data });
  };

  const onCancelButtonClick = () => {
    setPatchRequestData({});
    setEditedData(undefined);
    setIsEditing(!isEditing);

    setIsEditing(!isEditing);
    navigate(`${APP_ROUTES.DASHBOARD_PATH}`);
  };

  const onCountryChange = (country: any) => {
    setSelectedCountry(country);
  };

  const onTerritoryChange = (territory: any) => {
    setSelectedTerritory(territory);
    useCountyCodesData(territory.countryCd, territory.value);
  };

  const toggleDialog = () => {
    setVisible(!visible);
  };

  const onCategorySelected = (category: string) => {
    setSelectedCategory(category);
  };

  const onStatusSelected = (status: { id: string; value: string }) => {
    setSelectedStatus(status);
  };

  const onSaveButtonClick = () => {
    const isBasicDetailsSectionValid =
      basicSiteDetailsSectionRef.current?.triggerValidations();
    const isRetailSectionValid =
      retailAdminSectionRef.current?.triggerValidations();
    const isEvSectionValid = evSectionRef?.current?.triggerValidations();
    if (
      isBasicDetailsSectionValid &&
      isRetailSectionValid &&
      isEvSectionValid &&
      connection?.connection?.connectionId
    ) {
      patchRequest({
        data: patchRequestData,
        channelId: connection?.connection?.connectionId,
        initiatorId: AppConst.NOTIFICATION_HUB_INITIATOR_ID,
      });
    }
    if (!connection?.connection?.connectionId) {
      setShowToastNotification(true);
      setPatchRequestToastMessage([
        "",
        Locale.create_site?.error_messages?.connection_expired,
      ]);
      setTimeout(() => {
        setShowToastNotification(false);
      }, 15000);
    }
  };

  const onSiteTypeSelected = (type: { id: string; value: string }) => {
    setSelectedSiteType(type);
  };

  const onSubmitButtonClick = () => {
    setVisible(false);
  };

  const onClassifiedSiteTypeChange = (type: string) => {
    setClassifiedSiteType(type);
  };

  const onOperatingPlatformChange = (
    op: { id: string; value: string } | null
  ) => {
    setOperatingPlatform(op);
  };

  const onChangeCategory = (category: string) => {
    basicSiteDetailsSectionRef.current?.setNewCategory(category);
  };

  return (
    <div className="mdm--site-details-container">
      {/* <BreadcrumbComponent items={breadcrumbItems} /> */}
      {isPostRequestLoading && <Loader />}
      {showToastNotification && (
        <ToastNotification
          show={showToastNotification}
          type={"error"}
          message={patchRequestToastMessage}
        />
      )}

      {detailsTabSelected === 0 && (
        <div className="mdm--sd-button-container mdm--row">
          <Button
            className="mdm-submit-btn cancel-btn"
            onClick={onCancelButtonClick}
          >
            {Locale.common?.cancel}
          </Button>
          <Button
            className="mdm-submit-btn primary-btn"
            onClick={onSaveButtonClick}
            disabled={isSaveButtonDisabled}
          >
            {Locale.common?.save}
          </Button>
        </div>
      )}
      <div className="mdm--site-details mdm--row">
        <section className="mdm--sd-site-basic-details-section">
          <BasicSiteDetails
            isCreate={true}
            ref={basicSiteDetailsSectionRef}
            basicSiteDetails={undefined}
            isBasicSiteDetailsLoading={false}
            isEditing={isEditing}
            isMasterDataLoading={isMasterDataLoading}
            siteTypeCodes={siteTypeCodes}
            tenureTypeCodes={tenureTypeCodes}
            crSiteShopTypeCodes={crSiteShopTypeCodes}
            crFormatTypeCodes={crFormatTypeCodes}
            countryCodes={countryCodes}
            siteStatusCodes={siteStatusCodes}
            siteCategoryCodes={siteCategoryCodes}
            operatingPlatformCodes={operatingPlatformCodes}
            orgAffiliationTypeCodes={orgAffiliationTypeCodes}
            neraRiskCodes={neraRiskCodes}
            isOrgAffiliationCodesLoading={isOrgAffiliationCodesLoading}
            orgAffiliationCodes={orgAffiliationCodes}
            isCountyCodesLoading={isCountyCodesLoading}
            countyCodes={countyCodes}
            isTerritoryCodesLoading={isTerritoryCodesLoading}
            territoryCodes={territoryCodes}
            refetchTerritoryCodes={onCountryChange}
            refetchCountyCodes={onTerritoryChange}
            onSectionValueSet={onBasicDetailsPatchDataValueSet}
            setSelectedCategory={onCategorySelected}
            setSelectedStatus={onStatusSelected}
            classifiedSiteType={classifiedSiteType}
            onClassifiedSiteTypeChange={onClassifiedSiteTypeChange}
            setOperatingPlatform={onOperatingPlatformChange}
            onLocationChange={onLocationChange}
            countryDiallingCd={countryDiallingCode}
            onSiteTypeSelected={onSiteTypeSelected}
            usaCompanyCodes={usaCompanyCodes}
            isUSACompanyCodesLoading={isUSACompanyCodesLoading}
          />
        </section>

        <section className="mdm-sd-right-section">
          <TabStrip
            selected={detailsTabSelected}
            onSelect={handleDetailsTabSelect}
            className={"mdm--sd-details-parent-tab"}
          >
            <TabStripTab
              title={
                Locale.site_details?.retail_admin_update
                  .retail_admin_update_label
              }
            >
              <RetailAdminSiteDetails
                ref={retailAdminSectionRef}
                siteId={""}
                basicSiteDetails={undefined}
                isBasicSiteDetailsLoading={false}
                isEditing={isEditing}
                isTerritoryCodesLoading={isTerritoryCodesLoading}
                territoryCodes={territoryCodes}
                refetchTerritoryCodes={onCountryChange}
                isCountryCodesLoading={isMasterDataLoading}
                countryCodes={countryCodes}
                onBasicDetailsPatchDataValueSet={
                  onBasicDetailsPatchDataValueSet
                }
                onBasicDetailsEditedDataValueSet={onEditedDataValueSet}
                selectedCategory={selectedCategory}
                selectedStatus={selectedStatus}
                sectionEditedData={editedData}
                classifiedSiteType={classifiedSiteType}
                operatingPlatform={operatingPlatform}
                isCreate={true}
                onChangeCategory={onChangeCategory}
                siteTypeSelectedValue={selectedSiteType}
                countrySelectedValue={selectedCountry}
              />
              <EVSiteDetails
                ref={evSectionRef}
                siteId={""}
                basicSiteDetails={undefined}
                isBasicSiteDetailsLoading={false}
                isEditing={isEditing}
                onBasicDetailsPatchDataValueSet={
                  onBasicDetailsPatchDataValueSet
                }
                onBasicDetailsEditedDataValueSet={onEditedDataValueSet}
                sectionEditedData={editedData}
                isCreate={true}
                classifiedSiteType={classifiedSiteType}
              />
            </TabStripTab>
            <TabStripTab
              title={Locale.site_details?.map.toUpperCase()}
              disabled={
                !siteGeoCoordinates?.latitude || !siteGeoCoordinates?.longitude
              }
            >
              <section className="mdm-sd-map-container">
                <div className="mdm-sd-map">
                  <iframe
                    className="iframed-app__iframe"
                    title={Locale.site_details?.shell_station_locator}
                    src={`https://shellmdmlocator.geoapp.me/?locale=usa_EN&lat=${siteGeoCoordinates?.latitude}&lng=${siteGeoCoordinates?.longitude}`}
                    allowFullScreen={true}
                    allow="geolocation"
                    loading="lazy"
                  ></iframe>
                </div>
              </section>
            </TabStripTab>
          </TabStrip>
        </section>

        {visible && (
          <Dialog
            className="mdm-edit-site-confim-dialog mdm-dialog"
            width={400}
            onClose={toggleDialog}
          >
            <p style={{ margin: "25px", textAlign: "center" }}>
              {Locale.site_details?.create_site_confirmation_msg}
            </p>
            <DialogActionsBar>
              <button
                className="k-button k-button-md mdm-submit-btn primary-btn k-rounded-md k-button-solid k-button-solid-base"
                onClick={onSubmitButtonClick}
              >
                {Locale.common.ok}
              </button>
            </DialogActionsBar>
          </Dialog>
        )}
      </div>
    </div>
  );
};
export default withTelemetry(CreateSite);
