import { Provider } from "react-redux";
import { ReactQueryDevtools } from "react-query/devtools";
import { QueryClientProvider, QueryClient } from "react-query";
import { Helmet } from "react-helmet";

import AppRoutes from "./ui/routes";
import store from "./redux/store";
import { Locale } from "./utils/localization";

const { REACT_APP_ADOBE_ANALYTICS_LAUNCH_URL } = process.env;

declare global {
  interface Window {
    adobeDataLayer: any;
    evidon: any;
  }
}

export function App() {
  const queryClient = new QueryClient();
  return (
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <Helmet>
          <title>{Locale?.default_tab_title}</title>
          <script src={REACT_APP_ADOBE_ANALYTICS_LAUNCH_URL} async />
        </Helmet>
        <AppRoutes />
      </Provider>
      <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
    </QueryClientProvider>
  );
}
