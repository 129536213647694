export const initialState = { siteId: "", isRefetch: false };

interface ActionProps {
  type: String;
  payload?: any;
}

export default function refetchSiteDataReducer(
  state = initialState,
  action: ActionProps
) {
  switch (action.type) {
    case "REFETCH_SITE_DATA":
      state.siteId = action.payload?.siteId;
      state.isRefetch = action.payload?.isRefetch;
      return { ...state };

    default:
      return { ...state };
  }
}
