import { AppConst } from "../constants";

export const initialState = { selectedGlobalCountry: AppConst.ALL_SITES };

interface ActionProps {
  type: String;
  payload?: any;
}

export default function selectedGlobalCountryReducer(
  state = initialState,
  action: ActionProps
) {
  switch (action.type) {
    case "SET_SELECTED_GLOBAL_COUNTRY":
      // state.selectedGlobalCountry = action.payload;
      return { ...state, selectedGlobalCountry: action.payload };

    default:
      return state;
  }
}
