import React, { useEffect, useState } from "react";
import {
  DropDownList,
  DropDownListChangeEvent,
} from "@progress/kendo-react-dropdowns";
import { Skeleton } from "@progress/kendo-react-indicators";

import {
  AppConst,
  CategoryConst,
  EditFieldTypeConst,
} from "../../../../../../../../constants";
import { useProductClassCodesData } from "../../../../../../../../hooks";
import { TableDetailsViewData } from "../../../../../../../../models";
import { Locale } from "../../../../../../../../utils/localization";
import { TableDetailsView } from "../../../../../../../components/table-details-view/TableDetailsView";
import { FadeNotification } from "../../../../../../../components";

export const FuelProductsTab = (props: any) => {
  const {
    isEditing,
    onPatchRequestValueSet,
    onSectionValueSet,
    fetchedFuelProductsData,
    isSectionValueChanged,
    onSectionValueChanged,
    sectionEditedData,
    basicDetailsTabSelected,
    isFuelProductsDataLoading,
    isCreate,
    onChangeCategory,
    onFuelProductsDataUpdate,
    isSectionValid,
    sectionInvalidText,
  } = props;

  const [currentStateOfFuelProductsData, setCurrentStateOfFuelProductsData] =
    useState<TableDetailsViewData>({ tableHeaders: [] });
  const [savedStateOfFuelProductsData, setSavedStateOfFuelProductsData] =
    useState<any>();

  const [newFuelProductSelectedValue, setNewFuelProductSelectedValue] =
    useState<any>();

  const [isNewFuelProductValid, setIsNewFuelProductValid] = useState(0);
  const [fuelProductCodesList, setFuelProductCodesList] = useState<string[]>(
    []
  );

  const {
    isLoading: isFuelProductCodesLoading,
    data: fuelProductCodes,
    refetch: refetchFuelProductCodes,
  } = useProductClassCodesData();

  useEffect(() => {
    if (isEditing) {
      refetchFuelProductCodes();
    }
  }, [isEditing]);

  const onEditRow = (currentState: any, event: any, row: any, field: any) => {
    const newCurrentState = [...(currentState?.rows || [])].map((row: any) => {
      const { isValid, validationMessage, ...allData } = row;
      return allData;
    });

    const currentData = [...(newCurrentState || [])];
    const filterData = currentData?.map((item: any) => {
      if (row.id === item.id) {
        return { ...item, [field]: event?.value, productClassCd: event?.id };
      }
      return { ...item };
    });
    setCurrentStateOfFuelProductsData({ ...currentState, data: filterData });
  };

  useEffect(() => {
    let list: string[] = [];
    const tbHeaders = [
      {
        label:
          Locale.site_details?.retail_admin_update.fuel_products
            .fuel_products_code,
        field: "fuelProductName",
        editType: EditFieldTypeConst.DROPDOWN_FIELD,
        editData: fuelProductCodesList,
        onChange: onEditRow,
      },
      {
        label: "",
        field: "editActionRow",
      },
    ];
    if (isSectionValueChanged && sectionEditedData?.SiteProductClass) {
      list =
        sectionEditedData?.SiteProductClass?.data?.map((item: any) => {
          return {
            id: item.id,
            fuelProductName: item?.fuelProductName,
            productClassCd: item?.productClassCd,
            editActionRow: "",
          };
        }) || [];
    } else {
      list =
        fetchedFuelProductsData?.data?.result?.SiteProductClass?.item?.map(
          (
            item: {
              productClassCd: {
                productClassName: string;
                productClassCd: string;
              };
            },
            index: number
          ) => {
            return {
              id: index,
              fuelProductName: item?.productClassCd?.productClassName,
              productClassCd: item?.productClassCd?.productClassCd,
              editActionRow: "",
            };
          }
        ) || [];
    }
    const fuelProductData: TableDetailsViewData = {
      tableHeaders: tbHeaders,
      data: list,
    };
    setCurrentStateOfFuelProductsData(fuelProductData);
    setSavedStateOfFuelProductsData(fuelProductData);
  }, [
    fetchedFuelProductsData,
    fuelProductCodesList,
    isEditing,
    basicDetailsTabSelected,
  ]);

  useEffect(() => {
    fuelProductCodes && setFuelProductCodesList([...fuelProductCodes]);
  }, [fuelProductCodes]);

  const [defaultData, setDefaultData] = useState<any>([]);
  useEffect(() => {
    if (fetchedFuelProductsData) {
      const defaultFacility =
        fetchedFuelProductsData?.data?.result?.SiteProductClass?.item?.map(
          (item: any, index: number) => {
            return {
              index: index,
              fuelProductName: item?.productClassCd?.productClassName,
              productClassCd: item?.productClassCd?.productClassCd,
            };
          }
        );
      setDefaultData(defaultFacility);
    }
  }, [fetchedFuelProductsData]);

  const onNewFuelProductChange = (event: DropDownListChangeEvent) => {
    setNewFuelProductSelectedValue(event.target.value);
    setIsNewFuelProductValid(1);
  };

  const onShowNewRowClick = () => {
    setNewFuelProductSelectedValue(null);
    setIsNewFuelProductValid(1);
    setCurrentStateOfFuelProductsData({
      ...currentStateOfFuelProductsData,
      data: savedStateOfFuelProductsData.data,
    });
  };

  const onDiscardRow = (rowId: string) => {
    const discardRow = [...savedStateOfFuelProductsData?.data]?.filter(
      (row: any) => row.id === rowId
    )?.[0];

    const currentState = currentStateOfFuelProductsData?.data?.map(
      (row: any) => {
        if (row.id === rowId) {
          return discardRow;
        }
        return { ...row };
      }
    );

    const filteredArray = currentState?.filter((row: any) => {
      return row;
    });

    setCurrentStateOfFuelProductsData({
      ...currentStateOfFuelProductsData,
      data: filteredArray,
    });
  };

  const [newFuelProductInvalidText, setNewFuelProductInvalidText] = useState(
    Locale.create_site?.error_messages?.fuel_product_required_fields
  );
  const onAddNewFuelProductClick = () => {
    const savedFuels = savedStateOfFuelProductsData?.data?.map(
      (fuel: { fuelProductName: string }) => fuel.fuelProductName
    );
    if (newFuelProductSelectedValue) {
      if (savedFuels?.includes(newFuelProductSelectedValue?.value)) {
        setIsNewFuelProductValid(-1);
        setNewFuelProductInvalidText(
          Locale.create_site?.error_messages?.fuel_product_already_exists
        );
      } else {
        setIsNewFuelProductValid(1);
      }
    } else {
      setIsNewFuelProductValid(-1);
      setNewFuelProductInvalidText(
        Locale.create_site?.error_messages?.fuel_product_required_fields
      );
    }
    if (
      newFuelProductSelectedValue &&
      !savedFuels?.includes(newFuelProductSelectedValue?.value)
    ) {
      const newData: any = {
        id: new Date().getMilliseconds() + 1000,
        fuelProductName: newFuelProductSelectedValue?.value || "-",
        productClassCd: newFuelProductSelectedValue?.id || "-",
        editActionRow: "",
      };
      const currentData = [...(currentStateOfFuelProductsData?.data || [])];
      currentData.unshift(newData);

      var currentState: any = {
        ...currentStateOfFuelProductsData,
        data: [...currentData],
      };
      setCurrentStateOfFuelProductsData(currentState);
      setNewFuelProductSelectedValue(null);

      onSectionValueChanged(true);
      setSavedStateOfFuelProductsData({
        ...savedStateOfFuelProductsData,
        data: currentState.data,
      });
      onSectionValueSet({
        SiteProductClass: {
          ...savedStateOfFuelProductsData,
          data: currentState.data,
        },
      });
      onFuelProductsDataUpdate();
      const payload = currentState?.data?.map((product: any) => {
        return {
          productClassCd: {
            productClassCd: product?.productClassCd,
          },
        };
      });
      onPatchRequestValueSet(
        {
          SiteProductClass: {
            item: payload,
          },
        },
        true
      );
      return true;
    }
    return false;
  };

  const onDeleteRow = (rowId: string) => {
    const currentState = currentStateOfFuelProductsData?.data?.filter(
      (row: any) => {
        return row.id !== rowId;
      }
    );
    setCurrentStateOfFuelProductsData({
      ...currentStateOfFuelProductsData,
      data: currentState,
    });

    const savedState = savedStateOfFuelProductsData?.data?.filter(
      (row: any) => {
        return row.id !== rowId;
      }
    );

    onSectionValueChanged(true);
    setSavedStateOfFuelProductsData({
      ...savedStateOfFuelProductsData,
      data: savedState,
    });
    onSectionValueSet({
      SiteProductClass: {
        ...savedStateOfFuelProductsData,
        data: savedState,
      },
    });
    onFuelProductsDataUpdate();
    const payload = savedState?.map((product: any) => {
      return {
        productClassCd: {
          productClassCd: product.productClassCd,
        },
      };
    });
    onPatchRequestValueSet(
      {
        SiteProductClass: {
          item: payload,
        },
      },
      true
    );
  };

  const onSaveRow = (rowId: string) => {
    let isValid: boolean = true;

    const filteredData: any = currentStateOfFuelProductsData?.data?.filter(
      (row: any) => {
        return row.id === rowId;
      }
    )?.[0];

    const existedRecord: any = currentStateOfFuelProductsData?.data?.find(
      (row: any) => {
        return (
          row.fuelProductName === filteredData?.fuelProductName &&
          row?.id !== filteredData?.id
        );
      }
    );
    if (existedRecord?.fuelProductName) {
      isValid = false;

      const newCurrentState = currentStateOfFuelProductsData?.data?.map(
        (row: any) => {
          if (row.id === rowId) {
            return {
              ...row,
              isValid: false,
              validationMessage:
                Locale.create_site?.error_messages?.fuel_product_already_exists,
            };
          }
          return row;
        }
      );
      setCurrentStateOfFuelProductsData({
        ...currentStateOfFuelProductsData,
        data: newCurrentState,
      });
    }

    if (isValid) {
      const savedState = savedStateOfFuelProductsData?.data?.map((row: any) => {
        if (row.id === rowId) {
          return filteredData;
        }
        return { ...row };
      });

      onSectionValueChanged(true);
      onSectionValueSet({
        SiteProductClass: {
          ...currentStateOfFuelProductsData,
          data: savedState,
        },
      });
      onFuelProductsDataUpdate();
      setSavedStateOfFuelProductsData({
        ...currentStateOfFuelProductsData,
        data: savedState,
      });
      const payload = savedState.map((product: any) => {
        return {
          productClassCd: {
            productClassCd: product.productClassCd,
          },
        };
      });
      onPatchRequestValueSet(
        {
          SiteProductClass: {
            item: payload,
          },
        },
        true
      );
    }
    return isValid;
  };

  AppConst.useEffectSkipFirst(() => {
    if (savedStateOfFuelProductsData && isSectionValueChanged) {
      //Hydrogen  //Hydrogen only
      //EV 2     // EV only
      //LNG      //LNG only
      //Diesel  //Conventional

      //Diesel + EV   //Conventional with EV
      //Diesel + Hydrogen  //?
      //Diesel + LNG  //?
      //EV + Hydrogen //?
      //EV +  LNG //?
      //Hydrogen + LNG //?

      const conventionalFuelsObjArray = fuelProductCodes?.filter(
        (fuel: any) =>
          +fuel?.id !== AppConst.HYDROGEN_PRODUCT_CODE &&
          +fuel?.id !== AppConst.LNG_PRODUCT_CODE &&
          +fuel?.id !==
            AppConst.ELECTRIC_CHARGING_SHELL_RECHARGE_PRODUCT_CODE &&
          +fuel?.id !== AppConst.ELECTRIC_CHARGING_OTHER_PRODUCT_CODE
      );

      var conventionalFuels = conventionalFuelsObjArray?.map(
        (fuel: { id: number }) => +fuel.id
      );

      const containsAllConventional = savedStateOfFuelProductsData?.data?.every(
        (product: any) => conventionalFuels?.includes(+product?.productClassCd)
      );

      const containsHydrogen = savedStateOfFuelProductsData?.data?.find(
        (product: any) =>
          +product?.productClassCd === AppConst.HYDROGEN_PRODUCT_CODE
      );

      const containsLNG = savedStateOfFuelProductsData?.data?.find(
        (product: any) => +product?.productClassCd === AppConst.LNG_PRODUCT_CODE
      );

      const containsAllEV = savedStateOfFuelProductsData?.data?.every(
        (product: any) =>
          AppConst.EV_CHARGING_CODE_LIST?.includes(+product?.productClassCd)
      );

      const containsEV = savedStateOfFuelProductsData?.data?.find(
        (product: any) =>
          AppConst.EV_CHARGING_CODE_LIST?.includes(+product?.productClassCd)
      );

      const containsConventional = savedStateOfFuelProductsData?.data?.find(
        (product: any) => conventionalFuels?.includes(+product?.productClassCd)
      );

      if (
        savedStateOfFuelProductsData?.data?.length &&
        containsAllConventional &&
        containsConventional &&
        !containsEV &&
        !containsHydrogen &&
        !containsLNG
      ) {
        //If product list does not contain any of LNG/HYdrogen/EV products
        isSectionValueChanged &&
          onChangeCategory(CategoryConst.CONVENTIONAL_FUEL_SITE);
      } else if (
        savedStateOfFuelProductsData?.data?.length &&
        containsAllEV &&
        containsEV &&
        !containsConventional &&
        !containsHydrogen &&
        !containsLNG
      ) {
        //If product list contain all EV products
        isSectionValueChanged &&
          onChangeCategory(CategoryConst.DESTINATION_CHARGING_EV);
      } else if (
        savedStateOfFuelProductsData?.data?.length &&
        containsConventional &&
        containsEV &&
        !containsAllConventional &&
        !containsAllEV &&
        !containsHydrogen &&
        !containsLNG
      ) {
        //If product list contain conventional as well as EV product but does not contain Hydrogen/LNG product
        isSectionValueChanged &&
          onChangeCategory(CategoryConst.CONVENTIONAL_FUEL_SITE_WITH_EV);
      } else if (
        savedStateOfFuelProductsData?.data?.length &&
        ((savedStateOfFuelProductsData?.data?.length === 1 &&
          +savedStateOfFuelProductsData?.data?.[0]?.productClassCd ===
            AppConst.HYDROGEN_PRODUCT_CODE) ||
          (containsConventional &&
            containsHydrogen &&
            ((!containsLNG && !containsEV) ||
              (containsEV && !containsLNG) ||
              (containsLNG && !containsEV) ||
              (containsEV && containsLNG))))
      ) {
        //If product list only contain 1 product i.e. Hydrogen
        isSectionValueChanged && onChangeCategory(CategoryConst.HYDROGEN_SITE);
      } else if (
        savedStateOfFuelProductsData?.data?.length &&
        ((savedStateOfFuelProductsData?.data?.length === 1 &&
          +savedStateOfFuelProductsData?.data?.[0]?.productClassCd ===
            AppConst.LNG_PRODUCT_CODE) ||
          (containsConventional &&
            containsLNG &&
            ((!containsHydrogen && !containsEV) ||
              (containsEV && !containsHydrogen))))
      ) {
        //If product list only contain 1 product i.e. LNG
        isSectionValueChanged && onChangeCategory(CategoryConst.LNG_SITE);
      } else if (
        savedStateOfFuelProductsData?.data?.length &&
        ((containsEV &&
          containsHydrogen &&
          !containsConventional &&
          !containsLNG) ||
          (containsEV &&
            containsLNG &&
            !containsConventional &&
            !containsHydrogen) ||
          (containsHydrogen &&
            containsLNG &&
            !containsConventional &&
            !containsEV) ||
          (containsHydrogen &&
            containsLNG &&
            containsEV &&
            !containsConventional))
      ) {
        //If product list contains Hydrogen & LNG but does not contain conventional & EV
        isSectionValueChanged && onChangeCategory(CategoryConst.NEW_FUEL_HUB);
      } else {
        isSectionValueChanged && onChangeCategory(CategoryConst.OTHER);
      }
    }
  }, [isSectionValueChanged, savedStateOfFuelProductsData]);

  const renderAddNewFuelProductUI = () => {
    return (
      <>
        <div className="mdm--table-td">
          {isFuelProductCodesLoading ? (
            <Skeleton shape={"text"} style={{ width: "100%" }} />
          ) : (
            <>
              <DropDownList
                data={fuelProductCodesList}
                value={newFuelProductSelectedValue}
                onChange={onNewFuelProductChange}
                textField="value"
                dataItemKey="id"
                valid={isNewFuelProductValid !== -1 ? true : false}
              />
            </>
          )}
        </div>
      </>
    );
  };

  return (
    <>
      {isSectionValid === -1 && isEditing && (
        <div className="error-notification">
          <FadeNotification
            show={isSectionValid === -1 && isEditing}
            closable={false}
            type="error"
          >
            {sectionInvalidText}
          </FadeNotification>
        </div>
      )}
      <TableDetailsView
        className="fuel-products"
        data={{
          tableHeaders: currentStateOfFuelProductsData.tableHeaders,
          rows: currentStateOfFuelProductsData?.data,
          defaultData: defaultData,
        }}
        isLoading={isFuelProductsDataLoading}
        isEditing={isEditing}
        renderAddNewRowUI={renderAddNewFuelProductUI}
        deleteRow={onDeleteRow}
        onAddRowClick={onAddNewFuelProductClick}
        onEditDiscard={onDiscardRow}
        onSaveRow={onSaveRow}
        onShowNewRowClick={onShowNewRowClick}
        isCreate={isCreate}
        isNewRowValid={isNewFuelProductValid}
        newRowInvalidText={newFuelProductInvalidText}
      />
    </>
  );
};
