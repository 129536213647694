import React from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "@progress/kendo-react-buttons";

import { Locale } from "../../../../../utils/localization";
import { APP_ROUTES } from "../../../../../constants";
import changeHistoryIcon from "./../../../../../assets/images/change-history.svg";

import "./ChangeHistorySmallComponent.scss";

export const ChangeHistorySmallComponent = () => {
  const navigate = useNavigate();

  const onChangeHistoryButtonClick = () => {
    navigate(APP_ROUTES.CHANGE_HISTORY_PATH);
  };

  return (
    <div className="mdm--change-history-small-container">
      <div className="mdm--landing-page-item-body mdm--change-history-small-body mdm--row">
        <div className="mdm--change-history-text-section">
          <div className="mdm--row left-section">
            <img src={changeHistoryIcon} className="image" alt="imagenot" />
            <span className="change-history-text">
              {Locale.common?.drawer_items.change_history_label}
            </span>
          </div>
        </div>
        <Button
          className="buttons-container-button"
          size="small"
          fillMode="flat"
          iconClass="k-i-arrow-chevron-up"
          onClick={onChangeHistoryButtonClick}
        >
          {Locale.landing_page?.change_history.view_all_changes}
        </Button>
      </div>
    </div>
  );
};
