export class CategoryConst {
  public static CONVENTIONAL_FUEL_SITE = "Conventional Fuel Site";
  public static CONVENTIONAL_FUEL_SITE_WITH_EV =
    "Conventional Fuel Site with EV";
  public static DESTINATION_CHARGING_EV = "Destination Charging (EV)";
  public static FLEET_DEPOT = "Fleet Depot";
  public static FLEET_DEPOT_WITH_EV = "Fleet Depot with EV";
  public static HYDROGEN_SITE = "Hydrogen Site";
  public static LNG_SITE = "LNG Site";
  public static MOBILITY_HUB_EV_HUB = "Mobility Hub: EV Hub";
  public static MOBILITY_HUB_EV_PLUS_HUB = "Mobility Hub: EV Plus Hub";
  public static NEW_FUEL_HUB = "New Fuel Hub";
  public static NFR_ONLY_SITE = "NFR only Site";
  public static OTHER = "Other";

  public static CONVENTIONAL_FUEL_SITE_CATEGORY_LIST = [
    CategoryConst.CONVENTIONAL_FUEL_SITE,
  ];

  public static CONVENTIONAL_FUEL_SITE_WITH_EV_CATEGORY_LIST = [
    CategoryConst.CONVENTIONAL_FUEL_SITE_WITH_EV,
  ];

  public static EV_CATEGORY_LIST = [
    CategoryConst.MOBILITY_HUB_EV_HUB,
    CategoryConst.MOBILITY_HUB_EV_PLUS_HUB,
    CategoryConst.DESTINATION_CHARGING_EV,
    CategoryConst.FLEET_DEPOT_WITH_EV,
  ];

  public static NEW_FUEL_CATEGORY_LIST = [
    CategoryConst.NEW_FUEL_HUB,
    CategoryConst.LNG_SITE,
    CategoryConst.HYDROGEN_SITE,
  ];

  public static NFR_CATEGORY_LIST = [CategoryConst.NFR_ONLY_SITE];
}
