import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import _cloneDeep from "lodash/cloneDeep";
import _uniq from "lodash/uniq";
import _map from "lodash/map";
import {
  RadioButton,
  RadioButtonChangeEvent,
} from "@progress/kendo-react-inputs";

import { Locale } from "../../../utils/localization";
import { AppDataGrid, Loader } from "./../../components";

import refreshIcon from "./../../../assets/images/icons/refreshblueicon.svg";
import "./ChangeHistory.scss";
import { PageState, PaginationState } from "../../../models";
import { AppConst, APP_ROUTES, ChangeHistoryConst } from "../../../constants";
import backArrow from "../../../assets/images/back-arrow-icon.svg";
import { Link, useLocation, useNavigate } from "react-router-dom";
import React from "react";
import { format } from "date-fns";
import {
  useChangeHistoryByUserData,
  useChangeHistoryData,
} from "../../../hooks/useChangeHistoryData";
import { adobeAnalyticsConst } from "../../../constants/adobe-analytics.constant";
import { GridSortChangeEvent } from "@progress/kendo-react-grid";

interface TableHeaderType {
  label: string;
  field: string;
  width?: string;
  minWidth?: string;
}

interface TableHeaderType {
  label: string;
  field: string;
  width?: string;
  minWidth?: string;
}

export const ChangeHistory = () => {
  const { pathname } = useLocation();
  const selectedGlobalCountry = useSelector((state: any) => {
    return state?.selectedGlobalCountry.selectedGlobalCountry;
  });

  const defaultPayload: any = {
    SearchText: "",
    Country:
      selectedGlobalCountry === AppConst.ALL_SITES ? "" : selectedGlobalCountry,
    PageNo: 1,
    PageSize: 25,
    SortBy: ChangeHistoryConst.CHANGE_HISTORY_TABLE_HEADER_KEY.UPDATE_ON,
    SortOrder: "desc",
  };
  const initialPageState: PageState = {
    skip: 0,
    take: 25,
  };

  const initialPaginationState: PaginationState = {
    buttonCount: 4,
    info: true,
    type: "numeric",
    previousNext: true,
  };
  const defaultSort = [
    {
      field: ChangeHistoryConst.CHANGE_HISTORY_TABLE_HEADER_KEY.UPDATE_ON,
      dir: "desc",
    },
  ];

  const navigate = useNavigate();

  const [searchText, setSearchText] = useState("");
  const [searchInputValue, setSearchInputValue] = useState("");
  const [initialSort, setInitialSort] = useState(defaultSort);

  const [changeHistoryData, setChangeHistoryData] = useState<any[]>();
  const [changeHistoryDataCount, setChangeHistoryDataCount] = useState(0);
  const [requestPayload, setRequestPayload] = useState<any>(defaultPayload);
  const [isLoading, setIsLoading] = useState(false);
  const [pageState, setPageState] = useState(initialPageState);
  const [pageable, setPageable] = useState<PaginationState | boolean>(
    initialPaginationState
  );
  const isMounted = useRef(false);

  const [selectedValue, setSelectedValue] = useState("Site");

  const adminTableHeaders = [
    {
      label: Locale.landing_page?.change_history.table_headers.global_id_label,
      field: ChangeHistoryConst.CHANGE_HISTORY_TABLE_HEADER_KEY.GLOBAL_SITE_ID,
      // width: "120",
      minWidth: "10",
    },
    {
      label: Locale.landing_page?.change_history.table_headers.site_name_label,
      field: ChangeHistoryConst.CHANGE_HISTORY_TABLE_HEADER_KEY.SITE_NAME,
      // width: "400",
      minWidth: "20",
    },
    {
      label: Locale.landing_page?.change_history.table_headers.country_label,
      field: ChangeHistoryConst.CHANGE_HISTORY_TABLE_HEADER_KEY.COUNTRY,
      minWidth: "10",
    },
    {
      label: Locale.landing_page?.change_history.table_headers.changes_label,
      field: ChangeHistoryConst.CHANGE_HISTORY_TABLE_HEADER_KEY.CHANGES,
      minWidth: "15",
    },
    {
      label: Locale.landing_page?.change_history.table_headers.user_name_label,
      field: ChangeHistoryConst.CHANGE_HISTORY_TABLE_HEADER_KEY.USER_NAME,
      minWidth: "15",
    },

    {
      label: Locale.landing_page?.change_history.table_headers.update_on_label,
      field: ChangeHistoryConst.CHANGE_HISTORY_TABLE_HEADER_KEY.UPDATE_ON,
      minWidth: "15",
    },
    // {
    //   label: Locale.landing_page?.change_history.table_headers.update_by_label,
    //   field: ChangeHistoryConst.CHANGE_HISTORY_TABLE_HEADER_KEY.UPDATED_BY,
    // },
    {
      label: Locale.landing_page?.change_history.table_headers.role_label,
      field: ChangeHistoryConst.CHANGE_HISTORY_TABLE_HEADER_KEY.ROLE,
      minWidth: "15",
    },
  ];

  const territoryManagerTableHeaders = [
    {
      label: Locale.landing_page?.change_history.table_headers.global_id_label,
      field: ChangeHistoryConst.CHANGE_HISTORY_TABLE_HEADER_KEY.GLOBAL_SITE_ID,
      // width: "120",
      minWidth: "10",
    },
    {
      label: Locale.landing_page?.change_history.table_headers.site_name_label,
      field: ChangeHistoryConst.CHANGE_HISTORY_TABLE_HEADER_KEY.SITE_NAME,
      // width: "400",
      minWidth: "20",
    },
    {
      label: Locale.landing_page?.change_history.table_headers.country_label,
      field: ChangeHistoryConst.CHANGE_HISTORY_TABLE_HEADER_KEY.COUNTRY,
      minWidth: "10",
    },
    {
      label: Locale.landing_page?.change_history.table_headers.changes_label,
      field: ChangeHistoryConst.CHANGE_HISTORY_TABLE_HEADER_KEY.CHANGES,
      minWidth: "20",
    },
    {
      label: Locale.landing_page?.change_history.table_headers.user_name_label,
      field: ChangeHistoryConst.CHANGE_HISTORY_TABLE_HEADER_KEY.USER_NAME,
      minWidth: "15",
    },
    {
      label: Locale.landing_page?.change_history.table_headers.update_on_label,
      field: ChangeHistoryConst.CHANGE_HISTORY_TABLE_HEADER_KEY.UPDATE_ON,
      minWidth: "20",
    },
    // {
    //   label: Locale.landing_page?.change_history.table_headers.update_by_label,
    //   field: ChangeHistoryConst.CHANGE_HISTORY_TABLE_HEADER_KEY.UPDATED_BY,
    // },
  ];

  // const [tableHeaders, setTableHeaders] = useState(adminTableHeaders);
  const [tableHeaders, setTableHeaders] =
    useState<TableHeaderType[]>(adminTableHeaders);

  const { data: changeHistoryAPIData, refetch: refetchChangeHistoryData } =
    useChangeHistoryData(requestPayload);

  const {
    data: changeHistoryByUserAPIData,
    refetch: refetchChangeHistoryByUserData,
  } = useChangeHistoryByUserData(requestPayload);
  const userDetails = useSelector((state: any) => state?.auth?.user);

  useEffect(() => {
    window.adobeDataLayer = window.adobeDataLayer || [];
    window.adobeDataLayer.push({
      event: "Page Loaded",
      metric: "attract",
      timestamp: new Date().toISOString(),
      page: {
        pageName: "Site Data Web | " + pathname.slice(1),
        language: adobeAnalyticsConst.language,
        platform: adobeAnalyticsConst.platform,
        countryCode: selectedGlobalCountry,
        cvp: "Site Data Web",
        siteSection: pathname.slice(1),
      },
      user: {
        userId: userDetails.userLoginId,
        loginStatus: "active",
        userType: userDetails.userType,
        customerRole: userDetails?.roleDetail?.userRoleName,
      },
    });
  }, []);

  useEffect(() => {
    setIsLoading(true);
    selectedValue === "Site"
      ? refetchChangeHistoryData()
      : refetchChangeHistoryByUserData();
  }, [requestPayload, selectedValue]);

  useEffect(() => {
    setIsLoading(false);
    if (changeHistoryAPIData) {
      setChangeHistoryDataCount(changeHistoryAPIData?.data?.count);
      setChangeHistoryData(changeHistoryAPIData?.data?.changeHistoryResult);
      setPageable(initialPaginationState);
      if (changeHistoryAPIData?.data?.Count <= 25) {
        setPageable(false);
      }
    }
  }, [changeHistoryAPIData]);

  useEffect(() => {
    if (selectedValue === "User") {
      setIsLoading(false);
      if (changeHistoryByUserAPIData) {
        setChangeHistoryDataCount(changeHistoryByUserAPIData?.data?.count);
        setChangeHistoryData(
          changeHistoryByUserAPIData?.data?.changeHistoryResult
        );
        setPageable(initialPaginationState);
        if (changeHistoryByUserAPIData?.data?.Count <= 25) {
          setPageable(false);
        }
      }
    }
  }, [changeHistoryByUserAPIData]);

  useEffect(() => {
    if (isMounted?.current) {
      const delayDebounceFn = setTimeout(() => {
        setIsLoading(true);
        if (searchText) {
          if (searchText?.length >= 2) {
            setPageState({
              ...initialPageState,
              take: requestPayload?.PageSize,
            });

            if (selectedGlobalCountry === AppConst.ALL_SITES) {
              setRequestPayload({
                ...requestPayload,
                PageNo: 1,
                SearchText: searchText,
                Country: ``,
              });
            } else {
              setRequestPayload({
                ...requestPayload,
                PageNo: 1,
                SearchText: searchText,
                Country: selectedGlobalCountry,
              });
            }
          }
        } else {
          setPageState({ ...initialPageState, take: requestPayload?.PageSize });

          // setRequestPayload({ ...requestPayload, PageNo: 1, SearchText: "" });
          if (selectedGlobalCountry === AppConst.ALL_SITES) {
            setRequestPayload({
              ...requestPayload,
              PageNo: 1,
              SearchText: "",
              Country: ``,
            });
          } else {
            setRequestPayload({
              ...requestPayload,
              PageNo: 1,
              SearchText: "",
              Country: selectedGlobalCountry,
            });
          }
        }
      }, 2000);

      return () => clearTimeout(delayDebounceFn);
    } else {
      isMounted.current = true;
    }
  }, [searchText]);

  const onBackButtonClicked = () => {
    navigate(APP_ROUTES.DASHBOARD_PATH);
  };

  const sendSiteClickedEventAdobeAnalytics = (clickedGlobalSiteId: any) => {
    window.adobeDataLayer = window.adobeDataLayer || [];
    window.adobeDataLayer.push({
      event: "globalIDClicked",
      globalIDSelected: clickedGlobalSiteId, //'1250983'
      globalIDClickArea: pathname.slice(1), //name of area where tile is clicked for example Home Screen, Reports screen,
      metric: "engage",
      page: {
        pageName: "Site Data Web | " + pathname.slice(1),
        language: adobeAnalyticsConst.language,
        platform: adobeAnalyticsConst.platform,
        countryCode: selectedGlobalCountry,
        cvp: "Site Data Web",
        siteSection: pathname.slice(1),
      },
      user: {
        userId: userDetails.userLoginId,
        loginStatus: "active",
        userType: userDetails.userType,
        customerRole: userDetails?.roleDetail?.userRoleName,
      },
    });
  };
  const renderGridCell = (props: any) => {
    if (
      props.field ===
      ChangeHistoryConst.CHANGE_HISTORY_TABLE_HEADER_KEY.GLOBAL_SITE_ID
    ) {
      return (
        <td>
          <Link
            to={`${APP_ROUTES.SITES_PATH}/${props.dataItem[props.field]}`}
            onClick={() =>
              sendSiteClickedEventAdobeAnalytics(props.dataItem[props.field])
            }
          >
            {props.dataItem[props.field]}
          </Link>
        </td>
      );
    } else if (
      props.field ===
      ChangeHistoryConst.CHANGE_HISTORY_TABLE_HEADER_KEY.UPDATE_ON
    ) {
      return (
        <td
          title={format(
            new Date(props.dataItem[props.field]),
            AppConst.VIEW_DETAIL_DATE_FORMAT
          )}
          className={
            format(
              new Date(props.dataItem[props.field]),
              AppConst.VIEW_DETAIL_DATE_FORMAT
            )
              ? ""
              : "mdm-grid-cell-empty"
          }
        >
          {format(
            new Date(props.dataItem[props.field]),
            AppConst.VIEW_DETAIL_DATE_FORMAT
          )}
        </td>
      );
    }
    return (
      <td
        title={props.dataItem[props.field]}
        className={props.dataItem[props.field] ? "" : "mdm-grid-cell-empty"}
      >
        {props.dataItem[props.field] || "--"}
      </td>
    );
  };

  const onRefreshClick = () => {
    setIsLoading(true);
    setPageState(initialPageState);
    setSearchInputValue("");
    setSearchText("");
    setInitialSort(defaultSort);

    setRequestPayload(defaultPayload);
  };

  const onSearchTextChange = async (searchText: any) => {
    setSearchText(searchText);
  };

  useEffect(() => {
    onRefreshClick();
  }, [selectedGlobalCountry]);

  const clearFilter = () => {
    if (selectedGlobalCountry === AppConst.ALL_SITES) {
      setRequestPayload({
        ...requestPayload,
        Country: ``,
      });
    } else {
      setRequestPayload({
        ...requestPayload,
        Country: selectedGlobalCountry,
      });
    }
  };

  const pageChange = (event: PageState) => {
    setPageState(event);
    const page: number = event.skip / event.take + 1; //pageSize;
    if (selectedGlobalCountry === AppConst.ALL_SITES) {
      setRequestPayload({
        ...requestPayload,
        PageNo: page,
        PageSize: event.take,
        Country: ``,
      });
    } else {
      setRequestPayload({
        ...requestPayload,
        PageNo: page,
        PageSize: event.take,
        Country: selectedGlobalCountry,
      });
    }
  };

  const handleRadioButtonChange = (e: RadioButtonChangeEvent) => {
    setSelectedValue(e.value);
    setIsLoading(true);
    setPageState(initialPageState);
    setSearchInputValue("");
    setSearchText("");
    setRequestPayload(defaultPayload);
    setTableHeaders(
      e.value === "Site" ? adminTableHeaders : territoryManagerTableHeaders
    );
  };

  const onSearchInputChange = (event: any) => {
    setSearchInputValue(event.target.value);
    onSearchTextChange(event.target.value);
  };

  const onPasteInputChange = (
    event: React.ClipboardEvent<HTMLInputElement>
  ) => {
    // setSearchInputValue(event.clipboardData.getData("Text"));
    onSearchTextChange(event.clipboardData.getData("Text"));
  };
  const pageSort = (event: GridSortChangeEvent) => {
    if (event.sort && event.sort?.length && event.sort?.length > 0) {
      setRequestPayload({
        ...requestPayload,
        SortBy: ChangeHistoryConst.COLUMN_CONFIG[event.sort?.[0].field],
        SortOrder: event.sort?.[0].dir,
      });
    } else {
      setRequestPayload({
        ...requestPayload,
        SortBy: "",
        SortOrder: "",
      });
    }
  };
  return (
    <div className="mdm--change-history-container">
      <div className="mdn-change-history-first-row">
        <div
          onClick={onBackButtonClicked}
          className="mdm-change-history-back-arrow"
        >
          {" "}
          <img className="mdm-back-arrow-icon" src={backArrow} />
        </div>
        <div className="mdm-change-history-back-arrow">
          <h5 className="mdm--change-history-container-title mdm--landing-page-item-title">
            {Locale.landing_page?.change_history.change_history_details_title}
          </h5>
        </div>
      </div>

      <div
        className="mdm--change-history-container-table-container"
        style={{
          height: "84.5vh",
        }}
      >
        <div className="mdm--change-history-container-table-container-first-row">
          <div className="mdm--change-history-search-and-radio">
            <div className="mdm--change-history-container-column">
              <input
                type="search"
                value={searchInputValue}
                onChange={onSearchInputChange}
                onPaste={onPasteInputChange}
                placeholder={
                  Locale.common?.table_toolbar.search_input_placeholder
                }
                className="mdm--change-search-bar"
              />
            </div>
            <div
              style={{ fontSize: "1.5vw" }}
              className="mdm-row mdm--change-history-container-column mdm-change-history-radio"
            >
              <RadioButton
                className=""
                name="group1"
                value="Site"
                checked={selectedValue === "Site"}
                label="Site"
                onChange={handleRadioButtonChange}
                size={"medium"}
              />
              &ensp; &ensp; &ensp;
              <RadioButton
                name="group1"
                value="User"
                checked={selectedValue === "User"}
                label="User (me)"
                onChange={handleRadioButtonChange}
                size={"medium"}
              />
            </div>
          </div>
          <div className="mdm-main-container-refresh-button mdm-refresh-label">
            <div style={{ cursor: "pointer" }} onClick={onRefreshClick}>
              <img
                className="mdm-change-history-refresh-image"
                style={{ color: "#003C88" }}
                src={refreshIcon}
                alt="image"
              />
              <span className="mdm-refresh-label-2">
                {" "}
                {
                  Locale.landing_page?.change_history
                    .change_history_refresh_button_label
                }{" "}
              </span>
            </div>
          </div>
        </div>

        <div className="mdm--data-grid">
          <AppDataGrid
            isToolBarVisible={false}
            data={changeHistoryData || []}
            tableHeaders={tableHeaders}
            renderGridCell={renderGridCell}
            searchPlaceHolderText={
              Locale.landing_page?.sites_list.table_placeholder_text
            }
            onSearch={onSearchTextChange}
            onClear={clearFilter}
            totalCount={changeHistoryDataCount}
            pageChange={pageChange}
            pageState={pageState}
            pageSort={pageSort}
            initialSort={initialSort}
            onRefreshClick={onRefreshClick}
            pageable={pageable}
            isLoading={isLoading}
            maxHeight={"92%"}
            sortable={true}
            showFilterBadge={false}
            filterBadgeText={""}
          />
          {isLoading && <Loader />}
        </div>
      </div>
    </div>
  );
};
