import Cookies from "js-cookie";
import qs from "qs";
import axios from "axios";
import { apiConfig, appConfig } from "../../config";
import { tokenService } from "..";
import { ITokenData } from "../token-manager/token-manager.interface";
import store from "../../redux/store";

const { pingTokenUrl, redirectUrl } = apiConfig;
const {
  pingAuthentication: { clientId, refreshGrantType, authorizationGrantType },
} = appConfig;
const cipmAuthentication = appConfig.cipmAuthentication;
// console.log("cipmAuthenticationcipmAuthentication", cipmAuthentication);

const options = {
  headers: {
    "Content-Type": "application/x-www-form-urlencoded",
  },
};

export async function authenticateShellUser(
  code: string,
  codeVerifier: string
): Promise<void> {
  const payload = {
    client_id: clientId,
    code: code || "",
    grant_type: authorizationGrantType,
    redirect_uri: redirectUrl,
    code_verifier: codeVerifier || "",
  };
  const response = await axios.post(
    pingTokenUrl,
    qs.stringify(payload),
    options
  );
  const tokenData = response && response["data"];
  // const decodedToken: any =
  //   tokenData?.access_token && jwtDecode(tokenData?.access_token);
  // console.log("Decoded token::", decodedToken);
  //work around
  // await logOutUser(decodedToken, "NONSHELL");
  const userData: any = await getUser(tokenData, 1);
  // console.log("User Data after Auth API call::", userData);
  if (!userData.Error)
    tokenService.setAuthData(tokenData, tokenData.expires_in, "shell");
  return { ...userData, userType: "shell" };
}

function renewSessionData() {
  const state = store.getState();
  const inFifteenMinutes = new Date(
    new Date().getTime() +
      Math.ceil((Number(state?.auth?.auth?.expiryTime) * 1000) / 60000) *
        60 *
        1000
  );
  Cookies.set("reduxstoredata", JSON.stringify(state.auth));
  Cookies.set("expiry", inFifteenMinutes?.toString());
}

export async function renewShellAccessToken(
  refereshToken: string
): Promise<void> {
  const payload = {
    client_id: clientId,
    refresh_token: refereshToken,
    grant_type: refreshGrantType,
  };
  await axios
    .post(pingTokenUrl, qs.stringify(payload), options)
    ?.then((response) => {
      const tokenData = response && response["data"];
      tokenService.setAuthData(tokenData, tokenData.expires_in, "shell");
      renewSessionData();
    });
  // console.log("shell Refresh Token response", response);
}

export async function renewNonShellAccessToken(
  refereshToken: string
): Promise<void> {
  const URL = `${cipmAuthentication.loginHost}/token?client_id=${cipmAuthentication.clientId}&grant_type=${refreshGrantType}&refresh_token=${refereshToken}&redirect_uri=${redirectUrl}`;
  // console.log("nonshellrefresh token:::URL", URL);
  await axios.post(URL)?.then((response) => {
    const tokenData = response && response["data"];
    tokenService.setAuthData(tokenData, tokenData.expires_in, "nonshell");
    renewSessionData();
  });
  // console.log("nonshellrefresh token response:::", response, response["data"]);
}

export async function getUser(userData: any, type: number): Promise<any> {
  try {
    const response: any = await axios.get(
      `${appConfig.baseUrl}/userdetails/Authenticate`,
      {
        headers: {
          apikey: appConfig?.apgApiKey || "",
          Authorization: `${userData.access_token}`,
          logintype: type,
        },
      }
    );

    // console.log("UserData::", response);
    if (response.Error) {
      return response;
    }
    return response && response.data;
  } catch (err: any) {
    console.log("Error", err);
    if (err instanceof Error) {
      console.log("Instance of error", err instanceof Error);

      // this.publishFailedApiExceptions(err);
    }
    return { Error: err };
  }
}

export async function logOutUser(userData: any, type?: string): Promise<any> {
  try {
    const response: any = await axios.get(
      `${appConfig.baseUrl}/userdetails/Logout`,
      {
        headers: {
          apikey: appConfig?.apgApiKey || "",
          Authorization: `Bearer ${
            type === "NONSHELL" ? userData.id_token : userData.access_token
          }`,
        },
      }
    );

    // console.log("UserData::", response);
    if (response.Error) {
      return response;
    }
    return response && response.data;
  } catch (err: any) {
    console.log("Error", err);
    if (err instanceof Error) {
      console.log("Instance of error", err instanceof Error);

      // this.publishFailedApiExceptions(err);
    }
    return { Error: err };
  }
}

export async function authenticateNonShellUser(
  code: string,
  codeVerifier: string
): Promise<void> {
  const URL = `${cipmAuthentication.loginHost}/token?client_id=${cipmAuthentication.clientId}&grant_type=authorization_code&code=${code}&redirect_uri=${redirectUrl}&code_verifier=${codeVerifier}`;
  const response = await axios.post(URL);
  const tokenData = response && response["data"];

  const tokenDetails: ITokenData = {
    access_token: tokenData?.access_token,
    token_type: tokenData?.token_type,
    access_token_cipm: tokenData?.id_token,
    refresh_token: tokenData?.refresh_token,
    expires_in: tokenData?.expires_in,
  };
  //work around
  // await logOutUser(decodedToken, "NONSHELL");
  const userData: any = await getUser(tokenDetails, 2); // We send the UUID we got from CIPM to suhas in a similar fashion and get the user details.
  if (!userData.Error)
    tokenService.setAuthData(tokenDetails, tokenData?.expires_in, "nonshell");
  return { ...userData, userType: "nonshell" };
}
