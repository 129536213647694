import { useEffect, useState } from "react";
import { Skeleton } from "@progress/kendo-react-indicators";
import {
  DropDownList,
  DropDownListChangeEvent,
} from "@progress/kendo-react-dropdowns";
import { Input, InputChangeEvent } from "@progress/kendo-react-inputs";

import {
  useFacilitySubtypeCodesData,
  useMasterDataSet1,
} from "../../../../../../../../hooks";
import { TableDetailsViewData } from "../../../../../../../../models";
import { Locale } from "../../../../../../../../utils/localization";
import { FadeNotification } from "../../../../../../../components";
import { EditFieldTypeConst } from "../../../../../../../../constants";
import { TableDetailsView } from "../../../../../../../components/table-details-view/TableDetailsView";

export const FacilityTab = (props: any) => {
  const {
    isEditing,
    onPatchRequestValueSet,
    onSectionValueSet,

    isSectionValueChanged,
    onSectionValueChanged,
    sectionEditedData,
    basicDetailsTabSelected,
    isCreate,
    isSectionValid,
    sectionInvalidText,
    classifiedSiteType,
    fetchedSiteFacilityData,
    isSiteFacilityDataLoading,
    onFacilityDataUpdate,
  } = props;

  const [currentStateOfFacilityData, setCurrentStateOfFacilityData] =
    useState<TableDetailsViewData>({ tableHeaders: [] });
  const [savedStateOfFacilityData, setSavedStateOfFacilityData] =
    useState<any>();

  const [newFacilitySelectedValue, setNewFacilitySelectedValue] =
    useState<any>();

  const [newFacilitySubTypeSelectedValue, setNewFacilitySubTypeSelectedValue] =
    useState<any>();

  const [facilityTypeCodesList, setFacilityTypeCodesList] = useState<
    { id: string; value: string }[]
  >([]);

  const [newFacilitySbtypeValue, setNewFacilitySbtypeValue] = useState("");

  const [isNewFacilityRowValid, setIsNewFacilityRowValid] = useState(0);
  const [isNewFacilityValid, setIsNewFacilityValid] = useState(0);
  const [isNewFacilitySbtypeValid, setIsNewFacilitySbtypeValid] = useState(0);

  const {
    isLoading: isMasterDataSet1Loading,
    data: masterDataSet1,
    refetch: refetchMasterDataSet1,
  } = useMasterDataSet1();

  const {
    isLoading: isFacilitySubTypeCodesLoading,
    data: facilitySubTypeCodes,
    refetch: refetchFacilitySubTypeCodes,
  } = useFacilitySubtypeCodesData();

  const onNewFacilityChange = (event: DropDownListChangeEvent) => {
    setNewFacilitySelectedValue(event.target.value);
    setNewFacilitySubTypeSelectedValue(null);
    setIsNewFacilityValid(1);
    setIsNewFacilitySbtypeValid(1);
    setIsNewFacilityRowValid(1);
  };

  const onNewFacilitySubTypeChange = (event: DropDownListChangeEvent) => {
    setNewFacilitySubTypeSelectedValue(event.target.value);
    setIsNewFacilitySbtypeValid(1);
    setIsNewFacilityValid(1);
    setIsNewFacilityRowValid(1);
  };

  const onNewFacilitySbtypeValueChange = (event: InputChangeEvent) => {
    setNewFacilitySbtypeValue(event.value);
  };

  const [newFacilityRowInvalidText, setNewFacilityRowInvalidText] = useState(
    Locale?.create_site?.error_messages?.facility_required_fields
  );

  const onAddNewFacilityClick = () => {
    let isValid = false;
    const savedFacility = savedStateOfFacilityData?.data?.map(
      (facility: any) => {
        return {
          facilityTypeCd: facility.facilityTypeCd,
          facilitySubtypeCd: facility.facilitySubtypeCd,
          facilityType: facility.facilityType,
          facilitySubType: facility.facilitySubType,
          facilitySbtypeValue: facility.facilitySbtypeValue,
        };
      }
    );
    if (newFacilitySelectedValue) {
      setIsNewFacilityValid(1);
    } else {
      setIsNewFacilityValid(-1);
      setNewFacilityRowInvalidText(
        Locale?.create_site?.error_messages?.facility_required_fields
      );
      setIsNewFacilityRowValid(-1);
    }
    if (newFacilitySubTypeSelectedValue) {
      setIsNewFacilitySbtypeValid(1);
    } else {
      setIsNewFacilitySbtypeValid(-1);
      setIsNewFacilityRowValid(-1);
      setNewFacilityRowInvalidText(
        Locale?.create_site?.error_messages?.facility_required_fields
      );
    }
    if (
      (newFacilitySelectedValue && newFacilitySubTypeSelectedValue) ||
      newFacilitySelectedValue
    ) {
      const findIndex = savedFacility?.find((facility: any) => {
        return (
          facility.facilityTypeCd === newFacilitySelectedValue?.id &&
          facility?.facilitySubtypeCd ===
            (newFacilitySubTypeSelectedValue?.id || "")
        );
      });

      let isFacilitySubtypeExists = false;
      if (newFacilitySelectedValue) {
        facilitySubTypeCodes?.forEach((facilitySubtype: any) => {
          if (
            newFacilitySelectedValue?.id === facilitySubtype?.facilityTypeCd
          ) {
            isFacilitySubtypeExists = true;
          }
        });
      }
      if (findIndex) {
        setIsNewFacilityValid(-1);
        setIsNewFacilitySbtypeValid(-1);
        setIsNewFacilityRowValid(-1);
        setNewFacilityRowInvalidText(
          Locale?.create_site?.error_messages?.facility_already_exists
        );
      } else if (
        (newFacilitySelectedValue && newFacilitySubTypeSelectedValue) ||
        (newFacilitySelectedValue && !isFacilitySubtypeExists)
      ) {
        setIsNewFacilityValid(1);
        isValid = true;
      } else {
        isValid = false;
        setIsNewFacilitySbtypeValid(-1);
        setIsNewFacilityRowValid(-1);
        setNewFacilityRowInvalidText(
          Locale?.create_site?.error_messages?.facility_required_fields
        );
      }
    }
    if (isValid) {
      setIsNewFacilityRowValid(1);
      const newData: any = {
        id: new Date().getMilliseconds() + 1000,
        facilityType: newFacilitySelectedValue?.value || "",
        facilityTypeCd: newFacilitySelectedValue?.id || "",
        facilitySubType: newFacilitySubTypeSelectedValue?.value || "",
        facilitySubtypeCd: newFacilitySubTypeSelectedValue?.id || "",
        facilitySbtypeValue: newFacilitySbtypeValue || "",
        editActionRow: "",
      };
      const currentData = [...(currentStateOfFacilityData?.data || [])];
      currentData.unshift(newData);

      var currentState: any = {
        ...currentStateOfFacilityData,
        data: [...currentData],
      };
      setCurrentStateOfFacilityData(currentState);
      setNewFacilitySelectedValue(null);
      setNewFacilitySubTypeSelectedValue(null);
      setNewFacilitySbtypeValue("");

      onSectionValueChanged(true);
      setSavedStateOfFacilityData({
        ...savedStateOfFacilityData,
        data: currentState.data,
      });
      onSectionValueSet({
        SiteFacilitySubtype: {
          ...savedStateOfFacilityData,
          data: currentState.data,
        },
      });
      onFacilityDataUpdate();
      const payload = currentState?.data?.map((facility: any) => {
        return {
          ...(facility.facilityTypeCd && {
            facilityTypeCd: {
              facilityTypeCd: facility.facilityTypeCd,
            },
          }),
          ...(facility?.facilitySubtypeCd && {
            facilitySubtypeCd: {
              facilitySubtypeCd: facility?.facilitySubtypeCd,
            },
          }),
          ...(facility.facilitySbtypeValue && {
            facilitySbtypeValue: facility?.facilitySbtypeValue?.toString(),
          }),
        };
      });
      onPatchRequestValueSet(
        {
          SiteFacilitySubtype: {
            item: payload,
          },
        },
        true
      );
      return true;
    }
    return isValid;
  };

  const getFacilitySubTypeCodes = (selectedFacility: {
    id: string;
    value: string;
  }) => {
    const newFacilitySubTypes = facilitySubTypeCodes?.filter(
      (facilitySubtype: any) => {
        return facilitySubtype.facilityTypeCd === selectedFacility?.id;
      }
    );
    return newFacilitySubTypes;
  };

  const renderAddNewFacilityUI = () => {
    return (
      <>
        <div className="mdm--table-td">
          {isMasterDataSet1Loading ? (
            <Skeleton shape={"text"} style={{ width: "100%" }} />
          ) : (
            <>
              <DropDownList
                data={facilityTypeCodesList}
                value={newFacilitySelectedValue}
                onChange={onNewFacilityChange}
                textField="value"
                dataItemKey="id"
                valid={isNewFacilityValid !== -1 ? true : false}
              />
            </>
          )}
        </div>
        <div className="mdm--table-td">
          {isFacilitySubTypeCodesLoading ? (
            <Skeleton shape={"text"} style={{ width: "100%" }} />
          ) : (
            <>
              <DropDownList
                data={getFacilitySubTypeCodes(newFacilitySelectedValue) || []}
                value={newFacilitySubTypeSelectedValue}
                onChange={onNewFacilitySubTypeChange}
                textField="value"
                dataItemKey="id"
                valid={isNewFacilitySbtypeValid !== -1 ? true : false}
              />
            </>
          )}
        </div>
        <div className="mdm--table-td">
          <Input
            type="text"
            maxLength={50}
            value={newFacilitySbtypeValue}
            onChange={onNewFacilitySbtypeValueChange}
          />
        </div>
      </>
    );
  };

  const onEditRow = (currentState: any, event: any, row: any, field: any) => {
    const newCurrentState = [...(currentState?.rows || [])].map((row: any) => {
      const { isValid, validationMessage, ...allData } = row;
      return allData;
    });

    const currentData = [...(newCurrentState || [])];
    const filterData = currentData?.map((item: any) => {
      if (row.id === item.id) {
        if (field === "facilityType") {
          return {
            ...item,
            facilitySubType: "",
            facilitySubtypeCd: "",
            [field]: event?.value,
            facilityTypeCd: event?.id,
          };
        } else if (field === "facilitySubType") {
          return {
            ...item,
            [field]: event?.value,
            facilitySubtypeCd: event?.id,
          };
        }
        return { ...item, [field]: event?.value };
      }
      return { ...item };
    });

    let newTableHeaders = [...currentState?.tableHeaders];
    if (field === "facilityType") {
      const newFacilitySubTypeCodes = facilitySubTypeCodes?.filter(
        (facilitySubtype: any) => {
          return event.id === facilitySubtype?.facilityTypeCd;
        }
      );
      newTableHeaders = currentState?.tableHeaders?.map((header: any) => {
        if (header.field === "facilitySubType") {
          header.editData = [...newFacilitySubTypeCodes];
        }
        return header;
      });
    }
    setCurrentStateOfFacilityData({
      ...currentState,
      tableHeaders: newTableHeaders,
      data: filterData,
    });
  };

  const onDiscardRow = (rowId: string) => {
    const discardRow = [...savedStateOfFacilityData?.data]?.filter(
      (row: any) => row.id === rowId
    )?.[0];

    const currentState = currentStateOfFacilityData?.data?.map((row: any) => {
      if (row.id === rowId) {
        return discardRow;
      }
      return { ...row };
    });

    const filteredArray = currentState?.filter((row: any) => {
      return row;
    });

    setCurrentStateOfFacilityData({
      ...currentStateOfFacilityData,
      data: filteredArray,
    });
  };

  const onDeleteRow = (rowId: string) => {
    const currentState = currentStateOfFacilityData?.data?.filter(
      (row: any) => {
        return row.id !== rowId;
      }
    );
    setCurrentStateOfFacilityData({
      ...currentStateOfFacilityData,
      data: currentState,
    });

    const savedState = savedStateOfFacilityData?.data?.filter((row: any) => {
      return row.id !== rowId;
    });

    onSectionValueChanged(true);
    setSavedStateOfFacilityData({
      ...savedStateOfFacilityData,
      data: savedState,
    });
    onSectionValueSet({
      SiteFacilitySubtype: { ...savedStateOfFacilityData, data: savedState },
    });
    onFacilityDataUpdate();
    const payload = savedState?.map((facility: any) => {
      return {
        ...(facility.facilityTypeCd && {
          facilityTypeCd: {
            facilityTypeCd: facility.facilityTypeCd,
          },
        }),
        ...(facility?.facilitySubtypeCd && {
          facilitySubtypeCd: {
            facilitySubtypeCd: facility?.facilitySubtypeCd,
          },
        }),
        ...(facility.facilitySbtypeValue && {
          facilitySbtypeValue: facility?.facilitySbtypeValue?.toString(),
        }),
      };
    });
    onPatchRequestValueSet(
      {
        SiteFacilitySubtype: {
          item: payload,
        },
      },
      true
    );
  };

  const onSaveRow = (rowId: string) => {
    let isValid: boolean = true;

    const currentRow: any = currentStateOfFacilityData?.data?.filter(
      (row: any) => {
        return row.id === rowId;
      }
    )?.[0];

    let isFacilitySubtypeExists = false;
    if (currentRow?.facilityTypeCd) {
      facilitySubTypeCodes?.forEach((facilitySubtype: any) => {
        if (currentRow?.facilityTypeCd === facilitySubtype?.facilityTypeCd) {
          isFacilitySubtypeExists = true;
        }
      });
    }

    if (
      (currentRow?.facilityTypeCd && currentRow?.facilitySubtypeCd) ||
      (currentRow?.facilityTypeCd && !isFacilitySubtypeExists)
    ) {
      const existedRecord = savedStateOfFacilityData?.data?.find((row: any) => {
        return (
          row.facilityTypeCd === currentRow?.facilityTypeCd &&
          row?.facilitySubtypeCd === (currentRow?.facilitySubtypeCd || "") &&
          row?.id !== currentRow?.id
        );
      });
      if (existedRecord?.facilityTypeCd) {
        isValid = false;

        const newCurrentState = currentStateOfFacilityData?.data?.map(
          (row: any) => {
            if (row.id === rowId) {
              return {
                ...row,
                isValid: false,
                validationMessage:
                  Locale?.create_site?.error_messages?.facility_already_exists,
              };
            }
            return row;
          }
        );
        setCurrentStateOfFacilityData({
          ...currentStateOfFacilityData,
          data: newCurrentState,
        });
      }
    } else {
      isValid = false;
      const newCurrentState = currentStateOfFacilityData?.data?.map(
        (row: any) => {
          if (row.id === rowId) {
            return {
              ...row,
              isValid: false,
              validationMessage:
                Locale?.create_site?.error_messages?.facility_required_fields,
            };
          }
          return row;
        }
      );
      setCurrentStateOfFacilityData({
        ...currentStateOfFacilityData,
        data: newCurrentState,
      });
    }

    if (isValid) {
      const savedState = savedStateOfFacilityData?.data?.map((row: any) => {
        if (row.id === rowId) {
          return currentRow;
        }
        return { ...row };
      });

      onSectionValueChanged(true);
      onSectionValueSet({
        SiteFacilitySubtype: {
          ...currentStateOfFacilityData,
          data: savedState,
        },
      });
      onFacilityDataUpdate();
      setSavedStateOfFacilityData({
        ...currentStateOfFacilityData,
        data: savedState,
      });
      const payload = savedState.map((facility: any) => {
        return {
          ...(facility.facilityTypeCd && {
            facilityTypeCd: {
              facilityTypeCd: facility.facilityTypeCd,
            },
          }),
          ...(facility?.facilitySubtypeCd && {
            facilitySubtypeCd: {
              facilitySubtypeCd: facility?.facilitySubtypeCd,
            },
          }),
          ...(facility.facilitySbtypeValue && {
            facilitySbtypeValue: facility?.facilitySbtypeValue?.toString(),
          }),
        };
      });
      onPatchRequestValueSet(
        {
          SiteFacilitySubtype: {
            item: payload,
          },
        },
        true
      );
    }
    return isValid;
  };

  const onShowNewRowClick = () => {
    setNewFacilitySelectedValue(null);
    setNewFacilitySubTypeSelectedValue(null);
    setNewFacilitySbtypeValue("");
    setIsNewFacilityValid(1);
    setIsNewFacilityRowValid(1);
    setIsNewFacilitySbtypeValid(1);
    setCurrentStateOfFacilityData({
      ...currentStateOfFacilityData,
      data: savedStateOfFacilityData.data,
    });
  };

  const onEditClicked = (row: any) => {
    const newFacilitySubTypeCodes = facilitySubTypeCodes?.filter(
      (facilitySubtype: any) => {
        return row.facilityTypeCd === facilitySubtype?.facilityTypeCd;
      }
    );

    const newTableHeaders = currentStateOfFacilityData?.tableHeaders?.map(
      (header: any) => {
        if (header.field === "facilitySubType") {
          header.editData = [...newFacilitySubTypeCodes];
        }
        return header;
      }
    );
    setCurrentStateOfFacilityData({
      ...currentStateOfFacilityData,
      tableHeaders: newTableHeaders,
    });
    // setNewFacilitySubTypeCodes(newFacilitySubTypeCodes);
  };

  useEffect(() => {
    if (isEditing) {
      refetchMasterDataSet1();
      refetchFacilitySubTypeCodes();
    }
  }, [isEditing]);

  useEffect(() => {
    if (masterDataSet1?.facilityTypeCodes && classifiedSiteType) {
      const facilityTypes = masterDataSet1?.facilityTypeCodes?.map(
        (type: any) => {
          return {
            id: type.facilityTypeCd,
            value: type.facilityTypeName,
          };
        }
      );

      setFacilityTypeCodesList([...facilityTypes]);

      //Commented as Apoorva asked to show all the facilities to open for ALl types
      // if (
      //   classifiedSiteType === AppConst.GSAP_SITE_TYPE ||
      //   classifiedSiteType === AppConst.NON_GSAP_SITE_TYPE
      // ) {
      //   setFacilityTypeCodesList([...facilityTypeCodes]);
      // } else if (classifiedSiteType === AppConst.RBLA_SITE_TYPE) {
      //   const facilityTypeList = facilityTypeCodes?.filter(
      //     (facility: { id: string }) =>
      //       facility?.id === "1003" ||
      //       facility?.id === "962" ||
      //       facility?.id === "961" ||
      //       facility?.id === "17" ||
      //       facility?.id === "14"
      //   );
      //   setFacilityTypeCodesList([...facilityTypeList]);
      // } else if (classifiedSiteType === AppConst.PARTNER_SITE_TYPE) {
      //   const facilityTypeList = facilityTypeCodes?.filter(
      //     (facility: { id: string }) =>
      //       facility?.id === "1003" ||
      //       facility?.id === "962" ||
      //       facility?.id === "961" ||
      //       facility?.id === "17" ||
      //       facility?.id === "911"
      //   );
      //   setFacilityTypeCodesList([...facilityTypeList]);
      // }
    }
  }, [classifiedSiteType, masterDataSet1]);

  useEffect(() => {
    let list: string[] = [];
    const tbHeaders = [
      {
        label: Locale.site_details?.retail_admin_update.facility.facility_type,
        field: "facilityType",
        editType: EditFieldTypeConst.DROPDOWN_FIELD,
        editData: facilityTypeCodesList,
        onChange: onEditRow,
      },
      {
        label:
          Locale.site_details?.retail_admin_update.facility.facility_subtype,
        field: "facilitySubType",
        editType: EditFieldTypeConst.DROPDOWN_FIELD,
        editData: facilitySubTypeCodes || [],
        onChange: onEditRow,
      },
      {
        label:
          Locale.site_details?.retail_admin_update.facility
            .facility_subType_value,
        field: "facilitySbtypeValue",
        editType: EditFieldTypeConst.TEXT_FIELD,
        onChange: onEditRow,
        maxLength: 50,
      },
      {
        label: "",
        field: "editActionRow",
      },
    ];
    if (isSectionValueChanged && sectionEditedData?.SiteFacilitySubtype) {
      list =
        sectionEditedData?.SiteFacilitySubtype?.data?.map((item: any) => {
          return {
            id: item.id,
            facilityType: item.facilityType || "",
            facilitySubType: item.facilitySubType || "",
            facilitySbtypeValue: item?.facilitySbtypeValue || "",
            facilityTypeCd: item.facilityTypeCd || "",
            facilitySubtypeCd: item.facilitySubtypeCd || "",
            editActionRow: "",
          };
        }) || [];
    } else {
      list =
        fetchedSiteFacilityData?.data?.result?.SiteFacilitySubtype?.item?.map(
          (
            item: {
              facilityTypeCd: {
                facilityTypeName: string;
                facilityTypeCd: string;
              };
              facilitySubtypeCd: {
                facilitySubtypeName: string;
                facilitySubtypeCd: string;
              };
              facilitySbtypeValue: string;
            },
            index: number
          ) => {
            return {
              id: index,
              facilityType: item?.facilityTypeCd?.facilityTypeName || "",
              facilitySubType:
                item?.facilitySubtypeCd?.facilitySubtypeName || "",
              facilitySbtypeValue: item?.facilitySbtypeValue || "",
              facilityTypeCd: item.facilityTypeCd?.facilityTypeCd || "",
              facilitySubtypeCd:
                item.facilitySubtypeCd?.facilitySubtypeCd || "",
              // ...item,
              editActionRow: "",
            };
          }
        ) || [];
    }
    const facilityData: TableDetailsViewData = {
      tableHeaders: tbHeaders,
      data: list,
    };
    setCurrentStateOfFacilityData(facilityData);
    setSavedStateOfFacilityData(facilityData);
  }, [
    fetchedSiteFacilityData,
    facilityTypeCodesList,
    facilitySubTypeCodes,
    isEditing,
    basicDetailsTabSelected,
  ]);

  const [defaultData, setDefaultData] = useState<any>([]);

  useEffect(() => {
    if (fetchedSiteFacilityData) {
      const defaultFacility =
        fetchedSiteFacilityData?.data?.result?.SiteFacilitySubtype?.item?.map(
          (item: any, index: number) => {
            return {
              index: index,
              facilityType: item?.facilityTypeCd?.facilityTypeName || "",
              facilitySubType:
                item?.facilitySubtypeCd?.facilitySubtypeName || "",
              facilitySbtypeValue: item?.facilitySbtypeValue || "",
            };
          }
        );
      setDefaultData(defaultFacility);
    }
  }, [fetchedSiteFacilityData]);

  return (
    <>
      {isSectionValid === -1 && isEditing && (
        <div className="error-notification">
          <FadeNotification
            show={isSectionValid === -1 && isEditing}
            closable={false}
            type="error"
          >
            {sectionInvalidText}
          </FadeNotification>
        </div>
      )}
      <TableDetailsView
        className="facility"
        data={{
          tableHeaders: currentStateOfFacilityData.tableHeaders,
          rows: currentStateOfFacilityData?.data,
          defaultData: defaultData,
        }}
        isLoading={isSiteFacilityDataLoading}
        isEditing={isEditing}
        renderAddNewRowUI={renderAddNewFacilityUI}
        deleteRow={onDeleteRow}
        onAddRowClick={onAddNewFacilityClick}
        onEditDiscard={onDiscardRow}
        onSaveRow={onSaveRow}
        onShowNewRowClick={onShowNewRowClick}
        onEditClicked={onEditClicked}
        isCreate={isCreate}
        isNewRowValid={isNewFacilityRowValid}
        newRowInvalidText={newFacilityRowInvalidText}
      />
    </>
  );
};
