import { combineReducers } from "redux";
import authReducer from "./reducer";
import globalCountryReducer from "./site-listing/reducer";
import changeRequestReducer from "./changeRequestReducer";
import apiErrorStateReducer from "./apiErrorState.reducer";
import refetchSiteDataReducer from "./refetchSiteData.reducer";
import notificationStateReducer from "./notificationState.reducer";
import siteUpdateConnectionReducer from "./siteUpdateConnection.reducer";
import selectedGlobalCountryReducer from "./selectedGlobalCountry.reducer";
import sitesListPredefinedFiltersReducer from "./sitesListPredefinedFilters.reducer";

const rootReducer = combineReducers({
  auth: authReducer,
  globalCountry: globalCountryReducer,
  apiErrorState: apiErrorStateReducer,
  refechSiteData: refetchSiteDataReducer,
  changeRequestDetails: changeRequestReducer,
  notificationState: notificationStateReducer,
  siteUpdateConnection: siteUpdateConnectionReducer,
  selectedGlobalCountry: selectedGlobalCountryReducer,
  sitesListPredefinedFiltersReducer: sitesListPredefinedFiltersReducer,
});

export default rootReducer;
