import Cookies from "js-cookie";
import qs from "qs";
import sha256 from "crypto-js/sha256";
import base64 from "crypto-js/enc-base64";

import { appConfig, apiConfig } from "../../config";
import IAuthService from "./auth.interface";
import { http } from "..";

export default class AuthService implements IAuthService {
  CLIENT_ID: string = appConfig.pingAuthentication.clientId || "";
  PING_AUTH_URL: string = apiConfig.pingAuthUrl;
  REDIRECT_URL: string = apiConfig.redirectUrl;
  RESPONSE_TYPE: string = appConfig.pingAuthentication.responseType;
  GRANT_TYPE: string = appConfig.pingAuthentication.authorizationGrantType;
  CODE_VERIFIER: string = "";
  CODE_CHALLENGE: string = "";
  CODE_CHALLENGE_METHOD: string =
    appConfig.pingAuthentication.codeChallengeMethod;
  PING_TOKEN_URL: string = apiConfig.pingTokenUrl;
  CIPM: any = appConfig.cipmAuthentication;
  generateVerifier(length: number): string {
    let text: string = "";
    let possible: string =
      "ABCDEFGHIJKLMNOPQRSTssfrUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-._~";
    for (var i = 0; i < length; i++) {
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    return text;
  }

  generateCodeChallenge(): string {
    this.CODE_VERIFIER = this.generateVerifier(43);
    return this.base64URL(sha256(this.CODE_VERIFIER));
  }

  base64URL(verifier: CryptoJS.lib.WordArray) {
    return verifier
      .toString(base64)
      .replace(/=/g, "")
      .replace(/\+/g, "-")
      .replace(/\//g, "_");
  }

  nonShellUserLogin(): void {
    this.CODE_CHALLENGE = this.generateCodeChallenge();

    const loginPayload = {
      loginType: "nonshell",
      codeVerifier: this.CODE_VERIFIER,
    };

    // localStorage.setItem("loginPayload", JSON.stringify(loginPayload));
    Cookies.set("loginPayload", JSON.stringify(loginPayload));

    const authUrl = `${this.CIPM.loginHost}/authorize?redirect_uri=${this.REDIRECT_URL}&client_id=${this.CIPM.clientId}&response_type=code&state=f86C4Bgtc4&scope=openid&ui_locales=en-US&code_challenge=${this.CODE_CHALLENGE}&code_challenge_method=S256`;

    window.location.replace(authUrl);
  }

  shellUserLogin(): void {
    this.CODE_CHALLENGE = this.generateCodeChallenge();
    const loginPayload = {
      loginType: "shell",
      codeVerifier: this.CODE_VERIFIER,
    };
    // localStorage.setItem("loginPayload", JSON.stringify(loginPayload));
    Cookies.set("loginPayload", JSON.stringify(loginPayload));
    const authUrl = `${this.PING_AUTH_URL}?client_id=${this.CLIENT_ID}&redirect_uri=${this.REDIRECT_URL}&response_type=${this.RESPONSE_TYPE}&code_challenge=${this.CODE_CHALLENGE}&code_challenge_method=${this.CODE_CHALLENGE_METHOD}&grant_type=${this.GRANT_TYPE}`;
    // console.log("Auth URL formed::", authUrl);

    console.log("Redirect Url:::::: ", authUrl);
    window.location.replace(authUrl);
  }

  async tryFetchShellToken(
    code: string | undefined,
    codeVerifier: string | undefined
  ): Promise<void> {
    const payload = {
      client_id: this.CLIENT_ID,
      code: code || "",
      code_verifier: codeVerifier || "",
      redirect_uri: this.REDIRECT_URL,
      grant_type: this.GRANT_TYPE,
    };
    // console.log("Payload::", payload);

    const options = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };
    // console.log("QS payload", qs.stringify(payload));

    const response = await http.post(
      this.PING_TOKEN_URL,
      qs.stringify(payload),
      options
    );
    // console.log(response);
    return response && response["data"];
  }

  async tryFetchNonShellToken(
    code: string | undefined,
    codeVerifier: string | undefined
  ): Promise<void> {}
}
