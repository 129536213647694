import {
  ApplicationInsights,
  IExceptionTelemetry,
  IEventTelemetry,
} from "@microsoft/applicationinsights-web";
import {
  ReactPlugin,
  withAITracking,
} from "@microsoft/applicationinsights-react-js";
import { createBrowserHistory } from "history";
import { appConfig } from "../../config";

import { ComponentClass, ComponentType } from "react";
import IAppInisghtsService from "./app-insights.interface";

export default class AppInsightsService implements IAppInisghtsService {
  logLevel = appConfig.telemeteryLogLevel?.toLocaleLowerCase();
  isDev = appConfig.isDev;
  history = createBrowserHistory();
  reactPlugin = new ReactPlugin();
  appInsights = new ApplicationInsights({
    config: {
      instrumentationKey: appConfig.instrumentationKey,
      extensions: [this.reactPlugin],
      extensionConfig: {
        [this.reactPlugin.identifier]: { history: this.history },
      },
    },
  });

  publishCustomError(error: IExceptionTelemetry): void {
    if (this.isDev) {
      console.error(error.exception);
      return;
    }
    (this.logLevel === "verbose" || this.logLevel === "error") &&
      this.appInsights.trackException(error);
  }

  publishCustomEvent(event: IEventTelemetry): void {
    if (this.isDev) {
      console.info(event);
      return;
    }
    if (this.logLevel === "verbose" || this.logLevel === "info") {
      this.appInsights.trackEvent(event);
    }
  }

  getAppInisghtsHOC(Component: ComponentType): ComponentClass {
    return withAITracking(this.reactPlugin, Component);
  }

  getAppInisghts(): ApplicationInsights {
    return this.appInsights;
  }

  initializeAppInisghts(): void {
    this.appInsights.loadAppInsights();
  }
}
