import store from "./../../redux/store";

import { IHttpService } from "./http.interface";
import { API } from "../api";
// import axios from "axios";
import { appInsightsService } from "../index";
import { EVENT_NAMES } from "../../constants/index";
import { updateAPIErrorState } from "../../redux/actions/apiErrorState.actions";

export default class HttpService implements IHttpService {
  publishFailedApiExceptions(err: Error) {
    const { name, message, stack } = err;
    appInsightsService.publishCustomError({
      exception: err,
      properties: {
        type: EVENT_NAMES.FAILED_API_CALL,
        message: message,
        name: name,
        stackTrace: stack,
      },
    });
  }
  private logApiTimings(duration: number, url: string) {
    // console.log("API time", duration, "URL", url);

    appInsightsService.publishCustomEvent({
      name: "apiDuration",
      properties: { duration, url },
    });
  }

  async get(url: string, payload?: any) {
    try {
      const start = new Date().getTime();
      const response = await API.get(url, payload)?.catch((error: any) => {
        store.dispatch(updateAPIErrorState({ error }));
      });
      const end = new Date().getTime();
      const duration = end - start;
      this.logApiTimings(duration, url);
      return response;
    } catch (err: any) {
      console.log("Error from get http service:", err);
      if (err instanceof Error) {
        console.log("Instance of error", err instanceof Error);
        this.publishFailedApiExceptions(err);
      }
      return { Error: err };
    }
  }

  async post(url: string, payload?: any, headers?: any) {
    try {
      const start = new Date().getTime();
      const response = await API.post(url, payload, headers).catch(
        (error: any) => {
          store.dispatch(updateAPIErrorState({ error }));
        }
      );
      const end = new Date().getTime();
      const duration = end - start;
      this.logApiTimings(duration, url);
      return response;
    } catch (err: any) {
      console.log("Error from post http service::", err);
      if (err instanceof Error) {
        console.log("Instance of error", err instanceof Error);

        this.publishFailedApiExceptions(err);
      }
      return { Error: err };
    }
  }

  async put(url: string, payload?: any, headers?: any) {
    try {
      const start = new Date().getTime();
      const response = await API.put(url, payload, headers).catch(
        (error: any) => {
          store.dispatch(updateAPIErrorState({ error }));
        }
      );
      const end = new Date().getTime();
      const duration = end - start;
      this.logApiTimings(duration, url);
      return response;
    } catch (err: any) {
      console.log("Error from post http service::", err);
      if (err instanceof Error) {
        console.log("Instance of error", err instanceof Error);

        this.publishFailedApiExceptions(err);
      }
      return { Error: err };
    }
  }

  async patch(url: string, payload?: any, headers?: any) {
    try {
      const start = new Date().getTime();
      const response = await API.patch(url, payload, headers).catch(
        (error: any) => {
          store.dispatch(updateAPIErrorState({ error }));
        }
      );
      const end = new Date().getTime();
      const duration = end - start;
      this.logApiTimings(duration, url);
      return response;
    } catch (err: any) {
      console.log("Error from patch http service::", err);
      if (err instanceof Error) {
        console.log("Instance of error", err instanceof Error);

        this.publishFailedApiExceptions(err);
      }
      return { Error: err };
    }
  }
}
