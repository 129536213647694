import { useEffect } from "react";
import { useQuery } from "react-query";
import { format } from "date-fns";

import {
  AppConst,
  EditFieldTypeConst,
} from "../../../../../../../../constants";
import { Locale } from "../../../../../../../../utils/localization";
import { DetailsViewComponent } from "../../../../../../../components";
import { SitesService } from "../../../../../../../../services/sites.service";

interface IProps {
  siteId: string;
  basicDetailsTabSelected: number | undefined;
  isEditing: boolean;
  isCreate: boolean | undefined;
}

const fetchSiteRetailerdData = ({ queryKey }: any) => {
  const siteId = queryKey[1];
  return SitesService.getSiteDetails({
    globalSiteId: siteId || "",
    viewName: "SiteRetailerResult",
  });
};

export const RetailerTabGSAP = (props: IProps) => {
  const { siteId, isCreate, isEditing, basicDetailsTabSelected } = props;

  const {
    isLoading: isSiteRetailerDataLoading,
    data: siteRetailerDetails,
    refetch: refetchSiteRetailerData,
  } = useQuery(
    [`site-retailer-data-${siteId}`, siteId],
    fetchSiteRetailerdData,
    {
      staleTime: AppConst.VIEW_SITE_DETAILS_STALE_TIME,
      enabled: false,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      select: (data) => {
        //show only active retailer & primary retailer on top
        const sortedArr = data?.data?.result?.SiteRetailer?.item
          ?.filter((d: any) => {
            const tDate = new Date();
            const start = new Date(d.customerStartDate);
            const end = new Date(d.customerEndDate);
            return tDate > start && tDate < end;
          })
          ?.reduce((acc: any, element: any) => {
            if (element?.Retailer?.primaryOperatorInd === "Y") {
              return [element, ...acc];
            }
            return [...acc, element];
          }, []);
        return sortedArr;
      },
    }
  );

  useEffect(() => {
    if (basicDetailsTabSelected === 3 && siteId) {
      refetchSiteRetailerData();
    }
  }, [basicDetailsTabSelected]);

  const getRetailerItem = (data: any) => [
    {
      label: Locale.site_details?.retail_admin_update.retailer.customer_no,
      value: data?.Retailer?.customerNo || "",
      editType: EditFieldTypeConst.TEXT_FIELD,
      editValue: data?.Retailer?.customerNo || "",
      isDisabled: true,
      isVisible: true,
    },
    {
      label: Locale.site_details?.retail_admin_update.retailer.customer_name,
      value: data?.Retailer?.customerName || "",
      editType: EditFieldTypeConst.TEXT_FIELD,
      editValue: data?.Retailer?.customerName || "",
      isDisabled: true,
      isVisible: true,
    },
    {
      label:
        Locale.site_details?.retail_admin_update?.retailer?.partner_role_desc,
      value: data?.Retailer?.partnerRoleDesc,
      editType: EditFieldTypeConst.TEXT_FIELD,
      editValue: data?.Retailer?.partnerRoleDesc,
      isDisabled: true,
      isVisible: true,
    },
    {
      label: Locale.site_details?.retail_admin_update.retailer.language,
      value: data?.Retailer?.languageCd,
      editType: EditFieldTypeConst.TEXT_FIELD,
      editValue: data?.Retailer?.languageCd,
      isVisible: true,
      isDisabled: true,
    },
    {
      label: Locale.site_details?.retail_admin_update.retailer.start_date,
      value: data?.customerStartDate
        ? format(new Date(data?.customerStartDate), AppConst.VIEW_DATE_FORMAT)
        : "",
      editType: EditFieldTypeConst.TEXT_FIELD,
      editValue: data?.customerStartDate
        ? format(new Date(data?.customerStartDate), AppConst.VIEW_DATE_FORMAT)
        : "",
      isDisabled: true,
      isVisible: true,
    },
    {
      label: Locale.site_details?.retail_admin_update.retailer.end_date,
      value: data?.customerEndDate
        ? format(new Date(data?.customerEndDate), AppConst.VIEW_DATE_FORMAT)
        : "",
      editType: EditFieldTypeConst.TEXT_FIELD,
      editValue: data?.customerEndDate
        ? format(new Date(data?.customerEndDate), AppConst.VIEW_DATE_FORMAT)
        : "",
      isDisabled: true,
      isVisible: true,
    },
    {
      label: Locale.landing_page?.sites_list.table_headers.country_label,
      value: data?.Retailer?.countryCd?.countryName || "",
      editType: EditFieldTypeConst.TEXT_FIELD,
      editValue: data?.Retailer?.countryCd?.countryName || "",
      isDisabled: true,
      isVisible: true,
    },

    {
      label: Locale.site_details?.retail_admin_update.retailer.territory,
      value: data?.Retailer?.territoryCd?.territoryName || "",
      editType: EditFieldTypeConst.TEXT_FIELD,
      editValue: data?.Retailer?.territoryCd?.territoryName || "",
      isDisabled: true,
      isVisible: true,
    },
    {
      label: Locale.landing_page?.sites_list.table_headers.city_label,
      value: data?.Retailer?.cityName || "",
      editType: EditFieldTypeConst.TEXT_FIELD,
      editValue: data?.Retailer?.cityName || "",
      isDisabled: true,
      isVisible: true,
    },
    {
      label:
        Locale.site_details?.retail_admin_update.basic_details.street_address,
      value: data?.Retailer?.streetAddress || "",
      editType: EditFieldTypeConst.TEXT_FIELD,
      editValue: data?.Retailer?.streetAddress || "",
      isDisabled: true,
      isVisible: true,
    },
    {
      label: Locale.site_details?.retail_admin_update.basic_details.postal_code,
      value: data?.Retailer?.postalCd?.trim() || "",
      editType: EditFieldTypeConst.TEXT_FIELD,
      editValue: data?.Retailer?.postalCd?.trim() || "",
      isDisabled: true,
      isVisible: true,
    },
    { label: "", value: "", isVisible: true },
  ];

  return (
    <>
      {siteRetailerDetails?.map((item: any, index: number) => (
        <>
          {item?.Retailer?.primaryOperatorInd === "Y" && (
            <div className="tag tag--green">
              <span>{Locale.common?.primary}</span>
            </div>
          )}
          <DetailsViewComponent
            data={getRetailerItem(item)}
            classValue="mdm--row mdm--sd-data retailer-data gsap"
            isLoading={isSiteRetailerDataLoading}
            isEditing={isEditing}
            isCreate={isCreate}
            key={index}
          />
        </>
      ))}
    </>
  );
};
