import { http } from ".";
import { appConfig } from "../config";

export const ChangeHistoryService = {
  getChangeHistoryList: (payload: any) => {
    return http.get(
      `${appConfig.baseUrl}/changehistory/GetSiteMDMChangeHistory`,
      {
        params: payload,
      }
    );
  },
  getChangeHistoryByUser: (payload: any) => {
    return http.get(
      `${appConfig.baseUrl}/changehistory/GetSiteMDMChangeHistoryByUser`,
      {
        params: payload,
      }
    );
  },
};
