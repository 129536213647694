import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";

import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { Button } from "@progress/kendo-react-buttons";

import profileBackground from "../../../assets/images/profile-background.png";
import closeIcon from "../../../assets/images/profile-close-icon.svg";
import ProfileLogo from "./../../../assets/images/avatar-default.svg";

import { SiteConst } from "../../../constants";

import "@progress/kendo-theme-default/dist/all.css";
import "./Profile.scss";

const Profile = (props: any) => {
  const profileDetailsRef = useRef<any>(null);

  const [userCountries, setUserCountries] = useState("");

  /**
   * This Hook can be used for detecting clicks outside the Opened Menu
   */
  function clickOutside(ref: any, onClickOutside: any) {
    useEffect(() => {
      /**
       * Invoke Function onClick outside of element
       */
      function handleClickOutside(event: any) {
        if (ref.current && !ref.current.contains(event.target)) {
          onClickOutside();
        }
      }
      // Bind
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // dispose
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref, onClickOutside]);
  }

  clickOutside(profileDetailsRef, () => {
    onClose();
  });

  const userData = useSelector((state: any) => state.auth.user);

  useEffect(() => {
    if (userData.userRoleMapping?.length) {
      userData.userRoleMapping[0].countryCode;
      const countries: string[] = userData.userRoleMapping?.map(
        (country: { countryCode: string }) => country?.countryCode
      );
      setUserCountries(countries?.join(", "));
    }
  }, [userData]);

  const onClose = () => {
    props.onClose();
  };

  const handleProfileLogout = () => {
    props.handleProfileLogout();
  };

  return (
    <div className="mdm-main-container">
      {props.visibleDialog && (
        <div>
          <Dialog className="mdm-dialog" autoFocus={true}>
            {/* <img src={cross} alt=""/> */}
            <div ref={profileDetailsRef}>
              <div className="mdm-profile-image-background">
                <img
                  className="mdm-close-icon"
                  src={closeIcon}
                  alt="Close Icon"
                  onClick={onClose}
                />
                <img
                  className="mdm-profile-background"
                  src={profileBackground}
                  alt=""
                />
              </div>

              <div className="mdm-article-container ">
                <div className="mdm-article">
                  {/* <Avatar
                type="image"
                size="large"
                border={divue}
                fillMode="outline"
              >
                {" "} */}
                  {/* <div className="mdm-image"> */}

                  <img src={ProfileLogo} alt="" className="mdm-avatar" />
                  {/* </div> */}

                  {/* </Avatar> */}

                  <h5>
                    {userData.firstName} {userData.lastName}
                  </h5>
                </div>
                <div className="mdm-user-detail-article">
                  <h5 className="mdm-user-detail-heading">User Details</h5>

                  <table
                    className="mdm-user-data"
                    style={{
                      width: "100%",
                      borderSpacing: "20px",
                    }}
                    cellPadding={"50%"}
                    cellSpacing={"50%"}
                  >
                    <tbody>
                      <tr>
                        <td className="mdm-profile-table-title">
                          {SiteConst.PROFILE_USER_DETAILS.UUID}
                        </td>
                        <td>{userData.userLoginId}</td>
                      </tr>
                      <tr>
                        <td className="mdm-profile-table-title">
                          {SiteConst.PROFILE_USER_DETAILS.EMAIL}
                        </td>
                        <td>{userData.emailAddress}</td>
                      </tr>
                      <tr>
                        <td className="mdm-profile-table-title">
                          {SiteConst.PROFILE_USER_DETAILS.ROLE}
                        </td>
                        <td>{userData.roleDetail.userRoleName}</td>
                      </tr>
                      <tr>
                        <td className="mdm-profile-table-title">
                          {SiteConst.PROFILE_USER_DETAILS.FIRST_NAME}
                        </td>
                        <td>{userData.firstName}</td>
                      </tr>
                      <tr>
                        <td className="mdm-profile-table-title">
                          {SiteConst.PROFILE_USER_DETAILS.LAST_NAME}
                        </td>
                        <td>{userData.lastName}</td>
                      </tr>
                      {/* <tr>
                    <td className="mdm-profile-table-title">
                      {SiteConst.PROFILE_USER_DETAILS.USER_TYPE}
                    </td>
                    <td>{userData.orgAffiliationCode}</td>
                  </tr> */}
                      <tr>
                        <td className="mdm-profile-table-title">
                          {SiteConst.PROFILE_USER_DETAILS.ACTIVE}
                        </td>
                        <td>{userData.userStatus}</td>
                      </tr>
                      <tr>
                        <td className="mdm-profile-table-title">
                          {SiteConst.PROFILE_USER_DETAILS.COUNTRY}
                        </td>
                        <td>{userCountries}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              <DialogActionsBar layout="end">
                <Button
                  style={{ padding: "6px 25px" }}
                  className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base mdm-btn-logout-color"
                  onClick={handleProfileLogout}
                  //   onClick={toggleDialog}
                >
                  Logout
                </Button>
              </DialogActionsBar>
            </div>
          </Dialog>
        </div>
      )}
    </div>
  );
};

export default Profile;
