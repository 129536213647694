import React, { useEffect, useState } from "react";
import {
  DropDownList,
  DropDownListChangeEvent,
} from "@progress/kendo-react-dropdowns";
import { Skeleton } from "@progress/kendo-react-indicators";
import { Input, InputChangeEvent } from "@progress/kendo-react-inputs";
import {
  AppConst,
  EditFieldTypeConst,
} from "../../../../../../../../constants";
import { useEmployeeTypeCodesData } from "../../../../../../../../hooks";
import { TableDetailsViewData } from "../../../../../../../../models";
import { Locale } from "../../../../../../../../utils/localization";
import { TableDetailsView } from "../../../../../../../components/table-details-view/TableDetailsView";

export const SiteContactTab = (props: any) => {
  const {
    isEditing,
    isCreate,
    evDetailsTabSelected,
    onPatchRequestValueSet,
    isSectionValueChanged,
    onSectionValueChanged,
    sectionEditedData,
    onSectionValueSet,
    fetchedSiteContactData,
    isSiteContactDataLoading,
  } = props;

  const [currentStateOfContactData, setCurrentStateOfContactData] =
    useState<TableDetailsViewData>({ tableHeaders: [] });
  const [savedStateOfContactData, setSavedStateOfContactData] = useState<any>();

  const [newEmployeeTypeSelectedValue, setNewEmployeeTypeSelectedValue] =
    useState<any>();

  const [newEmployeeFirstName, setNewEmployeeFirstName] = useState<
    string | undefined
  >();

  const [newEmployeeMiddleName, setNewEmployeeMiddleName] = useState<
    string | undefined
  >();

  const [newEmployeeLastName, setNewEmployeeLastName] = useState<
    string | undefined
  >();

  const [isNewEmployeeRowValid, setIsNewEmployeeRowValid] = useState(0);
  const [newEmployeeRowInvalidText, setNewEmployeeRowInvalidText] =
    useState("");
  const [isNewEmployeeTypeValid, setIsNewEmployeeTypeValid] = useState(0);
  const [isNewEmployeeFirstNameValid, setIsNewEmployeeFirstNameValid] =
    useState(0);
  const [isNewEmployeeLastNameValid, setIsNewEmployeeLastNameValid] =
    useState(0);

  const [isNewEmployeeMiddleNameValid, setIsNewEmployeeMiddleNameValid] =
    useState(0);
  const [defaultData, setDefaultData] = useState<any>([]);

  const {
    isLoading: isEmployeeTypeCodesLoading,
    data: employeeTypeCodes,
    refetch: refetchEmployeeTypeCodes,
  } = useEmployeeTypeCodesData();

  useEffect(() => {
    if (isEditing && evDetailsTabSelected === 6) {
      refetchEmployeeTypeCodes();
    }
  }, [evDetailsTabSelected, isEditing]);

  useEffect(() => {
    if (fetchedSiteContactData) {
      const defaultFacility =
        fetchedSiteContactData?.data?.result?.SiteEmployee?.item?.map(
          (item: any, index: number) => {
            return {
              index: index,
              employeeType:
                item?.siteEmployeeTypeCd?.siteEmployeeTypeName || "-",
              employeeTypeCd:
                item?.siteEmployeeTypeCd?.siteEmployeeTypeCd || "-",
              employeeFirstName: item.firstName,
              employeeLastName: item.lastName,
              employeeMiddleName: item.middleInitial,
            };
          }
        );

      setDefaultData(defaultFacility);
    }
  }, [fetchedSiteContactData]);

  const onNewEmployeeTypeChange = (event: DropDownListChangeEvent) => {
    setNewEmployeeTypeSelectedValue(event.target.value);
    setIsNewEmployeeTypeValid(1);
    setIsNewEmployeeFirstNameValid(1);
    setIsNewEmployeeLastNameValid(1);
    setIsNewEmployeeRowValid(1);
  };

  const onNewEmployeeFirstNameChange = (event: InputChangeEvent) => {
    setNewEmployeeFirstName(event.value?.toUpperCase());
    setIsNewEmployeeTypeValid(1);
    setIsNewEmployeeFirstNameValid(1);
    setIsNewEmployeeLastNameValid(1);
    setIsNewEmployeeRowValid(1);
  };

  const onNewEmployeeMiddleNameChange = (event: InputChangeEvent) => {
    setNewEmployeeMiddleName(event.value?.toUpperCase());
    setIsNewEmployeeMiddleNameValid(1);
  };

  const onNewEmployeeLastNameChange = (event: InputChangeEvent) => {
    setNewEmployeeLastName(event.value?.toUpperCase());
    setIsNewEmployeeTypeValid(1);
    setIsNewEmployeeFirstNameValid(1);
    setIsNewEmployeeLastNameValid(1);
    setIsNewEmployeeRowValid(1);
  };

  const onAddNewEmployeeClick = () => {
    let isValid = true;

    if (newEmployeeTypeSelectedValue) {
      setIsNewEmployeeTypeValid(1);
    } else {
      setIsNewEmployeeRowValid(-1);
      setIsNewEmployeeTypeValid(-1);
      setNewEmployeeRowInvalidText(
        Locale.create_site?.error_messages.site_contact_required_fields
      );
    }
    if (newEmployeeFirstName) {
      if (AppConst.STRING_ONLY_FIELD_REGULAR_EXP?.test(newEmployeeFirstName)) {
        setIsNewEmployeeFirstNameValid(1);
      } else {
        isValid = false;
        setIsNewEmployeeFirstNameValid(-1);
        setIsNewEmployeeRowValid(-1);
        setNewEmployeeRowInvalidText(
          Locale.create_site?.error_messages.invalid_first_name
        );
      }
    } else {
      isValid = false;
      setIsNewEmployeeRowValid(-1);
      setIsNewEmployeeFirstNameValid(-1);
      setIsNewEmployeeLastNameValid(-1);
      setNewEmployeeRowInvalidText(
        Locale.create_site?.error_messages.site_contact_required_fields
      );
    }
    if (newEmployeeLastName) {
      if (AppConst.STRING_ONLY_FIELD_REGULAR_EXP?.test(newEmployeeLastName)) {
        setIsNewEmployeeLastNameValid(1);
      } else {
        isValid = false;
        setIsNewEmployeeLastNameValid(-1);
        setIsNewEmployeeRowValid(-1);
        setNewEmployeeRowInvalidText(
          Locale.create_site?.error_messages.invalid_last_name
        );
      }
    } else {
      isValid = false;
      setIsNewEmployeeRowValid(-1);
      setIsNewEmployeeLastNameValid(-1);
      setNewEmployeeRowInvalidText(
        Locale.create_site?.error_messages.site_contact_required_fields
      );
    }

    if (
      newEmployeeMiddleName &&
      !AppConst.STRING_ONLY_FIELD_REGULAR_EXP?.test(newEmployeeMiddleName)
    ) {
      isValid = false;
      setIsNewEmployeeRowValid(-1);
      setIsNewEmployeeMiddleNameValid(-1);
      setNewEmployeeRowInvalidText(
        Locale.create_site?.error_messages.invalid_middle_name
      );
    } else {
      setIsNewEmployeeMiddleNameValid(1);
    }

    if (
      newEmployeeTypeSelectedValue &&
      newEmployeeFirstName &&
      newEmployeeLastName
    ) {
      const findIndex = savedStateOfContactData?.data?.find((market: any) => {
        return (
          market.employeeTypeCd === newEmployeeTypeSelectedValue?.id &&
          market?.employeeFirstName === newEmployeeFirstName &&
          market?.employeeLastName === newEmployeeLastName &&
          market?.employeeMiddleName === (newEmployeeMiddleName || "-")
        );
      });
      if (findIndex) {
        isValid = false;
        setIsNewEmployeeTypeValid(-1);
        setIsNewEmployeeRowValid(-1);
        setIsNewEmployeeLastNameValid(-1);
        setIsNewEmployeeFirstNameValid(-1);
        setNewEmployeeRowInvalidText(
          Locale.create_site?.error_messages.site_contact_already_exists
        );
      }

      if (isValid) {
        setIsNewEmployeeRowValid(1);
        const newData: any = {
          id: new Date().getMilliseconds() + 1000,
          employeeType: newEmployeeTypeSelectedValue?.value || "-",
          employeeTypeCd: newEmployeeTypeSelectedValue?.id || "-",
          employeeFirstName: newEmployeeFirstName || "-",
          employeeMiddleName: newEmployeeMiddleName || "-",
          employeeLastName: newEmployeeLastName || "-",
          editActionRow: "",
        };
        const currentData = [...(currentStateOfContactData?.data || [])];
        currentData.unshift(newData);

        var currentState: any = {
          ...currentStateOfContactData,
          data: [...currentData],
        };
        setCurrentStateOfContactData(currentState);
        setNewEmployeeTypeSelectedValue(null);
        setNewEmployeeFirstName(undefined);
        setNewEmployeeMiddleName(undefined);
        setNewEmployeeLastName(undefined);

        onSectionValueChanged(true);
        setSavedStateOfContactData({
          ...savedStateOfContactData,
          data: currentState.data,
        });
        onSectionValueSet({
          SiteEmployee: {
            ...savedStateOfContactData,
            data: currentState.data,
          },
        });
        const payload = currentState?.data?.map((employee: any) => {
          return {
            firstName: employee.employeeFirstName,
            lastName: employee.employeeLastName,
            middleInitial: employee.employeeMiddleName,
            siteEmployeeTypeCd: {
              siteEmployeeTypeCd: employee.employeeTypeCd,
            },
          };
        });
        onPatchRequestValueSet(
          {
            SiteEmployee: {
              item: payload,
            },
          },
          true
        );
      }
    }

    return isValid;
  };

  const onEditRow = (currentState: any, event: any, row: any, field: any) => {
    const newCurrentState = [...(currentState?.rows || [])].map((row: any) => {
      const { isValid, validationMessage, ...allData } = row;
      return allData;
    });

    const currentData = [...(newCurrentState || [])];
    const filterData = currentData?.map((item: any) => {
      if (row.id === item.id) {
        if (field === "employeeType") {
          return { ...item, [field]: event?.value, employeeTypeCd: event?.id };
        }
        return { ...item, [field]: event?.value?.toUpperCase() };
      }
      return { ...item };
    });
    setCurrentStateOfContactData({ ...currentState, data: filterData });
  };

  const onDiscardRow = (rowId: string) => {
    const discardRow = [...savedStateOfContactData?.data]?.filter(
      (row: any) => row.id === rowId
    )?.[0];

    const currentState = currentStateOfContactData?.data?.map((row: any) => {
      if (row.id === rowId) {
        return discardRow;
      }
      return { ...row };
    });

    const filteredArray = currentState?.filter((row: any) => {
      return row;
    });

    setCurrentStateOfContactData({
      ...currentStateOfContactData,
      data: filteredArray,
    });
  };

  const onDeleteRow = (rowId: string) => {
    const currentState = currentStateOfContactData?.data?.filter((row: any) => {
      return row.id !== rowId;
    });
    setCurrentStateOfContactData({
      ...currentStateOfContactData,
      data: currentState,
    });

    const savedState = savedStateOfContactData?.data?.filter((row: any) => {
      return row.id !== rowId;
    });

    onSectionValueChanged(true);
    setSavedStateOfContactData({
      ...savedStateOfContactData,
      data: savedState,
    });
    onSectionValueSet({
      SiteEmployee: { ...savedStateOfContactData, data: savedState },
    });
    const payload = savedState?.map((employee: any) => {
      return {
        firstName: employee.employeeFirstName,
        lastName: employee.employeeLastName,
        middleInitial: employee.employeeMiddleName,
        siteEmployeeTypeCd: {
          siteEmployeeTypeCd: employee.employeeTypeCd,
        },
      };
    });
    onPatchRequestValueSet(
      {
        SiteEmployee: {
          item: payload,
        },
      },
      true
    );
  };

  const onSaveRow = (rowId: string) => {
    let isValid: boolean = true;
    const filteredData: any = currentStateOfContactData?.data?.filter(
      (row: any) => {
        return row.id === rowId;
      }
    )?.[0];

    let newCurrentState: any = [...(currentStateOfContactData?.data || [])];

    if (!filteredData?.employeeFirstName || !filteredData?.employeeLastName) {
      isValid = false;
      newCurrentState = currentStateOfContactData?.data?.map((row: any) => {
        if (row.id === rowId) {
          return {
            ...row,
            isValid: false,
            validationMessage:
              Locale.create_site?.error_messages.site_contact_required_fields,
          };
        }
        return row;
      });
    } else if (
      filteredData?.employeeFirstName &&
      !AppConst.STRING_ONLY_FIELD_REGULAR_EXP?.test(
        filteredData?.employeeFirstName
      )
    ) {
      isValid = false;
      newCurrentState = currentStateOfContactData?.data?.map((row: any) => {
        if (row.id === rowId) {
          return {
            ...row,
            isValid: false,
            validationMessage:
              Locale.create_site?.error_messages.invalid_first_name,
          };
        }
        return row;
      });
    } else if (
      filteredData?.employeeLastName &&
      !AppConst.STRING_ONLY_FIELD_REGULAR_EXP?.test(
        filteredData?.employeeLastName
      )
    ) {
      isValid = false;
      newCurrentState = currentStateOfContactData?.data?.map((row: any) => {
        if (row.id === rowId) {
          return {
            ...row,
            isValid: false,
            validationMessage:
              Locale.create_site?.error_messages.invalid_last_name,
          };
        }
        return row;
      });
    } else if (
      filteredData?.employeeMiddleName &&
      !AppConst.STRING_ONLY_FIELD_REGULAR_EXP?.test(
        filteredData?.employeeMiddleName
      )
    ) {
      isValid = false;
      newCurrentState = currentStateOfContactData?.data?.map((row: any) => {
        if (row.id === rowId) {
          return {
            ...row,
            isValid: false,
            validationMessage:
              Locale.create_site?.error_messages.invalid_middle_name,
          };
        }
        return row;
      });
    } else {
      const existedRecord: any = currentStateOfContactData?.data?.find(
        (row: any) => {
          return (
            row.employeeTypeCd === filteredData?.employeeTypeCd &&
            row.employeeFirstName === filteredData?.employeeFirstName &&
            row.employeeLastName === filteredData?.employeeLastName &&
            row.employeeMiddleName ===
              (filteredData?.employeeMiddleName || "-") &&
            row?.id !== filteredData?.id
          );
        }
      );

      if (existedRecord?.employeeTypeCd) {
        isValid = false;
        newCurrentState = currentStateOfContactData?.data?.map((row: any) => {
          if (row.id === rowId) {
            return {
              ...row,
              isValid: false,
              validationMessage:
                Locale.create_site?.error_messages.site_contact_already_exists,
            };
          }
          return row;
        });
      }
    }

    setCurrentStateOfContactData({
      ...currentStateOfContactData,
      data: newCurrentState,
    });

    if (isValid) {
      const savedState = savedStateOfContactData?.data?.map((row: any) => {
        if (row.id === rowId) {
          return filteredData;
        }
        return { ...row };
      });

      onSectionValueChanged(true);
      onSectionValueSet({
        SiteEmployee: {
          ...currentStateOfContactData,
          data: savedState,
        },
      });
      setSavedStateOfContactData({
        ...currentStateOfContactData,
        data: savedState,
      });
      const payload = savedState?.map((employee: any) => {
        return {
          firstName: employee.employeeFirstName,
          lastName: employee.employeeLastName,
          middleInitial: employee.employeeMiddleName,
          siteEmployeeTypeCd: {
            siteEmployeeTypeCd: employee.employeeTypeCd,
          },
        };
      });
      onPatchRequestValueSet(
        {
          SiteEmployee: {
            item: payload,
          },
        },
        true
      );
    }
    return isValid;
  };

  const onShowNewRowClick = () => {
    setNewEmployeeTypeSelectedValue(undefined);
    setNewEmployeeFirstName("");
    setNewEmployeeMiddleName("");
    setNewEmployeeLastName("");
    setIsNewEmployeeTypeValid(1);
    setIsNewEmployeeFirstNameValid(1);
    setIsNewEmployeeLastNameValid(1);
    setIsNewEmployeeRowValid(1);
    setCurrentStateOfContactData({
      ...currentStateOfContactData,
      data: savedStateOfContactData.data,
    });
  };

  const renderAddNewEmployeeUI = () => {
    return (
      <>
        <div className="mdm--table-td">
          {isEmployeeTypeCodesLoading ? (
            <Skeleton shape={"text"} style={{ width: "100%" }} />
          ) : (
            <DropDownList
              data={employeeTypeCodes}
              value={newEmployeeTypeSelectedValue}
              onChange={onNewEmployeeTypeChange}
              textField="value"
              dataItemKey="id"
              valid={isNewEmployeeTypeValid !== -1 ? true : false}
            />
          )}
        </div>
        <div className="mdm--table-td">
          <Input
            value={newEmployeeFirstName}
            onChange={onNewEmployeeFirstNameChange}
            valid={isNewEmployeeFirstNameValid !== -1 ? true : false}
          />
        </div>
        <div className="mdm--table-td">
          <Input
            value={newEmployeeMiddleName}
            onChange={onNewEmployeeMiddleNameChange}
            valid={isNewEmployeeMiddleNameValid !== -1 ? true : false}
            maxLength={1}
          />
        </div>
        <div className="mdm--table-td">
          <Input
            value={newEmployeeLastName}
            onChange={onNewEmployeeLastNameChange}
            valid={isNewEmployeeLastNameValid !== -1 ? true : false}
          />
        </div>
      </>
    );
  };

  useEffect(() => {
    let list: string[] = [];
    const tbHeaders = [
      {
        label: Locale.site_details?.ev_details.site_contact.site_employee_type,
        field: "employeeType",
        editType: EditFieldTypeConst.DROPDOWN_FIELD,
        editData: employeeTypeCodes,
        onChange: onEditRow,
      },
      {
        label: Locale.site_details?.ev_details.site_contact.first_name,
        field: "employeeFirstName",
        editType: EditFieldTypeConst.TEXT_FIELD,
        onChange: onEditRow,
        maxLength: 100,
      },
      {
        label: Locale.site_details?.ev_details.site_contact.middle_initial,
        field: "employeeMiddleName",
        editType: EditFieldTypeConst.TEXT_FIELD,
        onChange: onEditRow,
        maxLength: 1,
      },
      {
        label: Locale.site_details?.ev_details.site_contact.last_name,
        field: "employeeLastName",
        editType: EditFieldTypeConst.TEXT_FIELD,
        onChange: onEditRow,
        maxLength: 100,
      },
      {
        label: "",
        field: "editActionRow",
      },
    ];

    if (isSectionValueChanged && sectionEditedData?.SiteEmployee) {
      list =
        sectionEditedData?.SiteEmployee?.data?.map((item: any) => {
          return {
            id: item.id,
            employeeType: item.employeeType || "-",
            employeeTypeCd: item.employeeTypeCd || "-",
            employeeFirstName: item.employeeFirstName,
            employeeLastName: item.employeeLastName,
            employeeMiddleName: item.employeeMiddleName,
            editActionRow: "",
          };
        }) || [];
    } else {
      list =
        fetchedSiteContactData?.data?.result?.SiteEmployee?.item?.map(
          (item: any, index: number) => {
            return {
              id: index,

              employeeType:
                item?.siteEmployeeTypeCd?.siteEmployeeTypeName || "-",
              employeeTypeCd:
                item?.siteEmployeeTypeCd?.siteEmployeeTypeCd || "-",
              employeeFirstName: item.firstName,
              employeeLastName: item.lastName,
              employeeMiddleName: item.middleInitial,
              editActionRow: "",
            };
          }
        ) || [];
    }
    const contactData: TableDetailsViewData = {
      tableHeaders: tbHeaders,
      data: list,
    };
    setCurrentStateOfContactData(contactData);
    setSavedStateOfContactData(contactData);
  }, [
    fetchedSiteContactData,
    employeeTypeCodes,
    isEditing,
    evDetailsTabSelected,
  ]);

  return (
    <TableDetailsView
      className={"ev-site-contact"}
      data={{
        tableHeaders: currentStateOfContactData.tableHeaders,
        rows: currentStateOfContactData?.data,
        defaultData: defaultData,
      }}
      isLoading={isSiteContactDataLoading}
      isEditing={isEditing}
      renderAddNewRowUI={renderAddNewEmployeeUI}
      deleteRow={onDeleteRow}
      onAddRowClick={onAddNewEmployeeClick}
      onEditDiscard={onDiscardRow}
      onSaveRow={onSaveRow}
      onShowNewRowClick={onShowNewRowClick}
      isNewRowValid={isNewEmployeeRowValid}
      newRowInvalidText={newEmployeeRowInvalidText}
      isCreate={isCreate}
    />
  );
};
