export const initialState = { notificationState: [] };

interface ActionProps {
  type: String;
  payload?: any;
}

export default function notificationStateReducer(
  state = initialState,
  action: ActionProps
) {
  switch (action.type) {
    case "UPDATE_NOTIFICATION_STATE":
      state.notificationState = action.payload;
      return { ...state };

    default:
      return { ...state };
  }
}
