import { useEffect, useState } from "react";

import { NumericTextBoxChangeEvent } from "@progress/kendo-react-inputs";
import { DropDownListChangeEvent } from "@progress/kendo-react-dropdowns";

import {
  useSiteOperatorNameCodesData,
  useStationArchetypeCodeCodesData,
} from "../../../../../../../../hooks";

import { Locale } from "../../../../../../../../utils/localization";
import { EditFieldTypeConst } from "../../../../../../../../constants";
import { DetailsViewComponent } from "../../../../../../../components";

interface IProps {
  isCreate: boolean;
  isEditing: boolean;

  evDetailsTabSelected: any;

  onPatchRequestValueSet: any;
  onSectionValueSet: any;

  isSectionValueChanged: any;
  onSectionValueChanged: any;
  sectionEditedData: any;

  fetchedHydrogenOverviewData: any;
  isHydrogenOverviewDataLoading: boolean;

  setHydrogenOverviewSectionValid: (isValid: number) => void;
}

export const HydrogenOverview = (props: IProps) => {
  const {
    isCreate,
    isEditing,
    evDetailsTabSelected,

    onPatchRequestValueSet,
    onSectionValueSet,

    isSectionValueChanged,
    onSectionValueChanged,
    sectionEditedData,

    fetchedHydrogenOverviewData,
    isHydrogenOverviewDataLoading,

    setHydrogenOverviewSectionValid,
  } = props;

  const [dispenserCount, setDispenserCount] = useState<number | null>();
  const [minStockLevel, setMinStockLevel] = useState<number | null>();
  const [maxStockLevel, setMaxStockLevel] = useState<number | null>();
  const [h35PumpCount, setH35PumpCount] = useState<number | null>();
  const [h70PumpCount, setH70PumpCount] = useState<number | null>();
  const [siteOperatorName, setSiteOperatorName] = useState<{
    id: string;
    value: string;
  }>();
  const [stationArchetypeCode, setStationArchetypeCode] = useState<{
    id: string;
    value: string;
  }>();

  const [isMinStockLevelValid, setIsMinStockLevelValid] = useState(0);
  const [isMaxStockLevelValid, setIsMaxStockLevelValid] = useState(0);

  const [minStockLevelInvalidText, setMinStockLevelInvalidText] = useState("");
  const [maxStockLevelInvalidText, setMaxStockLevelInvalidText] = useState("");

  const {
    isLoading: isSiteOperatorNameCodesLoading,
    data: siteOperatorNameCodes,
    refetch: refetchSiteOperatorNameCodes,
  } = useSiteOperatorNameCodesData();

  const {
    isLoading: isStationArchetypeCodeCodesLoading,
    data: stationArchetypeCodeCodes,
    refetch: refetchStationArchetypeCodeCodes,
  } = useStationArchetypeCodeCodesData();

  useEffect(() => {
    if (evDetailsTabSelected === 2 && isEditing) {
      refetchSiteOperatorNameCodes();
      refetchStationArchetypeCodeCodes();
    }
  }, [evDetailsTabSelected, isEditing]);

  useEffect(() => {
    if (
      (sectionEditedData && isSectionValueChanged) ||
      fetchedHydrogenOverviewData
    ) {
      setDispenserCount(
        sectionEditedData?.dispenserCount
          ? sectionEditedData?.dispenserCount
          : sectionEditedData?.dispenserCount === 0
          ? 0
          : sectionEditedData?.dispenserCount === ""
          ? undefined
          : fetchedHydrogenOverviewData?.dispenserCount
      );
      setMinStockLevel(
        sectionEditedData?.minimumStockLevel
          ? +sectionEditedData?.minimumStockLevel
          : sectionEditedData?.minimumStockLevel === 0
          ? 0
          : sectionEditedData?.minimumStockLevel === ""
          ? undefined
          : +fetchedHydrogenOverviewData?.minimumStockLevel
      );
      setMaxStockLevel(
        sectionEditedData?.maximumStockLevel
          ? +sectionEditedData?.maximumStockLevel
          : sectionEditedData?.maximumStockLevel === 0
          ? 0
          : sectionEditedData?.maximumStockLevel === ""
          ? undefined
          : +fetchedHydrogenOverviewData?.maximumStockLevel
      );
      setH35PumpCount(
        sectionEditedData?.h35PumpCount
          ? sectionEditedData?.h35PumpCount
          : sectionEditedData?.h35PumpCount === 0
          ? 0
          : sectionEditedData?.h35PumpCount === ""
          ? undefined
          : fetchedHydrogenOverviewData?.h35PumpCount
      );
      setH70PumpCount(
        sectionEditedData?.h70PumpCount
          ? sectionEditedData?.h70PumpCount
          : sectionEditedData?.h70PumpCount === 0
          ? 0
          : sectionEditedData?.h70PumpCount === ""
          ? undefined
          : fetchedHydrogenOverviewData?.h70PumpCount
      );
      setSiteOperatorName(
        sectionEditedData?.siteOperator || {
          id: fetchedHydrogenOverviewData?.siteOperatorName?.siteOperatorCd,
          value: fetchedHydrogenOverviewData?.siteOperatorName?.siteOperatorCd,
        }
      );
      setStationArchetypeCode(
        sectionEditedData?.stationArchetypeCode || {
          id: fetchedHydrogenOverviewData?.stationArchetypeCode
            ?.stationArchetypeCd,
          value:
            fetchedHydrogenOverviewData?.stationArchetypeCode
              ?.stationArchetypeCd,
        }
      );
    }
  }, [sectionEditedData, fetchedHydrogenOverviewData]);

  useEffect(() => {
    if (minStockLevel === 0 || maxStockLevel === 0) {
      if (minStockLevel === 0) {
        setHydrogenOverviewSectionValid(-1);
        setIsMinStockLevelValid(-1);
        setMinStockLevelInvalidText(
          "Minimum stock level should be greater than 0"
        );
      }
      if (maxStockLevel === 0) {
        setHydrogenOverviewSectionValid(-1);
        setIsMaxStockLevelValid(-1);
        setMaxStockLevelInvalidText(
          "Maximum stock level should be greater than 0"
        );
      }
    } else if (
      (!minStockLevel || minStockLevel === null) &&
      maxStockLevel &&
      minStockLevel !== 0
    ) {
      setHydrogenOverviewSectionValid(-1);
      setIsMinStockLevelValid(-1);
      setMinStockLevelInvalidText(
        Locale.create_site?.error_messages?.min_stock_level_required
      );
    } else if (
      minStockLevel &&
      +minStockLevel !== 0 &&
      (maxStockLevel || maxStockLevel === 0) &&
      +maxStockLevel <= +minStockLevel
    ) {
      setHydrogenOverviewSectionValid(-1);
      setIsMaxStockLevelValid(-1);
      setMaxStockLevelInvalidText(
        Locale.create_site?.error_messages?.max_stock_level_invalid_msg
      );
    } else if (
      minStockLevel &&
      +minStockLevel !== 0 &&
      (!maxStockLevel || maxStockLevel === null || maxStockLevel === 0)
    ) {
    } else {
      setHydrogenOverviewSectionValid(0);
      setIsMinStockLevelValid(0);
      setIsMaxStockLevelValid(0);
    }
  }, [minStockLevel, maxStockLevel]);

  const onDispenserCountChange = (event: NumericTextBoxChangeEvent) => {
    const count = event.value;
    setDispenserCount(count);
    onSectionValueChanged(true);
    onSectionValueSet({
      dispenserCount: count === null ? "" : count,
    });
    onPatchRequestValueSet(
      {
        dispenserCount: count === null ? "" : count?.toString(),
      },
      +fetchedHydrogenOverviewData?.dispenserCount !== count
    );
  };

  const onMinStockLevelChange = (event: NumericTextBoxChangeEvent) => {
    const count = event.value;
    setMinStockLevel(count);
    onSectionValueChanged(true);
    setIsMinStockLevelValid(0);
    onSectionValueSet({
      minimumStockLevel: count === null ? "" : count,
    });
    onPatchRequestValueSet(
      {
        minimumStockLevel: count === null ? "" : count?.toString(),
      },
      +fetchedHydrogenOverviewData?.minimumStockLevel !== count
    );
  };

  const onMaxStockLevelChange = (event: NumericTextBoxChangeEvent) => {
    const count = event.value;
    setMaxStockLevel(count);
    onSectionValueChanged(true);
    setIsMaxStockLevelValid(0);
    onSectionValueSet({
      maximumStockLevel: count === null ? "" : count,
    });
    onPatchRequestValueSet(
      {
        maximumStockLevel: count === null ? "" : count?.toString(),
      },
      +fetchedHydrogenOverviewData?.maximumStockLevel !== count
    );
  };

  const onH35PumpCountChange = (event: NumericTextBoxChangeEvent) => {
    const count = event.value;
    setH35PumpCount(count);
    onSectionValueChanged(true);
    onSectionValueSet({
      h35PumpCount: count === null ? "" : count,
    });
    onPatchRequestValueSet(
      {
        h35PumpCount: count === null ? "" : count?.toString(),
      },
      +fetchedHydrogenOverviewData?.h35PumpCount !== count
    );
  };

  const onH70PumpCountChange = (event: NumericTextBoxChangeEvent) => {
    const count = event.value;
    setH70PumpCount(count);
    onSectionValueChanged(true);
    onSectionValueSet({
      h70PumpCount: count === null ? "" : count,
    });
    onPatchRequestValueSet(
      {
        h70PumpCount: count === null ? "" : count?.toString(),
      },
      +fetchedHydrogenOverviewData?.h70PumpCount !== count
    );
  };

  const onSiteOperatorNameChange = (event: DropDownListChangeEvent) => {
    const siteOperator = event.target.value;
    setSiteOperatorName(siteOperator);
    onSectionValueChanged(true);
    onSectionValueSet({
      siteOperator: siteOperator,
    });
    onPatchRequestValueSet(
      {
        siteOperatorName: {
          siteOperatorCd: siteOperator?.id,
        },
      },
      +fetchedHydrogenOverviewData?.siteOperatorName?.siteOperatorCd !==
        +siteOperator?.id
    );
  };

  const onStationArchetypeCodeChange = (event: DropDownListChangeEvent) => {
    const station = event.target.value;
    setStationArchetypeCode(station);
    onSectionValueChanged(true);
    onSectionValueSet({
      stationArchetypeCode: station,
    });
    onPatchRequestValueSet(
      {
        stationArchetypeCode: {
          stationArchetypeCd: station?.id,
        },
      },
      +fetchedHydrogenOverviewData?.stationArchetypeCode?.stationArchetypeCd !==
        +station?.id
    );
  };

  const getHydrogenOverviewData = () => [
    {
      label:
        Locale.site_details?.ev_details.hydrogen_section.hydrogen_overview
          ?.fields?.dispenser_count,
      value: fetchedHydrogenOverviewData?.dispenserCount,
      editType: EditFieldTypeConst.NUMBER_FIELD,
      onChange: onDispenserCountChange,
      editValue: dispenserCount,
      isEditDataLoading: false,
      isVisible: isCreate ? false : true,
      maxLength: 999999999,
      isvalid: true,
      isDisabled: true,
    },
    {
      label:
        Locale.site_details?.ev_details.hydrogen_section.hydrogen_overview
          ?.fields?.h35_pump_count,
      value: fetchedHydrogenOverviewData?.h35PumpCount,
      editType: EditFieldTypeConst.NUMBER_FIELD,
      onChange: onH35PumpCountChange,
      editValue: h35PumpCount,
      isEditDataLoading: false,
      isVisible: isCreate ? false : true,
      maxLength: 999999999,
      isvalid: true,
      isDisabled: true,
    },
    {
      label:
        Locale.site_details?.ev_details.hydrogen_section.hydrogen_overview
          ?.fields?.h70_pump_count,
      value: fetchedHydrogenOverviewData?.h70PumpCount,
      editType: EditFieldTypeConst.NUMBER_FIELD,
      onChange: onH70PumpCountChange,
      editValue: h70PumpCount,
      isEditDataLoading: false,
      isVisible: isCreate ? false : true,
      maxLength: 999999999,
      isvalid: true,
      isDisabled: true,
    },
    {
      label:
        Locale.site_details?.ev_details.hydrogen_section.hydrogen_overview
          ?.fields?.minimum_stock_level,
      value: fetchedHydrogenOverviewData?.minimumStockLevel
        ? +fetchedHydrogenOverviewData?.minimumStockLevel
        : "",
      editType: EditFieldTypeConst.NUMBER_FIELD,
      onChange: onMinStockLevelChange,
      editValue: minStockLevel,
      isEditDataLoading: false,
      isVisible: true,
      maxLength: 999999999999999,
      isValid: isMinStockLevelValid !== -1,
      validationMessage: minStockLevelInvalidText,
      isDisabled: false,
    },
    {
      label:
        Locale.site_details?.ev_details.hydrogen_section.hydrogen_overview
          ?.fields?.maximum_stock_level,
      value: fetchedHydrogenOverviewData?.maximumStockLevel
        ? +fetchedHydrogenOverviewData?.maximumStockLevel
        : "",
      editType: EditFieldTypeConst.NUMBER_FIELD,
      onChange: onMaxStockLevelChange,
      editValue: maxStockLevel,
      isEditDataLoading: false,
      isVisible: true,
      maxLength: 999999999999999,
      isValid: isMaxStockLevelValid !== -1,
      validationMessage: maxStockLevelInvalidText,
      isDisabled: false,
    },
    {
      label:
        Locale.site_details?.ev_details.hydrogen_section.hydrogen_overview
          ?.fields?.site_operator_name,
      value: fetchedHydrogenOverviewData?.siteOperatorName?.siteOperatorCd,
      editType: EditFieldTypeConst.DROPDOWN_FIELD,
      onChange: onSiteOperatorNameChange,
      isEditDataLoading: isSiteOperatorNameCodesLoading,
      editData: siteOperatorNameCodes || [],
      editValue: siteOperatorName,
      isVisible: true,
    },
    {
      label:
        Locale.site_details?.ev_details.hydrogen_section.hydrogen_overview
          ?.fields?.station_archetype_code,
      value:
        fetchedHydrogenOverviewData?.stationArchetypeCode?.stationArchetypeCd,
      editType: EditFieldTypeConst.DROPDOWN_FIELD,
      onChange: onStationArchetypeCodeChange,
      isEditDataLoading: isStationArchetypeCodeCodesLoading,
      editData: stationArchetypeCodeCodes || [],
      editValue: stationArchetypeCode,
      isVisible: true,
    },
  ];

  return (
    <DetailsViewComponent
      data={getHydrogenOverviewData()}
      classValue="mdm--row mdm--sd-data hydrogen-overview"
      isLoading={isHydrogenOverviewDataLoading}
      isEditing={isEditing}
      isCreate={isCreate}
    />
  );
};
