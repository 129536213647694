import React, { useEffect, useRef, useState } from "react";
import { useQuery } from "react-query";
import { format } from "date-fns";
import { GridSortChangeEvent } from "@progress/kendo-react-grid";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { filterBy } from "@progress/kendo-data-query";

import _cloneDeep from "lodash/cloneDeep";
import _uniq from "lodash/uniq";
import _map from "lodash/map";
import _remove from "lodash/remove";

import { AppDataGrid, Loader } from "../../../components";
import { Link } from "react-router-dom";
import {
  SitesListRequestData,
  PageState,
  PaginationState,
  SitesListDocumentData,
} from "../../../../models";
import { SiteConst, AppConst } from "../../../../constants";
import { Locale } from "../../../../utils/localization";

import "./ChangeRequestList.scss";
import { ConfigurationService } from "../../../../services/configuration.service";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { ToastNotification } from "../../../components/toast-notification/ToastNotification";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { useMasterDataSet1 } from "../../../../hooks";
import { Checkbox, RadioGroup } from "@progress/kendo-react-inputs";
import {
  TabStrip,
  TabStripSelectEventArguments,
  TabStripTab,
} from "@progress/kendo-react-layout";
import { useDispatch, useSelector } from "react-redux";
import {
  changeRequestDetails,
  changeRequestPageSize,
} from "../../../../redux/actions/changeRequestDetail";
import {
  cloneDeep,
  compact,
  difference,
  includes,
  pull,
  pullAll,
  remove,
} from "lodash";
import axios from "axios";

const TABLE_MAX_HEIGHT = "72vh";
interface MasterDataType {
  id: string;
  value: string;
  countrydiallingcd?: string;
}
export const ChangeRequestList = () => {
  const [success, setSuccess] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState<any>({
    name: "",
    value: "",
  });
  const [selected, setSelected] = useState<number>(0);

  const pageSizedetails = useSelector((state: any) => {
    return state.changeRequestDetails.pageSize;
  });

  const selectedGlobalCountry = useSelector((state: any) => {
    return state?.selectedGlobalCountry.selectedGlobalCountry;
  });

  const connection = useSelector((state: any) => {
    return state?.siteUpdateConnection?.connection;
  });

  useEffect(() => {
    connection?.on?.("SiteUpdateNotification", (message: any) => {
      console.log(message, "message");
      const msg = message;
      if (msg) {
        refetch();
      }
    });
  }, [connection]);

  const [checked, setChecked] = useState(
    AppConst.EXPORT_DATA_TYPE.CURRENT_PAGE
  );
  const dispatch = useDispatch();

  const [changeRequestListData, setChangeRequestListData] =
    useState<SitesListDocumentData[]>();

  const [currentConfigName, setCurrentConfigName] = useState<any>("");
  const [exportData, setExportData] = useState<SitesListDocumentData[]>();
  const [selecteDataOnCheck, setSelecteDataOnCheck] = useState<any>([]);
  const [isEnableButton, setIsEnableButton] = useState<boolean>(true);
  const [visible, setVisible] = useState<boolean>(false);

  const [filterLoading, setFilterLoading] = useState<boolean>(false);
  const [show, setShow] = useState("");
  const fileType = AppConst.EXPORT_FILE_TYPE;
  const fileExtension = AppConst.EXPORT_FILE_TYPE_EXTENSION;

  const defaultPayload: any = {
    filter:
      selectedGlobalCountry === AppConst.ALL_SITES
        ? ""
        : `Country eq  ${selectedGlobalCountry}`,
    searchText: "",
    orderby: "CreatedDate desc",
    page: 0,
    pageSize: pageSizedetails,
  };
  const initialPageState: PageState = {
    skip: 0,
    take: pageSizedetails,
  };

  const initialPaginationState: PaginationState = {
    buttonCount: 4,
    info: true,
    type: "numeric",
    previousNext: true,
    pageSizes: [25, 50, 100],
  };

  const defaultSort = [
    {
      field: "createdDate",
      dir: "desc",
    },
  ];

  const [totalCount, setTotalCount] = useState<number>(0);
  const [initialSort, setInitialSort] = useState(defaultSort);
  const [searchText, setSearchText] = useState("");
  const [selectedCountryItems, setSelectedCountryItems] = useState<any>({
    name: "",
    value: "",
  });
  const [selectedGroupItems, setSelectedGroupItems] = useState<any>({
    name: "",
    value: "",
  });
  const [selectedRequestedBysItems, setSelectedRequestedBysItems] =
    useState<any>({ name: "", value: "" });
  const [selectedLastUpdatedBysItems, setSelectedLastUpdatedBysItems] =
    useState<any>({ name: "", value: "" });
  const [selectedGlobalSiteIdItems, setSelectedGlobalSiteIdItems] =
    useState<any>({ name: "", value: "" });

  const [isDataLoading, setIsDataLoading] = useState(false);
  const [showApproveDialog, setShowApproveDialog] = useState(false);
  const [requestPayload, setRequestPayload] =
    useState<SitesListRequestData>(defaultPayload);
  const [RequestTypes, setRequestTypes] = useState<any>({});
  const [globalSiteId, setGlobalSiteId] = useState<any>([]);
  const [pageState, setPageState] = useState(initialPageState);
  const [selectAllC, setSeleteSAllC] = useState<any>([]);
  const [countryCodes, setCountryCodes] = useState<MasterDataType[]>();
  const [globalCountryMasterData, setGlobalCountryMasterData] = useState([]);
  const [groupMasterData, setGroupMasterData] = useState([]);
  const [requestedBysMasterData, setRequestedBysMasterData] = useState([]);
  const [lastUpdatedBysMasterData, setLastUpdatedBysMasterData] = useState([]);
  const [statusMasterData, setStatusMasterData] = useState([]);
  const [masterDataList, setMasterDataList] = useState<any>([]);
  const [pageable, setPageable] = useState<PaginationState | boolean>(
    initialPaginationState
  );
  const [checkedAll, setCheckedAll] = useState(false);
  const [conformationtext, setConformationtext] = useState(false);
  const [rejectionReason, setRejectionReason] = useState("");
  const isMounted = useRef(false);
  const userData = useSelector((state: any) => state.auth.user);
  const handleChangeCheckBox = (event: any) => {
    const newchangeRequestListData: any = changeRequestListData
      ?.filter((item) => {
        return item.status === "Open";
      })
      .map((item) => item.id);
    if (event?.target?.value) {
      setSeleteSAllC([...selectAllC, pageState.skip]);
      setCheckedAll(true);
      setIsEnableButton(false);

      setSelecteDataOnCheck([
        ...selecteDataOnCheck,
        ...newchangeRequestListData,
      ]);
    } else {
      setIsEnableButton(true);
      const filterArrySkip = selectAllC;
      pull(filterArrySkip, pageState.skip);

      const result = difference(selecteDataOnCheck, newchangeRequestListData);

      setSeleteSAllC(filterArrySkip);

      setCheckedAll(false);

      setSelecteDataOnCheck(result);
    }
  };
  const handleChangeForApproveReject = (e: any) => {
    if (e.value.value === "Approve") {
      setCurrentConfigName("Approve");
      setShowApproveDialog(true);
    } else {
      setCurrentConfigName("Reject");
      setShowApproveDialog(true);
    }
  };
  const CustomHeader = () => {
    return (
      <Checkbox
        className="configuration-checkbox-master"
        size="medium"
        onChange={handleChangeCheckBox}
        checked={checkedAll}
      />
    );
  };

  const statusForCheck = [{ value: "Approve" }, { value: "Reject" }];
  const CustomButton = () => {
    return (
      <DropDownList
        data={statusForCheck}
        textField="value"
        onChange={handleChangeForApproveReject}
        className="mdm--data-grid-button-batch"
        value={{ value: "Batch Action" }}
        disabled={selecteDataOnCheck?.length > 0 ? false : true}
      />
    );
  };
  const tableHeaders = [
    {
      label: "Id",
      field: "id",
      // width: "90",
      minWidth: "5",
    },
    {
      label: "Requested Date",
      field: "createdDate",
      // width: "90",
      minWidth: "10",
    },
    {
      label: "Global Site Id",
      field: SiteConst.SITE_LIST_TABLE_HEADER_KEY.GLOBAL_SITE_ID,
      // width: "90",
      minWidth: "7",
    },

    {
      label: "Site Name",
      field: SiteConst.SITE_LIST_TABLE_HEADER_KEY.SITE_NAME,
      minWidth: "7",
    },
    {
      label: "Request Type",
      field: "fieldName",
      minWidth: "7",
    },
    {
      label: "New Value",
      field: "newValue",
      minWidth: "8",
    },
    {
      label: "Old Value",
      field: "oldValue",
      minWidth: "6",
    },
    {
      label: "Country",
      field: "country",
      // width: "230",
      minWidth: "6",
    },

    {
      label: "Group",
      field: "groupName",
      minWidth: "6",
    },
    {
      label: "Requested By",
      field: "createdBy",
      // width: "140",
      minWidth: "7",
    },

    {
      label: "Last Updated By",
      field: "updatedBy",
      // width: "230",
      minWidth: "8",
    },
    {
      label: "Status",
      field: "status",
      // width: "140",
      minWidth: "6",
    },
  ];

  const { isLoading, data, refetch } = useQuery(
    "change-request-list",
    () => {
      return ConfigurationService.getChangeRequestList(requestPayload);
    },
    {
      staleTime: AppConst.VIEW_SITE_DETAILS_STALE_TIME,
      enabled: true,
      refetchOnMount: true,
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    if (data) {
      setChangeRequestListData(data?.data.items);
      setGlobalSiteId(data.data?.globalSiteIds?.slice());
      setRequestTypes(data.data?.requestTypes?.sort());
      setGlobalCountryMasterData(data?.data?.countries?.sort());
      setGroupMasterData(compact(data.data?.groups?.sort()));
      setRequestedBysMasterData(data.data?.requestedBys?.sort());
      setLastUpdatedBysMasterData(data.data?.lastUpdatedBys?.sort());
      setStatusMasterData(data.data?.statuses?.sort());

      if (data?.data?.items?.length > 0) {
        setTotalCount(data?.data.items[0]?.total);
      }

      setPageable(initialPaginationState);
      if (data?.data?.items?.length < 1 && data?.data.items[0]?.total <= 25) {
        setPageable(false);
      }
    }
    if (!data?.data?.items?.length) {
      setPageable(false);
    }
  }, [data]);
  const {
    isLoading: isMasterDataSet1Loading,
    data: masterDataSet1,
    refetch: refetchMasterDataSet1,
  } = useMasterDataSet1();
  const masterData = async () => {
    setFilterLoading(true);
    const masterData = await ConfigurationService.getChangeRequestList({
      ...defaultPayload,
      pageSize: 25,
    });
    setMasterDataList(masterData?.data);
    setFilterLoading(false);
  };

  useEffect(() => {
    refetchMasterDataSet1();
  }, []);

  useEffect(() => {
    if (masterDataSet1) {
      const countryTypes = masterDataSet1?.countryCodes?.map(
        (type: any) => type?.countryname || ""
      );
      setCountryCodes(countryTypes);
    }
  }, [masterDataSet1]);

  useEffect(() => {
    if (isMounted?.current) {
      setIsDataLoading(false);
      refetch();
    }
  }, [requestPayload]);
  useEffect(() => {
    if (isMounted?.current) {
      const delayDebounceFn = setTimeout(() => {
        if (searchText) {
          if (searchText?.length >= 0) {
            setPageState({
              ...initialPageState,
              take: requestPayload?.pageSize,
            });
            setIsDataLoading(true);
            setRequestPayload({ ...requestPayload, page: 0, searchText });
          }
        } else if (searchText?.length === 0) {
          setPageState({ ...initialPageState, take: requestPayload?.pageSize });
          setIsDataLoading(true);
          setRequestPayload({ ...requestPayload, page: 0, searchText: "" });
        } else {
          setPageState({ ...initialPageState, take: requestPayload?.pageSize });
          setIsDataLoading(true);
          setRequestPayload({ ...requestPayload, page: 0, searchText: "*" });
        }
      }, 2000);

      return () => clearTimeout(delayDebounceFn);
    } else {
      isMounted.current = true;
    }
  }, [searchText]);

  useEffect(() => {
    if (selectedGlobalCountry == AppConst.ALL_SITES) {
    } else if (selected === 2) {
      setRequestPayload({
        ...requestPayload,
        page: 0,
        filter: `Status eq Approved| and Country eq ${selectedGlobalCountry} `,
      });
    } else if (selected === 3) {
      setRequestPayload({
        ...requestPayload,
        page: 0,
        filter: `Status eq Rejected| and Country eq ${selectedGlobalCountry}`,
      });
    } else if (selected === 1) {
      setRequestPayload({
        ...requestPayload,
        page: 0,
        filter: `Status eq Open| and Country eq ${selectedGlobalCountry}`,
      });
    } else {
      setRequestPayload({
        ...requestPayload,
        page: 0,
        filter: `Country eq ${selectedGlobalCountry}`,
      });
    }
  }, [selectedGlobalCountry]);

  const onCheckBoxClick = (e: any, data: any) => {
    if (e?.target?.value) {
      setSelecteDataOnCheck([...selecteDataOnCheck, data]);
      setIsEnableButton(false);
    } else {
      selecteDataOnCheck.forEach((item: any) => {
        if (item === data) {
          const newArray = selecteDataOnCheck.filter((items: any) => {
            return data !== items;
          });
          setSelecteDataOnCheck(newArray);
        }
      });
      if (selecteDataOnCheck.length <= 1) {
        setIsEnableButton(true);
      }
    }
  };

  const replaceAll = async (str: string, find: string, replace: string) => {
    const wordArray: string[] = str
      .replaceAll("&", " and ")
      .split(" ")
      .filter((val) => val);
    const convertedWordArray = wordArray?.map(
      (value: string, index: number) => {
        let word = value;
        if (value === find && index !== 0 && index !== wordArray?.length - 1) {
          word = value.replace(new RegExp(find, "ig"), replace);
        }
        return word;
      }
    );
    return convertedWordArray.join(" ");
  };

  const pageChange = (event: PageState) => {
    const filterCheckIcon = includes(selectAllC, event.skip);
    setCheckedAll(filterCheckIcon);
    setIsDataLoading(true);
    setPageState(event);
    const page: number = event.skip / event.take + 1;
    //pageSize;
    dispatch(changeRequestPageSize(event.take));
    setRequestPayload({ ...requestPayload, page, pageSize: event.take });
  };

  const pageSort = (event: GridSortChangeEvent) => {
    setIsDataLoading(true);
    if (event.sort?.length) {
      setRequestPayload({
        ...requestPayload,
        orderby: `${SiteConst.COLUMN_CHANGE_CONFIG[event?.sort[0]?.field]} ${
          event.sort[0].dir
        }`,
      });
    } else {
      setRequestPayload({
        ...requestPayload,
        orderby: "",
      });
    }
  };
  useEffect(() => {
    if (exportData) {
      const ws = XLSX.utils.json_to_sheet(exportData);
      const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const data = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(data, "Export" + fileExtension);
      setIsDataLoading(false);
    }
  }, [exportData]);
  const excelExport = () => {
    setIsDataLoading(true);

    if (checked === AppConst.EXPORT_DATA_TYPE.ALL) {
      let totalRows = requestPayload?.pageSize;
      let currentPage = requestPayload?.page;
      totalRows = totalCount;
      currentPage = 0;
      if (totalRows > 100) {
        const pagesLength = totalRows / 100;
        let linksArray: number[] = [];
        for (let c = 0; c < pagesLength; c++) {
          linksArray.push(c + 1);
        }
        ConfigurationService.getAllChangeRequestList(linksArray, {
          ...requestPayload,
        }).then((response) => {
          setExportData(response);
        });
      } else {
        ConfigurationService.getChangeRequestList({
          ...requestPayload,
          pageSize: totalRows,
        }).then((res) => {
          setExportData(res?.data.items);
        });
      }
    } else {
      setExportData(data?.data.items);
    }
  };
  const onRefreshClick = () => {
    setIsDataLoading(true);
    setPageState(initialPageState);
    setInitialSort(defaultSort);
    setSearchText("");

    setSelectedCountryItems({ name: "", value: "" });
    setSelectedGroupItems({ name: "", value: "" });
    setSelectedRequestedBysItems({ name: "", value: "" });
    setSelectedLastUpdatedBysItems({ name: "", value: "" });
    setSelectedGlobalSiteIdItems({ name: "", value: "" });
    if (selected === 0) {
      setSelectedStatus({ name: "", value: "" });
      setRequestPayload({
        ...requestPayload,
        filter: "",
      });
    } else {
      setRequestPayload({
        ...requestPayload,
        filter: selectedStatus.value ? selectedStatus.value : "",
      });
    }
  };

  const onSearch = async (searchText: string) => {
    setSearchText("");
    setSearchText(searchText);
    let modifiedSearchText = await replaceAll(searchText, "and", "AND");
    modifiedSearchText = await replaceAll(modifiedSearchText, "or", "OR");
    setSearchText(modifiedSearchText);
  };

  const filterRows = () => {
    let filterQuery = ``;
    filterQuery = `${selectedStatus.value ? selectedStatus.value : ""}${
      selectedCountryItems.value ? selectedCountryItems.value : ""
    }${selectedGroupItems.value ? selectedGroupItems.value : ""}${
      selectedRequestedBysItems.value ? selectedRequestedBysItems.value : ""
    }${
      selectedLastUpdatedBysItems.value ? selectedLastUpdatedBysItems.value : ""
    }${selectedGlobalSiteIdItems.value ? selectedGlobalSiteIdItems.value : ""}`;

    setPageState({ ...initialPageState, take: requestPayload?.pageSize });
    setRequestPayload({ ...requestPayload, page: 0, filter: filterQuery });

    return filterQuery;
  };
  const handleChange = (e: any) => {
    setChecked(e.value);
  };

  const clearFilter = () => {
    setSelectedCountryItems({ name: "", value: "" });
    setSelectedGroupItems({ name: "", value: "" });
    setSelectedRequestedBysItems({ name: "", value: "" });
    setSelectedLastUpdatedBysItems({ name: "", value: "" });
    setSelectedGlobalSiteIdItems({ name: "", value: "" });
    if (selected === 0) {
      setSelectedStatus({ name: "", value: "" });
      setRequestPayload({
        ...requestPayload,
        filter: "",
      });
    } else {
      setRequestPayload({
        ...requestPayload,
        filter: selectedStatus.value ? selectedStatus.value : "",
      });
    }
  };

  useEffect(() => {
    setTimeout(() => {
      setSuccess(false);
    }, 16000);
  }, [show]);
  const getDataById = (e: any, data: any) => {
    dispatch(changeRequestDetails(data));
  };
  const toggleDialog = () => {
    setVisible(!visible);
  };

  const handleCountryFilterChange = (event: any) => {
    setSelectedCountryItems({
      value: `Country eq ${event.value} | and `,
      name: event.value,
    });
  };
  const handleRequestFilterChange = (event: any) => {
    setSelectedRequestedBysItems({
      value: `FieldName eq ${event.value} | and `,
      name: event.value,
    });
  };
  const handleLastUpdatedFilterChange = (event: any) => {
    setSelectedLastUpdatedBysItems({
      value: `UpdatedBy eq ${event.value} | and `,
      name: event.value,
    });
  };
  const handleGroupFilterChange = (event: any) => {
    setSelectedGroupItems({
      value: `GroupName eq ${event.value} | and `,
      name: event.value,
    });
  };
  const handleGlobalSiteIdFilterChange = (event: any) => {
    setSelectedGlobalSiteIdItems({
      value: `GlobalSiteId eq ${event.value}| and `,
      name: event.value,
    });
  };
  const filterData = (filter: any) => {
    const data = globalSiteId.slice();
    return filterBy(data, filter);
  };
  const filterChange = (event: any) => {
    setGlobalSiteId(filterData(event.filter));
  };
  const handleStatusFilterChange = (event: any) => {
    setSelectedStatus({
      value: `Status eq ${event.value} | and `,
      name: event.value,
    });
  };

  const exportContent = () => {
    const exportTypes: { label: string; value: string }[] = [
      {
        label: Locale.formatString(
          Locale.landing_page?.sites_list.export.export_current,
          changeRequestListData && changeRequestListData?.length < 25
            ? changeRequestListData?.length
            : pageState.take
        ),
        value: AppConst.EXPORT_DATA_TYPE.CURRENT_PAGE,
      },
      {
        label: Locale.formatString(
          Locale.landing_page?.sites_list.export.export_all,
          totalCount || 0
        ),
        value: AppConst.EXPORT_DATA_TYPE.ALL,
      },
    ];
    return (
      <>
        <fieldset className="mdm--grid-filter">
          <legend className="mdm--grid-filter-label">
            {Locale.landing_page?.sites_list.export.export_label}
          </legend>
          <RadioGroup
            data={exportTypes}
            value={checked}
            onChange={handleChange}
          />
        </fieldset>
      </>
    );
  };

  const filterContent = () => {
    if (filterLoading) {
      return <Loader />;
    }
    return (
      <>
        {
          <fieldset className="mdm--grid-filter">
            <legend className="mdm--grid-filter-label">
              {Locale.landing_page?.sites_list.table_headers.status_label}
            </legend>
            <DropDownList
              className="mdm--change-request-dropdown"
              style={{ width: "18vw", height: "4vh" }}
              data={statusMasterData}
              defaultItem={selectedStatus.name || "Select Status"}
              onChange={handleStatusFilterChange}
            />
          </fieldset>
        }
        {
          <fieldset className="mdm--grid-filter">
            <legend className="mdm--grid-filter-label">
              {Locale.landing_page?.sites_list.table_headers.country_label}
            </legend>
            <DropDownList
              className="mdm--change-request-dropdown"
              style={{ width: "18vw", height: "4vh" }}
              data={globalCountryMasterData}
              defaultItem={selectedCountryItems.name || "Select Country"}
              onChange={handleCountryFilterChange}
            />
          </fieldset>
        }
        {
          <fieldset className="mdm--grid-filter">
            <legend className="mdm--grid-filter-label">Global Site Id</legend>
            <DropDownList
              className="mdm--change-request-dropdown"
              style={{ width: "18vw", height: "4vh" }}
              data={globalSiteId}
              defaultItem={
                globalSiteId?.length === 1 ? globalSiteId[0] : "Select Site Id"
              }
              onFilterChange={filterChange}
              onChange={handleGlobalSiteIdFilterChange}
              filterable={true}
            />
          </fieldset>
        }
        {
          <fieldset className="mdm--grid-filter">
            <legend className="mdm--grid-filter-label">Request Types</legend>
            <DropDownList
              className="mdm--change-request-dropdown"
              style={{ width: "18vw", height: "4vh" }}
              data={RequestTypes}
              defaultItem={
                selectedRequestedBysItems.name || "Select Request Types"
              }
              onChange={handleRequestFilterChange}
            />
          </fieldset>
        }
        {
          <fieldset className="mdm--grid-filter">
            <legend className="mdm--grid-filter-label">Last Updated By</legend>
            <DropDownList
              className="mdm--change-request-dropdown"
              style={{ width: "18vw", height: "4vh" }}
              data={lastUpdatedBysMasterData}
              defaultItem={
                selectedLastUpdatedBysItems.name || "Select Last Updated By"
              }
              onChange={handleLastUpdatedFilterChange}
            />
          </fieldset>
        }
        {
          <fieldset className="mdm--grid-filter">
            <legend className="mdm--grid-filter-label">Group</legend>
            <DropDownList
              className="mdm--change-request-dropdown"
              style={{ width: "18vw", height: "4vh" }}
              data={groupMasterData}
              defaultItem={selectedGroupItems.name || "Select Group"}
              onChange={handleGroupFilterChange}
            />
          </fieldset>
        }
      </>
    );
  };

  const renderGridCell = (props: any) => {
    if (props.field === "checkId") {
      return (
        <td>
          <Checkbox
            className="configuration-checkbox"
            size="medium"
            // value={selecteDataOnCheck}
            name={props.dataItem.globalSiteId}
            onChange={(e) => onCheckBoxClick(e, props.dataItem.id)}
            disabled={props?.dataItem?.status !== "Open"}
            checked={selecteDataOnCheck.find(
              (x: any) => x === props?.dataItem?.id
            )}
          ></Checkbox>
        </td>
      );
    }
    if (props.field === "id") {
      return (
        <td>
          <Link
            onClick={(e) => getDataById(e, props.dataItem)}
            to={`/changerequestdetails/${props.dataItem["globalSiteId"]}`}
          >
            {props.dataItem[props.field]}
          </Link>
        </td>
      );
    } else if (props.field === "oldValue") {
      return (
        <td>
          {props.dataItem[props.field] === null ? (
            <span className="status_tag blank error">Blank</span>
          ) : (
            props.dataItem[props.field]
          )}
        </td>
      );
    } else if (props.field === "createdDate") {
      return (
        <td
          title={format(
            new Date(props.dataItem[props.field]),
            AppConst.VIEW_DETAIL_DATE_FORMAT
          )}
          className={
            format(
              new Date(props.dataItem[props.field]),
              AppConst.VIEW_DETAIL_DATE_FORMAT
            )
              ? ""
              : "mdm-grid-cell-empty"
          }
        >
          {format(
            new Date(props.dataItem[props.field]),
            AppConst.VIEW_DETAIL_DATE_FORMAT
          )}
        </td>
      );
    } else if (props.field === "status") {
      return (
        <td
          className={
            props.dataItem[props.field] === "Open" ? "success-open" : ""
          }
          // style={{color:`${props.dataItem[props.field] === "Approved" ? "#2a9041":"" } ` }}
        >
          {props.dataItem[props.field] === "Rejected" ? (
            <span className="error status_tag_rejected">
              {props.dataItem[props.field]}
            </span>
          ) : props.dataItem[props.field] === "Approved" ? (
            <span className="error status_tag_approved">
              {props.dataItem[props.field]}
            </span>
          ) : (
            <span className="error status_tag_open">
              {props.dataItem[props.field]}
            </span>
          )}{" "}
        </td>
      );
    }
    return (
      <td
        title={props.dataItem[props.field]}
        className={props.dataItem[props.field] ? "" : "mdm-grid-cell-empty"}
      >
        {props.dataItem[props.field] || "--"}
      </td>
    );
  };
  const handleSelect = (event: TabStripSelectEventArguments) => {
    setIsDataLoading(true);
    setPageState(initialPageState);
    setSelectedCountryItems("");
    setSelectedStatus("");
    setChecked(AppConst.EXPORT_DATA_TYPE.CURRENT_PAGE);

    if (event.selected === 0) {
      setSelectedStatus({
        value: ``,
        name: "",
      });
      setRequestPayload({ ...defaultPayload, searchText: searchText });
    } else if (event.selected === 2) {
      setSelectedStatus({
        value: `Status eq Approved | and `,
        name: "Approved",
      });
      const filteQuery = `Status eq Approved | and ${
        selectedCountryItems.value ? selectedCountryItems.value : ""
      } ${selectedGroupItems.value ? selectedGroupItems.value : ""} ${
        selectedRequestedBysItems.value ? selectedRequestedBysItems.value : ""
      } ${
        selectedLastUpdatedBysItems.value
          ? selectedLastUpdatedBysItems.value
          : ""
      } ${
        selectedGlobalSiteIdItems.value ? selectedGlobalSiteIdItems.value : ""
      }`;

      setRequestPayload({
        ...requestPayload,
        filter: filteQuery,
      });
    } else if (event.selected === 3) {
      setSelectedStatus({
        value: `Status eq Rejected | and `,
        name: "Rejected",
      });
      const filterQuery = `Status eq Rejected | and  ${
        selectedCountryItems.value ? selectedCountryItems.value : ""
      }${selectedGroupItems.value ? selectedGroupItems.value : ""}${
        selectedRequestedBysItems.value ? selectedRequestedBysItems.value : ""
      }${
        selectedLastUpdatedBysItems.value
          ? selectedLastUpdatedBysItems.value
          : ""
      }${
        selectedGlobalSiteIdItems.value ? selectedGlobalSiteIdItems.value : ""
      }`;

      setRequestPayload({
        ...requestPayload,
        filter: filterQuery,
      });
    } else if (event.selected === 1) {
      setSelectedStatus({
        value: `Status eq Open | and `,
        name: "Open",
      });
      const filterQuery = `Status eq Open | and ${
        selectedCountryItems.value ? selectedCountryItems.value : ""
      }${selectedGroupItems.value ? selectedGroupItems.value : ""}${
        selectedRequestedBysItems.value ? selectedRequestedBysItems.value : ""
      }${
        selectedLastUpdatedBysItems.value
          ? selectedLastUpdatedBysItems.value
          : ""
      }${
        selectedGlobalSiteIdItems.value ? selectedGlobalSiteIdItems.value : ""
      }`;

      setRequestPayload({
        ...requestPayload,
        filter: filterQuery,
      });
    }
    setSelected(event.selected);
  };
  const okBtnClickCancel = () => {
    setShowApproveDialog(false);
    setConformationtext(false);
    setRejectionReason("");
  };
  const okBtnClick = async () => {
    try {
      if (currentConfigName === "Reject") {
        if (rejectionReason.length < 1) {
          setConformationtext(true);
        } else {
          const data = await approveReject();
          refetch();
          setRejectionReason("");
          setSelecteDataOnCheck([]);
          setVisible(true);
          setShowApproveDialog(false);
          setConformationtext(false);
        }
      } else {
        const data = await approveReject();
        refetch();
        setSelecteDataOnCheck([]);
        setVisible(true);
        setShowApproveDialog(false);
        setConformationtext(false);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const approveReject = async () => {
    let config = {
      headers: {
        "x-channel-Id": connection?.connection?.connectionId,
        "x-initiator-Id": AppConst.NOTIFICATION_HUB_INITIATOR_ID,
      },
    };
    if (currentConfigName === "Reject") {
      const rejectBody = {
        Status: 2,

        ChangeRequestIds: selecteDataOnCheck,

        RejectionReason: rejectionReason,
      };
      return ConfigurationService.putChangeRequestApproveReject(
        rejectBody,
        config
      );

      // return await axios({
      //   method: "put",
      //   url: `https://test-changerequest-api.azurewebsites.net/api/changerequest`,
      //   headers: {
      //     Authorization: "Bearer test",
      //     "x-userId": userData.emailAddress,
      //     "Content-Type": "application/json",
      //     "x-channel-Id": connection?.connection?.connectionId,
      //     "x-initiator-Id": AppConst.NOTIFICATION_HUB_INITIATOR_ID,
      //   },
      //   data: rejectBody,
      // });
    } else {
      const approveBody = {
        Status: 1,
        ChangeRequestIds: selecteDataOnCheck,
      };
      return ConfigurationService.putChangeRequestApproveReject(
        approveBody,
        config
      );

      // return await axios({
      //   method: "put",
      //   url: `https://test-changerequest-api.azurewebsites.net/api/changerequest`,
      //   headers: {
      //     Authorization: "Bearer test",
      //     "x-userId": userData.emailAddress,
      //     "Content-Type": "application/json",
      //     "x-channel-Id": connection?.connection?.connectionId,
      //     "x-initiator-Id": AppConst.NOTIFICATION_HUB_INITIATOR_ID,
      //   },
      //   data: approveBody,
      // });
    }
  };
  return (
    <>
      <div className="mdm--site-list-container">
        <ToastNotification show={success} type="success" message={[show]} />
        <div className="mdm--landing-page-item-body mdm--sites-list-body mdm--row">
          <TabStrip
            className="mdm--site-list-tabs"
            selected={selected}
            onSelect={handleSelect}
          >
            <TabStripTab
              key="shellsite"
              title={
                <>
                  <span
                    className="mdm--lp-sites-list-tab-title"
                    data-testid="shell_sites_title"
                  >
                    {Locale.landing_page?.sites_list.tab_titles.all_sites}
                  </span>
                  {/* <span className="mdm--dot">{totalCount}</span> */}
                </>
              }
              disabled={isDataLoading && selected !== 0}
            >
              <AppDataGrid
                data={changeRequestListData || []}
                tableHeaders={tableHeaders}
                initialSort={initialSort}
                renderGridCell={renderGridCell}
                searchPlaceHolderText={"Search"}
                onSearch={onSearch}
                onFilter={filterRows}
                onExport={excelExport}
                onClear={clearFilter}
                filterContent={filterContent}
                exportContent={exportContent}
                totalCount={totalCount}
                pageChange={pageChange}
                pageSort={pageSort}
                pageState={pageState}
                onRefreshClick={onRefreshClick}
                pageable={pageable}
                isLoading={isDataLoading || isLoading}
                CustomHeader={CustomHeader}
                searchValue={searchText}
                actionButton={CustomButton}
                // maxHeight={"15rem"}
                maxHeight={TABLE_MAX_HEIGHT}
                showFilterBadge={false}
                filterBadgeText={""}
              />
              {(isLoading || isDataLoading) && <Loader />}
            </TabStripTab>
            <TabStripTab
              key="nonshellsite"
              title={
                <>
                  <span
                    className="mdm--lp-sites-list-tab-title"
                    data-testid="shell_sites_title"
                  >
                    Open
                  </span>
                </>
              }
              disabled={isDataLoading && selected !== 0}
            >
              <AppDataGrid
                data={changeRequestListData || []}
                tableHeaders={tableHeaders}
                initialSort={initialSort}
                renderGridCell={renderGridCell}
                searchPlaceHolderText={
                  Locale.landing_page?.sites_list.table_placeholder_text
                }
                onSearch={onSearch}
                onFilter={filterRows}
                onExport={excelExport}
                onClear={clearFilter}
                filterContent={filterContent}
                exportContent={exportContent}
                totalCount={totalCount}
                pageChange={pageChange}
                pageSort={pageSort}
                pageState={pageState}
                actionButton={CustomButton}
                onRefreshClick={onRefreshClick}
                pageable={pageable}
                CustomHeader={CustomHeader}
                isLoading={isDataLoading || isLoading}
                // maxHeight={"15rem"}
                searchValue={searchText}
                maxHeight={TABLE_MAX_HEIGHT}
                showFilterBadge={false}
                filterBadgeText={""}
              />
              {(isLoading || isDataLoading) && <Loader />}
            </TabStripTab>
            <TabStripTab
              title={
                <>
                  <span className="mdm--lp-sites-list-tab-title">Approved</span>
                  {/* <span className="mdm--dot">{totalCount}</span> */}
                </>
              }
              disabled={isDataLoading && selected !== 1}
            >
              <AppDataGrid
                data={changeRequestListData || []}
                tableHeaders={tableHeaders}
                initialSort={initialSort}
                renderGridCell={renderGridCell}
                searchPlaceHolderText={
                  Locale.landing_page?.sites_list.table_placeholder_text
                }
                onSearch={onSearch}
                onFilter={filterRows}
                onExport={excelExport}
                exportContent={exportContent}
                onClear={clearFilter}
                filterContent={filterContent}
                totalCount={totalCount}
                pageSort={pageSort}
                pageState={pageState}
                pageChange={pageChange}
                onRefreshClick={onRefreshClick}
                pageable={pageable}
                isLoading={isDataLoading || isLoading}
                CustomHeader={CustomHeader}
                actionButton={CustomButton}
                // maxHeight={"15rem"}
                maxHeight={TABLE_MAX_HEIGHT}
                searchValue={searchText}
                showFilterBadge={false}
                filterBadgeText={""}
              />
              {(isLoading || isDataLoading) && <Loader />}
            </TabStripTab>
            <TabStripTab
              title={
                <>
                  <span className="mdm--lp-sites-list-tab-title">Rejected</span>
                  {/* <span className="mdm--dot">
                    {totalCount}
                  </span> */}
                </>
              }
              disabled={isDataLoading && selected !== 2}
            >
              <AppDataGrid
                data={changeRequestListData || []}
                tableHeaders={tableHeaders}
                initialSort={initialSort}
                renderGridCell={renderGridCell}
                searchPlaceHolderText={"Search"}
                onSearch={onSearch}
                onFilter={filterRows}
                searchValue={searchText}
                onExport={excelExport}
                exportContent={exportContent}
                onClear={clearFilter}
                filterContent={filterContent}
                totalCount={totalCount}
                pageSort={pageSort}
                pageState={pageState}
                pageChange={pageChange}
                onRefreshClick={onRefreshClick}
                pageable={pageable}
                actionButton={CustomButton}
                CustomHeader={CustomHeader}
                isLoading={isDataLoading || isLoading}
                // maxHeight={"15rem"}
                maxHeight={TABLE_MAX_HEIGHT}
                showFilterBadge={false}
                filterBadgeText={""}
              />
              {(isLoading || isDataLoading) && <Loader />}
            </TabStripTab>
          </TabStrip>
        </div>
      </div>
      {showApproveDialog && (
        <Dialog
          style={{
            width: "95%",
            marginLeft: "0%",
          }}
          title={currentConfigName}
          className="mdm--session-expired-portal-aproved"
          closeIcon={false}
        >
          {currentConfigName === "Reject" ? (
            <>
              <textarea
                value={rejectionReason}
                name="rejectionReason"
                onChange={(e) => setRejectionReason(e.target.value)}
                placeholder="Enter Reason (Required)"
                style={{ width: "38vh" }}
              />
              {conformationtext ? (
                <div className="mdm--details-view-component-message-error">
                  Required field
                </div>
              ) : (
                ""
              )}
            </>
          ) : (
            <p style={{ margin: "2vh", textAlign: "center" }}>
              Are you sure you want to approve?
            </p>
          )}
          <DialogActionsBar>
            <div className="change-request-button-batch">
              <button
                className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
                onClick={okBtnClickCancel}
              >
                Cancel
              </button>
              <button
                className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
                onClick={okBtnClick}
              >
                {currentConfigName}
              </button>
            </div>
          </DialogActionsBar>
        </Dialog>
      )}
      {visible && (
        <Dialog
          className="mdm-edit-site-confim-dialog mdm-dialog"
          width={400}
          onClose={toggleDialog}
        >
          <p style={{ margin: "25px", textAlign: "center" }}>
            {Locale.site_details?.change_confirmation_msg}
          </p>
          <DialogActionsBar>
            <button
              className="k-button k-button-md mdm-submit-btn primary-btn k-rounded-md k-button-solid k-button-solid-base"
              onClick={toggleDialog}
            >
              {Locale.common.ok}
            </button>
          </DialogActionsBar>
        </Dialog>
      )}
      <ToastNotification show={success} type="success" message={[show]} />
    </>
  );
};
