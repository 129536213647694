import React from "react";
import ReactDOM from "react-dom";

import "./index.scss";

import reportWebVitals from "./reportWebVitals";
import { App } from "./App";

// import "@progress/kendo-theme-default/dist/all.scss";

import { appConfig } from "./config";
import { appInsightsService } from "./services";

const root = document.getElementById("root");

function initialize() {
  appConfig.allowTelemetery && appInsightsService.initializeAppInisghts();
  ReactDOM.render(<App />, root);
}

initialize();
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
