import React, { useRef, useEffect, useState } from "react";
import { format } from "date-fns";

import messageBox from "../../../assets/images/messageBox.png";
import { AppConst } from "../../../constants";

import "./TooltipContentTemplate.scss";

export const TooltipContentTemplate = (props: any) => {
  const [change, setChange] = useState("-100px");

  // console.log(change,"chaking")
  const toltipstyle = {
    bottom: `${change}`,
  };
  const el = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (!el) return;
    setTimeout(() => {
      const top_to_dev = el.current!.getBoundingClientRect().y;
      const height_dev = el.current!.getBoundingClientRect().height;
      const remain = props.height - height_dev;
      if (top_to_dev >= remain + 30) {
        if (top_to_dev >= remain + 100) {
          setChange("-2vh");
        } else {
          setChange("-4vh");
        }
      }
    });
  }, []); //empty dependency array so it only runs once at render

  return (
    <div
      ref={el}
      className="change-history-container-for-hover"
      style={toltipstyle}
    >
      <div className="ad">
        <img src={messageBox} className="image" />
        <div className="address-site-com">CHANGE DETAILS</div>
      </div>

      <table
        className="change-history-table-data"
        style={{ border: "5", borderSpacing: "20px" }}
        cellPadding={"20%"}
        cellSpacing={"50%"}
      >
        <tr>
          <td>
            Site <span className="vertical-bar">|</span>
          </td>
          <td>{props.data.siteName} </td>
        </tr>
        <tr>
          <td>
            Country <span className="vertical-bar">|</span>
          </td>
          <td>{props.data.country}</td>
        </tr>
        <tr>
          <td>
            User Role <span className="vertical-bar">|</span>
          </td>
          <td>{props.data.role}</td>
        </tr>
        <tr>
          <td>
            Changes Made <span className="vertical-bar">|</span>
          </td>
          <td>{props.data.changes}</td>
        </tr>
        <tr>
          <td>
            User Name <span className="vertical-bar">|</span>
          </td>
          <td>{props.data.userName}</td>
        </tr>
        {/* <tr>
          <td>
            Updated By <span className="vertical-bar">|</span>
          </td>
          <td>{props.data.updatedBy}</td>
        </tr> */}

        <tr>
          <td>
            Updated Date <span className="vertical-bar">|</span>
          </td>
          <td>
            {format(
              new Date(props.data.updatedOn),
              AppConst.VIEW_DETAIL_DATE_FORMAT
            )}
          </td>
        </tr>
      </table>
    </div>
  );
};
