import { useRef, useState, useEffect } from "react";
import { GridToolbar } from "@progress/kendo-react-grid";
import { Popup } from "@progress/kendo-react-popup";
import { Button } from "@progress/kendo-react-buttons";
import { Badge } from "@progress/kendo-react-indicators";
import { InputChangeEvent } from "@progress/kendo-react-inputs";

import { TextInput } from "./../../../text-input/TextInput";

import filterIcon from "./../../../../../assets/images/icons/filter-icon.svg";
import exportIcon from "./../../../../../assets/images/icons/export_icon.svg";
import refreshIcon from "./../../../../../assets/images/icons/refresh_icon.svg";

import "./AppDataGridToolbar.scss";
import { Locale } from "../../../../../utils/localization";

interface IProps {
  onSearch: (searchQuery: string) => void;
  onClear: () => void;
  onFilter?: () => void;
  onExport?: () => void;
  searchPlaceHolderText: string;
  filterContent?: any;
  exportContent?: any;
  onRefreshClick?: () => void;
  isLoading?: boolean;
  actionButton?: any;
  statusItems?: [];
  searchValue?: string;
  showFilterBadge: boolean;
  filterBadgeText: string;
}

export const AppDataGridToolbar = (props: IProps) => {
  const {
    onSearch,
    searchPlaceHolderText,
    filterContent,
    exportContent,
    onFilter,
    onExport,
    onClear,
    isLoading,
    actionButton,
    searchValue,
    showFilterBadge,
    filterBadgeText,
  } = props;

  const filterAnchor = useRef<HTMLButtonElement | null>(null);
  const filterContentRef = useRef<any>(null);
  const blurTimeoutFilterRef = useRef<any>(null);

  const exportAnchor = useRef<HTMLButtonElement | null>(null);
  const exportContentRef = useRef<any>(null);
  const blurTimeoutExportRef = useRef<any>(null);

  const [showFilter, setShowFilter] = useState(false);
  const [showExport, setShowExport] = useState(false);
  const [searchInputValue, setSearchInputValue] = useState("");

  useEffect(() => {
    setSearchInputValue(searchValue || "");
  }, [searchValue]);

  const onSearchInputChange = (event: InputChangeEvent) => {
    setSearchInputValue(event.value);
    onSearch(event.value);
  };

  // const onPasteInputChange = (
  //   event: React.ClipboardEvent<HTMLInputElement>
  // ) => {
  //   setSearchInputValue(event.clipboardData.getData("Text"));
  //   onSearch(event.clipboardData.getData("Text"));
  // };

  const onRefreshClick = () => {
    setSearchInputValue("");
    props.onRefreshClick?.();
  };

  const onFilterSubmitClick = () => {
    onFilter && onFilter();
    setShowFilter(false);
  };

  const onExportSubmitClick = () => {
    onExport && onExport();
    setShowExport(false);
  };

  const onFilterClearClick = () => {
    onClear();
    setShowFilter(false);
  };

  const onClearSearchBtnClick = () => {
    setSearchInputValue("");
    onSearch("");
  };

  const onFilterOpen = () => {
    filterContentRef.current && filterContentRef.current.focus();
  };

  const onFilterFocus = () => {
    // the user is still inside the content
    clearTimeout(blurTimeoutFilterRef.current);
  };

  const onBlurFilterTimeout = () => {
    // the user is now outside the popup
    setShowFilter(false);
  };

  const onFilterBlur = () => {
    clearTimeout(blurTimeoutFilterRef.current);
    blurTimeoutFilterRef.current = setTimeout(onBlurFilterTimeout, 200);
  };

  const onExportOpen = () => {
    exportContentRef.current && exportContentRef.current.focus();
  };

  const onExportFocus = () => {
    // the user is still inside the content
    clearTimeout(blurTimeoutExportRef.current);
  };

  const onBlurExportTimeout = () => {
    // the user is now outside the popup
    setShowExport(false);
  };

  const onExportBlur = () => {
    clearTimeout(blurTimeoutExportRef.current);
    blurTimeoutExportRef.current = setTimeout(onBlurExportTimeout, 200);
  };

  return (
    <div className="mdm--data-grid-toolbar ">
      <GridToolbar>
        {actionButton && actionButton()}
        <TextInput
          id={"mdm--data-grid-toolbar-search"}
          data-testid="email-input"
          type="text"
          value={searchInputValue}
          onChange={onSearchInputChange}
          // onPaste={onPasteInputChange}
          showClearButton={searchInputValue?.length ? true : false}
          onClearSearchBtnClick={onClearSearchBtnClick}
          placeholder={
            searchPlaceHolderText ||
            Locale.common?.table_toolbar.search_input_placeholder
          }
        />
        <div className="buttons-container">
          {exportContent && (
            <span
              className="buttons-container-button anchor content"
              ref={exportAnchor}
            >
              <Button
                className="mdm--data-grid-export-button mdm--data-grid-button"
                imageUrl={exportIcon}
                disabled={isLoading}
                onClick={() => {
                  setShowExport(!showExport);
                }}
              >
                {Locale.common?.table_toolbar.export_btn_label}
              </Button>
            </span>
          )}

          <span className="buttons-container-button anchor content">
            <Button
              className="mdm--data-grid-button"
              onClick={onRefreshClick}
              imageUrl={refreshIcon}
              disabled={isLoading}
            >
              {Locale.common?.table_toolbar.refresh_btn_label}
            </Button>
          </span>

          <span
            ref={filterAnchor}
            className="buttons-container-button anchor content"
          >
            <Button
              className="mdm--data-grid-button"
              imageUrl={filterIcon}
              onClick={() => {
                setShowFilter(!showFilter);
              }}
            >
              {Locale.common?.table_toolbar.filter_btn_label}
              {showFilterBadge && (
                <Badge
                  align={{
                    vertical: "top",
                    horizontal: "end",
                  }}
                  themeColor="secondary"
                >
                  {filterBadgeText}
                </Badge>
              )}
            </Button>
          </span>

          <Popup
            anchor={exportAnchor.current}
            anchorAlign={{
              horizontal: "right",
              vertical: "bottom",
            }}
            popupAlign={{
              horizontal: "right",
              vertical: "top",
            }}
            onOpen={onExportOpen}
            show={showExport}
            popupClass={"popup-content mdm--grid-toolbar-export-popup"}
          >
            <div
              className="mdm--row mdm--grid-toolbar-export-content"
              ref={exportContentRef}
              tabIndex={0}
              onFocus={onExportFocus}
              onBlur={onExportBlur}
            >
              {exportContent && exportContent()}
            </div>
            <div className="mdm--row button-container">
              <Button
                className="mdm-submit-btn primary-btn"
                disabled={isLoading}
                onClick={onExportSubmitClick}
              >
                {Locale.landing_page?.sites_list.export.export_label}
              </Button>
            </div>
          </Popup>

          <Popup
            anchor={filterAnchor.current}
            anchorAlign={{
              horizontal: "right",
              vertical: "bottom",
            }}
            popupAlign={{
              horizontal: "right",
              vertical: "top",
            }}
            onOpen={onFilterOpen}
            show={showFilter}
            popupClass={"popup-content mdm--grid-toolbar-filter-popup"}
          >
            <div
              className="mdm--row mdm--grid-toolbar-filter-items"
              // ref={filterContentRef}
              tabIndex={0}
              onFocus={onFilterFocus}
              onBlur={onFilterBlur}
              ref={filterContentRef}
            >
              {filterContent && filterContent()}
            </div>
            <div className="mdm--row button-container">
              <Button
                className="mdm-submit-btn secondary-btn"
                onClick={onFilterClearClick}
              >
                {Locale.common?.filter_buttons.clear}
              </Button>
              <Button
                className="mdm-submit-btn primary-btn"
                onClick={onFilterSubmitClick}
              >
                {Locale.common?.filter_buttons.apply}
              </Button>
            </div>
          </Popup>
        </div>
      </GridToolbar>
    </div>
  );
};
