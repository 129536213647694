import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import withTelemetry from "../../components/hoc/with-telemetry";
import { appInsightsService } from "../../../services";
import { APP_ROUTES, EVENT_NAMES, RoleConst } from "../../../constants";

import { SitesList, RecentUpdatesComponent } from "./components";

import { adobeAnalyticsConst } from "../../../constants/adobe-analytics.constant";

import { StatusComponent } from "./components/Status";
import { ChangeHistorySmallComponent } from "./components/ChangeHistorySmall";

import { DashboardToggleComponent } from "../../components/dashboard-toggle";
import { CategoryGraphComponent } from "./components/Category";

import { Locale } from "../../../utils/localization";

import "./Dashboard.scss";

const Dashboard = () => {
  const { pathname } = useLocation();
  const userDetails = useSelector((state: any) => state.auth.user);
  const roleName = userDetails?.roleDetail?.userRoleName;

  appInsightsService.publishCustomEvent({
    name: EVENT_NAMES.DASHBOARD_PAGE_CLICKED,
    properties: {},
  });

  const leadershipRoleInd = useSelector(
    (state: any) => state?.auth?.user?.leadershipRoleInd
  );

  const [isSiteListExpanded, setIsSiteListExpanded] = useState(false);

  const [totalSitesCount, setTotalSitesCount] = useState(0);

  const onExpand = () => {
    setIsSiteListExpanded(!isSiteListExpanded);
  };

  const selectedGlobalCountry = useSelector((state: any) => {
    return state?.selectedGlobalCountry.selectedGlobalCountry;
  });

  useEffect(() => {
    window.adobeDataLayer = window.adobeDataLayer || [];
    window.adobeDataLayer.push({
      event: "Page Loaded",
      metric: "attract",
      timestamp: new Date().toISOString(),
      page: {
        pageName: "Site Data Web | " + pathname.slice(1),
        language: adobeAnalyticsConst.language,
        platform: adobeAnalyticsConst.platform,
        countryCode: selectedGlobalCountry,
        cvp: "Site Data Web",
        siteSection: pathname.slice(1),
      },
      user: {
        userId: userDetails.userLoginId,
        loginStatus: "active",
        userType: userDetails.userType,
        customerRole: userDetails?.roleDetail?.userRoleName,
      },
    });
  }, []);

  const onTotalCountChange = (total: number) => {
    setTotalSitesCount(total);
  };

  return (
    <div className="mdm--landing-page">
      <div
        className={`mdm--lp-sites-list-section mdm--landing-page-item expandable ${
          isSiteListExpanded && "expanded"
        }`}
      >
        <SitesList onExpand={onExpand} isExpanded={isSiteListExpanded} />
      </div>

      <div className="mdm--lp-new-recent-section mdm--landing-page-item">
        {roleName === RoleConst.MARKET_DATA_LEAD ||
        roleName === RoleConst.SALES_OPS_SME ||
        roleName === RoleConst.TM_AND_DM ||
        roleName === RoleConst.RETAILER ||
        roleName === RoleConst.GI_ACCESS_USER ||
        roleName === RoleConst.GLOBAL_USER ? (
          <>
            <CategoryGraphComponent totalSitesCount={totalSitesCount} />
            <StatusComponent onTotalCountChange={onTotalCountChange} />
            {leadershipRoleInd ? (
              <DashboardToggleComponent
                titleText={Locale.leadership_landing_page?.leadership_dashboard}
                navigateText={
                  Locale.leadership_landing_page?.view_leadership_dashboard
                }
                path={APP_ROUTES.LEADERSHIP_DASHBOARD_PATH}
              />
            ) : (
              <ChangeHistorySmallComponent />
            )}
          </>
        ) : (
          <RecentUpdatesComponent />
        )}
      </div>
    </div>
  );
};
export default withTelemetry(Dashboard);
