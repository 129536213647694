import axios from "axios";
import { http } from ".";
import { appConfig } from "../config";

const getQueryString = (str: any) => {
  var queryString = Object.keys(str)
    .map((key) => key + "=" + str[key])
    .join("&")
    ?.replaceAll(",", "|");
  return queryString;
};

const getNewStatusData = (inputData: any) => {
  let outputData: {
    monthgroup: string;
    sitestatus: string;
    total: number;
  }[] = [];
  inputData?.forEach(
    (item: { monthgroup: string; sitestatus: string; total: number }) => {
      const exitedItem = outputData?.find(
        (inputItem: {
          monthgroup: string;
          sitestatus: string;
          total: number;
        }) => {
          return (
            inputItem?.monthgroup === item?.monthgroup &&
            inputItem?.sitestatus === item?.sitestatus
          );
        }
      );
      if (exitedItem?.monthgroup) {
        const exitedItemTotal = exitedItem?.total + item?.total;
        const index = outputData.findIndex(
          (opData: { monthgroup: string; sitestatus: string; total: number }) =>
            opData?.monthgroup === item?.monthgroup &&
            opData?.sitestatus === item?.sitestatus
        );
        outputData[index].total = exitedItemTotal;
      } else {
        outputData?.push({ ...item });
      }
    }
  );
  return [...outputData];
};

const getNewSCData = (scInputData: any) => {
  let scOutputData: {
    sitecategory?: string;
    label?: string;
    total: number;
  }[] = [];

  scInputData?.forEach((item: { sitecategory: string; total: number }) => {
    let isItemAlreadyExists = false;
    const exitedItem: any = scOutputData?.find((inputItem: any) => {
      return inputItem?.sitecategory === item?.sitecategory;
    });
    isItemAlreadyExists = exitedItem?.sitecategory;
    if (isItemAlreadyExists) {
      const exitedItemTotal = exitedItem?.total + item?.total;
      const index = scOutputData.findIndex(
        (opData: any) => opData?.sitecategory === item?.sitecategory
      );
      scOutputData[index].total = exitedItemTotal;
    } else {
      scOutputData?.push({ ...item });
    }
  });
  return [...(scOutputData || [])];
};

const getNewSTData = (stInputData: any) => {
  let stOutputData: any[] = [];
  stInputData?.forEach((item: { sitetype: string; total: number }) => {
    let isItemAlreadyExists = false;
    const exitedItem: any = stOutputData?.find((inputItem: any) => {
      return inputItem?.sitetype === item?.sitetype;
    });
    isItemAlreadyExists = exitedItem?.sitetype ? true : false;
    if (isItemAlreadyExists) {
      const exitedItemTotal = exitedItem?.total + item?.total;
      const index = stOutputData.findIndex(
        (opData: any) => opData?.sitetype === item?.sitetype
      );
      stOutputData[index].total = exitedItemTotal;
    } else {
      stOutputData?.push({ ...item });
    }
  });

  return [...(stOutputData || [])];
};

const getNewOPData = (opInputData: any) => {
  let opOutputData: any[] = [];
  opInputData?.forEach((item: any) => {
    let isItemAlreadyExists = false;
    const exitedItem = opOutputData?.find((inputItem: any) => {
      return inputItem?.siteoperatingplatform === item?.siteoperatingplatform;
    });
    isItemAlreadyExists = exitedItem?.siteoperatingplatform;
    if (isItemAlreadyExists) {
      const exitedItemTotal = exitedItem?.total + item?.total;
      const index = opOutputData.findIndex(
        (opData: any) =>
          opData?.siteoperatingplatform === item?.siteoperatingplatform
      );
      opOutputData[index].total = exitedItemTotal;
    } else {
      opOutputData?.push({ ...item });
    }
  });

  return [...(opOutputData || [])];
};

export const LeadershipSitesService = {
  getStatusCountData: (payload: any) => {
    const { isChecked, shellCountries, rblaCountries } = payload;
    let isShellCountriesSelected = false;
    let isRBLACountriesSelected = false;
    let isNocountrySelected = false;

    if (shellCountries?.length) {
      isShellCountriesSelected = true;
    }
    if (rblaCountries?.length) {
      isRBLACountriesSelected = true;
    }
    if (!shellCountries?.length && !rblaCountries?.length) {
      isNocountrySelected = true;
    }

    if (isNocountrySelected) {
      return http.get(
        `${appConfig.baseUrl}/leadership/SiteStatus?${getQueryString({
          SiteTypes: isChecked ? [2, 3, 5] : [2, 3],
          country: [],
        })}`
      );
    } else if (isShellCountriesSelected && isRBLACountriesSelected) {
      return axios
        .all([
          http.get(
            `${appConfig.baseUrl}/leadership/SiteStatus?${getQueryString({
              SiteTypes: [2, 3],
              country: [...shellCountries],
            })}`
          ),
          http.get(
            `${appConfig.baseUrl}/leadership/SiteStatus?${getQueryString({
              SiteTypes: [5],
              country: [...rblaCountries],
            })}`
          ),
        ])
        .then(
          axios.spread((...responses) => {
            const newData = getNewStatusData([
              ...(responses?.[0]?.data?.result || []),
              ...(responses?.[1]?.data?.result || []),
            ]);
            return {
              data: {
                result: [...(newData || [])],
              },
            };
          })
        )
        .catch((error) => error);
    } else if (
      isShellCountriesSelected &&
      !isRBLACountriesSelected &&
      !isNocountrySelected
    ) {
      return http.get(
        `${appConfig.baseUrl}/leadership/SiteStatus?${getQueryString({
          SiteTypes: [2, 3],
          country: [...shellCountries],
        })}`
      );
    } else if (
      isRBLACountriesSelected &&
      !isShellCountriesSelected &&
      !isNocountrySelected
    ) {
      return http.get(
        `${appConfig.baseUrl}/leadership/SiteStatus?${getQueryString({
          SiteTypes: [5],
          country: [...rblaCountries],
        })}`
      );
    }
  },

  getSiteCategorySiteTypeAndSiteOPData: (payload: any) => {
    const { isChecked, shellCountries, rblaCountries } = payload;
    let isShellCountriesSelected = false;
    let isRBLACountriesSelected = false;
    let isNocountrySelected = false;

    if (shellCountries?.length) {
      isShellCountriesSelected = true;
    }
    if (rblaCountries?.length) {
      isRBLACountriesSelected = true;
    }
    if (!shellCountries?.length && !rblaCountries?.length) {
      isNocountrySelected = true;
    }

    if (isNocountrySelected) {
      return http.get(
        `${appConfig.baseUrl}/leadership/SiteData?${getQueryString({
          SiteTypes: isChecked ? [2, 3, 5] : [2, 3],
          country: [],
        })}`
      );
    } else if (isShellCountriesSelected && isRBLACountriesSelected) {
      return axios
        .all([
          http.get(
            `${appConfig.baseUrl}/leadership/SiteData?${getQueryString({
              SiteTypes: [2, 3],
              country: [...shellCountries],
            })}`
          ),
          http.get(
            `${appConfig.baseUrl}/leadership/SiteData?${getQueryString({
              SiteTypes: [5],
              country: [...rblaCountries],
            })}`
          ),
        ])
        .then(
          axios.spread((...responses) => {
            const newSCData = getNewSCData([
              ...(responses?.[0]?.data?.result?.siteCategoryData || []),
              ...(responses?.[1]?.data?.result?.siteCategoryData || []),
            ]);

            const newSTData = getNewSTData([
              ...(responses?.[0]?.data?.result?.siteTypeData || []),
              ...(responses?.[1]?.data?.result?.siteTypeData || []),
            ]);

            const newOPData = getNewOPData([
              ...(responses?.[0]?.data?.result?.siteOperatingPlatformData ||
                []),
              ...(responses?.[1]?.data?.result?.siteOperatingPlatformData ||
                []),
            ]);

            return {
              data: {
                result: {
                  siteCategoryData: [...(newSCData || [])],
                  siteTypeData: [...(newSTData || [])],
                  siteOperatingPlatformData: [...(newOPData || [])],
                },
              },
            };
            return [];
          })
        )
        .catch((error) => error);
    } else if (
      isShellCountriesSelected &&
      !isRBLACountriesSelected &&
      !isNocountrySelected
    ) {
      return http.get(
        `${appConfig.baseUrl}/leadership/SiteData?${getQueryString({
          SiteTypes: [2, 3],
          country: [...shellCountries],
        })}`
      );
    } else if (
      isRBLACountriesSelected &&
      !isShellCountriesSelected &&
      !isNocountrySelected
    ) {
      return http.get(
        `${appConfig.baseUrl}/leadership/SiteData?${getQueryString({
          SiteTypes: [5],
          country: [...rblaCountries],
        })}`
      );
    }
  },

  getCountryCodesData: () => {
    return http.get(`${appConfig.baseUrl}/leadership/Country`);
  },
};
