import { appConfig } from "../../../config";
import { appInsightsService } from "../../../services/app-insights";

export default function withTelemetery(Component: React.FC) {
  if (appConfig.allowTelemetery) {
    return appInsightsService.getAppInisghtsHOC(Component);
  } else {
    return Component;
  }
}
