import { useEffect, useRef, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useSelector, useDispatch, batch } from "react-redux";
import { useQuery } from "react-query";
import { Helmet } from "react-helmet";

import {
  TabStrip,
  TabStripSelectEventArguments,
  TabStripTab,
} from "@progress/kendo-react-layout";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { Button } from "@progress/kendo-react-buttons";

import {
  useMasterDataSet1,
  useMasterDataSet2,
  useCountyCodesData,
  useTerritoryCodesData,
  useOrgAffiliationCodesData,
  usePatchSiteDetailsMutation,
  useUSACompanyCodesData,
} from "../../../../hooks";

import {
  EVENT_NAMES,
  SignalRNotificationStatusConst,
  AppConst,
  SignalRNotificationTypeConst,
  adobeAnalyticsConst,
  RoleConst,
  SiteTypeConst,
} from "../../../../constants";
import {
  BasicSiteDetails,
  RetailAdminSiteDetails,
  EVSiteDetails,
} from "./components";

import withTelemetry from "../../../components/hoc/with-telemetry";
import { appInsightsService } from "../../../../services";
import { Locale } from "../../../../utils/localization";
import { ToastNotification } from "../../../components/toast-notification/ToastNotification";
import { Loader } from "../../../components";
import { SitesService } from "../../../../services/sites.service";
import { updateNotificationState } from "../../../../redux/actions/notificationState.actions";

import { refetchSiteData } from "../../../../redux/actions/refetchSiteData";

import { AdobeAnalyticsService } from "../../../../services/adobe-analytics.service";

import "./SiteDetails.scss";

interface MasterDataType {
  id: string;
  value: string;
  countrydiallingcd?: string;
}

const fetchBasicSiteData = ({ queryKey }: any) => {
  const siteId = queryKey[1];
  return SitesService.getSiteDetails({
    globalSiteId: siteId || "",
    viewName: "BasicDetail",
  });
};

const SiteDetails = () => {
  appInsightsService.publishCustomEvent({
    name: EVENT_NAMES.SITE_DETAILS_PAGE_CLICKED,
    properties: {},
  });
  const { siteId } = useParams();
  const { pathname } = useLocation();

  const dispatch = useDispatch();

  const userDetails = useSelector((state: any) => state.auth.user);
  const roleName = userDetails?.roleDetail?.userRoleName;

  const selectedGlobalCountry = useSelector((state: any) => {
    return state?.selectedGlobalCountry.selectedGlobalCountry;
  });

  const refetchData = useSelector((state: any) => state.refechSiteData);

  const connection = useSelector((state: any) => {
    return state.siteUpdateConnection.connection;
  });

  const [isEditButtonDisabled, setIsEditButtonDisabled] = useState(false);

  const basicSiteDetailsSectionRef = useRef<any>();
  const retailAdminSectionRef = useRef<any>();
  const evSectionRef = useRef<any>();

  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedStatus, setSelectedStatus] = useState<any>();

  const [classifiedSiteType, setClassifiedSiteType] = useState("");
  const [operatingPlatform, setOperatingPlatform] = useState<any>();

  const [visible, setVisible] = useState<boolean>(false);
  const [showToastNotification, setShowToastNotification] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState<any>("");
  const [selectedTerritory, setSelectedTerritory] = useState<any>("");
  const [detailsTabSelected, setDetailsTabSelected] = useState<number>(0);
  const [isEditing, setIsEditing] = useState(false);
  const [siteGeoCoordinates, setSiteGeoCoordinates] = useState({
    longitude: "",
    latitude: "",
  });
  const [patchRequestData, setPatchRequestData] = useState<any>({
    globalSiteId: siteId,
  });
  const [editedData, setEditedData] = useState<any>();
  const [patchRequestToastMessage, setPatchRequestToastMessage] = useState<
    string[]
  >([]);

  const [refetchAllData, setRefetchAllData] = useState(false);

  const [siteTypeCodes, setSiteTypeCodes] = useState<MasterDataType[]>();
  const [tenureTypeCodes, setTenureTypeCodes] = useState<MasterDataType[]>();
  const [crSiteShopTypeCodes, setCRSiteShopTypeCodes] =
    useState<MasterDataType[]>();
  const [crFormatTypeCodes, setCRFormatTypeCodes] =
    useState<MasterDataType[]>();
  const [countryCodes, setCountryCodes] = useState<MasterDataType[]>();
  const [siteStatusCodes, setSiteStatusCodes] = useState<MasterDataType[]>();
  const [siteCategoryCodes, setSiteCategoryCodes] =
    useState<MasterDataType[]>();
  const [operatingPlatformCodes, setOperatingPlatformCodes] =
    useState<MasterDataType[]>();
  const [orgAffiliationTypeCodes, setOrgAffiliationTypeCodes] =
    useState<MasterDataType[]>();
  const [neraRiskCodes, setNeraRiskCodes] = useState<MasterDataType[]>();
  const [isMasterDataLoading, setIsMasterDataLoading] = useState(true);
  const [countryDiallingCode, setCountryDiallingCode] = useState("");
  const [selectedSiteType, setSelectedSiteType] = useState<any>();

  const notificationState = useSelector(
    (state: any) => state.notificationState?.notificationState
  );

  const [showActionButtons, setShowActionButtons] = useState(false);

  const {
    isLoading: isBasicSiteDetailsLoading,
    data: basicSiteDetails,
    refetch: refetchBasicDetails,
  } = useQuery([`basic-site-data-${siteId}`, siteId], fetchBasicSiteData, {
    staleTime: AppConst.VIEW_SITE_DETAILS_STALE_TIME,
    enabled: true,
    refetchOnMount: true,
    refetchOnWindowFocus: false,
  });

  const { data: masterDataSet1, refetch: refetchMasterDataSet1 } =
    useMasterDataSet1();

  const { data: masterDataSet2, refetch: refetchMasterDataSet2 } =
    useMasterDataSet2();

  useEffect(() => {
    return () => {
      document.title = Locale?.default_tab_title || "Site - MDM";
    };
  }, []);

  useEffect(() => {
    if (masterDataSet1 && masterDataSet2) {
      const siteTypes = masterDataSet1?.siteTypeCodes?.map((type: any) => {
        return {
          id: type?.siteTypeCd,
          value: type?.siteTypeName,
        };
      });

      const tenureTypes = masterDataSet1?.tenureTypeCodes?.map((type: any) => {
        return {
          id: type?.tenureTypeCd,
          value: type?.tenureTypeName,
        };
      });

      const crSiteShopTypes = masterDataSet1?.crSiteShopTypeCodes?.map(
        (type: any) => {
          return {
            id: type?.crSiteShopTypeCd,
            value: type?.crSiteShopTypeName,
          };
        }
      );

      const crFormatTypes = masterDataSet1?.crFormatTypeCodes?.map(
        (type: any) => {
          return {
            id: type?.crFormatTypeCd,
            value: type?.crFormatTypeName,
          };
        }
      );

      const countryTypes = masterDataSet1?.countryCodes?.map((type: any) => {
        return {
          id: type?.countrycd,
          value: type?.countryname || "",
          countrydiallingcd: type?.countrydiallingcd || "",
        };
      });

      const statusTypes = masterDataSet2?.siteStatusCodes?.map((type: any) => {
        return {
          id: type?.siteStatusCd,
          value: type?.siteStatusName,
        };
      });

      const siteCategoryTypes = masterDataSet2?.siteCategories?.map(
        (type: any, index: number) => {
          return {
            id: index,
            value: type.siteCategoryCd,
          };
        }
      );

      const operatingPlatformTypes =
        masterDataSet2?.operatingPlatformCodes?.map((type: any) => {
          return {
            id: type.operatingPlatformCd,
            value: type.operatingPlatformName,
          };
        });

      const orgAffiliationTypeTypes =
        masterDataSet2?.orgAffiliationTypeCodes?.map((type: any) => {
          return {
            id: type.orgAffiliationTypeCd,
            value: type.orgAffiliationTypeName || "",
          };
        });

      const neraRiskTypes = masterDataSet2?.neraRiskCodes?.map((type: any) => {
        return {
          id: type.neraRiskCd,
          value: type.neraRiskName,
        };
      });

      setSiteTypeCodes(siteTypes);
      setTenureTypeCodes(tenureTypes);
      setCRSiteShopTypeCodes(crSiteShopTypes);
      setCRFormatTypeCodes(crFormatTypes);
      setCountryCodes(countryTypes);

      setSiteStatusCodes(statusTypes);
      setSiteCategoryCodes(siteCategoryTypes);
      setOperatingPlatformCodes(operatingPlatformTypes);
      setOrgAffiliationTypeCodes(orgAffiliationTypeTypes);
      setNeraRiskCodes(neraRiskTypes);
      setIsMasterDataLoading(false);
    }
  }, [masterDataSet1, masterDataSet2]);

  const {
    isLoading: isTerritoryCodesLoading,
    data: territoryCodes,
    refetch: refetchTerritoryCodes,
  } = useTerritoryCodesData(
    selectedCountry?.id,
    selectedCountry?.length ? true : false
  );

  const {
    isLoading: isCountyCodesLoading,
    data: countyCodes,
    refetch: refetchCountyCodes,
  } = useCountyCodesData(
    selectedCountry?.id,
    selectedCountry?.length || selectedTerritory?.length ? true : false
  );

  const {
    isLoading: isOrgAffiliationCodesLoading,
    data: orgAffiliationCodes,
    refetch: refetchOrgAffiliationCodes,
  } = useOrgAffiliationCodesData();

  const {
    isLoading: isUSACompanyCodesLoading,
    data: usaCompanyCodes,
    refetch: refetchUSACompanyCodes,
  } = useUSACompanyCodesData();

  const {
    mutate: patchRequest,
    isSuccess: isPatchRequestSuccess,
    isLoading: isPatchRequestLoading,
    data: patchRequestResponse,
  } = usePatchSiteDetailsMutation();

  useEffect(() => {
    const isInProgress = notificationState?.find((notification: any) => {
      return (
        notification?.id === siteId &&
        notification?.status ===
          SignalRNotificationStatusConst.IN_PROGRESS_NOTIFICATION
      );
    });
    setIsEditButtonDisabled(false);
    if (isInProgress) {
      setIsEditButtonDisabled(true);
    }
  }, [notificationState]);

  useEffect(() => {
    if (patchRequestResponse?.Error) {
      const error: any = patchRequestResponse?.Error?.message;
      setShowToastNotification(true);
      setPatchRequestToastMessage([
        Locale.formatString(
          Locale.create_site?.error_messages?.edit_site_request_failed,
          siteId
        ),
        error,
      ]);
      setTimeout(() => {
        setShowToastNotification(false);
      }, 15000);
    } else if (patchRequestResponse?.status) {
      batch(() => {
        dispatch(
          updateNotificationState([
            {
              type: SignalRNotificationTypeConst.EDIT_NOTIFICATION,
              id: siteId,
              requestId: patchRequestResponse?.data?.requestId,
              status: SignalRNotificationStatusConst.IN_PROGRESS_NOTIFICATION,
              isNewNotification: true,
            },
            ...notificationState,
          ])
        );
      });
      setVisible(true);
    }
  }, [isPatchRequestSuccess]);

  useEffect(() => {
    if (basicSiteDetails) {
      setSiteGeoCoordinates({
        longitude: basicSiteDetails?.data?.result?.longitude,
        latitude: basicSiteDetails?.data?.result?.latitude,
      });
    }
  }, [basicSiteDetails]);

  useEffect(() => {
    if (selectedCountry) {
      refetchTerritoryCodes(selectedCountry?.id);
      refetchCountyCodes(selectedCountry?.id);

      const country = countryCodes?.find(
        (country: MasterDataType) =>
          selectedCountry?.id === country?.countrydiallingcd
      );
      setCountryDiallingCode(country?.countrydiallingcd || "");
    }
  }, [selectedCountry]);

  useEffect(() => {
    if (selectedTerritory) {
      const obj: any = {
        countryCd: selectedCountry.id,
        territoryCd: selectedTerritory?.id,
      };
      refetchCountyCodes(obj);
    }
  }, [selectedTerritory]);

  useEffect(() => {
    if (isEditing) {
      refetchMasterDataSet1();
      refetchMasterDataSet2();
      refetchOrgAffiliationCodes();
      refetchUSACompanyCodes();
    }
  }, [isEditing]);

  useEffect(() => {
    if (refetchData?.isRefetch && siteId === refetchData?.siteId) {
      setRefetchAllData(true);
      refetchBasicDetails();
      setIsEditing(false);
      setEditedData(undefined);
      setPatchRequestData({
        globalSiteId: siteId,
      });
      dispatch(refetchSiteData({ siteId: "", isRefetch: false }));
    }
  }, [refetchData]);

  useEffect(() => {
    if (roleName && detailsTabSelected === 0 && basicSiteDetails) {
      const siteTypeId = basicSiteDetails?.data?.result?.siteTypeCd?.siteTypeCd;
      if (
        siteTypeId === SiteTypeConst.SHELL_IN_NETWORK_RETAIL_SITE ||
        siteTypeId === SiteTypeConst.SHELL_COMMERCIAL_SITE ||
        siteTypeId === SiteTypeConst.SHELL_LICENSED_MARKET_SITE ||
        siteTypeId === SiteTypeConst.SHELL_CARD_PARTNER_SITE ||
        siteTypeId === SiteTypeConst.COMPETITOR_SITE
      ) {
        setShowActionButtons(true);
      } else {
        setShowActionButtons(false);
      }
      if (
        roleName === RoleConst.GLOBAL_USER ||
        roleName === RoleConst.PARTNER_LOCAL_SME
      ) {
        setShowActionButtons(false);
      }
    } else {
      setShowActionButtons(false);
    }
  }, [detailsTabSelected, roleName, basicSiteDetails]);

  const handleDetailsTabSelect = (e: TabStripSelectEventArguments) => {
    setDetailsTabSelected(e.selected);
  };

  const onBasicDetailsPatchDataValueSet = (data: any, isDirty?: boolean) => {
    if (isDirty) {
      setPatchRequestData({ ...patchRequestData, ...data });
    } else {
      const field = Object.keys(data)[0];
      const { [field]: newField, ...patchRequestNewData } = patchRequestData;
      setPatchRequestData({ ...patchRequestNewData });
    }
  };

  const onEditedDataValueSet = (data: any) => {
    setEditedData({ ...editedData, ...data });
  };

  const onCancelButtonClick = () => {
    setPatchRequestData({
      globalSiteId: siteId,
    });
    setEditedData(undefined);
    setIsEditing(!isEditing);
    sendAdobeAnalyticsData("editCancelClick");
  };

  const onButtonClick = () => {
    setRefetchAllData(false);
    setIsEditing(!isEditing);
    sendAdobeAnalyticsData("editClick");
  };

  const onCountryChange = (country: any) => {
    setSelectedCountry(country);
  };

  const onTerritoryChange = (territory: any) => {
    setSelectedTerritory(territory);
  };

  const toggleDialog = () => {
    setVisible(!visible);
  };

  const onCategorySelected = (category: string) => {
    setSelectedCategory(category);
  };

  const onStatusSelected = (status: { id: string; value: string }) => {
    setSelectedStatus(status);
  };

  const onSiteTypeSelected = (type: { id: string; value: string }) => {
    setSelectedSiteType(type);
  };

  const sendAdobeAnalyticsData = async (type: string) => {
    window.adobeDataLayer = window.adobeDataLayer || [];
    // subCategory: "<$subCategory>", //'Facility Type | Facility Subtype, Fuel Products Code, Facility Type | Opening Time |Closing Time'
    //  editAction: "editSave", //'editCancel, editSave''

    let topMainCategory = "";
    let bottomMainCategory = "";
    let leftMainCategory = "";

    for (const key in patchRequestData) {
      if (Object.prototype.hasOwnProperty.call(patchRequestData, key)) {
        const result = await AdobeAnalyticsService.findSection(key);
        switch (result?.section) {
          case "top":
            topMainCategory += result.name + "|";
            break;
          case "bottom":
            bottomMainCategory += result.name + "|";
            break;
          case "left":
            leftMainCategory += result.name + "|";
            break;

          default:
            break;
        }
      }
    }
    window.adobeDataLayer.push({
      event: type, // editClicked editSaveClicked editCancelClicked
      globalSiteId: siteId,
      topMainCategory: topMainCategory, //'Facility|Fuel Products| Operating Hours|Retailer'
      bottomMainCategory: bottomMainCategory, // EV Overview |EV Charge Post
      leftMainCategory: leftMainCategory, // Status|SiteName
      metric: "engage",
      timestamp: new Date().toISOString(),
      page: {
        pageName: "Site Data Web | " + pathname.slice(1),
        language: adobeAnalyticsConst.language,
        platform: adobeAnalyticsConst.platform,
        countryCode: selectedGlobalCountry,
        cvp: "Site Data Web",
        siteSection: pathname.slice(1),
      },
      user: {
        userId: userDetails.userLoginId,
        loginStatus: "active",
        userType: userDetails.userType,
        customerRole: userDetails?.roleDetail?.userRoleName,
      },
    });
  };

  const onSaveButtonClick = () => {
    const isBasicSiteDetailsSectionValid =
      basicSiteDetailsSectionRef.current?.triggerValidations();
    const isRetaileAdminSectionValid =
      retailAdminSectionRef.current?.triggerValidations();
    const isEVSectionValid = evSectionRef?.current?.triggerValidations();
    if (
      isBasicSiteDetailsSectionValid &&
      isRetaileAdminSectionValid &&
      isEVSectionValid &&
      connection?.connection?.connectionId
    ) {
      console.log("patchRequestData", patchRequestData);

      patchRequest({
        data: patchRequestData,
        channelId: connection?.connection?.connectionId,
        initiatorId: AppConst.NOTIFICATION_HUB_INITIATOR_ID,
      });
    }
    if (!connection?.connection?.connectionId) {
      setShowToastNotification(true);
      setPatchRequestToastMessage([
        "",
        Locale.create_site?.error_messages?.connection_expired,
      ]);
      setTimeout(() => {
        setShowToastNotification(false);
      }, 15000);
    }
  };

  const onSubmitButtonClick = () => {
    setPatchRequestData({
      globalSiteId: siteId,
    });
    setEditedData(undefined);
    setIsEditing(false);
    setVisible(false);
    sendAdobeAnalyticsData("editSaveClick");
  };

  const onClassifiedSiteTypeChange = (type: string) => {
    setClassifiedSiteType(type);
  };

  const onOperatingPlatformChange = (
    op: { id: string; value: string } | null
  ) => {
    setOperatingPlatform(op);
  };

  const onChangeCategory = (category: string) => {
    basicSiteDetailsSectionRef.current?.setNewCategory(category);
  };

  return (
    <>
      {siteId && (
        <Helmet>
          <title>{`${Locale?.default_tab_title} - ${siteId}`}</title>
        </Helmet>
      )}
      <div className="mdm--site-details-container">
        {/* <BreadcrumbComponent items={breadcrumbItems} /> */}
        {isPatchRequestLoading && <Loader />}
        {showToastNotification && (
          <ToastNotification
            show={showToastNotification}
            type={"error"}
            message={patchRequestToastMessage}
          />
        )}

        {showActionButtons && (
          <div className="mdm--sd-button-container mdm--row">
            {isEditing ? (
              <>
                <Button
                  className="mdm-submit-btn cancel-btn"
                  onClick={onCancelButtonClick}
                  disabled={false}
                >
                  {Locale.common?.cancel}
                </Button>
                <Button
                  className="mdm-submit-btn primary-btn"
                  onClick={onSaveButtonClick}
                  disabled={Object.keys(patchRequestData).length <= 1}
                >
                  {Locale.common?.save}
                </Button>
              </>
            ) : (
              <Button
                className="mdm-submit-btn primary-btn edit-btn"
                onClick={onButtonClick}
                disabled={isEditButtonDisabled}
                title={
                  isEditButtonDisabled
                    ? Locale.create_site?.error_messages
                        ?.signalr_request_already_in_progress
                    : ""
                }
              >
                {Locale.common?.edit}
              </Button>
            )}
          </div>
        )}
        <div className="mdm--site-details mdm--row">
          <section className="mdm--sd-site-basic-details-section">
            <BasicSiteDetails
              ref={basicSiteDetailsSectionRef}
              basicSiteDetails={basicSiteDetails?.data?.result}
              isBasicSiteDetailsLoading={isBasicSiteDetailsLoading}
              isEditing={isEditing}
              isCreate={false}
              isMasterDataLoading={isMasterDataLoading}
              siteTypeCodes={siteTypeCodes}
              tenureTypeCodes={tenureTypeCodes}
              crSiteShopTypeCodes={crSiteShopTypeCodes}
              crFormatTypeCodes={crFormatTypeCodes}
              countryCodes={countryCodes}
              siteStatusCodes={siteStatusCodes}
              siteCategoryCodes={siteCategoryCodes}
              operatingPlatformCodes={operatingPlatformCodes}
              orgAffiliationTypeCodes={orgAffiliationTypeCodes}
              neraRiskCodes={neraRiskCodes}
              isOrgAffiliationCodesLoading={isOrgAffiliationCodesLoading}
              orgAffiliationCodes={orgAffiliationCodes}
              isCountyCodesLoading={isCountyCodesLoading}
              countyCodes={countyCodes}
              isTerritoryCodesLoading={isTerritoryCodesLoading}
              territoryCodes={territoryCodes}
              refetchTerritoryCodes={onCountryChange}
              refetchCountyCodes={onTerritoryChange}
              onSectionValueSet={onBasicDetailsPatchDataValueSet}
              setSelectedCategory={onCategorySelected}
              setSelectedStatus={onStatusSelected}
              onSiteTypeSelected={onSiteTypeSelected}
              classifiedSiteType={classifiedSiteType}
              onClassifiedSiteTypeChange={onClassifiedSiteTypeChange}
              setOperatingPlatform={onOperatingPlatformChange}
              countryDiallingCd={countryDiallingCode}
              usaCompanyCodes={usaCompanyCodes}
              isUSACompanyCodesLoading={isUSACompanyCodesLoading}
            />
          </section>

          <section className="mdm-sd-right-section">
            <TabStrip
              selected={detailsTabSelected}
              onSelect={handleDetailsTabSelect}
              className={"mdm--sd-details-parent-tab"}
            >
              <TabStripTab
                title={
                  Locale.site_details?.retail_admin_update
                    .retail_admin_update_label
                }
              >
                <RetailAdminSiteDetails
                  ref={retailAdminSectionRef}
                  siteId={siteId || ""}
                  basicSiteDetails={basicSiteDetails?.data?.result}
                  isBasicSiteDetailsLoading={isBasicSiteDetailsLoading}
                  isEditing={isEditing}
                  isTerritoryCodesLoading={isTerritoryCodesLoading}
                  territoryCodes={territoryCodes}
                  refetchTerritoryCodes={onCountryChange}
                  isCountryCodesLoading={isMasterDataLoading}
                  countryCodes={countryCodes}
                  onBasicDetailsPatchDataValueSet={
                    onBasicDetailsPatchDataValueSet
                  }
                  onBasicDetailsEditedDataValueSet={onEditedDataValueSet}
                  selectedCategory={selectedCategory}
                  selectedStatus={selectedStatus}
                  sectionEditedData={editedData}
                  classifiedSiteType={classifiedSiteType}
                  operatingPlatform={operatingPlatform}
                  refetchAllData={refetchAllData}
                  isCreate={false}
                  onChangeCategory={onChangeCategory}
                  siteTypeSelectedValue={selectedSiteType}
                  countrySelectedValue={selectedCountry}
                />
                <EVSiteDetails
                  ref={evSectionRef}
                  siteId={siteId || ""}
                  basicSiteDetails={basicSiteDetails?.data?.result}
                  isBasicSiteDetailsLoading={isBasicSiteDetailsLoading}
                  isEditing={isEditing}
                  onBasicDetailsPatchDataValueSet={
                    onBasicDetailsPatchDataValueSet
                  }
                  onBasicDetailsEditedDataValueSet={onEditedDataValueSet}
                  sectionEditedData={editedData}
                  isCreate={false}
                  classifiedSiteType={classifiedSiteType}
                  refetchAllData={refetchAllData}
                />
              </TabStripTab>
              <TabStripTab title={Locale.site_details?.map.toUpperCase()}>
                <section className="mdm-sd-map-container">
                  <div className="mdm-sd-map">
                    <object
                      data={`https://shellmdmlocator.geoapp.me/?locale=usa_EN&lat=${siteGeoCoordinates?.latitude}&lng=${siteGeoCoordinates?.longitude}`}
                      width="100%"
                      height="100%"
                    >
                      <embed
                        src={`https://shellmdmlocator.geoapp.me/?locale=usa_EN&lat=${siteGeoCoordinates?.latitude}&lng=${siteGeoCoordinates?.longitude}`}
                        width="600"
                        height="400"
                      ></embed>
                      Error: Embedded data could not be displayed.
                    </object>
                  </div>
                </section>
              </TabStripTab>
            </TabStrip>
          </section>
          {visible && (
            <Dialog
              className="mdm-edit-site-confim-dialog mdm-dialog"
              width={400}
              onClose={toggleDialog}
            >
              <p style={{ margin: "25px", textAlign: "center" }}>
                {Locale.site_details?.edit_site_confirmation_msg}
              </p>
              <DialogActionsBar>
                <button
                  className="k-button k-button-md mdm-submit-btn primary-btn k-rounded-md k-button-solid k-button-solid-base"
                  onClick={onSubmitButtonClick}
                >
                  {Locale.common.ok}
                </button>
              </DialogActionsBar>
            </Dialog>
          )}
        </div>
      </div>
    </>
  );
};
export default withTelemetry(SiteDetails);
