import React, { useEffect, useState } from "react";
import {
  DropDownList,
  DropDownListChangeEvent,
} from "@progress/kendo-react-dropdowns";
import { Skeleton } from "@progress/kendo-react-indicators";

import { useCurrencyCodesData } from "../../../../../../../../hooks";
import { TableDetailsViewData } from "../../../../../../../../models";
import { TableDetailsView } from "../../../../../../../components/table-details-view/TableDetailsView";
import { Locale } from "../../../../../../../../utils/localization";
import { EditFieldTypeConst } from "../../../../../../../../constants";

export const CurrenciesTab = (props: any) => {
  const {
    isEditing,
    evDetailsTabSelected,
    onPatchRequestValueSet,
    isSectionValueChanged,
    onSectionValueChanged,
    sectionEditedData,
    onSectionValueSet,
    fetchedCurrencyData,
    isCurrencyDataLoading,
    isCreate,
  } = props;

  const [currentStateOfCurrencyData, setCurrentStateOfCurrencyData] =
    useState<TableDetailsViewData>({ tableHeaders: [] });
  const [savedStateOfCurrencyData, setSavedStateOfCurrencyData] =
    useState<any>();

  const [newCurrencySelectedValue, setNewCurrencySelectedValue] =
    useState<any>();
  const [isNewCurrencyValid, setIsNewCurrencyValid] = useState(0);

  const {
    isLoading: isCurrencyCodesLoading,
    data: currencyCodes,
    refetch: refetchCurrencyCodes,
  } = useCurrencyCodesData();

  useEffect(() => {
    if (isEditing && evDetailsTabSelected === 7) {
      refetchCurrencyCodes();
    }
  }, [evDetailsTabSelected, isEditing]);

  const onNewCurrencyChange = (event: DropDownListChangeEvent) => {
    setNewCurrencySelectedValue(event.target.value);
    setIsNewCurrencyValid(1);
  };

  const [newCurrenciesInvalidText, setNewCurrenciesInvalidText] = useState("");

  const onAddNewCurrencyClick = () => {
    const savedCurrencies = savedStateOfCurrencyData?.data?.map(
      (fuel: { currencyCd: string }) => fuel.currencyCd
    );
    if (newCurrencySelectedValue) {
      if (savedCurrencies?.includes(newCurrencySelectedValue?.id)) {
        setIsNewCurrencyValid(-1);
        setNewCurrenciesInvalidText(
          Locale.create_site?.error_messages.currency_already_exists
        );
      } else {
        setIsNewCurrencyValid(1);
      }
    } else {
      setIsNewCurrencyValid(-1);
      setNewCurrenciesInvalidText(
        Locale.create_site?.error_messages.currency_required_fields
      );
    }

    if (
      newCurrencySelectedValue &&
      !savedCurrencies?.includes(newCurrencySelectedValue?.id)
    ) {
      const newData: any = {
        id: new Date().getMilliseconds() + 1000,
        currency: newCurrencySelectedValue?.value || "-",
        currencyCd: newCurrencySelectedValue?.id || "-",
        editActionRow: "",
      };
      const currentData = [...(currentStateOfCurrencyData?.data || [])];
      currentData.unshift(newData);

      var currentState: any = {
        ...currentStateOfCurrencyData,
        data: [...currentData],
      };
      setCurrentStateOfCurrencyData(currentState);
      setNewCurrencySelectedValue(null);

      onSectionValueChanged(true);
      setSavedStateOfCurrencyData({
        ...savedStateOfCurrencyData,
        data: currentState.data,
      });
      onSectionValueSet({
        SiteCurrency: {
          ...savedStateOfCurrencyData,
          data: currentState.data,
        },
      });

      const payload = currentState?.data?.map((currency: any) => {
        return {
          currencyCd: {
            currencyCd: currency.currencyCd,
          },
        };
      });
      onPatchRequestValueSet(
        {
          SiteCurrency: {
            item: payload,
          },
        },
        true
      );
      return true;
    }
    return false;
  };

  const onEditRow = (currentState: any, event: any, row: any, field: any) => {
    const newCurrentState = [...(currentState?.rows || [])].map((row: any) => {
      const { isValid, validationMessage, ...allData } = row;
      return allData;
    });

    const currentData = [...(newCurrentState || [])];
    const filterData = currentData?.map((item: any) => {
      if (row.id === item.id) {
        if (field === "currency") {
          return {
            ...item,
            [field]: event?.value,
            currencyCd: event?.id,
          };
        }
        return { ...item, [field]: event?.value };
      }
      return { ...item };
    });
    setCurrentStateOfCurrencyData({ ...currentState, data: filterData });
  };

  const onDiscardRow = (rowId: string) => {
    const discardRow = [...savedStateOfCurrencyData?.data]?.filter(
      (row: any) => row.id === rowId
    )?.[0];

    const currentState = currentStateOfCurrencyData?.data?.map((row: any) => {
      if (row.id === rowId) {
        return discardRow;
      }
      return { ...row };
    });

    const filteredArray = currentState?.filter((row: any) => {
      return row;
    });
    setCurrentStateOfCurrencyData({
      ...currentStateOfCurrencyData,
      data: filteredArray,
    });
  };

  const onDeleteRow = (rowId: string) => {
    const currentState = currentStateOfCurrencyData?.data?.filter(
      (row: any) => {
        return row.id !== rowId;
      }
    );
    setCurrentStateOfCurrencyData({
      ...currentStateOfCurrencyData,
      data: currentState,
    });

    const savedState = savedStateOfCurrencyData?.data?.filter((row: any) => {
      return row.id !== rowId;
    });

    onSectionValueChanged(true);
    setSavedStateOfCurrencyData({
      ...savedStateOfCurrencyData,
      data: savedState,
    });
    onSectionValueSet({
      SiteCurrency: { ...savedStateOfCurrencyData, data: savedState },
    });
    const payload = savedState?.map((eid: any) => {
      return {
        currencyCd: {
          currencyCd: eid.currencyCd,
        },
      };
    });
    onPatchRequestValueSet(
      {
        SiteCurrency: {
          item: payload,
        },
      },
      true
    );
  };

  const onSaveRow = (rowId: string) => {
    let isValid: boolean = true;

    const filteredData: any = currentStateOfCurrencyData?.data?.filter(
      (row: any) => {
        return row.id === rowId;
      }
    )?.[0];

    const existedRecord: any = currentStateOfCurrencyData?.data?.find(
      (row: any) => {
        return (
          row.currencyCd === filteredData?.currencyCd &&
          row?.id !== filteredData?.id
        );
      }
    );

    if (existedRecord?.currencyCd) {
      isValid = false;

      const newCurrentState = currentStateOfCurrencyData?.data?.map(
        (row: any) => {
          if (row.id === rowId) {
            return {
              ...row,
              isValid: false,
              validationMessage:
                Locale.create_site?.error_messages.currency_already_exists,
            };
          }
          return row;
        }
      );
      setCurrentStateOfCurrencyData({
        ...currentStateOfCurrencyData,
        data: newCurrentState,
      });
    }

    if (isValid) {
      const savedState = savedStateOfCurrencyData?.data?.map((row: any) => {
        if (row.id === rowId) {
          return filteredData;
        }
        return { ...row };
      });

      onSectionValueChanged(true);
      onSectionValueSet({
        SiteCurrency: {
          ...currentStateOfCurrencyData,
          data: savedState,
        },
      });
      setSavedStateOfCurrencyData({
        ...currentStateOfCurrencyData,
        data: savedState,
      });
      const payload = savedState?.map((eid: any) => {
        return {
          currencyCd: {
            currencyCd: eid.currencyCd,
          },
        };
      });
      onPatchRequestValueSet(
        {
          SiteCurrency: {
            item: payload,
          },
        },
        true
      );
    }
    return isValid;
  };

  const onShowNewRowClick = () => {
    setIsNewCurrencyValid(1);
    setNewCurrencySelectedValue(undefined);
    setCurrentStateOfCurrencyData({
      ...currentStateOfCurrencyData,
      data: savedStateOfCurrencyData.data,
    });
  };

  const renderAddNewCurrencyUI = () => {
    return (
      <>
        <div className="mdm--table-td">
          {isCurrencyCodesLoading ? (
            <Skeleton shape={"text"} style={{ width: "100%" }} />
          ) : (
            <DropDownList
              data={currencyCodes}
              value={newCurrencySelectedValue}
              onChange={onNewCurrencyChange}
              textField="value"
              dataItemKey="id"
              valid={isNewCurrencyValid !== -1 ? true : false}
            />
          )}
        </div>
      </>
    );
  };

  useEffect(() => {
    let list: string[] = [];
    const tbHeaders = [
      {
        label: Locale.site_details?.ev_details.currency.accepted_currency,
        field: "currency",
        editType: EditFieldTypeConst.DROPDOWN_FIELD,
        editData: currencyCodes,
        onChange: onEditRow,
      },
      {
        label: "",
        field: "editActionRow",
      },
    ];
    if (isSectionValueChanged && sectionEditedData?.SiteCurrency) {
      list =
        sectionEditedData?.SiteCurrency?.data?.map((item: any) => {
          return {
            id: item.id,
            currency: item.currency,
            currencyCd: item.currencyCd,
            editActionRow: "",
          };
        }) || [];
    } else {
      list =
        fetchedCurrencyData?.data?.result?.SiteCurrency?.item?.map(
          (item: any, index: number) => {
            return {
              id: index,
              currency: item.currencyCd?.currencyName,
              currencyCd: item.currencyCd?.currencyCd,
              editActionRow: "",
            };
          }
        ) || [];
    }
    const currencyData: TableDetailsViewData = {
      tableHeaders: tbHeaders,
      data: list,
    };
    setCurrentStateOfCurrencyData(currencyData);
    setSavedStateOfCurrencyData(currencyData);
  }, [fetchedCurrencyData, currencyCodes, isEditing, evDetailsTabSelected]);

  const [defaultData, setDefaultData] = useState<any>([]);
  useEffect(() => {
    if (fetchedCurrencyData) {
      const defaultFacility =
        fetchedCurrencyData?.data?.result?.SiteCurrency?.item?.map(
          (item: any, index: number) => {
            return {
              index: index,
              currency: item.currencyCd?.currencyName,
              currencyCd: item.currencyCd?.currencyCd,
            };
          }
        );
      setDefaultData(defaultFacility);
    }
  }, [fetchedCurrencyData]);

  return (
    <TableDetailsView
      className={"site-currency"}
      data={{
        tableHeaders: currentStateOfCurrencyData.tableHeaders,
        rows: currentStateOfCurrencyData?.data,
        defaultData: defaultData,
      }}
      isLoading={isCurrencyDataLoading}
      isEditing={isEditing}
      renderAddNewRowUI={renderAddNewCurrencyUI}
      deleteRow={onDeleteRow}
      onAddRowClick={onAddNewCurrencyClick}
      onEditDiscard={onDiscardRow}
      onSaveRow={onSaveRow}
      onShowNewRowClick={onShowNewRowClick}
      isNewRowValid={isNewCurrencyValid}
      newRowInvalidText={newCurrenciesInvalidText}
      isCreate={isCreate}
    />
  );
};
